import { Dropdown, Menu, Pagination, Radio } from 'antd';
import { mediaMax } from 'common/theme';
import styled from 'styled-components';
import { Button } from '../button';
import { FC, PropsWithChildren, ComponentProps } from 'react';

// @todo: antd@5
export const ScDropdown: FC<PropsWithChildren<ComponentProps<typeof Dropdown>>> = styled(
  Dropdown,
)`
  &.ant-dropdown-trigger {
    padding: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 5px;
    bottom: 0;
    top: 0;
    ${({ disabled }) => (disabled ? 'opacity: 0.3; cursor: not-allowed;' : '')}
  }
`;

export const ScMenu = styled(Menu)`
  padding: 0;
  right: 5px;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
`;

export const ScMenuItem = styled(Menu.Item)`
  padding: 1.25em 2.5em;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.softPeach};
  font-weight: 600;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.highlightColor};
    color: ${({ theme }) => theme.highlightColor_contrast};
  }
`;

export const ScPagination = styled(Pagination)`
  &.ant-pagination {
    font-size: inherit;
    margin: 0 auto;

    .ant-pagination-options {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        height: ${({ theme }) => theme.paginationHeight};
        align-items: center;
        display: flex;
        border-color: ${({ theme }) => theme.mainColor};
        box-shadow: none;
      }
    }

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      font-size: inherit;
      min-height: 32px;
      width: ${({ theme }) => theme.paginationHeight};
      height: ${({ theme }) => theme.paginationHeight};
      border: 1px solid ${({ theme }) => theme.colors.softPeach};
      font-family: Montserrat, serif;

      &.ant-pagination-item-active,
      &:hover {
        border: 1px solid ${({ theme }) => theme.mainBorderColor};
        background-color: ${({ theme }) => theme.mainColor};

        > a {
          color: ${({ theme }) => theme.mainColor_contrast};
          height: 100%;
        }
      }

      > a {
        color: ${({ theme }) => theme.colors.softPeach};
        font-weight: 600;
        line-height: ${({ theme }) => theme.paginationHeight};
      }

      .ant-pagination-item-link {
        border: none;
      }
    }

    .ant-pagination-prev {
      margin-right: 1em;
    }

    .ant-pagination-next {
      margin-left: 0.5em;
    }

    .ant-pagination-jump-next {
      border: 1px solid ${({ theme }) => theme.colors.softPeach};
      width: 3em;
      height: 3em;
      padding-top: 0.5em;
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${({ theme }) => theme.mainColor};
  }
`;

export const ScPaginationContent = styled.span`
  font-size: 0.75em;
`;

export const ScTableFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  flex-direction: column;

  ${mediaMax.lg} {
    margin-bottom: 1em;
  }

  .pagination-info {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: 600;

    ${mediaMax.lg} {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
`;

export const ScLoadNextStep = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: 2.5em;
`;

export const ScHeaderConfirmationContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  padding: 0.75em 0.75em 0;
  width: 270px;
  > div {
    margin-bottom: 0.5em;
  }
`;

export const ScHeaderConfirmationContainerButton = styled(Button)`
  padding: 4px;
  border: 1px solid transparent;
  height: auto;
`;

export const ScTableDiscount = styled.div`
  color: ${({ theme }) => theme.discountColor};
`;

export const ScTableEdit = styled.div`
  color: ${({ theme }) => theme.recordIsEditedHighlightColor};
`;

export const ScRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner,
  .ant-radio .ant-radio-inner {
    border-color: ${({ theme }) => theme.mainBorderColor};
    width: 1.5em;
    height: 1.5em;

    &::after {
      content: '\\2713';
      width: 1.375em;
      height: 1.375em;
      background-color: ${({ theme }) => theme.mainColor};
      color: ${({ theme }) => theme.mainColor_contrast};
      border-radius: 1.875em;
      top: 0;
      left: 0;
      transition: linear;
      line-height: 1.5em;
      text-align: center;
    }
  }
`;
