import { notification as AntdNotification } from 'antd';
import { MessageFields } from '@hypercharge/xdms-client/lib/types';
import { nanoid } from 'nanoid';
import {
  NotificationMessageByStatus,
  NotificationProps,
  NotificationReference,
  NotificationType,
} from './types';
import {
  EXTERNAL_NOTIFICATION_TYPE__MAPPING__NOTIFICATION_TYPE,
  NOTIFICATION_TYPE__MAPPING__LIBRARY_NOTIFICATION_TYPE,
  STATUS__MAPPING__NOTIFICATION_TYPE,
} from './mappings';
import { CloseIcon, NOTIFICATION_ICONS } from './icons';
import { isMessage } from './utils';
import { runIfNotAborted } from '../httpClient';
import { Status } from '../types';
import { isString } from '../index';

/** General configuration */
AntdNotification.config({
  placement: 'bottomRight',
  bottom: 0,
  duration: 6,
  closeIcon: CloseIcon,
});

export * from './types';

const DEFAULT_NOTIFICATION_TYPE = NotificationType.info;

/**
 * Open notification popup
 * @param {NotificationProps} args
 * @return {NotificationReference} list of ids of opened popups
 */
const open = (args: NotificationProps): NotificationReference | undefined => {
  if (!args.message) return;
  if (isMessage(args.message)) {
    const messageCode = args.message[MessageFields.code];
    const messageText = args.message[MessageFields.text] ?? '';

    let Icon: NotificationProps['icon'] = NOTIFICATION_ICONS[DEFAULT_NOTIFICATION_TYPE];

    let notificationType: NotificationProps['type'] = DEFAULT_NOTIFICATION_TYPE;

    if (args.type) {
      Icon = NOTIFICATION_ICONS[args.type];
    }

    if (messageCode) {
      const externalNotificationType =
        EXTERNAL_NOTIFICATION_TYPE__MAPPING__NOTIFICATION_TYPE[messageCode];

      if (externalNotificationType) {
        notificationType = externalNotificationType;
        Icon = NOTIFICATION_ICONS[externalNotificationType];
      }
    }

    return renderNotification({
      ...args,
      type: notificationType,
      icon: Icon,
      message: messageText,
    });
  }
  if (Array.isArray(args.message)) {
    return args.message
      .map(message => open({ ...args, message: message }))
      .flat()
      .filter(isString);
  }

  return renderNotification({ ...args, message: args.message as string });
};

/**
 * Open notification by specific status code.
 * @param {Status} status
 * @param {Record<Status, string>} variants
 * @return {NotificationReference | undefined}
 */
const openByStatus = (
  status: Status,
  variants: NotificationMessageByStatus,
): NotificationReference | undefined => {
  const type = STATUS__MAPPING__NOTIFICATION_TYPE[status];
  const message = variants[status];

  // dont show notification, if no message was passed for passed status
  if (!message) return;

  return open({
    type: type,
    message: message,
  });
};

/**
 * Close popup(s) by ids
 * @param {NotificationReference} keys
 */
const close = (keys: NotificationReference): void => {
  if (Array.isArray(keys)) keys.forEach(AntdNotification.close);
  else AntdNotification.close(keys);
};

/**
 * Handle request error
 * @param {Error} e
 * @param {(status: Status) => void} setStatus
 */
const requestError = (e: unknown, setStatus?: (status: Status) => void): void => {
  runIfNotAborted(e as Error, () => {
    const error: Error = e instanceof Error ? e : new Error('UNKNOWN_ERROR');
    console.error(e);
    open({ message: error.message, header: error.name, type: NotificationType.error });

    setStatus?.(Status.Error);
  });
};

export const notification = {
  open: open,
  openByStatus: openByStatus,
  close: close,
  requestError: requestError,
};

/**
 * Local function that calls library method to render a notification
 * @param {Omit<NotificationProps, "message"> & {message: string}} args
 * @return {string}
 */
const renderNotification = (
  args: Omit<NotificationProps, 'message'> & { message: string },
): string => {
  const key = nanoid();

  const AntdNotificationType =
    NOTIFICATION_TYPE__MAPPING__LIBRARY_NOTIFICATION_TYPE[
      args.type ?? DEFAULT_NOTIFICATION_TYPE
    ];

  const Icon: NotificationProps['icon'] =
    args.icon ??
    (args.type && NOTIFICATION_ICONS[args.type]) ??
    NOTIFICATION_ICONS[DEFAULT_NOTIFICATION_TYPE];

  AntdNotification.open({
    key: key,
    icon: Icon,
    message: args.header,
    description: args.message,
    type: AntdNotificationType,
    className: `type-${AntdNotificationType}`,
  });

  return key;
};
