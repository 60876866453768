import { createGlobalStyle } from 'styled-components';
import { assembleFontFileLocation } from 'common/assemblers';

export const GlobalStyleFont = createGlobalStyle`
  ${({ theme }) => {
    if (!theme.fontFamilyName || !theme.fontFamilyLocation) return '';
    const { fontFamilyName, fontFamilyLocation } = theme;

    const { extensions, weights, styles } = theme.fonts;

    const combinations: [typeof weights[0], typeof styles[0]][] = [];

    weights.forEach(weight => {
      styles.forEach(style => {
        combinations.push([weight, style]);
      });
    });

    return combinations
      .map<string>(([weight, style]) => {
        const urlsList = extensions.map(extension => {
          const url = assembleFontFileLocation(
            fontFamilyLocation,
            fontFamilyName,
            weight.fileNameSuffix,
            style.fileNameSuffix,
            extension.extension,
          );

          return `url("${url}") format("${extension.format}")`;
        });

        return `
        @font-face {
          font-family: "${fontFamilyName}";
          src: ${urlsList.join(',')};
          font-style: ${style.name};
          font-weight: ${weight.weightNumber};
        }`;
      })
      .join('\n');
  }}

  body {
    font-family: ${({ theme }) => {
      const list = [...theme.fonts.standardFontFamiliesList];
      if (theme.fontFamilyName) list.unshift(theme.fontFamilyName);
      return list.join(', ');
    }};
  }
`;
