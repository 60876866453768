import Icon from '@ant-design/icons';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields } from '@hypercharge/xdms-client/lib/configuration/types';
import {
  ConfigurationListItem,
  ConfigurationListItemFields,
  ConfigurationStatusListItemFields,
  /** @todo: replace with {@link ConfigurationListItemFields} */
  OfferItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Row } from 'antd';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { DATE_FORMAT, FILTER_SIGNS } from 'common/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { Button } from 'components/button';
import ConfigurationCopyModal from 'components/configuration/ConfigurationCopyModal';
import Container from 'components/container/Container';
import {
  FiltersList,
  SelectedFiltersItemsList_Filters,
} from 'components/filters/FiltersList';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { useSession } from 'context/session/AuthenticatedSessionProvider';
import { useModalState } from 'hooks/useModalState';
import Layout from 'layout/Default/Layout';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/es/interface';
import React, {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { notification, NotificationType } from 'utils/notification';
import { ChangeStatusModal } from './ChangeStatusModal';
import {
  ScColRight,
  ScConfigurationFilters,
  ScConfigurationFiltersRow,
  ScTable,
} from './ConfigurationList.styles';
import { useColumns } from './use-columns';
import ConfigurationNewVersionModal from '../../components/configuration/ConfigurationNewVersionModal';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import useOpenConfiguration from 'hooks/useOpenConfiguration';
import { Status } from 'utils/types';
import { useGlobalModalsStates } from 'context/globalModalsStates/GlobalModalsStatesProvider';

export type DateRange = [string, string];

export const DotsIcon = props => <Icon component={Dots} {...props} />;

export const columnKeys = {
  PUBLISH_STATE: ConfigurationListItemFields.completed,
  ID: ConfigurationListItemFields.id,
  NAME: ConfigurationListItemFields.name,
  CUSTOMER_NAME: ConfigurationListItemFields.customerName,
  CUSTOMER_LAST_NAME: ConfigurationListItemFields.customerName2,
  CUSTOMER_CONTACT: ConfigurationListItemFields.customerContact,
  DEALER: ConfigurationListItemFields.dealerId,
  END_USER: ConfigurationListItemFields.customer2Name,
  MODEL: ConfigurationListItemFields.modelId,
  ACTUAL_PRICE: ConfigurationListItemFields.actualPrice,
  STATUS: ConfigurationListItemFields.status,
  CREATION_DATE: ConfigurationListItemFields.createdAt,
  CREATED_FROM: ConfigurationListItemFields.dateCreatedFrom,
  CREATED_TO: ConfigurationListItemFields.dateCreatedTo,
  CREATED_BY: ConfigurationListItemFields.createdBy,
  CHANGED_BY: ConfigurationListItemFields.changedBy,
  TOKEN: ConfigurationListItemFields.importToken,
  STOCK_NUMBER: ConfigurationListItemFields.stockNumber,
  CALL_NAME: ConfigurationListItemFields.callName,
  WAREHOUSE_NUMBER: ConfigurationListItemFields.warehouseNumber,
  ACTIONS: 'actions',
};

export const ConfigurationList: FC = () => {
  const [filters, setFilters] = useState<
    SelectedFiltersItemsList_Filters<ConfigurationListItemFields>
  >([]);
  const { t } = useTranslation();
  const {
    getConfigurationStatusCodes,
    configurationStatusCodes,
    searchConfigurations,
    getConfigurationStatusList,
    setConfigurationStatus,
    copyConfiguration,
    createNewVersion,
  } = useConfiguration();
  const { handleNewConfiguration } = useSession();
  const { handleOpenConfiguration } = useOpenConfiguration();
  const { setConfigurationImportModalState } = useGlobalModalsStates();
  const { allowImportConfiguration, isFeatureEnabled } = useFeature();

  const configurationDetails = useSelector(
    configurationSelectors.getConfigurationAllDetails,
  );
  const status = useSelector(configurationSelectors.getConfigurationsListStatus);

  const isConfigurationImportFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationListImport,
  });

  const isConfigurationCreateFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationListCreateConfig,
  });

  const isImportButtonVisible = useMemo(
    () => allowImportConfiguration && isConfigurationImportFeatureEnabled,
    [allowImportConfiguration, isConfigurationImportFeatureEnabled],
  );

  const [stickyOffset, setStickyOffset] = useState<number>(0);
  const [configurations, setConfigurations] = useState<ConfigurationListItem[]>([]);

  const defaultPageSize = DEFAULT_TABLE_PAGE_SIZE;

  const handleConfigurationImport = useCallback(() => {
    setConfigurationImportModalState({ initialValues: {} });
  }, [setConfigurationImportModalState]);

  const [filterStatusCodeList, setFilterStatusCodeList] = useState<string[]>([]);
  const [dateCreated, setDateCreated] = useState<DateRange | null>(null);

  const tableTitles = useMemo(() => {
    return {
      [columnKeys.PUBLISH_STATE]: t('CONFIC_LIST_PUBLISH_STATE'),
      [columnKeys.ID]: t('CONFIG_LIST_NUMBER'),
      [columnKeys.NAME]: t('CONFIG_LIST_NAME'),
      [columnKeys.CUSTOMER_NAME]: t('CONFIG_LIST_CUSTOMER_INFO'),
      [columnKeys.CUSTOMER_LAST_NAME]: t('CONFIG_LIST_CUSTOMER_LAST_NAME'),
      [columnKeys.CUSTOMER_CONTACT]: t('CONFIG_LIST_CUSTOMER_CONTACT'),
      [columnKeys.DEALER]: t('CONFIG_LIST_DEALER'),
      [columnKeys.END_USER]: t('CLIENTS_END_USER'),
      [columnKeys.MODEL]: t('MODELS'),
      [columnKeys.ACTUAL_PRICE]: t('CONFIG_LIST_ACTUAL_VALUE'),
      [columnKeys.STATUS]: t('STATUS'),
      [columnKeys.CREATION_DATE]: t('CREATION_DATE'),
      [columnKeys.CREATED_FROM]: t('FROM'),
      [columnKeys.CREATED_TO]: t('TO'),
      [columnKeys.CREATED_BY]: t('CREATED_BY'),
      [columnKeys.CHANGED_BY]: t('CHANGED_BY'),
      [columnKeys.TOKEN]: t('TOKEN'),
      [columnKeys.STOCK_NUMBER]: t('CONFIGURATION_STOCK_NUMBER'),
      [columnKeys.CALL_NAME]: t('CONFIGURATION_CALL_NAME'),
      [columnKeys.WAREHOUSE_NUMBER]: t('CONFIGURATION_WAREHOUSE_NUMBER'),
      [columnKeys.ACTIONS]: t('ACTIONS'),
    };
  }, [t]);

  const getOffersList = useCallback(async () => {
    const filteredConfigurations = await searchConfigurations(filters);
    setConfigurations(filteredConfigurations);
  }, [filters, searchConfigurations]);

  const [changeStatusModalState, handleChangeStatusModal] = useModalState<
    ComponentProps<typeof ChangeStatusModal>,
    Record<string, never>,
    ComponentProps<typeof ChangeStatusModal>
  >({});

  const handleChangeConfigurationStatus = useCallback(
    async (args: {
      configurationNumber: string | number;
      statusId: string;
      reasonId?: string;
      reasonText?: string;
      date?: string;
    }) => {
      // todo: ugly, but quick
      const statusList = await getConfigurationStatusList({
        configurationNumber: args.configurationNumber,
        sellDate: null,
      });

      const statusItem = get(statusList, 'statuses', []).find(
        statusListItem => statusListItem.id === args.statusId,
      );
      let reasonItem = get(statusList, 'reasons', []).find(
        statusListItem => statusListItem.id === args.reasonId,
      );

      if (!reasonItem) reasonItem = { id: '', text: '' };
      if (args.reasonText) reasonItem.text = args.reasonText;

      try {
        await setConfigurationStatus({
          configurationNumber: args.configurationNumber,
          status: statusItem,
          reason: reasonItem,
          sellDate: args.date ?? null,
        });

        handleChangeStatusModal(undefined);
        await getOffersList();
      } catch (e) {
        notification.open({
          message: t('STATUS_NOT_UPDATED'),
          type: NotificationType.error,
        });
      }
    },
    [
      getConfigurationStatusList,
      getOffersList,
      handleChangeStatusModal,
      setConfigurationStatus,
      t,
    ],
  );

  const handleShowChangeStatusModal = useCallback(
    async (configurationNumber: string | number, statusId: string) => {
      const statusList = await getConfigurationStatusList({
        configurationNumber: configurationNumber,
        sellDate: null,
      });

      handleChangeStatusModal({
        configurationNumber,
        statusList: statusList?.statuses ?? [],
        reasonList: statusList?.reasons ?? [],
        onClose: () => handleChangeStatusModal(undefined),
        onSubmit: handleChangeConfigurationStatus,
        // not all values filled, it is proper behavior
        initialValues: { statusId: statusId },
      });
    },
    [
      getConfigurationStatusList,
      handleChangeConfigurationStatus,
      handleChangeStatusModal,
    ],
  );

  const [configurationCopyModalState, handleConfigurationCopyModal] = useModalState<
    ComponentProps<typeof ConfigurationCopyModal>,
    Record<string, never>,
    ComponentProps<typeof ConfigurationCopyModal>
  >({});

  const handleCreateCopy = useCallback(
    async (
      configurationNumber: number,
      values: { language: string; quantity: number },
    ) => {
      const response = await copyConfiguration({
        configurationNumber: configurationNumber,
        languageCode: values.language,
        copiesQuantity: values.quantity,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationCopyModal(undefined);

      await getOffersList();
    },
    [copyConfiguration, getOffersList, handleConfigurationCopyModal],
  );

  const handleClickCreateCopy = useCallback(
    (configurationNumber: number) => {
      handleConfigurationCopyModal({
        defaultLanguageCode:
          configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
        onSubmit: values => handleCreateCopy(configurationNumber, values),
        onClose: () => handleConfigurationCopyModal(undefined),
      });
    },
    [configurationDetails, handleCreateCopy, handleConfigurationCopyModal],
  );

  const [configurationNewVersionModalState, handleConfigurationNewVersionModal] =
    useModalState<
      ComponentProps<typeof ConfigurationNewVersionModal>,
      Record<string, never>,
      ComponentProps<typeof ConfigurationNewVersionModal>
    >({});

  const handleCreateNewVersion = useCallback(
    async (configurationNumber: number, values: { language: string }) => {
      if (!configurationNumber) return;

      const response = await createNewVersion({
        configurationNumber: configurationNumber,
        languageCode: values.language,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationNewVersionModal(undefined);

      await getOffersList();
    },
    [createNewVersion, getOffersList, handleConfigurationNewVersionModal],
  );

  const handleClickCreateNewVersion = useCallback(
    (configurationNumber: number) => {
      handleConfigurationNewVersionModal({
        defaultLanguageCode:
          configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
        onSubmit: values => handleCreateNewVersion(configurationNumber, values),
        onClose: () => handleConfigurationNewVersionModal(undefined),
      });
    },
    [handleConfigurationNewVersionModal, configurationDetails, handleCreateNewVersion],
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm({ closeDropdown: true });
    const preparedFilters = filters.filter(filter => get(filter, 'name') !== dataIndex);
    const filterValue = get(selectedKeys, 0, '');
    const sign =
      dataIndex === OfferItemFields.ID ? FILTER_SIGNS.EQUAL : FILTER_SIGNS.INCLUDES;
    const filter = {
      name: dataIndex,
      value: get(selectedKeys, 0, ''),
      sign,
    };

    if (filterValue) {
      preparedFilters.push(filter);
    }

    setFilters(preparedFilters);
  };

  const handleReset = useCallback(
    dataIndex => {
      const { dateCreatedFrom, dateCreatedTo, createdAt } = ConfigurationListItemFields;
      let updatedFilters = filters.filter(filter => filter.name !== dataIndex);

      if (dataIndex === OfferItemFields.statusCode) {
        setFilterStatusCodeList([]);
      }

      if (
        dataIndex === dateCreatedTo ||
        dataIndex === dateCreatedFrom ||
        dataIndex === createdAt
      ) {
        setDateCreated(null);
        updatedFilters = filters
          .filter(filter => filter.name !== dateCreatedTo)
          .filter(filter => filter.name !== dateCreatedFrom);

        setFilters(updatedFilters);
      }

      setFilters(updatedFilters);
    },
    [filters],
  );

  const onCreationDateChange = (
    momentDate: RangeValue<moment.Moment>,
    date: DateRange,
  ) => {
    setDateCreated(date);
  };

  const onFilterClear = () => {
    setFilterStatusCodeList([]);
    setDateCreated(null);
    setFilters([]);
  };

  const onCreationDateSubmit = useCallback(
    (selectedKeys, confirm) => {
      const { dateCreatedFrom, dateCreatedTo } = ConfigurationListItemFields;
      const preparedFilters = filters
        .filter(filter => filter.name !== dateCreatedTo)
        .filter(filter => filter.name !== dateCreatedFrom);

      const currentDateCreatedFrom = get(dateCreated, 0);
      const currentDateCreatedTo = get(dateCreated, 1);

      if (!currentDateCreatedFrom || !currentDateCreatedTo) {
        setFilters(preparedFilters);
      } else {
        setFilters([
          ...preparedFilters,
          {
            name: dateCreatedFrom,
            sign: FILTER_SIGNS.EQUAL,
            value: currentDateCreatedFrom,
          },
          {
            name: dateCreatedTo,
            sign: FILTER_SIGNS.EQUAL,
            value: currentDateCreatedTo,
          },
        ]);
      }
      confirm({ closeDropdown: true });
    },
    [dateCreated, filters],
  );

  const getRangePickerValue = useMemo((): RangeValue<Moment> | undefined => {
    const dateCreatedFrom = get(dateCreated, 0);
    const dateCreatedTo = get(dateCreated, 1);
    return dateCreatedFrom && dateCreatedTo
      ? [moment(dateCreatedFrom, DATE_FORMAT), moment(dateCreatedTo, DATE_FORMAT)]
      : undefined;
  }, [dateCreated]);

  const onEditConfiguration = useCallback(
    async configuration => {
      const configurationNumber: number = get(configuration, OfferItemFields.ID);

      await handleOpenConfiguration(configurationNumber);
    },
    [handleOpenConfiguration],
  );

  const onStatusChange = useCallback((value: string[]) => {
    setFilterStatusCodeList(value);
  }, []);

  const onStatusSubmit = (selectedKeys, confirm) => {
    const updatedFilters = filters.filter(
      filter => get(filter, 'name') !== ConfigurationListItemFields.status,
    );
    const displayValue = filterStatusCodeList.map(statusCode => {
      const status = configurationStatusCodes?.find(
        item => item[ConfigurationStatusListItemFields.code] === statusCode,
      );
      return status?.[ConfigurationStatusListItemFields.name] ?? t('STATUS_UNKNOWN');
    });

    if (filterStatusCodeList.length) {
      updatedFilters.push({
        name: ConfigurationListItemFields.status,
        value: filterStatusCodeList,
        displayValue: displayValue.join(', '),
        sign: FILTER_SIGNS.EQUAL,
      });
    }
    confirm({ closeDropdown: true });
    setFilters(updatedFilters);
  };

  useEffect(() => {
    getConfigurationStatusCodes();
  }, [getConfigurationStatusCodes]);

  useEffect(() => {
    getOffersList();
  }, [getOffersList]);

  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById('layout-top-section');
      if (!element) return;
      setStickyOffset(element.clientHeight);
    };

    // on init
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tableColumns = useColumns({
    tableTitles,
    filters,
    handleSearch,
    handleReset,
    configurationStatusCodes,
    filterStatusCodeList: filterStatusCodeList,
    onStatusSubmit,
    onStatusChange,
    onCreationDateSubmit,
    onCreationDateChange,
    getRangePickerValue,
    onEditConfiguration,
    handleShowChangeStatusModal,
    handleShowCopyConfigurationModal: handleClickCreateCopy,
    handleCreateNewVersion: handleClickCreateNewVersion,
  });

  return (
    <Layout withSteps={false} bg={false}>
      <Container>
        {changeStatusModalState && <ChangeStatusModal {...changeStatusModalState} />}

        <ScConfigurationFilters data-testid="configurations-page-container">
          <ScConfigurationFiltersRow gutter={16}>
            <Col>
              <FiltersList
                tableTitles={tableTitles}
                filters={filters}
                onClearOne={handleReset}
                onClearAll={onFilterClear}
              />
            </Col>
            {(isImportButtonVisible || isConfigurationCreateFeatureEnabled) && (
              <ScColRight>
                <Row gutter={16}>
                  {isImportButtonVisible && (
                    <Col>
                      <Button
                        onClick={handleConfigurationImport}
                        data-testid="configurations-page-import-config-btn"
                      >
                        {t('CONFIGURATION_IMPORT')}
                      </Button>
                    </Col>
                  )}
                  {isConfigurationCreateFeatureEnabled && (
                    <Col>
                      <Button
                        onClick={handleNewConfiguration}
                        variant="primary"
                        data-testid="configurations-page-create-new-config-btn"
                      >
                        {t('CONFIG_LIST_CREATE_CONFIG')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </ScColRight>
            )}
          </ScConfigurationFiltersRow>
        </ScConfigurationFilters>
        <ScTable
          loading={status === Status.Loading}
          rowKey={record => get(record, OfferItemFields.ID)}
          columns={tableColumns}
          data={configurations}
          pageSize={defaultPageSize}
          scroll={{ x: 2200 }}
          rowClassName="configration-list__row"
          sticky={{
            // header height in px
            offsetHeader: stickyOffset,
          }}
          pagination
        />
      </Container>

      {configurationNewVersionModalState && (
        <ConfigurationNewVersionModal {...configurationNewVersionModalState} />
      )}
      {configurationCopyModalState && (
        <ConfigurationCopyModal {...configurationCopyModalState} />
      )}
    </Layout>
  );
};
