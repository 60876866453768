import {
  CategoryModelItem,
  OptionalModelItem,
} from 'context/tableCategories/TableCategoriesProvider';
import { get } from 'utils';

interface GetUniqueCategoryFilterKeyParams {
  category: CategoryModelItem | OptionalModelItem;
  idFlag: string;
}

/**
 * Gets the ID of the main category
 * @param {GetUniqueCategoryFilterKeyParams} params
 * @return {string}
 */
export const getUniqueCategoryFilterKey = (
  params: GetUniqueCategoryFilterKeyParams,
): string => {
  const { category, idFlag } = params;
  return get(category, idFlag, '');
};
