import styled from 'styled-components';

export const ScLoader = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999;

  height: 100%;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.5);

  text-align: center;

  font-size: 1.8em;

  display: flex;
  align-items: center;
  justify-content: center;
`;
