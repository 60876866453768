import {
  Configuration_GetDetailsById_Output,
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields,
  Configuration_GetDetailsById_Output_Relation_Fields as ConfigurationRelationFields,
  CONFIGURATION_PRIMARY_CONTACT_MARK,
  Model,
  ModelFields,
  ModelImageFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonItem,
  TtParam,
  TtParamFields,
  WebImageItemFields,
  ModelPackageLine,
} from '@hypercharge/xdms-client/lib/types';
import { ContactFields, ContactItem, CustomerItem, CustomerFields } from 'types/vendor';
import {
  MAX_TEXT_LENGTH_IN_TABLE,
  TERMS_AND_CONDITIONS_PAGE_URL,
} from 'common/constants';
import { PAGES_SETTINGS } from 'context/tableCategories/TableCategoriesProvider';
import { get, isEqual, pick, reduce } from 'utils';
import { Pagination } from '../../components/table/Pagination';
import { getPackageLines, isPackage } from '../../pages/DynamicPage/utils';
import { checkWhetherRecordContainsString } from '../check-whether-record-contains-string';
import { ComponentProps } from 'react';

export * from './price';
export * from './mergeCurrentAndNewRecord';

export const mapToTableFormat = <T>(data: T[], key: string): Array<any> =>
  data.map(item => ({
    key: item[key],
    ...item,
  }));

export const extractItemFields = (
  items: Array<any> | null = [],
  key: string,
): string[] | number[] => {
  const result: string[] = [];

  reduce(
    items || [],
    (acc, item) => {
      const itemField = get(item, key);
      if (itemField) {
        acc.push(itemField);
      }

      return acc;
    },
    result,
  );

  return result;
};

export const getTtParams = (ttParams): { ttParam: TtParam[] } => {
  const ttParam: TtParam[] = [];

  reduce(
    ttParams,
    (acc, item, key) => {
      acc.push({
        [TtParamFields.name]: key,
        [TtParamFields.value]: item,
      });

      return acc;
    },
    ttParam,
  );

  return {
    ttParam,
  };
};

interface SortTableDataBySearchValueParams<T extends Record<string, unknown>> {
  data: T[];
  searchValue?: string | undefined | null;
  filteringKeys?: string[];
  packageLines?: ModelPackageLine[];
}
/** @todo: add trim to searchValue */
export const sortTableDataBySearchValue = <
  T extends Record<string, unknown> = Model_CommonItem,
>({
  data,
  searchValue,
  filteringKeys,
  packageLines,
}: SortTableDataBySearchValueParams<T>): T[] => {
  if (!data) return [];
  if (!searchValue) return data;

  return data.filter(record => {
    const checkResults: boolean[] = [];
    const recordToSearchIn = filteringKeys?.length
      ? (pick(record, filteringKeys) as T)
      : record;

    const isRecordFits = checkWhetherRecordContainsString(recordToSearchIn, searchValue);
    checkResults.push(isRecordFits);

    if (isPackage(record) && packageLines?.length) {
      let lines = getPackageLines({
        model: {
          [ModelFields.packageLines]: packageLines,
        } as Model,
        packageRecord: record as Model_CommonItem,
      });

      if (filteringKeys?.length) {
        lines = lines.map(record => pick(record, filteringKeys) as ModelPackageLine);
      }

      checkResults.push(
        lines.some(line => checkWhetherRecordContainsString(line, searchValue)),
      );
    }

    return checkResults.includes(true);
  });
};

interface UpdatePaginationParams {
  defaultPagination: ComponentProps<typeof Pagination>;
  page: number;
  data: unknown[];
  pageSize: number;
  totalCount: number;
}
export const getUpdatedPagination = (
  params: UpdatePaginationParams,
): ComponentProps<typeof Pagination> => {
  const { defaultPagination, page, pageSize, totalCount } = params;
  return {
    ...defaultPagination,
    current: page,
    total: totalCount,
    from: (page - 1) * pageSize + 1,
    to: Math.min(page * pageSize, totalCount),
    pageSize: pageSize,
  };
};

export const getModelImage = (model: Model | null): string => {
  const imageLink = get(model, `${ModelFields.model}.0.${ModelImageFields.sysLink}`, '');
  const webImages = get(model, ModelFields.webImage, []);

  const filteredImages = webImages.filter(
    image => get(image, ModelImageFields.sysLink) === imageLink,
  );

  if (filteredImages.length > 1) {
    const sortedImages = filteredImages.sort(
      (a, b) =>
        get(a, WebImageItemFields.orderNumber, 0) -
        get(b, WebImageItemFields.orderNumber, 0),
    );
    return get(sortedImages, `0.${WebImageItemFields.url}`, '');
  }

  return get(filteredImages, `0.${WebImageItemFields.url}`, '');
};

export const getModelUpdateAttributes = (
  stepId: keyof typeof PAGES_SETTINGS,
): ModelUpdateAttributesObjectFields => {
  if (stepId === TERMS_AND_CONDITIONS_PAGE_URL) {
    return ModelUpdateAttributesObjectFields.termsAndConditions;
  } else {
    return ModelUpdateAttributesObjectFields.optionOrAccessory;
  }
};

/**
 * The "\n" character is used to split a string into two values.
 * The name and description of the note are stored in two different variables.
 * However, these variables must be displayed in one field.
 * So the function is designed to split a string into two values
 */
export const splitRemark = (
  remarkText: string,
): { title: string; description: string } => {
  const title = get(remarkText.split('\n'), 0, '');
  const description =
    remarkText.indexOf('\n') > -1
      ? remarkText.substring(remarkText.indexOf('\n') + 1)
      : '';
  return { title, description };
};

/**
 * Function for collecting two values into one
 */
export const notesFieldsBuilder = (title: string, description: string): string => {
  const separator = title && description ? '\n' : '';
  return `${title}${separator}${description}`;
};

/**
 * Checking customer information changes
 *
 * @param configurationDetails {Configuration_GetDetailsById_Output | null}
 * @param customer current client/endUser details {CustomerItem | null}
 * @param contact current client/endUser main contact {ContactItem | null}
 * @param relatedContacts currenct client/endUser additional contacts {ContactItem[] | null}
 * @param customerType {client | endUser}
 * @returns boolean
 */
export const checkChangeConfigData = (
  configurationDetails: Configuration_GetDetailsById_Output | null,
  customer: CustomerItem | null,
  contact: ContactItem | null,
  relatedContacts: ContactItem[] | null,
  customerType: 'client' | 'endUser',
): boolean => {
  const configuration = get(configurationDetails, 'configuration');
  const relations = get(configurationDetails, 'relations', []);

  const configurationNumber = get(
    configuration,
    ConfigurationDetailsFields.configurationNumber,
  );

  if (!configurationNumber) {
    return false;
  }

  let customerFieldType = ConfigurationDetailsFields.customerNumber;
  let oldClientPrimaryContactId: number | undefined;

  if (customerType === 'client') {
    oldClientPrimaryContactId = get(
      configuration,
      ConfigurationDetailsFields.contactNumber,
    );
  } else {
    customerFieldType = ConfigurationDetailsFields.customerNumber2;

    const oldClientPrimaryContactObject = relations.find(
      relation =>
        relation[ConfigurationRelationFields.typeCode] ===
          ConfigurationDetailsFields.customerNumber2 &&
        relation[ConfigurationRelationFields.priority] ===
          CONFIGURATION_PRIMARY_CONTACT_MARK,
    );
    oldClientPrimaryContactId = get(
      oldClientPrimaryContactObject,
      ConfigurationRelationFields.contactNumber,
    );
  }

  const oldClientNumber = get(configuration, customerFieldType);

  const oldClientRelatedContacts = relations.filter(
    relation =>
      relation[ConfigurationRelationFields.typeCode] === customerFieldType &&
      relation[ConfigurationRelationFields.priority] !==
        CONFIGURATION_PRIMARY_CONTACT_MARK,
  );
  const oldClientRelatedContactsIds: number[] | string[] = extractItemFields(
    oldClientRelatedContacts,
    ConfigurationRelationFields.contactNumber,
  );
  const clientRelatedContactsIds: number[] | string[] = extractItemFields(
    relatedContacts,
    ContactFields.ID,
  );

  const isClientNumberChanged = oldClientNumber !== get(customer, CustomerFields.id, 0);
  const isContactNumberChanged =
    oldClientPrimaryContactId !== get(contact, ContactFields.ID);
  const isRelatedContactsChanged = !isEqual(
    oldClientRelatedContactsIds,
    clientRelatedContactsIds,
  );

  return isClientNumberChanged || isContactNumberChanged || isRelatedContactsChanged;
};

export const longTextFormat = (
  text: string,
  maxLength: number = MAX_TEXT_LENGTH_IN_TABLE,
): string => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}... ` : text;
};

export const customerContactFormatName = (
  firstName: string | undefined,
  secondName: string | undefined,
): string => {
  return [firstName, secondName].filter(Boolean).join(' ');
};

/**
 * half up round with possible to set precision
 * @param value rounding number
 * @param maxSymbolsAfterDecimal  maximum symbols after decimal
 * @returns number
 */
export const halfUpRound = (value: number, maxSymbolsAfterDecimal = 2): number => {
  const precision = Math.pow(10, maxSymbolsAfterDecimal);
  return Math.round(value * precision) / precision;
};
