import { CURRENCIES } from 'common/constants';
import { isNil } from '../index';

export type Style = 'decimal' | 'currency' | 'percent';

const DEFAULT_LOCALE = 'en-ES';

/** Separator used on BE */
const DECIMAL_SEPARATOR_BE = '.';

export const localizeFormatPriceRules = (
  locale: string,
): { decimals: string; thousands: string } => {
  const rawThousandSeparator = (1000).toLocaleString(locale).substring(1, 2);
  return {
    decimals: (1.1).toLocaleString(locale).substring(1, 2),
    thousands: /\d/.test(rawThousandSeparator) ? '' : rawThousandSeparator,
  };
};

export const formatNumber = (
  number: string | number,
  locale: string,
  options?: { addThousandsSeparator?: boolean; symbolsAfterDecimal?: number },
): string => {
  let [leftSide, rightSide] = String(number).split(DECIMAL_SEPARATOR_BE);
  const separators = localizeFormatPriceRules(locale);

  {
    const { addThousandsSeparator, symbolsAfterDecimal } = options ?? {};

    // next operation is here just to correctly round up symbols after decimal
    const [, roundedRightSide] = Number(`1.${rightSide}`)
      .toLocaleString(locale, {
        minimumFractionDigits: symbolsAfterDecimal,
        maximumFractionDigits: symbolsAfterDecimal,
      })
      .split(separators.decimals);

    rightSide = roundedRightSide;

    if (addThousandsSeparator) {
      leftSide = leftSide.replace(/\B(?=(\d{3})+(?!\d))/g, separators.thousands);
    }
  }

  rightSide = rightSide ? separators.decimals + rightSide : '';

  return `${leftSide}${rightSide}`;
};

interface FormatPriceParams {
  price: number;
  locale: string;
  currency?: string;
  style?: Style;
  symbolsAfterDecimal?: number;
}
export const formatPrice = ({
  price,
  locale,
  currency,
  style = 'currency',
  symbolsAfterDecimal = 2,
}: FormatPriceParams): string => {
  const language = process.env.NODE_ENV === 'test' ? DEFAULT_LOCALE : locale;
  let value = price ?? 0; // It would check if price is not null and undefined. Default value used to just check if value is not undefined, but it can be null sometimes.
  if (style === 'percent') {
    value = value / 100;
  }
  return value.toLocaleString(language, {
    style,
    currency: style === 'currency' && !currency ? CURRENCIES[0].value : currency,
    minimumFractionDigits: symbolsAfterDecimal,
    maximumFractionDigits: symbolsAfterDecimal,
  });
};

interface NumberWithCurrencySymbolParserParams {
  value: string | undefined;
  locale: string;
  prefix?: string;
  symbolsAfterDecimal?: number;
  suffix?: string;
}
export const numberWithCurrencySymbolParser = ({
  value,
  locale,
  symbolsAfterDecimal = 999, // take all of them
  prefix = '',
  suffix = '',
}: NumberWithCurrencySymbolParserParams): number => {
  if (!value) return 0;

  const separators = localizeFormatPriceRules(locale);
  const s_d = separators.decimals;

  let result = value;
  result = removePrefixSuffix(value, prefix, suffix);
  // remove all except digits and decimalSeparator
  result = result.replace(new RegExp(`[^0-9${s_d}]`, 'g'), '');
  // remove symbols after decimalSeparator according to symbolsAfterDecimal
  {
    // Had to use plain js transformations instead of RegExp below
    // as `positive lookbehind` doesn't work yet for safari: https://caniuse.com/js-regexp-lookbehind
    // .replace(new RegExp(`(?<=\\${s_d}\\d{${symbolsAfterDecimal}}).+`, 'g'), '')
    // eslint-disable-next-line prefer-const
    let [left, right] = result.split(s_d);
    if (right) {
      right = right.substring(0, symbolsAfterDecimal);
      result = `${left}${s_d}${right}`;
    }
  }

  result = result.replace(s_d, DECIMAL_SEPARATOR_BE);

  return Number(result);
};

// https://observablehq.com/@mbostock/localized-number-parsing
interface NumberWithCurrencySymbolDynamicFormatterParams {
  value: string | number | undefined;
  /** Used to fix edge case bug: when user inputs number like '123.' the point is being removed by parser, so its being lost */
  rawValue?: string;
  locale: string;
  prefix?: string;
  symbolsAfterDecimal?: number;
  suffix?: string;
  min?: number;
}
export const numberWithCurrencySymbolDynamicFormatter = ({
  value,
  locale,
  prefix = '',
  min = 0,
  suffix = '',
  symbolsAfterDecimal = 2,
  rawValue,
}: NumberWithCurrencySymbolDynamicFormatterParams): string => {
  if (!value) return addPrefixSuffix('0', prefix, suffix);

  const separators = localizeFormatPriceRules(locale);
  const s_d = separators.decimals;

  let result = value
    .toString()
    // replace all except numbers,minus sign and decimal separator
    .replace(new RegExp(`[^0-9-.]`, 'g'), '');

  // quantity of decimals after delimeter
  // used to preserve amount of decimals in resulting string
  const decimalsQuantityToKeep: number | undefined = result.split('.')?.[1]?.length;

  // replace '-' if value cant be negative
  if (min >= 0) result = result.replace('-', '');

  result = parseFloat(result).toLocaleString(locale, {
    minimumFractionDigits: symbolsAfterDecimal,
    maximumFractionDigits: symbolsAfterDecimal,
  });

  // handle right side changes
  {
    // eslint-disable-next-line prefer-const
    let [leftSide, rightSide] = result.split(s_d);
    let delimeter = '';

    if (isNil(decimalsQuantityToKeep)) {
      rightSide = '';

      const rawValueWithoutPrefixSuffix = rawValue
        ? removePrefixSuffix(rawValue, prefix, suffix)
        : undefined;
      if (!isNil(rawValueWithoutPrefixSuffix)) {
        const [, rawRightSide] = rawValueWithoutPrefixSuffix.split(s_d);

        // preserves delimeter and zeros after delimeter
        if (!isNil(rawRightSide) && /0*/g.test(rawRightSide)) {
          delimeter = s_d;
          rightSide = rawRightSide.slice(0, symbolsAfterDecimal);
        }
      }
    } else {
      // in case some numbers were after delimeter
      delimeter = s_d;
      rightSide = rightSide.substring(0, decimalsQuantityToKeep);
    }

    result = `${leftSide}${delimeter}${rightSide}`;
  }

  return addPrefixSuffix(result, prefix, suffix);
};

export const addPrefixSuffix = (
  value: string,
  prefix: string,
  suffix: string,
): string => {
  return `${prefix}${value}${suffix}`;
};

export const removePrefixSuffix = (
  value: string,
  prefix: string,
  suffix: string,
): string => {
  return value.replace(prefix, '').replace(suffix, '');
};
