import React, { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import FormField from './FormField';
import Selector from 'components/form/Selector';
import { useFormikContext } from 'formik';
import { citySelectors } from 'store';
import { useSelector } from 'react-redux';
import { CityFields } from 'types/vendor';
import { Status } from 'utils/types';
import { useCity } from 'context/city/useCity';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { debounce } from 'utils';
import { FILTER_SIGNS } from 'common/constants';

const DEFAULT_ROW_SPACES: [number, number] = [16, 0];
export const ZIP_CODE_SEPARATOR = ' - ';

// eslint-disable-next-line prettier/prettier
const FormFieldProxy = FormField<typeof Selector>;
type SelectorProps = ComponentProps<typeof FormFieldProxy> & { 'data-testid'?: string; };
type SelectorInputProps = Omit<SelectorProps, 'name' | 'options' | 'component'> & {
  name: Exclude<SelectorProps['name'], undefined>;
  options?: SelectorProps['options'] | undefined;
};

interface Props {
  gutter?: ComponentProps<typeof Row>['gutter'];
  inputZipProps: SelectorInputProps;
  inputCityProps: SelectorInputProps;
}

export const InputCityZipField: React.FC<Props> = ({
  inputZipProps,
  inputCityProps,
  gutter,
}) => {
  const { getCitiesList } = useCity();

  const { setFieldValue } = useFormikContext();

  const { citiesList, citiesListStatus } = useSelector(citySelectors.getAll);
  const zipOptions = useMemo(() => {
    return mapToSelectOptions(citiesList, {
      label: [CityFields.zipNumber, CityFields.cityName],
      value: CityFields.zipNumber,
      separator: ZIP_CODE_SEPARATOR,
    });
  }, [citiesList]);

  const cityOptions = useMemo(() => {
    return mapToSelectOptions(citiesList, {
      label: CityFields.cityName,
      value: CityFields.cityName,
    });
  }, [citiesList]);

  // debounce confuses linter by unknown deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchZip = useCallback<(value: string) => void>(
    debounce(async (value: string): Promise<void> => {
      await getCitiesList([
        { name: CityFields.zipNumber, value, sign: FILTER_SIGNS.EQUAL },
      ]);
    }, 1000),
    [getCitiesList],
  );

  // debounce confuses linter by unknown deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchCity = useCallback<(value: string) => void>(
    debounce(async (value: string): Promise<void> => {
      await getCitiesList([
        { name: CityFields.cityName, value, sign: FILTER_SIGNS.EQUAL },
      ]);
    }, 1000),
    [getCitiesList],
  );

  useEffect(() => {
    getCitiesList();
  }, [getCitiesList]);

  const onZipChange = useCallback(
    (value: string) => {
      const entity = citiesList.find(record => record[CityFields.zipNumber] === value);

      if (!entity) return;
      setFieldValue(inputZipProps.name, entity[CityFields.zipNumber]);
      setFieldValue(inputCityProps.name, entity[CityFields.cityName]);
    },
    [citiesList, inputCityProps.name, inputZipProps.name, setFieldValue],
  );

  const onCityChange = useCallback(
    (value: string) => {
      const entity = citiesList.find(record => record[CityFields.cityName] === value);
      if (!entity) return;
      setFieldValue(inputZipProps.name, entity[CityFields.zipNumber]);
      setFieldValue(inputCityProps.name, entity[CityFields.cityName]);
    },
    [citiesList, inputCityProps.name, inputZipProps.name, setFieldValue],
  ); 

  return (
    <Row gutter={gutter ?? DEFAULT_ROW_SPACES}>
      <Col md={8}>
        <FormField
          component={Selector}
          options={zipOptions}
          onChange={event => onZipChange(event.target.value)}
          showSearch
          onSearch={debouncedSearchZip}
          loading={citiesListStatus === Status.Loading}
          {...inputZipProps}
        />
      </Col>
      <Col md={16}>
        <FormField
          component={Selector}
          options={cityOptions}
          onChange={event => onCityChange(event.target.value)}
          showSearch
          onSearch={debouncedSearchCity}
          loading={citiesListStatus === Status.Loading}
          {...inputCityProps}
        />
      </Col>
    </Row>
  );
};
