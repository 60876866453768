import { get, omit, uniq } from 'utils';
import { ContactFields, CustomerFields } from 'types/vendor';
import { ConfigurationRelation } from '../../context/relations/types';
import { UrlTransform } from '../../utils/urlTransform';
import { TFunction } from 'i18next';
import { DocumentsTreeData } from 'hooks/useDocumentsTree';
import { DocumentRelatedEntityCode } from 'context/document/types';
import { EMAIL_ALLOWED_VALUE } from 'common/constants';

/**
 * Group up emails for filling cc and primary email
 * If isContactsEnabled===false: should use first relations customer email as primary
 * If isContactsEnabled===true: should use first relations primary contact email as primary
 * @param {ConfigurationRelation[]} relations
 * @param {boolean} isContactsEnabled
 * @param {boolean} shouldSkipNoMail
 * @return {{cc: string[], primary: string}}
 */
export const groupRelationsEmails = (
  relations: ConfigurationRelation[],
  isContactsEnabled: boolean,
  shouldSkipNoMail: boolean,
): { cc: string[]; primary: string } => {
  let primaryEmail = '';
  let ccEmails: string[] = [];

  if (isContactsEnabled) {
    const [relation] = relations;

    if (relation) {
      const { primaryContact, additionalContacts } = relation;

      primaryEmail = get(primaryContact, CustomerFields.email, '');

      const additionalContactsEmails = additionalContacts.map<string>(contact =>
        get(contact, ContactFields.email, ''),
      );

      ccEmails = additionalContactsEmails;
    }
  } else {
    relations.forEach(({ customer }, idx) => {
      const shouldBeUsedAsMainEmail = idx === 0;

      const email = get(customer, CustomerFields.email, '');

      if (shouldBeUsedAsMainEmail) {
        primaryEmail = email;
      }
    });
  }

  ccEmails = uniq(ccEmails)
    .filter(Boolean)
    .filter(email => email !== primaryEmail)
    .filter(email => (shouldSkipNoMail ? email !== EMAIL_ALLOWED_VALUE : true));

  return {
    primary: primaryEmail,
    cc: ccEmails,
  };
};

export type MailtoUrls = { url: string; urlNoBody: string; body: string };

/** Space */
const SP = '&#160;';

const getSpaces = (num: number): string => Array(num).fill(SP).join('');

export const getCustomEmailMailtoUrl = (
  relations: ConfigurationRelation[],
  isContactsEnabled: boolean,
  shouldSkipNoMail: boolean,
  configurationModelName: string | null,
  documentsTree: DocumentsTreeData,
  t: TFunction,
  lng: string,
): MailtoUrls => {
  const emails = groupRelationsEmails(relations, isContactsEnabled, shouldSkipNoMail);

  let body = '';

  documentsTree.forEach(entity => {
    body += `<h4>${t(entity.tKey)}:</h4>`;

    entity.relatedEntities.forEach(entityItem => {
      const isConfiguration =
        entity.relatedEntityCode === DocumentRelatedEntityCode.configuration;
      if (!isConfiguration) {
        body += `<h5>${getSpaces(2)}${
          entityItem.relatedEntity?.[entity.nameField]
        }:</h5>`;
      }

      entityItem.documents.forEach(document => {
        body += `${getSpaces(isConfiguration ? 2 : 4)}<a href="${document.fileUrl}">${
          document.name
        }</a> </br>`;
      });
    });
  });

  const params: Record<string, unknown> = {
    subject: t('DOCUMENT_SEND_EMAIL_SUBJECT', { configurationModelName, lng: lng }),
    body: body,
  };

  if (emails.cc.length) params.cc = emails.cc.join(';');

  const query = UrlTransform.stringifyQuery(params, { encode: false });
  const queryNoBody = UrlTransform.stringifyQuery(omit(params, 'body'), {
    encode: false,
  });

  return {
    url: `mailto:${emails.primary}?${query}`,
    urlNoBody: `mailto:${emails.primary}?${queryNoBody}`,
    body: body,
  };
};
