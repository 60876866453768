import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ScAddNewEntityFormContainer } from './CreateContactModal.styles';
import { VerticalScrolling } from 'components/VerticalScrolling/VerticalScrolling';
import { CreateContactForm } from './CreateContactForm';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { ContactFields, ContactItem, CustomerItem } from 'types/vendor';

interface Props {
  /** Related customer is mandatory here as BE don't create contact record without related customer */
  relatedCustomer: CustomerItem;
  onClose(): void;
  onSubmit?(values: ContactItem | null): void;
  onWarningClick?(name: ContactFields, value: string): void;
}
export const CreateContactModal: FC<Props> = ({
  relatedCustomer,
  onClose,
  onSubmit,
  onWarningClick,
}) => {
  const { t } = useTranslation();

  return (
    <VerticalScrolling
      refEl="create-contactmodal-ref"
      refWrapper="create-contact-modal-wrapper-ref"
    >
      <Modal
        visible
        onCancel={onClose}
        data-testid="end-user-add-new-contact-modal"
        className="create-contact-modal-ref"
        wrapClassName="create-contact-modal-wrapper-ref"
        variant="md"
      >
        <ScAddNewEntityFormContainer>
          <CreateContactForm
            onSubmit={onSubmit}
            relatedCustomer={relatedCustomer}
            onWarningClick={onWarningClick}
          >
            <Button
              onClick={onClose}
              className="fullwidth"
              data-testid="customer-page-new-contact-tab-cancel-modal"
            >
              {t('CANCEL')}
            </Button>
          </CreateContactForm>
        </ScAddNewEntityFormContainer>
      </Modal>
    </VerticalScrolling>
  );
};
