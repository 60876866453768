import styled from 'styled-components';

export const ScWrap = styled.div`
  position: absolute;

  width: fit-content;
  z-index: 1111;
  height: 2em !important;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s, opacity 0.3s;
  top: 0;
  opacity: 0.1;
  left: 0;
  transform: translateX(-90%);
  &:hover {
    opacity: 1;
    transform: translateX(0);
  }
`;
