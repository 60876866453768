import React, { FC, PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  id: string | number;
  idx: number;
  disabledToDrag?: boolean;
  disabledFromDrag?: boolean;
}

const DragListItem: FC<PropsWithChildren<Props>> = ({
  children,
  disabledToDrag,
  disabledFromDrag,
  id,
  idx,
}) => {
  if (disabledFromDrag) {
    return <div key={id}>{children}</div>;
  }

  return (
    <Draggable
      key={id}
      draggableId={String(id)}
      index={idx}
      isDragDisabled={disabledToDrag}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DragListItem;
