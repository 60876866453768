import {
  Model,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { ColumnsType } from 'antd/lib/table';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useTranslation } from 'react-i18next';
import { ExcludesFalse } from 'types/common';
import { formatPrice } from 'utils/format';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';
import { commonItemType__mapping__translationKey } from './RulesModal.meta';
import { sharedSelectors } from 'store';
import { useSelector } from 'react-redux';

export type ReplaceablesStateItem = Model_CommonItem & { key: string };

export type ReplaceablesState = ReplaceablesStateItem[];

export const assembleReplaceablesState = (
  model: Model,
  itemsIdsList: string[],
): ReplaceablesState => {
  return itemsIdsList
    .map(id => getModelCommonItemById(model, id))
    .filter(Boolean as unknown as ExcludesFalse)
    .map(item => ({ ...item, key: item[Model_CommonItemFields.ID] }));
};

export const useReplaceablesColumns = (): ColumnsType<Model_CommonItem> => {
  const { t, i18n } = useTranslation();
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const { currencyCode } = useCurrency();

  return [
    {
      title: t('TABLE_ITEM_ID'),
      width: '9em',
      key: Model_CommonItemFields.ID,
      dataIndex: Model_CommonItemFields.ID,
    },
    {
      title: t('DESCRIPTION'),
      width: '24em',
      dataIndex: Model_CommonItemFields.name,
      className: 'cell__description',
    },
    {
      title: t('ENTITY'),
      width: '8em',
      render: (value, record) => {
        return t(
          commonItemType__mapping__translationKey[record[Model_CommonItemFields.type]],
        );
      },
    },
    {
      title: t('MODAL_RULES__COLUMN_TITLE_GROSSPRICE'),
      render: (value, record) => {
        const field = shouldShowPricesWithVAT
          ? Model_CommonItemFields.grossPriceVat
          : Model_CommonItemFields.grossPrice;

        return formatPrice({
          price: record[field],
          currency: currencyCode,
          locale: i18n.language,
        });
      },
    },
  ];
};
