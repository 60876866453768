import { css } from 'styled-components';

export const basicInputStyle = css`
  padding: ${({ theme }) => `calc((${theme.inputHeight} - 1.5rem)/2)`} 1em;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  height: ${({ theme }) => theme.inputHeight};
  box-shadow: none;

  &:focus,
  &:hover,
  &.ant-input-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.mainColor};
  }
  /* fixes appearing shadow on NumberInput with error */
  &&:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.dustyGray};
  }
`;

export const readOnlyStyleInput = css`
  border-color: ${({ theme }) => theme.colors.softPeach};
  background: ${({ theme }) => theme.alternativeColor};
  cursor: not-allowed;

  &.ant-input.ant-input {
    &:focus,
    &:hover,
    &.ant-input-focused {
      border-color: ${({ theme }) => theme.colors.softPeach};
    }
  }
`;

export const vendorSelectOptionStyle = css`
  .ant-select-item-option {
    background-color: inherit;

    &-selected:not(&-disabled),
    &-active:not(&-disabled) {
      background-color: ${({ theme }) => theme.mainColor};
      color: ${({ theme }) => theme.mainColor_contrast};
    }
  }
`;

export const focusInputStyle = css`
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.mainColor};
`;

export const inputStyles = css`
  width: 100%;
  margin-bottom: 1.25em;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    margin-bottom: 0.438em;
    font-size: 0.75em;
    font-weight: 600;
  }

  &.search {
    .ant-input-suffix {
      padding-right: 1em;
    }
  }

  .ant-form-item label,
  .ant-input,
  .ant-btn.ant-btn input,
  .ant-picker-input > input,
  .ant-input-number {
    padding: 1.278em;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: inherit;
  }

  .ant-input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${readOnlyStyleInput}
  }

  &:not(.ant-input-affix-wrapper).ant-input {
    &:focus,
    &:hover,
    &.ant-input-focused {
      ${focusInputStyle}
    }
  }
`;
