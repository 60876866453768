import styled from 'styled-components';

export const ScLongText = styled.span`
  text-overflow: ellipsis;
`;

export const ScLongTextWrapper = styled.span`
  overflow: hidden;
  white-space: nowrap;
  max-width: 10em;
  text-overflow: ellipsis;
  display: inline-block;
`;
