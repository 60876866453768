import { Button as ButtonAntd } from 'antd';
import { RotatingLoader } from 'components/RotatingLoader';
import React, { FC } from 'react';
import {
  ScItemHeaderContainer,
  ScItemHeaderActions,
  ScItemCheckboxContent,
  ScItemCheckbox,
  ScCaretDownOutlined,
  ScCaretRightOutlined,
  ScItemHeaderName,
} from './ItemHeader.styles';

interface Props {
  checkCount: number;
  disabled?: boolean;
  onCheck(): void;
  isOpen: boolean;
  onOpen(): void;
  isLoaderVisible?: boolean;
  name: string;
}

const ItemHeader: FC<Props> = ({
  checkCount,
  disabled,
  onCheck,
  isOpen,
  onOpen,
  name,
  isLoaderVisible,
}) => {
  return (
    <ScItemHeaderContainer>
      <ScItemCheckbox checked={Boolean(checkCount)} onClick={onCheck} disabled={disabled}>
        <ScItemCheckboxContent disabled={disabled}>
          {checkCount || ''}
        </ScItemCheckboxContent>
      </ScItemCheckbox>

      <ScItemHeaderName>{name}</ScItemHeaderName>

      <ScItemHeaderActions>
        {isLoaderVisible && <RotatingLoader />}
        <ButtonAntd
          onClick={onOpen}
          type="text"
          icon={isOpen ? <ScCaretDownOutlined /> : <ScCaretRightOutlined />}
        />
      </ScItemHeaderActions>
    </ScItemHeaderContainer>
  );
};

export default ItemHeader;
