import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { sectionWithTwoColumns } from './styles';
import { Spin } from 'antd';
import { FC, PropsWithChildren, ComponentProps } from 'react';

// @todo: antd@5
export const ScSpin: FC<PropsWithChildren<ComponentProps<typeof Spin>>> = styled(Spin)``;

export const ScLanguageCodeAndCountryContainer = styled.div`
  display: flex;
  padding-bottom: 2em;
  padding: 1.625em;
  margin-bottom: 1em;
  > div {
    display: flex;
    flex: 1;
  }
  .ant-row .ant-form-item {
    margin-bottom: 0;
  }

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const TwoColumnContainer = styled.div`
  ${sectionWithTwoColumns}
`;

export const ScCustomerDetailsForm = styled.div`
  padding: 1.625em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  > div:last-child {
    margin: 0;
  }
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScWarningOutlined = styled(WarningOutlined)`
  color: #ff6813;
  cursor: pointer;
`;

export const ScCheckOutlined = styled(CheckOutlined)`
  color: ${({ theme }) => theme.notificationColors.success};
`;
