import VendorMobileInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';

export const ScVendorMobileInput = styled(VendorMobileInput)`
  width: 100%;
  padding: 0.8em 1em 0.8em 0.5em;
  border-radius: 0;
  border: 1px solid #ecdede;
  height: 3.123em;
  box-shadow: none;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.alternativeColor : 'initial'};

  .PhoneInputCountry {
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  .PhoneInputInput {
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    font-size: 0.75em;
    font-weight: 600;
    touch-action: manipulation;
    box-shadow: none;
    background-color: transparent;
    color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : '#000')};
  }
`;
