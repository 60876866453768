import styled from 'styled-components';
import Table from 'components/table';

export const ScTable = styled(Table)`
  padding-bottom: 200px;
  padding-top: 1.875em;

  && {
    .ant-table-thead > tr > th.ant-table-cell,
    .ant-table tfoot > tr > th.ant-table-cell {
      padding: 0.75em 0.5em;
    }
  }

  .ant-table-cell {
    font-size: 0.875em;
  }
`;
