import {
  Model,
  ModelFields,
  Model_CommonItem,
  Structure,
  StructureFields,
  StructureItem,
  StructureItemFields,
  WebImageItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { ImageItem } from 'types/common';
import { get } from 'utils';

export const getExtendedImage = (
  source: Structure | Model | null,
  imageLinkType = '',
  record?: StructureItem | Model_CommonItem,
): string | undefined => {
  const recordUrlId = get(record, imageLinkType);
  if (!recordUrlId) {
    return;
  }

  const webImages = get(source, ModelFields.webImage, []);
  const structureImage = webImages.find(
    item => get(item, WebImageItemFields.sysLink) === recordUrlId,
  );

  return get(structureImage, WebImageItemFields.url);
};

export const getCarouselFormattedImages = (
  source: Structure | null,
  option?: StructureItem,
): ImageItem[] => {
  const optionUrlId = get(option, StructureItemFields.imageLink);

  if (!optionUrlId) {
    return [];
  }

  const webImages = get(source, StructureFields.images, []);
  const structureImages = webImages.filter(
    item => get(item, WebImageItemFields.sysLink) === optionUrlId,
  );

  return structureImages.map(img => ({
    id: get(option, StructureItemFields.code, ''),
    image: get(img, WebImageItemFields.url, ''),
    alt: get(option, StructureItemFields.name, ''),
  }));
};
