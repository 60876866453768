import {
  Model_CommonCategory,
  Model_CommonCategoryFields,
} from '@hypercharge/xdms-client/lib/types';
import { IS_CATEGORY_REQUIRED_staticIdRegExp } from 'common/constants';

export const isCategoryRequired = (category: Model_CommonCategory): boolean => {
  return IS_CATEGORY_REQUIRED_staticIdRegExp.test(
    category[Model_CommonCategoryFields.staticId],
  );
};
