import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { ScUserButton } from './UserButton.styles';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store';

interface Props {
  onClick?(): void;
  t: TFunction;
}
const UserButton: FC<Props> = ({ onClick, t }) => {
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

  if (!isAuthenticated) return null;

  return (
    <ScUserButton
      key="user-button"
      onClick={onClick}
      data-testid="header-user-drop-down-button"
    >
      {t('MENU')}
    </ScUserButton>
  );
};

export default UserButton;
