import { useExternalStorage } from 'context/externalStorage/ExternalStorageProvider';
import { useInvalidImage } from 'hooks/useInvalidImage';
import React, { FC, useMemo, useRef } from 'react';
import styled from 'styled-components';

const ScExtendedImageTableItem = styled.img<{ isInvalidImage: boolean }>`
  max-width: 14.5em;
  height: 8.125em;
  object-fit: contain;
  cursor: pointer;
  width: ${({ isInvalidImage }) => (isInvalidImage ? '55%' : '100%')};
`;

type ExtendedImageTableItemProps = {
  [key: string]: unknown;
  src: string | undefined;
  onClick(event: React.MouseEvent<HTMLImageElement>): void;
};

export const ExtendedImageTableItem: FC<ExtendedImageTableItemProps> = ({
  src,
  onClick,
  ...rest
}) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const { isInvalidImage, handleImageError } = useInvalidImage({
    imageSrc: src,
    imageRef: ref?.current,
  });
  const { getOptimizedImageUrl } = useExternalStorage();

  const optimizedSrc = useMemo<string | undefined>(() => {
    return getOptimizedImageUrl({
      fileName: src,
      options: {
        resize: {
          width: 376,
          height: 212,
        },
      },
    });
  }, [getOptimizedImageUrl, src]);

  return (
    <ScExtendedImageTableItem
      {...rest}
      src={optimizedSrc ?? src}
      onClick={onClick}
      onError={handleImageError}
      isInvalidImage={isInvalidImage}
      ref={ref}
    />
  );
};
