import { PAGES_SETTINGS } from 'context/tableCategories/TableCategoriesProvider';
import { useMemo } from 'react';
import { Model_CommonCategory } from '@hypercharge/xdms-client/lib/model';
import { getCategoriesOnLvl } from 'context/tableCategories/utils/getCategoriesOnLvl';
import { Model_CommonItem } from '@hypercharge/xdms-client/lib/types';
import { getInconfiguredCategories } from 'context/tableCategories/utils/getInconfiguredCategories';
import { isCategoryRequired } from 'context/tableCategories/utils/isCategoryRequired';
import { PAGES_WITH_REQUIRED_CATEGORIES } from 'common/constants';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors, modelSelectors } from '../store';

interface Result {
  inconfiguredCategory: { stepId: string; category: Model_CommonCategory } | undefined;
  inconfiguredCategories: {
    [k in keyof typeof PAGES_SETTINGS]?: Model_CommonCategory[];
  };
  // all required categories, regardless of whether configured or not
  requiredCategories: {
    [k in keyof typeof PAGES_SETTINGS]?: Model_CommonCategory[];
  };
}

/** Works only for lvl1 categories */
export default function useInconfiguredCategory(): Result {
  const dynamicStepsFeatures = useSelector(
    featuresFlagsSelectors.getDynamicStepsFeatures,
  );

  const { model, isConfigurationCreatedFromStock } = useSelector(modelSelectors.getAll);

  /** Contains only inconfigured categories on lvl1 */
  const inconfiguredCategories: Result['inconfiguredCategories'] = useMemo(() => {
    if (!model) return {};
    return PAGES_WITH_REQUIRED_CATEGORIES.reduce((acc, stepId) => {
      if (
        isConfigurationCreatedFromStock &&
        dynamicStepsFeatures?.[stepId]['YNallowDisableForStockConfiguration']
      ) {
        return acc;
      }
      const { MODEL_TABLE, MODEL_TABLE_CATEGORIES } = PAGES_SETTINGS[stepId];
      const itemsList = (model[MODEL_TABLE] ?? []) as Model_CommonItem[];
      const categoriesList = model[MODEL_TABLE_CATEGORIES] ?? [];
      const lvl1CategoriesList = getCategoriesOnLvl({}, categoriesList);

      const inconfiguredCategoriesList = getInconfiguredCategories(
        lvl1CategoriesList,
        itemsList,
      );

      if (inconfiguredCategoriesList.length) {
        acc[stepId] = inconfiguredCategoriesList;
      }

      return acc;
    }, {});
  }, [dynamicStepsFeatures, isConfigurationCreatedFromStock, model]);

  const requiredCategories: Result['requiredCategories'] = useMemo(() => {
    if (!model) return {};
    return PAGES_WITH_REQUIRED_CATEGORIES.reduce((acc, stepId) => {
      const { MODEL_TABLE_CATEGORIES } = PAGES_SETTINGS[stepId];
      const categoriesList = model[MODEL_TABLE_CATEGORIES] ?? [];
      const lvl1CategoriesList = getCategoriesOnLvl({}, categoriesList);
      acc[stepId] = lvl1CategoriesList.filter(isCategoryRequired);

      return acc;
    }, {});
  }, [model]);

  const firstInconfiguredCategory = useMemo<
    | {
        stepId: string;
        category: Model_CommonCategory;
      }
    | undefined
  >(() => {
    return (
      PAGES_WITH_REQUIRED_CATEGORIES.reduce<
        { stepId: string; category: Model_CommonCategory }[]
      >((acc, stepId) => {
        const categories = inconfiguredCategories[stepId];
        if (categories?.length) {
          acc.push(...categories.map(category => ({ category, stepId })));
        }
        return acc;
      }, [])
        // get first
        .find(item => Boolean(item))
    );
  }, [inconfiguredCategories]);

  return {
    inconfiguredCategories,
    inconfiguredCategory: firstInconfiguredCategory,
    requiredCategories,
  };
}
