import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { sizes } from 'common/theme';
import { ScSplitPane } from './index.styles';
import { ReactComponent as ResizeIcon } from 'assets/icons/resize.svg';
import { svgToBase64 } from 'utils/svgToBase64';

const resizeIconString = `url("${svgToBase64(<ResizeIcon />)}")`;

type Props = React.PropsWithChildren<{
  isFallbackTableImage?: boolean;
}>;

const SplitPane: FC<Props> = ({ children, isFallbackTableImage = true }) => {
  const isMd = useMediaQuery({ maxWidth: sizes.md });

  const element = document.getElementById('layout-top-section');

  const splitPaneStyles: CSSProperties = {
    overflow: 'visible',
    position: 'relative',
  };

  return (
    <ScSplitPane
      style={splitPaneStyles}
      minSize={isMd ? '100%' : '80%'}
      split="vertical"
      allowResize
      resizerClassName={classnames('Resizer', {
        hidden: isFallbackTableImage,
      })}
      resizerStyle={{ backgroundImage: resizeIconString }}
      size="85%"
      $headerHeight={element?.clientHeight}
      $isFallbackTableImage={isFallbackTableImage}
    >
      {children}
    </ScSplitPane>
  );
};

export default SplitPane;
