import styled from 'styled-components';
import { Collapse } from 'antd';
import { ScTable } from './TotalTableRow.styles';
import { FC, PropsWithChildren, ComponentProps } from 'react';

// @todo: antd@5
export const ScCollapse: FC<PropsWithChildren<ComponentProps<typeof Collapse>>> = styled(
  Collapse,
)`
  font-size: inherit;

  &.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid ${({ theme }) => theme.dividerLineColorLight};
    > .ant-collapse-header {
      padding: 0;

      .ant-collapse-arrow {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
      }
    }
  }
`;

export const ScCollapsePanel: FC<
  PropsWithChildren<ComponentProps<typeof Collapse.Panel>>
> = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;

    ${ScTable} {
      td:nth-child(1) .ant-checkbox-wrapper {
        margin-bottom: 0;
      }

      td:nth-child(1),
      td:nth-child(2) {
        font-weight: 400;
      }

      td:nth-child(5),
      td:nth-child(6),
      td:nth-child(7) {
        color: ${({ theme }) => theme.colors.dustyGray};
      }
    }
  }
`;
