import React, { FC } from 'react';
import { get } from 'utils';
import { useTranslation } from 'react-i18next';
import { Price } from 'components/Price';
import { TotalTableRow } from '../../components/TotalTableRow';
import { useTotalPage } from '../../TotalPageProvider';
import { BannerSettings } from '../types';

type Props = BannerSettings;

const TotalGenericSubTotalExManufacturer: FC<Props> = () => {
  const { t } = useTranslation();

  const { FIELDS, hideZeroDiscounts, totalData } = useTotalPage();

  const discountAmount = get(totalData, FIELDS.subtotalExManufacturer.discountAmount, 0);
  const discountPercent = get(
    totalData,
    FIELDS.subtotalExManufacturer.discountPercent,
    0,
  );

  const showDiscountAmount = Boolean(!hideZeroDiscounts || discountAmount);
  const showDiscountPercent = Boolean(!hideZeroDiscounts || discountPercent);

  return (
    <TotalTableRow
      data-testid="total-page-subtotal-ex-manufacturer"
      borderBottom="thin"
      shadeNumbers
      fields={[
        t('TOTAL_HEADER_SUBTOTAL_EX_MANUFACTURER'),
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.subtotalExManufacturer.brutto, 0)} />,
        <>{showDiscountAmount && <Price value={discountAmount} />}</>,
        <>
          {showDiscountPercent && (
            <Price value={discountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price value={get(totalData, FIELDS.subtotalExManufacturer.netto, 0)} />,
      ]}
    />
  );
};

export default TotalGenericSubTotalExManufacturer;
