import { EventError } from './types';

export const getErrorMessage = (
  error: EventError | undefined,
  fallback: string,
): string => {
  if (!error) return fallback;

  if (!error.notify) return '';

  if (error?.message) {
    return error.message;
  }

  return fallback;
};
