import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { PreviewImage } from 'types/common';
import * as Sentry from '@sentry/react';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';
import { useTranslation } from 'react-i18next';

type Args = {
  isSelected?: boolean;
};

const Labels: Record<PreviewImage['type'], string> = {
  package: 'STREAMING_LABEL_PACKAGE',
  option: 'STREAMING_LABEL_OPTION',
  accessory: 'STREAMING_LABEL_ACCESSORY',
};

export default function usePreviewImages({ isSelected = true }: Args): Result {
  const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);

  const currentLanguageCode = useCurrentLanguageCode();
  const { t } = useTranslation();

  const modelToken = useSelector(modelSelectors.getToken);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_STREAMING_SERVER_API_ENDPOINT as string;

    if (!modelToken) return;

    const requestBody: RequestBody = {
      marketOrLocale: currentLanguageCode.iso,
      token: modelToken,
      isSelected,
      isActive: isSelected,
    };

    fetch(`${baseUrl}/api/cars/info`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then<{ data: PreviewImage[] }>(res => res.json())
      .then(res => {
        const newData =
          res.data?.map(record => ({
            ...record,
            tKey: t(Labels[record.type]),
          })) ?? [];

        setPreviewImages(newData);
      })
      .catch(err => {
        Sentry.captureException(err, { extra: requestBody });
      });
  }, [modelToken, currentLanguageCode, isSelected, t]);

  return { data: previewImages };
}

type RequestBody = {
  marketOrLocale: string;
  token: string;
  isSelected?: boolean;
  isActive?: boolean;
};

type Result = {
  data: PreviewImage[];
};
