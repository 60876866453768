import React, { FC, ChangeEvent, useCallback } from 'react';
import { SwitchProps as VendorSwitchProps } from 'antd/lib/switch';
import { ScTitle, ScSwitch as ScSwitch, SwitcherWrapper } from './Switcher.styles';

type SwitchInputElement = Omit<HTMLInputElement, 'value'> & { value: boolean };

interface SwitcherProps extends Omit<VendorSwitchProps, 'onChange'> {
  title?: string;
  alignment?: 'column' | 'row';
  value?: boolean;
  disabled?: boolean;
  onChange?(event: ChangeEvent<SwitchInputElement>): void;
  name?: string;
}

const Switcher: FC<SwitcherProps> = ({
  title,
  alignment = 'column',
  value,
  onChange,
  disabled = false,
  name,
  ...props
}) => {
  const localOnChange = useCallback(
    (value: boolean) => {
      const event = {
        target: {
          value: value,
          name: name,
        },
      } as ChangeEvent<SwitchInputElement>;
      onChange?.(event);
    },
    [name, onChange],
  );

  return (
    <SwitcherWrapper direction={alignment}>
      {title && <ScTitle role="heading">{title}</ScTitle>}
      <ScSwitch
        {...props}
        onChange={localOnChange}
        disabled={disabled}
        checked={value || props.checked}
        $hasLeftMargin={alignment === 'row'}
      />
    </SwitcherWrapper>
  );
};

export default Switcher;
