import { STORAGE_KEYS } from 'common/constants';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useRelations } from 'context/relations/RelationsProvider';
import { useStructureApi } from 'context/structure/useStructureApi';
import { useCallback } from 'react';
import { saveInLocalStorage } from 'utils/storage';

type Result = {
  unsetCurrentConfiguration(): void;
};
export default function useUnsetCurrentConfiguration(): Result {
  const { unsetRelations } = useRelations();
  const { unsetModel } = useModelApi();

  const { unsetConfigurationProvider } = useConfiguration();
  const { unsetStructure } = useStructureApi();

  const unsetCurrentConfiguration = useCallback<
    Result['unsetCurrentConfiguration']
  >(() => {
    unsetConfigurationProvider();
    unsetModel();
    unsetStructure();
    unsetRelations();
    saveInLocalStorage(STORAGE_KEYS.configurationNumber, null);
  }, [unsetConfigurationProvider, unsetModel, unsetStructure, unsetRelations]);

  return { unsetCurrentConfiguration };
}
