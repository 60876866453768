import { Model_CommonItem } from '@hypercharge/xdms-client/lib/types';
import { PAGES_SETTINGS } from 'context/tableCategories/TableCategoriesProvider';
import { get } from 'utils';
import { NAME_TAB_ALL } from 'utils/constants';
import { getUniqueCategoryFilterKey } from 'utils/get-unique-category-filter-key';
import { FilteredTableData } from '../types';

interface SortByCustomOrderParams {
  a: Model_CommonItem;
  b: Model_CommonItem;
  stepId: string;
  isSortingByGrossPriceAscendingEnabled?: boolean;
  isPricesWithVATenabled?: boolean;
}
/**
 * Function for sorting table items in custom order.
 * Returns the priority number.
 */
const sortByCustomOrder = (params: SortByCustomOrderParams): number => {
  const { a, b, stepId, isSortingByGrossPriceAscendingEnabled, isPricesWithVATenabled } =
    params;
  const { MODEL_TABLE_ITEMS_FIELDS } = PAGES_SETTINGS[stepId];
  const {
    selectedByDefault,
    selected,
    optional,
    manuallyAdded,
    grossPrice,
    grossPriceVat,
  } = MODEL_TABLE_ITEMS_FIELDS;
  const priorityByDefaultValue = b[selectedByDefault] - a[selectedByDefault];
  const priorityBySelectedValue = b[selected] - a[selected];
  const priorityByOptionValue = a[optional] - b[optional];
  const priorityByManuallyValue = a[manuallyAdded] - b[manuallyAdded];

  if (priorityBySelectedValue !== 0) {
    return priorityBySelectedValue;
  }
  if (priorityByDefaultValue !== 0) {
    return priorityByDefaultValue;
  }
  if (priorityByOptionValue !== 0) {
    if (a[optional]) {
      return b[manuallyAdded] || !b[selected] ? -1 : 1;
    } else {
      return a[manuallyAdded] || !a[selected] ? 1 : -1;
    }
  }
  if (priorityByManuallyValue !== 0) {
    return a[manuallyAdded] && b[selected] ? 1 : -1;
  }

  if (isSortingByGrossPriceAscendingEnabled) {
    const field = isPricesWithVATenabled ? grossPriceVat : grossPrice;
    return a[field] - b[field];
  }

  return priorityByManuallyValue;
};

interface GetFilteredTableDataParams {
  items?: Model_CommonItem[];
  stepId: string;
  selectedCategory: string | string[] | null;
  selectedSubCategory: string | string[] | null;
  isSortingByGrossPriceAscendingEnabled?: boolean;
  isPricesWithVATenabled?: boolean;
}
export const getFilteredTableData = (
  params: GetFilteredTableDataParams,
): FilteredTableData => {
  const {
    items = [],
    stepId,
    selectedCategory,
    selectedSubCategory,
    isSortingByGrossPriceAscendingEnabled,
    isPricesWithVATenabled,
  } = params;
  const { MODEL_TABLE_ITEMS_FIELDS, MODEL_TABLE_CATEGORY_FIELDS } =
    PAGES_SETTINGS[stepId];
  const { name, hidden, categoryId } = MODEL_TABLE_ITEMS_FIELDS;
  const { lvl1: id } = MODEL_TABLE_CATEGORY_FIELDS;
  let dummyMessage = '';
  const tableData = items
    .filter(option => {
      if (selectedCategory && selectedCategory !== NAME_TAB_ALL) {
        return (
          getUniqueCategoryFilterKey({ category: option, idFlag: id }) ===
            selectedCategory &&
          selectedSubCategory === get(option, 'CDcat2') &&
          selectedCategory
        );
      }
      return true;
    })
    .filter(option => !get(option, hidden))
    .sort((a, b) => get(a, name, '').localeCompare(get(b, name, '')))
    .sort((a, b) => {
      if (selectedCategory === NAME_TAB_ALL) {
        return get(a, categoryId, '').localeCompare(get(b, categoryId, ''));
      }
      return 0;
    })
    .sort((a, b) =>
      sortByCustomOrder({
        a,
        b,
        stepId,
        isSortingByGrossPriceAscendingEnabled,
        isPricesWithVATenabled,
      }),
    );

  const isDataDummy: boolean =
    !tableData.length &&
    items.every(option => {
      return get(option, 'YNdummyHyper');
    });

  if (isDataDummy) {
    dummyMessage = get(items, '0.INdummyDescr', '');
  }

  return {
    tableData,
    dummyMessage,
  };
};
