import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ScBodyWrapper,
  ScCardTextGroup,
  ScContentWrapper,
  ScExclusiveOptionsLink,
  ScHeaderImage,
  ScHeading,
  ScImageWrapper,
  ScOptionCard,
  ScSection,
  ScSectionSubtitle,
  ScSectionTitle,
  ScTextFlow,
} from './StreamingPackageDetails.styles';
import { useTranslation } from 'react-i18next';
import usePackageDetails from './StreamingPackageDetails/usePackageDetails';
import ImageCard from './shared/ImageCard';
import { PreviewImageType } from 'types';
import { Modal } from 'components/modal';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import classnames from 'classnames';
import { OptionDetail } from './StreamingPackageDetails/types';

const SECTION_TO_LABEL = {
  includedOptions: 'PACKAGE_DETAILS_INCLUDED_OPTIONS_TITLE',
  exclusiveOptions: 'PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_TITLE',
  toRemove: 'PACKAGE_DETAILS_TO_REMOVE_OPTIONS_TITLE',
};

type Props = {
  code: string;
  onReset(): void;
  isActive: boolean;
  isSelected: boolean;
  withToRemove: boolean;
};

const StreamingPackageDetails: React.FC<Props> = ({
  code,
  onReset: originalOnReset,
  isActive,
  isSelected,
  withToRemove,
}) => {
  const { t } = useTranslation();
  const { sendMessage } = useStreaming();

  const { data: packageDetails, onReset } = usePackageDetails({
    code,
    isActive,
    isSelected,
    withToRemove,
  });

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'selection',
        data: {
          currentlyPreviewedPackage: code,
        },
      },
    });
  }, [code, sendMessage]);

  const handleReset = useCallback(() => {
    onReset();
    originalOnReset();

    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'selection',
        data: {
          currentlyPreviewedPackage: null,
        },
      },
    });
  }, [originalOnReset, onReset, sendMessage]);

  const displayData = useMemo<{ section: string; options: OptionDetail[] }[]>(() => {
    return ['includedOptions', 'exclusiveOptions', 'toRemove']
      .filter(section => packageDetails?.[section]?.length > 0)
      .map(section => {
        const options = packageDetails?.[section].map(option => {
          if (option.description.length >= 250) {
            return {
              ...option,
              description: option.description.slice(0, 251) + '...',
            };
          }
          return option;
        });
        return {
          section: section,
          options: options,
        };
      });
  }, [packageDetails]);

  if (!packageDetails) return null;

  return (
    <Modal variant="xl" visible onCancel={handleReset}>
      <ScHeaderImage
        hideContent
        image={{
          url: packageDetails.previewImage?.url ?? '',
          name: '',
          options: [],
          code: code,
          description: '',
          focused: false,
          isDefault: false,
          tKey: '',
          type: PreviewImageType.Package,
        }}
      />
      <ScBodyWrapper>
        {displayData.map(item => {
          const { section, options } = item;

          return (
            <ScSection key={section}>
              <ScSectionTitle>{t(SECTION_TO_LABEL[section])}</ScSectionTitle>
              {section === 'exclusiveOptions' && (
                <ScSectionSubtitle>
                  {t('PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_SUBTITLE')}{' '}
                  <ScExclusiveOptionsLink>
                    {t('PACKAGE_DETAILS_EXCLUSIVE_OPTIONS_LINK')}
                  </ScExclusiveOptionsLink>
                </ScSectionSubtitle>
              )}
              <ScContentWrapper>
                {options.map(option => {
                  return (
                    <ScOptionCard
                      key={option.label}
                      className={classnames({
                        blured: section === 'toRemove',
                      })}
                    >
                      <ScImageWrapper>
                        <ImageCard
                          key={option.label}
                          image={{
                            ...option,
                            url: option.url ?? '',
                            name: '',
                            options: [],
                            code: code,
                            description: '',
                            focused: false,
                            isDefault: false,
                            tKey: '',
                            type: PreviewImageType.Package,
                          }}
                          hideContent
                        />
                      </ScImageWrapper>
                      {(option.label || option.description) && (
                        <ScCardTextGroup>
                          {option.label && <ScHeading>{option.label}</ScHeading>}
                          {option.description && (
                            <ScTextFlow>{option.description}</ScTextFlow>
                          )}
                        </ScCardTextGroup>
                      )}
                    </ScOptionCard>
                  );
                })}
              </ScContentWrapper>
            </ScSection>
          );
        })}
      </ScBodyWrapper>
    </Modal>
  );
};

export default StreamingPackageDetails;
