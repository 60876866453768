import { SelectProps } from 'antd/lib/select';
import cn from 'classnames';
import React, { FC, ChangeEvent, useCallback } from 'react';
import { OptionWithKey, OptionWithKey as TOptionWithKey } from 'types/common';
import { ScOption, ScSelect } from './Selector.styles';
import { Loader } from 'components/Loader';

interface Props extends Omit<SelectProps<TOptionWithKey | string | number>, 'onChange'> {
  options: TOptionWithKey[];
  fullwidth?: boolean;
  size?: 'small' | 'large';
  name?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>, option: OptionWithKey): void;
  'data-testid'?: string;
}

const Select: FC<Props> = ({
  options,
  size,
  className,
  bordered = true,
  fullwidth,
  onChange,
  loading,
  dropdownRender,
  ...props
}) => {
  const localOnChange = useCallback(
    (value: number | string | string[] | number[], option: TOptionWithKey) => {
      const event: ChangeEvent<HTMLInputElement> = {
        target: {
          value: value,
          name: props.name ?? '',
        },
      } as ChangeEvent<HTMLInputElement>;

      onChange?.(event, option);
    },
    [props.name, onChange],
  );

  return (
    <ScSelect
      loading={loading}
      onChange={localOnChange as any}
      className={cn(
        className,
        { bordered: bordered, fullwidth: fullwidth },
        {
          size_small: size === 'small',
        },
      )}
      data-testid="select-field-container"
      // to search by label only: https://stackoverflow.com/a/71530973
      filterOption={(inputValue, option) => {
        return option?.children.toLowerCase().includes(inputValue.toLowerCase());
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as SelectProps<any>)}
      dropdownRender={menu => (
        <>
          <Loader.Conditional isLoading={Boolean(loading)} />
          {dropdownRender?.(menu) ?? menu}
        </>
      )}
      /** pair for {@link vendorSelectOptionStyle} */
      getPopupContainer={trigger => trigger.parentElement}
    >
      {options.map(option => (
        <ScOption
          key={option.key}
          disabled={option.disabled}
          value={option.value}
          data-testid="select-field-option"
        >
          {option.label}
        </ScOption>
      ))}
    </ScSelect>
  );
};

export default Object.assign(Select, { Option: ScOption });
