import { Model_CommonItem } from '@hypercharge/xdms-client/lib/types';
import React, { ReactNode, ReactElement, PropsWithChildren } from 'react';
import { TotalTableRow } from './TotalTableRow';
import { TotalsTableColumnsGenericProp } from '../styles';
import { ScCollapse, ScCollapsePanel } from './TotalTableCollapsibleListRow.styles';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ModelRecordFields } from 'types/vendor';

const getExpandIcon = ({ isActive }: { isActive?: boolean }): ReactNode => {
  return isActive ? (
    <MinusOutlined style={{ fontSize: '1em' }} />
  ) : (
    <PlusOutlined style={{ fontSize: '1em' }} />
  );
};

interface Props<T> {
  items?: T[];
  getItemFields?(item: T): TotalsTableColumnsGenericProp<ReactNode | string | undefined>;
  testIdPrefix: string;
  headerFields: TotalsTableColumnsGenericProp<ReactNode | string | undefined>;
  headerColumnsCustomWidths?: TotalsTableColumnsGenericProp<number>;
  columnsCustomWidths?: TotalsTableColumnsGenericProp<number>;
}

function TotalTableCollapsibleListRow<T = Model_CommonItem>({
  items,
  getItemFields,
  testIdPrefix,
  headerFields,
  children,
  headerColumnsCustomWidths,
  columnsCustomWidths,
}: PropsWithChildren<Props<T>>): ReactElement<Props<T>> {
  return (
    <ScCollapse bordered={false} ghost expandIcon={getExpandIcon}>
      <ScCollapsePanel
        collapsible={!items?.length && !children ? 'disabled' : undefined}
        header={
          <TotalTableRow
            customTotalsTableColumnsWidths={headerColumnsCustomWidths}
            data-testid={`total-page-${testIdPrefix}-heading`}
            shiftFirstColumn
            shadeNumbers
            fields={headerFields}
          />
        }
        key={testIdPrefix}
      >
        {getItemFields &&
          items?.map(item => (
            <TotalTableRow
              key={String(item[ModelRecordFields.ID])}
              customTotalsTableColumnsWidths={columnsCustomWidths}
              fields={getItemFields?.(item)}
            />
          ))}
        {children}
      </ScCollapsePanel>
    </ScCollapse>
  );
}

export default TotalTableCollapsibleListRow;
