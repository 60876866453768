import React, { FC, PropsWithChildren } from 'react';
import {
  ScDocumentItemContainer,
  ScDocumentItemHeader,
  ScDocumentItemInner,
} from './DocumentEntity.styles';

interface Props {
  title: string;
}

const DocumentEntity: FC<PropsWithChildren<Props>> = props => {
  const { title, children } = props;

  return (
    <ScDocumentItemContainer>
      <ScDocumentItemHeader>{title}</ScDocumentItemHeader>
      <ScDocumentItemInner>{children}</ScDocumentItemInner>
    </ScDocumentItemContainer>
  );
};

export default DocumentEntity;
