import { Procedure_GetModelData_Response_Action_RuleCode_Values as ActionRuleCode } from '@hypercharge/xdms-client/lib/_backendMethods/procedures/getModelData';
import { ModelAction } from 'types/vendor';
import { TotalPageAdaptedFields } from './fieldsMapping';
import {
  DEFAULT_CURRENCY,
  TOTAL_DEALER_ACTION_CODE,
  TOTAL_DEALER_DELIV_ACTION_CODE,
} from 'common/constants';
import { SummaryField, SummaryFieldType } from 'context/streaming/types';

const prepareAction = (
  action: ModelAction,
  fields: TotalPageAdaptedFields,
  type: SummaryFieldType | null = null,
): SummaryField => {
  const sign = action[fields.actions.sign];
  const amount = action[fields.actions.amount];
  const name = action[fields.actions.name];

  return {
    name,
    currency: DEFAULT_CURRENCY,
    value: amount,
    sign,
    type,
  };
};

const getNationalFields = (
  actionsList: ModelAction[],
  fields: TotalPageAdaptedFields,
): SummaryField[] => {
  return actionsList
    .filter(action => action[fields.actions.rule] === ActionRuleCode.national)
    .filter(
      action =>
        action[fields.actions.code] !== TOTAL_DEALER_DELIV_ACTION_CODE &&
        action[fields.actions.code] !== TOTAL_DEALER_ACTION_CODE,
    )
    .filter(action => action[fields.actions.selected])
    .map(action => {
      const actionField = prepareAction(action, fields, SummaryFieldType.national);
      actionField.name = 'SUMMARY_NATIONAL_PROMOTIONS';
      return actionField;
    });
};

export const getStreamActions = (
  actionsList: ModelAction[],
  fields: TotalPageAdaptedFields,
): SummaryField[] => {
  const result: SummaryField[] = [];
  const nationalFields = getNationalFields(actionsList, fields);
  result.push(...nationalFields);

  const plainFields = actionsList
    .filter(action => {
      return (
        action[fields.actions.rule] !== ActionRuleCode.national &&
        action[fields.actions.code] !== TOTAL_DEALER_DELIV_ACTION_CODE &&
        action[fields.actions.code] !== TOTAL_DEALER_ACTION_CODE &&
        action[fields.actions.selected]
      );
    })
    .map(action => prepareAction(action, fields, SummaryFieldType.local));

  result.push(...plainFields);

  const deliv = actionsList.find(
    action => action[fields.actions.code] === TOTAL_DEALER_DELIV_ACTION_CODE,
  );

  if (deliv && deliv[fields.actions.selected]) {
    const delivField = prepareAction(deliv, fields);
    delivField.name = 'DELIV';
    result.unshift(delivField);
  }

  // special action
  const delta = actionsList.find(
    action => action[fields.actions.code] === TOTAL_DEALER_ACTION_CODE,
  );

  if (delta && delta[fields.actions.selected]) {
    const deltaField = prepareAction(delta, fields);
    deltaField.name = 'DELTA';
    result.push(deltaField);
  }

  return result;
};
