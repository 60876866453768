import {
  SoftOfferOptionsKeys,
  SoftOfferInsurancePolicyHolderFields,
  SoftOfferInsurancePolicyHolder,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { Status } from 'utils/types';
import {
  DATE_FORMAT,
  SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
} from 'common/constants';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { DatePicker } from 'components/form/DatePicker';
import { isFieldRequired } from 'utils/is-field-required';
import { MobileInput, MobileInputCountry } from 'components/form/MobileInput';
import { CountryFields } from '@hypercharge/xdms-client/lib/types';
import { useCountry } from 'context/country/CountryProvider';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

const testPrefix = 'soft-offer_insurance-policy-holder_';

interface Props {
  isDisabled: boolean;
  defaultCountryCode?: string;
  initialValues?: SoftOfferInsurancePolicyHolder;
  onSubmit(values: SoftOfferInsurancePolicyHolder): void;
  setIsTabLocked?(value: boolean): void;
}

const InsurancePolicyHolderForm: FC<Props> = ({
  onSubmit,
  initialValues,
  defaultCountryCode,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const { countries } = useCountry();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  const languages = options?.[SoftOfferOptionsKeys.HolderLanguage]?.options ?? [];
  const sexes = options?.[SoftOfferOptionsKeys.HolderSex]?.options ?? [];
  const jobs = options?.[SoftOfferOptionsKeys.HolderJob]?.options ?? [];
  const enterpriseLegalForms =
    options?.[SoftOfferOptionsKeys.HolderEnterpriseLegalForm]?.options ?? [];
  const personTypes = options?.[SoftOfferOptionsKeys.HolderPersonType]?.options ?? [];
  const licenceCategoryCodes =
    options?.[SoftOfferOptionsKeys.HolderLicenceCategoryCode]?.options ?? [];

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    return initialValues;
  }, [initialValues]);

  const formProps = useFormik({
    initialValues: initialData!,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { dirty, values } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  const isCompany = useMemo(() => {
    return (
      values?.[SoftOfferInsurancePolicyHolderFields.personType] ===
      SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY
    );
  }, [values]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={8}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.lastName}
                  name={SoftOfferInsurancePolicyHolderFields.lastName}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.lastName,
                  )}
                  label={t('SOFT_OFFER_LAST_NAME')}
                  data-testid={testPrefix + 'lastname'}
                  disabled={isDisabled}
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.firstName}
                  name={SoftOfferInsurancePolicyHolderFields.firstName}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.firstName,
                  )}
                  label={t('SOFT_OFFER_FIRST_NAME')}
                  data-testid={testPrefix + 'firstname'}
                  disabled={isDisabled}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.birthdayAt}
                  name={SoftOfferInsurancePolicyHolderFields.birthdayAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.birthdayAt,
                  )}
                  label={t('SOFT_OFFER_BIRTHDAY')}
                  data-testid={testPrefix + 'birthday'}
                  disabled={isDisabled}
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.sex}
                  name={SoftOfferInsurancePolicyHolderFields.sex}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(sexes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.sex,
                  )}
                  label={t('SOFT_OFFER_GENDER')}
                  data-testid={testPrefix + 'gender'}
                  disabled={isDisabled}
                />
              </Col>
            </Row>

            <InputCityZipField
              inputZipProps={{
                id: SoftOfferInsurancePolicyHolderFields.zipCode,
                name: SoftOfferInsurancePolicyHolderFields.zipCode,
                required: isFieldRequired(
                  schema,
                  SoftOfferInsurancePolicyHolderFields.zipCode,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_ZIPCODE'),
              }}
              inputCityProps={{
                id: SoftOfferInsurancePolicyHolderFields.locality,
                name: SoftOfferInsurancePolicyHolderFields.locality,
                required: isFieldRequired(
                  schema,
                  SoftOfferInsurancePolicyHolderFields.locality,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_TOWN'),
              }}
            />

            <FormField
              id={SoftOfferInsurancePolicyHolderFields.street}
              name={SoftOfferInsurancePolicyHolderFields.street}
              component={Input}
              required={isFieldRequired(
                schema,
                SoftOfferInsurancePolicyHolderFields.street,
              )}
              label={t('SOFT_OFFER_STREET')}
              data-testid={testPrefix + 'street'}
              disabled={isDisabled}
            />
          </Col>
          <Col md={8}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.number}
                  name={SoftOfferInsurancePolicyHolderFields.number}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.number,
                  )}
                  label={t('SOFT_OFFER_NR')}
                  data-testid={testPrefix + 'number'}
                  disabled={isDisabled}
                />
              </Col>

              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.box}
                  name={SoftOfferInsurancePolicyHolderFields.box}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.box,
                  )}
                  label={t('SOFT_OFFER_BOX')}
                  data-testid={testPrefix + 'box'}
                  disabled={isDisabled}
                />
              </Col>

              <Col md={12}>
                <FormField
                  name={SoftOfferInsurancePolicyHolderFields.phone}
                  component={MobileInput}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.phone,
                  )}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE')}
                  data-testid={testPrefix + 'phone'}
                />
              </Col>

              <Col md={12}>
                <FormField
                  name={SoftOfferInsurancePolicyHolderFields.phoneGsm}
                  component={MobileInput}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.phoneGsm,
                  )}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE_GSM')}
                  data-testid={testPrefix + 'phoneGsm'}
                />
              </Col>
            </Row>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.personType}
                  name={SoftOfferInsurancePolicyHolderFields.personType}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(personTypes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.personType,
                  )}
                  label={t('SOFT_OFFER_PERSON_TYPE')}
                  data-testid={testPrefix + 'personType'}
                  disabled={isDisabled}
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferInsurancePolicyHolderFields.language}
                  name={SoftOfferInsurancePolicyHolderFields.language}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(languages, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferInsurancePolicyHolderFields.language,
                  )}
                  label={t('SOFT_OFFER_LANGUAGE')}
                  data-testid={testPrefix + 'language'}
                  disabled={isDisabled}
                />
              </Col>
            </Row>

            <FormField
              id={SoftOfferInsurancePolicyHolderFields.email}
              name={SoftOfferInsurancePolicyHolderFields.email}
              component={Input}
              required={isFieldRequired(
                schema,
                SoftOfferInsurancePolicyHolderFields.email,
              )}
              label={t('SOFT_OFFER_EMAIL')}
              data-testid={testPrefix + 'email'}
              disabled={isDisabled}
            />
          </Col>
          <Col md={8}>
            {!isCompany && (
              <FormField
                id={SoftOfferInsurancePolicyHolderFields.job}
                name={SoftOfferInsurancePolicyHolderFields.job}
                component={Select}
                loading={isLoading}
                options={mapToSelectOptions<OlistItemOption>(jobs, {
                  label: OlistItemOptionFields.label,
                  value: OlistItemOptionFields.value,
                })}
                required={isFieldRequired(
                  schema,
                  SoftOfferInsurancePolicyHolderFields.job,
                )}
                label={t('SOFT_OFFER_JOB')}
                data-testid={testPrefix + 'job'}
                disabled={isDisabled}
              />
            )}

            {isCompany && (
              <Row gutter={SUB_ROW_SPACES}>
                <Col md={24}>
                  <FormField
                    id={SoftOfferInsurancePolicyHolderFields.enterpriseName}
                    name={SoftOfferInsurancePolicyHolderFields.enterpriseName}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferInsurancePolicyHolderFields.enterpriseName,
                    )}
                    label={t('SOFT_OFFER_ENTERPRICE_NAME')}
                    data-testid={testPrefix + 'enterpriseName'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id={SoftOfferInsurancePolicyHolderFields.enterpriseNumber}
                    name={SoftOfferInsurancePolicyHolderFields.enterpriseNumber}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferInsurancePolicyHolderFields.enterpriseNumber,
                    )}
                    label={t('SOFT_OFFER_ENTERPRICE_NUMBER')}
                    data-testid={testPrefix + 'enterpriseNumber'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id={SoftOfferInsurancePolicyHolderFields.enterpriseLegalForm}
                    name={SoftOfferInsurancePolicyHolderFields.enterpriseLegalForm}
                    component={Select}
                    loading={isLoading}
                    options={mapToSelectOptions<OlistItemOption>(enterpriseLegalForms, {
                      label: OlistItemOptionFields.label,
                      value: OlistItemOptionFields.value,
                    })}
                    required={isFieldRequired(
                      schema,
                      SoftOfferInsurancePolicyHolderFields.enterpriseLegalForm,
                    )}
                    label={t('SOFT_OFFER_ENTERPRICE_LEGAL_FORM')}
                    data-testid={testPrefix + 'enterpriseLegalForm'}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
            )}

            <FormField
              id={SoftOfferInsurancePolicyHolderFields.licence}
              name={SoftOfferInsurancePolicyHolderFields.licence}
              component={DatePicker}
              format={DATE_FORMAT}
              required={isFieldRequired(
                schema,
                SoftOfferInsurancePolicyHolderFields.licence,
              )}
              label={t('SOFT_OFFER_INSURANCE_POLICY_HOLDER_LICENCE')}
              data-testid={testPrefix + 'licence'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferInsurancePolicyHolderFields.licenceCategoryCode}
              name={SoftOfferInsurancePolicyHolderFields.licenceCategoryCode}
              component={Select}
              required={isFieldRequired(
                schema,
                SoftOfferInsurancePolicyHolderFields.licenceCategoryCode,
              )}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(licenceCategoryCodes, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              label={t('SOFT_OFFER_INSURANCE_POLICY_HOLDER_LICENCE_CATEGORY_CODE')}
              data-testid={testPrefix + 'licenceCategoryCode'}
              disabled={isDisabled}
            />
          </Col>
        </Row>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default InsurancePolicyHolderForm;
