import { ModelTax, ModelTaxFields } from '@hypercharge/xdms-client/lib/types';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const columnKey = {
  CATEGORY: ModelTaxFields.isSelected,
  DESCRIPTION: ModelTaxFields.info,
  VALUE: ModelTaxFields.amount,
};

export const useColumns = (): ColumnsType<ModelTax> => {
  const { t } = useTranslation();

  const keyToColumn: Record<string, ColumnType<ModelTax>> = useMemo(() => {
    return {
      [columnKey.DESCRIPTION]: {
        title: t('TAXES_DESCRIPTION'),
        dataIndex: ModelTaxFields.info,
        key: columnKey.DESCRIPTION,
        render: value => value,
      },
      [columnKey.VALUE]: {
        title: t('TAXES_VALUE'),
        dataIndex: ModelTaxFields.amount,
        key: columnKey.VALUE,
        render: value => value,
      },
    };
  }, [t]);

  return Object.values(keyToColumn);
};
