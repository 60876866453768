import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModelAction } from 'types/vendor';
import { TOTAL_ACTIONS_RULES_TRANSLATIONS_CODES } from 'common/constants';
import { useCurrency } from 'context/currency/CurrencyProvider';
import ActionItem from '../../components/ActionItem/ActionItem';
import { BannerSettings } from '../types';
import { useTotalPage } from '../../TotalPageProvider';
import { FilterItem, handleFiltering } from '../_common/filter';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';

type SpecificSettings = {
  filter: FilterItem[];
  isNameEnabled?: boolean;
  isSignEnabled?: boolean;
  isCheckEnabled?: boolean;
  isAmountEnabled?: boolean;
  isPercentEnabled?: boolean;
};

type Props = BannerSettings<SpecificSettings>;

const TotalGenericActionsList: FC<Props> = ({
  filter,
  isNameEnabled,
  isSignEnabled,
  isCheckEnabled,
  isAmountEnabled,
  isPercentEnabled,
}) => {
  const { t } = useTranslation();

  const { currency } = useCurrency();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);

  const {
    actionsList,
    FIELDS,
    updateActionPercent,
    updateActionAmount,
    toggleActionIsSelected,
    toggleActionSign,
    updateActionName,
  } = useTotalPage();

  const updateName = useCallback<typeof updateActionName>(
    async (record, value) => {
      if (!value) return;

      updateActionName(record, value);
    },
    [updateActionName],
  );

  const recordsList = useMemo<ModelAction[]>(
    () => handleFiltering(actionsList, filter),
    [filter, actionsList],
  );

  return (
    <>
      {recordsList.map(item => {
        const isDisabled = item[FIELDS.actions.disabled] || isConfigurationComplete;
        return (
          <ActionItem
            key={item[FIELDS.actions.itemNumber]}
            t={t}
            description={t(
              TOTAL_ACTIONS_RULES_TRANSLATIONS_CODES[item[FIELDS.actions.rule]],
            )}
            record={item}
            updateAmount={updateActionAmount}
            updatePercent={updateActionPercent}
            updateName={updateName}
            toggleCheck={toggleActionIsSelected}
            toggleSign={toggleActionSign}
            FIELDS={FIELDS}
            currency={currency}
            disabled={{
              name: isDisabled || !isNameEnabled,
              sign: isDisabled || !isSignEnabled,
              check: isDisabled || !isCheckEnabled,
              amount: isDisabled || !isAmountEnabled,
              percent: isDisabled || !isPercentEnabled,
            }}
          />
        );
      })}
    </>
  );
};

export default TotalGenericActionsList;
