import {
  SoftOfferOptionsKeys,
  SoftOfferAcceptionQuestions,
  SoftOfferAcceptionQuestionsFields,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik, Form } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import Checkbox from 'components/form/Checkbox';
import { ScCheckboxFormFieldWrapper, ScCollapse, ScCollapsePanel } from '../styles';
import Switcher from 'components/form/Switcher';
import { isFieldRequired } from 'utils/is-field-required';
import { SOFT_OFFER_INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE_OTHER } from 'common/constants';
import { Input } from 'components/form/Input';

const SUB_ROW_SPACES: [number, number] = [16, 0];

const tPrefix = 'SOFT_OFFER_ACCEPTION_QUESTIONS_';
const testPrefix = 'soft-offer_acception-questions_';

type FormValues = SoftOfferAcceptionQuestions;

interface Props {
  isDisabled: boolean;
  initialValues?: SoftOfferAcceptionQuestions;
  onSubmit(values: SoftOfferAcceptionQuestions): void;
  setIsTabLocked?(value: boolean): void;
}

const AcceptionQuestionsForm: FC<Props> = ({
  onSubmit,
  initialValues,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const insuranceRefusedPreviouslyList =
    options?.[SoftOfferOptionsKeys.InsuranceRefusedPreviously]?.options ?? [];
  const thirdPartyInsuranceProvidersList =
    options?.[SoftOfferOptionsKeys.ThirdPartyInsuranceProviders]?.options ?? [];

  const schema = getSchema(t);

  const getActiveTabsKeys = (values: SoftOfferAcceptionQuestions): string[] => {
    return [
      SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously,
      SoftOfferAcceptionQuestionsFields.isDeductedPreviously,
      SoftOfferAcceptionQuestionsFields.isThirdPartyInsured,
      SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently,
      SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently,
      SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur,
    ].filter(key => values?.[key]);
  };

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const assembledInitialValues = useMemo<FormValues>(() => {
    const securityDepositInitialValues = initialValues!;

    return {
      ...securityDepositInitialValues,
    };
  }, [initialValues]);

  const formProps = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: assembledInitialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue, values, dirty } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  return (
    <FormikProvider value={formProps}>
      <Form data-testid="soft-offer-step-form">
        <ScCollapse activeKey={getActiveTabsKeys(values)}>
          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously}
                  component={Switcher}
                  checked={
                    values?.[
                      SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously
                    ]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isInsuranceRefusedPreviously'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_INSURANCE_REFUSED_PREVIOUSLY')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col sm={12}>
                    <FormField
                      id={
                        SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode
                      }
                      name={
                        SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode
                      }
                      component={Select}
                      required={isFieldRequired(
                        schema,
                        SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode,
                      )}
                      options={mapToSelectOptions<OlistItemOption>(
                        insuranceRefusedPreviouslyList,
                        {
                          label: OlistItemOptionFields.label,
                          value: OlistItemOptionFields.value,
                        },
                      )}
                      label={t(tPrefix + 'INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE')}
                      data-testid={testPrefix + 'insuranceRefusedPreviouslyReasonCode'}
                      disabled={
                        isDisabled ||
                        !values?.[
                          SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously
                        ]
                      }
                    />
                  </Col>
                  {values?.[
                    SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode
                  ] === SOFT_OFFER_INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE_OTHER && (
                    <Col sm={12}>
                      <FormField
                        id={
                          SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReason
                        }
                        name={
                          SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReason
                        }
                        component={Input}
                        required={isFieldRequired(
                          schema,
                          SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReason,
                        )}
                        label={t(tPrefix + 'INSURANCE_REFUSED_PREVIOUSLY_REASON')}
                        data-testid={testPrefix + 'insuranceRefusedPreviouslyReason'}
                        disabled={
                          isDisabled ||
                          !values?.[
                            SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously
                          ]
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isDeductedPreviously}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isDeductedPreviously}
                  component={Switcher}
                  checked={
                    values?.[SoftOfferAcceptionQuestionsFields.isDeductedPreviously]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isDeductedPreviously,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isDeductedPreviously'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_DEDUCTED_PREVIOUSLY')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <FormField
                  id={SoftOfferAcceptionQuestionsFields.deductedPreviouslyReason}
                  name={SoftOfferAcceptionQuestionsFields.deductedPreviouslyReason}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferAcceptionQuestionsFields.deductedPreviouslyReason,
                  )}
                  data-testid={testPrefix + 'deductedPreviouslyReason'}
                  label={t(tPrefix + 'DEDUCTED_PREVIOUSLY_REASON')}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferAcceptionQuestionsFields.isDeductedPreviously]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isThirdPartyInsured}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isThirdPartyInsured}
                  component={Switcher}
                  checked={
                    values?.[SoftOfferAcceptionQuestionsFields.isThirdPartyInsured]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isThirdPartyInsured,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isThirdPartyInsured'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_THIRD_PARTY_INSURED')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col sm={12}>
                    <FormField
                      id={SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceCode}
                      name={SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceCode}
                      component={Select}
                      loading={isLoading}
                      options={mapToSelectOptions<OlistItemOption>(
                        thirdPartyInsuranceProvidersList,
                        {
                          label: OlistItemOptionFields.label,
                          value: OlistItemOptionFields.value,
                        },
                      )}
                      required={isFieldRequired(
                        schema,
                        SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceCode,
                      )}
                      label={t(tPrefix + 'THIRD_PARTY_INSURANCE_CODE')}
                      data-testid={testPrefix + 'thirdPartyInsuranceCode'}
                      disabled={
                        isDisabled ||
                        !values?.[SoftOfferAcceptionQuestionsFields.isThirdPartyInsured]
                      }
                    />
                  </Col>
                  <Col sm={12}>
                    <FormField
                      id={SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceNumber}
                      name={SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceNumber}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceNumber,
                      )}
                      label={t(tPrefix + 'THIRD_PARTY_INSURANCE_NUMBER')}
                      data-testid={testPrefix + 'thirdPartyInsuranceNumber'}
                      disabled={
                        isDisabled ||
                        !values?.[SoftOfferAcceptionQuestionsFields.isThirdPartyInsured]
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled}
                  component={Switcher}
                  checked={
                    values?.[
                      SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled
                    ]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isDaysTrackIndicatorEnabled'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_DAYS_TRACK_INDICATOR_ENABLED')}
              </>
            }
          />

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently}
                  component={Switcher}
                  checked={
                    values?.[
                      SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently
                    ]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isBannedFromDrivingRecently'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_BANNED_FROM_DRIVING_RECENTLY')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <FormField
                  id={SoftOfferAcceptionQuestionsFields.bannedFromDrivingRecentlyReason}
                  name={SoftOfferAcceptionQuestionsFields.bannedFromDrivingRecentlyReason}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferAcceptionQuestionsFields.bannedFromDrivingRecentlyReason,
                  )}
                  label={t(tPrefix + 'BANNED_FROM_DRIVING_RECENTLY_REASON')}
                  data-testid={testPrefix + 'bannedFromDrivingRecentlyReason'}
                  disabled={
                    isDisabled ||
                    !values?.[
                      SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently
                    ]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently}
                  component={Switcher}
                  checked={
                    values?.[
                      SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently
                    ]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isRestrictedInDrivingRecently'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_RESTRICTED_IN_DRIVING_RECENTLY')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <FormField
                  id={SoftOfferAcceptionQuestionsFields.restrictedInDrivingRecentlyReason}
                  name={
                    SoftOfferAcceptionQuestionsFields.restrictedInDrivingRecentlyReason
                  }
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferAcceptionQuestionsFields.restrictedInDrivingRecentlyReason,
                  )}
                  label={t(tPrefix + 'RESTRICTED_IN_DRIVING_RECENTLY_REASON')}
                  data-testid={testPrefix + 'restrictedInDrivingRecentlyReason'}
                  disabled={
                    isDisabled ||
                    !values?.[
                      SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently
                    ]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>

          <ScCollapsePanel
            showArrow={false}
            key={SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur}
            header={
              <>
                <FormField
                  name={SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur}
                  component={Switcher}
                  checked={
                    values?.[SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur]
                  }
                  onClick={(value, event) => {
                    event.stopPropagation();
                    setFieldValue(
                      SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur,
                      value,
                    );
                  }}
                  data-testid={testPrefix + 'isDangerousDriverOccur'}
                  disabled={isDisabled}
                />
                {t(tPrefix + 'IS_DANGEROUS_DRIVER_OCCUR')}
              </>
            }
          >
            <Row>
              <Col md={18}>
                <FormField
                  id={SoftOfferAcceptionQuestionsFields.dangerousDriverOccurReason}
                  name={SoftOfferAcceptionQuestionsFields.dangerousDriverOccurReason}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferAcceptionQuestionsFields.dangerousDriverOccurReason,
                  )}
                  label={t(tPrefix + 'DANGEROUS_DRIVER_OCCUR_REASON')}
                  data-testid={testPrefix + 'dangerousDriverOccurReason'}
                  disabled={
                    isDisabled ||
                    !values?.[SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur]
                  }
                />
              </Col>
            </Row>
          </ScCollapsePanel>
        </ScCollapse>

        <ScCheckboxFormFieldWrapper>
          <FormField
            id={SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive}
            name={SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive}
            component={Checkbox}
            required={isFieldRequired(
              schema,
              SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive,
            )}
            checked={
              values?.[SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive]
            }
            disabled={isDisabled}
            label={t('IS_ALL_DRIVERS_LICENCED_TO_DRIVE')}
            data-testid={testPrefix + 'isAllDriversLicencedToDrive'}
          />
        </ScCheckboxFormFieldWrapper>

        <ScCheckboxFormFieldWrapper>
          <FormField
            id={SoftOfferAcceptionQuestionsFields.isAllDriversResidents}
            name={SoftOfferAcceptionQuestionsFields.isAllDriversResidents}
            component={Checkbox}
            required={isFieldRequired(
              schema,
              SoftOfferAcceptionQuestionsFields.isAllDriversResidents,
            )}
            checked={values?.[SoftOfferAcceptionQuestionsFields.isAllDriversResidents]}
            disabled={isDisabled}
            label={t('IS_ALL_DRIVERS_RESIDENTS')}
            data-testid={testPrefix + 'isAllDriversResidents'}
          />
        </ScCheckboxFormFieldWrapper>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default AcceptionQuestionsForm;
