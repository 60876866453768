import { DatePicker as AntDatePicker } from 'antd';
import { basicInputStyle, inputStyles } from 'components/form/styles';
import styled, { css } from 'styled-components';

const { RangePicker } = AntDatePicker;

export const ScRangePickerWrapper = styled(RangePicker)`
  &:focus,
  &:hover,
  &.ant-input-focused {
    box-shadow: none;
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: ${({ theme }) => theme.mainColor};
    box-shadow: none;
  }

  & .ant-picker-active-bar {
    background: none;
  }
`;

export const ScDatePicker = styled(AntDatePicker)`
  &.ant-picker input {
    ${basicInputStyle}
    border: none;
  }

  ${inputStyles}
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;

  &:focus,
  &:hover,
  &.ant-input-focused {
    box-shadow: none;
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: ${({ theme }) => theme.mainColor};
  }
`;

const datePickerStyles = css`
  .ant-picker-panel {
    font-size: 0.75em;
  }

  .ant-picker-header-view {
    font-weight: 600;
  }

  .ant-picker-body thead tr th {
    font-weight: 600;
    text-align: center;
  }

  .ant-picker-header-view button:hover {
    color: ${props => props.theme.mainColor};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: ${props => props.theme.mainColor_hovered};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background-color: ${props => props.theme.mainColor_pressed};
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background-color: ${props => props.theme.mainColor_pressed};
  }

  .ant-picker-cell .ant-picker-cell-inner {
    font-weight: 600;
    width: 100%;
    height: 100%;
  }

  .ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid #000;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.mainColor};
    border: 1px solid #000000;
    color: ${props => props.theme.mainColor_contrast};
  }

  .ant-picker-today-btn {
    color: ${({ theme }) => theme.mainColor};
  }
`;

export const DatePickerContainer = styled.div`
  ${datePickerStyles}
`;

export const RangePickerContainer = styled.div`
  ${datePickerStyles}

  .ant-picker-cell {
    overflow-x: hidden;

    &:hover {
      .ant-picker-cell-inner {
        background-color: transparent;
      }
    }

    &.ant-picker-cell-in-range,
    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        border: none;
      }

      &::before {
        height: 85%;
        left: 0;
        right: 0;
        background-color: ${({ theme }) => theme.colors.metalicLight};
      }
    }

    &.ant-picker-cell-range-start,
    &.ant-picker-cell-range-end {
      &::before {
        background-color: ${({ theme }) => theme.mainColor};
      }
    }

    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        border: none;

        &::before {
          border: none;
        }
      }
    }

    .ant-picker-cell-inner {
      padding: 0.3em 0.6em;
      border: none;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-range {
    &::before {
      height: 85%;
    }
  }
`;
