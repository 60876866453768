import * as Yup from 'yup';
import {
  SoftOfferAcceptionQuestionsFields,
  SoftOfferAcceptionQuestions,
} from 'types/vendor';
import { TFunction } from 'i18next';
import { SOFT_OFFER_INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE_OTHER } from 'common/constants';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<SoftOfferAcceptionQuestions>>({
    [SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode]:
      Yup.string().when(SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously, {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      }),
    [SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReason]:
      Yup.string().when(
        [
          SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously,
          SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode,
        ],
        {
          is: (
            isInsuranceRefusedPreviously: boolean,
            insuranceRefusedPreviouslyReasonCode: string,
          ) =>
            isInsuranceRefusedPreviously &&
            insuranceRefusedPreviouslyReasonCode ===
              SOFT_OFFER_INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE_OTHER,
          then: Yup.string().required(t('FORM__REQUIRED')),
        },
      ),
    [SoftOfferAcceptionQuestionsFields.isDeductedPreviously]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.deductedPreviouslyReason]: Yup.string().when(
      SoftOfferAcceptionQuestionsFields.isDeductedPreviously,
      {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferAcceptionQuestionsFields.isThirdPartyInsured]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceCode]: Yup.string().when(
      SoftOfferAcceptionQuestionsFields.isThirdPartyInsured,
      {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceNumber]: Yup.string().when(
      SoftOfferAcceptionQuestionsFields.isThirdPartyInsured,
      {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.bannedFromDrivingRecentlyReason]:
      Yup.string().when(SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently, {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      }),
    [SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.restrictedInDrivingRecentlyReason]:
      Yup.string().when(SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently, {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      }),
    [SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.dangerousDriverOccurReason]: Yup.string().when(
      SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur,
      {
        is: true,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive]: Yup.boolean(),
    [SoftOfferAcceptionQuestionsFields.isAllDriversResidents]: Yup.boolean(),
  });
};
