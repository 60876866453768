import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentItem,
  DocumentItemDetails,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
} from 'context/document/types';
import { DocumentsScreenListItemDetailed } from '@hypercharge/xdms-client/lib/_backendMethods/screens/documents/types/detailed';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentFormValues } from './types';
import DocumentForm from './DocumentForm';
import { ScFormWrap } from './DocumentForm.styles';

interface Props {
  document: DocumentItem;
  relatedEntity: DocumentRelatedEntity;
  relatedEntityCode: DocumentRelatedEntityCode;
  isSaveHidden?: boolean;
  reload?(): void;
}

const DocumentFormUpdate: FC<Props> = ({
  document,
  relatedEntity,
  relatedEntityCode,
  isSaveHidden,
  reload,
}) => {
  const { t } = useTranslation();

  const { getDocumentDetails, updateDocument } = useDocumentsApi();

  const [details, setDetails] = useState<DocumentItemDetails>();

  useEffect(() => {
    getDocumentDetails(document, relatedEntityCode).then(response => {
      setDetails(response.response ?? undefined);
    });
  }, [getDocumentDetails, document, relatedEntityCode]);

  const formInitialData = useMemo<DocumentFormValues | undefined>(() => {
    if (!details) return;

    return {
      url: details.fileUrl,
      names: [details.name],
      type: details.type,
      typeCategory: details.typeCategory,
      status: details.status,
    };
  }, [details]);

  const onSubmit = useCallback(
    async (values: DocumentFormValues) => {
      const data: Partial<DocumentsScreenListItemDetailed> = {
        name: values.names?.[0],
        type: values.type,
        typeCategory: values.typeCategory,
        status: values.status,
      };

      if (details?.computed.isLink) {
        data.fileUrl = values.url;
      }

      const { status, messageHandled } = await updateDocument(
        document.rowid,
        data,
        relatedEntityCode,
        relatedEntity,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('DOCUMENT_UPDATE_SUCCESS'),
          [Status.Error]: t('DOCUMENT_UPDATE_ERROR'),
        });
      }
      reload?.();
    },
    [
      document.rowid,
      relatedEntity,
      relatedEntityCode,
      updateDocument,
      t,
      reload,
      details,
    ],
  );

  return (
    <ScFormWrap>
      <DocumentForm
        relatedEntityCode={relatedEntityCode}
        onSubmit={onSubmit}
        isCreate={false}
        details={details}
        initialValues={formInitialData}
        isLink={details?.computed.isLink}
        isFile={details?.computed.isFile}
        disabled={!details?.computed.isEditable}
        isSaveHidden={isSaveHidden}
      />
    </ScFormWrap>
  );
};

export default DocumentFormUpdate;
