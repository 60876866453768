import React, { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as StreamingAvailableIcon } from 'assets/icons/streaming-available.svg';
import { ReactComponent as StreamingActiveIcon } from 'assets/icons/streaming-active.svg';
import { ReactComponent as StreamingBrokenIcon } from 'assets/icons/streaming-broken.svg';
import { ReactComponent as StreamingWaitingIcon } from 'assets/icons/streaming-waiting.svg';
import { streamingSelectors } from 'store/streaming';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { Dropdown } from 'components/dropdown';
import ImageGalleryModal from 'components/ImageGalleryModal';
import useStreamingCarGallery from 'components/streaming/useStreamingCarGallery';
import useStreamingAvailableRecords from 'components/streaming/useStreamingAvailableRecords';
import useStreamingInstalledRecords from 'components/streaming/useStreamingInstalledRecords';
import StreamingRecordsPreviewer from 'components/streaming/StreamingRecordsPreviewer';
import { useSelector } from 'react-redux';
import {
  ScStreamingMenu,
  ScStreamingMenuItem,
  ScStreamingMenuItemWrapper,
} from './index.styles';
import { Button } from 'components/button/Button';
import StreamingSettings from '../StreamingSettings';
import { StreamingOption } from './types';
import useMenu from './useMenu';
import { configurationSelectors } from 'store';
import StreamingPackageDetails from '../StreamingPackageDetails';
import { PreviewImage, PreviewImageType } from 'types';

const StreamingMenuSelector = () => {
  const [currentPackage, setCurrentPackage] = useState<string | null>(null);

  const configurator = useSelector(streamingSelectors.getConfigurator);
  const isStreamBroken = useSelector(streamingSelectors.getIsStreamBroken);
  const isStreamWaiting = useSelector(streamingSelectors.getIsStreamWaiting);

  const { t } = useTranslation();
  const activeTerminal = useSelector(streamingSelectors.getActiveTerminal);
  const isEnabled = useSelector(streamingSelectors.getIsEnabled);
  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const {
    carouselImages: streamingCarouselImages,
    handleSlide: handleStreamingVehicleCarSlide,
    handleClose: handleStreamingVehicleCarClose,
  } = useStreamingCarGallery();

  const { options, selectedOption, selectOption } = useMenu();

  const { displayData: availableRecords } = useStreamingAvailableRecords({
    enabled: selectedOption === StreamingOption.AVAILABLE_RECORDS,
  });

  const { displayData: installedRecords } = useStreamingInstalledRecords({
    enabled: selectedOption === StreamingOption.INSTALLED_RECORDS,
  });

  const streamingIcon = useMemo(() => {
    if (isStreamBroken) {
      return <StreamingBrokenIcon />;
    }

    if (isStreamWaiting) {
      return <StreamingWaitingIcon />;
    }

    if (activeTerminal) {
      return <StreamingActiveIcon />;
    }

    return <StreamingAvailableIcon />;
  }, [activeTerminal, isStreamBroken, isStreamWaiting]);

  const streamingTooltipTitle = useMemo(() => {
    if (isStreamBroken) {
      return t('STREAMING_CONFIGURATOR_BROKEN', { confNumber: configurationNumber });
    }

    if (activeTerminal) {
      return t('STREAMING_CONFIGURATOR_STREAMING', { confNumber: configurationNumber });
    }

    return '';
  }, [activeTerminal, configurationNumber, isStreamBroken, t]);

  const handleCloseCarPreview = useCallback(() => {
    handleStreamingVehicleCarClose();
    selectOption(null);
  }, [handleStreamingVehicleCarClose, selectOption]);

  const menu = useMemo(() => {
    return (
      <ScStreamingMenu>
        {options
          .filter(menuOption => menuOption.available)
          .map(menuOption => {
            return (
              <ScStreamingMenuItem
                key={menuOption.label}
                onClick={menuOption.handler}
                disabled={menuOption.disabled}
              >
                <Tooltip placement="rightTop" title={menuOption.title}>
                  <ScStreamingMenuItemWrapper>
                    {menuOption.icon}
                    {menuOption.label}
                  </ScStreamingMenuItemWrapper>
                </Tooltip>
              </ScStreamingMenuItem>
            );
          })}
      </ScStreamingMenu>
    );
  }, [options]);

  const handleRecordsPreviewClick = useCallback((image: PreviewImage) => {
    if (image.type !== PreviewImageType.Package) return;

    setCurrentPackage(image.code);
  }, []);

  const handlePackageDetailsReset = useCallback(() => {
    setCurrentPackage(null);
  }, []);

  const handleRecordsPreviewClose = useCallback(() => {
    selectOption(null);
    setCurrentPackage(null);
  }, [selectOption]);

  const floatHolder = document.getElementById('float-holder');

  const streamingFloatingButton =
    floatHolder && isEnabled
      ? createPortal(
          <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
            <Tooltip placement="right" title={streamingTooltipTitle}>
              {/* need wrapper div otherwise tooltip does not interact with disabled button */}
              <div>
                <Button
                  variant="highlighted"
                  shape="circle"
                  icon={streamingIcon}
                  disabled={isStreamBroken}
                />
              </div>
            </Tooltip>
          </Dropdown>,
          floatHolder,
        )
      : null;

  return (
    <>
      {streamingFloatingButton}

      {selectedOption === StreamingOption.SETTINGS && configurator && (
        <StreamingSettings configuratorId={configurator.id} />
      )}

      {selectedOption === StreamingOption.CAR_PREVIEW && (
        <ImageGalleryModal
          images={streamingCarouselImages}
          visible
          onCancel={handleCloseCarPreview}
          onSlide={handleStreamingVehicleCarSlide}
        />
      )}

      {selectedOption === StreamingOption.AVAILABLE_RECORDS && (
        <StreamingRecordsPreviewer
          onClose={handleRecordsPreviewClose}
          displayData={availableRecords}
          onClick={handleRecordsPreviewClick}
        />
      )}

      {selectedOption === StreamingOption.INSTALLED_RECORDS && (
        <StreamingRecordsPreviewer
          onClose={handleRecordsPreviewClose}
          displayData={installedRecords}
          onClick={handleRecordsPreviewClick}
        />
      )}

      {currentPackage && (
        <StreamingPackageDetails
          code={currentPackage}
          onReset={handlePackageDetailsReset}
          isActive={selectedOption === StreamingOption.INSTALLED_RECORDS}
          isSelected={selectedOption === StreamingOption.INSTALLED_RECORDS}
          withToRemove={selectedOption === StreamingOption.INSTALLED_RECORDS}
        />
      )}
    </>
  );
};

export default StreamingMenuSelector;
