/**
 * Added to compare form values to original record.
 * @desc Fields from first arg is used for comparison.
 * @param {Record<string, unknown> | null | undefined} values
 * @param {Record<string, unknown> | null | undefined} record
 * @return {boolean}
 */
export const checkFormValuesDiffersFromRecord = (
  values: Record<string, unknown> | null | undefined,
  record: Record<string, unknown> | null | undefined,
): boolean => {
  if (!values && !record) return false;
  if (!values || !record) return true;
  return Object.entries(values).some(([key, value]) => {
    return record[key] !== value;
  });
};
