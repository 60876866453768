import styled from 'styled-components';

export const ScWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .ant-form-item {
    flex-grow: 1;
  }
`;

export const ScSeparator = styled.span`
  margin: 0 0.5em;
`;

export const ScLabel = styled.span`
  font-size: ${({ theme }) => theme.inputFontSize};
  color: ${({ theme }) => theme.textColor};
  height: auto;
  margin-bottom: 0.438em;
  vertical-align: top;
  white-space: nowrap;
`;
