import { FiltersListItem } from 'components/filters/FiltersListItem';
import { DataIndex } from 'components/table/types';
import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ScClearFiltersButton, ScFiltersListWrapper } from './FiltersList.styles';
import { FilterField } from 'types/vendor';

export type SelectedFiltersItemsList_Filters<T = DataIndex> = (FilterField<T> & {
  displayValue?: string;
})[];

interface SelectedFiltersItemsListProps {
  filters: SelectedFiltersItemsList_Filters;
  onClearAll(event: MouseEvent<HTMLButtonElement>): void;
  onClearOne(dataIndex: DataIndex): void;
  tableTitles: { [key: string]: string };
}
export const FiltersList: FC<SelectedFiltersItemsListProps> = ({
  filters,
  onClearAll,
  onClearOne,
  tableTitles,
}) => {
  const { t } = useTranslation();
  const isClearButtonDisabled = !filters.length;

  return (
    <ScFiltersListWrapper>
      <ScClearFiltersButton
        onClick={onClearAll}
        variant="next"
        data-testid="clear-filters-btn"
        disabled={isClearButtonDisabled}
      >
        {t('CLEAR_FILTERS')}
      </ScClearFiltersButton>

      {filters.map(filterItem => {
        const dataIndex = filterItem.name;
        const value = filterItem.displayValue || filterItem.value + '';

        return (
          <FiltersListItem
            key={String(dataIndex)}
            title={tableTitles[String(dataIndex)]}
            value={value}
            dataIndex={dataIndex}
            onClear={onClearOne}
          />
        );
      })}
    </ScFiltersListWrapper>
  );
};
