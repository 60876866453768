import { ReactNode } from 'react';
import { Message } from '@hypercharge/xdms-client/lib/types';
import { Status } from '../types';

export enum NotificationType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export type NotificationMessage = Message | Message[] | string;

export interface NotificationProps {
  message?: NotificationMessage | null;
  header?: string;
  icon?: ReactNode;
  type?: NotificationType;
  getContainer?(): HTMLElement;
}

export type NotificationReference = string | string[];

export type NotificationMessageByStatus = {
  [key in keyof Partial<typeof Status>]: string;
};
