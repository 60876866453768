import React, { FC, useMemo } from 'react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { Col, Row } from 'antd';
import FormField from 'components/form/formik/FormField';
import { Input } from '../../components/form/Input';
import { Button } from '../../components/button';
import { useTranslation } from 'react-i18next';
import { getLoginFormSchema, LoginFormValues } from './LoginFormMeta';

interface Props {
  initialValues: LoginFormValues;
  onSubmit(values: LoginFormValues, actions: FormikHelpers<LoginFormValues>): void;
}

const LoginForm: FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();

  const formSchema = useMemo(() => getLoginFormSchema(t), [t]);

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={formSchema}
      data-testid="xdms-setting-page-form-wrapper"
    >
      {(formProps: FormikProps<LoginFormValues>) => {
        return (
          <form
            noValidate
            onSubmit={formProps.handleSubmit}
            data-testid="login-page-form"
          >
            <Row>
              <Col xs={24}>
                <FormField
                  id="userName"
                  component={Input}
                  disabled={formProps.isSubmitting}
                  name="userName"
                  placeholder={t('AUTH__USER')}
                  data-testid="login-page-form-user-name-field"
                  autoComplete="on"
                />
              </Col>
              <Col xs={24}>
                <FormField
                  id="password"
                  component={Input}
                  disabled={formProps.isSubmitting}
                  name="password"
                  placeholder={t('AUTH__PASSWORD')}
                  type="password"
                  data-testid="login-page-form-password-field"
                  autoComplete="on"
                />
              </Col>
            </Row>
            <Button
              loading={formProps.isSubmitting}
              variant="primary"
              fullwidth
              noBorder
              htmlType="submit"
              data-testid="login-page-form-submit-button"
            >
              {t('AUTH__CONTINUE')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
