import {
  DocumentTemplate_GetPDFTemplatesList_Args_Entities,
  PrintTemplateListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { PRIMARY_DYNAMIC_FIELD } from 'common/constants';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useDocumentTemplateApi } from 'context/documentTemplate/useDocumentTemplateApi';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { TradeInComponent, useTradeIn } from 'context/tradeIn/TradeInProvider';
import { useModalState } from 'hooks/useModalState';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  TradeInSendEmailModal,
  TradeInSendEmailModalProps,
} from './TradeInSendEmailModal';
import { TradeInForm } from './TradeInForm';
import { TradeInTable } from './TradeInTable';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentItem, DocumentRelatedEntityCode } from 'context/document/types';
import { useSelector } from 'react-redux';
import { documentTemplatesSelectors, modelSelectors } from 'store';
import { StreamingEventType } from 'context/streaming/types';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';

const dictionary = {
  tradeInForm: TradeInForm,
  tradeInTable: TradeInTable,
};

export const TradeIn: FC = () => {
  const { t, i18n } = useTranslation();
  const { iso: languageISOCode } = useCurrentLanguageCode();
  const tradeInContext = useTradeIn();
  const { component, setTradeInTableComponent } = tradeInContext;
  const { currencyCode } = useCurrency();
  const { pathname } = useLocation();
  const { getPDFTemplates } = useDocumentTemplateApi();
  const { handlePrevStep, handleNextStep } = useStep();
  const { sendMessage } = useStreaming();

  const pdfTemplates = useSelector(documentTemplatesSelectors.getTemplatesList);
  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);
  const modelToken = useSelector(modelSelectors.getToken);

  const { getDocumentsList } = useDocumentsApi();

  const [sendEmailModalState, handleSendEmailModal] = useModalState<
    TradeInSendEmailModalProps,
    Record<string, never>,
    TradeInSendEmailModalProps
  >({});

  // should allow only when pdf templates are re-fetched
  // otherwise there is a chance the page can break due to
  // different pdf templates already set in previous page
  const [canPassPdfTemplates, setCanPassPdfTemplates] = useState<boolean>(false);

  useEffect(() => {
    setTradeInTableComponent();
    setCanPassPdfTemplates(false);
  }, [pathname, setTradeInTableComponent]);

  useEffect(() => {
    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            optionType: null,
            token: modelToken,
          },
        },
      });
    }
  }, [modelToken, sendMessage]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'selection',
      },
    });
  }, [sendMessage]);

  const getTradeInDocuments = useCallback(
    async (machineNumber: string): Promise<DocumentItem[] | undefined> => {
      const tradeIn = tradeInContext.getTradeInByMachineNumber(machineNumber);
      if (!tradeIn) return;

      const { response } = await getDocumentsList(
        DocumentRelatedEntityCode.tradeIn,
        tradeIn as any,
      );
      return response ?? [];
    },
    [tradeInContext, getDocumentsList],
  );

  const getTradeInPDFTemplates = useCallback(async () => {
    await getPDFTemplates({
      entity: DocumentTemplate_GetPDFTemplatesList_Args_Entities.tradeIn,
    });
    setCanPassPdfTemplates(true);
  }, [getPDFTemplates]);

  const getTradeInFormProps = useCallback(() => {
    const primaryPdfTemplates = pdfTemplates.filter(
      pdfTemplate =>
        pdfTemplate[PrintTemplateListItemFields.dynamicField] === PRIMARY_DYNAMIC_FIELD,
    );

    return {
      pdfTemplates: canPassPdfTemplates ? primaryPdfTemplates : [],
      getTradeInPDFTemplates,
    };
  }, [canPassPdfTemplates, pdfTemplates, getTradeInPDFTemplates]);

  const getTradeInTableProps = useCallback(() => {
    return {
      getTradeInDocuments,
      currencyCode,
      language: i18n.language,
      handlePrevStep,
      handleNextStep,
    };
  }, [i18n.language, getTradeInDocuments, currencyCode, handlePrevStep, handleNextStep]);

  const handleTradeInSendEmail = useCallback(
    (machineNumber: string) => {
      const tradeIn = tradeInContext.getTradeInByMachineNumber(machineNumber);
      if (!tradeIn) return;
      handleSendEmailModal({
        onClose: () => handleSendEmailModal(undefined),
        relatedEntity: tradeIn as any,
        initialSubject: t('TRADE_IN_SEND_EMAIL_SUBJECT', {
          brand: tradeIn?.brandName,
          model: tradeIn?.modelName,
          lng: languageISOCode,
        }),
      });
    },
    [t, tradeInContext, handleSendEmailModal, languageISOCode],
  );

  const componentToProps = useMemo(() => {
    return {
      [TradeInComponent.Form]: getTradeInFormProps(),
      [TradeInComponent.Table]: getTradeInTableProps(),
    };
  }, [getTradeInFormProps, getTradeInTableProps]);

  const Result = useMemo(() => {
    const Component = dictionary[component];

    const componentSpecificProps = componentToProps[component] ?? {};

    if (Component) {
      return (
        <>
          {sendEmailModalState && <TradeInSendEmailModal {...sendEmailModalState} />}
          <Component
            t={t}
            tradeInContext={tradeInContext}
            isConfigurationComplete={isConfigurationComplete}
            handleTradeInSendEmail={handleTradeInSendEmail}
            {...componentSpecificProps}
          />
        </>
      );
    }

    return <div>{t('TRADE_IN_STUB')}</div>;
  }, [
    component,
    t,
    tradeInContext,
    isConfigurationComplete,
    componentToProps,
    sendEmailModalState,
    handleTradeInSendEmail,
  ]);

  return Result;
};
