import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import * as Sentry from '@sentry/react';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';
import { PackageDetails } from './types';

type Args = {
  code: string;
  isSelected?: boolean;
  isActive?: boolean;
  withToRemove?: boolean;
};

export default function usePackageDetails({
  code,
  isSelected = false,
  isActive = false,
  withToRemove = false,
}: Args): Result {
  const [packageDetails, setPackageDetails] = useState<PackageDetails | null>(null);

  const currentLanguageCode = useCurrentLanguageCode();

  const modelToken = useSelector(modelSelectors.getToken);

  const onReset = useCallback(() => {
    setPackageDetails(null);
  }, []);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_STREAMING_SERVER_API_ENDPOINT as string;

    if (!modelToken) return;

    const requestBody: RequestBody = {
      marketOrLocale: currentLanguageCode.iso,
      token: modelToken,
      isSelected,
      isActive,
      withToRemove,
    };

    fetch(`${baseUrl}/api/package-details/${code}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then<{ data: PackageDetails | null }>(res => res.json())
      .then(res => {
        setPackageDetails(res.data);
      })
      .catch(err => {
        Sentry.captureException(err, { extra: requestBody });
      });
  }, [modelToken, currentLanguageCode, isSelected, isActive, withToRemove, code]);

  return { data: packageDetails, onReset };
}

type RequestBody = {
  marketOrLocale: string;
  token: string;
  isSelected?: boolean;
  isActive?: boolean;
  withToRemove: boolean;
};

type Result = {
  data: PackageDetails | null;
  onReset(): void;
};
