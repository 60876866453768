import {
  FinancingCalculation,
  FinancingCalculationFields,
  FinancingProduct,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';

type DynamicField =
  | {
      name: string;
      type: 'number';
      value: number | string;
      min: number;
      max: number;
    }
  | {
      name: string;
      type: 'select';
      value: number | string;
      options: ReturnType<typeof mapToSelectOptions>;
    };

export const extractFields = (
  calculation: FinancingCalculation,
  product: FinancingProduct,
): DynamicField[] => {
  const fieldsNames = calculation[FinancingCalculationFields.enabledFields];
  if (!fieldsNames?.length) return [];
  return fieldsNames?.map(fullName => {
    const name = fullName.replace('v-', '');
    const min: number = product[`${name}-min`] ?? 0;
    const max: number = product[`${name}-max`] ?? 0;
    const step: number = product[`${name}-step`] ?? 0;
    const list: string[] = calculation[`${fullName}List`]?.split(',') ?? [];

    if (step) {
      const options: { label: string; value: string }[] = [];
      for (let value = min; value < max; value += step) {
        options.push({ label: String(value), value: String(value) });
      }
      return {
        name: name,
        type: 'select' as const,
        value: calculation[fullName],
        options: mapToSelectOptions(options, { label: 'label', value: 'value' }),
      };
    }

    if (list?.length) {
      const options: { label: string; value: string }[] = list.map(value => ({
        label: value,
        value: value,
      }));
      return {
        name: name,
        type: 'select' as const,
        value: calculation[fullName],
        options: mapToSelectOptions(options, { label: 'label', value: 'value' }),
      };
    }

    return {
      name: name,
      type: 'number' as const,
      value: calculation[fullName],
      min: min,
      max: max,
    };
  });
};
