import { CaretDownOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Button } from 'components/button';
import styled from 'styled-components';
import Checkbox from '../../form/Checkbox';
import { mediaMax } from 'common/theme';

export const DocumentStatus = styled.span`
  font-size: 0.75em;
  line-height: 0.914em;
  margin-bottom: 0.625em;
  display: inline-block;
`;

export const ScAddDocumentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125em;

  ${mediaMax.md} {
    height: auto;
    flex-direction: column;
  }
`;

export const DocumentUpload = styled(Upload)`
  width: 100%;

  .ant-upload-select {
    width: 100%;
  }

  .ant-upload-list {
    margin-bottom: 2.063em;
  }

  .ant-upload-list-item-name {
    font-size: 0.75em;
  }
`;

export const ProgressWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  max-width: 6.875em;
  margin-left: 4px;
`;

export const SubmitButton = styled(Button)`
  margin-bottom: 0.75em;
`;

export const DocumentCheckbox = styled(Checkbox)`
  display: inline-flex;
  align-items: center;
`;

export const DocumentCheckboxMultipleNamesWrapper = styled.div`
  margin-bottom: 1.5em;
`;

export const DocumentLabel = styled.span`
  font-size: 0.75em;
  line-height: 0.914em;
  color: ${({ theme }) => theme.colors.black};
`;

export const StatusCheckboxWrapper = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;

  margin-bottom: 0;
  padding: 0.813em 0.938em;
  border: 1px solid
    ${({ $errored, theme }) =>
      $errored ? theme.fieldErrorColor : theme.colors.lightSilver};
  height: inherit;
  position: relative;

  &:last-child {
    margin-left: 0.625em;
  }

  ${mediaMax.md} {
    width: 100%;

    &:first-child {
      margin-bottom: 1.5em;
    }

    &:last-child {
      margin-left: 0;
    }
  }
`;

// keep synced with FormItem styles
export const StatusErrorMessage = styled.div`
  font-size: 0.625em;
  font-weight: 500;
  color: ${({ theme }) => theme.fieldErrorColor};

  position: absolute;
  top: 100%;
  left: 0;
`;

export const DocumentTypeSelectArrowIcon = styled(CaretDownOutlined).attrs({
  style: {
    pointerEvents: 'none',
  },
})``;

export const ScFormWrap = styled.div`
  margin-top: 0.625em;
`;
