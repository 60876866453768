import React, { ComponentProps, FC, useCallback } from 'react';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields } from '@hypercharge/xdms-client/lib/types';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';
import ConfigurationCopyModal from 'components/configuration/ConfigurationCopyModal';
import ConfigurationNewVersionModal from 'components/configuration/ConfigurationNewVersionModal';
import { useAuthApi } from 'context/auth/useAuthApi';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { useSession } from 'context/session/AuthenticatedSessionProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { useModalState } from 'hooks/useModalState';
import { useTranslation } from 'react-i18next';
import {
  ScComplementActionWrapper,
  ScConfigurationAction,
  ScCurrenContainerWrapper,
  ScCurrencyWrapper,
  ScLinksContainer,
  ScStreamingToggleWrapper,
  Separator,
} from './Links.styles';
import { LogoutIcon } from './Logout.styles';
import { useSelector } from 'react-redux';
import {
  hostSettingsSelectors,
  authSelectors,
  featuresFlagsSelectors,
  configurationSelectors,
  streamingSelectors,
  modelSelectors,
} from 'store';
import { useHistory } from 'react-router-dom';
import { notification } from 'utils/notification';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { Currency } from './menus/Currency';
import { CurrentContainer } from './menus/CurrentContainer';
import { Language } from './menus/Language';
import { ScCustomLink } from './menus/styles';
import { StreamingEventType } from 'context/streaming/types';
import { STORAGE_KEYS } from 'common/constants';
import * as storage from 'utils/storage';
import useOpenConfiguration from 'hooks/useOpenConfiguration';
import StreamingToggle from 'components/streaming/StreamingToggle';
import { useGlobalModalsStates } from 'context/globalModalsStates/GlobalModalsStatesProvider';

interface Props {
  handleClickMenuItem(visible: boolean): void;
}

export const Links: FC<Props> = ({ handleClickMenuItem }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { configurationsListUrl, copyConfiguration, createNewVersion } =
    useConfiguration();
  const { logout } = useAuthApi();
  const { setConfigurationImportModalState, setConfigurationMarginModalState } =
    useGlobalModalsStates();
  const { handleNewConfiguration: sessionHandleNewConfiguration } = useSession();
  const { allowImportConfiguration, isFeatureEnabled } = useFeature();
  const { sendMessage } = useStreaming();
  const { handleOpenConfiguration } = useOpenConfiguration();

  const { configurationNumber, configurationDetails } = useSelector(
    configurationSelectors.getAll,
  );
  const { isConfigurationComplete, isConfigurationCreatedFromStock } = useSelector(
    modelSelectors.getVariables,
  );

  const username = useSelector(authSelectors.getUsername);
  const hostname = useSelector(hostSettingsSelectors.getHost);
  const configurator = useSelector(streamingSelectors.getConfigurator);

  const isNewVersionButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderNewVersionButton,
  });
  const isCopyButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderCopyButton,
  });
  const isNewConfigurationButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderNewConfigurationButton,
  });
  const isHeaderMarginFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderMargin,
  });
  const isImportButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderImportButton,
  });
  const isHeaderCurrencyFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderCurrency,
  });
  const isStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowStreaming,
  });

  const isStreamingEnabled =
    !isConfigurationCreatedFromStock && isStreamingFeatureEnabled;

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const customLinkURL = globalFeatures?.[GlobalFeaturesFlagsFields.customLinkURL] ?? '';

  const handleConfigurationsList = useCallback(() => {
    handleClickMenuItem(false);
    history.push(configurationsListUrl);
  }, [configurationsListUrl, handleClickMenuItem, history]);

  const handleClickImportConfiguration = useCallback(() => {
    handleClickMenuItem(false);
    setConfigurationImportModalState({ initialValues: {} });
  }, [handleClickMenuItem, setConfigurationImportModalState]);

  const handleNewConfiguration = useCallback(() => {
    handleClickMenuItem(false);
    sessionHandleNewConfiguration();
  }, [handleClickMenuItem, sessionHandleNewConfiguration]);

  const handleMargin = useCallback(() => {
    handleClickMenuItem(false);
    setConfigurationMarginModalState({});
  }, [handleClickMenuItem, setConfigurationMarginModalState]);

  const [configurationCopyModalState, handleConfigurationCopyModal] = useModalState<
    ComponentProps<typeof ConfigurationCopyModal>,
    Record<string, never>,
    ComponentProps<typeof ConfigurationCopyModal>
  >({});

  const handleCreateCopy = useCallback(
    async (
      configurationNumber: number,
      values: { language: string; quantity: number },
    ) => {
      const response = await copyConfiguration({
        configurationNumber: configurationNumber,
        languageCode: values.language,
        copiesQuantity: values.quantity,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationCopyModal(undefined);

      if (response?.firstConfigurationNumber) {
        await handleOpenConfiguration(response.firstConfigurationNumber);
      }
    },
    [copyConfiguration, handleOpenConfiguration, handleConfigurationCopyModal],
  );

  const handleClickCreateCopy = useCallback(() => {
    handleClickMenuItem(false);

    if (!configurationNumber) return;

    handleConfigurationCopyModal({
      defaultLanguageCode:
        configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
      onSubmit: values => handleCreateCopy(configurationNumber, values),
      onClose: () => handleConfigurationCopyModal(undefined),
    });
  }, [
    handleClickMenuItem,
    configurationNumber,
    handleConfigurationCopyModal,
    configurationDetails,
    handleCreateCopy,
  ]);

  const [configurationNewVersionModalState, handleConfigurationNewVersionModal] =
    useModalState<
      ComponentProps<typeof ConfigurationNewVersionModal>,
      Record<string, never>,
      ComponentProps<typeof ConfigurationNewVersionModal>
    >({});

  const handleCreateNewVersion = useCallback(
    async (configurationNumber: number, values: { language: string }) => {
      const response = await createNewVersion({
        configurationNumber: configurationNumber,
        languageCode: values.language,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationNewVersionModal(undefined);

      if (response?.firstConfigurationNumber) {
        await handleOpenConfiguration(response.firstConfigurationNumber);
      }
    },
    [createNewVersion, handleOpenConfiguration, handleConfigurationNewVersionModal],
  );

  const handleClickCreateNewVersion = useCallback(() => {
    handleClickMenuItem(false);

    if (!configurationNumber) return;

    handleConfigurationNewVersionModal({
      defaultLanguageCode:
        configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
      onSubmit: values => handleCreateNewVersion(configurationNumber, values),
      onClose: () => handleConfigurationNewVersionModal(undefined),
    });
  }, [
    handleClickMenuItem,
    configurationNumber,
    handleConfigurationNewVersionModal,
    configurationDetails,
    handleCreateNewVersion,
  ]);

  const handleClickCustomLink = useCallback(() => {
    handleClickMenuItem(false);
  }, [handleClickMenuItem]);

  const handleLanguageChange = useCallback(() => {
    handleClickMenuItem(false);
  }, [handleClickMenuItem]);

  const handleLogout = useCallback(() => {
    logout({}, () => {
      const [terminal] = configurator?.terminals ?? [];

      if (terminal) {
        sendMessage({
          type: StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
          data: {
            customerId: terminal.customerId,
          },
        });
      }

      sendMessage({
        type: StreamingEventType.CLEAR_SLOTS,
      });

      storage.saveInLocalStorage(STORAGE_KEYS.connectionId, undefined);
    });
  }, [configurator, logout, sendMessage]);

  return (
    <ScLinksContainer data-testid="header-user-drop-down-menu-container">
      <ScCurrenContainerWrapper>
        <CurrentContainer>{t('CURRENT_HOST', { host: hostname })}</CurrentContainer>
      </ScCurrenContainerWrapper>
      <ScCurrenContainerWrapper>
        <CurrentContainer>{t('CURRENT_USER', { user: username })}</CurrentContainer>
      </ScCurrenContainerWrapper>
      <ScConfigurationAction onClick={handleConfigurationsList} icon={<LinkIcon />}>
        {t('CONFIGURATIONS')}
      </ScConfigurationAction>

      {isCopyButtonFeatureEnabled && isConfigurationComplete && (
        <ScConfigurationAction
          onClick={handleClickCreateCopy}
          disabled={!configurationNumber}
          icon={<LinkIcon />}
        >
          {t('COPY_ACTION')}
        </ScConfigurationAction>
      )}

      {isNewVersionButtonFeatureEnabled && (
        <ScConfigurationAction
          onClick={handleClickCreateNewVersion}
          disabled={!configurationNumber}
          icon={<LinkIcon />}
        >
          {t('NEW_VERSION_ACTION')}
        </ScConfigurationAction>
      )}

      {isNewConfigurationButtonFeatureEnabled && (
        <ScConfigurationAction onClick={handleNewConfiguration} icon={<PlusIcon />}>
          {t('NEW_CONFIGURATION')}
        </ScConfigurationAction>
      )}

      {isHeaderMarginFeatureEnabled && (
        <ScConfigurationAction
          onClick={handleMargin}
          disabled={!configurationNumber}
          icon={<PercentIcon />}
        >
          {t('CONFIGURATION_MARGIN')}
        </ScConfigurationAction>
      )}

      {isImportButtonFeatureEnabled && allowImportConfiguration && (
        <ScConfigurationAction
          onClick={handleClickImportConfiguration}
          icon={<ImportIcon />}
        >
          {t('CONFIGURATION_IMPORT')}
        </ScConfigurationAction>
      )}
      {customLinkURL && (
        <ScCustomLink href={customLinkURL} target="_blank">
          <ScConfigurationAction onClick={handleClickCustomLink}>
            {t('CUSTOM_LINK')}
          </ScConfigurationAction>
        </ScCustomLink>
      )}
      <ScConfigurationAction onClick={handleLogout} icon={<LogoutIcon />}>
        {t('LOG_OUT')}
      </ScConfigurationAction>

      <ScComplementActionWrapper>
        {isStreamingEnabled && (
          <ScStreamingToggleWrapper>
            <StreamingToggle />
          </ScStreamingToggleWrapper>
        )}

        {isHeaderCurrencyFeatureEnabled && (
          <ScCurrencyWrapper $isCentered={isStreamingEnabled}>
            <Currency />
            <Separator />
          </ScCurrencyWrapper>
        )}
        <Language handleChange={handleLanguageChange} />
      </ScComplementActionWrapper>

      {configurationNewVersionModalState && (
        <ConfigurationNewVersionModal {...configurationNewVersionModalState} />
      )}
      {configurationCopyModalState && (
        <ConfigurationCopyModal {...configurationCopyModalState} />
      )}
    </ScLinksContainer>
  );
};
