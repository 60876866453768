import styled from 'styled-components';
import { CarOutlined } from '@ant-design/icons';

export const ScPreviewIcon = styled(CarOutlined)`
  && {
    font-size: 1.75em;
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.dustyGray : theme.mainColor};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ScPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  margin-left: 0.5em;
`;
