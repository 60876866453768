import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FINANCING_URL } from 'common/constants';
import { Button } from 'components/button';
import NavButtonsContainer from 'components/button/NavButtonsContainer/NavButtonsContainer';
import { ScButton, ScFooterContainer } from './Footer.styles';

interface Props {
  isSubmitHidden?: boolean;
  isSubmitDisabled?: boolean;
  publishButton?: {
    disabled?: boolean;
    loading?: boolean;
    onPublish?(): void;
  };
}

const Footer: FC<Props> = ({ isSubmitDisabled, isSubmitHidden, publishButton }) => {
  const { t } = useTranslation();

  return (
    <ScFooterContainer>
      <NavButtonsContainer>
        <NavLink to={`${FINANCING_URL}/list`}>
          <Button>{t('FINANCING_VIEW_FINANCING_LIST')}</Button>
        </NavLink>

        {publishButton && (
          <ScButton
            variant="highlighted"
            data-testid="soft-offer-form-publish-btn"
            onClick={publishButton.onPublish}
            disabled={publishButton.disabled}
            loading={publishButton.loading}
          >
            {t('SOFT_OFFER_PUBLISH')}
          </ScButton>
        )}

        {!isSubmitHidden && (
          <Button
            variant="highlighted"
            data-testid="soft-offer-form-create-btn"
            htmlType="submit"
            disabled={isSubmitDisabled}
          >
            {t('SOFT_OFFER_UPDATE')}
          </Button>
        )}
      </NavButtonsContainer>
    </ScFooterContainer>
  );
};

export default Footer;
