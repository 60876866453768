import {
  Model,
  ModelComponentChange,
  ModelComponentChangeField,
  ModelFields,
  ModelMachineConfigurationFields,
  ModelMachineConfiguration_ConfigurationSource_Values,
  ModelRule,
  ModelRuleField,
  ModelRuleValues,
} from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';

const getMachineConfiguration = (model: Model) => {
  return get(model, [ModelFields.machineConfiguration, 0]);
};

export const getIsConfigurationComplete = (model: Model): boolean => {
  const machineConfiguration = getMachineConfiguration(model);

  return get(machineConfiguration, ModelMachineConfigurationFields.complete, false);
};

export const getIsConfigurationCreatedFromStock = (model: Model): boolean => {
  const machineConfiguration = getMachineConfiguration(model);

  const source = get(
    machineConfiguration,
    ModelMachineConfigurationFields.configurationSource,
  );
  const stockNumber = get(
    machineConfiguration,
    ModelMachineConfigurationFields.stockNumber,
  );
  return (
    source === ModelMachineConfiguration_ConfigurationSource_Values.stock &&
    Boolean(stockNumber)
  );
};

export const getIsModelConfigured = (model: Model): boolean => {
  const machineConfiguration = getMachineConfiguration(model);

  return Boolean(machineConfiguration);
};

export const componentChangeToRule = (record: ModelComponentChange): ModelRule => {
  // if was present - will be removed, if was not present - will be added
  const code = record[ModelComponentChangeField.isAdded]
    ? ModelRuleValues.replaceable
    : ModelRuleValues.obligatory;

  return {
    [ModelRuleField.option1]: record[ModelComponentChangeField.parentRecordId],
    [ModelRuleField.option2]: record[ModelComponentChangeField.recordId],
    [ModelRuleField.ruleCode]: code,
    NRbrnd2: 0,
    NRbrnd: 0,
    YNdummyHyper: false,
    INdummyDescr: '',
  };
};
