import * as Sentry from '@sentry/react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

interface File {
  fileName: string;
  fileUrl: string;
}

export class FileZip {
  private _zip = new JSZip();
  zipArchiveName = 'untitled';

  constructor(zipArchiveName: string) {
    this.zipArchiveName = zipArchiveName;
  }

  /**
   * Create new folder in zip archive
   * @param {string} name
   * @return {JSZip}
   */
  public createFolder(name: string): JSZip | null {
    return this._zip.folder(name);
  }

  /**
   * Put files to zip folder
   * @param {File[]} files
   * @param {JSZip} folder
   * @return {Promise<void>}
   */
  async putFilesToFolder(files: File[], folder: JSZip): Promise<void> {
    for (const { fileName, fileUrl } of files) {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const fileExtension = this.getExtensionFromUrl(fileUrl);
        folder?.file(fileName + '.' + fileExtension, blob, { binary: true });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  /**
   * Download zip archive
   * @return {void}
   */
  public download(): void {
    this._zip
      .generateAsync({ type: 'blob' })
      .then(content => saveAs(content, `${this.zipArchiveName}.zip`));
  }

  private getExtensionFromUrl(fileUrl: string): string {
    // assume that URL contains last segment spliited with dot that is extensio of the file
    // then remove all subsequent data (if present) such as query params and hash links
    const match = fileUrl.match(/\.(\w{3,4})(?:$|\?)/);
    if (!match) return '';
    return match[1];
  }
}
