import { ParsedQuery, UrlTransform } from 'utils/urlTransform';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { get, omit } from 'utils';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from './history';
import { URL_QUERY_PARAMS } from '../../common/constants';
import {
  extractModelSelectionsFromQuery,
  getRelationsFromQuery,
  GetRelationsFromQueryResult,
} from './utils';

type Keys = URL_QUERY_PARAMS | string | number;

export type SetQueryParamArg = {
  key: Keys;
  value: string | string[] | number | null | undefined;
};

export type ContextValue = {
  path: string;
  query: ParsedQuery;
  initialQuery: ParsedQuery;
  queryValues: {
    configurationNumber: string | number | null;
    modelPageExtendedView: string | null;
    modelSelections: Record<string, string>;
    standardFeaturesValue: boolean;
    presetDossier: string | number | null;
    presetRelations: GetRelationsFromQueryResult;
    host: string | null;
    hostLock: boolean;
    stream: string | null;
    stockMachine: string | null;
  };
  /** Same as {@link queryValues}, but it is being filled only once and on first render.
   * Handy to check set of parameters with which app was opened with. */
  initialQueryValues: ContextValue['queryValues'];
  setQueryParams(...newParams: SetQueryParamArg[]): void;
  removeQueryParams(...keys: Keys[]): void;
  getQueryParamValue(param: Keys): string;
};

export const QueryContext = createContext<ContextValue | undefined>(undefined);

const UrlQueryProviderWithoutRouter: FC<
  PropsWithChildren<RouteComponentProps<any> & { value?: ContextValue }>
> = ({ location, ...otherProps }) => {
  const parsedQuery = useMemo(
    () => UrlTransform.parseQuery(location.search),
    [location.search],
  );

  const initialParsedQuery = useMemo(
    () => UrlTransform.parseQuery(location.search),
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
    [],
  );

  // we want a function that accepts multiple params in order to change all needed at once
  // and thus avoid unecessary downstream renders
  const setQueryParams = useCallback<ContextValue['setQueryParams']>((...newParams) => {
    // used retrieving 'search' from direct memory cell to prevent recursive race conditions situations
    let newQuery = UrlTransform.parseQuery(history.location.search);
    for (const p of newParams) {
      if (p.value) {
        newQuery[p.key] = p.value as string;
      } else if (p.key) {
        newQuery = omit(newQuery, p.key);
      }
    }

    history.replace({
      search: UrlTransform.stringifyQuery(newQuery),
    });
  }, []);

  const removeQueryParams = useCallback<ContextValue['removeQueryParams']>((...keys) => {
    // used retrieving 'search' from direct memory cell to prevent recursive race conditions situations
    const newQuery = UrlTransform.parseQuery(history.location.search);
    for (const key of keys) {
      delete newQuery[key];
    }

    history.replace({
      search: UrlTransform.stringifyQuery(newQuery),
    });
  }, []);

  const getQueryParamValue = useCallback<ContextValue['getQueryParamValue']>(
    (param): string => {
      const queryParam = get(parsedQuery, param, '');
      return queryParam ? queryParam.toString() : '';
    },
    [parsedQuery],
  );

  const extractQueryValues = useCallback<
    (query: ParsedQuery) => ContextValue['queryValues']
  >(query => {
    const getValue = (key: URL_QUERY_PARAMS): string => {
      return get(query, key, null) as string;
    };

    return {
      configurationNumber: getValue(URL_QUERY_PARAMS.configurationNumber),
      modelPageExtendedView: getValue(URL_QUERY_PARAMS.modelPageExtendedView),
      modelSelections: extractModelSelectionsFromQuery(query),
      standardFeaturesValue: getValue(URL_QUERY_PARAMS.standardFeaturesValue) === 'true',
      presetDossier: getValue(URL_QUERY_PARAMS.presetDossier),
      presetRelations: getRelationsFromQuery(query),
      host: getValue(URL_QUERY_PARAMS.host),
      hostLock: getValue(URL_QUERY_PARAMS.hostLock) === 'true',
      stream: getValue(URL_QUERY_PARAMS.stream),
      stockMachine: getValue(URL_QUERY_PARAMS.stockMachine),
    };
  }, []);

  const queryValues = useMemo<ContextValue['queryValues']>(() => {
    return extractQueryValues(parsedQuery);
  }, [parsedQuery, extractQueryValues]);

  const initialQueryValues = useMemo<ContextValue['initialQueryValues']>(() => {
    return extractQueryValues(parsedQuery);
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
  }, [extractQueryValues]);

  const value = useMemo(
    () => ({
      path: location.pathname,
      initialQuery: initialParsedQuery,
      query: parsedQuery,
      queryValues,
      initialQueryValues,
      setQueryParams,
      removeQueryParams,
      getQueryParamValue,
    }),
    [
      setQueryParams,
      initialParsedQuery,
      parsedQuery,
      queryValues,
      initialQueryValues,
      removeQueryParams,
      getQueryParamValue,
      location.pathname,
    ],
  );
  return <QueryContext.Provider value={value} {...otherProps} />;
};

export const UrlQueryProvider = withRouter(UrlQueryProviderWithoutRouter);

export const useQuery = (): ContextValue => {
  const value = useContext(QueryContext);
  if (value === undefined) {
    throw new Error('useQuery must be used inside a QueryProvider');
  }
  return value;
};
