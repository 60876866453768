import React, { FC, HTMLProps } from 'react';
import { ScPaper, ScPaperBody, ScPaperFooter, ScPaperHeader } from './Paper.styles';
import cn from 'classnames';

interface Props extends HTMLProps<HTMLDivElement> {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  ref?: never;
  as?: never;
}

const Paper: FC<Props> = ({ footer, header, children, className, ...props }) => {
  return (
    <ScPaper {...props} className={cn(className)}>
      {header && <ScPaperHeader>{header}</ScPaperHeader>}
      <ScPaperBody>{children}</ScPaperBody>
      {footer && <ScPaperFooter>{footer}</ScPaperFooter>}
    </ScPaper>
  );
};

export default Paper;
