import React, { ComponentProps, FC } from 'react';
import { ScButton } from './scrollableMenuItem.styles';
import classNames from 'classnames';

type Props = ComponentProps<typeof ScButton> & {
  onClick(): void;
  active: boolean;
  disabled: boolean;
  testIdSuffix?: string;
};

const ScrollableMenuItem: FC<Props> = ({
  children,
  active,
  disabled,
  testIdSuffix,
  onClick,
}) => {
  return (
    <ScButton
      disabled={disabled}
      data-testid={`category-item__${testIdSuffix ?? ''}`}
      className={classNames({ active: active })}
      onClick={onClick}
    >
      {children}
    </ScButton>
  );
};

export default ScrollableMenuItem;
