import Icon from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from './search.svg';

export const SearchOutlinedIcon = props => <Icon component={SearchIcon} {...props} />;

export const ScNoItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  > span {
    max-width: 30em;
    text-align: center;
  }

  &.uppercase {
    > span {
      max-width: 35em;
    }
  }
`;

export const ScNoItemsIcon = styled(SearchOutlinedIcon)`
  font-size: 1.5em;
`;

export const SearchOutlinedContainer = styled.div`
  width: 5.5em;
  height: 5.5em;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 2em;
  align-items: center;

  justify-content: center;
  display: flex;
`;
