import React, { useCallback, useState, PropsWithChildren } from 'react';
import {
  ScImageSlotWrapper,
  ScImageSlotErrorWrapper,
  ScNoDataWrapper,
  ScImage,
  ScImageWrapper,
  ScImageCardWrapper,
  ScContent,
  NoDataBackground,
  NoDataWrapper,
  NoDataContent,
} from './ImageCard.styles';
import { RotatingLoader } from 'components/RotatingLoader';

interface Props {
  className?: string;
  errorIcon: React.ReactNode;
  name: string;
  url: string;
  description: string;
  onClick?(): void;
}

const ImageSlot: React.FC<PropsWithChildren<Props>> = ({
  className,
  errorIcon,
  url,
  description,
  children,
  onClick,
}) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setHasLoaded(true);
  }, []);

  const handleImageError = useCallback(() => {
    setHasLoaded(true);
    setIsError(true);
  }, []);

  return (
    <ScImageCardWrapper onClick={onClick} className={className}>
      <ScImageSlotWrapper>
        {!hasLoaded && (
          <NoDataWrapper>
            <NoDataBackground />
            <NoDataContent>
              <RotatingLoader />
            </NoDataContent>
          </NoDataWrapper>
        )}
        {isError ? (
          <ScNoDataWrapper>
            <NoDataWrapper>
              <NoDataBackground />
              <NoDataContent>
                <ScImageSlotErrorWrapper>{errorIcon}</ScImageSlotErrorWrapper>
              </NoDataContent>
            </NoDataWrapper>
          </ScNoDataWrapper>
        ) : (
          <ScImageWrapper>
            <ScImage
              $height="100%"
              src={url}
              alt={description}
              title={description}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </ScImageWrapper>
        )}
      </ScImageSlotWrapper>
      {children && <ScContent>{children}</ScContent>}
    </ScImageCardWrapper>
  );
};

export default ImageSlot;
