import { Button } from 'components/button';
import styled from 'styled-components';

export const ScHeaderSearchContainer = styled.div<{ $width: string | undefined }>`
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  padding: 0.625em 0.625em 1em 0.625em;
  width: ${({ $width }) => $width ?? '16.875em'};
`;

export const ScHeaderSearchContainerButton = styled(Button)`
  border: none;
  height: auto;
  line-height: initial;
  padding: 0;
`;
