import React, { FC } from 'react';
import { Button } from './Button';
import Icon from '@ant-design/icons';
import { ReactComponent as Arrow } from '../../assets/icons/collapseArrow.svg';
import { ScCollapseButton } from './ButtonCollapse.styles';

const CollapseIcon = props => <Icon component={Arrow} {...props} />;

interface Props extends React.ComponentProps<typeof Button> {
  isOpen: boolean;
}

export const ButtonCollapse: FC<Props> = ({ isOpen, children, ...props }) => {
  return (
    <ScCollapseButton {...props}>
      <CollapseIcon data-testid="collapse-icon-1" rotate={isOpen ? 0 : 180} />
      <CollapseIcon data-testid="collapse-icon-2" rotate={isOpen ? 180 : 0} />
      <span data-testid="collapse-text">{children}</span>
    </ScCollapseButton>
  );
};
