import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sharedActions, hostSettingsSelectors, authSelectors } from 'store';

/**
 * Control over {@link isWholePageLoading} from all possible contexts which can load data
 */
export const useUnauthenticatedLoadingStatusesUpdatEffect = () => {
  const dispatch = useDispatch();

  const hostSettingsStatus = useSelector(hostSettingsSelectors.getStatus);
  const baseUrlStatus = useSelector(authSelectors.getBaseUrlStatus);

  useEffect(() => {
    const statusesList = [hostSettingsStatus, baseUrlStatus];
    dispatch(sharedActions.setAuthenticatedStatusesList(statusesList));
  }, [dispatch, hostSettingsStatus, baseUrlStatus]);
};
