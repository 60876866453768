import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import XDMSClientFactory from '@hypercharge/xdms-client';
import { STORAGE_KEYS, XDMS_CLIENT_ENVIRONMENT_CONSTANTS } from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { featuresFlagsSelectors, authSelectors, sharedActions } from 'store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import * as storage from 'utils/storage';

export type XdmsClient = ReturnType<typeof XDMSClientFactory>;
type ContextValue = {
  xdmsClient: any;
  xdmsClientTyped: XdmsClient;
};

const XdmsClientContext = React.createContext<ContextValue | undefined>(undefined);

const XdmsClientProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const [xdmsClient, setXdmsClient] = useState<ContextValue['xdmsClient']>(null);

  const dispatch = useDispatch();

  const authToken = useSelector(authSelectors.getAuthToken);
  const baseUrl = useSelector(authSelectors.getBaseUrl);
  const username = useSelector(authSelectors.getUsername);

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const isGlobalFeaturesLoaded = useMemo(() => {
    return Boolean(globalFeatures);
  }, [globalFeatures]);

  const inst = useMemo(() => {
    return globalFeatures?.[GlobalFeaturesFlagsFields.XDMSAppInst];
  }, [globalFeatures]);

  useEffect(() => {
    if (!authToken || !baseUrl || !username) return;

    const client = XDMSClientFactory({
      tenantBaseUrl: baseUrl,
      username: username,
      authData: {
        token: authToken,
      },
      constants: {
        clientInstanceName: inst,
        ...XDMS_CLIENT_ENVIRONMENT_CONSTANTS,
      },
      httpHooks: {
        sessionExpired: () => {
          storage.saveInLocalStorage(STORAGE_KEYS.authToken, undefined);
          storage.saveInLocalStorage(STORAGE_KEYS.connectionId, undefined);
          window.location.reload();
        },
      },
    });

    dispatch(sharedActions.setIsXdmsClientSettled(isGlobalFeaturesLoaded));
    setXdmsClient(client);
  }, [authToken, baseUrl, username, dispatch, isGlobalFeaturesLoaded, inst]);

  const value = useMemo(
    () => ({
      xdmsClient,
      xdmsClientTyped: xdmsClient,
      setXdmsClient,
    }),
    [xdmsClient, setXdmsClient],
  );

  return <XdmsClientContext.Provider value={value} {...props} />;
};

const useXdmsClient = (): ContextValue => {
  const context = useContext(XdmsClientContext);

  if (context === undefined) {
    throw new Error(
      `${useXdmsClient.name} must be used within an ${XdmsClientProvider.name}`,
    );
  }

  return context;
};

export { XdmsClientProvider, useXdmsClient };
