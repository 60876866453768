import styled from 'styled-components';
import { Button } from './Button';

export const ScCollapseButton = styled(Button)`
  height: 100%;
  max-height: 11.25em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0 1em;
  font-size: 0.875em;
  line-height: 2.5em;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

  & span {
    margin: 0;
    text-transform: capitalize;

    svg {
      font-size: 1.938em;
      padding: 0;
      margin: 0;
      transition: transform 0.3s ease-out;
    }
  }

  & span:first-child {
    margin-bottom: -0.625em;
  }
`;
