import currency from 'currency.js';

export class Money {
  private _value = 0;

  constructor(value: number) {
    this._value = value;
  }

  add(value: number): Money {
    this._value = currency(this._value).add(value).value;
    return this;
  }

  subtract(value: number): Money {
    this._value = currency(this._value).subtract(value).value;
    return this;
  }

  divide(value: number): Money {
    this._value = currency(this._value).divide(value).value;
    return this;
  }

  multiply(value: number): Money {
    this._value = currency(this._value).multiply(value).value;
    return this;
  }

  applyPercent(percent: number): Money {
    this._value = currency(this._value).multiply(percent).divide(100).value;
    return this;
  }

  getPercent(value: number): Money {
    this._value = currency(this._value).divide(value).multiply(100).value;
    return this;
  }

  get value(): number {
    return this._value;
  }
}
