import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader, ModalBody } from 'components/modal/Modal.styles';
import { Modal } from 'components/modal';
import {
  DocumentItem,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
} from 'context/document/types';
import { DocumentsList } from 'components/document/List/DocumentsList';
import { useDocumentsApi } from 'context/document/useDocumentsApi';

export type DocumentModalProps = {
  onClose(): void;
  relatedEntity: DocumentRelatedEntity;
  relationEntityCode: DocumentRelatedEntityCode;
  isAddFileVisible?: boolean;
  isAddLinkVisible?: boolean;
};

export const DocumentModal: FC<DocumentModalProps> = ({
  onClose,
  relatedEntity,
  relationEntityCode,
  isAddFileVisible,
  isAddLinkVisible,
}) => {
  const { t } = useTranslation();

  const { getDocumentsList } = useDocumentsApi();

  const [documents, setDocuments] = useState<DocumentItem[]>([]);

  const loadDocuments = useCallback(() => {
    getDocumentsList(relationEntityCode, relatedEntity).then(({ response }) => {
      setDocuments(response ?? []);
    });
  }, [getDocumentsList, relationEntityCode, relatedEntity]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  return (
    <Modal variant="sm" visible onCancel={onClose}>
      <ModalHeader>{t('DOCUMENTS')}</ModalHeader>
      <ModalBody>
        <DocumentsList
          isCaretVisible
          relatedEntityCode={relationEntityCode}
          relatedEntity={relatedEntity}
          documents={documents}
          isAddFileVisible={isAddFileVisible}
          isAddLinkVisible={isAddLinkVisible}
          reloadDocuments={loadDocuments}
        />
      </ModalBody>
    </Modal>
  );
};
