import React, { FC, useMemo } from 'react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { Col, Row } from 'antd';
import FormField from 'components/form/formik/FormField';
import { Input } from '../../components/form/Input';
import { Button } from '../../components/button';
import { useTranslation } from 'react-i18next';
import {
  getHostSettingsFormSchema,
  HostSettingsFormValues,
} from './HostSettingsFormMeta';

interface Props {
  initialValues: HostSettingsFormValues;
  onSubmit(
    values: HostSettingsFormValues,
    actions: FormikHelpers<HostSettingsFormValues>,
  ): void;
}

const HostSettingsForm: FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation();

  const formSchema = useMemo(() => getHostSettingsFormSchema(t), [t]);

  return (
    <Formik<HostSettingsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={formSchema}
      data-testid="xdms-setting-page-form-wrapper"
    >
      {(formProps: FormikProps<HostSettingsFormValues>) => {
        return (
          <form
            noValidate
            onSubmit={formProps.handleSubmit}
            data-testid="xdms-setting-page-form"
          >
            <Row>
              <Col xs={24}>
                <FormField
                  /* eslint-disable-next-line jsx-a11y/no-autofocus */
                  autoFocus
                  component={Input}
                  disabled={formProps.isSubmitting}
                  name="host"
                  placeholder={t('AUTH__XDMS_URL')}
                  type="text"
                  value={formProps.values.host}
                  data-testid="xdms-setting-page-form-url-field"
                  autoComplete="on"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Button
                  loading={formProps.isSubmitting}
                  variant="primary"
                  fullwidth
                  noBorder
                  htmlType="submit"
                  data-testid="xdms-setting-page-form-submit-button"
                >
                  {t('AUTH__SAVE')}
                </Button>
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default HostSettingsForm;
