import React, { FC, useMemo } from 'react';
import {
  ScStreamingTerminalTitle,
  ScStreamingTerminalTitleStatus,
  ScStreamingTerminalTitleWrapper,
} from './StreamingTerminalTitle.styles';

type Props = {
  className?: string;
  terminalCustomerId: string;
  terminalConfiguratorId: number | null;
  configuratorId: number;
  status: 'offline' | 'vacant' | 'busy';
};

const statusToColor: Record<Props['status'], string> = {
  offline: '#989898',
  vacant: 'green',
  busy: '#4EAD04',
};

export const StreamingTerminalTitle: FC<Props> = ({
  terminalCustomerId,
  className,
  status,
  terminalConfiguratorId,
  configuratorId,
}) => {
  const color = useMemo<string>(() => {
    if (status === 'busy' && terminalConfiguratorId !== configuratorId) return 'red';

    return statusToColor[status];
  }, [status, terminalConfiguratorId, configuratorId]);

  return (
    <ScStreamingTerminalTitleWrapper>
      <ScStreamingTerminalTitleStatus color={color} title={status} />
      <ScStreamingTerminalTitle className={className}>
        ID {terminalCustomerId}
      </ScStreamingTerminalTitle>
    </ScStreamingTerminalTitleWrapper>
  );
};
