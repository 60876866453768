import React, { FC } from 'react';
import { TotalTableRow } from '../components/TotalTableRow';
import { TFunction } from 'i18next';
import { TotalsItem } from '@hypercharge/xdms-client/lib/types';
import { TotalPageAdaptedFields } from '../utils';
import { get } from 'utils';
import { Price } from 'components/Price';
import { TotalTextEllipsis } from '../components/TotalTextEllipsis';

interface Props {
  t: TFunction;
  totalData: TotalsItem | null;
  FIELDS: TotalPageAdaptedFields;
}
const SalesInfo: FC<Props> = ({ t, totalData, FIELDS }) => {
  return (
    <>
      <TotalTableRow
        borderBottom="thin"
        fields={[
          t('TOTAL_HEADER_SALES_INFO'),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ]}
      />
      <TotalTableRow
        data-testid="total-page-current-tier"
        borderBottom="thin"
        shiftFirstColumn
        fields={[
          t('TOTAL_CURRENT_TIER'),
          <TotalTextEllipsis>{get(totalData, FIELDS.currentTier, '')}</TotalTextEllipsis>,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ]}
      />
      <TotalTableRow
        data-testid="total-page-price-to-next-tier"
        borderBottom="thin"
        shiftFirstColumn
        fields={[
          t('TOTAL_PRICE_TO_NEXT_TIER'),
          <Price value={get(totalData, FIELDS.priceToNextTier, 0)} />,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ]}
      />
    </>
  );
};

export default SalesInfo;
