import * as Yup from 'yup';
import { TFunction } from 'i18next';

export interface HostSettingsFormValues {
  host: string;
}

export function getHostSettingsFormSchema(
  t: TFunction,
): Yup.Schema<HostSettingsFormValues> {
  return Yup.object().shape({
    host: Yup.string().required(t('FORM__REQUIRED')),
  });
}
