import { SoftOfferCheckout, SoftOfferCheckoutFields } from 'types/vendor';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { ScCheck, ScTable } from './styles';
import { Price } from 'components/Price';

const tPrefix = 'SOFT_OFFER_CHECKOUT_';

interface Props {
  isDisabled: boolean;
  isPublishDisabled: boolean;
  isDocumentsVisible: boolean;
  tableData?: SoftOfferCheckout[];
  documentsData: { url: string; name: string }[];
  onCalculate(): void;
  onPublish(): void;
}

const CheckoutForm: FC<Props> = ({
  isDisabled,
  onCalculate,
  onPublish,
  tableData,
  documentsData,
  isPublishDisabled,
  isDocumentsVisible,
}) => {
  const { t } = useTranslation();

  const {
    softOfferDetailsStatus,
    softOfferTarificationUpdateStatus,
    softOfferTarificationPublishStatus,
    softOfferTarificationCalculateStatus,
  } = useSelector(softOffersSelectors.getAll);

  /** Its ugly, but {@link onCalculate} has circular dep */
  const firstRunRef = useRef<boolean>(false);
  useEffect(() => {
    if (firstRunRef.current || isDisabled) return;
    firstRunRef.current = true;
    onCalculate();
  }, [isDisabled, onCalculate]);

  const isLoading = useMemo<boolean>(
    () =>
      [
        softOfferDetailsStatus,
        softOfferTarificationUpdateStatus,
        softOfferTarificationPublishStatus,
        softOfferTarificationCalculateStatus,
      ].includes(Status.Loading),
    [
      softOfferDetailsStatus,
      softOfferTarificationUpdateStatus,
      softOfferTarificationPublishStatus,
      softOfferTarificationCalculateStatus,
    ],
  );

  return (
    <>
      <ScTable className={'styledTable'}>
        <colgroup>
          <col width={'70%'} />
          <col width={'15%'} />
          <col width={'15%'} />
        </colgroup>
        <thead>
          <tr>
            <th>{t(tPrefix + 'TABLE_HEADER_NAME')}</th>
            <th>{t(tPrefix + 'TABLE_HEADER_IS_SELECTED')}</th>
            <th>{t(tPrefix + 'TABLE_HEADER_IS_PRICE')}</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map(item => (
            <tr key={item[SoftOfferCheckoutFields.warrantyName]}>
              <td>{item[SoftOfferCheckoutFields.warrantyName]}</td>
              <td>{item[SoftOfferCheckoutFields.isSelected] && <ScCheck />}</td>
              <td>
                <Price value={item[SoftOfferCheckoutFields.priceAmount]} />
              </td>
            </tr>
          ))}
        </tbody>
      </ScTable>

      <Footer
        isSubmitHidden
        publishButton={{
          onPublish: onPublish,
          loading: isLoading,
          disabled: isPublishDisabled,
        }}
        documentsData={documentsData}
        isDocumentsVisible={isDocumentsVisible}
      />
    </>
  );
};

export default CheckoutForm;
