import { DataIndex } from 'components/table/types';
import React, { FC } from 'react';
import { ScFilterListItem, ScCloseOutlined } from './FiltersListItem.styles';

interface Props {
  title: string;
  value: string | number;
  onClear(dataIndex: DataIndex): void;
  dataIndex: DataIndex;
}

export const FiltersListItem: FC<Props> = ({ title, value, onClear, dataIndex }) => {
  return (
    <ScFilterListItem
      onClick={() => onClear(dataIndex)}
      data-testid={`clear-filters-btn__${value}`}
    >
      {title} = {value}
      <ScCloseOutlined />
    </ScFilterListItem>
  );
};
