import { useCallback, useEffect, useMemo } from 'react';
import {
  ConfDetailsFields,
  DocumentTemplate,
  Model_CommonCategoryFields,
  ModelFields,
  ModelMachineConfigurationFields,
  ModelMarginItemFields,
  ModelUpdateAttributesObjectFields,
} from 'types/vendor';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useSelector } from 'react-redux';
import {
  configurationSelectors,
  documentTemplatesSelectors,
  modelSelectors,
} from 'store';
import { useConfiguration } from './ConfigurationProvider';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { get } from 'utils';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { TOTAL_PUBLISH_DOCUMENT_TYPE } from 'utils/constants';
import { DocumentRelatedEntityCode } from 'context/document/types';
import { StreamingEventType } from 'context/streaming/types';
import useCarInfo from 'hooks/useCarInfo';
import { useTranslation } from 'react-i18next';
import { useDocumentTypesApi } from 'context/document/useDocumentTypesApi';
import useInconfiguredCategory from 'hooks/useInconfiguredCategory';
import { useDocumentTemplateApi } from 'context/documentTemplate/useDocumentTemplateApi';
import { DocumentTemplate_GetPDFTemplatesList_Args_Entities } from '@hypercharge/xdms-client/lib/types';

interface ConfigurationDetailsImage {
  source: string;
  alt: string;
  title: string;
  key: string | number;
}

const ORDERED_CONFIGURATION_DETAILS_SIDES = [
  'threeQuartersFrontLeft',
  'threeQuartersRearLeft',
  'wheel',
  'driversView',
  'rear',
];

interface SharedOptionsApi {
  publishConfiguration(template?: DocumentTemplate): Promise<void>;
  requiredItems: [string, boolean][];
  pdfTemplates: DocumentTemplate[];
  modelPublishAllowed: boolean;
}

export const useConfigurationPublish = (): SharedOptionsApi => {
  const { t } = useTranslation();

  const { getConfigurationDetailsById } = useConfiguration();
  const { getDocumentsList, uploadDocumentLink } = useDocumentsApi();
  const { isFeatureEnabled } = useFeature();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();
  const { data: carInfoData } = useCarInfo();
  const { getDocumentTypes } = useDocumentTypesApi();
  const { inconfiguredCategories, requiredCategories } = useInconfiguredCategory();
  const { getPDFTemplates } = useDocumentTemplateApi();

  const { configurationNumber, configurationModelName } = useSelector(
    configurationSelectors.getAll,
  );
  const { model } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);
  const { configurationDetails } = useSelector(configurationSelectors.getAll);
  const pdfTemplates = useSelector(documentTemplatesSelectors.getTemplatesList);

  const isGeneratePublishImageFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalGeneratePublishImage,
  });
  const isRequiredCategoriesFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RequiredCategories,
  });
  const isAllowInfoPageSalesCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSalesCode,
  });
  const isAllowInfoPageSellCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSellCode,
  });

  useEffect(() => {
    getPDFTemplates({
      entity: DocumentTemplate_GetPDFTemplatesList_Args_Entities.configuration,
    });
  }, [getPDFTemplates]);

  const modelPublishAllowed = useMemo<boolean>(() => {
    return get(
      model,
      [ModelFields.modelMargin, 0, ModelMarginItemFields.publishAllowed],
      false,
    );
  }, [model]);

  const getSummaryTransparentImage = useCallback(() => {
    const [visualization] = carInfoData?.visualizations ?? [];
    if (!visualization) return '';

    const imageView = visualization.views.exterior.studio?.threeQuartersFrontLeft;
    return imageView?.sizes?.large?.transparentUrl ?? '';
  }, [carInfoData]);

  const configurationDetailsImages = useMemo<ConfigurationDetailsImage[]>(() => {
    const [visualization] = carInfoData?.visualizations ?? [];
    if (!visualization) return [];

    const { interior, exterior } = visualization.views;
    const views: Record<string, string | null | undefined> = {
      threeQuartersFrontLeft: exterior.studio?.threeQuartersFrontLeft?.sizes?.large?.url,
      threeQuartersRearLeft: exterior.studio?.threeQuartersRearLeft?.sizes?.large?.url,
      wheel: exterior.details.wheel?.sizes?.large?.url,
      driversView: interior.studio?.driversView?.sizes?.large?.url,
      rear: interior.studio?.rear?.sizes?.large?.url,
    };

    if (!Object.values(views).length) return [];

    return ORDERED_CONFIGURATION_DETAILS_SIDES.filter(side => views[side]).map(side => ({
      source: String(views[side]),
      alt: side,
      title: side,
      key: side,
    }));
  }, [carInfoData]);

  const getPublishedDocumentUrl = useCallback(documents => {
    if (Array.isArray(documents)) {
      const file = documents.find(document => document.computed.isFile);
      if (file) return file.fileUrl;
    }
    return '';
  }, []);

  const publishConfiguration = useCallback(
    async (template?: DocumentTemplate) => {
      if (!model) return;
      const updatedModel = template
        ? {
            ...model,
            [ModelFields.params]: [
              ...get(model, ModelFields.params, []),
              {
                NMparam: 'INlayout',
                VAparam: JSON.stringify(template),
              },
            ],
          }
        : model;
      {
        // @todo: remove any
        const machineConfiguration: any = get(
          model,
          `${ModelFields.machineConfiguration}.0`,
          {},
        );
        const { messageHandled, status } = await updateModel(updatedModel, {
          [ModelUpdateAttributesObjectFields.machineConfiguration]: {
            ...machineConfiguration,
            [ModelMachineConfigurationFields.complete]: true,
          },
        });

        if (modelToken) {
          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'selection',
              data: {
                token: modelToken,
              },
            },
          });
        }

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('TOTAL_SUCCESS_PUBLISH_MESSAGE'),
            [Status.Error]: t('GLOBAL_ERROR_TEXT'),
          });
        }
      }

      if (isGeneratePublishImageFeatureEnabled) {
        const { response: documentTypes } = await getDocumentTypes();
        const documentType = documentTypes?.find(
          ({ id }) => id === TOTAL_PUBLISH_DOCUMENT_TYPE,
        );
        if (!documentType) {
          notification.openByStatus(Status.Error, {
            [Status.Error]: t('TOTAL_PUBLISH_NOT_FOUND_DOCUMENT_TYPE'),
          });
          return;
        }
        const [documentLink] = configurationDetailsImages;
        if (!documentLink?.source) return;
        await uploadDocumentLink(
          {
            name: `${configurationNumber}-email-image-${configurationModelName}`,
            typeCategory: TOTAL_PUBLISH_DOCUMENT_TYPE,
            type: TOTAL_PUBLISH_DOCUMENT_TYPE,
            url: documentLink.source,
          },
          DocumentRelatedEntityCode.configuration,
          undefined,
        );
      }

      const { response } = await getDocumentsList(
        DocumentRelatedEntityCode.configuration,
        undefined,
      );
      const documentSrc = getPublishedDocumentUrl(response ?? []);
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'summary',
          data: {
            documentSrc,
            imageSrc: getSummaryTransparentImage(),
          },
        },
      });
      if (configurationNumber) {
        await getConfigurationDetailsById({ configurationNumber: configurationNumber });
      }
    },
    [
      model,
      isGeneratePublishImageFeatureEnabled,
      getDocumentsList,
      getPublishedDocumentUrl,
      sendMessage,
      getSummaryTransparentImage,
      configurationNumber,
      updateModel,
      modelToken,
      t,
      getDocumentTypes,
      configurationDetailsImages,
      uploadDocumentLink,
      configurationModelName,
      getConfigurationDetailsById,
    ],
  );

  const salesCode = useMemo(
    () => configurationDetails?.configuration[ConfDetailsFields.salesCode],
    [configurationDetails],
  );

  const sellCode = useMemo(
    () => configurationDetails?.configuration[ConfDetailsFields.sellCode],
    [configurationDetails],
  );

  const requiredItems = useMemo<[string, boolean][]>(() => {
    const items: [string, boolean][] = [
      [t('TOTAL_CONFIGURATION_PUBLISHABLE_MESSAGE'), modelPublishAllowed],
    ];

    if (isRequiredCategoriesFeatureEnabled) {
      const inconfiguredCategoriesHash = Object.values(inconfiguredCategories)
        .flat()
        .reduce<Record<string, string>>((hash, category) => {
          const categoryName = category?.[Model_CommonCategoryFields.name];
          if (categoryName) hash[categoryName] = categoryName;
          return hash;
        }, {});

      Object.values(requiredCategories)
        .flat()
        .forEach(category => {
          const categoryName = category?.[Model_CommonCategoryFields.name];
          if (!categoryName) return;
          const isConfigured = !inconfiguredCategoriesHash[categoryName];
          items.push([
            t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: categoryName }),
            isConfigured,
          ]);
        });
    }

    if (isAllowInfoPageSalesCode) {
      items.push([
        t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: t('INFO_SALES_CODE') }),
        Boolean(salesCode),
      ]);
    }

    if (isAllowInfoPageSellCode) {
      items.push([
        t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: t('INFO_SELL_CODE') }),
        Boolean(sellCode),
      ]);
    }

    return items;
  }, [
    t,
    modelPublishAllowed,
    isRequiredCategoriesFeatureEnabled,
    isAllowInfoPageSalesCode,
    isAllowInfoPageSellCode,
    inconfiguredCategories,
    requiredCategories,
    salesCode,
    sellCode,
  ]);

  return {
    pdfTemplates: pdfTemplates,
    requiredItems: requiredItems,
    modelPublishAllowed: modelPublishAllowed,
    publishConfiguration: publishConfiguration,
  };
};
