import styled from 'styled-components';
import { mediaMax } from 'common/theme';

export const ScOrder = styled.div`
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.vampire};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  min-width: 6.75em;

  ${mediaMax.md} {
    font-size: 0.75em;
  }

  .card-total {
    text-transform: capitalize;
    line-height: 1.125em;
    line-height: 1.45em;
  }
`;

export const ScOrderText = styled.div`
  white-space: nowrap;
`;
