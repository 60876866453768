import { InputProps } from 'antd/lib/input';
import React, { CSSProperties, RefObject } from 'react';
import { ScInput, ScInputContainer, ScLabel } from './Input.styles';
import { getTitle } from 'utils/get-title';

interface Props extends Omit<InputProps, 'style'> {
  'data-testid'?: string;
  label?: string;
  className?: string;
  ref?: RefObject<any>;
  style?: Partial<{
    label: CSSProperties;
    input: CSSProperties;
  }>;
}

export const Input = React.forwardRef((props: Props, ref: any) => {
  return (
    <ScInputContainer className={props.className} data-testid={'input-field-container'}>
      {props.label && (
        <ScLabel
          className={props.disabled ? 'form-input-disabled' : ''}
          style={props.style && props.style.label}
          data-testid="input-field-label"
        >
          {getTitle(props.label, { required: props.required })}
        </ScLabel>
      )}
      <ScInput
        autoComplete="off"
        {...props}
        style={props.style && props.style.input}
        ref={ref}
      />
    </ScInputContainer>
  );
});
