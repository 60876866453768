import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { FC, PropsWithChildren, ComponentProps } from 'react';

export const ScCloseOutlined = styled(CloseOutlined)`
  width: 1.375em;
  height: 1.375em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// @todo: antd@5
export const ScCollapse: FC<PropsWithChildren<ComponentProps<typeof Collapse>>> = styled(
  Collapse,
)`
  &.ant-collapse {
    .ant-collapse-item {
      flex-direction: column;
      margin-bottom: 1.25em;
      border-left: 0;
      border-right: 0;

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }

      .ant-collapse-header {
        border: 1px solid ${({ theme }) => theme.colors.softPeach};
        box-shadow: ${({ theme }) => theme.shadows.formHeader};
        padding-left: 3.75em;
        font-size: 0.875em;

        .ant-collapse-extra {
          position: absolute;
          left: 1.5em;
          top: 0.75em;
          cursor: pointer;
          z-index: 10;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-bottom: 0;
        }
      }
    }
  }
`;

export const ScCollapsePanel: FC<
  PropsWithChildren<ComponentProps<typeof Collapse.Panel>>
> = styled(Collapse.Panel)``;
