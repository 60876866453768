import styled from 'styled-components';
import Select from 'components/form/Selector';
import { InputNumber } from 'components/form/InputNumber';

export const ScModalContent = styled.div`
  padding: 1em;
`;

export const ScSelect = styled(Select)`
  margin-bottom: 1.25em;
`;

export const ScInputNumber = styled(InputNumber)``;
