import React, { FC, useMemo } from 'react';
import { FormikProvider, Form } from 'formik';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { ComponentsRendererSchema } from 'hooks/useComponentsRenderer/types';
import { useComponentsRender } from 'hooks/useComponentsRenderer';
import InfoFormComponentsCollection from './InfoFormComponentsCollection';
import { useInfoForm } from './InfoFormProvider';
import { useStep } from 'context/step/StepProvider';
import InfoFormButtons from './InfoFormButtons';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

const InfoForm: FC = () => {
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const { handlePrevStep } = useStep();
  const { formProps } = useInfoForm();

  const renderSchema = useMemo<ComponentsRendererSchema>(() => {
    return globalFeatures?.[GlobalFeaturesFlagsFields.InfoFormSchema] ?? [];
  }, [globalFeatures]);

  const renderResult = useComponentsRender(InfoFormComponentsCollection, renderSchema);

  return (
    <FormikProvider value={formProps}>
      <Form>
        {renderResult}
        <InfoFormButtons onPrev={handlePrevStep} />
      </Form>
    </FormikProvider>
  );
};

export default InfoForm;
