import { URL_QUERY_PARAMS } from 'common/constants';
import { useQuery } from 'context/router/UrlQueryProvider';
import useBuildStockMachine from 'hooks/useBuildStockMachine';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sharedSelectors } from 'store';

export default function useBuildStockFromUrl(): void {
  const [stockMachine, setStockMachine] = useState<string | null>(null);

  const { handleStockBuild } = useBuildStockMachine();
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);
  const { queryValues, removeQueryParams } = useQuery();

  useEffect(() => {
    if (isWholePageLoading) return;

    if (queryValues.stockMachine) {
      setStockMachine(queryValues.stockMachine);
      removeQueryParams(URL_QUERY_PARAMS.stockMachine);
    }
  }, [isWholePageLoading, queryValues.stockMachine, removeQueryParams]);

  useEffect(() => {
    if (!stockMachine) return;
    setStockMachine(null);
    handleStockBuild(stockMachine);
  }, [stockMachine, handleStockBuild]);
}
