import { DropDownProps as AntdDropdownProps } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Dropdown } from 'components/dropdown';
import { Stub } from 'components/stub';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { DocumentTemplate } from 'types/vendor';
import {
  ScDocumentTemplateMenu,
  ScDocumentTemplateMenuItem,
  ScDocumentTemplateMenuItemButton,
} from './DocumentTemplateDropdown.styles';
import PopConfirm from 'components/PopConfirm';
import {
  LanguageFields,
  PrintTemplateListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'context/language/LanguageProvider';
import { LANGUAGE_CODE_mapping_PRINT_TEMPLATE_DESCRIPTION_FIELD } from 'common/constants';

interface DocumentTemplateDropdownProps
  extends Omit<AntdDropdownProps, 'overlay' | 'visible'> {
  templates: DocumentTemplate[];
  stubTitle: string;
  onAction(template: DocumentTemplate): Promise<void> | void;
  withConfirm?: boolean;
  confirmPlacement?: React.ComponentProps<typeof PopConfirm>['placement'];
  confirmOffsetX?: number;
  width?: string;
  visible?: boolean | undefined;
}

export const DocumentTemplateDropdown = (
  props: PropsWithChildren<DocumentTemplateDropdownProps>,
): React.ReactElement => {
  const {
    templates,
    stubTitle,
    onAction,
    withConfirm = false,
    confirmPlacement,
    confirmOffsetX,
    width,
    visible,
    ...rest
  } = props;

  const { i18n } = useTranslation();
  const { languages } = useLanguage();

  const templateDescriptionField = useMemo<string>(() => {
    const currentLanguage = languages.find(record => {
      const recCode = record[LanguageFields.shortLanguageCode].toLowerCase();
      const currCode = i18n.language.toLowerCase();
      return recCode === currCode;
    });

    if (!currentLanguage) return PrintTemplateListItemFields.description;

    const descriptionField =
      LANGUAGE_CODE_mapping_PRINT_TEMPLATE_DESCRIPTION_FIELD[
        currentLanguage[LanguageFields.languageCode]
      ];

    return descriptionField ?? PrintTemplateListItemFields.description;
  }, [i18n.language, languages]);

  const getDocumentTemplateMenuItemButton = useCallback(
    template => {
      let title = get(template, templateDescriptionField);
      if (!title) title = get(template, PrintTemplateListItemFields.description);

      return (
        <ScDocumentTemplateMenuItemButton
          data-testid="document-template-menu-item-button"
          onClick={e => {
            // avoid closing menu when confirm is present
            if (withConfirm) {
              e.stopPropagation();
            } else {
              // due to the fact two cases are possible:
              // with popup and without it -
              // we need to make sure to call action when clicking
              // an item only if there is no popup
              onAction(template);
            }
          }}
        >
          {title}
        </ScDocumentTemplateMenuItemButton>
      );
    },
    [withConfirm, onAction, templateDescriptionField],
  );

  const menu = useMemo(() => {
    if (!templates.length) {
      return (
        <Stub data-testid="document-template-stub-container">
          <EyeInvisibleOutlined data-testid="document-template-stub-icon" />
          <span data-testid="document-template-stub-title">{stubTitle}</span>
        </Stub>
      );
    }

    return (
      <ScDocumentTemplateMenu $width={width}>
        {templates.map(template => {
          return (
            <ScDocumentTemplateMenuItem
              key={template._id}
              style={{
                // styling through CSS-in-JS does not override antd's styles
                height: 'auto',
              }}
            >
              {withConfirm ? (
                <PopConfirm
                  variant={'error'}
                  onConfirm={() => onAction(template)}
                  placement={confirmPlacement}
                  align={{
                    offset: confirmOffsetX ? [confirmOffsetX] : [],
                  }}
                >
                  {getDocumentTemplateMenuItemButton(template)}
                </PopConfirm>
              ) : (
                getDocumentTemplateMenuItemButton(template)
              )}
            </ScDocumentTemplateMenuItem>
          );
        })}
      </ScDocumentTemplateMenu>
    );
  }, [
    templates,
    width,
    stubTitle,
    withConfirm,
    confirmPlacement,
    confirmOffsetX,
    getDocumentTemplateMenuItemButton,
    onAction,
  ]);

  const mergeProps = useMemo<Partial<React.ComponentProps<typeof Dropdown>>>(() => {
    const result: Partial<React.ComponentProps<typeof Dropdown>> = {};

    if (visible !== undefined) result.visible = visible;

    return result;
  }, [visible]);

  return <Dropdown {...rest} {...mergeProps} overlay={menu} />;
};
