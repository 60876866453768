import { SearchOutlined } from '@ant-design/icons';
import {
  ContactFields,
  ContactItem,
  CustomerItem,
  CustomerFields,
  Filter,
} from 'types/vendor';
import { FILTER_SIGNS } from 'common/constants';
import { useContact } from 'context/contact/ContactProvider';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { Status } from 'utils/types';
import { SearchCustomerModal } from '../customer/SearchCustomerModal';
import { getFilterTableTitlesBySearchColumns } from '../customerForms/utils/get-filtered-table-titles-by-search-columns';
import { SearchModal } from '../SearchModal/SearchModal';
import getColumnSearchProps from 'components/table/helpers/getColumnSearchProps';
import { TableInputFilterByColumn } from '../table/TableInputFilterByColumn';

interface Props {
  onClose(): void;
  onSubmit(result: ContactItem[]): void;
  initialValues?: ContactItem[];
  /** Used just to update local `filters` state. */
  filters?: Filter<ContactFields>;
  onAddNew?(): void;
  selectMode?: 'single' | 'multiple';
}
export const SearchContactModal: FC<Props> = ({
  onClose,
  onSubmit,
  onAddNew,
  initialValues,
  filters: externalFilters,
  selectMode,
}) => {
  const { t } = useTranslation();

  const { status: contactStatus, getContactsList, contacts } = useContact();

  const [filters, setFilters] = useState<Filter<ContactFields>>([]);
  const [isSearchCustomerVisible, setIsSearchCustomerVisible] = useState<boolean>(false);

  // After rerender filters link to new memory cell that's why need to check externalFilters like string
  const stringifiedExternalFilters = JSON.stringify(externalFilters);

  useEffect(() => {
    setFilters(externalFilters ?? []);
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
  }, [stringifiedExternalFilters]);

  // It's a hack for next `useEffect`. Smh effect had been called repeatedly, though deps didn't change.
  const stringifiedFilters = useMemo<string>(() => JSON.stringify(filters), [filters]);
  useEffect(() => {
    const timeout = setTimeout(async () => {
      await getContactsList(filters);
    }, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
  }, [stringifiedFilters, getContactsList]);

  const isLoading = useMemo(() => {
    return [contactStatus].includes(Status.Loading);
  }, [contactStatus]);

  const handleReset = useCallback(async dataIndex => {
    setFilters(filters => filters.filter(filter => filter.name !== dataIndex));
  }, []);

  const handleSearch = useCallback(
    async (selectedKeys, confirm, dataIndex) => {
      confirm({ closeDropdown: true });
      const preparedFilters = filters.filter(filter => get(filter, 'name') !== dataIndex);
      const filterValue = get(selectedKeys, 0, '');

      if (filterValue) {
        const sign =
          dataIndex === ContactFields.relationId
            ? FILTER_SIGNS.EQUAL
            : FILTER_SIGNS.INCLUDES;

        preparedFilters.push({
          name: dataIndex,
          value: filterValue,
          sign: sign,
        });
      }

      setFilters(preparedFilters);
    },
    [filters],
  );

  const onSearchByCustomer = useCallback(confirm => {
    setIsSearchCustomerVisible(true);
    confirm({ closeDropdown: true });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: ContactFields.ID,
        key: ContactFields.ID,
      },
      {
        title: t('CLIENTS_LAST_NAME'),
        dataIndex: ContactFields.name2,
        key: ContactFields.name2,
      },
      {
        title: t('CLIENTS_FIRST_NAME'),
        dataIndex: ContactFields.name,
        key: ContactFields.name,
      },

      {
        title: t('CLIENTS_PHONE_NUMBER'),
        dataIndex: ContactFields.phone,
        key: ContactFields.phone,
      },
      {
        title: t('CLIENTS_EMAIL'),
        dataIndex: ContactFields.email,
        key: ContactFields.email,
      },
      {
        title: t('CUSTOMER_ID'),
        dataIndex: ContactFields.relationId,
        key: ContactFields.relationId,
        ...getColumnSearchProps({
          filters: filters,
          handleReset: handleReset,
          handleSearch: handleSearch,
          dataIndex: ContactFields.relationId,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, visible }) => {
            return (
              <TableInputFilterByColumn
                dataIndex={ContactFields.relationId}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                handleSearch={handleSearch}
                handleReset={handleReset}
                visible={visible}
                inputProps={{
                  suffix: <SearchOutlined onClick={() => onSearchByCustomer(confirm)} />,
                  placeholder: t('CUSTOMER_ID_PLACEHOLDER'),
                }}
              />
            );
          },
        }),
      },
    ].map(column => {
      if (column.key === ContactFields.relationId) {
        return column;
      }
      return {
        ...column,
        ...getColumnSearchProps({
          dataIndex: column.dataIndex,
          filters: filters,
          handleReset: handleReset,
          handleSearch: handleSearch,
        }),
      };
    });
  }, [t, filters, handleReset, handleSearch, onSearchByCustomer]);

  const tableTitles = useMemo(() => {
    return getFilterTableTitlesBySearchColumns<ContactFields>(columns, {
      // @todo: add vat code for types for contact fields
      [CustomerFields.vatNumber]: t('CLIENTS_VAT'),
    });
  }, [columns, t]);

  const handleSelect = useCallback(async (records: CustomerItem[]) => {
    const recordId = records[0]?.[CustomerFields.id];

    if (!recordId) return;

    setFilters(all => {
      const clean = all.filter(({ name }) => name !== ContactFields.relationId);
      return [
        ...clean,
        {
          name: ContactFields.relationId,
          value: recordId,
          sign: FILTER_SIGNS.EQUAL,
        },
      ];
    });
  }, []);

  return (
    <>
      {isSearchCustomerVisible && (
        <SearchCustomerModal
          onClose={() => setIsSearchCustomerVisible(false)}
          onSubmit={handleSelect}
        />
      )}
      <SearchModal
        testNamePrefix="contact"
        selectMode={selectMode}
        onClose={onClose}
        initialValue={initialValues}
        onAddNew={onAddNew}
        tableTitles={tableTitles}
        tableColumns={columns}
        tableData={contacts}
        tableDataIdField={ContactFields.ID}
        filters={filters}
        onFilterClearAll={() => setFilters([])}
        onFilterClearOne={handleReset}
        isLoading={isLoading}
        onSubmit={records => {
          onSubmit(records);
          onClose();
        }}
      />
    </>
  );
};
