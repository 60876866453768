import { createAction, createReducer } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import {
  DynamicStepFeaturesFlags,
  DynamicStepFeaturesFlagsGrouped,
} from 'common/dynamicPagesFeaturesFlags';
import { GlobalFeaturesFlags } from 'common/globalFeaturesFlags';
import { ApplicationState } from '../index';
import { DefaultParamsListItem } from '@hypercharge/xdms-client/lib/types';

const NAME = 'featuresFlags';

interface State {
  readonly globalFeaturesStatus: Status;
  /** if undefined - didn't set up yet, null - just empty.
   * But in most cases will not be null. */
  readonly globalFeatures?: GlobalFeaturesFlags | null | undefined;
  readonly dynamicStepsFeaturesStatus: Status;
  /** if undefined - didn't set up yet, null - just empty.
   * But in most cases will not be null. */
  readonly dynamicStepsFeatures?: DynamicStepFeaturesFlagsGrouped | null | undefined;
  readonly currentPageFeatures?: DynamicStepFeaturesFlags | null | undefined;
  readonly defaultValues?: DefaultParamsListItem | null | undefined;
}

const initialState: State = {
  globalFeaturesStatus: Status.Idle,
  dynamicStepsFeaturesStatus: Status.Idle,
};

const actions = {
  setGlobalFeaturesFlagsStatus: createAction<State['globalFeaturesStatus']>(
    getActionName(NAME, 'SET_GLOBAL_FEATURES_FLAGS_STATUS'),
  ),
  setGlobalFeaturesFlags: createAction<State['globalFeatures']>(
    getActionName(NAME, 'SET_GLOBAL_FEATURES_FLAGS'),
  ),
  setDynamicStepsFeaturesStatus: createAction<State['dynamicStepsFeaturesStatus']>(
    getActionName(NAME, 'SET_DYNAMIC_STEPS_FEATURES_FLAGS_STATUS'),
  ),
  setDynamicStepsFeatures: createAction<State['dynamicStepsFeatures']>(
    getActionName(NAME, 'SET_DYNAMIC_STEPS_FEATURES_FLAGS'),
  ),
  setCurrentPageFeatures: createAction<State['currentPageFeatures']>(
    getActionName(NAME, 'SET_CURRENT_PAGE_FEATURES_FLAGS'),
  ),
  setDefaultValues: createAction<State['defaultValues']>(
    getActionName(NAME, 'SET_DEFAULT_VALUES'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setGlobalFeaturesFlagsStatus, (state, action) => ({
    ...state,
    globalFeaturesStatus: action.payload ?? null,
  }));
  builder.addCase(actions.setGlobalFeaturesFlags, (state, action) => ({
    ...state,
    globalFeatures: action.payload ?? null,
  }));
  builder.addCase(actions.setDynamicStepsFeaturesStatus, (state, action) => ({
    ...state,
    dynamicStepsFeaturesStatus: action.payload ?? null,
  }));
  builder.addCase(actions.setDynamicStepsFeatures, (state, action) => ({
    ...state,
    dynamicStepsFeatures: action.payload ?? null,
  }));
  builder.addCase(actions.setCurrentPageFeatures, (state, action) => ({
    ...state,
    currentPageFeatures: action.payload ?? null,
  }));
  builder.addCase(actions.setDefaultValues, (state, action) => ({
    ...state,
    defaultValues: action.payload ?? null,
  }));
});

const selectors = {
  getAll: (state: ApplicationState): ApplicationState['featuresFlags'] =>
    state.featuresFlags,
  getGlobalFeaturesStatus: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['globalFeaturesStatus'] =>
    state.featuresFlags.globalFeaturesStatus,
  getDynamicStepsFeaturesStatus: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['dynamicStepsFeaturesStatus'] =>
    state.featuresFlags.dynamicStepsFeaturesStatus,
  getGlobalFeatures: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['globalFeatures'] =>
    state.featuresFlags.globalFeatures,
  getDynamicStepsFeatures: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['dynamicStepsFeatures'] =>
    state.featuresFlags.dynamicStepsFeatures,
  getCurrentPageFeatures: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['currentPageFeatures'] =>
    state.featuresFlags.currentPageFeatures,
  getDefaultValues: (
    state: ApplicationState,
  ): ApplicationState['featuresFlags']['defaultValues'] =>
    state.featuresFlags.defaultValues,
};

export type { State as FeatureFlagsState };
export {
  actions as featuresFlagsActions,
  reducer as featuresFlagsReducer,
  selectors as featuresFlagsSelectors,
};
