import styled from 'styled-components';
import Checkbox from 'components/form/Checkbox';
import { Button } from 'components/button';

export const ScPackagesLinesWrapper = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  margin-top: 0.625em;
`;

export const ScPackageGroupWrapper = styled.div`
  border: 1px solid #e9dbdb;
  padding: 0.813em 1em;
  margin-bottom: 0.625em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PackageTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.25em;
  text-transform: capitalize;
  text-align: center;
  padding: 1.5em 1.5625em;
  border-bottom: 1px solid rgb(191, 191, 191);
`;

export const ScPackageLinesTableHeaderTitle = styled.span`
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.0625em;
  color: ${({ theme }) => theme.textColor};
`;

export const ScPackageGroupCheckbox = styled(Checkbox)<{ disabled: boolean }>`
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
`;

export const ScPackageEntryWrapper = styled.div`
  margin-bottom: 1.875em;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ScPackageContentWrapper = styled.div`
  padding: 0.9375em 1.5625em 1.875em 1.5625em;
`;

export const ScPackageGroupTitle = styled.span`
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.066875em;
  color: ${({ theme }) => theme.textColor};
`;

export const ScTotalSection = styled.div`
  padding-top: 1.563em;
  padding-right: 3.813em;
  text-align: right;
`;

export const ScTotalItem = styled.span`
  &:last-of-type {
    margin-left: 1.063em;
  }
`;

export const ScTotalItemValue = styled.span`
  color: ${({ theme }) => theme.textColor};
  font-size: 0.813em;
  font-weight: 500;
  margin-left: 0.313em;
`;

export const ScModalFooterItem = styled.div`
  flex: 1;

  &:last-of-type {
    margin-left: 0.438em;
  }
`;

export const ScModalFooterDisabledButton = styled(Button)`
  pointer-events: none;
`;
