import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  FC,
  PropsWithChildren,
} from 'react';
import { get, find } from 'utils';
import { Option } from 'types/common';
import { Status } from 'utils/types';
import { DEFAULT_CURRENCY, CURRENCIES, STORAGE_KEYS } from 'common/constants';
import * as storage from 'utils/storage';

type ContextValue = {
  status: Status;
  mappedCurrencies: Option[];
  setActiveCurrency(currencyCode: string): void;
  currency: string;
  currencyCode: string;
};

export const CurrencyContext = React.createContext<ContextValue | undefined>(undefined);

const CurrencyProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const defaultCurrencyCode = CURRENCIES[0].value;
  const [status, setStatus] = useState(Status.Success);
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const [currencyCode, setCurrencyCode] = useState<string>(defaultCurrencyCode);

  const setActiveCurrency = useCallback((currencyCode: string) => {
    if (currencyCode) {
      currencyCode = currencyCode.trim();
      const activeCurrency = find(
        CURRENCIES,
        currency => currency.value === currencyCode,
      );
      const currencyLabel = get(activeCurrency, 'label', DEFAULT_CURRENCY);
      setCurrency(currencyLabel);
      setCurrencyCode(get(activeCurrency, 'value', defaultCurrencyCode));
      storage.saveInLocalStorage(STORAGE_KEYS.currency, activeCurrency);
    }
  }, []);

  useEffect(() => {
    setStatus(Status.Loading);
    const storedCurrency = storage.loadFromLocalStorage<string>(STORAGE_KEYS.currency);
    setCurrency(get(storedCurrency, 'label', DEFAULT_CURRENCY));
    setCurrencyCode(get(storedCurrency, 'value', defaultCurrencyCode));
    setStatus(Status.Success);
  }, []);

  const value = useMemo(
    () => ({
      mappedCurrencies: CURRENCIES,
      status,
      currency,
      currencyCode,
      setActiveCurrency,
    }),
    [status, currency, currencyCode, setActiveCurrency],
  );

  return <CurrencyContext.Provider value={value} {...props} />;
};

const useCurrency = (): ContextValue => {
  const context = useContext(CurrencyContext);

  if (context === undefined) {
    throw new Error('useCurrency must be used within an CurrencyProvider');
  }

  return context;
};

export { CurrencyProvider, useCurrency };
