import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  text-align: center;
  padding: 2em 0;
`;

export const NotFoundTitle = styled.h1`
  font-size: 5em;
  line-height: 5em;
  margin-bottom: 1em;
  color: ${props => props.theme.mainColor};
`;
