export const NO_IMAGE_PATH = '/images/no-image.jpg';

export const F5_KEYBOARD_BUTTON_CODE = 116;
export const R_KEYBOARD_BUTTON_CODE = 82;

export const NAME_TAB_ALL = 'ALL';
export const NAME_TAB_ALL_KEY = 'ALL_TAB_IN_SUBSTEP';
export const defaultStepId = 'ACC';

export enum CommentsModalTabs {
  internal = 'internal',
  external = 'external',
  commercial = 'commercial',
}

export const DEFAULT_CURRENT_TABLE_PAGE = 1;
export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const DEFAULT_MODAL_TABLE_PAGE_SIZE = 10;
export const TABLET_MODAL_TABLE_PAGE_SIZE = 7;
export const DEFAULT_PAGE_SIZE_OPTIONS = ['50', '100'];

export const DEFAULT_REMARK_ROLE = '1';

export const CONFIG_CONTACT_RELATION_TYPE = 'NRconfig';

export const DEFAULT_COUNTRY_CODE = 'BE';

export const TOTAL_PUBLISH_DOCUMENT_TYPE = 'MTP';

/**
 * In case there is single document name field at a time
 * then use this constant for setting the key for the input
 * otherwise some other id should be used for each document
 * in case there are 2 or more of them
 *
 * Examples:
 *
 * 1 input --> documentName = { documentName: 'Key for single document name' }
 *
 * 2 inputs --> documentName = {
 *  'file-id-1': 'First document name',
 *  'file-id-2': 'Second document name',
 * }
 *
 * 3 inputs --> documentName = {
 *  'file-id-1': 'First document name',
 *  'file-id-2': 'Second document name',
 *  'file-id-3': 'Third document name',
 * }
 */
export const DEFAULT_DOCUMENT_NAME_KEY = 'documentName';
export const DISABLED_DOCUMENT_TYPES = [TOTAL_PUBLISH_DOCUMENT_TYPE];
export const EXCLUDED_DOCUMENT_TYPES = ['TAK'];
