import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';

interface Props {
  onPrev(): void;
}

const InfoFormButtons: FC<Props> = ({ onPrev }) => {
  const { t } = useTranslation();

  return (
    <Row
      gutter={[10, { xs: 0, sm: 10, md: 0 }]}
      data-testid="info-page-form-actions-container"
    >
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8, offset: 8 }}
        data-testid="info-page-form-actions-skip-wrapper"
      >
        <Button
          htmlType="button"
          variant={'prev'}
          fullwidth
          data-testid="info-page-form-actions-prev-step-btn"
          onClick={onPrev}
        >
          {t('PREV_STEP')}
        </Button>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 8 }}
        data-testid="info-page-form-actions-skip-wrapper"
      >
        <Button
          htmlType="submit"
          variant="highlighted"
          fullwidth
          data-testid="info-page-form-actions-submit-btn"
        >
          {t('INFO_CONFIRM_BUTTON')}
        </Button>
      </Col>
    </Row>
  );
};

export default InfoFormButtons;
