import { pick } from 'utils';

/**
 * Picks all requested fields if present and returns deep copy of entity.
 */
export const cleanEntity = <T = Record<string, unknown>, O = Partial<T>>(
  entity: T,
  fieldsToPick: string[],
): O => {
  return pick(entity, fieldsToPick) as O;
};
