import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { ScSelector } from './styles';
import { useSelector } from 'react-redux';
import { hostSettingsSelectors } from 'store/hostSettings';

type Props = {
  handleChange?(): void;
};

export const Language: FC<Props> = ({ handleChange }) => {
  const { i18n } = useTranslation();
  const { availableLanguages } = useSelector(hostSettingsSelectors.getLanguageSettings);

  const languageList = useMemo(() => {
    if (!availableLanguages) return [];
    return mapToSelectOptions<{ code: string; label: string }>(availableLanguages, {
      label: 'label',
      value: 'code',
    });
  }, [availableLanguages]);

  const changeLang = useCallback(
    (value: string) => {
      i18n.changeLanguage(value);
      handleChange?.();
    },
    [i18n, handleChange],
  );

  return (
    <ScSelector
      className="button-styled"
      options={languageList}
      onChange={event => changeLang(event.target.value)}
      defaultValue={i18n.language}
      value={i18n.language}
      size="small"
      bordered={false}
    />
  );
};
