import React, { useCallback, useMemo, useState } from 'react';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { streamingSelectors } from 'store/streaming';
import { useTranslation } from 'react-i18next';
import useStreamingCarGallery from 'components/streaming/useStreamingCarGallery';
import useStreamingOpenMyTerminal from 'components/streaming/useStreamingOpenMyTerminal';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useSelector } from 'react-redux';
import { configurationSelectors, modelSelectors } from 'store';
import {
  ScPreviewIcon,
  ScStreamingModalCloseForTerminal,
  ScStreamingOpenActiveTerminal,
  ScStreamingPreviewAllNotSelectedRecords,
  ScStreamingPreviewAllSelectedRecords,
  ScStreamingSettings,
  ScStreamingStartStreaming,
  ScStreamingStopStreaming,
  ScStreamingWithSummaryPrice,
} from './index.styles';
import { StreamingOption } from './types';
import { RotatingLoader } from 'components/RotatingLoader';
import useRequiredCategory from 'hooks/useRequiredCategory';

export default function useMenu(): Result {
  const [selectedOption, setSelectedOption] = useState<StreamingOption | null>(null);

  const configurator = useSelector(streamingSelectors.getConfigurator);
  const preferredTerminal = useSelector(streamingSelectors.getPreferredTerminal);
  const isStreamWaiting = useSelector(streamingSelectors.getIsStreamWaiting);

  const { isFeatureEnabled } = useFeature();

  const isAllowHeaderGalleryFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderGalleryEnabled,
  });

  const isForceStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowForceStreaming,
  });

  const { t } = useTranslation();
  const { sendMessage, openModal } = useStreaming();
  const { configurationDetails } = useSelector(configurationSelectors.getAll);
  const { isModelConfigured } = useSelector(modelSelectors.getAll);
  const currentScreen = useSelector(streamingSelectors.getCurrentScreen);
  const activeTerminal = useSelector(streamingSelectors.getActiveTerminal);
  const withSummaryPrice = activeTerminal?.flags.withSummaryPrice ?? true;
  const requiredCategoryName = useRequiredCategory();
  const {
    handlePreview: handleCarPreview,
    isLoading: isCarPreviewLoading,
    error: carPreviewError,
    title: carPreviewTitle,
  } = useStreamingCarGallery();

  const { handleOpenMyTerminal, canOpenMyTerminal } = useStreamingOpenMyTerminal();

  const handleStreamingSettings = useCallback(() => {
    if (configurator) {
      openModal();
    }
  }, [openModal, configurator]);

  const options = useMemo(() => {
    return [
      {
        label: t('STREAMING_VEHICLE_GALLERY_LABEL'),
        icon: isCarPreviewLoading ? <RotatingLoader /> : <ScPreviewIcon />,
        handler: () => {
          setSelectedOption(StreamingOption.CAR_PREVIEW);
          handleCarPreview();
        },
        available: !!(
          isAllowHeaderGalleryFeatureEnabled &&
          configurationDetails &&
          isModelConfigured &&
          !isStreamWaiting
        ),
        disabled: Boolean(requiredCategoryName || !!carPreviewError),
        title: isCarPreviewLoading ? '' : carPreviewTitle,
      },
      {
        label: t('STREAMING_AVAILABLE_OPTIONS_LABEL'),
        icon: <ScStreamingPreviewAllNotSelectedRecords />,
        handler: () => {
          setSelectedOption(StreamingOption.AVAILABLE_RECORDS);
        },
        available: !!activeTerminal && isModelConfigured && !isStreamWaiting,
        disabled: false,
        title: '',
      },
      {
        label: t('STREAMING_INSTALLED_OPTIONS_LABEL'),
        icon: <ScStreamingPreviewAllSelectedRecords />,
        handler: () => {
          setSelectedOption(StreamingOption.INSTALLED_RECORDS);
        },
        available: !!activeTerminal && isModelConfigured && !isStreamWaiting,
        disabled: false,
        title: '',
      },
      {
        label: t('STREAMING_CLOSE_PREVIEW_MODAL_FOR_TERMINAL'),
        icon: <ScStreamingModalCloseForTerminal />,
        handler: () => {
          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'global',
              data: {
                carPreviewImageIndex: null,
                isAllRecordsPreview: false,
              },
            },
          });

          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'selection',
              data: {
                currentlyPreviewedPackage: null,
              },
            },
          });
        },
        available: !!activeTerminal && isModelConfigured && !isStreamWaiting,
        disabled: false,
        title: '',
      },
      {
        label: t('STREAMING_OPEN_ACTIVE_TERMINAL'),
        icon: <ScStreamingOpenActiveTerminal />,
        handler: () => {
          handleOpenMyTerminal(activeTerminal!.customerId);
        },
        available: true,
        disabled: !activeTerminal,
        title: '',
      },
      {
        label: t(
          activeTerminal
            ? 'STREAMING_STOP_STREAMING_LABEL'
            : 'STREAMING_OPEN_MY_TERMINAL',
        ),
        icon: activeTerminal ? (
          <ScStreamingStopStreaming />
        ) : (
          <ScStreamingStartStreaming />
        ),
        handler: () => {
          setSelectedOption(StreamingOption.ACTIVATION);

          if (!activeTerminal) {
            handleOpenMyTerminal();
            return;
          }

          sendMessage({
            type: StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
            data: {
              customerId: activeTerminal?.customerId,
            },
          });
        },
        // we should be able to stop streaming if there is active one
        // however if the feature flag is disabled and no streaming is active
        // we hide the direct link to start the streaming since it is opening new tab
        // and is considered as a standalone feature
        available:
          activeTerminal && preferredTerminal?.customerId === activeTerminal.customerId
            ? false
            : isForceStreamingFeatureEnabled,
        disabled: !canOpenMyTerminal,
        title: '',
      },
      {
        label: withSummaryPrice
          ? t('STREAMING_WITHOUT_SUMMARY_PRICE')
          : t('STREAMING_WITH_SUMMARY_PRICE'),
        icon: <ScStreamingWithSummaryPrice />,
        handler: () => {
          sendMessage({
            type: StreamingEventType.UPDATE_TERMINAL_FLAGS,
            data: {
              customerId: activeTerminal?.customerId,
              flags: {
                withSummaryPrice: !withSummaryPrice,
              },
            },
          });
        },
        available: currentScreen === 'summary' && !!activeTerminal,
        disabled: false,
        title: '',
      },
      {
        label: t('STREAMING'),
        icon: <ScStreamingSettings />,
        handler: () => {
          setSelectedOption(StreamingOption.SETTINGS);
          handleStreamingSettings();
        },
        available: true,
        disabled: false,
        title: '',
      },
    ];
  }, [
    t,
    isCarPreviewLoading,
    isAllowHeaderGalleryFeatureEnabled,
    configurationDetails,
    isModelConfigured,
    isStreamWaiting,
    requiredCategoryName,
    carPreviewError,
    carPreviewTitle,
    activeTerminal,
    preferredTerminal,
    isForceStreamingFeatureEnabled,
    canOpenMyTerminal,
    withSummaryPrice,
    currentScreen,
    handleCarPreview,
    sendMessage,
    handleOpenMyTerminal,
    handleStreamingSettings,
  ]);

  return { options, selectedOption, selectOption: setSelectedOption };
}

type Option = {
  label: string;
  icon: React.ReactNode;
  handler(): void;
  available: boolean;
  disabled: boolean;
  title: string;
};

type Result = {
  options: Option[];
  selectedOption: StreamingOption | null;
  selectOption(newOption: StreamingOption | null): void;
};
