import { Input } from 'components/form/Input';
import styled from 'styled-components';

export const ScInput = styled(Input)`
  margin-bottom: 1em;

  input {
    margin-bottom: 0;
  }
`;
