import React, { FC } from 'react';
import { Button } from './Button';
import {
  ScDropdownIcon,
  ScMenuLabel,
  ScDropdownTriggerButton,
} from './ButtonDropdownTrigger.styles';

export const ButtonDropdownTrigger: FC<
  Omit<React.ComponentProps<typeof Button>, 'variant'>
> = ({ children, ...props }) => {
  return (
    <ScDropdownTriggerButton {...props}>
      <ScMenuLabel data-testid="label">{children}</ScMenuLabel>
      <ScDropdownIcon className="cp-i-none" focusable="false" data-testid="icon" />
    </ScDropdownTriggerButton>
  );
};
