import React, { FC } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import UserDesktop from './UserDesktop';
import SelectorSkeleton from 'layout/Default/header/SelectorSkeleton';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store';

const User: FC = () => {
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);
  if (!isAuthenticated) {
    return <SelectorSkeleton />;
  }

  return (
    <ErrorBoundary>
      <UserDesktop />
    </ErrorBoundary>
  );
};

export default User;
