import { Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import {
  FiltersList,
  SelectedFiltersItemsList_Filters,
} from 'components/filters/FiltersList';
import Table from 'components/table';
import { get } from 'utils';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_MODAL_TABLE_PAGE_SIZE,
  TABLET_MODAL_TABLE_PAGE_SIZE,
} from 'utils/constants';
import {
  ScFooter,
  ScSaveButton,
  ScAddNewButton,
  ScHeader,
  ScHeaderContainer,
  ScHeaderRow,
} from './SearchModal.styles';
import { ColumnType } from 'antd/lib/table';
import { RowSelectionType } from 'antd/lib/table/interface';
import { sizes } from 'common/theme';
import { VerticalScrolling } from 'components/VerticalScrolling/VerticalScrolling';
import classNames from 'classnames';
import { Modal } from 'components/modal';

type Item = Record<string, unknown>;

type SelectMode = 'single' | 'multiple';

const SelectModesMapping: Record<SelectMode, RowSelectionType> = {
  single: 'radio',
  multiple: 'checkbox',
};

interface Props {
  onClose(): void;
  initialValue?: Item[];
  tableColumns: Pick<ColumnType<Item>, 'dataIndex' | 'title'>[];
  onAddNew?(): void;
  tableData: Item[];
  tableDataIdField: string;
  tableTitles?: Record<string, string>;
  filters: SelectedFiltersItemsList_Filters;
  onFilterClearAll(): void;
  onFilterClearOne(dataIndex): void;
  isLoading: boolean;
  onSubmit(v: Item[]): void;
  testNamePrefix?: string;
  selectMode?: SelectMode;
}
/** Modal with table, pagination, submit button */
export const SearchModal: FC<Props> = props => {
  const {
    onClose,
    initialValue,
    tableColumns,
    onAddNew,
    tableData,
    tableDataIdField,
    tableTitles,
    filters,
    onFilterClearAll,
    onFilterClearOne,
    onSubmit,
    testNamePrefix = '',
    selectMode = 'single',
  } = props;

  const { t } = useTranslation();

  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });
  const pageSize = useMemo<number>(() => {
    return isLgAndWider ? DEFAULT_MODAL_TABLE_PAGE_SIZE : TABLET_MODAL_TABLE_PAGE_SIZE;
  }, [isLgAndWider]);

  const [selectedRows, setSelectedRows] = useState<Item[]>(initialValue ?? []);
  const selectedRowsKeys = useMemo<string[]>(() => {
    return selectedRows.map(item => String(get(item, tableDataIdField)));
  }, [selectedRows, tableDataIdField]);

  const localTableTitles = useMemo<Record<string, string>>(() => {
    if (tableTitles) return tableTitles;

    return tableColumns.reduce<Record<string, string>>((acc, col) => {
      if (col.dataIndex && col.title) acc[String(col.dataIndex)] = String(col.title);
      return acc;
    }, {});
  }, [tableColumns, tableTitles]);

  const onSelect = useCallback(
    (record: Item) => {
      if (selectMode === 'single') {
        setSelectedRows([record]);
      }

      if (selectMode === 'multiple') {
        setSelectedRows(itemsList => {
          const items = [...itemsList];
          const f = tableDataIdField;
          const idx = items.findIndex(rec => rec[f] === record[f]);

          if (~idx) items.splice(idx, 1);
          else items.push(record);

          return items;
        });
      }
    },
    [selectMode, tableDataIdField],
  );

  const handleSave = useCallback(() => onSubmit(selectedRows), [selectedRows, onSubmit]);

  return (
    <VerticalScrolling refEl="search-modal-ref" refWrapper="search-modal-wrapper-ref">
      <Modal
        variant="lg"
        visible
        onCancel={onClose}
        data-testid="customer-page-search-contact-modal"
        className={classNames({
          'search-modal-ref': true,
        })}
        wrapClassName={classNames({
          'search-modal-wrapper-ref': true,
        })}
        center
      >
        <ScHeader>
          <ScHeaderContainer size="lg">
            <ScHeaderRow>
              <Col xs={18} lg={20}>
                <FiltersList
                  tableTitles={localTableTitles}
                  filters={filters}
                  onClearOne={onFilterClearOne}
                  onClearAll={onFilterClearAll}
                />
              </Col>
              <Col xs={6} lg={4}>
                {onAddNew && (
                  <ScAddNewButton
                    onClick={onAddNew}
                    variant={'add'}
                    data-testid={`${testNamePrefix}-modal-add-new-btn`}
                  >
                    {t('ADD_CONTACT_MODAL_ADD')}
                  </ScAddNewButton>
                )}
              </Col>
            </ScHeaderRow>
          </ScHeaderContainer>
        </ScHeader>
        <Table
          columns={tableColumns}
          rowSelection={{
            selectedRowKeys: selectedRowsKeys,
            type: SelectModesMapping[selectMode],
            onSelect: onSelect,
          }}
          data={tableData}
          rowKey={record => String(get(record, tableDataIdField))}
          className="modal-table"
          onRow={record => ({
            onClick: () => {
              onSelect(record);
              return undefined;
            },
            // @todo: move to styled component styles
            style: {
              fontSize: '0.875em',
            },
          })}
          scroll={{
            x: true,
          }}
          pageSize={pageSize}
          pagination={tableData.length > pageSize}
        />
        <ScFooter>
          <ScSaveButton
            disabled={!selectedRows.length || !tableData.length}
            variant="primary"
            onClick={handleSave}
            data-testid={`${testNamePrefix}-modal-save-btn`}
          >
            {t('SAVE')}
          </ScSaveButton>
        </ScFooter>
      </Modal>
    </VerticalScrolling>
  );
};
