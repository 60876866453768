import { Col } from 'antd';
import { Pagination } from 'components/table/Pagination';
import SubcategoryItem from './item';
import {
  CategoryModelItem,
  subcategoryKeys,
} from 'context/tableCategories/TableCategoriesProvider';
import { get } from 'utils';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_CURRENT_TABLE_PAGE, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { getUpdatedPagination } from 'utils/format';
import { useTranslation } from 'react-i18next';
import { ScPaginationWrapper, ScRow, ScSubcategoryTable } from './index.styles';

interface Props {
  pageSize?: number;
  defaultCurrentPage?: number;
  totalCount?: number;
  subcategories: CategoryModelItem[];
  selectSubcategory(category: CategoryModelItem): void;
  getItemImage(category: CategoryModelItem): string;
}

const Subcategory: FC<Props> = ({
  subcategories,
  selectSubcategory,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  defaultCurrentPage = DEFAULT_CURRENT_TABLE_PAGE,
  totalCount = subcategories.length,
  getItemImage,
}) => {
  const { t } = useTranslation();

  const [tableData, setTableData] = useState<CategoryModelItem[]>([]);

  const withPagination: boolean = useMemo(() => {
    return subcategories.length > pageSize;
  }, [pageSize, subcategories]);

  const onPaginationChange = useCallback(
    (page, pageSize) => {
      setTableData(subcategories.slice((page - 1) * pageSize, pageSize * page));
      if (withPagination) {
        const updatedPaginationSetting = getUpdatedPagination({
          defaultPagination: defaultPaginationSettings,
          page,
          data: subcategories,
          pageSize,
          totalCount,
        });
        setPaginationSettings(updatedPaginationSetting as any);
      }
    },
    [subcategories, withPagination, totalCount],
  );

  const defaultPaginationSettings = useMemo(
    () => ({
      pageSize,
      defaultCurrent: defaultCurrentPage,
      current: defaultCurrentPage,
      from: defaultCurrentPage,
      to: Math.min(pageSize, subcategories.length),
      total: totalCount,
      onChange: onPaginationChange,
      // @todo: check whether it is needed
      onResize: () => {},
    }),
    [pageSize, defaultCurrentPage, subcategories, totalCount, onPaginationChange],
  );

  const [paginationSettings, setPaginationSettings] = useState(defaultPaginationSettings);

  const getUniqueSubcategoryKey = useCallback((category: CategoryModelItem): string => {
    const key = subcategoryKeys.reduce((accumulator, key) => {
      return `${accumulator}-${get(category, key, '')}`;
    }, '');
    return key;
  }, []);

  useEffect(() => {
    if (subcategories) {
      setTableData(subcategories.slice(0, defaultCurrentPage * pageSize));
      if (withPagination) {
        setPaginationSettings({
          ...defaultPaginationSettings,
          current: defaultCurrentPage,
          total: totalCount,
        });
      }
    }
  }, [
    subcategories,
    defaultCurrentPage,
    pageSize,
    withPagination,
    totalCount,
    defaultPaginationSettings,
  ]);

  return (
    <ScSubcategoryTable
      className={withPagination ? 'with-pagination' : ''}
      data-testid="table-subcategories"
    >
      <ScRow gutter={[50, 27]} data-testid="table-subcategories-wrapper">
        {tableData.map(category => (
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xxl={{ span: 6 }}
            key={getUniqueSubcategoryKey(category)}
          >
            <SubcategoryItem
              t={t}
              data={category}
              selectSubcategory={selectSubcategory}
              id={getUniqueSubcategoryKey(category)}
              categoryImage={getItemImage(category)}
            />
          </Col>
        ))}
      </ScRow>
      {withPagination && (
        <ScPaginationWrapper data-testid="table-subcategories-pagination">
          <Col span={24}>
            <Pagination {...paginationSettings} />
          </Col>
        </ScPaginationWrapper>
      )}
    </ScSubcategoryTable>
  );
};

export default Subcategory;
