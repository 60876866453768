import { defaultImage } from 'common/constants';
import { ReactEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { hostSettingsSelectors } from '../store/hostSettings';

type Params = {
  imageSrc?: string | undefined;
  imageRef?: HTMLImageElement | null;
};

type Result = {
  isInvalidImage: boolean;
  handleImageError: ReactEventHandler<HTMLImageElement>;
};

export const useInvalidImage = (params: Params): Result => {
  const { imageSrc, imageRef } = params;

  const [isBrokenImage, setIsBrokenImage] = useState<boolean>(false);

  const hostLogo = useSelector(hostSettingsSelectors.getPrimaryLogo);

  useEffect(() => {
    if (!imageSrc) {
      if (imageRef) {
        imageRef.src = hostLogo ?? defaultImage;
        setIsBrokenImage(true);
      }
    }
  }, [imageSrc, hostLogo, imageRef]);

  useEffect(() => {
    if (
      !imageRef?.src.includes(hostLogo as string) &&
      !imageRef?.src.includes(defaultImage)
    ) {
      setIsBrokenImage(false);
    }
  }, [imageSrc, hostLogo, imageRef]);

  const handleImageError = useCallback(
    e => {
      e.target.src = hostLogo ?? defaultImage;
      setIsBrokenImage(true);
    },
    [hostLogo],
  );

  // "invalid" means that it is not well-prepared beforehand (by BE ?)
  // and we need to handle image optimization on FE side
  const isInvalidImage = useMemo<boolean>(() => {
    return (
      isBrokenImage || !imageSrc || imageSrc === hostLogo || imageSrc === defaultImage
    );
  }, [isBrokenImage, imageSrc, hostLogo]);

  return { isInvalidImage, handleImageError };
};
