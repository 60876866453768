import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const ScEnabledIcon = styled(EyeFilled)`
  font-size: 2em;
  color: ${({ theme }) => theme.mainColor};
`;

export const ScDisabledIcon = styled(EyeInvisibleFilled)`
  font-size: 2em;
  color: ${({ theme }) => theme.colors.dustyGray};
`;
