import styled from 'styled-components';
import TotalEditableField from '../components/TotalEditableField';
import { TotalTableInput } from '../components/TotalTableInput';

export const ScTotalEditableField = styled(TotalEditableField)<{ $isEdited: boolean }>`
  display: inline-flex;
  height: 2em;
  font-weight: 400;
  ${({ $isEdited, theme }) =>
    $isEdited ? `color: ${theme.recordIsEditedHighlightColor};` : ''}
`;

export const ScTotalTableInput = styled(TotalTableInput)`
  &&.ant-input-number {
    font-size: 1em;
    width: 120%;
    padding-left: 0.1em;

    .ant-input-number-input {
      padding: 0.75em 0.5em 0.75em 0.75em;
      font-weight: 400;
    }
  }
`;
