import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import { CloseWrapper, Close } from './MobileOverlayPage.styles';

const modalStyleMobile = {
  content: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    padding: '0 1.25em',
    boxSizing: 'border-box',
    overflow: 'auto',
    outline: 'none',
    borderRadius: '0',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    minWidth: '50%',
    maxWidth: '405px',
    inset: '0',
    marginLeft: 'auto',
  },
  overlay: {
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

type Props = PropsWithChildren<{
  onClose?(): void;
  className?: string;
}>;

export default ({ onClose, children, className }: Props): React.ReactElement => {
  return (
    <ReactModal
      key="Modal"
      ariaHideApp={false}
      portalClassName="ReactModalPortal_slide"
      closeTimeoutMS={250}
      contentLabel="user"
      isOpen={true}
      onRequestClose={onClose}
      style={modalStyleMobile}
      data-testid="mobile-overlay-modal"
    >
      <CloseWrapper>
        <Close
          onClick={onClose}
          data-testid="mobile-overlay-modal-header-close-icon-button"
        />
      </CloseWrapper>
      <div className={className} data-testid="mobile-overlay-modal-content-wrapper">
        {children}
      </div>
    </ReactModal>
  );
};
