import {
  DocumentListItem,
  DocumentLocation,
  DocumentLockType,
  ConfigurationDocument,
  GetConfigurationDocumentListOutput,
  DocumentDetails,
} from 'types/vendor';
import { USERNAME_PREFIX } from 'common/constants';
import {
  DocumentItem,
  DocumentItemDetails,
  DocumentSource,
  DocumentsSwarm,
} from './types';
import { getDocumentsSorted, isDocDefault, isDocPDF } from './utils';
import { getStatusValuesByDocumentStatus } from './documentStatus';
import { GetFeatureValueParams } from 'context/feature/FeatureProvider';
import { DocumentRelatedEntitySettingsSelectors } from './settings';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { DocumentRelationType } from 'types/vendor';

export const unifyDocumentRecord = {
  listItem: (
    record: DocumentListItem,
    isStoredExternally: (url: string) => boolean,
  ): DocumentItem => {
    const [isExternal] = getStatusValuesByDocumentStatus(record.status);
    const isFileStoredExternally = isStoredExternally(record.fileUrl);

    return {
      ...record,
      computed: {
        location: isFileStoredExternally
          ? DocumentLocation.amazon
          : DocumentLocation.link,
        isFile: isFileStoredExternally,
        isLink: !isFileStoredExternally,
        createdAt: record.updatedAt ?? '',
        isSelectable: isExternal,
        isEditable: record.isEditable,
        isDraggable: !isDocDefault(record) && !isDocPDF(record),
        isDeleteVisible: record.lockType !== DocumentLockType.default,
        isDownloadVisible: isFileStoredExternally,
        isCopyVisible: true,
        isOpenVisible: !isFileStoredExternally,
      },
    };
  },
  swarmItem: (
    record: ConfigurationDocument,
    relationType: string,
    relationId: string,
    isStoredExternally: (url: string) => boolean,
  ): DocumentItem => {
    const [isExternal] = getStatusValuesByDocumentStatus(record.status);
    const isFileStoredExternally = isStoredExternally(record.fileUrl);

    return {
      ...record,
      relationType: relationType as DocumentRelationType,
      relationId: relationId,
      /** Value for type compatibility */
      typeCategoryPicklistCode: '',
      /** Value for type compatibility */
      typeCategory: '',
      computed: {
        location: isFileStoredExternally
          ? DocumentLocation.amazon
          : DocumentLocation.link,
        isFile: isFileStoredExternally,
        isLink: !isFileStoredExternally,
        createdAt: record.updatedAt ?? '',
        isSelectable: isExternal,
        isDraggable: !isDocDefault(record) && !isDocPDF(record),
        isDeleteVisible: record.lockType !== DocumentLockType.default,
        isDownloadVisible: isFileStoredExternally,
        isCopyVisible: true,
        isOpenVisible: !isFileStoredExternally,
      },
    };
  },
  details: (record: DocumentDetails, username: string): DocumentItemDetails => {
    return {
      ...record,
      computed: {
        isLink: record.location === DocumentLocation.link,
        isFile: record.location === DocumentLocation.amazon,
        isEditable:
          record.changedBy === `${USERNAME_PREFIX}${username}` && record.isEditable,
      },
    };
  },
};

export const unifyDocumentsSwarm = (
  configurationDocuments: DocumentListItem[],
  restDocumentsGroups: GetConfigurationDocumentListOutput,
  isStoredExternally: (url: string) => boolean,
  isFeatureEnabled: (params: GetFeatureValueParams) => boolean,
): DocumentsSwarm => {
  const shouldSortByNewestDocuments = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.shouldSortByNewestDocuments,
  });

  const rest: DocumentsSwarm = Object.entries(restDocumentsGroups)
    .filter(values => {
      const key: DocumentSource = values[0] as DocumentSource;
      const settings = DocumentRelatedEntitySettingsSelectors.getBySource(key);
      return isFeatureEnabled({ feature: settings.availabilityFeatureFlag });
    })
    .filter(values => {
      const entitiesList: GetConfigurationDocumentListOutput['options'] = values[1];
      return entitiesList.reduce<ConfigurationDocument[]>(
        (acc, { documents }) => [...acc, ...documents],
        [],
      ).length;
    })
    .reduce((acc, values) => {
      const key: DocumentSource = values[0] as DocumentSource;
      const entitiesList: GetConfigurationDocumentListOutput['options'] = values[1];

      acc[key as DocumentSource] = entitiesList
        .filter(({ documents }) => documents.length)
        .map(
          ({ name, code, documents, documentsRelationType, documentsRelationCode }) => {
            const documentItems = documents.map(record =>
              unifyDocumentRecord.swarmItem(
                record,
                documentsRelationType,
                documentsRelationCode,
                isStoredExternally,
              ),
            );

            return {
              name: name,
              code: code,
              documents: getDocumentsSorted(documentItems, {
                isNewestSort: shouldSortByNewestDocuments,
              }),
            };
          },
        );
      return acc;
    }, {});

  const configurationDocumentItems = configurationDocuments.map(record =>
    unifyDocumentRecord.listItem(record, isStoredExternally),
  );

  return {
    [DocumentSource.CONFIGURATION]: [
      {
        name: null,
        code: null,
        documents: getDocumentsSorted(configurationDocumentItems, {
          isNewestSort: shouldSortByNewestDocuments,
        }),
      },
    ],
    ...rest,
  };
};
