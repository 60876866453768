import styled from 'styled-components';

export const NoImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ScCaption = styled.span<{ $truncated?: boolean }>`
  ${({ $truncated = true }) =>
    $truncated
      ? `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`
      : ''}

  display: inline-block;
  font-size: 1.15em;
  font-weight: 500;
  color: #464646;
  text-align: left;
  text-transform: capitalize;
  line-height: inherit;
  margin-top: 0.75em;
`;

export const ScBadge = styled.div`
  width: max-content;
  text-transform: capitalize;
  border-radius: 4px;
  padding: 0.5em;
  background-color: ${({ theme }) => theme.colors.lightGray};
  font-weight: 500;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.black};
`;

export const ScOptionsList = styled.ul`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
  display: inline-block;
  list-style: disc;
  padding-left: 2rem;
`;

export const ScText = styled.div`
  font-weight: 400;
  font-size: 1em;
  color: #464646;
  margin-bottom: 0.5em;
`;
