import {
  ACCESSORIES_PAGE_URL,
  CLIENTS_URL,
  INFO_URL,
  MODELS_URL,
  OPTIONS_PAGE_URL,
  PACKAGES_PAGE_URL,
  PRICE_LIST_URL,
  SOFT_OFFER_URL,
  STORAGE_KEYS,
  TERMS_AND_CONDITIONS_PAGE_URL,
  TOTAL_URL,
  TRADE_IN_URL,
} from 'common/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';
import { useQuery } from 'context/router/UrlQueryProvider';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors, modelSelectors, streamingActions } from 'store';
import { streamingSelectors } from 'store/streaming';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { StreamingEventType, StreamingStatus } from './types';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './utils';
import createConnection from 'utils/create-connection';
import * as storage from 'utils/storage';

// @todo: make connection setup and teardown hooked up to streaming/enabled disabled state
// and dont create connection in such unconditional manner
const connectionInstance = createConnection();

type StreamingEventData =
  | {
      name: 'global';
      data: Partial<{
        userName: string | null;
        modelNumber: string | null;
        modelName: string | null;
        isPublished: boolean;
        languageCode: string;
        configurationNumber: number | null;
        carPreviewImageIndex: number | null;
      }>;
    }
  // @todo: remove any once all data typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any;

type SendMessageParams = {
  type: StreamingEventType;
  data?: StreamingEventData;
};

interface ContextValue {
  sendMessage(params: SendMessageParams): void;
  setup(): void;
  end(): void;
  isModalOpen: boolean;
  openModal(): void;
  closeModal(): void;
  getScreen(): string;
}

const StreamingContext = React.createContext<ContextValue | undefined>(undefined);

const StreamingProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // keep isReady to re-created on re-renders
  // in order to support dependency list updates
  // wherever isReady variable is used.
  // reactive approach with timeout does not work
  // for unknown reason
  // @todo: reveal the reason why isReady of instance does not work
  const isReady = connectionInstance.isReady();
  const pingRef = useRef<number | null>(null);
  const isInitialListTerminals = useRef<boolean>(true);
  const unhandledEvents = useRef<SendMessageParams[]>([]);

  const { queryValues } = useQuery();
  const { t } = useTranslation();

  const { isFeatureEnabled } = useFeature();
  const isForceStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowForceStreaming,
  });
  const { isConfigurationCreatedFromStock } = useSelector(modelSelectors.getAll);
  const configurator = useSelector(streamingSelectors.getConfigurator);
  const preferredTerminal = useSelector(streamingSelectors.getPreferredTerminal);
  const token = useSelector(authSelectors.getAuthToken);
  const username = useSelector(authSelectors.getUsername);
  const baseURL = useSelector(authSelectors.getBaseUrl);
  const stream = queryValues.stream;

  const host = baseURL?.split('.')[0].replace('https://', '');

  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getScreen = useCallback(() => {
    // avoid relying on location from react-router
    // as it provokes re-renders on login
    switch (window.location.pathname) {
      case `/steps/${PACKAGES_PAGE_URL}`:
      case `/steps/${OPTIONS_PAGE_URL}`:
      case `/steps/${ACCESSORIES_PAGE_URL}`:
      case `/steps/${TERMS_AND_CONDITIONS_PAGE_URL}`:
      case `${INFO_URL}`:
      case `${TRADE_IN_URL}`:
      case `${SOFT_OFFER_URL}`:
        return 'selection';
      case `${PRICE_LIST_URL}`:
        return 'priceList';
      case `${CLIENTS_URL}`:
        return 'standby';
      case `${TOTAL_URL}`:
        return 'summary';
      case `${MODELS_URL}`:
        return 'model';
      default:
        return 'standby';
    }
  }, []);

  const sendMessage = useCallback<ContextValue['sendMessage']>(
    params => {
      const { type, data } = params;

      const connection = connectionInstance.get();

      if (!connectionInstance.isReady()) {
        // catch first events that are sent
        // just after / at the same time
        // the connection is being configured
        unhandledEvents.current.push(params);
        return;
      }

      connection?.send(
        JSON.stringify({
          type,
          host,
          data,
        }),
      );
    },
    [host],
  );

  const handleMessage = useCallback(
    message => {
      let parsedMessage;

      try {
        parsedMessage = JSON.parse(message.data);
      } catch (err) {
        parsedMessage = {};
      }

      const { type, data, errors, connectionId } = parsedMessage;

      if (connectionId) {
        const currentConnectionId = storage.loadFromLocalStorage<string>(
          STORAGE_KEYS.connectionId,
        );

        if (currentConnectionId !== connectionId) {
          storage.saveInLocalStorage(STORAGE_KEYS.connectionId, connectionId);
        }
      }

      if (type === StreamingEventType.INITIALIZE) {
        dispatch(streamingActions.setConfigurator(data));

        sendMessage({
          type: StreamingEventType.LIST_ALL_TERMINALS,
          data: {
            host,
          },
        });

        sendMessage({
          type: StreamingEventType.GET_CURRENT_STATE,
        });
      }

      if (type === StreamingEventType.GET_CURRENT_STATE) {
        dispatch(streamingActions.setConfigurator(data));

        if (pingRef.current) {
          clearInterval(pingRef.current);
          pingRef.current = null;
        } else {
          dispatch(streamingActions.setIsStreamWaiting(false));
        }

        if (data.status === StreamingStatus.BUSY) {
          const pingInterval = setInterval(() => {
            sendMessage({
              type: StreamingEventType.PING,
            });
          }, 5000);

          pingRef.current = Number(pingInterval);
        }
      }

      if (type === StreamingEventType.LIST_ALL_TERMINALS) {
        dispatch(streamingActions.setAllTerminals(data));

        if (
          isInitialListTerminals.current &&
          isForceStreamingFeatureEnabled &&
          configurator?.status !== StreamingStatus.BUSY
        ) {
          sendMessage({
            type: StreamingEventType.START_STREAMING_FOR_TERMINAL,
            data: {
              customerId: stream || username,
              initialScreen: getScreen(),
            },
          });
        }

        isInitialListTerminals.current = false;
      }

      if (type === StreamingEventType.PING) {
        dispatch(streamingActions.setIsStreamWaiting(data?.isStreamBroken));

        if (data?.isStreamBroken && !data?.waiting && pingRef.current) {
          clearInterval(pingRef.current);
          pingRef.current = null;
          sendMessage({
            type: StreamingEventType.GET_CURRENT_STATE,
          });
        }
      }

      if (type === StreamingEventType.START_STREAMING_FOR_TERMINAL) {
        notification.openByStatus(errors.length ? Status.Error : Status.Success, {
          [Status.Success]: t('STREAMING_SESSION_STARTED'),
          [Status.Error]: getErrorMessage(errors[0], t('STREAMING_SESSION_FAILED')),
        });
      }

      if (type === StreamingEventType.EMIT_SLOT_CHANGE) {
        if (data?.name === 'selection') {
          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'global',
            },
          });
        }
      }
    },
    [
      configurator,
      dispatch,
      getScreen,
      host,
      isForceStreamingFeatureEnabled,
      sendMessage,
      stream,
      t,
      username,
    ],
  );

  const setup = useCallback(async () => {
    if (isReady) return;

    const connection = await connectionInstance.init({
      host,
      token,
      customerId: username ?? undefined,
    });

    dispatch(streamingActions.setIsStreamBroken(false));

    connection.onmessage = handleMessage.bind(connection);

    connection.onclose = event => {
      connectionInstance.close();

      if (event.code === 1000) {
        dispatch(streamingActions.setAllTerminals([]));
        dispatch(streamingActions.setIsStreamWaiting(false));
        dispatch(streamingActions.setConfigurator(null));
        return;
      }

      dispatch(streamingActions.setIsStreamBroken(true));
    };
  }, [isReady, host, token, username, dispatch, handleMessage]);

  const end = useCallback(() => {
    connectionInstance.close();
  }, []);

  useEffect(() => {
    if (isConfigurationCreatedFromStock) {
      end();
      dispatch(streamingActions.setIsEnabled(false));
    }
  }, [isConfigurationCreatedFromStock, end, dispatch]);

  useEffect(() => {
    if (!isReady) return;

    const events = unhandledEvents.current;

    events.forEach(event => {
      sendMessage(event);
    });

    unhandledEvents.current = [];
  }, [sendMessage, isReady, unhandledEvents.current.length]);

  useEffect(() => {
    if (configurator?.status === StreamingStatus.BUSY) return;

    if (preferredTerminal?.status !== StreamingStatus.VACANT) return;

    sendMessage({
      type: StreamingEventType.START_STREAMING_FOR_TERMINAL,
      data: {
        customerId: stream || username,
        initialScreen: getScreen(),
      },
    });
  }, [preferredTerminal, getScreen, username, sendMessage, stream, configurator]);

  const value = useMemo(
    () => ({
      sendMessage,
      setup,
      end,
      isModalOpen,
      openModal,
      closeModal,
      getScreen,
    }),
    [sendMessage, setup, end, isModalOpen, openModal, closeModal, getScreen],
  );

  return <StreamingContext.Provider value={value} {...props} />;
};

const useStreaming = (): ContextValue => {
  const context = useContext(StreamingContext);

  if (context === undefined) {
    throw new Error('useStreaming must be used within an StreamingProvider');
  }

  return context;
};

export { StreamingProvider, useStreaming };
