import styled from 'styled-components';
import { ReactComponent as ModalCloseIcon } from 'assets/icons/modal-close.svg';
import { ScImageGalleryWrapper } from 'components/styles';

export const ScModalCloseIcon = styled(ModalCloseIcon)`
  width: 42px;
  height: 42px;
  top: 30px;
  right: 30px;
  position: fixed;
  fill: ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease-out;

  &:hover {
    fill: ${({ theme }) => theme.mainColor};
  }
`;

export const ScModelImageGalleryWrapper = styled(ScImageGalleryWrapper)<{
  $width: string;
}>`
  .image-gallery-swipe {
    width: calc(100% - 240px);
    margin: 0 auto;
  }

  .image-gallery-image {
    width: ${({ $width }) => $width};
  }

  .image-gallery-thumbnails-container {
    display: flex;
    justify-content: center;
  }
`;
