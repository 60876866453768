import { FC } from 'react';

import { BannerSettings, SectionsDynamicTypes } from './types';

import Accordion from './Accordion/Accordion';
import ActionsList from './ActionsList/ActionsList';
import DealerAction from './DealerAction/DealerAction';
import GlobalDiscounts from './GlobalDiscounts/GlobalDiscounts';
import TotalExDealer from './TotalExDealer/TotalExDealer';
import SubTotalExManufacturer from './SubTotalExManufacturer/SubTotalExManufacturer';
import TotalExManufacturer from './TotalExManufacturer/TotalExManufacturer';
import AccessoriesSelected from './Accessories/AccessoriesSelected';

export const sectionsDynamicTypes__mapping__component: Record<
  SectionsDynamicTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FC<BannerSettings<any>>
> = {
  [SectionsDynamicTypes.accordion]: Accordion,
  [SectionsDynamicTypes.actionsList]: ActionsList,
  [SectionsDynamicTypes.dealerAction]: DealerAction,
  [SectionsDynamicTypes.globalDiscounts]: GlobalDiscounts,
  [SectionsDynamicTypes.totalExDealer]: TotalExDealer,
  [SectionsDynamicTypes.subTotalExManufacturer]: SubTotalExManufacturer,
  [SectionsDynamicTypes.totalExManufacturer]: TotalExManufacturer,
  [SectionsDynamicTypes.accessoriesSelected]: AccessoriesSelected,
};
