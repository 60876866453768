import styled from 'styled-components';
import Checkbox from 'components/form/Checkbox';
import { TotalTableRow } from '../TotalTableRow';
import { Price } from 'components/Price';
import { TextArea } from 'components/form/TextArea';

export const ScTotalTableRow = styled(TotalTableRow)`
  td {
    padding-top: 0;
    padding-bottom: 0;
  }
  td:nth-child(1) {
    font-weight: 400;
  }
`;

export const ScCheckbox = styled(Checkbox)`
  margin: 0 1.5em 0.5em 0;
`;

export const ScSelectColumnWrapper = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ScNameInput = styled(TextArea)`
  &&.ant-input {
    font-size: 1em;
    padding: 0.75em;
    width: calc(100% + 0.75em);
    font-weight: normal;
    position: absolute;
    top: -12px;
  }
`;

export const ScBruttoPrice = styled(Price)`
  display: inline-block;
`;
