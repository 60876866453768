import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs as TBreadcrumbs } from 'utils/types';
import { ScBreadcrumb, ScBreadcrumbItem } from './index.styles';

type Props = {
  breadcrumbs: TBreadcrumbs[];
};

const Breadcrumbs: FC<Props> = ({ breadcrumbs = [] }) => {
  return (
    <ScBreadcrumb separator=">">
      {breadcrumbs.map(({ id, title, path, disabled }) => (
        <ScBreadcrumbItem key={id}>
          {disabled ? title : <Link to={path}>{title}</Link>}
        </ScBreadcrumbItem>
      ))}
    </ScBreadcrumb>
  );
};

export default Breadcrumbs;
