import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerFields, CustomerItem, Filter } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { useCustomer } from 'context/customer/CustomerProvider';
import { useContact } from 'context/contact/ContactProvider';
import { Status } from 'utils/types';
import { getFilterTableTitlesBySearchColumns } from '../../customerForms/utils/get-filtered-table-titles-by-search-columns';
import { SearchModal } from '../../SearchModal/SearchModal';
import { useColumns } from './use-columns';

interface Props {
  onClose(): void;
  onSubmit(records: CustomerItem[]): void;
  initialValues?: CustomerItem[];
  /** Used just to update local `filters` state. */
  filters?: Filter<CustomerFields>;
  onAddNew?(): void;
}
export const SearchCustomerModal: FC<Props> = ({
  onClose,
  onSubmit,
  onAddNew,
  initialValues,
  filters: externalFilters,
}) => {
  const { t } = useTranslation();

  const { status: customerStatus, getCustomers, customers } = useCustomer();
  const { status: contactStatus } = useContact();

  const [filters, setFilters] = useState<Filter<CustomerFields>>([]);

  useEffect(() => {
    setFilters(externalFilters ?? []);
  }, [externalFilters]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      await getCustomers(filters);
    }, 100);
    return () => clearTimeout(timeout);
  }, [getCustomers, filters]);

  const isLoading = useMemo(() => {
    return [customerStatus, contactStatus].includes(Status.Loading);
  }, [customerStatus, contactStatus]);

  const handleReset = useCallback(
    async dataIndex => {
      const updatedFilters = filters.filter(filter => filter.name !== dataIndex);

      setFilters(updatedFilters);

      await getCustomers(updatedFilters);
    },
    [filters, getCustomers],
  );

  const columns = useColumns({
    handleReset,
    filters,
    setFilters,
  });

  const tableTitles = useMemo(() => {
    return getFilterTableTitlesBySearchColumns<CustomerFields>(columns, {
      [CustomerFields.vatNumber]: t('CLIENTS_VAT'),
    });
  }, [columns, t]);

  // @todo: antd@5
  return (
    <SearchModal
      testNamePrefix="customer"
      onClose={onClose}
      initialValue={initialValues}
      onAddNew={onAddNew}
      tableTitles={tableTitles}
      tableColumns={columns as any}
      tableData={customers}
      tableDataIdField={CustomerFields.id}
      filters={filters}
      onFilterClearAll={() => setFilters([])}
      onFilterClearOne={handleReset}
      isLoading={isLoading}
      onSubmit={records => {
        onSubmit(records as CustomerItem[]);
        onClose();
      }}
    />
  );
};
