import styled from 'styled-components';
import { Spin } from 'antd';
import { HostSettingsExternal } from 'common/hostsSettings';

export const ScLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 100%;
`;

// z-index: 1000; is on modals
export const ScSpin = styled(Spin)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 27vh;
`;

export const ScHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const ScContentWrapper = styled.div<{
  bg?: HostSettingsExternal['backgroundCommon'];
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1;
  width: 100%;
  ${({ bg }) => {
    if (bg) {
      return `
      background-image: ${bg.backgroundImage};
      ${bg.backgroundSize ? `background-size: ${bg.backgroundSize}` : ''};
      ${bg.backgroundRepeat ? `background-repeat: ${bg.backgroundRepeat}` : ''};
      `;
    }
    return '';
  }}
  padding-top: 0.875em;
`;
