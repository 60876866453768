import React, { FC, useCallback } from 'react';
import AuthLayout from 'layout/Auth/AuthLayout';
import HostSettingsForm from './HostSettingsForm';
import { HostSettingsFormValues } from './HostSettingsFormMeta';
import { FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { hostSettingsActions, hostSettingsSelectors, sharedSelectors } from 'store';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE, LOGIN_URL } from 'common/constants';

const HostSettingsPage: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const host = useSelector(hostSettingsSelectors.getHost);

  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);

  const onSubmit = useCallback(
    async (
      values: HostSettingsFormValues,
      actions: FormikHelpers<HostSettingsFormValues>,
    ) => {
      actions.setSubmitting(true);

      dispatch(hostSettingsActions.setHost(values.host));

      actions.setSubmitting(false);

      history.push({ pathname: LOGIN_URL, search: location.search });
    },
    [dispatch, history, location.search],
  );

  return (
    <AuthLayout
      isLoading={isWholePageLoading}
      background={{
        background: `url(${DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
      }}
    >
      <HostSettingsForm onSubmit={onSubmit} initialValues={{ host: host ?? '' }} />
    </AuthLayout>
  );
};

export default HostSettingsPage;
