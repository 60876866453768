import React, { FC } from 'react';
import loadable from '@loadable/component';
import AntIcon from '@ant-design/icons';

const DynamicIcon = loadable(
  ({ type }: { type: string }) => import(`@ant-design/icons/es/icons/${type}.js`),
  // Without caching always returns first result
  // https://loadable-components.com/docs/dynamic-import/
  { cacheKey: ({ type }) => type },
);

const Icon: FC<Props> = ({ type, ...rest }) => {
  if (!type) {
    return null;
  }

  return <DynamicIcon type={type} {...rest} />;
};

type Props = React.ComponentProps<typeof AntIcon>;

export default Icon;
