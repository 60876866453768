import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { ReactComponent as Swap } from 'assets/icons/swap.svg';
import { Empty, Col } from 'antd';
import { Button } from 'components/button/Button';

export const ScSwapList = styled.div`
  margin: 1em 1em 0 1em;
`;

export const ScSwapListItem = styled.div`
  padding: 1em;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.backgroundColor};
  }
  &[data-select='true'] {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;

export const ScRightCol = styled(Col)`
  text-align: right;
  margin-bottom: 0.5em;
`;

export const ScLabel = styled.span`
  font-size: 0.85em;
  font-weight: 500;
`;

const SwapIcon = props => <Icon component={Swap} {...props} />;

export const ScSwapIcon = styled(SwapIcon)``;

export const ScIconWrap = styled.div`
  padding: 1em;
  text-align: center;
  font-size: 1.2em;
`;

export const ScEmpty = styled(Empty)`
  & .ant-empty-image {
    height: 3.75em;
  }
  & .ant-empty-description {
    font-size: 1em;
  }
`;

export const ScTitle = styled.div`
  text-align: center;
  padding: 1.5em 1.5625em;
  border-bottom: 1px solid rgb(191, 191, 191);
`;

export const ScFooter = styled.div`
  display: flex;
  text-align: center;
  padding: 1.25em 1em;
`;

export const ScButton = styled(Button)`
  flex: 1;

  &:last-of-type {
    margin-left: 1em;
  }
`;
