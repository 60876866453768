import { ContactFields, ContactItem, CustomerItem, CustomerFields } from 'types/vendor';
import { Col, Row } from 'antd';
import { Button } from 'components/button';
import { Contact, useContact } from 'context/contact/ContactProvider';
import { useCountry } from 'context/country/CountryProvider';
import { useLanguage } from 'context/language/LanguageProvider';
import { FormikHelpers } from 'formik';
import { get } from 'utils';
import React, { FC, useCallback, useMemo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { UserForm, UserType } from '../customerForms/UserForm';
import { notification, NotificationType } from '../../utils/notification';
import { getContactFormSchema } from './contact-form-schema';

interface Props {
  /** Related customer is mandatory here as BE don't create contact record without related customer */
  relatedCustomer: CustomerItem;
  onSubmit?(values: ContactItem | null): void;
  onWarningClick?(name: ContactFields, value: string): void;
}
export const CreateContactForm: FC<PropsWithChildren<Props>> = ({
  children,
  onSubmit,
  relatedCustomer,
  onWarningClick,
}) => {
  const initialValues = Contact();
  const { countries } = useCountry();
  const { languages } = useLanguage();
  const { t } = useTranslation();
  const { saveNewContact, getContactsList } = useContact();

  const formSchema = useMemo(() => getContactFormSchema(t), [t]);

  const handleSubmit = useCallback(
    async (values: ContactItem, actions: FormikHelpers<ContactItem>) => {
      try {
        actions.setSubmitting(true);
        const contact = await saveNewContact({
          ...values,
          [ContactFields.relationId]: get(relatedCustomer, CustomerFields.id),
        });

        actions.setSubmitting(false);
        actions.resetForm();
        if (typeof onSubmit === 'function') {
          onSubmit(contact);
        }
        notification.open({
          message: t('CLIENTS_NEW_CONTACT_CREATED'),
          type: NotificationType.success,
        });
      } catch (e) {
        notification.requestError(e);
      }
    },
    [saveNewContact, relatedCustomer, onSubmit, t],
  );

  return (
    <UserForm
      selectionPlaceholder={t('SELECT_CUSTOMER')}
      selectionValue={get(relatedCustomer, CustomerFields.name, '')}
      selectionHeaderValue={t('CLIENT')}
      selectionReadOnly={true}
      onSubmit={handleSubmit}
      countries={countries}
      languages={languages}
      readonly={false}
      initialFormValues={initialValues}
      formSchema={formSchema}
      handleWarningIconClick={onWarningClick as any}
      showCommercialCustomerType={false}
      reloadEntities={getContactsList as any}
      userType={UserType.Contact}
      footer={
        <Row
          gutter={[20, 20]}
          data-testid="customer-page-new-contact-tab-actions-wrapper"
        >
          <Col span={24}>
            <Button
              variant="primary"
              className="active fullwidth"
              htmlType="submit"
              data-testid="customer-page-new-contact-tab-submit-btn"
            >
              {t('CLIENTS_SAVE_AND_CONTINUE')}
            </Button>
          </Col>
          <Col span={24}>{children}</Col>
        </Row>
      }
    />
  );
};
