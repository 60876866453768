import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { STORAGE_KEYS, URL_QUERY_PARAMS } from '../../common/constants';
import { UrlTransform } from '../../utils/urlTransform';
import { useQuery } from '../router/UrlQueryProvider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadFromLocalStorage, saveInLocalStorage } from '../../utils/storage';
import { AuthenticatedSessionProvider } from './AuthenticatedSessionProvider';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';

interface ContextValue {
  confirmConfigurationChange(): boolean;
  configurationNumberChanged: boolean;
}

const SessionContext = React.createContext<ContextValue | undefined>(undefined);

/** Context for session restore and some specific session actions
 * Wraps all the business providers and controls its render */
const AuthenticatedPrerenderSessionProvider: FC<PropsWithChildren> = props => {
  const [configurationNumberChanged, setConfigurationNumberChanged] =
    useState<boolean>(false);

  const history = useHistory();
  const { t } = useTranslation();
  const { queryValues, removeQueryParams } = useQuery();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const confirmConfigurationChange = useCallback((): boolean => {
    return window.confirm(t('DATA_MAYBE_LOST'));
  }, [t]);

  /** If opened existing configuration with dossier query - reset configuration,
   * keep only dossier related query values */
  useEffect(() => {
    const storedConfigurationNumber = loadFromLocalStorage<number>(
      STORAGE_KEYS.configurationNumber,
    );

    if (!(storedConfigurationNumber || configurationNumber) || !queryValues.presetDossier)
      return;

    saveInLocalStorage(STORAGE_KEYS.configurationNumber, undefined);
    setConfigurationNumberChanged(true);

    const newQuery = { [URL_QUERY_PARAMS.presetDossier]: queryValues.presetDossier };

    queryValues.presetRelations.forEach(
      ({ key, customerId }) => (newQuery[key] = customerId),
    );

    history.replace({
      search: UrlTransform.stringifyQuery(newQuery),
    });
  }, [
    configurationNumber,
    history,
    queryValues.presetDossier,
    queryValues.presetRelations,
    removeQueryParams,
  ]);

  const value = useMemo(
    () => ({
      confirmConfigurationChange,
      configurationNumberChanged,
    }),
    [confirmConfigurationChange, configurationNumberChanged],
  );

  return <SessionContext.Provider value={value} {...props} />;
};

const usePrerenderSession = (): ContextValue => {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error(
      `${usePrerenderSession.name} must be used within an ${AuthenticatedSessionProvider.name}`,
    );
  }

  return context;
};

export { AuthenticatedPrerenderSessionProvider, usePrerenderSession };
