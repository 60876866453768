import { FileImageOutlined } from '@ant-design/icons/lib';
import {
  PriceListFields,
  PriceListItemWithImage,
} from '@hypercharge/xdms-client/lib/types';
import { Button as AntButton } from 'antd';
import { MAX_DESC_LENGTH } from 'common/constants';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isFunction } from 'utils';
import {
  ScDescription,
  ScImage,
  ScImageContainer,
  ScPriceListItem,
  ScPriceListItemTextBody,
  ScTitle,
} from './styles';

export const ScShowMore = styled(AntButton)`
  padding: 0;
  display: block;
  background: none;
  font-weight: 600;

  &:hover {
    background: none;
  }
`;

export const ScFileImageOutlined = styled(FileImageOutlined)`
  font-size: 3em;
  color: #c4c4c4;
`;

export interface PriceListItemProps {
  onClick?(): void;
  record: PriceListItemWithImage;
}

export const PriceListItem: FC<PriceListItemProps> = ({ record, onClick }) => {
  const { t } = useTranslation();

  const [isShowMoreExpanded, setIsShowMoreExpanded] = useState<boolean>(false);

  const title = record[PriceListFields.catalogName];
  const description = record[PriceListFields.catalogDescription];
  const imageUrl = record[PriceListFields.imageUrl];

  const pickedDescription = useMemo<string>(() => {
    return description || title;
  }, [description, title]);

  const isDescriptionLong = useMemo<boolean>(
    () => pickedDescription.length > MAX_DESC_LENGTH,
    [pickedDescription],
  );

  const renderedDescription = useMemo<string>(() => {
    if (!isDescriptionLong) return pickedDescription;

    return isShowMoreExpanded
      ? pickedDescription
      : `${pickedDescription.substring(0, MAX_DESC_LENGTH)}... `;
  }, [isShowMoreExpanded, pickedDescription, isDescriptionLong]);

  const toggleShowMore = () => setIsShowMoreExpanded(!isShowMoreExpanded);

  const selectModel = _code => {
    if (isFunction(onClick)) onClick();
  };

  return (
    <ScPriceListItem data-testid={`price-list-page-item-container-${title}`}>
      <ScImageContainer
        data-testid={`price-list-page-item-image-wrapper-${title}`}
        onClick={selectModel}
      >
        {imageUrl ? (
          <ScImage
            src={imageUrl}
            alt={title}
            data-testid={`price-list-page-item-image-${title}`}
          />
        ) : (
          <ScFileImageOutlined data-testid="price-list-page-item-image-icon" />
        )}
      </ScImageContainer>
      <ScPriceListItemTextBody>
        <ScTitle
          data-testid={`price-list-page-item-title-${title}`}
          onClick={selectModel}
        >
          {title}
        </ScTitle>
        <ScDescription data-testid={`price-list-page-item-description-${title}`}>
          {renderedDescription}
          {isDescriptionLong && (
            <ScShowMore
              type="text"
              onClick={toggleShowMore}
              data-testid={`price-list-page-item-collapse-description-btn-${title}`}
            >
              {t(isShowMoreExpanded ? 'SHOW_LESS' : 'SHOW_MORE')}
            </ScShowMore>
          )}
        </ScDescription>
      </ScPriceListItemTextBody>
    </ScPriceListItem>
  );
};
