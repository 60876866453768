import {
  ModelPackageLineFields,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';

/**
 * Determines whether record is package line or not
 *
 * @param record {Model_CommonItem} - record
 * @returns {boolean}
 */
export const isPackageLine = (record: Model_CommonItem): boolean => {
  const packageId = record[Model_CommonItemFields.ID];
  const packageName = record[ModelPackageLineFields.package];
  return Boolean(packageName && packageName !== packageId);
};
