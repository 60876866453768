import React, { FC } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const DropDownStyled = styled(MdArrowDropDown)`
  font-size: 1.5em;
  height: 2em;
  color: ${props => props.theme.textColor};
`;

type SelectorSkeleton = {
  width?: number;
  height?: number;
};

const SelectorSkeleton: FC<SelectorSkeleton> = ({ height = 80, width = 55 }) => {
  return (
    <div className="cp-c-row" data-testid="skeleton-container">
      <div
        className="cp-c-column  cp-c-align-spacebetween-start"
        data-testid="skeleton-content-wrapper"
      >
        <Skeleton width={width} data-testid="skeleton-line" />
        <Skeleton width={height} data-testid="skeleton-line" />
      </div>
      <DropDownStyled data-testid="skeleton-drop-down-icon" />
    </div>
  );
};

export default SelectorSkeleton;
