import React, { FC, useEffect, useState } from 'react';
import { ScTotalTableRow } from './GlobalDiscountItem.styles';
import { TotalTableInput } from '../../components/TotalTableInput';
import { TFunction } from 'i18next';

interface Props {
  t: TFunction;
  tKey: string;
  initialValue: number;
  onChange(v: number): void;
  disabled?: {
    percent?: boolean;
  };
}

const GlobalDiscountItem: FC<Props> = ({ t, tKey, initialValue, onChange, disabled }) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setValue(Number(initialValue));
  }, [initialValue]);

  return (
    <ScTotalTableRow
      data-testid={`total-page-globalDiscount-${tKey}`}
      shadeNumbers
      shiftFirstColumn
      fields={[
        t(tKey),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        <TotalTableInput
          key={'1'}
          size="middle"
          max={100}
          step={1}
          value={value}
          testNamePrefix="percent"
          onBlur={event => onChange(event.target.value)}
          onChange={event => setValue(event.target.value)}
          inputPrefix="%"
          symbolsAfterDecimal={10}
          disabled={Boolean(disabled?.percent)}
        />,
        undefined,
      ]}
    />
  );
};

export default GlobalDiscountItem;
