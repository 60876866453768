import { configureStore, EnhancedStore, PreloadedState, Reducer } from '@reduxjs/toolkit';
import { IS_DEVELOPMENT_MODE } from '../common/constants';
import { ApplicationState } from './index';

import { featuresFlagsReducer } from './featureFlags';
import { hostSettingsReducer } from './hostSettings';
import { authReducer } from './auth';
import { sharedReducer } from './shared';
import { streamingReducer } from './streaming';
import { configurationReducer } from './configuration';
import { documentTypesReducer } from './documentTypes';
import { softOffersReducer } from './softOffers';
import { documentsReducer } from './documents';
import { sharedOptionsReducer } from './sharedOptions';
import { modelReducer } from './model';
import { contactReducer } from './contact';
import { prospectDossierReducer } from './prospectDossier';
import { cityReducer } from './city';
import { documentTemplatesReducer } from './documentTemplates';
import { structureReducer } from './structure';
import { financingReducer } from './financing';

export const reducers: Record<keyof ApplicationState, Reducer> = {
  featuresFlags: featuresFlagsReducer,
  hostSettings: hostSettingsReducer,
  auth: authReducer,
  shared: sharedReducer,
  streaming: streamingReducer,
  configuration: configurationReducer,
  documentTypes: documentTypesReducer,
  softOffers: softOffersReducer,
  documents: documentsReducer,
  sharedOptions: sharedOptionsReducer,
  model: modelReducer,
  contact: contactReducer,
  prospectDossier: prospectDossierReducer,
  city: cityReducer,
  documentTemplates: documentTemplatesReducer,
  structure: structureReducer,
  financing: financingReducer,
};

export function setupStore(
  preloadedState?: PreloadedState<ApplicationState>,
): EnhancedStore<ApplicationState> {
  return configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: false }),
    devTools: IS_DEVELOPMENT_MODE,
    preloadedState,
  });
}
