import { useCallback } from 'react';
import { notification } from 'utils/notification';
import { HostSettingsExternal } from 'common/hostsSettings';
import { RequestResponseDetails } from 'types/common';
import { Status } from 'utils/types';
import { DynamicParameters_GetList_Output } from '@hypercharge/xdms-client/src/dynamicParameters/types';
import { assembleHostSettingsKey } from 'common/assemblers';
import { getHostSettings as xdmsGetHostSettings } from '@hypercharge/xdms-client';
import { DEFAULT_HOST_SETTINGS_EXTERNAL } from 'common/hostsSettings';

interface Value {
  getHostSettings(
    tenantBaseUrl: string,
    clientId: string,
  ): Promise<RequestResponseDetails<HostSettingsExternal>>;
}

const useHostSettings = (): Value => {
  const getHostSettings = useCallback<Value['getHostSettings']>(
    async (tenantBaseUrl, customerId) => {
      let result: RequestResponseDetails<HostSettingsExternal>;

      try {
        const response: DynamicParameters_GetList_Output = await xdmsGetHostSettings({
          tenantBaseUrl: tenantBaseUrl,
          key: assembleHostSettingsKey(customerId),
        });

        result = {
          response: response.response as HostSettingsExternal,
          status: Status.Success,
        };
      } catch (e) {
        notification.requestError(e);
        result = {
          messageHandled: true,
          status: Status.Error,
        };
      }

      if (!result.response || !Object.keys(result).length) {
        result.response = DEFAULT_HOST_SETTINGS_EXTERNAL;
      }

      return result;
    },
    [],
  );

  return {
    getHostSettings,
  };
};

export { useHostSettings };
