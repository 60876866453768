import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';

const NAME = 'document';

interface State {
  readonly documentsListStatus: Status;
  readonly documentDetailsStatus: Status;
  readonly documentForAllEntitiesStatus: Status;
  readonly documentDeletionStatus: Status;
  readonly documentUploadStatus: Status;
  readonly documentUpdateStatus: Status;
}

const initialState: State = {
  documentsListStatus: Status.Idle,
  documentDetailsStatus: Status.Idle,
  documentForAllEntitiesStatus: Status.Idle,
  documentDeletionStatus: Status.Idle,
  documentUploadStatus: Status.Idle,
  documentUpdateStatus: Status.Idle,
};

const actions = {
  setListStatus: createAction<State['documentsListStatus']>(
    getActionName(NAME, 'SET_LIST_STATUS'),
  ),
  setDetailsStatus: createAction<State['documentDetailsStatus']>(
    getActionName(NAME, 'SET_DETAILS_STATUS'),
  ),
  setForAllEntitiesStatus: createAction<State['documentForAllEntitiesStatus']>(
    getActionName(NAME, 'SET_FOR_ALL_ENTITIES_STATUS'),
  ),
  setDeletionStatus: createAction<State['documentDeletionStatus']>(
    getActionName(NAME, 'SET_DELETION_STATUS'),
  ),
  setUploadStatus: createAction<State['documentUploadStatus']>(
    getActionName(NAME, 'SET_UPLOAD_STATUS'),
  ),
  setUpdateStatus: createAction<State['documentUpdateStatus']>(
    getActionName(NAME, 'SET_UPDATE_STATUS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setListStatus, (state, action) => ({
    ...state,
    documentsListStatus: action.payload,
  }));
  builder.addCase(actions.setDetailsStatus, (state, action) => ({
    ...state,
    documentDetailsStatus: action.payload,
  }));
  builder.addCase(actions.setForAllEntitiesStatus, (state, action) => ({
    ...state,
    documentForAllEntitiesStatus: action.payload,
  }));
  builder.addCase(actions.setDeletionStatus, (state, action) => ({
    ...state,
    documentDeletionStatus: action.payload,
  }));
  builder.addCase(actions.setUploadStatus, (state, action) => ({
    ...state,
    documentUploadStatus: action.payload,
  }));
  builder.addCase(actions.setUpdateStatus, (state, action) => ({
    ...state,
    documentUpdateStatus: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ documents }: ApplicationState) => documents,
    (state: State) => state,
  ),
};

export type { State as DocumentsState };
export {
  actions as documentsActions,
  reducer as documentsReducer,
  selectors as documentsSelectors,
};
