import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  Configuration_GetDetailsById_Output as ConfigurationAllDetails,
  Configuration_GetDetailsById_Output_Configuration as Configuration,
  Configuration_GetDetailsById_Output_Relation as ConfigurationRelation,
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationFields,
} from '@hypercharge/xdms-client/lib/types';

const NAME = 'configuration';

/** if particular state value is undefined - didn't set up yet, null - just empty. */
interface State {
  readonly status: Status;
  readonly configurationsListStatus: Status;

  /** Raw response from request.
   * Added to save some backward compatibility with existing codebase and reduce amount of changes PR.
   * In most cases {@link configuration} may be used instead. */
  readonly configurationDetails?: ConfigurationAllDetails | null | undefined;
  readonly configuration?: Configuration | null | undefined;
  readonly configurationRelations?: ConfigurationRelation[] | null | undefined;

  /** AKA configurationId */
  readonly configurationNumber?: number | null | undefined;
  readonly modelNumber?: string | null | undefined;
  /** AKA modelVersion */
  readonly modelVersionNumber?: string | null | undefined;
  /** AKA modelId */
  readonly modelCatalogCode?: string | null | undefined;

  readonly configurationModelName?: string | null | undefined;
  readonly configurationStatusCode?: string | null | undefined;
  readonly configurationProspectDossierId?: number | null | undefined;
}

const initialState: State = {
  status: Status.Idle,
  configurationsListStatus: Status.Idle,
};

const actions = {
  setStatus: createAction<State['status']>(getActionName(NAME, 'SET_STATUS')),
  setConfigurationsListStatus: createAction<State['configurationsListStatus']>(
    getActionName(NAME, 'SET_CONFIGURATIONS_LIST_STATUS'),
  ),
  setConfigurationDetails: createAction<State['configurationDetails']>(
    getActionName(NAME, 'SET_CONFIGURATION_DETAILS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setStatus, (state, action) => ({
    ...state,
    status: action.payload,
  }));
  builder.addCase(actions.setConfigurationsListStatus, (state, action) => ({
    ...state,
    configurationsListStatus: action.payload,
  }));
  builder.addCase(actions.setConfigurationDetails, (state, action) => {
    const configuration = action.payload?.['configuration'];

    return {
      ...state,
      configurationDetails: action.payload,
      configuration: configuration,
      configurationRelations: action.payload?.['relations'],

      configurationNumber: configuration?.[ConfigurationFields.id],
      modelNumber: configuration?.[ConfigurationFields.modelNumber],
      modelVersionNumber: configuration?.[ConfigurationFields.versionNumber],
      modelCatalogCode: configuration?.[ConfigurationFields.catalogCode],

      configurationModelName: configuration?.[ConfigurationFields.modelName],
      configurationStatusCode: configuration?.[ConfigurationFields.statusCode],
      configurationProspectDossierId: configuration?.[ConfigurationFields.projectId],
    };
  });
});

const selectors = {
  getAll: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state,
  ),
  getStatus: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state.status,
  ),
  getConfigurationsListStatus: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state.configurationsListStatus,
  ),
  getConfigurationAllDetails: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state.configurationDetails,
  ),
  getConfiguration: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state.configuration,
  ),
  getConfigurationCommonVariables: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => ({
      configurationNumber: state.configurationNumber,
      configurationProspectDossierId: state.configurationProspectDossierId,
      configurationModelName: state.configurationModelName,
      modelNumber: state.modelNumber,
      modelVersionNumber: state.modelVersionNumber,
      modelCatalogCode: state.modelCatalogCode,
    }),
  ),
  getConfigurationRelations: createSelector(
    ({ configuration }: ApplicationState) => configuration,
    state => state.configurationRelations,
  ),
};

export type { State as ConfigurationState };
export {
  actions as configurationActions,
  reducer as configurationReducer,
  selectors as configurationSelectors,
};
