import {
  DatePickerProps as VendorDatePickerProps,
  RangePickerProps as VendorRangePickerProps,
} from 'antd/lib/date-picker';
import React, { FC, ReactNode, useMemo, useCallback, ChangeEvent } from 'react';
import moment, { Moment } from 'moment';
import {
  ScRangePickerWrapper,
  ScDatePicker,
  DatePickerContainer,
  RangePickerContainer,
} from './DatePicker.styles';
import { useTranslation } from 'react-i18next';

interface DatePickerProps
  extends Omit<VendorDatePickerProps, 'value' | 'onChange' | 'format'> {
  value?: string | Date | Moment;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  format: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  format,
  value,
  onChange,
  name,
  ...props
}) => {
  const { t } = useTranslation();
  const localValue = useMemo<Moment | undefined>(() => {
    if (!value || !format) return;
    return moment(value, format);
  }, [format, value]);

  const localOnChange = useCallback(
    (momentValue: Moment | null) => {
      const value: string = momentValue ? momentValue.format(format) : '';

      const event: ChangeEvent<HTMLInputElement> = {
        target: {
          value: value,
          name: name ?? '',
        },
      } as ChangeEvent<HTMLInputElement>;

      onChange?.(event);
    },
    [format, name, onChange],
  );

  return (
    <ScDatePicker
      name={name}
      value={localValue}
      onChange={localOnChange}
      format={format}
      panelRender={panelNode => (
        <DatePickerContainer>{panelNode as ReactNode}</DatePickerContainer>
      )}
      placeholder={t('DATE_PICKER_PLACEHOLDER')}
      {...props}
      autoComplete="off"
    />
  );
};

export const ScRangePicker: FC<VendorRangePickerProps> = props => {
  return (
    <ScRangePickerWrapper
      panelRender={panelNode => (
        <RangePickerContainer>{panelNode as ReactNode}</RangePickerContainer>
      )}
      {...props}
      autoComplete="off"
    />
  );
};
