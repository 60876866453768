import { FILTER_SIGNS } from 'common/constants';
import Selector from 'components/form/Selector';
import { useMachineApi } from 'context/machine/useMachineApi';
import React, { useMemo, useState } from 'react';
import { Filter, StockMachineBrandFields } from 'types/vendor';
import useGetOptions from './useGetOptions';

type Props = Omit<React.ComponentProps<typeof Selector>, 'options'>;

const BrandSelector: React.FC<Props> = ({ onChange, ...props }) => {
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { getBrandOptions } = useMachineApi();

  const filters = useMemo<Filter<StockMachineBrandFields>>(() => {
    const result: Filter<StockMachineBrandFields> = [];

    if (searchValue) {
      result.push({
        name: StockMachineBrandFields.name,
        sign: FILTER_SIGNS.INCLUDES,
        value: searchValue,
      });
    }

    return result;
  }, [searchValue]);

  const { options, isLoading } = useGetOptions(getBrandOptions, filters);

  return (
    <Selector
      {...props}
      onChange={(event, value) => {
        onChange?.(event, value);
        setSearchValue(null);
      }}
      options={options}
      loading={isLoading}
      onSearch={setSearchValue}
      showSearch
    />
  );
};

export default BrandSelector;
