import {
  Model,
  Model_CommonItem,
  Model_CommonItemFields,
  ModelFields,
} from '@hypercharge/xdms-client/lib/types';

/** @todo prob move to xdms-client */
const modelFieldsToSearch: ModelFields[] = [
  ModelFields.options,
  ModelFields.accessories,
  ModelFields.termsAndConditions,
  ModelFields.packages,
  ModelFields.packageLines,
];

export const getModelCommonItemById = (
  model: Model,
  itemId: string,
): Model_CommonItem | undefined => {
  return modelFieldsToSearch
    .map(field => model[field])
    .filter((item): item is Model_CommonItem[] => Boolean(item))
    .reduce((acc, item) => {
      acc.push(...item);
      return acc;
    }, [])
    .find(item => item[Model_CommonItemFields.ID] === itemId);
};
