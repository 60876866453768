import React, { FC } from 'react';
import {
  ScNoImageIconWrapper,
  ScNoImageText,
  ScNoImageWrapper,
  SсNoImageContainer,
  ScNoImageIcon,
} from './NoImage.styles';

type Props = {
  text: string;
  height?: number;
  onlyImage?: boolean;
  ratio?: string;
  transparent?: boolean;
};

export const NoImage: FC<Props> = ({
  text,
  height,
  onlyImage = false,
  ratio,
  transparent = false,
}) => {
  return (
    <ScNoImageWrapper $height={height} $ratio={ratio} $transparent={transparent}>
      <SсNoImageContainer>
        <ScNoImageIconWrapper>
          <ScNoImageIcon />
        </ScNoImageIconWrapper>
        {!onlyImage && <ScNoImageText>{text}</ScNoImageText>}
      </SсNoImageContainer>
    </ScNoImageWrapper>
  );
};
