import React, { FC, PropsWithChildren } from 'react';
import Loading from './Loading';
import { ScLoader } from './ConditionalLoader.styles';

interface Props {
  preventRender?: boolean;
  isLoading: boolean;
}

const ConditionalLoader: FC<PropsWithChildren<Props>> = ({
  isLoading,
  preventRender,
  children,
}) => {
  return (
    <>
      {isLoading && (
        <ScLoader>
          <Loading />
        </ScLoader>
      )}
      {(!isLoading || !preventRender) && children}
    </>
  );
};

export default ConditionalLoader;
