import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  SoftOffer_GetDetailsByRowId_Output,
  SoftOfferDetails,
  SoftOfferListItem,
} from '@hypercharge/xdms-client/lib/types';

const NAME = 'softOffers';

/** if particular state value is undefined - didn't set up yet, null - just empty. */
interface State {
  readonly softOffersListStatus: Status;
  readonly softOffersList: SoftOfferListItem[] | null | undefined;
  readonly softOfferDetailsStatus: Status;
  readonly softOfferDetails: SoftOfferDetails | null | undefined;
  readonly softOfferOptions:
    | SoftOffer_GetDetailsByRowId_Output['options']
    | null
    | undefined;
  readonly softOfferDeleteStatus: Status;
  readonly softOfferTarificationUpdateStatus: Status;
  readonly softOfferTarificationCalculateStatus: Status;
  readonly softOfferTarificationPublishStatus: Status;
}

const initialState: State = {
  softOffersListStatus: Status.Idle,
  softOffersList: undefined,
  softOfferDetailsStatus: Status.Idle,
  softOfferDetails: undefined,
  softOfferOptions: undefined,
  softOfferDeleteStatus: Status.Idle,
  softOfferTarificationUpdateStatus: Status.Idle,
  softOfferTarificationCalculateStatus: Status.Idle,
  softOfferTarificationPublishStatus: Status.Idle,
};

const actions = {
  setSoftOffersListStatus: createAction<State['softOffersListStatus']>(
    getActionName(NAME, 'SET_LIST_STATUS'),
  ),
  setSoftOffersList: createAction<State['softOffersList']>(
    getActionName(NAME, 'SET_LIST'),
  ),
  setSoftOfferDetailsStatus: createAction<State['softOfferDetailsStatus']>(
    getActionName(NAME, 'SET_DETAILS_STATUS'),
  ),
  setSoftOfferDetails: createAction<State['softOfferDetails']>(
    getActionName(NAME, 'SET_DETAILS'),
  ),
  setOptions: createAction<State['softOfferOptions']>(getActionName(NAME, 'SET_OPTIONS')),
  setSoftOfferDeleteStatus: createAction<State['softOfferDeleteStatus']>(
    getActionName(NAME, 'SET_DELETE_STATUS'),
  ),
  setTarificationUpdateStatus: createAction<State['softOfferTarificationUpdateStatus']>(
    getActionName(NAME, 'SET_TAR_UPDATE_STATUS'),
  ),
  setTarificationCalculateStatus: createAction<
    State['softOfferTarificationCalculateStatus']
  >(getActionName(NAME, 'SET_TAR_CALCULATE_STATUS')),
  setTarificationPublishStatus: createAction<State['softOfferTarificationPublishStatus']>(
    getActionName(NAME, 'SET_TAR_PUBLISH_STATUS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setSoftOffersListStatus, (state, action) => ({
    ...state,
    softOffersListStatus: action.payload,
  }));
  builder.addCase(actions.setSoftOffersList, (state, action) => ({
    ...state,
    softOffersList: action.payload,
  }));
  builder.addCase(actions.setSoftOfferDetailsStatus, (state, action) => ({
    ...state,
    softOfferDetailsStatus: action.payload,
  }));
  builder.addCase(actions.setSoftOfferDetails, (state, action) => ({
    ...state,
    softOfferDetails: action.payload,
  }));
  builder.addCase(actions.setOptions, (state, action) => ({
    ...state,
    softOfferOptions: action.payload,
  }));
  builder.addCase(actions.setSoftOfferDeleteStatus, (state, action) => ({
    ...state,
    softOfferDeleteStatus: action.payload,
  }));
  builder.addCase(actions.setTarificationUpdateStatus, (state, action) => ({
    ...state,
    softOfferTarificationUpdateStatus: action.payload,
  }));
  builder.addCase(actions.setTarificationCalculateStatus, (state, action) => ({
    ...state,
    softOfferTarificationCalculateStatus: action.payload,
  }));
  builder.addCase(actions.setTarificationPublishStatus, (state, action) => ({
    ...state,
    softOfferTarificationPublishStatus: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state,
  ),
  getListStatus: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOffersListStatus,
  ),
  getList: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOffersList,
  ),
  getDetailsStatus: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOfferDetailsStatus,
  ),
  getDetails: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOfferDetails,
  ),
  getOptions: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOfferOptions,
  ),
  getDeleteStatus: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => state.softOfferDetailsStatus,
  ),
  getTarificationStatuses: createSelector(
    ({ softOffers }: ApplicationState) => softOffers,
    (state: State) => ({
      softOfferTarificationPublishStatus: state.softOfferTarificationPublishStatus,
      softOfferTarificationUpdateStatus: state.softOfferTarificationUpdateStatus,
      softOfferTarificationCalculateStatus: state.softOfferTarificationCalculateStatus,
    }),
  ),
};

export type { State as SoftOffersState };
export {
  actions as softOffersActions,
  reducer as softOffersReducer,
  selectors as softOffersSelectors,
};
