export enum Status {
  Idle,
  Loading,
  Error,
  Success,
}

export type Breadcrumbs = {
  id: string;
  title: string;
  disabled: boolean;
  path: string;
};
