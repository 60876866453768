import {
  Model,
  ModelFields,
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonItem_Type_Values,
} from '@hypercharge/xdms-client/lib/types';
import { ColumnsType } from 'antd/lib/table';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useTranslation } from 'react-i18next';
import { ExcludesFalse } from 'types/common';
import { formatPrice } from 'utils/format';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';
import { commonItemType__mapping__translationKey } from './RulesModal.meta';
import { useSelector } from 'react-redux';
import { sharedSelectors } from 'store';

export type ObligatoryOneStateItem = {
  key: string;
  item: Model_CommonItem;
  selected: boolean;
  disabled: boolean;
  relatedPackage?: Model_CommonItem;
};

export type ObligatoryOneState = ObligatoryOneStateItem[];

export enum ObligatoryOneActionType {
  init,
  select,
}

export type ObligatoryOneAction =
  | {
      type: ObligatoryOneActionType.init;
      model: Model;
      modelItemsIdsList: string[];
    }
  | {
      type: ObligatoryOneActionType.select;
      itemId: string;
    };

export const obligatoryOneReducer = (
  state: ObligatoryOneState,
  action: ObligatoryOneAction,
): ObligatoryOneState => {
  switch (action.type) {
    /**
     * PackageLines:
     * if corresponding package selected - should make all
     * selection disabled, packageLine selected and let user proceed;
     * if package is not selected - should make that exact packageLine disabled
     * and show tooltip saying that user should select corresponding package to
     * use this packageLine
     * */
    case ObligatoryOneActionType.init: {
      const itemsList = action.modelItemsIdsList
        .map(id => getModelCommonItemById(action.model, id))
        .filter(Boolean as unknown as ExcludesFalse);

      let makeAllOptionsDisabled = false;

      let result: ObligatoryOneState = itemsList.map(item => {
        const result: ObligatoryOneState[0] = {
          key: item[Model_CommonItemFields.ID],
          item: item,
          selected: item[Model_CommonItemFields.selected],
          disabled: false,
        };

        if (item[Model_CommonItemFields.selected]) {
          makeAllOptionsDisabled = true;
        }

        if (
          item[Model_CommonItemFields.type] === Model_CommonItem_Type_Values.packageLine
        ) {
          const package_ = action.model[ModelFields.packages]?.find(
            record =>
              record[Model_CommonItemFields.ID] === item[Model_CommonItemFields.package],
          );

          result.relatedPackage = package_;
          result.disabled = true;

          if (package_?.[Model_CommonItemFields.selected]) {
            makeAllOptionsDisabled = true;
            result.selected = true;
          }
        }

        return result;
      });

      if (makeAllOptionsDisabled)
        result = result.map(item => ({ ...item, disabled: true }));

      return result;
    }
    case ObligatoryOneActionType.select: {
      return state.map(record => ({
        ...record,
        selected:
          record.item[Model_CommonItemFields.selected] ||
          record.item[Model_CommonItemFields.ID] === action.itemId,
      }));
    }
    default:
      return state;
  }
};

export const useObligatoryOneColumns = (): ColumnsType<ObligatoryOneStateItem> => {
  const { t, i18n } = useTranslation();
  const { currencyCode } = useCurrency();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  return [
    {
      title: t('TABLE_ITEM_ID'),
      width: '9em',
      dataIndex: ['item', Model_CommonItemFields.ID],
    },
    {
      title: t('DESCRIPTION'),
      width: '24em',
      dataIndex: ['item', Model_CommonItemFields.name],
      className: 'cell__description',
    },
    {
      title: t('ENTITY'),
      width: '8em',
      render: (value, record) => {
        return t(
          commonItemType__mapping__translationKey[
            record.item[Model_CommonItemFields.type]
          ],
          { value: record.relatedPackage?.[Model_CommonItemFields.ID] },
        );
      },
    },
    {
      title: t('MODAL_RULES__COLUMN_TITLE_GROSSPRICE'),
      render: (value, record) => {
        const field = shouldShowPricesWithVAT
          ? Model_CommonItemFields.grossPriceVat
          : Model_CommonItemFields.grossPrice;

        return formatPrice({
          price: record.item[field],
          currency: currencyCode,
          locale: i18n.language,
        });
      },
    },
  ];
};
