import { CloseOutlined } from '@ant-design/icons/lib';
import {
  ContactFields,
  ContactItem,
  ConfigurationRelation,
  ConfigurationRelationFields,
  Filter,
  CustomerFields,
} from 'types/vendor';
import { Tooltip } from 'antd';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { cloneDeep, get, isEqual } from 'utils';
import { InfoPageFormFields } from './meta';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectContactModal } from '../../components/contact/SelectContactModal';
import { useContact } from 'context/contact/ContactProvider';
import { useRelations } from 'context/relations/RelationsProvider';
import { useInfoForm } from './InfoFormProvider';
import { FILTER_SIGNS } from 'common/constants';

interface Props {
  allowedRelationCodes: string[];
  disabled?: boolean;
}

const ContactRolesForm: FC<Props> = ({ allowedRelationCodes, disabled = false }) => {
  const { t, formProps } = useInfoForm();

  const { values, setFieldValue } = formProps;

  const { loadContactsList } = useContact();
  const { relations } = useRelations();

  const [showContactSearchModal, setShowContactSearchModal] = useState<boolean>(false);

  const [activeContactRoleType, setActiveContactRoleType] = useState<
    string | undefined
  >();

  const contactRoles = useMemo<ConfigurationRelation[]>(
    () =>
      values.contactRoles.filter(contact => {
        return allowedRelationCodes.includes(
          contact[ConfigurationRelationFields.relationCode],
        );
      }),
    [values.contactRoles, allowedRelationCodes],
  );

  const defaultFilter = useMemo<Filter<ContactFields>>(() => {
    const customerId = relations[0]?.customer?.[CustomerFields.id];

    if (!customerId) return [];

    return [
      {
        name: ContactFields.relationId,
        value: customerId,
        sign: FILTER_SIGNS.EQUAL,
      },
    ];
  }, [relations]);

  /** Just to have initial values for search modal */
  const [selectedContactsByType, setSelectedContactsByType] = useState<
    Record<string, ContactItem | undefined | null>
  >({});

  /** Just to keep {@link selectedContactsByType} up to date */
  useEffect(() => {
    (async () => {
      const clonedSelectedContactsByType = cloneDeep(selectedContactsByType);

      for await (const contactRole of contactRoles) {
        const contactId = contactRole[ConfigurationRelationFields.contactId];
        const roleType = contactRole[ConfigurationRelationFields.relationCode];

        const oldContactId = selectedContactsByType[roleType]?.[ContactFields.ID];

        if (oldContactId === contactId) break;

        if (!contactId) break;

        const contacts = await loadContactsList([
          { name: ContactFields.ID, value: contactId },
        ]);

        const contact = contacts?.[0];

        if (!contact) break;

        clonedSelectedContactsByType[roleType] = contact as unknown as ContactItem;
      }

      if (!isEqual(selectedContactsByType, clonedSelectedContactsByType)) {
        setSelectedContactsByType(clonedSelectedContactsByType);
      }
    })();
  }, [contactRoles, loadContactsList, selectedContactsByType]);

  const openContactSearchModal = useCallback((role: string) => {
    setShowContactSearchModal(true);
    setActiveContactRoleType(role);
  }, []);

  const handleSelectContact = useCallback(
    (contacts: ContactItem[]) => {
      const contact = contacts[0];

      if (!contact) return;

      const clonedContactRoles = cloneDeep(contactRoles);

      const activeContactRole = clonedContactRoles.find(
        contact =>
          contact[ConfigurationRelationFields.relationCode] === activeContactRoleType,
      );

      if (activeContactRole) {
        activeContactRole[ConfigurationRelationFields.contactId] = get(
          contact,
          ContactFields.ID,
          0,
        );
        activeContactRole[ConfigurationRelationFields.name] = get(
          contact,
          ContactFields.name,
          '',
        );
        activeContactRole[ConfigurationRelationFields.description] = get(
          contact,
          ContactFields.note,
          '',
        );
      }

      setFieldValue(InfoPageFormFields.contactRoles, clonedContactRoles);
    },
    [activeContactRoleType, contactRoles, setFieldValue],
  );

  const handleDeselectContact = useCallback(
    (activeContactRoleType: string) => {
      const clonedContactRoles = cloneDeep(contactRoles);

      const activeContactRole = clonedContactRoles.find(
        contact =>
          contact[ConfigurationRelationFields.relationCode] === activeContactRoleType,
      );

      if (activeContactRole) {
        activeContactRole[ConfigurationRelationFields.name] = '';
        activeContactRole[ConfigurationRelationFields.contactId] = 0;
      }

      setFieldValue(InfoPageFormFields.contactRoles, clonedContactRoles);
    },
    [contactRoles, setFieldValue],
  );

  const getInputFieldSuffix = useCallback(
    (contact: ConfigurationRelation | null, testNamePrefix: string) => {
      if (!get(contact, ConfigurationRelationFields.name, false)) return null;

      return (
        <Tooltip
          placement="top"
          title={t('CLIENT_DESELECT_ITEM')}
          data-testid={`info-page-${testNamePrefix}-contact-deselect-tooltip`}
        >
          <CloseOutlined
            onClick={() => handleDeselectContact(testNamePrefix)}
            data-testid={`info-page-${testNamePrefix}-contact-deselect-icon-button`}
          />
        </Tooltip>
      );
    },
    [handleDeselectContact, t],
  );

  const activeModalInitialValue = useMemo<ContactItem[]>(() => {
    if (!activeContactRoleType) return [];

    const contact = selectedContactsByType[activeContactRoleType];

    if (!contact) return [];

    return [contact];
  }, [activeContactRoleType, selectedContactsByType]);

  if (!contactRoles.length) return null;

  return (
    <>
      {showContactSearchModal && (
        <SelectContactModal
          defaultFilter={defaultFilter}
          relatedCustomer={relations[0]?.customer}
          initialValues={activeModalInitialValue}
          onSelect={handleSelectContact}
          isOpen={true}
          setIsOpen={setShowContactSearchModal}
          isAddEnabled
        />
      )}

      {contactRoles.map((contact: ConfigurationRelation) => (
        <FormField
          key={`${contact[ConfigurationRelationFields.relationCode]}-${
            contact[ConfigurationRelationFields.relationType]
          }`}
          id={contact[ConfigurationRelationFields.relationType]}
          name={InfoPageFormFields.contactRoles}
          component={Input}
          value={get(contact, ConfigurationRelationFields.name, '')}
          label={get(contact, ConfigurationRelationFields.relationType, '')}
          placeholder={t('SELECT_CONTACT')}
          data-testid={`info-page-contact-roles-${
            contact[ConfigurationRelationFields.relationCode]
          }-field`}
          onClick={() =>
            openContactSearchModal(
              get(contact, ConfigurationRelationFields.relationCode, ''),
            )
          }
          suffix={getInputFieldSuffix(
            contact,
            get(contact, ConfigurationRelationFields.relationCode, ''),
          )}
          autoComplete="off"
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default ContactRolesForm;
