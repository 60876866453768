import { useXdmsClient } from 'context/xdms/XdmsClient';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { documentTemplatesActions } from 'store';
import { RequestResponseDetails } from 'types';
import { DocumentTemplate, GetDocumentTemplatesArguments } from 'types/vendor';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';

type DocumentTemplatesApi = {
  getPDFTemplates(
    params: GetDocumentTemplatesArguments,
  ): Promise<RequestResponseDetails<DocumentTemplate[]>>;
};

export const useDocumentTemplateApi = (): DocumentTemplatesApi => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const getPDFTemplates = useCallback<DocumentTemplatesApi['getPDFTemplates']>(
    async params => {
      let result: RequestResponseDetails<DocumentTemplate[]>;

      dispatch(documentTemplatesActions.setDocumentTemplatesStatus(Status.Loading));
      try {
        const pdfTemplates = await xdmsClient.documentTemplate.getPDFTemplatesList(
          params,
        );
        result = {
          messageHandled: false,
          status: Status.Success,
          response: pdfTemplates,
        };

        dispatch(documentTemplatesActions.setDocumentTemplates(pdfTemplates));
        dispatch(documentTemplatesActions.setDocumentTemplatesStatus(Status.Success));
      } catch (e) {
        result = { messageHandled: true, status: Status.Error };
        notification.requestError(e);
        dispatch(documentTemplatesActions.setDocumentTemplatesStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.documentTemplate],
  );

  return { getPDFTemplates };
};
