import styled from 'styled-components';

export const ScButtonsContainer = styled.div`
  padding: 2em;
`;

export const ScBody = styled.div`
  padding: 2.5em 2em 2em;
`;

export const ScContainer = styled.div`
  position: relative;
`;

export const ScFloatingContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
`;
