import { FilterItem } from './_common/filter';

export enum SectionsDynamicTypes {
  accordion = 'accordion',
  actionsList = 'actionsList',
  dealerAction = 'dealerAction',
  globalDiscounts = 'globalDiscounts',
  totalExDealer = 'totalExDealer',
  subTotalExManufacturer = 'subTotalExManufacturer',
  totalExManufacturer = 'totalExManufacturer',

  accessoriesSelected = 'accessoriesSelected',
}

export type BannerSettings<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SpecificSettings extends Record<string, unknown> = Record<string, unknown>,
> = SpecificSettings & {
  /** basically unique value for react key */
  key: string;
  /** selector for specific component.
   * some components (only one for now) are generic:
   * @example 'accordion'
   * others are fully hardcoded components:
   * @example 'actionsList'
   * @example 'baseMachine'
   */
  type: SectionsDynamicTypes;
  titleTKey?: string;
  filter?: FilterItem[];
  children?: BannerSettings[];
};
