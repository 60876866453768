import { useLanguage } from 'context/language/LanguageProvider';
import { useRelations } from 'context/relations/RelationsProvider';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactFields, LanguageFields } from 'types/vendor';

const codeToLocale = {
  fr: 'fr-BE',
  nl: 'nl-BE',
  de: 'nl-BE',
  en: 'en-US',
};
// It has caveat - when relation language was not found in langugages list, but was found in mapping {@link codeToLocale}
// iso and system language will be different.
// It is covered in test "when no language code > returns global language code"
export default function useCurrentLanguageCode(): {
  iso: string;
  system: string;
  systemShort: string;
} {
  const { i18n } = useTranslation();
  const { relations } = useRelations();
  const { languages } = useLanguage();

  const result = useMemo(() => {
    const mandatoryRelation = relations.find(relation => relation.mandatory);
    const customerLanguageCode = mandatoryRelation?.customer?.[ContactFields.lang];

    const language = languages.find(
      option => option[LanguageFields.languageCode] === customerLanguageCode,
    );
    // take the code ISO 639-1 of format
    // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    const languageCode = language?.[LanguageFields.shortLanguageCode]?.toLowerCase();

    return {
      iso: codeToLocale[languageCode ?? i18n.language] ?? 'nl-BE',
      system: language?.[LanguageFields.languageCode] ?? '',
      systemShort: language?.[LanguageFields.shortLanguageCode] ?? 'NL',
    };
  }, [i18n.language, languages, relations]);

  // have current language translations dowloaded in memory
  useEffect(() => {
    i18n?.loadLanguages?.(result.iso);
  }, [i18n, result.iso]);

  return result;
}
