import { theme, ThemeToCssProvider } from 'common/theme';
import ScrollToTop from 'components/ScrollToTop';
import { i18n, TFunction } from 'i18next';
import moment from 'moment';
import 'moment/locale/en-gb'; // responsible for date configuration (first day of week, daylight saving time, etc.)
import React, { FC, PropsWithChildren, Suspense, useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { createI18nInstance } from '../common/i18n';
import { CurrencyProvider } from '../context/currency/CurrencyProvider';
import { history } from '../context/router/history';
import { UrlQueryProvider } from '../context/router/UrlQueryProvider';
import { UnauthenticatedSessionProvider } from '../context/session/UnauthenticatedSessionProvider';
import { XdmsClientProvider } from '../context/xdms/XdmsClient';
import DevThingies from '../layout/Default/DevThingies';
import { sharedActions } from '../store';
import { hostSettingsActions, hostSettingsSelectors } from '../store/hostSettings';

moment.locale('en', {
  week: {
    dow: 1,
  },
});

export { default as AuthenticatedAppProviders } from './AuthenticatedAppProviders';

export const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { customerId, favicon, hostTheme } = useSelector(hostSettingsSelectors.getAll);
  const languageSettings = useSelector(hostSettingsSelectors.getLanguageSettings);

  useEffect(() => {
    const faviconLinkHTMLElement = document.getElementById('favicon');
    faviconLinkHTMLElement?.setAttribute('href', favicon);
  }, [customerId, favicon]);

  const { i18nInstance, initPromise } = useMemo<{
    i18nInstance: i18n;
    initPromise: Promise<TFunction>;
  }>(() => {
    return createI18nInstance(
      languageSettings.translationsLocation,
      languageSettings.availableLanguages,
    );
  }, [languageSettings.availableLanguages, languageSettings.translationsLocation]);

  useEffect(() => {
    dispatch(sharedActions.setIsI18nSettled(false));
    initPromise.then(() => {
      dispatch(sharedActions.setIsI18nSettled(true));
    });
  }, [dispatch, initPromise]);

  return (
    <Router history={history}>
      <ScrollToTop />

      {false && (
        <DevThingies
          customerId={customerId}
          setCustomerId={customerId => {
            dispatch(hostSettingsActions.setCustomerId(customerId));
          }}
        />
      )}

      <I18nextProvider i18n={i18nInstance}>
        <ThemeProvider theme={{ ...theme, ...hostTheme }}>
          <ThemeToCssProvider />
          <UrlQueryProvider>
            <Suspense fallback={<></>}>
              <XdmsClientProvider>
                <UnauthenticatedSessionProvider>
                  <CurrencyProvider>{children}</CurrencyProvider>
                </UnauthenticatedSessionProvider>
              </XdmsClientProvider>
            </Suspense>
          </UrlQueryProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Router>
  );
};
