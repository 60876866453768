import styled from 'styled-components';
import { Button } from 'components/button';
import { PrinterFilled } from '@ant-design/icons';

export const ScFooterContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ScButton = styled(Button)`
  .ant-btn-loading-icon {
    position: absolute;
    left: 1em;
  }
`;

export const ScDocumentButtonIcon = styled(PrinterFilled)`
  font-size: 1.6em;
  margin-right: 0.5em;
`;
