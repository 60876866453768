import * as Sentry from '@sentry/react';
import {
  ParseOptions,
  stringifyUrl,
  parse,
  ParsedQuery as ExternalParsedQuery,
  stringify,
  UrlObject as ExternalUrlObject,
} from 'query-string';

const OPTIONS: ParseOptions = {
  arrayFormat: 'bracket',
};

/** Adaptation for external types */
export type ParsedQuery<T = string> = ExternalParsedQuery<T>;
export type UrlObject = ExternalUrlObject;

/** Just an encapsulated adaptation for external library  */
export const UrlTransform = {
  stringifyUrl: (url: UrlObject): string => stringifyUrl(url, OPTIONS),
  /**
   * ## Parse query from string
   * @example Input: '1=2&3=4'; Output: {1: 2, 3: 4}
   * @example Input: '?1=2&3=4'; Output: {1: 2, 3: 4}
   */
  parseQuery: (query: string): ParsedQuery => {
    let queryToParse: string = query[0] === '?' ? query.slice(1) : query;

    try {
      queryToParse = atob(queryToParse);
    } catch (e) {
      // if the parse failed because of some other reason other than invalid value
      // we need to handle this as abnormal behavior
      // https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names
      if (isDOMException(e) && e.name !== 'InvalidCharacterError') {
        Sentry.captureException(e);
        return {};
      }
    }

    return parse(queryToParse, OPTIONS);
  },
  stringifyQuery: <T = string | number>(
    query: ParsedQuery<T>,
    options?: { encode: boolean },
  ): string => {
    const { encode = true } = options ?? {};
    const value = stringify(query, OPTIONS);
    if (encode) return btoa(value);
    return value;
  },
};

const isDOMException = (e: unknown): e is DOMException => {
  return e instanceof DOMException;
};
