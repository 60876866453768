import * as Yup from 'yup';
import {
  SoftOfferSecurityDepositFields,
  SoftOfferSecurityDeposit,
  SoftOfferPayment,
  SoftOfferPaymentFields,
} from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<
    Partial<SoftOfferSecurityDeposit & { payment: Partial<SoftOfferPayment> }>
  >({
    payment: Yup.object({
      [SoftOfferPaymentFields.paymentType]: Yup.string().required(t('FORM__REQUIRED')),
    }),
    [SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]: Yup.boolean(),
    [SoftOfferSecurityDepositFields.vehiclePlaces]: Yup.number().when(
      SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen,
      {
        is: true,
        then: Yup.number()
          .min(1, t('INVALID_NUMBER_MIN', { v: 1 }))
          .required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferSecurityDepositFields.yearsWithoutSinistre]: Yup.number(),
    [SoftOfferSecurityDepositFields.driverLastClaimsFaultAt]: Yup.string().nullable(),
    [SoftOfferSecurityDepositFields.isOmnium]: Yup.boolean(),
    [SoftOfferSecurityDepositFields.omniumType]: Yup.string(),
    [SoftOfferSecurityDepositFields.evaluationType]: Yup.string(),
    [SoftOfferSecurityDepositFields.invoicePrice]: Yup.number().when(
      SoftOfferSecurityDepositFields.isOmnium,
      {
        is: true,
        then: Yup.number()
          .min(1, t('INVALID_NUMBER_MIN', { v: 1 }))
          .required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferSecurityDepositFields.vehicleValueNetto]: Yup.number(),
    [SoftOfferSecurityDepositFields.vehicleValueBrutto]: Yup.number().when(
      SoftOfferSecurityDepositFields.isOmnium,
      {
        is: true,
        then: Yup.number()
          .min(1, t('INVALID_NUMBER_MIN', { v: 1 }))
          .required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferSecurityDepositFields.deductibleFormula]: Yup.string(),
    [SoftOfferSecurityDepositFields.guaranteeFormulaTmp]: Yup.string(),
    [SoftOfferSecurityDepositFields.guaranteeFormula]: Yup.string()
      .required(t('FORM__REQUIRED'))
      .test(
        'notEmptyFormula',
        t('FORM__REQUIRED'),
        (value: string) => value !== 'NO_Formula',
      ),
    [SoftOfferSecurityDepositFields.isSafetyPackChosen]: Yup.boolean(),
    [SoftOfferSecurityDepositFields.isLegalAssistanceChosen]: Yup.boolean(),
    [SoftOfferSecurityDepositFields.selectedPJ]: Yup.string(),
    [SoftOfferSecurityDepositFields.isDriverChosen]: Yup.boolean(),
    [SoftOfferSecurityDepositFields.driver]: Yup.string(),
    [SoftOfferSecurityDepositFields.vehicleGroupTypeCode]: Yup.string(),
    [SoftOfferSecurityDepositFields.claimsNotAtFaultCount]: Yup.number(),
    [SoftOfferSecurityDepositFields.claimsVehicleDamageCount]: Yup.number(),
    [SoftOfferSecurityDepositFields.theftClaimsCount]: Yup.number(),
  });
};
