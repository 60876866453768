import styled from 'styled-components';
import { Button } from 'components/button';

// clearing opacity for pseudo element
// in order to remove default animcation effect
export const ScButton = styled(Button)`
  && {
    display: inline-block;

    border-width: 0 0 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.dustyGray};

    line-height: 0.836em;
    padding: 0.3em 0 0.6em 0;
    height: auto;
    margin: 0 0.938em;

    transition: color 0.5s, border-color 0.5s;
    color: ${({ theme }) => theme.colors.dustyGray};
    font-weight: 500;
    &:hover {
      color: ${({ theme }) => theme.colors.outerSpace};
    }
    &.active {
      color: ${({ theme }) => theme.colors.black};
      border-color: ${({ theme }) => theme.colors.black};
      span {
        font-weight: 600;
      }
    }

    &:disabled {
      background: none;
      &:hover,
      &:focus,
      &:active {
        background: none;
      }
    }

    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      transition: border-color 0.3s;
      border-color: transparent;
      border-width: 0 0 1px 0;
      border-style: solid;
      display: block;
      z-index: 1;
      opacity: 0;
    }
    &.active:before {
      border-color: ${({ theme }) => theme.mainColor};
    }
  }

  &::after {
    display: none;
  }
`;
