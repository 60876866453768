import React, { FC, PropsWithChildren } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

interface Props {
  onChange(startIdx: number, endIdx: number): void;
}

const DragList: FC<PropsWithChildren<Props>> = ({ children, onChange }) => {
  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return;
        if (result.source.index === result.destination.index) return;
        onChange(result.source.index, result.destination.index);
      }}
    >
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragList;
