import classNames from 'classnames';
import React from 'react';
import VendorMasonry from 'react-masonry-css';

type Props = {
  className?: string;
  breakpointCols: React.ComponentProps<typeof VendorMasonry>['breakpointCols'];
};

const Masonry: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  breakpointCols,
  children,
}) => {
  return (
    <VendorMasonry
      className={classNames(className, { 'masonry-grid': true })}
      breakpointCols={breakpointCols}
    >
      {children}
    </VendorMasonry>
  );
};

export default Masonry;
