import React, { FC, useState } from 'react';
import { SearchCustomerModal } from './SearchCustomerModal';
import { CreateCustomerModal } from './CreateCustomerModal';
import { CustomerFields, CustomerItem, Filter } from 'types/vendor';

interface Props {
  initialValues?: CustomerItem[];
  onSelect(customers: CustomerItem[]): void | Promise<void>;
  isOpen: boolean;
  setIsOpen(visible: boolean): void;
  isAddEnabled: boolean;
}
export const SelectCustomerModal: FC<Props> = props => {
  const {
    initialValues,
    onSelect,
    isOpen: isModalCustomerSearchVisible,
    setIsOpen: setIsModalCustomerSearchVisible,
    isAddEnabled,
  } = props;

  const [customerSearchFilter, setCustomerSearchFilter] = useState<
    Filter<CustomerFields>
  >([]);

  const [isModalCustomerAddNewVisible, setIsModalCustomerAddNewVisible] =
    useState<boolean>(false);

  const onAddNew = () => setIsModalCustomerAddNewVisible(true);

  return (
    <>
      {isModalCustomerSearchVisible && (
        <SearchCustomerModal
          filters={customerSearchFilter}
          initialValues={initialValues}
          onClose={() => {
            setIsModalCustomerSearchVisible(false);
            setCustomerSearchFilter([]);
          }}
          onSubmit={onSelect}
          onAddNew={isAddEnabled ? onAddNew : undefined}
        />
      )}
      {isModalCustomerAddNewVisible && (
        <CreateCustomerModal
          onClose={() => setIsModalCustomerAddNewVisible(false)}
          onSubmit={record => {
            setIsModalCustomerSearchVisible(false);
            onSelect([record]);
          }}
          onWarningClick={(name, value) => {
            setCustomerSearchFilter([{ name: name, value: value }]);
            setIsModalCustomerAddNewVisible(false);
          }}
        />
      )}
    </>
  );
};
