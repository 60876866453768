import VendorTextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { focusInputStyle } from './styles';

export const ScTextArea = styled(VendorTextArea)`
  &:focus,
  &:hover {
    ${focusInputStyle}
  }
  &.ant-input {
    padding: 0.8em 1em;
    border-radius: 0;
    height: ${({ theme }) => theme.inputHeight};
    box-shadow: none;
    height: 100%;
    box-sizing: border-box;
    font-size: 0.75em;
    font-weight: 600;
  }
`;

export const ScLabel = styled.label`
  display: block;
  margin-bottom: 0.438em;
  font-size: 0.75em;
  font-weight: 600;
`;

export const ScTextAreaContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;
