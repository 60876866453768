import {
  AccessoryItemFields,
  ModelCreatableAttributesObjectFields,
  OptionItemFields,
  PackageItemFields,
} from '@hypercharge/xdms-client/lib/types';
import {
  ACCESSORIES_PAGE_URL,
  DEFAULT_QUANTITY,
  OPTIONS_PAGE_URL,
  PACKAGES_PAGE_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
} from 'common/constants';
import { Input } from 'components/form/Input';
import { InputNumber } from 'components/form/InputNumber';
import Selector from 'components/form/Selector';
import { Modal } from 'components/modal';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { ScrollMenuOptions } from 'types/common';
import { get } from 'utils';
import { defaultStepId, NAME_TAB_ALL } from 'utils/constants';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import {
  ScModalFooter,
  ScModalFooterButton,
  ScModalTitle,
} from 'components/modalLike.styles';
import { TableLike } from 'components/tableLike.styles';

import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

const MODAL_SETTINGS = {
  [ACCESSORIES_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: AccessoryItemFields,
    MODAL_TITLE: 'ACCESSORIES',
    CREATE_OBJECT_FIELDS: ModelCreatableAttributesObjectFields.optionOrAccessory,
    OPTION_TYPE: 'A',
  },
  [OPTIONS_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: OptionItemFields,
    MODAL_TITLE: 'OPTIONS',
    CREATE_OBJECT_FIELDS: ModelCreatableAttributesObjectFields.optionOrAccessory,
    OPTION_TYPE: 'O',
  },
  [TERMS_AND_CONDITIONS_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: OptionItemFields,
    MODAL_TITLE: 'TERMS_AND_CONSITIONS',
    CREATE_OBJECT_FIELDS: ModelCreatableAttributesObjectFields.termsAndConditions,
    OPTION_TYPE: 'O',
  },
  [PACKAGES_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: PackageItemFields,
    MODAL_TITLE: 'PACKAGES',
    CREATE_OBJECT_FIELDS: ModelCreatableAttributesObjectFields.package,
    OPTION_TYPE: 'P',
  },
};

export type CustomOptionModalProps = {
  onClose(): void;
  stepId?: keyof typeof MODAL_SETTINGS | undefined;
  categories: ScrollMenuOptions[];
};

export type FormValues = {
  optionName: string;
  selectedCategory: string;
  optionPrice: number;
  optionAmount: number;
  optionPurchasePrice: number;
};

const initialFormValues = {
  optionName: '',
  selectedCategory: '',
  optionPrice: 0,
  optionAmount: 1,
  optionPurchasePrice: 0,
};

export const CreateOptionModal: FC<CustomOptionModalProps> = ({
  onClose,
  stepId = defaultStepId,
  categories = [],
}) => {
  const { t } = useTranslation();
  const { addModelProperties, getModel } = useModelApi();
  const { currency } = useCurrency();

  const { isFeatureEnabled } = useFeature();
  const isQuantitySelectionFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowQuantitySelection',
    source: FeatureSource.DYNAMIC,
  });

  const { model, status, isConfigurationComplete } = useSelector(modelSelectors.getAll);

  const [formValues, updateFormValues] = useState<FormValues>(initialFormValues);

  const { MODEL_TABLE_ITEMS_FIELDS, MODAL_TITLE, CREATE_OBJECT_FIELDS, OPTION_TYPE } =
    MODAL_SETTINGS[stepId];

  const handleChangeField = useCallback(
    (value: string | number, selector: string) => {
      updateFormValues({
        ...formValues,
        [selector]: value,
      });
    },
    [formValues],
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    const {
      optionType,
      defaultAmount,
      selected,
      name,
      price,
      purchasePrice,
      initialPurchasePrice,
      categoryId,
    } = MODEL_TABLE_ITEMS_FIELDS;
    const {
      optionAmount,
      selectedCategory,
      optionName,
      optionPrice,
      optionPurchasePrice,
    } = formValues;

    if (!model) return;

    const { messageHandled, status } = await addModelProperties(model, {
      [CREATE_OBJECT_FIELDS]: {
        [optionType]: OPTION_TYPE,
        [defaultAmount]: isQuantitySelectionFeatureEnabled
          ? optionAmount
          : DEFAULT_QUANTITY,
        [categoryId]: selectedCategory.replace(',', ''),
        [selected]: true,
        [name]: optionName,
        [price]: optionPrice,
        [purchasePrice]: optionPurchasePrice,
        [initialPurchasePrice]: optionPurchasePrice,
      },
    });

    if (!messageHandled) {
      notification.openByStatus(status, {
        [Status.Success]: t('TABLE_CREATE_ITEM_SUCCESS'),
        [Status.Error]: t('GLOBAL_ERROR_TEXT'),
      });
    }

    await getModel();
    updateFormValues(initialFormValues);
    onClose();
  }, [
    MODEL_TABLE_ITEMS_FIELDS,
    formValues,
    model,
    addModelProperties,
    CREATE_OBJECT_FIELDS,
    OPTION_TYPE,
    isQuantitySelectionFeatureEnabled,
    getModel,
    onClose,
    t,
  ]);

  return (
    <Modal variant="sm" closable={false} visible onCancel={onClose} center>
      <ScModalTitle>{t(MODAL_TITLE)}</ScModalTitle>
      <TableLike>
        <TableLike.colgroup>
          <TableLike.col width={'50%'} />
          <TableLike.col width={'50%'} />
        </TableLike.colgroup>

        <TableLike.tr>
          <TableLike.td>{t('PRODUCT_CATEGORY')}</TableLike.td>
          <TableLike.td>
            <Selector
              className="fullwidth"
              bordered={false}
              size="small"
              options={mapToSelectOptions<ScrollMenuOptions>(
                categories.filter(category => category.key !== NAME_TAB_ALL),
                {
                  label: 'name',
                  value: 'key',
                },
              )}
              value={formValues.selectedCategory}
              onChange={({ target }) =>
                handleChangeField(target.value, 'selectedCategory')
              }
            />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{t('DESCRIPTION')}</TableLike.td>
          <TableLike.td>
            <Input
              bordered={false}
              size="small"
              value={formValues.optionName}
              onChange={({ target }) => handleChangeField(target.value, 'optionName')}
              data-testid="create-option-modal-description-input"
              maxLength={60}
            />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{t('PURCHASE_PRICE')}</TableLike.td>
          <TableLike.td>
            <InputNumberLocalized
              bordered={false}
              size="small"
              min={0}
              step={0.01}
              value={formValues.optionPurchasePrice}
              valuePrefix={currency}
              defaultValue={formValues.optionPurchasePrice}
              onChange={event =>
                handleChangeField(event.target.value, 'optionPurchasePrice')
              }
              data-testid="create-option-modal-purchase-price-input"
            />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{t('CUSTOM_RECORD_MODAL_SELLING_PRICE')}</TableLike.td>
          <TableLike.td>
            <InputNumberLocalized
              bordered={false}
              size="small"
              min={0}
              step={0.01}
              value={formValues.optionPrice}
              defaultValue={formValues.optionPrice}
              valuePrefix={currency}
              onChange={event => handleChangeField(event.target.value, 'optionPrice')}
              data-testid="create-option-modal-selleng-price-input"
            />
          </TableLike.td>
        </TableLike.tr>

        {isQuantitySelectionFeatureEnabled && (
          <TableLike.tr>
            <TableLike.td>{t('NUMBER')}</TableLike.td>
            <TableLike.td>
              <InputNumber
                bordered={false}
                size="small"
                min={1}
                value={formValues.optionAmount}
                defaultValue={formValues.optionAmount}
                onChange={event => handleChangeField(event.target.value, 'optionAmount')}
                data-testid="create-option-modal-amount-input"
              />
            </TableLike.td>
          </TableLike.tr>
        )}
      </TableLike>

      <ScModalFooter>
        <ScModalFooterButton
          onClick={onClose}
          data-testid="create-option-modal-cancel-btn"
        >
          {t('FORM_CANCEL')}
        </ScModalFooterButton>
        <ScModalFooterButton
          onClick={handleSubmit}
          loading={status === Status.Loading}
          disabled={!formValues.selectedCategory || isConfigurationComplete}
          variant="primary"
          data-testid="create-option-modal-submit-btn"
        >
          {t('SAVE')}
        </ScModalFooterButton>
      </ScModalFooter>
    </Modal>
  );
};
