import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerSettings } from '../types';
import { useTotalPage } from '../../TotalPageProvider';
import AccessoriesSelected from '../../sections/Accessories/AccessoriesSelected';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';

type Props = BannerSettings;

const TotalGenericAccessoriesSelected: FC<Props> = () => {
  const { t } = useTranslation();

  const { hideZeroDiscounts, totalData, FIELDS, hideIfEmpty } = useTotalPage();

  const model = useSelector(modelSelectors.getModel);

  return (
    <AccessoriesSelected
      t={t}
      model={model}
      FIELDS={FIELDS}
      totalData={totalData}
      hideZeroDiscounts={hideZeroDiscounts}
      hideIfEmpty={hideIfEmpty}
    />
  );
};

export default TotalGenericAccessoriesSelected;
