import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Links } from '../Links';
import UserButton from './UserButton';
import { ScDropdown } from './UserDesktop.styles';

export default () => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClickMenuItem = useCallback((visible: boolean) => {
    setVisibleMenu(visible);
  }, []);

  return (
    <ScDropdown
      overlay={<Links handleClickMenuItem={handleClickMenuItem} />}
      trigger={['click']}
      data-testid="header-user-drop-down-container"
      visible={visibleMenu}
      onVisibleChange={setVisibleMenu}
      getPopupContainer={triggerNode => triggerNode.parentElement || document.body}
    >
      <UserButton t={t} />
    </ScDropdown>
  );
};
