import React, { FC, useEffect, useMemo, useRef } from 'react';
import { DEFAULT_CURRENT_TABLE_PAGE, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import usePagination from 'hooks/usePagination';
import { Pagination } from './Pagination';
import { TableProps as VendorTableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { ScRotatingLoaderWrap, ScTable, ScTableWrap } from './index.styles';
import { selectMaxWidths, selectMinWidths } from './utils';
import classNames from 'classnames';
import { RotatingLoader } from 'components/RotatingLoader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableProps = VendorTableProps<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableColumn<T = any> = ColumnType<T> & {
  maxWidth?: string;
  /**
   * when taleLayout:auto, sticky header enabled, antd ignores header columns width
   * and sizes all columns by data columns content width, so such header columns are being cut in width
   */
  minWidth?: string;
};

type Props = React.PropsWithChildren<{
  pageSize?: number;
  data: Record<string, unknown>[];
  rowSelection?: TableProps['rowSelection'];
  rowKey?: TableProps['rowKey'];
  onRow?: TableProps['onRow'];
  totalCount?: number;
  className?: string;
  columns: TableColumn[];
  sticky?: TableProps['sticky'];
  rowClassName?: TableProps['rowClassName'];
  pagination?: boolean;
  scroll?: TableProps['scroll'];
  // when we pass undefined from parent component
  // it by default takes default prop value
  tableLayout?: TableProps['tableLayout'] | 'none';
  showSizeChanger?: boolean;
  resetKey?: string;
  loading?: boolean;
}>;

const Table: FC<Props> = ({
  className,
  pageSize,
  data,
  columns,
  rowSelection,
  rowKey,
  rowClassName,
  onRow,
  totalCount,
  sticky,
  children,
  pagination = false,
  scroll,
  tableLayout = 'auto',
  showSizeChanger = true,
  resetKey,
  loading,
}) => {
  const defaultCurrentPage = DEFAULT_CURRENT_TABLE_PAGE;

  const ref = useRef<HTMLDivElement | null>(null);

  const { dataToShow, reset, ...paginationSettings } = usePagination({
    data,
    pageSize: pageSize || DEFAULT_TABLE_PAGE_SIZE,
    current: defaultCurrentPage,
    total: totalCount ?? data.length,
    skip: !pagination,
  });

  useEffect(() => {
    const pagesQuantity = Math.floor(
      paginationSettings.total / paginationSettings.pageSize,
    );
    if (paginationSettings.current > pagesQuantity) {
      paginationSettings.onChange(1);
    }
  }, [
    paginationSettings.total,
    paginationSettings.pageSize,
    paginationSettings.onChange,
  ]);

  useEffect(() => {
    return () => reset();
  }, [reset, resetKey]);

  const canBeSticky =
    (ref.current?.clientHeight ?? 0) - (ref.current?.offsetTop ?? 0) >
      window.screen.height && data.length > 0;

  const widths: { max: [number, string][]; min: [number, string][] } = useMemo(
    () => ({
      max: selectMaxWidths(columns, rowSelection ? 1 : 0),
      min: selectMinWidths(columns, rowSelection ? 1 : 0),
    }),
    [columns, rowSelection],
  );

  return (
    <ScTableWrap className={className} ref={ref}>
      {loading && (
        <ScRotatingLoaderWrap>
          <RotatingLoader />
        </ScRotatingLoaderWrap>
      )}
      <ScTable
        className={classNames({ loading: loading })}
        $maxWidths={widths.max}
        $minWidths={widths.min}
        tableLayout={tableLayout === 'none' ? undefined : tableLayout}
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        dataSource={dataToShow}
        scroll={scroll}
        rowKey={rowKey}
        rowClassName={rowClassName}
        onRow={onRow}
        sticky={canBeSticky ? sticky : undefined}
      />
      {children}
      {pagination && (
        <Pagination {...paginationSettings} showSizeChanger={showSizeChanger} />
      )}
    </ScTableWrap>
  );
};

export default Table;
