import React, { useCallback, useMemo } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { ScDropdown, ScMenu } from 'components/table/styles';
import { DotsIcon } from '../ConfigurationList';
import { ScMenuItem } from '../ConfigurationList/ConfigurationList.styles';
import { bubblingPreventer } from 'components/table/BubblingPreventer';
import {
  SoftOfferListItem,
  SoftOfferListItemFields,
} from '@hypercharge/xdms-client/lib/softOffer/types';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from '../../store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { FeatureFlagColumnSettings } from 'types/common';

type MenuItem = {
  item: React.ReactElement;
  order: number;
};

export const columnKeys = {
  ID: SoftOfferListItemFields.id,
  OFFER: SoftOfferListItemFields.polisNumber,
  IP_HOLDER: SoftOfferListItemFields.contactName,
  CREATED_ON: SoftOfferListItemFields.createdAt,
  USER: SoftOfferListItemFields.changedBy,
  ACTIONS: 'actions',
};

interface Params {
  softOfferEditAction(id: number): void;
  softOfferDeleteAction(id: number): void;
}

export const useColumns = ({
  softOfferEditAction,
  softOfferDeleteAction,
}: Params): ColumnsType<SoftOfferListItem> => {
  const { t } = useTranslation();
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[GlobalFeaturesFlagsFields.SoftOfferColumns] as
      | FeatureFlagColumnSettings[]
      | undefined;

    return columns ?? [];
  }, [globalFeatures]);

  const getMenuItemEditSoftOffer = useCallback(
    (softOffer: SoftOfferListItem): MenuItem => ({
      item: (
        <ScMenuItem
          onClick={() => softOfferEditAction(softOffer[SoftOfferListItemFields.id])}
          data-testid="soft-offer-step-table-item-action-edit"
        >
          {t('SOFT_OFFER_EDIT_ACTION')}
        </ScMenuItem>
      ),
      order: 1,
    }),
    [t, softOfferEditAction],
  );

  const getMenuItemDeleteSoftOffer = useCallback(
    (softOffer: SoftOfferListItem): MenuItem => ({
      item: (
        <ScMenuItem
          onClick={() => softOfferDeleteAction(softOffer[SoftOfferListItemFields.id])}
          data-testid="soft-offer-step-table-item-action-delete"
        >
          {t('DELETE_ACTION')}
        </ScMenuItem>
      ),
      order: 2,
    }),
    [t, softOfferDeleteAction],
  );

  const flagToMenuItem: Record<string, (softOffer: SoftOfferListItem) => MenuItem> =
    useMemo(() => {
      return {
        [GlobalFeaturesFlagsFields.allowSoftOfferDelete]: getMenuItemDeleteSoftOffer,
        [GlobalFeaturesFlagsFields.allowSoftOfferEdit]: getMenuItemEditSoftOffer,
      };
    }, [getMenuItemEditSoftOffer, getMenuItemDeleteSoftOffer]);

  const menu = useCallback(
    (softOffer: SoftOfferListItem) => {
      let menuItems: MenuItem[] = [];

      if (!globalFeatures) return <></>;

      Object.entries(globalFeatures).forEach(([settingKey, settingValue]) => {
        if (
          typeof settingValue === 'boolean' &&
          settingValue &&
          flagToMenuItem[settingKey]
        ) {
          const menuItem = flagToMenuItem[settingKey](softOffer);
          menuItems.push(menuItem);
        }
      });

      menuItems = menuItems.sort((a, b) => a.order - b.order);

      return (
        <ScMenu
          onClick={({ domEvent }) => bubblingPreventer(domEvent)}
          data-testid="softOffer-page-table-item-actions-container"
        >
          {menuItems.map(menuItem => menuItem.item)}
        </ScMenu>
      );
    },
    [globalFeatures, flagToMenuItem],
  );

  const keyToColumn: Record<string, ColumnType<SoftOfferListItem>> = useMemo(() => {
    return {
      [columnKeys.ID]: {
        title: 'ID',
        key: columnKeys.ID,
        dataIndex: columnKeys.ID,
      },
      [columnKeys.OFFER]: {
        title: t('SOFT_OFFER_OFFER'),
        key: columnKeys.OFFER,
        dataIndex: columnKeys.OFFER,
      },
      [columnKeys.IP_HOLDER]: {
        title: t('SOFT_OFFER_IP_HOLDER'),
        key: columnKeys.IP_HOLDER,
        dataIndex: columnKeys.IP_HOLDER,
      },
      [columnKeys.CREATED_ON]: {
        title: t('SOFT_OFFER_CREATED_ON'),
        key: columnKeys.CREATED_ON,
        dataIndex: columnKeys.CREATED_ON,
      },
      [columnKeys.USER]: {
        title: t('SOFT_OFFER_USER'),
        key: columnKeys.USER,
        dataIndex: columnKeys.USER,
      },
      [columnKeys.ACTIONS]: {
        title: t('ACTION'),
        // key: 'operation',
        fixed: 'right' as const,
        width: '5.5em',
        render: data => (
          <div>
            <ScDropdown
              trigger={['click']}
              overlay={() => menu(data)}
              placement="bottomLeft"
              data-testid="soft-offer-step-table-item-actions-btn"
            >
              <DotsIcon style={{ fontSize: '2em' }} />
            </ScDropdown>
          </div>
        ),
      },
    };
  }, [t, menu]);

  const tableColumns: ColumnsType<SoftOfferListItem> = useMemo(() => {
    let columns: ColumnsType<SoftOfferListItem> = [];

    if (columnsSettings) {
      const columnKeysToRender: string[] = columnsSettings?.map(({ name }) => name);
      columns = columnKeysToRender.map(key => keyToColumn[key]).filter(Boolean);
    } else {
      columns = Object.values(keyToColumn);
    }

    const hasActionsColumn = columns.some(column => column.key === columnKeys.ACTIONS);
    if (!hasActionsColumn) {
      columns.push(keyToColumn[columnKeys.ACTIONS]);
    }

    return columns;
  }, [columnsSettings, keyToColumn]);

  return tableColumns;
};
