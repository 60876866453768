import styled from 'styled-components';
import { mediaMax, mediaMin } from 'common/theme';

export const ScImageContainer = styled.div`
  width: 100%;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whisper};
  cursor: pointer;
`;

export const ScTitle = styled.div`
  font-size: 1.375em;
  font-weight: 600;
  line-height: 2em;
  padding-top: 1.563em;
  cursor: pointer;
  margin-bottom: 0.563em;
`;

export const ScPriceListItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 1.25em;
  transition: border-color 0.3s;
  align-self: flex-start;
  box-shadow: ${({ theme }) => theme.shadows.formContainer};

  &:hover {
    border-color: ${({ theme }) => theme.mainColor_hovered};
  }

  ${mediaMax.lg} {
    flex: 0 1 48%;
  }

  ${mediaMax.xs} {
    flex: 1 1 auto;
    width: 100%;
  }

  ${mediaMin.md} {
    flex: 0 1 32%;
  }
`;

export const ScImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ScPriceListItemTextBody = styled.div`
  padding: 0 1em 1em 1em;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ScDescription = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.5em;
`;
