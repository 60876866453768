import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import useCarInfo from 'hooks/useCarInfo';
import useRequiredCategory from 'hooks/useRequiredCategory';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { streamingActions, streamingSelectors } from 'store';
import { ImageItem } from 'types';

export default function useStreamingCarGallery(): Result {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sendMessage } = useStreaming();

  const requiredCategoryName = useRequiredCategory();

  const {
    data: carInfoData,
    refetch: refetchCarInfo,
    error,
    loading,
  } = useCarInfo({
    skip: Boolean(requiredCategoryName),
  });

  const carouselImages = useSelector(streamingSelectors.getImageItems);

  const getPreviewImages = useCallback(
    (data: ReturnType<typeof useCarInfo>['data']): ImageItem[] => {
      const [visualization] = data?.visualizations ?? [];
      if (!visualization) return [];

      const { interior, exterior } = visualization.views;
      const views: Record<string, string | null | undefined> = {
        threeQuartersFrontLeft:
          exterior.studio?.threeQuartersFrontLeft?.sizes?.large?.url,
        threeQuartersFrontRight:
          exterior.studio?.threeQuartersFrontRight?.sizes?.large?.url,
        threeQuartersRearLeft: exterior.studio?.threeQuartersRearLeft?.sizes?.large?.url,
        threeQuartersRearRight:
          exterior.studio?.threeQuartersRearRight?.sizes?.large?.url,
        left: exterior.studio?.left?.sizes?.large?.url,
        right: exterior.studio?.right?.sizes?.large?.url,
        wheel: exterior.details?.wheel?.sizes?.large?.url,
        driversView: interior.studio?.driversView?.sizes?.large?.url,
        side: interior.studio?.side?.sizes?.large?.url,
        rear: interior.studio?.rear?.sizes?.large?.url,
      };

      return ORDERED_CONFIGURATION_DETAILS_SIDES.map(side => ({
        id: side,
        image: views[side],
        alt: side,
      })).filter((item): item is ImageItem => Boolean(item.image));
    },
    [],
  );

  const preview = useCallback(async () => {
    let imagesToShow = getPreviewImages(carInfoData);

    if (!imagesToShow.length) {
      const result = await refetchCarInfo();
      if (result) {
        imagesToShow = getPreviewImages(result.data);
      }
    }

    dispatch(streamingActions.setImageItems(imagesToShow));
  }, [getPreviewImages, carInfoData, dispatch, refetchCarInfo]);

  const handlePreview = useCallback(() => {
    if (requiredCategoryName || !!error) return;

    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'global',
        data: {
          carPreviewImageIndex: 0,
        },
      },
    });

    preview();
  }, [error, preview, requiredCategoryName, sendMessage]);

  const handleSlide = useCallback(
    (index: number) => {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'global',
          data: {
            carPreviewImageIndex: index,
            isAllRecordsPreview: false,
          },
        },
      });
    },
    [sendMessage],
  );

  const handleClose = useCallback(() => {
    dispatch(streamingActions.setImageItems([]));

    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'global',
        data: {
          carPreviewImageIndex: -1,
        },
      },
    });
  }, [dispatch, sendMessage]);

  const title = useMemo(() => {
    if (error) return t('DISABLED_PREVIEW_ERROR');

    if (!requiredCategoryName) return t('GALLERY_ICON_PREVIEW');

    return t('DISABLED_PREVIEW_TOOLTIP_MESSAGE', { categoryName: requiredCategoryName });
  }, [error, requiredCategoryName, t]);

  return {
    carouselImages,
    handlePreview,
    handleSlide,
    handleClose,
    isLoading: loading,
    error,
    title,
  };
}

type Result = {
  carouselImages: ImageItem[];
  handlePreview(): void;
  handleSlide(index: number): void;
  handleClose(): void;
  isLoading: boolean;
  error: { message: string } | null;
  title: string;
};

const ORDERED_CONFIGURATION_DETAILS_SIDES = [
  'threeQuartersFrontLeft',
  'threeQuartersFrontRight',
  'threeQuartersRearLeft',
  'threeQuartersRearRight',
  'left',
  'right',
  'wheel',
  'driversView',
  'side',
  'rear',
];
