import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

export const ScTable = styled.table`
  td,
  th {
    &:nth-child(2),
    &:nth-child(3) {
      text-align: center;
    }
  }
  thead {
    tr {
      td,
      th {
        &:first-child {
          padding-left: 2.5em;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          padding-left: 0.5em;
        }
        &:last-child {
          padding-right: 0.5em;
        }
      }

      &:last-child {
        font-weight: 600;
        td:nth-child(1) {
          padding-left: 2.5em;
        }
      }
    }
  }
`;

export const ScCheck = styled(CheckCircleOutlined)`
  color: ${({ theme }) => theme.chargeColor};
  font-size: 1.2em;
`;
