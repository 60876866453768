import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundContainer, NotFoundTitle } from './NotFound.styles';

type NotFoundProps = {
  title?: string;
  content?: string;
};

const NotFound: FC<NotFoundProps> = ({ title, content }) => {
  const { t } = useTranslation();
  return (
    <NotFoundContainer data-testid="not-found-page-container">
      <NotFoundTitle data-testid="not-found-page-title">{title || '404'}</NotFoundTitle>
      <h2 data-testid="not-found-page-content">{content || t('NOT_FOUND_TEXT')}</h2>
    </NotFoundContainer>
  );
};

export default NotFound;
