import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from '../button';

export const ScCloseOutlined = styled(CloseOutlined)`
  padding-left: 2em;
`;

export const ScFilterListItem = styled(Button)`
  display: flex;
  align-items: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  margin-left: 1em;
  height: 100%;
`;
