import { createGlobalStyle } from 'styled-components';
import { DEFAULT_HOST_THEME } from 'common/hostsSettings';

export enum sizes {
  xs = 768,
  sm = 992,
  md = 1200,
  lg = 1860,
}

export const mediaBetween = (from: sizes, to: sizes): string => {
  return `@media screen and (min-width: ${from / 16}em) and (max-width: ${to / 16}em)`;
};

export const mediaMin: Record<keyof typeof sizes, string> = Object.keys(sizes).reduce(
  (acc: Record<string, string>, label: string) => {
    acc[label] = `@media screen and (min-width: ${sizes[label] / 16}em)`;
    return acc;
  },
  {},
);

export const mediaMax: Record<keyof typeof sizes, string> = Object.keys(sizes).reduce(
  (acc: Record<string, string>, label: string) => {
    acc[label] = `@media screen and (max-width: ${sizes[label] / 16}em)`;
    return acc;
  },
  {},
);

export const CONTAINER_SIZES = {
  xs: 768,
  sm: 992,
  md: 1200,
  lg: 1860,
};
export type ContainerSize = keyof typeof CONTAINER_SIZES;

export const VARIANT_TO_WIDTH_MAP = {
  // 450px
  xs: 28.125,
  // 605px
  sm: 37.813,
  // 920px
  md: 57.5,
  // 1235px
  lg: 77.188,
  // 1860px
  xl: 116.25,
};

const staticTheme = {
  colors: {
    softPeach: '#BFBFBF',
    dustyGray: '#989898',
    white: '#ffffff',
    whisper: '#e9e9e9',
    black: '#000',
    outerSpace: '#464646',
    gainsboro: '#dcdcdc',
    aliceBlue: '#f4f8ff',
    platinum: '#ECDEDE',
    lightSilver: '#d9d9d9',
    vampire: '#090909',
    metalicLight: '#284e8033',
    lightGray: '#C3D1E0',
  },
  shadows: {
    formContainer:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    formHeader: '0 1px 0.625em rgba(0, 0, 0, 0.07)',
    noImage: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
  },
  // disabled button background
  disabledSurfaceColor: '#EAECED',
  /** text color for {@link disabledSurfaceColor} */
  disabledSurfaceColor_contrast: '#989898',
  /** Same as {@link staticTheme.colors.outerSpace}, but with opacity */
  dividerLineColor: 'rgba(70, 70, 70, 0.5)',
  dividerLineColorLight: 'rgba(191, 191, 191, 0.4)',
  paginationHeight: '3rem',
  inputHeight: '3.185rem',
  inputHeight_sm: '1.85rem',
  buttonHeight: '3.125em',
  inputFontSize: '0.75em',
  inputLabelFontSize: '0.75em',
  mainBorderColor: '#000000',
  discountColor: '#4EAD04',
  chargeColor: '#FD0000',
  recordIsEditedHighlightColor: '#FF0000',
  notificationColors: {
    error: '#FD0000',
    success: '#4EAD04',
    warning: '#FAE615',
    info: '#4587E4',
  },
  alternativeColor: '#FBFBFB',
  backgroundColor: '#f8f8f8',
  errorBgColor: 'rgba(213, 38, 38, 0.2)',
  fieldErrorColor: '#ff0000',
  modalDisabledColor: '#f5f5f5',
  modalInputTextColor: '#2b2b2b',
  fonts: {
    standardFontFamiliesList: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen-Sans',
      'Ubuntu',
      'Helvetica Neue',
      'sans-serif',
    ],
    /**
     * Possible Weights:
     * 100 - Thin.
     * 200 - ExtraLight, UltraLight.
     * 300 - Light.
     * 400 - Normal, Book, Regular.
     * 500 - Medium.
     * 600 - SemiBold, DemiBold.
     * 700 - Bold.
     * 800 - ExtraBold, UltraBold.
     */
    weights: [
      { fileNameSuffix: '', weightNumber: 400 },
      { fileNameSuffix: 'Normal', weightNumber: 400 },
      { fileNameSuffix: 'Regular', weightNumber: 400 },
      { fileNameSuffix: 'Medium', weightNumber: 500 },
      { fileNameSuffix: 'SemiBold', weightNumber: 600 },
      { fileNameSuffix: 'DemiBold', weightNumber: 600 },
      { fileNameSuffix: 'Medium', weightNumber: 600 }, // hack, as we have file with 'Bold' name for weight 600
      { fileNameSuffix: 'Bold', weightNumber: 700 },
    ],
    extensions: [
      { extension: 'ttf', format: 'truetype' },
      { extension: 'otf', format: 'opentype' },
      { extension: 'woff', format: 'woff' },
      { extension: 'woff2', format: 'woff2' },
    ],
    styles: [
      { fileNameSuffix: '', name: 'normal' },
      { fileNameSuffix: 'Italic', name: 'italic' },
    ],
  },
};

export const theme = { ...staticTheme, ...DEFAULT_HOST_THEME };

/** Migration from js variables to css variables.
 * Primary sense of it - to use in css assets files.
 * Mostly used for notifications. */
export const ThemeToCssProvider = createGlobalStyle`
  :root {
    --color-softPeach: ${({ theme }) => theme.colors.softPeach};
    --color-dustyGray: ${({ theme }) => theme.colors.dustyGray};
    --color-white: ${({ theme }) => theme.colors.white};
    --color-whisper: ${({ theme }) => theme.colors.whisper};
    --color-black: ${({ theme }) => theme.colors.black};

    --notification-color-error: ${({ theme }) => theme.notificationColors.error};
    --notification-color-success: ${({ theme }) => theme.notificationColors.success};
    --notification-color-warning: ${({ theme }) => theme.notificationColors.warning};
    --notification-color-info: ${({ theme }) => theme.notificationColors.info};
  }
`;

export type Theme = typeof theme;
