import styled from 'styled-components';
import { mediaMax } from 'common/theme';

export const ScTable = styled.table`
  width: 100%;
  table-layout: fixed;
  font-weight: 400;

  td {
    padding: 1.25em 0.5em;

    ${mediaMax.sm} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  td:last-child {
    padding-right: 0;
  }
  td:first-child {
    padding-left: 0;
  }

  td:nth-child(1) {
    font-weight: 600;
  }

  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    text-align: center;
  }

  &.unfixed {
    table-layout: auto;
    white-space: nowrap;
  }
  &.shade-numbers {
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      color: ${({ theme }) => theme.colors.dustyGray};
    }
  }

  td.shift-cell-to-left {
    padding-left: 2em;
  }

  &.text-small td {
    font-size: 0.5em;
  }
  &.text-medium td {
    font-size: 0.8em;
  }
  &.text-large td {
    font-size: 0.875em;
  }
  &.text-extra-large td {
    font-size: 1em;
  }

  &.border-top-thick {
    border-top: 1px solid ${({ theme }) => theme.dividerLineColor};
  }
  &.border-top-thin {
    border-top: 1px solid ${({ theme }) => theme.dividerLineColorLight};
  }

  &.border-bottom-thick {
    border-bottom: 1px solid ${({ theme }) => theme.dividerLineColor};
  }
  &.border-bottom-thin {
    border-bottom: 1px solid ${({ theme }) => theme.dividerLineColorLight};
  }
  &.font-weight-bold {
    font-weight: 600;
  }

  .ant-input-number {
    font-size: inherit;
  }
`;
