import { STEPS_URL } from 'common/constants';
import {
  DynamicStepFeaturesFlags,
  DynamicStepFeaturesFlagsGrouped,
} from 'common/dynamicPagesFeaturesFlags';

/**
 * ## Looks for external(BE) settings for current pathname
 * @param {DynamicStepFeaturesFlagsGrouped | undefined | null} dynamicPagesFlags
 * @param {string} pathname
 * @return {unknown}
 * @todo add support for static steps parameters insertion
 */
export const detectDynamicPageFeaturesByPathname = (
  dynamicPagesFlags: DynamicStepFeaturesFlagsGrouped | undefined | null,
  pathname: string,
): DynamicStepFeaturesFlags | null => {
  if (!dynamicPagesFlags) return null;

  const params: [string, Record<string, unknown>] | undefined = Object.entries(
    dynamicPagesFlags,
  ).find(([paramsKey]) => {
    return new RegExp(`^${STEPS_URL}/${paramsKey}`).test(pathname);
  });

  if (params) return params[1] as DynamicStepFeaturesFlags;

  return null;
};
