import Container from 'components/container/Container';
import Paper from 'components/Paper/Paper';
import { useStreaming } from 'context/streaming/StreamingProvider';
import React, { FC, useEffect, useMemo } from 'react';
import Layout from 'layout/Default/Layout';
import InfoForm from './InfoForm';
import { InfoFormProvider } from './InfoFormProvider';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors, modelSelectors } from 'store';
import { ContainerSize } from 'common/theme';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { StreamingEventType } from 'context/streaming/types';

const Info: FC = () => {
  const { sendMessage } = useStreaming();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const modelToken = useSelector(modelSelectors.getToken);

  useEffect(() => {
    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            token: modelToken,
            optionType: null,
          },
        },
      });
    }
  }, [modelToken, sendMessage]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: { screen: 'selection' },
    });
  }, [sendMessage]);

  const containerWidth = useMemo<ContainerSize>(() => {
    return globalFeatures?.[GlobalFeaturesFlagsFields.InfoContainerWidth] ?? 'sm';
  }, [globalFeatures]);

  return (
    <Layout>
      <Container size={containerWidth}>
        <Paper>
          <InfoFormProvider>
            <InfoForm />
          </InfoFormProvider>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Info;
