import { Modal } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ModalCloseIcon } from 'assets/icons/modal-close.svg';

export const ScModal = styled(Modal)`
  font-size: inherit;
  padding-bottom: 0;
  padding-top: 1.375em;

  &.modal {
    &-transparent {
      .ant-modal-content {
        background-color: transparent;
      }
    }
  }

  .ant-modal-header {
    box-shadow: ${({ theme }) => theme.shadows.formHeader};
    border: none;
    .ant-modal-title {
      font-size: 1.125em;
    }
  }

  .ant-modal-body {
    font-size: inherit;
    padding: 0;
  }

  .ant-modal-close {
    position: absolute;
    right: 0;
    top: -1.375em;
    color: white;

    &-x {
      width: 1.375em;
      height: 1.375em;
    }

    [role='img'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ModalBody = styled.div`
  padding: 1.5em 1.5625em;
`;

export const ModalHeader = styled.div<{ $textAlign?: 'center' | 'left' | 'right' }>`
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  ${({ $textAlign }) => ($textAlign ? `text-align: ${$textAlign};` : '')};
  border: none;
  font-size: 1.125em;
  padding: 1em 1.5em;
`;

export const ScModalCloseIcon = styled(ModalCloseIcon)`
  fill: ${({ theme }) => theme.colors.white};
`;
