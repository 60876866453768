import { DownloadOutlined, LinkOutlined, PaperClipOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/new-tab.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/image-icon.svg';
import { ReactComponent as ParkIcon } from 'assets/icons/park.svg';
import { RotatingLoader } from 'components/RotatingLoader';

const commonIconStyles = css`
  font-size: 1.1em;
  margin: auto;
  height: 1em;
`;

const commonPreTitleActionStyles = css`
  ${commonIconStyles}
  color: ${({ theme }) => theme.colors.dustyGray};
`;

export const ScNewTabIcon = styled(NewTabIcon)`
  ${commonIconStyles}
`;

export const ScDownloadOutlined = styled(DownloadOutlined)`
  ${commonIconStyles}
`;

export const ScLinkOutlined = styled(LinkOutlined)`
  ${commonPreTitleActionStyles}
`;

export const ScPaperClipOutlined = styled(PaperClipOutlined)`
  ${commonPreTitleActionStyles}
`;

export const ScPhotoIcon = styled(PhotoIcon)`
  ${commonPreTitleActionStyles}
`;

export const ScParkIcon = styled(ParkIcon)`
  ${commonIconStyles}
`;

export const ScDeleteIcon = styled(DeleteIcon)`
  margin: auto;
`;

export const ScDragIcon = styled(DragIcon)`
  align-self: center;
  vertical-align: bottom;
`;

export const ScDocumentHeaderPreTitleActionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 8em;
  max-width: 8em;
`;

export const ScDocumentHeaderPreTitleAction = styled.div<{ $visible: boolean }>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  flex: 1 1 100%;

  display: flex;
  justify-content: center;

  &:first-child,
  &:only-type {
    margin-left: 0;
  }
`;

export const ScRotatingLoader = styled(RotatingLoader)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
