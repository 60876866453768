import { CURRENCIES, DEFAULT_CURRENCY } from 'common/constants';
import { useCurrency } from 'context/currency/CurrencyProvider';
import SelectorSkeleton from 'layout/Default/header/SelectorSkeleton';
import React, { FC } from 'react';
import { Option } from 'types/common';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { ScSelector } from './styles';

export const Currency: FC = () => {
  const { currency, setActiveCurrency } = useCurrency();
  const changeCurrency = value => {
    setActiveCurrency(value as string);
  };

  if (!CURRENCIES.length) return <SelectorSkeleton />;

  return (
    <ScSelector
      className="button-styled"
      options={mapToSelectOptions<Option>(CURRENCIES, {
        label: 'label',
        value: 'value',
      })}
      onChange={changeCurrency}
      defaultValue={DEFAULT_CURRENCY}
      value={currency}
      disabled={true}
      size="small"
      bordered={false}
    />
  );
};
