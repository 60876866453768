import { sizes } from 'common/theme';
import React, { ChangeEvent, FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { ScrollMenuOptions } from 'types/common';
import { Button } from 'components/button';
import NavButtonsContainer from 'components/button/NavButtonsContainer/NavButtonsContainer';
import HorizontalScrollList from 'components/HorizontalScrollList/HorizontalScrollList';
import {
  ScFilters,
  ScFiltersContainer,
  ScFiltersWrap,
  ScOptionsFilter,
  ScSearchInput,
  ScStandardFeaturesContainer,
  ScViewSwitcherContainer,
} from './index.styles';
import FiltersScrollableMenuItem from '../scrollableMenu/scrollableMenuItem';
import { StandardFeatures } from '../StandardFeatures';
import TableViewSwitcher from './viewSwitcher';
import { MdSearch } from 'react-icons/md';

type Props = {
  scrollMenuOptions: ScrollMenuOptions[];
  handlePrevStep(): void;
  handleNextStep?(): void;
  onSelect(subStep: string): void;
  prevStepDisabled: boolean;
  nextStepDisabled: boolean;
  onSearch(value: ChangeEvent<HTMLInputElement>): void;
  searchValue?: string;
  isSearchDisabled?: boolean;
  extraRight?: ReactNode;
  isAllowExtendedView?: boolean;
  isTableViewExtended?: boolean;
  isStandardFeaturesFeatureEnabled?: boolean;
  setIsTableViewExtended?(tableViewExtendedStatus: boolean): void;
  handleStandardFeaturesValueChange?(isStandardFeaturesEnabled: boolean): void;
  showModelImage?: boolean;
};

const Filters: FC<Props> = ({
  scrollMenuOptions,
  onSelect,
  onSearch,
  searchValue = '',
  isSearchDisabled = false,
  handlePrevStep,
  handleNextStep,
  prevStepDisabled,
  nextStepDisabled,
  extraRight,
  isAllowExtendedView = false,
  isTableViewExtended = false,
  isStandardFeaturesFeatureEnabled = false,
  setIsTableViewExtended,
  handleStandardFeaturesValueChange,
  showModelImage = false,
}) => {
  const { t } = useTranslation();
  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  const handleNextButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // for some reason button remains focused after trigger
      e.currentTarget.blur();
      handleNextStep?.();
    },
    [handleNextStep],
  );

  const handlePrevButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // for some reason button remains focused after trigger
      e.currentTarget.blur();
      handlePrevStep?.();
    },
    [handlePrevStep],
  );

  const tooltipTriggers = useMemo(() => {
    return [isLgAndWider ? 'hover' : 'click'];
  }, [isLgAndWider]);

  const activeCategoryIdx = useMemo(
    () => scrollMenuOptions.findIndex(({ active }) => active),
    [scrollMenuOptions],
  );

  return (
    <ScFiltersContainer $showModelImage={showModelImage}>
      <ScFiltersWrap>
        <ScFilters>
          <ScSearchInput
            placeholder={t('SEARCH')}
            onChange={onSearch}
            value={searchValue}
            disabled={isSearchDisabled}
            suffix={<MdSearch />}
            data-testid="search-input"
          />
          {setIsTableViewExtended && isAllowExtendedView && (
            <ScViewSwitcherContainer
              data-testid="configuration-table-view-switcher-container"
              role="switch"
            >
              <TableViewSwitcher
                disabled={!isAllowExtendedView}
                isTableViewExtended={isTableViewExtended}
                changeTableView={setIsTableViewExtended}
              />
            </ScViewSwitcherContainer>
          )}
          {isStandardFeaturesFeatureEnabled && (
            <ScStandardFeaturesContainer
              data-testid="configuration-table-standard-features-container"
              role="switch"
            >
              <StandardFeatures
                onChange={handleStandardFeaturesValueChange}
                t={t}
                tooltipTriggers={tooltipTriggers}
              />
            </ScStandardFeaturesContainer>
          )}
        </ScFilters>
        {extraRight}
        <NavButtonsContainer>
          <Button
            disabled={prevStepDisabled}
            onClick={handlePrevButtonClick}
            variant={'prev'}
          >
            {t('PREV_STEP')}
          </Button>
          {typeof handleNextStep === 'function' && (
            <Button
              disabled={nextStepDisabled}
              onClick={handleNextButtonClick}
              variant="next"
              data-testid="configuration-table-filter-next-step-btn"
            >
              {t('NEXT_STEP')}
            </Button>
          )}
        </NavButtonsContainer>
      </ScFiltersWrap>
      {Boolean(scrollMenuOptions.length) && (
        <ScOptionsFilter data-testid="configuration-table-filter-categories-wrapper">
          <HorizontalScrollList activeItemIdx={activeCategoryIdx}>
            {scrollMenuOptions.map(item => (
              <FiltersScrollableMenuItem
                key={item.key}
                onClick={() => onSelect(item.key)}
                active={item.active}
                disabled={item.disabled}
                testIdSuffix={item.tKey || item.name}
              >
                {item.tKey ? t(item.tKey) : item.name}
              </FiltersScrollableMenuItem>
            ))}
          </HorizontalScrollList>
        </ScOptionsFilter>
      )}
    </ScFiltersContainer>
  );
};

export default Filters;
