import React, { FC } from 'react';
import { FinancingTable } from './Table';
import { FinancingForm } from './Form';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FINANCING_URL } from 'common/constants';

export const FinancingPage: FC = () => {
  return (
    <Switch>
      <Route path={FINANCING_URL + '/list'} component={FinancingTable} />
      <Route path={FINANCING_URL + '/:id'} component={FinancingForm} />
      <Redirect to={FINANCING_URL + '/list'} />
    </Switch>
  );
};
