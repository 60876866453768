import React, { useEffect, useRef } from 'react';
import {
  ScContentWrapper,
  ScIconWrapper,
  ScImageCard,
  ScModal,
  ScModalBody,
  ScModalCloseIcon,
} from './StreamingRecordsPreviewer.styles';
import { theme } from 'common/theme';
import { PreviewImage, PreviewImageType } from 'types';
import useDebounce from 'hooks/useDebounce';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';

type Props = {
  onClose(): void;
  displayData: PreviewImage[];
  onClick(image: PreviewImage): void;
};

const StreamingRecordsPreviewer: React.FC<Props> = ({
  onClose,
  onClick,
  displayData,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { sendMessage } = useStreaming();

  const debouncedHandleWheel = useDebounce(() => {
    const targetElement = ref.current?.parentElement?.parentElement;

    if (!targetElement) return;

    const scrollInPercentage =
      (targetElement.scrollTop / targetElement.scrollHeight) * 100;

    const roundedPercentage = parseFloat(scrollInPercentage.toFixed(2));

    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'global',
        data: {
          scrollYPos: roundedPercentage,
        },
      },
    });
  }, 500);

  useEffect(() => {
    const element = document.querySelector('.streaming-records-previewer')?.parentElement;

    if (!element) return;

    element.addEventListener('scroll', debouncedHandleWheel);
    debouncedHandleWheel();
    return () => {
      element.removeEventListener('scroll', debouncedHandleWheel);
    };
  }, [debouncedHandleWheel]);

  useEffect(() => {
    return () => {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            currentlyPreviewedPackage: null,
          },
        },
      });
    };
  }, [sendMessage]);

  return (
    <ScModal
      className="streaming-records-previewer"
      variant="xl"
      center={false}
      visible
      maskClosable={false}
      onCancel={onClose}
      transparent
      maskStyle={{ backgroundColor: theme.colors.white }}
      modalRender={() => {
        return (
          <ScModalBody
            ref={ref}
            onWheel={debouncedHandleWheel}
            onTouchEnd={debouncedHandleWheel}
          >
            <ScIconWrapper>
              <ScModalCloseIcon onClick={onClose} />
            </ScIconWrapper>
            <ScContentWrapper>
              {displayData.map(image => (
                <ScImageCard
                  key={image.name}
                  image={image}
                  onClick={onClick}
                  $isPackage={image.type === PreviewImageType.Package}
                />
              ))}
            </ScContentWrapper>
          </ScModalBody>
        );
      }}
    />
  );
};

export default StreamingRecordsPreviewer;
