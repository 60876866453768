import React from 'react';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { TableInputFilterByColumn } from 'components/table/TableInputFilterByColumn';
import { find, get } from 'utils';
import { SelectedFiltersItemsList_Filters } from 'components/filters/FiltersList';
import { ColumnType as VendorColumnType } from 'antd/lib/table/interface';
import { DataIndex } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ColumnType = VendorColumnType<any>;

const ScLookUpTableIcon = styled(SearchOutlined)`
  font-size: 1em;
`;

type Args = {
  dataIndex: DataIndex;
  filterDropdown?: ColumnType['filterDropdown'];
  render?: ColumnType['render'];
  filteredValue?: ColumnType['filteredValue'];
  filterIcon?: ColumnType['filterIcon'];
  filters: SelectedFiltersItemsList_Filters;
  handleSearch(selectedKeys, confirm, dataIndex: DataIndex): void;
  handleReset(dataIndex: DataIndex): void;
  width?: string;
};

// https://ant.design/components/table#column
type Result = {
  filterDropdown: ColumnType['filterDropdown'];
  filterIcon: ColumnType['filterIcon'];
  onFilter: ColumnType['onFilter'];
  render: ColumnType['render'];
  filteredValue: ColumnType['filteredValue'];
};

const getColumnSearchProps = (props: Args): Result => {
  const {
    dataIndex,
    filters,
    filterDropdown,
    render,
    handleSearch,
    handleReset,
    filteredValue = (() => {
      const filter = find(filters, filter => filter.name === dataIndex);
      return get(filter, 'value') ? [get(filter, 'value', '').toString()] : null;
    })(),
    filterIcon = <ScLookUpTableIcon />,
  } = props;

  const result = {
    filterDropdown,
    filterIcon,
    onFilter: (_value, record) => {
      return Boolean(record);
    },
    render: (...args) => (render ? render(...args) : args[0]),
    filteredValue,
  } as Result;

  if (!filterDropdown) {
    result.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, visible }) => {
      return (
        <TableInputFilterByColumn
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          handleSearch={handleSearch}
          handleReset={handleReset}
          visible={visible}
        />
      );
    };
  }

  return result;
};

export default getColumnSearchProps;
