import styled from 'styled-components';

export const ScStepNumberIcon = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.softPeach};
  color: ${props => props.theme.colors.softPeach};
  border-radius: 5em;
  width: 2.5em;
  min-width: 2em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.375em;

  .step-number-icon {
    &--soft-offer {
      font-size: 1.5em;
    }
  }

  svg > path,
  svg > circle,
  svg > rect {
    transition: all 0.3s linear;
  }
`;

export const ScStepNumberTitle = styled.div`
  font-size: 0.875em;
  color: ${props => props.theme.colors.softPeach};
`;

export const ScStepNumber = styled.div`
  -webkit-touch-callout: none;
  user-select: none;
  white-space: nowrap;
  font-weight: 600;
  transition: all 0.3s linear;

  &:last-child {
    padding-right: 1.75em;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled:hover {
    cursor: not-allowed;
  }

  &.active {
    ${ScStepNumberIcon} {
      background-color: ${({ theme }) => theme.mainColor};
      border-color: ${({ theme }) => theme.mainColor};
      color: ${({ theme }) => theme.mainColor_contrast};

      .step-number-icon {
        svg {
          > path,
          > circle,
          > rect {
            fill: ${({ theme }) => theme.mainColor_contrast};
          }
        }

        &--soft-offer {
          font-size: 1.5em;

          svg {
            path:first-child {
              fill: ${({ theme }) => theme.mainColor_contrast};
            }

            path:last-child {
              fill: ${({ theme }) => theme.mainColor};
            }
          }
        }
      }
    }

    ${ScStepNumberTitle} {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;
