import styled from 'styled-components';

export const ScScrollWrapper = styled.div`
  .react-horizontal-scrolling-menu--wrapper {
    .react-horizontal-scrolling-menu--inner-wrapper {
      align-items: center;

      .react-horizontal-scrolling-menu--arrow-left {
        margin-right: 0.6em;
      }
      .react-horizontal-scrolling-menu--arrow-right {
        margin-left: 0.6em;
      }

      .react-horizontal-scrolling-menu--scroll-container {
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .react-horizontal-scrolling-menu--item {
          border-style: solid;
          border-width: 0 0 1px;
          border-color: ${({ theme }) => theme.colors.softPeach};
        }

        .react-horizontal-scrolling-menu--item:first-child button {
          margin-left: 0;
        }
        .react-horizontal-scrolling-menu--item:last-child button {
          margin-right: 0;
        }
      }
    }
  }
`;
