import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { authSelectors, configurationSelectors } from 'store';
import { Input } from 'components/form/Input';
import { Button } from 'components/button/Button';
import { getAuthData } from '@hypercharge/xdms-client';
import { Form, FormikProvider, useFormik } from 'formik';
import FormField from 'components/form/formik/FormField';
import { ScContainer, ScFloatingContainer } from './ConfigurationMarginModal.styles';
import { Loader } from 'components/Loader';

interface Props {
  onSuccess(): void;
}

const ConfigurationMarginModalLogin: FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const { baseUrl, username } = useSelector(authSelectors.getAll);

  const [status, setStatus] = useState(Status.Idle);
  const [message, setMessage] = useState('');

  const handleSuccess = useCallback(() => {
    setStatus(Status.Success);
    setMessage(t('SUCCESS'));
    setTimeout(() => {
      onSuccess();
    }, 1000);
  }, [onSuccess, t]);

  const handleError = useCallback(
    (error?: string) => {
      setStatus(Status.Error);
      setMessage(error || t('ERROR'));
      setTimeout(() => {
        setStatus(Status.Idle);
      }, 1000);
    },
    [t],
  );

  const formProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { password: '' },
    onSubmit: async values => {
      if (!configurationNumber || !username || !baseUrl) return;
      setStatus(Status.Loading);
      let token = '';
      try {
        const response = await getAuthData({
          username: username,
          password: values.password,
          tenantBaseUrl: baseUrl,
        });
        token = response.token;
        handleSuccess();
      } catch (error) {
        handleError(String(error));
      }
      token ? handleSuccess() : handleError();
    },
  });

  const isDisabled = useMemo(
    () => status === Status.Loading || status === Status.Success,
    [status],
  );

  return (
    <ScContainer>
      <h5>{t('CONFIGURATION_MARGIN_MODAL_FORM_TITLE')}</h5>

      <FormikProvider value={formProps}>
        <Form>
          <FormField
            id="password"
            component={Input}
            disabled={isDisabled}
            name="password"
            placeholder={t('AUTH__PASSWORD')}
            type="password"
            autoComplete="on"
          />
          <Button
            htmlType="submit"
            fullwidth
            variant="primary"
            disabled={isDisabled || !formProps.values.password}
          >
            {t('SUBMIT')}
          </Button>
        </Form>
      </FormikProvider>

      {status === Status.Success && <ScFloatingContainer>{message}</ScFloatingContainer>}
      {status === Status.Error && <ScFloatingContainer>{message}</ScFloatingContainer>}
      {status === Status.Loading && (
        <ScFloatingContainer>
          <Loader />
        </ScFloatingContainer>
      )}
    </ScContainer>
  );
};

export default ConfigurationMarginModalLogin;
