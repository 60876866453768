import Icon from '@ant-design/icons';
import { Document_Locations } from '@hypercharge/xdms-client/lib/document';
import { Button as ButtonAntd, Tooltip } from 'antd';
import { DocumentItem } from 'context/document/types';
import React, { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PopConfirm from '../../../PopConfirm';
import Checkbox from '../../../form/Checkbox';
import {
  ScDeleteIcon,
  ScDocumentHeaderPreTitleAction,
  ScDocumentHeaderPreTitleActionsWrapper,
  ScDownloadOutlined,
  ScDragIcon,
  ScLinkOutlined,
  ScNewTabIcon,
  ScPaperClipOutlined,
  ScParkIcon,
  ScPhotoIcon,
  ScRotatingLoader,
} from './DocumentHeader.styles';
import { Header } from './Header';
import {
  ScCaretDownOutlined,
  ScCaretRightOutlined,
  ScHeaderActionButton,
} from './Header.styles';

export const getDocumentHeaderIcon = (record: DocumentItem): ComponentType | null => {
  if (record.isPhoto) {
    return ScPhotoIcon;
  }

  if (record.computed.location === Document_Locations.link) {
    return ScLinkOutlined;
  }

  if (record.computed.location === Document_Locations.amazon) {
    return ScPaperClipOutlined;
  }

  return null;
};

interface Props {
  name: string;
  createdAt: string | undefined;
  icon: ComponentType | null;

  isDraggable: boolean;

  isLoaderVisible: boolean;

  onCheck(): void;
  isCheckSelected: boolean;
  isCheckDisabled: boolean;
  isCheckVisible: boolean;

  onCaret(): void;
  isCaretOpen: boolean;
  isCaretVisible: boolean;

  onDownload(): void;
  isDownloadVisible: boolean;

  onCopy(): void;
  isCopyVisible: boolean;

  onOpen(): void;
  isOpenVisible: boolean;

  onDelete(): void;
  isDeleteVisible: boolean;
}

const DocumentHeader: React.FC<Props> = ({
  name,
  createdAt,
  icon,
  isDraggable,
  isLoaderVisible,
  onCheck,
  isCheckSelected,
  isCheckDisabled,
  isCheckVisible,
  onCaret,
  isCaretOpen,
  isCaretVisible,
  onDownload,
  isDownloadVisible,
  onCopy,
  isCopyVisible,
  onOpen,
  isOpenVisible,
  onDelete,
  isDeleteVisible,
}) => {
  const { t } = useTranslation();

  const preTitleActions = useMemo(() => {
    return (
      <ScDocumentHeaderPreTitleActionsWrapper>
        <ScDocumentHeaderPreTitleAction $visible={isDraggable}>
          <ScDragIcon />
        </ScDocumentHeaderPreTitleAction>
        <ScDocumentHeaderPreTitleAction $visible={!!icon}>
          {icon && <Icon component={icon} />}
        </ScDocumentHeaderPreTitleAction>
        <ScDocumentHeaderPreTitleAction $visible={isCheckVisible}>
          <Checkbox
            disabled={isCheckDisabled}
            checked={isCheckSelected}
            onChange={onCheck}
          />
        </ScDocumentHeaderPreTitleAction>
      </ScDocumentHeaderPreTitleActionsWrapper>
    );
  }, [icon, isCheckDisabled, isCheckVisible, isDraggable, isCheckSelected, onCheck]);

  const actions = useMemo(
    () => (
      <>
        {isLoaderVisible && <ScRotatingLoader />}
        {isOpenVisible && (
          <Tooltip placement="top" title={t('DOCUMENTS_NEW_TAB_ACTION_TOOLTIP')}>
            <ScHeaderActionButton onClick={onOpen} type="text" icon={<ScNewTabIcon />} />
          </Tooltip>
        )}
        {isDownloadVisible && (
          <Tooltip placement="top" title={t('DOCUMENTS_DOWNLOAD_ACTION_TOOLTIP')}>
            <ScHeaderActionButton
              onClick={onDownload}
              type="text"
              icon={<ScDownloadOutlined />}
            />
          </Tooltip>
        )}
        {isCopyVisible && (
          <Tooltip placement="top" title={t('DOCUMENTS_COPY_LINK_ACTION_TOOLTIP')}>
            <ScHeaderActionButton onClick={onCopy} type="text" icon={<ScParkIcon />} />
          </Tooltip>
        )}
        {isDeleteVisible && (
          <PopConfirm variant={'error'} onConfirm={onDelete} placement="bottom">
            <Tooltip placement="top" title={t('DOCUMENTS_DELETE_ACTION_TOOLTIP')}>
              <ScHeaderActionButton danger type="text" icon={<ScDeleteIcon />} />
            </Tooltip>
          </PopConfirm>
        )}
        {isCaretVisible && (
          <Tooltip placement="top" title={t('DOCUMENTS_MORE_ACTION_TOOLTIP')}>
            <ButtonAntd
              onClick={onCaret}
              type="text"
              icon={isCaretOpen ? <ScCaretDownOutlined /> : <ScCaretRightOutlined />}
            />
          </Tooltip>
        )}
      </>
    ),
    [
      isCaretVisible,
      isCaretOpen,
      isCopyVisible,
      isDeleteVisible,
      isDownloadVisible,
      isLoaderVisible,
      isOpenVisible,
      onCaret,
      onCopy,
      onDelete,
      onDownload,
      onOpen,
      t,
    ],
  );

  return (
    <Header
      withCursorPointer={isDraggable}
      preTitleActions={preTitleActions}
      title={name}
      createdAt={createdAt}
      actions={actions}
    />
  );
};

export default DocumentHeader;
