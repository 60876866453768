import ErrorBoundary from 'components/ErrorBoundary';
import { Steps } from 'components/steps/Steps';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { VerticalScrolling } from 'components/VerticalScrolling/VerticalScrolling';
import { Header } from './header/Header';
import { ScContentWrapper, ScHeader, ScLayout, ScSpin } from './Layout.styles';
import Loading from 'components/Loader/Loading';
import { useSelector } from 'react-redux';
import { authSelectors, hostSettingsSelectors, sharedSelectors } from 'store';
import { isNil } from '../../utils';
import { HostSettingsExternal } from 'common/hostsSettings';

type VerticalScrollingProps = React.ComponentProps<typeof VerticalScrolling>;

interface Props {
  withHeader?: boolean;
  withSteps?: boolean;
  withVerticalScrolling?: boolean;
  verticalScrollingRefEl?: VerticalScrollingProps['refEl'];
  varticalScrollingRefWrapper?: VerticalScrollingProps['refWrapper'];
  verticalScrollingReset?: VerticalScrollingProps['reset'];
  bg?: HostSettingsExternal['backgroundCommon'] | false;
}

const Layout: FC<PropsWithChildren<Props>> = ({
  withHeader = true,
  withSteps = true,
  withVerticalScrolling = false,
  verticalScrollingRefEl,
  varticalScrollingRefWrapper,
  verticalScrollingReset,
  bg,
  children,
}) => {
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);

  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);
  const backgroundCommon = useSelector(hostSettingsSelectors.getBackgroundCommon);

  const background = useMemo<HostSettingsExternal['backgroundCommon'] | undefined>(() => {
    if (!isNil(bg)) return bg || undefined;

    return backgroundCommon;
  }, [backgroundCommon, bg]);

  return (
    <ScLayout data-testid="layout-container">
      <ScSpin
        indicator={<Loading />}
        size="large"
        spinning={isWholePageLoading}
        data-testid="loading-indicator"
      />
      {/* <CookiesHeader /> */}
      <ScHeader id="layout-top-section">
        {withHeader && <Header />}
        {isAuthenticated && withSteps && <Steps />}
      </ScHeader>
      <ErrorBoundary>
        {withVerticalScrolling ? (
          <VerticalScrolling
            refEl={verticalScrollingRefEl ?? 'layout-ref'}
            refWrapper={varticalScrollingRefWrapper ?? window}
            reset={verticalScrollingReset}
          >
            <ScContentWrapper className="layout-ref" bg={background}>
              {children}
            </ScContentWrapper>
          </VerticalScrolling>
        ) : (
          <ScContentWrapper bg={background}>{children}</ScContentWrapper>
        )}
      </ErrorBoundary>
    </ScLayout>
  );
};

export default Layout;
