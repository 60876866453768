import {
  FinancingCalculation,
  FinancingProduct,
  FinancingProductFields,
  FinancingTotal,
  FinancingTotalFields,
} from 'types/vendor';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLike } from 'components/tableLike.styles';
import { Price } from 'components/Price';
import { useFormik, Form, FormikProvider } from 'formik';
import FormField from 'components/form/formik/FormField';
import { Row, Col } from 'antd';
import Selector from 'components/form/Selector';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { extractFields } from './utils';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';
import { Button } from 'components/button';

const FORM_FIELDS_TKEYS_MAPPING = {
  CBmm: 'FINANCING_FIELD_MONTHS',
  PRadvance: 'FINANCING_FIELD_ADVANCE_AMOUNT',
  PCrest: 'FINANCING_FIELD_RESIDUAL_PERCENT',
};
const ROW_SPACES: [number, number] = [20, 0];
const INFO_FIELDS_SHOWN_REGEX = /v-PRinfo-show-\d/;
const getInfoFieldValueField = (number: number) => `v-PRinfo-${number}`;
const getInfoFieldLabelField = (number: number) => `v-PRinfo-label-${number}`;

interface Props {
  calculationSimulation?: FinancingCalculation;
  activeCalculationSimulation?: FinancingCalculation;
  isCalculationSimulationLoading: boolean;
  isSelected: boolean;
  onSelect(): void;
  products?: FinancingProduct[];
  values?: FinancingTotal;
  onProductChange(code: string): void;
  onUpdateSimulation(
    productKey: string,
    values: Record<string, string | number>,
  ): Promise<void>;
  onSaveSimulation(
    product: FinancingProduct,
    values: Record<string, string | number>,
  ): Promise<void>;
}

const Total: FC<Props> = ({
  values,
  products,
  calculationSimulation,
  activeCalculationSimulation,
  isCalculationSimulationLoading,
  isSelected,
  onSelect,
  onProductChange,
  onUpdateSimulation,
  onSaveSimulation,
}) => {
  const { t } = useTranslation();

  const [selectedProductKey, setSelectedProductKey] = useState(
    values?.[FinancingTotalFields.productKey],
  );

  useEffect(() => {
    if (selectedProductKey) onProductChange(selectedProductKey);
  }, [onProductChange, selectedProductKey]);

  useEffect(() => {
    setSelectedProductKey(value => value ?? values?.[FinancingTotalFields.productKey]);
  }, [values]);

  const selectedProduct = useMemo(() => {
    return products?.find(
      item => item[FinancingProductFields.productKey] === selectedProductKey,
    );
  }, [products, selectedProductKey]);

  const fields = useMemo(() => {
    if (!calculationSimulation || !selectedProduct) return [];
    return extractFields(calculationSimulation, selectedProduct);
  }, [calculationSimulation, selectedProduct]);

  const activeFields = useMemo(() => {
    if (!activeCalculationSimulation || !selectedProduct) return [];
    return extractFields(activeCalculationSimulation, selectedProduct);
  }, [activeCalculationSimulation, selectedProduct]);

  const fieldsRender = useMemo(() => {
    return fields.map(field => {
      switch (field.type) {
        case 'number': {
          return (
            <Col key={field.name} md={8}>
              <FormField
                name={field.name}
                component={InputNumberLocalized}
                label={t(FORM_FIELDS_TKEYS_MAPPING[field.name] ?? 'VALUE')}
                min={field.min}
                max={field.max}
                help={t('FINANCING_MIN_MAX', { min: field.min, max: field.max })}
                disabled={isCalculationSimulationLoading}
              />
            </Col>
          );
        }
        case 'select': {
          return (
            <Col key={field.name} md={8}>
              <FormField
                name={field.name}
                component={Selector}
                label={t(FORM_FIELDS_TKEYS_MAPPING[field.name] ?? 'VALUE')}
                options={field.options}
                disabled={isCalculationSimulationLoading}
              />
            </Col>
          );
        }
      }
    });
  }, [fields, t, isCalculationSimulationLoading]);

  const initialValues = useMemo(() => {
    const result: Record<string, string | number> = {};
    for (const field of fields) {
      result[field.name] = field.value;
    }
    return result;
  }, [fields]);

  const formProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: initialValues,
    onSubmit: async values => {
      const valuesCopy = { ...values };
      delete valuesCopy['productCode'];
      if (!selectedProductKey) return;
      await onUpdateSimulation(selectedProductKey, valuesCopy);
    },
  });

  const onChange = useCallback(
    async (values: Record<string, string | number>) => {
      const valuesCopy = { ...values };
      delete valuesCopy['productCode'];
      if (!selectedProductKey) return;
      await onUpdateSimulation(selectedProductKey, valuesCopy);
    },
    [onUpdateSimulation, selectedProductKey],
  );

  useEffect(() => {
    if (!formProps.dirty) return;
    const timeout = setTimeout(async () => {
      await onChange(formProps.values);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [onChange, formProps.values, formProps.dirty]);

  const isNewSimulationActive = useMemo(() => {
    const activeFieldsValues = activeFields.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    const fieldsValues = fields.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    return Object.entries(fieldsValues).some(
      ([name, value]) => value !== activeFieldsValues[name],
    );
  }, [activeFields, fields]);

  const infoFieldsRender = useMemo(() => {
    if (!values) return null;
    // pick only numbers of visible fields
    const fieldsNumbers = Object.keys(values)
      .filter(field => INFO_FIELDS_SHOWN_REGEX.test(field))
      .filter(field => values[field])
      .map(item => Number(item.replace(/\D/g, '')));

    return fieldsNumbers.map(number => {
      const valueField = getInfoFieldValueField(number);
      const labelField = getInfoFieldLabelField(number);

      return (
        <TableLike.tr key={labelField}>
          <TableLike.td>{values?.[labelField]}</TableLike.td>
          <TableLike.td>
            <Price value={values?.[valueField] ?? 0} />
          </TableLike.td>
          {isNewSimulationActive && (
            <TableLike.td>
              <Price value={calculationSimulation?.[valueField] ?? 0} />
            </TableLike.td>
          )}
        </TableLike.tr>
      );
    });
  }, [values, calculationSimulation, isNewSimulationActive]);

  const productCodeOptions = useMemo(() => {
    if (!products?.length) return [];
    return mapToSelectOptions(products, {
      label: FinancingProductFields.name,
      value: FinancingProductFields.productKey,
    });
  }, [products]);

  const onSave = useCallback(async () => {
    if (!selectedProduct) return;
    await onSaveSimulation(selectedProduct, formProps.values);
  }, [formProps.values, onSaveSimulation, selectedProduct]);

  return (
    <>
      <FormikProvider value={formProps}>
        <Form>
          <Row gutter={ROW_SPACES}>
            <Col md={8}>
              <FormField
                name="productCode"
                component={Selector}
                options={productCodeOptions}
                label={t('FINANCING_PRODUCT_TYPE')}
                value={selectedProductKey}
                onChange={event => setSelectedProductKey(event.target.value)}
                disabled={isCalculationSimulationLoading}
              />
            </Col>

            {fieldsRender}
          </Row>
        </Form>
      </FormikProvider>

      <TableLike $flat>
        <TableLike.colgroup>
          {isNewSimulationActive ? (
            <>
              <TableLike.col width={'50%'} />
              <TableLike.col width={'25%'} />
              <TableLike.col width={'25%'} />
            </>
          ) : (
            <>
              <TableLike.col width={'50%'} />
              <TableLike.col width={'50%'} />
            </>
          )}
        </TableLike.colgroup>
        <TableLike.tr>
          <TableLike.th>{t('NAME')}</TableLike.th>
          {isNewSimulationActive ? (
            <>
              <TableLike.th>{t('OLD_VALUE')}</TableLike.th>
              <TableLike.th>{t('NEW_VALUE')}</TableLike.th>
            </>
          ) : (
            <TableLike.th>{t('VALUE')}</TableLike.th>
          )}
        </TableLike.tr>
        {infoFieldsRender}
      </TableLike>
      <Row style={{ marginTop: '1rem' }} gutter={16}>
        <Col>
          <Button variant="primary" onClick={onSave}>
            {t('SAVE')}
          </Button>
        </Col>
        <Col>
          <Button variant="prev" disabled={isSelected} onClick={onSelect}>
            {t('FINANCING_TOTAL_SAVE')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Total;
