import { useQuery } from 'context/router/UrlQueryProvider';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors, streamingSelectors } from 'store';
import { UrlTransform } from 'utils/urlTransform';

export default function useStreamingOpenMyTerminal(): Result {
  const { queryValues } = useQuery();

  const username = useSelector(authSelectors.getUsername);
  const preferredTerminal = useSelector(streamingSelectors.getPreferredTerminal);
  const baseURL = useSelector(authSelectors.getBaseUrl);
  const host = baseURL?.split('.')[0].replace('https://', '');
  const customerId = queryValues.stream || username;

  const getTerminalUrl = useCallback((id: string, host: string) => {
    const baseUrl = process.env.REACT_APP_STREAMING_TERMINAL_URL as string;
    return UrlTransform.stringifyUrl({
      url: baseUrl,
      query: {
        customer_id: id,
        host,
      },
    });
  }, []);

  const handleOpenMyTerminal = useCallback(
    (id?: string) => {
      if (!host) return;

      if (id) {
        window.open(getTerminalUrl(id, host), '_blank');
        return;
      }

      if (!customerId || !preferredTerminal) return;

      window.open(getTerminalUrl(customerId, host), '_blank');
    },
    [host, customerId, preferredTerminal, getTerminalUrl],
  );

  const canOpenMyTerminal = useMemo(
    () => !!(host && customerId && preferredTerminal),
    [customerId, host, preferredTerminal],
  );

  return { handleOpenMyTerminal, canOpenMyTerminal };
}

type Result = {
  handleOpenMyTerminal(id?: string): void;
  canOpenMyTerminal: boolean;
};
