import React, { FC } from 'react';
import { ScNoItems, ScNoItemsIcon, SearchOutlinedContainer } from './NoItems.styles';

type NoItems = {
  message: string;
  className?: string;
  component?: React.ReactNode;
};

export const NoItems: FC<NoItems> = ({ message, className, component }) => {
  return (
    <ScNoItems data-testid="no-item-container">
      <SearchOutlinedContainer
        className={className}
        data-testid="no-item-puase-animation-area"
      >
        {component ? (
          component
        ) : (
          <ScNoItemsIcon data-testid="no-item-pause-animation-icon" />
        )}
      </SearchOutlinedContainer>
      <span data-testid="no-item-text">{message}</span>
    </ScNoItems>
  );
};
