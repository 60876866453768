import { TOTAL_ACTIONS_RULES_TRANSLATIONS_CODES } from 'common/constants';
import { handleFiltering } from 'pages/total/sectionsDynamic/_common/filter';
import { BannerSettings, SectionsDynamicTypes } from 'pages/total/sectionsDynamic/types';
import { useMemo } from 'react';
import {
  ActionRuleCode,
  ModelAction,
  ModelActionFields,
  ModelActionRuleCodeValues,
} from 'types/vendor';

/**
 * Filters out duplicate national promotions.
 * @param actionsList {ModelAction[]} - List of total actions.
 * @param settings {BannerSettings[]} - List of schema settings
 * @returns {ModelAction[]} Filtered total actions.
 */
export default function useStreamingActionsList(
  actionsList: ModelAction[],
  settings: BannerSettings[],
): ModelAction[] {
  return useMemo(() => {
    const setting = settings.find(
      setting =>
        setting.titleTKey === 'TOTAL_ACTIONS_LIST' &&
        setting.type === SectionsDynamicTypes.accordion,
    );

    const sideFilter = setting?.children
      ?.find(
        settingItem =>
          settingItem.titleTKey ===
          TOTAL_ACTIONS_RULES_TRANSLATIONS_CODES[ModelActionRuleCodeValues.national],
      )
      ?.children?.find(child => child.type === SectionsDynamicTypes.actionsList)
      ?.filter?.find(setting => setting[0] === ModelActionFields.sideCode);

    if (!sideFilter) return actionsList;

    return actionsList.filter(action =>
      action[ModelActionFields.ruleCode] === ActionRuleCode.national
        ? handleFiltering([action], [sideFilter]).length > 0
        : true,
    );
  }, [actionsList, settings]);
}
