import {
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonItem_Type_Values,
} from '@hypercharge/xdms-client/lib/types';

/**
 * Determines whether record is package or not
 *
 * @param record {Model_CommonItem} - record
 * @returns {boolean}
 */
export const isPackage = (
  record: Record<string, unknown>,
): record is Model_CommonItem => {
  return record[Model_CommonItemFields.type] === Model_CommonItem_Type_Values.package;
};
