import React, { FC, ReactNode, useMemo } from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<PopconfirmProps, 'title'> {
  variant?: 'error';
  title?: string;
}

const PopConfirm: FC<Props> = props => {
  const { t } = useTranslation();

  const icon = useMemo<ReactNode | undefined>(() => {
    if (props.icon) return props.icon;
    switch (props.variant) {
      case 'error':
        return <WarningOutlined style={{ color: 'red' }} />;
      default:
        return undefined;
    }
  }, [props.variant, props.icon]);

  return (
    <Popconfirm
      title={t('ARE_YOU_SURE')}
      icon={icon}
      okText={t('YES')}
      okButtonProps={{
        type: 'text',
      }}
      cancelText={t('NO')}
      cancelButtonProps={{
        type: 'text',
      }}
      {...props}
    />
  );
};

export default PopConfirm;
