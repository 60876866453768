import React from 'react';

export const bubblingPreventer = e => {
  e.stopPropagation();
};

export default ({ children, ...props }) => (
  <div {...props} onClick={e => e.stopPropagation()}>
    {children}
  </div>
);
