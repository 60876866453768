import {
  ConfigurationListItem,
  ConfigurationStatusListItem,
  Configuration_CreateCopies_Input,
  Configuration_CreateCopies_Output,
  Configuration_CreateNewVersion_Input,
  Configuration_CreateNewVersion_Output,
  Configuration_Create_Input,
  Configuration_Create_Output,
  Configuration_GetDetailsById_Input,
  Configuration_GetDetailsById_Output,
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields,
  Configuration_GetStatusList_Input,
  Configuration_GetStatusList_Output,
  Configuration_SetStatus_Input,
  Configuration_Update_Input,
  Configuration_Update_Output,
  Message,
  OfferItemFields,
  PriceListFields,
  StructureFields,
  StructureLabel,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, Filter } from 'types/vendor';
import {
  CLIENTS_URL,
  MODELS_URL,
  STORAGE_KEYS,
  TOTAL_URL,
  URL_QUERY_PARAMS,
} from 'common/constants';
import { useRelations } from 'context/relations/RelationsProvider';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { get } from 'utils';
import { notification } from 'utils/notification';
import { saveInLocalStorage } from 'utils/storage';
import { Status } from 'utils/types';
import { RequestResponseDetails } from '../../types/common';
import { getTtParams } from '../../utils/format';
import { ParsedQuery, UrlTransform } from '../../utils/urlTransform';
import { decodeModelNumber } from '../model/utils/modelNumber';
import { filterSubStepsLabels } from '../step/utils';
import { useStructureApi } from 'context/structure/useStructureApi';
import { configurationUpdate_EncodeInputParams } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelectors,
  configurationActions,
  configurationSelectors,
  sharedSelectors,
} from 'store';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfDetailsFields } from '@hypercharge/xdms-client/lib/configuration/types';
import {
  ConfigurationListItemFields,
  Configuration_CreateFromMach_Input,
  Configuration_CreateFromMach_Output,
} from '@hypercharge/xdms-client/src/configuration';
import { ConfigurationRelation } from 'context/relations/types';

type ContextValue = {
  getConfigurationStatusCodes(): Promise<ConfigurationStatusListItem[] | null>;
  searchConfigurations(
    filter?: Filter<ConfigurationListItemFields>,
  ): Promise<ConfigurationListItem[]>;
  configurationStatusCodes: ConfigurationStatusListItem[] | null;
  unsetConfigurationProvider(): void;
  getConfigurationStatusList(
    args: Configuration_GetStatusList_Input,
  ): Promise<Configuration_GetStatusList_Output | null>;
  statusList: Configuration_GetStatusList_Output | null;
  setConfigurationStatus(params: Configuration_SetStatus_Input): Promise<void>;
  getConfigurationDetailsById(
    params: Configuration_GetDetailsById_Input,
  ): Promise<Configuration_GetDetailsById_Output | null>;
  /** Same as {@link getConfigurationDetailsById} except it doesn't set {@link configurationDetails} */
  loadConfigurationDetailsById(
    params: Configuration_GetDetailsById_Input,
  ): Promise<Configuration_GetDetailsById_Output | null>;
  // + frequently used constants from 'configurationDetails'
  configurationsListUrl: string;
  // - frequently used constants from 'configurationDetails'
  isNewConfiguration: boolean;
  copyConfiguration(
    args: Configuration_CreateCopies_Input,
  ): Promise<Configuration_CreateCopies_Output | null>;
  createNewVersion(
    args: Configuration_CreateNewVersion_Input,
  ): Promise<Configuration_CreateNewVersion_Output | null>;
  createConfiguration(
    params: Configuration_Create_Input,
  ): Promise<RequestResponseDetails<Configuration_Create_Output | null>>;
  createFromMachine(
    params: Configuration_CreateFromMach_Input,
  ): Promise<RequestResponseDetails<Configuration_CreateFromMach_Output | null>>;
  updateConfiguration(
    params?: Omit<Configuration_Update_Input, 'relations'>,
  ): Promise<Configuration_Update_Output | null>;
  updateConfigurationById(
    configurationNumber: number | string,
    params: Omit<Configuration_Update_Input, 'relations'> & {
      relations: ConfigurationRelation[];
    },
  ): Promise<Configuration_Update_Output | null>;
  restoreConfigurationProvider(
    configurationNumber: string | number,
  ): Promise<Configuration_GetDetailsById_Output | null>;
  updateConfigurationByExternalToken(
    token: string | number,
    configurationNumber: string | number,
  ): Promise<RequestResponseDetails>;
  createConfigurationFromExternalToken(
    token: string | number,
    customerNumber: string | number,
  ): Promise<
    RequestResponseDetails<{
      message: Message[] | null;
      configurationNumber: string | number | null;
    }>
  >;
  assembleConfigurationUrlByConfigurationNumber(
    configurationNumber: number | string,
  ): Promise<string>;
};
export type ConfigurationContextValue = ContextValue;
const ConfigurationContext = React.createContext<ContextValue | undefined>(undefined);

const ConfigurationProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();
  const { relations } = useRelations();
  const { getStructure } = useStructureApi();

  const username = useSelector(authSelectors.getUsername);
  const { configurationNumber, modelNumber, configurationDetails } = useSelector(
    configurationSelectors.getAll,
  );
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const setStatus = useCallback(
    (status: Status) => {
      dispatch(configurationActions.setStatus(status));
    },
    [dispatch],
  );

  const [configurationStatusCodes, setConfigurationStatusCodes] = useState<
    ConfigurationStatusListItem[] | null
  >(null);
  const [statusList, setStatusList] = useState<Configuration_GetStatusList_Output | null>(
    null,
  );

  const isNewConfiguration = useMemo<ContextValue['isNewConfiguration']>(
    () => !configurationDetails,
    [configurationDetails],
  );

  const configurationsListUrl = useMemo<string>(() => {
    const newQuery = UrlTransform.stringifyQuery({
      [URL_QUERY_PARAMS.configurationNumber]: configurationNumber,
    });
    let url = '/configurations';
    if (configurationNumber) url += `?${newQuery}`;
    return url;
  }, [configurationNumber]);

  const searchConfigurations = useCallback<ContextValue['searchConfigurations']>(
    async (filter): Promise<ConfigurationListItem[]> => {
      let offers: ConfigurationListItem[] = [];
      if (!xdmsClient) return offers;
      dispatch(configurationActions.setConfigurationsListStatus(Status.Loading));
      try {
        offers = await xdmsClient.configuration.getList(filter);
        dispatch(configurationActions.setConfigurationsListStatus(Status.Success));
      } catch (e) {
        dispatch(configurationActions.setConfigurationsListStatus(Status.Error));
        notification.requestError(e);
      }
      return offers;
    },
    [dispatch, xdmsClient],
  );

  const getConfigurationStatusList = useCallback(
    async (
      params: Configuration_GetStatusList_Input,
    ): Promise<Configuration_GetStatusList_Output | null> => {
      let statusList: Configuration_GetStatusList_Output | null = null;
      if (!xdmsClient) return null;
      setStatus(Status.Loading);
      try {
        statusList = await xdmsClient.configuration.getStatusList(params);
        setStatusList(statusList);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return statusList;
    },
    [setStatus, xdmsClient],
  );

  const setConfigurationStatus = useCallback<ContextValue['setConfigurationStatus']>(
    async (params: Configuration_SetStatus_Input): Promise<void> => {
      const { configurationNumber, status, reason, sellDate } = params;
      setStatus(Status.Loading);
      if (!xdmsClient) return;

      try {
        const { message } = await xdmsClient.configuration.setStatus({
          configurationNumber,
          status,
          reason,
          sellDate,
        });

        notification.open({ message: message });

        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }
    },
    [setStatus, xdmsClient],
  );

  const getConfigurationStatusCodes = useCallback<
    ContextValue['getConfigurationStatusCodes']
  >(async (): Promise<ConfigurationStatusListItem[] | null> => {
    const result = null;
    if (!xdmsClient) return result;

    try {
      const offerCodes = await xdmsClient.configurationStatus.getList();
      setConfigurationStatusCodes(offerCodes);
      setStatus(Status.Success);

      return offerCodes;
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return result;
  }, [setStatus, xdmsClient]);

  const getConfigurationDetailsById = useCallback(
    async (
      params: Configuration_GetDetailsById_Input,
    ): Promise<Configuration_GetDetailsById_Output | null> => {
      let configurationDetails: Configuration_GetDetailsById_Output | null = null;
      setStatus(Status.Loading);
      if (!xdmsClient) return configurationDetails;

      try {
        configurationDetails = await xdmsClient.configuration.getDetailsById(params);
        dispatch(configurationActions.setConfigurationDetails(configurationDetails));
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return configurationDetails;
    },
    [dispatch, setStatus, xdmsClient],
  );

  const loadConfigurationDetailsById = useCallback(
    async (
      params: Configuration_GetDetailsById_Input,
    ): Promise<Configuration_GetDetailsById_Output | null> => {
      let configurationDetails: Configuration_GetDetailsById_Output | null = null;
      if (!xdmsClient) return configurationDetails;
      setStatus(Status.Loading);

      try {
        configurationDetails = await xdmsClient.configuration.getDetailsById(params);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return configurationDetails;
    },
    [setStatus, xdmsClient],
  );

  const copyConfiguration = useCallback(
    async (
      args: Configuration_CreateCopies_Input,
    ): Promise<Configuration_CreateCopies_Output | null> => {
      let response: Configuration_CreateCopies_Output | null = null;
      if (!xdmsClient) return response;
      setStatus(Status.Loading);

      try {
        response = await xdmsClient.configuration.createCopies(args);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return response;
    },
    [setStatus, xdmsClient],
  );

  const createNewVersion = useCallback(
    async (
      args: Configuration_CreateNewVersion_Input,
    ): Promise<Configuration_CreateNewVersion_Output | null> => {
      let response: Configuration_CreateNewVersion_Output | null = null;
      setStatus(Status.Loading);

      try {
        response = await xdmsClient.configuration.createNewVersion(args);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return response;
    },
    [setStatus, xdmsClient.configuration],
  );

  const createConfiguration = useCallback<ContextValue['createConfiguration']>(
    async (
      params: Configuration_Create_Input,
    ): Promise<RequestResponseDetails<Configuration_Create_Output | null>> => {
      let result: RequestResponseDetails<Configuration_Create_Output | null>;
      setStatus(Status.Loading);

      const offerData: Configuration_Create_Input = {
        ...params,
        [ConfDetailsFields.withVAT]: shouldShowPricesWithVAT,
      };

      try {
        const response = await xdmsClient.configuration.create(offerData);
        setStatus(Status.Success);
        const configurationId: string | null = get(
          response,
          'createdConfigurationNumber',
        );

        if (configurationId) {
          await getConfigurationDetailsById({ configurationNumber: configurationId });
          saveInLocalStorage(STORAGE_KEYS.configurationNumber, configurationId);
          result = { status: Status.Success, messageHandled: false, response: response };
        } else {
          if (response?.message?.length) {
            notification.open({ message: response.message });
            result = { status: Status.Error, messageHandled: true };
          } else {
            result = { status: Status.Error, messageHandled: false };
          }
        }
      } catch (e) {
        notification.requestError(e, setStatus);
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [
      getConfigurationDetailsById,
      setStatus,
      shouldShowPricesWithVAT,
      xdmsClient.configuration,
    ],
  );

  const createFromMachine = useCallback<ContextValue['createFromMachine']>(
    async params => {
      let result: RequestResponseDetails<Configuration_CreateFromMach_Output | null> = {
        status: Status.Idle,
        response: null,
      };

      try {
        setStatus(Status.Loading);
        const response = await xdmsClient.configuration.createFromMachine(params);

        result.status = Status.Success;
        result.response = response;

        const message = get(response, 'message', []);

        if (message?.length) {
          notification.open({ message: message });
          result.messageHandled = true;
        }

        setStatus(Status.Success);

        return result;
      } catch (e) {
        result = { status: Status.Error, messageHandled: true };
        notification.requestError(e, setStatus);
      }

      return result;
    },
    [setStatus, xdmsClient.configuration],
  );

  const updateConfiguration = useCallback<ContextValue['updateConfiguration']>(
    async params => {
      let response: Configuration_Update_Output | null = null;

      if (!configurationDetails) return response;

      const configurationNumber: number | undefined = get(
        configurationDetails,
        `configuration.${ConfigurationDetailsFields.configurationNumber}`,
      );
      if (!configurationNumber) return response;

      try {
        setStatus(Status.Loading);

        const newOfferData = configurationUpdate_EncodeInputParams({
          relations,
          ...params,
        });

        newOfferData[ConfDetailsFields.withVAT] = shouldShowPricesWithVAT;

        response = await xdmsClient.configuration.update(
          configurationNumber,
          newOfferData,
        );
        await getConfigurationDetailsById({
          configurationNumber: configurationNumber,
        });
        const message = get(response, 'message', []);
        notification.open({ message: message });
        setStatus(Status.Success);

        return response;
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return response;
    },
    [
      setStatus,
      configurationDetails,
      relations,
      shouldShowPricesWithVAT,
      xdmsClient.configuration,
      getConfigurationDetailsById,
    ],
  );

  /** Update configuration by id without updating state */
  const updateConfigurationById = useCallback<ContextValue['updateConfigurationById']>(
    async (configurationNumber, data) => {
      let response: Configuration_Update_Output | null = null;

      try {
        const newOfferData = configurationUpdate_EncodeInputParams(data);

        newOfferData[ConfDetailsFields.withVAT] = shouldShowPricesWithVAT;

        response = await xdmsClient.configuration.update(
          configurationNumber,
          newOfferData,
        );
        if (response.message?.length) {
          notification.open({ message: response.message });
        }
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return response;
    },
    [setStatus, shouldShowPricesWithVAT, xdmsClient.configuration],
  );

  const updateConfigurationByExternalToken = useCallback<
    ContextValue['updateConfigurationByExternalToken']
  >(
    async (token, configurationNumber) => {
      let result: RequestResponseDetails = { status: Status.Idle };

      try {
        setStatus(Status.Loading);
        const response = await xdmsClient.configuration.updateByExternalCode(
          token,
          configurationNumber,
        );

        result = { status: Status.Success };
        setStatus(Status.Success);

        const message = get(response, 'message', []);
        if (message?.length) {
          notification.open({ message: message });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e, setStatus);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [setStatus, xdmsClient.configuration],
  );

  const createConfigurationFromExternalToken = useCallback<
    ContextValue['createConfigurationFromExternalToken']
  >(
    async (token, customerNumber) => {
      let result: RequestResponseDetails<{
        message: Message[] | null;
        configurationNumber: string | number | null;
      }> = { status: Status.Idle };

      try {
        setStatus(Status.Loading);
        const response = await xdmsClient.configuration.createFromExternalCode(
          token,
          customerNumber,
        );

        result = { status: Status.Success, response: response };
        setStatus(Status.Success);

        const message = get(response, 'message', []);
        if (message?.length) {
          notification.open({ message: message });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e, setStatus);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [setStatus, xdmsClient.configuration],
  );

  const assembleConfigurationUrlByConfigurationNumber = useCallback<
    ContextValue['assembleConfigurationUrlByConfigurationNumber']
  >(
    async anotherConfigurationNumber => {
      const configurationDetailsResponse = await getConfigurationDetailsById({
        configurationNumber: anotherConfigurationNumber,
      });

      const configurationDetails = get(configurationDetailsResponse, 'configuration');

      const modelId: string = get(configurationDetails, OfferItemFields.catalogCode, '');
      const modelNumber: string = get(
        configurationDetails,
        OfferItemFields.modelNumber,
        '',
      );

      const structureParams = getTtParams({
        [CustomerFields.id]: username,
        [PriceListFields.catalog]: get(configurationDetails, OfferItemFields.catalogCode),
      });
      const structure = await getStructure(structureParams);
      const subStepsLabels: StructureLabel[] = filterSubStepsLabels(
        get(structure, StructureFields.labels, []),
      );
      const parsedModelQueryParams = decodeModelNumber(modelNumber, subStepsLabels);
      const finishedStepsCount: number = Object.keys(parsedModelQueryParams).length;

      const newQuery: ParsedQuery = { ...parsedModelQueryParams };

      newQuery[URL_QUERY_PARAMS.configurationNumber] = String(anotherConfigurationNumber);

      const queryString: string = UrlTransform.stringifyQuery(newQuery);
      let redirectUrl = '/';

      if (finishedStepsCount < subStepsLabels.length) {
        redirectUrl = MODELS_URL;
      } else if (finishedStepsCount && finishedStepsCount === subStepsLabels.length) {
        redirectUrl = TOTAL_URL;
      } else if (finishedStepsCount === 0 && modelId) {
        redirectUrl = CLIENTS_URL;
      }

      return `${redirectUrl}?${queryString}`;
    },
    [username, getConfigurationDetailsById, getStructure],
  );

  const unsetConfigurationProvider = useCallback<
    ContextValue['unsetConfigurationProvider']
  >(() => {
    dispatch(configurationActions.setConfigurationDetails(null));
  }, [dispatch]);

  const restoreConfigurationProvider = useCallback<
    ContextValue['restoreConfigurationProvider']
  >(
    configurationNumber => {
      return getConfigurationDetailsById({ configurationNumber: configurationNumber });
    },
    [getConfigurationDetailsById],
  );

  // need to watch for model number change and based on that update the model name
  useEffect(() => {
    if (configurationNumber) {
      getConfigurationDetailsById({ configurationNumber });
    }
  }, [configurationNumber, getConfigurationDetailsById, modelNumber]);

  const value = useMemo(
    () => ({
      createConfiguration,
      configurationStatusCodes,
      getConfigurationStatusCodes,
      unsetConfigurationProvider,
      searchConfigurations,
      getConfigurationStatusList,
      setConfigurationStatus,
      statusList,
      getConfigurationDetailsById,
      loadConfigurationDetailsById,
      isNewConfiguration,
      copyConfiguration,
      createNewVersion,
      createFromMachine,
      updateConfiguration,
      updateConfigurationById,
      configurationsListUrl,
      restoreConfigurationProvider,
      updateConfigurationByExternalToken,
      createConfigurationFromExternalToken,
      assembleConfigurationUrlByConfigurationNumber,
    }),
    [
      createConfiguration,
      configurationStatusCodes,
      getConfigurationStatusCodes,
      searchConfigurations,
      unsetConfigurationProvider,
      getConfigurationStatusList,
      setConfigurationStatus,
      statusList,
      getConfigurationDetailsById,
      loadConfigurationDetailsById,
      isNewConfiguration,
      copyConfiguration,
      createNewVersion,
      createFromMachine,
      updateConfiguration,
      updateConfigurationById,
      configurationsListUrl,
      restoreConfigurationProvider,
      updateConfigurationByExternalToken,
      createConfigurationFromExternalToken,
      assembleConfigurationUrlByConfigurationNumber,
    ],
  );

  return <ConfigurationContext.Provider value={value} {...props} />;
};

const useConfiguration = (): ContextValue => {
  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error(
      `${useConfiguration.name} must be used within an ${ConfigurationProvider.name}`,
    );
  }

  return context;
};

export { ConfigurationProvider, useConfiguration };
