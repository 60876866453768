import { useField } from 'formik';
import React, { ComponentProps, ElementType, useMemo } from 'react';
import { getTitle } from 'utils/get-title';
import { ScFormItem } from './FormField.styles';

type CustomProps = {
  name: string;
  label?: string;
  help?: string;
  required?: boolean;
  withErrors?: boolean;
};

type Props<C extends ElementType> = ComponentProps<C> & { component: C } & CustomProps;

function FormField<C extends ElementType>({
  component: Component,

  name,
  label,
  required = false,
  withErrors = true,

  ...props
}: Props<C>) {
  const [field, meta] = useField({ name });

  const error = useMemo<string | undefined>(() => {
    if (!withErrors) return;
    // sometimes meta.error returns `{}`, and it conflicts with it's type `string | undefined`
    // so we ignore it
    if (typeof meta.error === 'object') return;
    return meta.error;
  }, [meta.error, withErrors]);

  return (
    <ScFormItem
      labelAlign="left"
      required={required}
      hasFeedback={Boolean(error)}
      help={error || props.help}
      validateStatus={error ? 'error' : undefined}
      label={label ? getTitle(label, { required }) : ''}
    >
      <Component {...field} {...props} name={name} />
    </ScFormItem>
  );
}

export default FormField;
