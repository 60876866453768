import cn from 'classnames';
import { useCurrency } from 'context/currency/CurrencyProvider';
import React, { DetailedHTMLProps, FC, TableHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatPrice, Style } from 'utils/format';

interface Props
  extends DetailedHTMLProps<TableHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  value: number;
  currency?: string;
  className?: string;
  priceStyle?: Style;
  discount?: boolean;
  percentage?: boolean;
  'data-testid'?: string;
}
export type PriceProps = Props;
const ScPrice = styled.div`
  font-size: 0.875em;
  word-break: break-word;

  &.discount {
    color: ${({ theme }) => theme.discountColor};
  }
`;

export const Price: FC<Props> = ({
  value,
  className,
  priceStyle,
  discount,
  percentage,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { currencyCode } = useCurrency();

  // ref override fixes typescript error
  return (
    <ScPrice
      data-testid="price-display-field"
      {...props}
      ref={() => props.ref}
      className={cn(className, { discount })}
    >
      {discount && t('DISCOUNT')}{' '}
      {formatPrice({
        price: value,
        locale: i18n.language,
        currency: currencyCode,
        style: percentage ? 'percent' : priceStyle,
      })}
    </ScPrice>
  );
};
