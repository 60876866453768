import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'assets/icons/edit-not-filled.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export const ScWrapper = styled.span`
  height: ${({ theme }) => theme.inputHeight};
  display: contents;
`;

export const ScContentContainer = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  min-width: 0;
  position: relative;
`;

export const ScInputContainer = styled.span<{ $isVisible: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  transition: none;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  z-index: 1;
`;

export const ScButtonContainer = styled.span<{ $isEditing: boolean }>`
  width: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  margin-left: ${({ $isEditing }) => ($isEditing ? '0.75em' : '0')};
`;

export const ScEditButton = styled(EditIcon)`
  cursor: pointer;
  font-size: 1.6em;
  color: ${({ theme }) => theme.mainColor};
`;

export const ScCloseButton = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.softPeach};
  cursor: pointer;
`;
