import React, { FC, useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { isNil } from 'utils';
import { CustomerFields, CustomerItem } from 'types/vendor';
import { customerContactFormatName } from 'utils/format';
import { Status } from 'utils/types';
import { ConfigurationRelation } from 'context/relations/types';
import { useTranslation } from 'react-i18next';
import { notification } from 'utils/notification';
import { useRelations } from 'context/relations/RelationsProvider';
import { SelectCustomerModal } from 'components/customer/SelectCustomerModal';
import { useCustomer } from 'context/customer/CustomerProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { Button } from 'components/button';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { FormikHelpers } from 'formik';
import { checkFormValuesDiffersFromRecord } from 'utils/check-form-values-differs-from-record';
import CustomerForm from 'components/customer/CustomerForm';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import { getGlobalFeatureFlag_RelationCustomerAddEnabled } from 'context/relations/utils';
import { useSelector } from 'react-redux';
import { configurationSelectors, modelSelectors } from 'store';
import { useProspectDossierApi } from 'context/prospectDossier/useProspectDossierApi';

interface Props {
  relation: ConfigurationRelation;
  goForward(): void;
  goFarForward(): void;
}
export const RelationItemCustomer: FC<Props> = props => {
  const { relation, goForward, goFarForward } = props;

  const { t } = useTranslation();
  const { setRelationCustomer } = useRelations();
  const { loadCustomersList, updateCustomer } = useCustomer();
  const { updateProspectDossierCustomerByIdx } = useProspectDossierApi();
  const { updateConfiguration, isNewConfiguration } = useConfiguration();
  const { isFeatureEnabled } = useFeature();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);
  const configurationDetails = useSelector(configurationSelectors.getConfiguration);
  const { configurationProspectDossierId } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const [isModalSelectVisible, setIsModalSelectVisible] = useState<boolean>(false);

  const isUpdateConfigurationOnClientUpdateFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationUpdateOnClientUpdate,
  });
  const isAddNewItemFeatureEnabled = isFeatureEnabled({
    feature: getGlobalFeatureFlag_RelationCustomerAddEnabled(relation.idx + 1),
  });
  const isUpdateDossierCustomerFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowUpdateDossierCustomer,
  });

  /** Initially open select modal if relation's customer not filled */
  useEffect(() => {
    if (relation.mandatory && !relation.customer) setIsModalSelectVisible(true);
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(
    async (records: CustomerItem[]) => {
      const record = records[0];

      if (!record) return;

      setRelationCustomer(relation.id, record);

      if (record && isNil(record[CustomerFields.lang])) {
        notification.open({
          message: t('NO_LANGUAGE_CODE_ERROR', { entity: t('CLIENTS_END_USER') }),
        });
      }
    },
    [relation.id, setRelationCustomer, t],
  );

  const handleUpdateProspectDossier = useCallback(
    async (
      prospectDossierId: number,
      values: CustomerItem,
    ): Promise<{ status: Status }> => {
      const { status, messageHandled } = await updateProspectDossierCustomerByIdx(
        relation.idx,
        prospectDossierId,
        values,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('CLIENTS_PROSPECT_DOSSIER_UPDATE_SUCCESS'),
          [Status.Error]: t('CLIENTS_PROSPECT_DOSSIER_UPDATE_ERROR'),
        });
      }

      return { status: status };
    },
    [updateProspectDossierCustomerByIdx, t, relation],
  );

  const handleUpdateRecord = useCallback(
    async (
      values: CustomerItem,
    ): Promise<{ status: Status; response: CustomerItem | null }> => {
      const customerId: number | undefined = values[CustomerFields.id];

      if (!customerId) return { status: Status.Error, response: null };

      const { status, messageHandled, response } = await updateCustomer(
        customerId,
        values,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('CLIENTS_CLIENT_UPDATED'),
          [Status.Error]: t('CLIENTS_CLIENT_UPDATE_ERROR'),
        });
      }

      return { status: status, response: response ?? null };
    },
    [updateCustomer, t],
  );

  const handleConfirm = useCallback(
    async (values: CustomerItem, actions: FormikHelpers<CustomerItem>) => {
      actions.setSubmitting(false); // to have loader on form to be hidden, as we have global loader

      const isRecordUpdated = checkFormValuesDiffersFromRecord(relation.customer, values);
      const isDifferentRecord =
        String(relation.customer?.[CustomerFields.id]) !==
        String(
          configurationDetails?.[relation.fields.customerIdFromConfigurationDetails],
        );
      const isDossierUpdateEnabled =
        isUpdateDossierCustomerFeatureEnabled && configurationProspectDossierId;
      const isConfigurationUpdateNeeded =
        isUpdateConfigurationOnClientUpdateFeatureEnabled && isRecordUpdated;

      if (isRecordUpdated) {
        const { status, response } = await handleUpdateRecord(values);

        if (isDossierUpdateEnabled && response) {
          await handleUpdateProspectDossier(configurationProspectDossierId, response);
        }

        if (status === Status.Success) {
          await handleSelect(response ? [response] : []);
        }
      }

      if ((isDifferentRecord || isConfigurationUpdateNeeded) && !isNewConfiguration) {
        await updateConfiguration();

        // dont update dossier, if it was already updated above
        if (!isRecordUpdated && isDossierUpdateEnabled) {
          await handleUpdateProspectDossier(configurationProspectDossierId, values);
        }
      }

      if (isNewConfiguration) goForward();
    },
    [
      configurationDetails,
      configurationProspectDossierId,
      goForward,
      handleSelect,
      handleUpdateProspectDossier,
      handleUpdateRecord,
      isNewConfiguration,
      isUpdateConfigurationOnClientUpdateFeatureEnabled,
      isUpdateDossierCustomerFeatureEnabled,
      relation.customer,
      relation.fields.customerIdFromConfigurationDetails,
      updateConfiguration,
    ],
  );

  const handleSkipThisStep = useCallback(async () => {
    if (!isNewConfiguration) await updateConfiguration();
    goFarForward();
  }, [updateConfiguration, goFarForward, isNewConfiguration]);

  const deselectRecord = () => setRelationCustomer(relation.id, null);

  const handleSelectionClick = useCallback(() => {
    setIsModalSelectVisible(true);
  }, []);

  const renderSelectionInput = () => (
    <FormField
      readOnly={isConfigurationComplete}
      disabled={isConfigurationComplete}
      name="selection"
      data-testid="selection-value-Customer"
      onClick={isConfigurationComplete ? undefined : handleSelectionClick}
      placeholder={t('SELECT_CUSTOMER')}
      label={relation.customer ? t('CLIENT') : ''}
      component={Input}
      value={customerContactFormatName(
        relation.customer?.[CustomerFields.name],
        relation.customer?.[CustomerFields.name2],
      )}
      suffix={
        <Tooltip
          placement="top"
          title={t('CLIENT_DESELECT_ITEM')}
          data-testid="customer-page-client-block-search-tab-deselect-tooltip"
        >
          <CloseOutlined
            onClick={isConfigurationComplete ? undefined : deselectRecord}
            data-testid="customer-page-client-block-search-tab-deselect-icon-button"
          />
        </Tooltip>
      }
    />
  );

  return (
    <>
      <SelectCustomerModal
        initialValues={relation.customer ? [relation.customer] : []}
        onSelect={handleSelect}
        isOpen={isModalSelectVisible}
        setIsOpen={setIsModalSelectVisible}
        isAddEnabled={isAddNewItemFeatureEnabled}
      />

      <CustomerForm
        plainIdx={relation.idx + 1}
        header={renderSelectionInput()}
        initialValues={relation.customer}
        onSubmit={handleConfirm}
        reloadEntities={loadCustomersList}
        readonly={isConfigurationComplete}
      />

      {!relation.customer && !relation.mandatory && (
        <Button
          onClick={handleSkipThisStep}
          fullwidth
          variant="primary"
          data-testid="relation_customerForm_skipButton"
        >
          {t('SKIP_AND_GO')}
        </Button>
      )}
    </>
  );
};
