import React from 'react';
import { Checkbox as VendorCheckbox } from 'antd';
import VendorCheckboxGroup from 'antd/lib/checkbox/Group';
import styled, { css } from 'styled-components';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { svgToBase64 } from 'utils/svgToBase64';

const checkmarkIconString = `url("${svgToBase64(<CheckmarkIcon />)}")`;

export const checkBox = css`
  &.ant-radio,
  &.ant-checkbox,
  .ant-radio,
  .ant-checkbox {
    font-size: inherit;
    font-weight: 600;

    .ant-checkbox-inner {
      border-radius: 0;
      border: 1px solid ${({ theme }) => theme.mainBorderColor};
      width: 1.5em;
      height: 1.5em;

      &:hover {
        border-radius: 1em;
      }
    }

    &.ant-checkbox-checked {
      &:after {
        border: none;
        border-radius: 1em;
      }

      .ant-checkbox-inner {
        &::after {
          border: none;
          top: 0;
          left: 0;
          transform: none;
          transition: none;
          width: 1.4375em;
          height: 1.4375em;
          background: none;
          mask: ${checkmarkIconString} no-repeat center center;
          background-color: ${({ theme }) => theme.colors.black};
        }
      }
    }

    &.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.mainColor};
        &::after {
          background-color: ${({ theme }) => theme.mainColor_contrast};
        }
      }
    }
  }
`;

export const ScCheckboxGroup = styled(VendorCheckboxGroup)`
  ${checkBox}
  /* to make list vertical */
  .ant-checkbox-wrapper {
    display: block;
  }
`;

export const ScCheckbox = styled(VendorCheckbox)<{ $circle: boolean }>`
  &.ant-checkbox-wrapper {
    ${checkBox}

    && {
      .ant-checkbox,
      .ant-radio {
        .ant-checkbox-inner {
          border-radius: ${({ $circle }) => ($circle ? '50%' : '0%')};
        }
      }
    }
  }
`;
