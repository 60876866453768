import { ModelCategoryFields } from 'types/vendor';
import useInconfiguredCategory from './useInconfiguredCategory';

export default function useRequiredCategory(): string | undefined {
  const { inconfiguredCategories } = useInconfiguredCategory();

  // taking color category name as the only required category for enabling preview
  const colorCategoryName = Object.values(inconfiguredCategories)
    .flat()
    .find(category => {
      return category?.[ModelCategoryFields.staticId].includes('Colour');
    });

  const requiredCategoryName = colorCategoryName
    ? colorCategoryName[ModelCategoryFields.name]
    : undefined;

  return requiredCategoryName;
}
