import { MessageCodeValues } from '@hypercharge/xdms-client/lib/types';
import { IconType as AntdNotificationType } from 'antd/lib/notification';
import { NotificationType } from './types';
import { Status } from '../types';

/**
 * External - from BE
 * */
export const EXTERNAL_NOTIFICATION_TYPE__MAPPING__NOTIFICATION_TYPE: {
  [key in MessageCodeValues]: NotificationType;
} = {
  [MessageCodeValues.error]: NotificationType.error,
  [MessageCodeValues.info]: NotificationType.info,
  [MessageCodeValues.info2]: NotificationType.info,
  [MessageCodeValues.success]: NotificationType.success,
  [MessageCodeValues.warning]: NotificationType.warning,
};

export const NOTIFICATION_TYPE__MAPPING__LIBRARY_NOTIFICATION_TYPE: {
  [key in NotificationType]: AntdNotificationType;
} = {
  [NotificationType.error]: 'error',
  [NotificationType.info]: 'info',
  [NotificationType.success]: 'success',
  [NotificationType.warning]: 'warning',
};

export const STATUS__MAPPING__NOTIFICATION_TYPE: {
  [key in keyof Partial<typeof Status>]: NotificationType;
} = {
  [Status.Error]: NotificationType.error,
  [Status.Success]: NotificationType.success,
};
