import styled, { css } from 'styled-components';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

export const ScItemHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4em 0;
`;
export const ScItemHeaderActions = styled.div`
  margin-left: auto;
`;

export const ScItemHeaderName = styled.div`
  font-size: 0.75em;
`;

export const ScItemCheckbox = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border: ${({ theme, disabled = false }) =>
    `1px solid ${disabled ? '#d9d9d9' : theme.colors.black}`};
  background-color: ${({ theme, checked, disabled = false }) => {
    if (disabled) {
      return '#f5f5f5';
    }

    if (checked) {
      return theme.mainColor;
    }

    return theme.colors.white;
  }};
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ScItemCheckboxContent = styled.span<{
  disabled?: boolean;
}>`
  font-size: 0.75em;
  font-weight: 600;
  color: ${({ theme, disabled = false }) =>
    disabled ? theme.colors.black : theme.mainColor_contrast};
`;

const commonCaretStyles = css`
  font-size: 0.8em;
`;

export const ScCaretDownOutlined = styled(CaretDownOutlined)`
  ${commonCaretStyles}
`;

export const ScCaretRightOutlined = styled(CaretRightOutlined)`
  ${commonCaretStyles}
`;
