import { Model_CommonCategory } from '@hypercharge/xdms-client/lib/types';
import { CATEGORIES_ORDER } from 'common/constants';

export type Path = {
  [k in typeof CATEGORIES_ORDER[number]]?: string;
};

export const getCategoriesOnLvl = (
  path: Path,
  categoriesList: Model_CommonCategory[],
): Model_CommonCategory[] => {
  const idx = CATEGORIES_ORDER.findIndex(field => path[field]) + 1;

  const fieldsToHaveValueSameAsOnPath = CATEGORIES_ORDER.slice(0, idx);
  const targetLvlFieldToHaveAnyValue = CATEGORIES_ORDER[idx];
  const nextLvlsFieldsToBeEmpty = CATEGORIES_ORDER.slice(
    idx + 1,
    CATEGORIES_ORDER.length,
  );

  return categoriesList.filter(category => {
    return (
      fieldsToHaveValueSameAsOnPath.every(field => category[field] === path[field]) &&
      category[targetLvlFieldToHaveAnyValue] &&
      nextLvlsFieldsToBeEmpty.every(field => !category[field])
    );
  });
};
