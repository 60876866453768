import {
  Model,
  ModelRecord,
  ModelRecordFields,
  ModelAction,
  ModelActionFields,
  ModelFields,
  ModelTotalsItem,
  ModelActionSideValues,
} from 'types/vendor';
import { get } from 'utils';
import { TOTAL_DEALER_ACTION_CODE } from 'common/constants';

export const sortSelectedByDefaultAsFirst = <Item extends ModelRecord>(
  a: Item,
  b: Item,
): number => {
  const aValue = get(a, ModelRecordFields.selectedByDefault);
  const bValue = get(b, ModelRecordFields.selectedByDefault);

  return aValue === bValue ? 0 : aValue ? 1 : -1;
};

/**
 * Extract delta/dealer action from model
 * @param {Model} model
 * @return {ModelAction | undefined}
 */
export const extractDealerAction = (model: Model): ModelAction | undefined => {
  const allItems = get(model, ModelFields.actions, []);
  return allItems.find(item => {
    const action = item[ModelActionFields.actionCode];
    const side = item[ModelActionFields.sideCode];

    return action === TOTAL_DEALER_ACTION_CODE && side === ModelActionSideValues.sale;
  });
};

/**
 * Extract totals data from model
 * @param {Model} model
 * @return {TotalsItem}
 */
export const extractTotalData = (model: Model): ModelTotalsItem => {
  return get(model, [ModelFields.totals, 0]);
};
