import { PaginationProps as AntdPaginationProps } from 'antd/lib/pagination';
import React, { FC, useCallback, useEffect, useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_CURRENT_TABLE_PAGE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_TABLE_PAGE_SIZE,
} from 'utils/constants';
import { ScPagination, ScPaginationContent, ScTableFooter } from './styles';

interface Props extends AntdPaginationProps {
  current?: number;
  to?: number;
  from?: number;
  onResize(size: number): void;
  showSizeChanger?: boolean;
}

export const Pagination: FC<PropsWithChildren<Props>> = ({
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  defaultCurrent = DEFAULT_CURRENT_TABLE_PAGE,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  total = 1,
  onChange,
  onResize,
  children,
  from,
  to,
  showSizeChanger = true,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrent);
  const [paginationSettings, setPaginationSettings] = useState({
    pageSize,
    defaultCurrent,
    total,
    pageSizeOptions,
  });
  const onPaginationChange = useCallback(
    (page, pageSize) => {
      setCurrentPage(page);
      setPaginationSettings({
        ...paginationSettings,
        total,
      });

      if (onChange) {
        onChange(page, pageSize);
      }
    },
    [total, onChange, paginationSettings],
  );

  const onPageSizeChange = useCallback(
    (_, size) => {
      onResize?.(size);
    },
    [onResize],
  );

  useEffect(() => {
    setPaginationSettings({
      ...paginationSettings,
      pageSize,
      total,
      pageSizeOptions,
    });
  }, [total, currentPage, pageSize, pageSizeOptions]);

  return (
    <ScTableFooter role="navigation">
      <div className="pagination-info" data-testid="configuration-table-pagination-info">
        {t('TABLE_SHOWING', {
          from,
          to,
          total,
        })}
      </div>
      <ScPagination
        onChange={onPaginationChange}
        onShowSizeChange={onPageSizeChange}
        showSizeChanger={showSizeChanger}
        {...paginationSettings}
        {...otherProps}
        data-testid="configuration-table-pagination-btns"
      />
      <ScPaginationContent>{children}</ScPaginationContent>
    </ScTableFooter>
  );
};
