import React, { FC } from 'react';
import { TotalsItem } from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';
import { Price } from 'components/Price';
import { TotalPageAdaptedFields } from '../../utils';
import { TFunction } from 'i18next';
import { ScTitlesList, ScTitlesListItem } from './TItle.styles';

interface Props {
  totalData: TotalsItem | null;
  modelId: string | null;
  modelName: string | null;
  FIELDS: TotalPageAdaptedFields;
  t: TFunction;
  isShowHODFeatureEnabled: boolean;
}

const Title: FC<Props> = ({
  totalData,
  modelId,
  modelName,
  FIELDS,
  t,
  isShowHODFeatureEnabled,
}) => {
  return (
    <ScTitlesList data-testid="total-page-model">
      <ScTitlesListItem>{modelName}</ScTitlesListItem>
      <ScTitlesListItem>{modelId}</ScTitlesListItem>

      <ScTitlesListItem>
        {t('TOTAL_LIST_PRICE')}
        {': '}
        <Price
          style={{ display: 'inline' }}
          value={get(totalData, FIELDS.listPrice, 0)}
          data-testid="total-page-model_listPrice"
        />
      </ScTitlesListItem>

      {isShowHODFeatureEnabled && (
        <ScTitlesListItem>
          {t('TOTAL_HOD')}
          {': '}
          <Price style={{ display: 'inline' }} value={get(totalData, FIELDS.HOD, 0)} />
        </ScTitlesListItem>
      )}
    </ScTitlesList>
  );
};

export default Title;
