export enum StreamingEventType {
  INITIALIZE = 'initialize',
  LIST_ALL_TERMINALS = 'listAllTerminals',
  START_STREAMING_FOR_TERMINAL = 'startStreamingForTerminal',
  STOP_STREAMING_FOR_TERMINAL = 'stopStreamingForTerminal',
  EMIT_SLOT_CHANGE = 'emitSlotChange',
  GENERATE_CUSTOMER_ID = 'generateCustomerId',
  RESUME = 'resume',
  DELETE_TERMINAL = 'deleteTerminal',
  ABORT_SESSION_FOR_TERMINAL = 'abortSessionForTerminal',
  LOGOUT_TERMINAL = 'logoutTerminal',
  CHANGE_SCREEN = 'changeScreen',
  GET_CURRENT_STATE = 'getCurrentState',
  CLEAR_SLOTS = 'clearSlots',
  UPDATE_TERMINAL_FLAGS = 'updateTerminalFlags',
  PING = 'ping',
}

export enum StreamingOptionType {
  option = 'OPTION',
  accessory = 'ACCESSORY',
  package = 'PACKAGE',
}

export enum StreamingStatus {
  VACANT = 'vacant',
  BUSY = 'busy',
  OFFLINE = 'offline',
}

export type EventError = {
  status: number;
  message: string;
  name: string;
  notify: boolean;
};

export enum SummaryFieldType {
  local = 'local',
  national = 'national',
}

export type SummaryField = {
  name: string;
  value: number;
  currency: string;
  sign?: string;
  type: SummaryFieldType | null;
};
