import * as Sentry from '@sentry/react';

export const runIfNotAborted = (e: Error, callback: () => void): void => {
  // @todo: rely on more solid condition
  // as the error name of abort error might
  // depend from browser to browser.
  // Instead it'd be good to have AbortController signal.aborted check
  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/aborted
  if (e.name !== 'AbortError') {
    Sentry.captureException(e);
    callback();
  }
};
