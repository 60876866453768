import { useCallback } from 'react';
import {
  API_XDMS_LOGIN_URL,
  INDEX_URL,
  URL_QUERY_PARAMS,
  XDMS_CLIENT_ENVIRONMENT_CONSTANTS,
} from 'common/constants';
import { login as xdmsClientLogin, LoginResponse } from '@hypercharge/xdms-client';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { RequestResponseDetails } from 'types/common';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/auth';
import { useQuery } from '../router/UrlQueryProvider';
import { pick } from 'utils';
import { UrlTransform } from 'utils/urlTransform';
import { useTranslation } from 'react-i18next';

interface ContextValue {
  logout(
    config?: {
      keepUsername?: boolean;
      keepConfigurationNumber?: boolean;
      instant?: boolean;
    },
    callback?: () => void,
  ): void;
  login(values: {
    host: string;
    username: string;
    password: string;
  }): Promise<RequestResponseDetails<Omit<LoginResponse, 'client'> | undefined>>;
}

const useAuthApi = (): ContextValue => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { query } = useQuery();

  const confirmLogout = useCallback((): boolean => {
    return window.confirm(t('AUTH_LOGOUT_CONFIRMATION'));
  }, [t]);

  const logout = useCallback<ContextValue['logout']>(
    (config, callback) => {
      if (!config?.instant && !confirmLogout()) return;

      callback?.();

      dispatch(authActions.clear(config));
      const queryValuesToKeep = pick(query, [
        URL_QUERY_PARAMS.hostLock,
        URL_QUERY_PARAMS.host,
      ]);

      window.location.replace(
        UrlTransform.stringifyUrl({
          url: INDEX_URL,
          query: queryValuesToKeep,
        }),
      );
    },
    [confirmLogout, dispatch, query],
  );

  const login = useCallback<ContextValue['login']>(
    async values => {
      let result: RequestResponseDetails<Omit<LoginResponse, 'client'> | undefined>;

      try {
        dispatch(authActions.setAuthStatus(Status.Loading));

        const loginResponse = await xdmsClientLogin({
          host: values.host,
          username: values.username,
          password: values.password,
          tenantUrlProviderUrl: API_XDMS_LOGIN_URL,
          environmentConstants: XDMS_CLIENT_ENVIRONMENT_CONSTANTS,
        });

        dispatch(authActions.setAuthStatus(Status.Success));

        result = {
          status: Status.Success,
          messageHandled: false,
          response: loginResponse,
        };
      } catch (e) {
        notification.requestError(e);
        dispatch(authActions.setAuthStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [dispatch],
  );

  return { login, logout };
};

export { useAuthApi };
