import {
  ModelAction,
  ModelActionFields,
  ModelActionSignValues,
  ModelTotalsItem,
} from 'types/vendor';
import { get, toNumber } from '../../../utils';
import { Money } from '../../../utils/money';
import { TotalPageAdaptedFields } from './fieldsMapping';

export const handleChangeBalance = (
  newBalance: number,
  dealerAction: ModelAction,
  FIELDS: TotalPageAdaptedFields,
  totalData: ModelTotalsItem,
): ModelAction | undefined => {
  const newDealerAction = { ...dealerAction };

  const oldSign = get(newDealerAction, FIELDS.dealerAction.sign);
  const oldDiscount = toNumber(get(newDealerAction, FIELDS.dealerAction.amount, 0));
  const oldBalance = toNumber(get(totalData, FIELDS.balance, 0));

  if (oldBalance === newBalance) return;

  const mNewDiscount = new Money(oldBalance);
  switch (oldSign) {
    case ModelActionSignValues.positive:
      mNewDiscount.subtract(oldDiscount);
      break;

    case ModelActionSignValues.negative:
      mNewDiscount.add(oldDiscount);
      break;
  }

  mNewDiscount.subtract(newBalance);

  const newDiscount = mNewDiscount.value;
  const newSign =
    newDiscount >= 0 ? ModelActionSignValues.negative : ModelActionSignValues.positive;

  if (oldDiscount === newDiscount && oldSign === newSign) return;

  newDealerAction[FIELDS.dealerAction.sign as string] = newSign;
  newDealerAction[FIELDS.dealerAction.amount as string] = Math.abs(newDiscount);
  newDealerAction[FIELDS.dealerAction.amountReverse as string] = 0;
  newDealerAction[FIELDS.dealerAction.percent as string] = 0;
  newDealerAction[FIELDS.dealerAction.isSelected as string] = newDiscount !== 0;

  return newDealerAction;
};

export const handleChangeAmount = (
  value: number,
  dealerAction: ModelAction,
  FIELDS: TotalPageAdaptedFields,
  shouldShowPricesWithVAT: boolean,
): ModelAction | undefined => {
  const newDealerAction = { ...dealerAction };

  if (newDealerAction[FIELDS.dealerAction.amount as string] === value) return;
  newDealerAction[FIELDS.dealerAction.amount as string] = value;
  newDealerAction[FIELDS.dealerAction.amountReverse as string] = 0;
  newDealerAction[FIELDS.dealerAction.percent as string] = 0;
  newDealerAction[FIELDS.dealerAction.isSelected as string] = value > 0;

  // edge case: when setting included discount to 0 - it doesn't update, due to BE logic with 0.
  // BE logic with 0: value, which is 0 - is ignored, another value (which have value) is being set
  if (!value && shouldShowPricesWithVAT) {
    newDealerAction[ModelActionFields.amount_withVat] = 0;
    newDealerAction[ModelActionFields.amount] = 0;
  }

  return newDealerAction;
};

export const handleChangePercent = (
  value: number,
  dealerAction: ModelAction,
  FIELDS: TotalPageAdaptedFields,
): ModelAction | undefined => {
  const newDealerAction = { ...dealerAction };

  if (newDealerAction[FIELDS.dealerAction.percent as string] === value) return;
  newDealerAction[FIELDS.dealerAction.percent as string] = value;
  newDealerAction[FIELDS.dealerAction.amount as string] = 0;
  newDealerAction[FIELDS.dealerAction.isSelected as string] = value > 0;

  return newDealerAction;
};
