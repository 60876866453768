import React, { FC } from 'react';
import { GlobalStyle } from './global';
import { GlobalStyleFont } from './fonts';

const GlobalStyles: FC = () => {
  return (
    <>
      <GlobalStyle />
      <GlobalStyleFont />
    </>
  );
};

export default GlobalStyles;
