export enum Sign {
  equals = '==',
  notEquals = '!=',
}

export const sign__mapping__operation: Record<Sign, (a: unknown, b: unknown) => boolean> =
  {
    [Sign.equals]: (a, b) => a === b,
    [Sign.notEquals]: (a, b) => a !== b,
  };

/** [field, sign, value] */
export type FilterItem = [string, Sign, unknown];

export const handleFiltering = <T = unknown>(
  recordsList: T[],
  filter: FilterItem[],
): T[] => {
  return recordsList.filter(record => {
    const results = filter.map(([field, sign, value]) => {
      const recordValue = record[field];
      const operation = sign__mapping__operation[sign];

      return operation(value, recordValue);
    });

    return results.every(Boolean);
  });
};
