import { Button } from './button';
import styled from 'styled-components';

// extracted these styles from 'components/table/styles'
// as they are being used as separate layout build set
// and weren't been used in table

export const ScModalTitle = styled.div`
  text-align: center;
  padding: 1.5em 1.5625em;
  border-bottom: 1px solid rgb(191, 191, 191);
`;

export const ScModalFooter = styled.div`
  display: flex;
  text-align: center;
  padding: 1.25em 1em;
`;

export const ScModalFooterButton = styled(Button)`
  flex: 1;

  &:last-of-type {
    margin-left: 0.438em;
  }
`;
