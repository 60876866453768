import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModelAction, ModelActionRuleCodeValues } from 'types/vendor';
import { get } from 'utils';
import { TotalTableInput } from '../TotalTableInput';
import { TOTAL_PROMOTIONS__RULE_CODE__mapping__STATIC_NAME_FIELD_PRIORITY } from 'common/constants';
import {
  ScCheckbox,
  ScSelectColumnWrapper,
  ScTotalTableRow,
  ScNameInput,
  ScBruttoPrice,
} from './ActionItem.styles';
import { TotalPageAdaptedFields } from '../../utils';
import { sizes } from 'common/theme';
import { useMediaQuery } from 'react-responsive';
import { TotalTextEllipsis } from 'pages/total/components/TotalTextEllipsis';
import ActionItemSignToggle from './ActionItemSignToggle';
import { TFunction } from 'i18next';
import { TotalTableRowProps } from '../TotalTableRow';
import TotalEditableField from '../TotalEditableField';

type ActionDealerFieldsNames =
  | 'checkbox'
  | 'name'
  | 'description'
  | 'itemNumber'
  | 'quantity'
  | 'sign'
  | 'discountAmount'
  | 'discountPercent'
  | 'total';

interface Props extends Omit<TotalTableRowProps, 'fields'> {
  t: TFunction;
  record: ModelAction;
  FIELDS: TotalPageAdaptedFields;
  currency: string;
  updatePercent?(record: ModelAction, value: number): void;
  updateAmount?(record: ModelAction, value: number): void;
  updateName?(record: ModelAction, value: string): void;
  toggleSign?(record: ModelAction): void;
  toggleCheck?(record: ModelAction): void;
  disabled?: {
    percent?: boolean;
    amount?: boolean;
    check?: boolean;
    sign?: boolean;
    name?: boolean;
  };
  showBottomBorder?: boolean;
  description?: string;
  /** @todo: Maybe add some mapping. Like in useColumns hooks. */
  fieldsToHide?: ActionDealerFieldsNames[] | string[];
  maxDiscountAmountValue?: number | undefined;
}

const ActionItem: FC<Props> = ({
  t,
  record,
  updatePercent,
  updateAmount,
  updateName,
  toggleCheck,
  toggleSign,
  FIELDS,
  disabled,
  showBottomBorder,
  currency,
  description,
  fieldsToHide,
  maxDiscountAmountValue,
  ...props
}) => {
  const isSelected = record[FIELDS.actions.selected];
  const itemNumber = record[FIELDS.actions.itemNumber];
  const sign = record[FIELDS.actions.sign];
  const isSignEditable = record[FIELDS.actions.signEditable];

  // used for actions whose name may be updated
  const name = get(record, FIELDS.actions.name, '');

  // used for actions whose name is static
  const staticName = useMemo<string>(() => {
    const rule = record[FIELDS.actions.rule];
    const code = record[FIELDS.actions.code];

    const fieldsSet =
      TOTAL_PROMOTIONS__RULE_CODE__mapping__STATIC_NAME_FIELD_PRIORITY[rule] ??
      TOTAL_PROMOTIONS__RULE_CODE__mapping__STATIC_NAME_FIELD_PRIORITY[
        ModelActionRuleCodeValues.local
      ];

    const fieldWithValue = fieldsSet.find(field => record[field]);
    if (!fieldWithValue) return code;

    return record[fieldWithValue];
  }, [FIELDS.actions.code, FIELDS.actions.rule, record]);

  const [amount, setAmount] = useState<number>(0);
  const [percent, setPercent] = useState<number>(0);

  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  useEffect(() => {
    setAmount(get(record, FIELDS.actions.amount, 0));
    setPercent(get(record, FIELDS.actions.percent, 0));
  }, [FIELDS, record]);

  const disabledStates = useMemo(() => {
    return {
      checkbox: disabled?.check || !toggleCheck,
      amountInput: disabled?.amount || !updateAmount,
      percentInput: disabled?.percent || !updatePercent,
      sign: disabled?.sign || !isSignEditable || !toggleSign,
      name: disabled?.name || !updateName || !isSelected,
    };
  }, [
    isSelected,
    disabled,
    isSignEditable,
    toggleCheck,
    toggleSign,
    updateAmount,
    updatePercent,
    updateName,
  ]);

  const isFieldVisible = useCallback<(field: ActionDealerFieldsNames) => boolean>(
    field => {
      return !fieldsToHide?.includes(field);
    },
    [fieldsToHide],
  );

  // ref override fixes typescript error
  return (
    <ScTotalTableRow
      key={itemNumber}
      data-testid="total-page-action"
      shadeNumbers
      shiftFirstColumn
      borderBottom={showBottomBorder ? 'thick' : undefined}
      {...props}
      ref={() => props.ref}
      fields={[
        <ScSelectColumnWrapper key="checkbox">
          {isFieldVisible('checkbox') && (
            <ScCheckbox
              checked={isSelected}
              onChange={() => toggleCheck?.(record)}
              disabled={disabledStates.checkbox}
            />
          )}

          {isFieldVisible('name') && !disabledStates.name && (
            <TotalEditableField
              component={ScNameInput}
              value={name}
              formatValueForStaticView={value => (
                <TotalTextEllipsis>{value}</TotalTextEllipsis>
              )}
              onChange={value => updateName?.(record, String(value))}
              componentProps={{
                autoSize: true,
                autoFocus: true,
              }}
            />
          )}

          {isFieldVisible('name') && disabledStates.name && (
            <TotalTextEllipsis>{staticName}</TotalTextEllipsis>
          )}
        </ScSelectColumnWrapper>,
        isFieldVisible('description') && description ? (
          <TotalTextEllipsis>{description}</TotalTextEllipsis>
        ) : undefined,
        isFieldVisible('itemNumber') ? (
          <TotalTextEllipsis>{itemNumber}</TotalTextEllipsis>
        ) : undefined,
        undefined,
        isFieldVisible('sign') ? (
          <ActionItemSignToggle
            active={!disabledStates.sign}
            t={t}
            onClick={() => toggleSign?.(record)}
            value={sign}
          />
        ) : undefined,
        isFieldVisible('discountAmount') ? (
          <TotalTableInput
            size="middle"
            value={amount}
            step={1}
            max={maxDiscountAmountValue}
            onBlur={event => {
              updateAmount?.(record, event.target.value);
            }}
            onChange={event => setAmount(event.target.value)}
            testNamePrefix="amount"
            disabled={disabledStates.amountInput}
            inputPrefix={isLgAndWider ? currency : undefined}
          />
        ) : undefined,
        isFieldVisible('discountPercent') ? (
          <TotalTableInput
            size="middle"
            max={100}
            step={1}
            value={percent}
            testNamePrefix="percent"
            onBlur={event => {
              updatePercent?.(record, event.target.value);
            }}
            onChange={event => setPercent(event.target.value)}
            inputPrefix={isLgAndWider ? '%' : undefined}
            symbolsAfterDecimal={10} // take all of them
            disabled={disabledStates.percentInput}
          />
        ) : undefined,
        isFieldVisible('total') ? (
          <>
            {sign + ' '}
            <ScBruttoPrice value={amount} />
          </>
        ) : undefined,
      ]}
    />
  );
};

export default ActionItem;
