import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined';
import {
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import {
  Model,
  Model_CommonItem_DisabledCode_Values,
} from '@hypercharge/xdms-client/lib/types';
import { Tooltip } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { SortOrder as AntdSortOrder } from 'antd/lib/table/interface';
import { DynamicStepFeaturesFlags } from 'common/dynamicPagesFeaturesFlags';
import { bubblingPreventer } from 'components/table/BubblingPreventer';
import CommentIcons from 'components/table/CommentIcons';
import { ExtendedImageTableItem } from 'components/table/ExtendedImageTableItem';
import ImageButton from 'components/table/ImageButton';
import {
  ScDropdown,
  ScMenu,
  ScMenuItem,
  ScTableDiscount,
  ScTableEdit,
} from 'components/table/styles';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useQuery } from 'context/router/UrlQueryProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import {
  PAGES_SETTINGS,
  useTableCategories,
} from 'context/tableCategories/TableCategoriesProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debounce, get, orderBy } from 'utils';
import { NAME_TAB_ALL } from 'utils/constants';
import { formatNumber, formatPrice } from 'utils/format';
import { notification } from 'utils/notification';
import { getExtendedImage } from 'utils/table-images';
import { CommentsModalCallParams } from './Modals/CommentsModal';
import { DiscountModalCallParams } from './Modals/DiscountModal';
import { EditPriceModalCallParams } from './Modals/EditPriceModal/EditPriceModal';
import {
  PackageLinesModalCallParams,
  packageLineTablesToSearchForIncompatible,
} from './Modals/PackageLinesModal';
import { featuresFlagsSelectors, modelSelectors, sharedSelectors } from 'store';
import { Status } from 'utils/types';
import { DotsIcon } from '../ConfigurationList';
import {
  ScBadge,
  ScBadgeContainer,
  ScCheckbox,
  ScInputNumber,
  ScMenuItemContent,
} from './index.styles';
import {
  checkRecordIncompatibility,
  getRecordIncompatibilityMessage,
  isPackage,
  sortNumericValues,
  sortStringValues,
} from './utils';
import { TableColumn } from 'components/table';
import { ScIcon } from './use-columns.styles';
import { ScColumnTitle } from 'components/styles';
import { StreamingEventType } from 'context/streaming/types';
import { getSelectedTableItems } from 'utils/get-selected-table-items';
import { isStandardOption } from './utils/is-standard-option';

const SELECT_OPTION_CODE = {
  disable: Model_CommonItem_DisabledCode_Values.mandatory,
  enable: Model_CommonItem_DisabledCode_Values.empty,
};

const columnKey = {
  SELECTION: 'Selection',
  EXTENDED_IMAGE: 'ExtendedImage',
  CATEGORY: Model_CommonItemFields.categoryCode,
  DESCRIPTION: Model_CommonItemFields.name,
  COMMENTS: 'Comments',
  PAPERCLIP: 'Attachments',
  PHOTO: 'Photo',
  ID: Model_CommonItemFields.ID,
  AMOUNT: Model_CommonItemFields.defaultAmount,
  GROSS_PRICE: Model_CommonItemFields.grossPrice,
  DISCOUNT: Model_CommonItemFields.discountPercent,
  TOTAL_PRICE: Model_CommonItemFields.totalSellingPrice,
  ACTIONS: 'Operation',
};

type SortOrder = AntdSortOrder | undefined;
const SORT_VALUES_ORDER: SortOrder[] = ['ascend', 'descend', undefined];

type MenuItem = {
  item: React.ReactNode;
  column: string | null;
  order: number;
};

type Params = {
  handleCommentsModal(callParams?: CommentsModalCallParams): void;
  handleDocumentsModal(callParams?: { option: Model_CommonItem }): void;
  handleViewImages(option?: Model_CommonItem): Promise<void>;
  handleSelectRecord(record: Model_CommonItem, toSelect: boolean): void;
  handleEditPriceModal(callParams?: EditPriceModalCallParams): void;
  handleDiscountModal(callParams?: DiscountModalCallParams): void;
  handlePackageLinesModal(callParams?: PackageLinesModalCallParams): void;
  stepId: keyof typeof PAGES_SETTINGS;
  pathname: string;
  isTableViewExtended: boolean;
};

export const useColumns = (params: Params): TableColumn<Model_CommonItem>[] => {
  const { t, i18n } = useTranslation();

  const [title, setTitle] = useState<string>(
    t('TABLE_SELECTION_DISABLED_UNKNOWN_REASON'),
  );

  const {
    handleCommentsModal,
    handleDocumentsModal,
    handleViewImages,
    stepId,
    handleSelectRecord,
    handleEditPriceModal,
    handleDiscountModal,
    handlePackageLinesModal,
    isTableViewExtended = false,
  } = params;
  const { isFeatureEnabled } = useFeature();

  const { model, isConfigurationCreatedFromStock, isConfigurationComplete, modelRules } =
    useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const currentPageFeatures = useSelector(featuresFlagsSelectors.getCurrentPageFeatures);
  const columnsSettings = currentPageFeatures?.columnsList;

  const { query } = useQuery();
  const { currencyCode } = useCurrency();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();
  const { getCategoriesHierarchy } = useTableCategories();

  const isManageDocumentsFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowManageDocuments',
    source: FeatureSource.DYNAMIC,
  });

  const isAllowDisableForStockConfiguration = isFeatureEnabled({
    feature: 'YNallowDisableForStockConfiguration',
    source: FeatureSource.DYNAMIC,
  });

  const isAllowUseExternalDisabledValueFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowUseExternalDisabledValue',
    source: FeatureSource.DYNAMIC,
  });
  const isAllowStandardOptionAmountAlwaysChangeFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowStandardOptionAmountAlwaysChange',
    source: FeatureSource.DYNAMIC,
  });

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const PRICE_FIELDS = useMemo(() => {
    return {
      TOTAL_PRICE: shouldShowPricesWithVAT
        ? Model_CommonItemFields.nettoPriceWithVat
        : Model_CommonItemFields.totalSellingPrice,
      GROSS_PRICE: shouldShowPricesWithVAT
        ? Model_CommonItemFields.grossPriceVat
        : Model_CommonItemFields.grossPrice,
    };
  }, [shouldShowPricesWithVAT]);

  const [canShowCategory, setCanShowCategory] = useState<boolean>(true);

  const { MODEL_TABLE_ITEMS_FIELDS, MODEL_UPDATE_ATTRIBUTES, SELECTED_CATEGORY_NAME } =
    PAGES_SETTINGS[stepId];

  const isSelectDisabled = useCallback(
    (record: Model_CommonItem, model: Model | null): boolean => {
      if (isConfigurationComplete) return true;
      if (isAllowDisableForStockConfiguration && isConfigurationCreatedFromStock)
        return true;

      const code = get(
        record,
        MODEL_TABLE_ITEMS_FIELDS.disabledCode,
        SELECT_OPTION_CODE.enable,
      );
      if (code === SELECT_OPTION_CODE.disable) return true;

      if (isAllowUseExternalDisabledValueFeatureEnabled)
        return !record[Model_CommonItemFields.isChangeable];

      return checkRecordIncompatibility({
        record,
        modelRules,
        tableItems: getSelectedTableItems({
          model,
          tables: packageLineTablesToSearchForIncompatible,
        }),
        model,
      });
    },
    [
      MODEL_TABLE_ITEMS_FIELDS.disabledCode,
      isAllowDisableForStockConfiguration,
      isConfigurationComplete,
      isConfigurationCreatedFromStock,
      isAllowUseExternalDisabledValueFeatureEnabled,
      modelRules,
    ],
  );

  const getSelectionTooltip = useCallback(
    (record: Model_CommonItem, model: Model | null) => {
      if (isAllowUseExternalDisabledValueFeatureEnabled) {
        const isSelected = record[Model_CommonItemFields.selected];
        return t(isSelected ? 'TABLE_DESELECTION_DISABLED' : 'TABLE_SELECTION_DISABLED');
      }

      return (
        getRecordIncompatibilityMessage({
          record,
          modelRules,
          tableItems: getSelectedTableItems({
            model,
            tables: packageLineTablesToSearchForIncompatible,
          }),
          t,
          model,
        }) ?? t('TABLE_SELECTION_DISABLED_UNKNOWN_REASON')
      );
    },
    [t, isAllowUseExternalDisabledValueFeatureEnabled, modelRules],
  );

  const shouldDisableDetails = useCallback((record: Model_CommonItem): boolean => {
    return isPackage(record) && !record[Model_CommonItemFields.selected];
  }, []);

  useEffect(() => {
    const isActiveTabAll = get(query, SELECTED_CATEGORY_NAME, '') === NAME_TAB_ALL;
    setCanShowCategory(Boolean(isActiveTabAll && model));
  }, [SELECTED_CATEGORY_NAME, model, query]);

  const handleAddAsOptional = useCallback(
    async (option: Model_CommonItem): Promise<void> => {
      const isDefaultOption = get(option, MODEL_TABLE_ITEMS_FIELDS.optional);
      const isOptionSelected = get(option, MODEL_TABLE_ITEMS_FIELDS.selected);

      if (!model) return;

      const { status, messageHandled } = await updateModel(model, {
        [MODEL_UPDATE_ATTRIBUTES]: {
          ...option,
          [MODEL_TABLE_ITEMS_FIELDS.optional]: !isDefaultOption,
          [MODEL_TABLE_ITEMS_FIELDS.selected]: isDefaultOption ? isOptionSelected : true,
        },
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: { token: modelToken },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('UPDATE_SUCCESSFUL'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }
    },
    [
      MODEL_TABLE_ITEMS_FIELDS.optional,
      MODEL_TABLE_ITEMS_FIELDS.selected,
      model,
      updateModel,
      MODEL_UPDATE_ATTRIBUTES,
      sendMessage,
      modelToken,
      t,
    ],
  );

  const debounceUpdateModel = debounce(async (model, option, fieldName, fieldValue) => {
    const { messageHandled, status, response } = await updateModel(model, {
      [MODEL_UPDATE_ATTRIBUTES]: {
        ...option,
        [fieldName]: fieldValue,
      },
    });

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }

    // move focus from input
    // fix: displayed in input value doesn't change when new value arrived in state and user still focused on input
    (document.activeElement as HTMLElement | undefined)?.blur();

    if (!messageHandled) {
      notification.openByStatus(status, {
        [Status.Success]: t('UPDATE_SUCCESSFUL'),
        [Status.Error]: t('GLOBAL_ERROR_TEXT'),
      });
    }

    return response;
  }, 1000);

  const handleChangeAmount = useCallback(
    (amount: number, record: Model_CommonItem) => {
      if (!get(record, MODEL_TABLE_ITEMS_FIELDS.selected, false)) {
        handleSelectRecord(record, true);
      }

      debounceUpdateModel(model, record, MODEL_TABLE_ITEMS_FIELDS.defaultAmount, amount);
    },
    [
      model,
      MODEL_TABLE_ITEMS_FIELDS.selected,
      MODEL_TABLE_ITEMS_FIELDS.defaultAmount,
      debounceUpdateModel,
      handleSelectRecord,
    ],
  );

  const getMenuItemComment = useCallback(
    (row: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleCommentsModal({ option: row })}
            data-testid="dynamic-step-table-item-action-comment"
            key="action-comment"
          >
            <ScMenuItemContent>
              {get(row, MODEL_TABLE_ITEMS_FIELDS.internalCommentDescription) ||
              get(row, MODEL_TABLE_ITEMS_FIELDS.externalCommentDescription)
                ? t('EDIT_COMMENT')
                : t('CONFIG_LIST_ADD_COMMENT')}
            </ScMenuItemContent>
          </ScMenuItem>
        ),
        column: columnKey.COMMENTS,
        order: 1,
      };
    },
    [
      MODEL_TABLE_ITEMS_FIELDS.externalCommentDescription,
      MODEL_TABLE_ITEMS_FIELDS.internalCommentDescription,
      handleCommentsModal,
      t,
    ],
  );

  const getMenuItemOptional = useCallback(
    (row: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleAddAsOptional(row)}
            data-testid="dynamic-step-table-item-action-add-as-optional"
            disabled={isConfigurationComplete}
            key="action-mark-as-optional"
          >
            <ScMenuItemContent>
              {get(row, MODEL_TABLE_ITEMS_FIELDS.optional)
                ? t('CONFIG_LIST_REMOVE_AS_OPTIONAL')
                : t('CONFIG_LIST_ADD_AS_OPTIONAL')}
            </ScMenuItemContent>
          </ScMenuItem>
        ),
        column: null,
        order: 2,
      };
    },
    [MODEL_TABLE_ITEMS_FIELDS.optional, handleAddAsOptional, isConfigurationComplete, t],
  );

  const getMenuItemDetails = useCallback(
    (record: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleEditPriceModal({ option: record })}
            data-testid="dynamic-step-table-item-action-edit-price"
            disabled={isConfigurationComplete || shouldDisableDetails(record)}
            key="action-detaiils"
          >
            <Tooltip
              placement="left"
              title={
                shouldDisableDetails(record) ? t('MANAGE_DETAILS_SELECTION_REQUIRED') : ''
              }
            >
              <ScMenuItemContent>{t('CONFIG_LIST_EDIT_PRICE')}</ScMenuItemContent>
            </Tooltip>
          </ScMenuItem>
        ),
        column: null,
        order: 4,
      };
    },
    [handleEditPriceModal, isConfigurationComplete, shouldDisableDetails, t],
  );

  const getMenuItemDiscount = useCallback(
    (row: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleDiscountModal({ option: row })}
            data-testid="dynamic-step-table-item-action-discount"
            disabled={isConfigurationComplete}
            key="action-discount"
          >
            <ScMenuItemContent>{t('CONFIG_LIST_ADD_DISCOUNT')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        column: columnKey.DISCOUNT,
        order: 3,
      };
    },
    [handleDiscountModal, t, isConfigurationComplete],
  );

  const getMenuItemDocuments = useCallback(
    (row: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleDocumentsModal({ option: row })}
            data-testid="dynamic-step-table-item-action-add-document"
            key="action-document"
          >
            <ScMenuItemContent>{t('CONFIG_LIST_ADD_DOCUMENT')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        column: columnKey.PAPERCLIP,
        order: 5,
      };
    },
    [handleDocumentsModal, t],
  );

  const getMenuItemPackageLines = useCallback(
    (row: Model_CommonItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handlePackageLinesModal({ option: row, isSelectHidden: true })}
            data-testid="dynamic-step-table-item-action-show-package-lines"
            disabled={isConfigurationComplete}
            key="action-package-lines"
          >
            <ScMenuItemContent>{t('CONFIG_LIST_VIEW_PACKAGE_LINES')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        column: null,
        order: 6,
      };
    },
    [handlePackageLinesModal, isConfigurationComplete, t],
  );

  const getMenuItems = useCallback(
    row => {
      const flags = currentPageFeatures;
      const result: MenuItem[] = [];
      if (!flags || flags['YNallowAddAsOptional']) {
        result.push(getMenuItemOptional(row));
      }

      if (!flags || flags['YNallowAddComment']) {
        result.push(getMenuItemComment(row));
      }

      if (!flags) {
        result.push(getMenuItemDetails(row));
      }

      if (
        flags?.['YNallowManageDetails'] &&
        flags['YNallowManageDetailsOnlySelfMade'] &&
        row[Model_CommonItemFields.manuallyAdded]
      ) {
        result.push(getMenuItemDetails(row));
      }
      if (flags?.['YNallowManageDetails'] && !flags['YNallowManageDetailsOnlySelfMade']) {
        result.push(getMenuItemDetails(row));
      }

      if (!flags || flags['YNallowManageDiscount']) {
        result.push(getMenuItemDiscount(row));
      }

      if (!flags || flags['YNallowManageDocuments']) {
        result.push(getMenuItemDocuments(row));
      }

      if (!flags || flags['YNallowViewDetails']) {
        result.push(getMenuItemPackageLines(row));
      }

      return result;
    },
    [
      currentPageFeatures,
      getMenuItemComment,
      getMenuItemDetails,
      getMenuItemDiscount,
      getMenuItemDocuments,
      getMenuItemOptional,
      getMenuItemPackageLines,
    ],
  );

  const menu = useCallback(
    (row: Model_CommonItem) => {
      if (!currentPageFeatures) {
        const menuItems = getMenuItems(row);
        const orderedMenuItems = orderBy(menuItems, 'order', ['asc']);

        return {
          menuRenderNode: (
            <ScMenu
              onClick={({ domEvent }) => bubblingPreventer(domEvent)}
              data-testid="dynamic-step-table-item-actions"
            >
              {orderedMenuItems.map(menuItem => menuItem.item)}
            </ScMenu>
          ),
          menuLength: orderedMenuItems.length,
        };
      }

      let menuItems: MenuItem[] = getMenuItems(row);

      if (columnsSettings) {
        const availableColumns = columnsSettings.reduce((res, { name }) => {
          res[name] = true;
          return res;
        }, {});
        menuItems = menuItems.filter(menuItem => {
          if (!menuItem.column) {
            return true;
          }
          return availableColumns[menuItem.column];
        });
      }

      menuItems = orderBy(menuItems, 'order', ['asc']);

      return {
        menuRenderNode: (
          <ScMenu
            onClick={({ domEvent }) => bubblingPreventer(domEvent)}
            data-testid="dynamic-step-table-item-actions"
          >
            {menuItems.map(menuItem => menuItem.item)}
          </ScMenu>
        ),
        menuLength: menuItems.length,
      };
    },
    [currentPageFeatures, getMenuItems, columnsSettings],
  );

  const [sortState, setSortState] = useState<Record<string, SortOrder>>({});

  const toggleSort = useCallback((columnKey: string) => {
    setSortState(sortState => {
      const currentValue = sortState[columnKey] as SortOrder;
      const currentValueIdx = SORT_VALUES_ORDER.indexOf(currentValue);
      if (currentValueIdx === -1) return sortState;

      const nextValue: SortOrder =
        currentValueIdx >= SORT_VALUES_ORDER.length - 1
          ? SORT_VALUES_ORDER[0]
          : SORT_VALUES_ORDER[currentValueIdx + 1];

      return {
        ...sortState,
        [columnKey]: nextValue,
      };
    });
  }, []);

  useEffect(() => {
    const newSortState = (columnsSettings ?? []).reduce(
      (acc, { sort, name }) => ({ ...acc, [name]: sort }),
      {},
    );
    setSortState(newSortState);
  }, [columnsSettings]);

  // reason of minWidth defined in {@link Table component minWidth property}
  const keyToColumn: Record<
    string,
    (settings: DynamicStepFeaturesFlags['columnsList'][0]) => ColumnType<Model_CommonItem>
  > = useMemo(() => {
    return {
      [columnKey.SELECTION]: () => ({
        title: '',
        dataIndex: columnKey.SELECTION,
        key: columnKey.SELECTION,
        width: '3em',
        render: (_value, record) => {
          const isDisabled = isSelectDisabled(record, model);

          return (
            <ScBadgeContainer data-testid="dynamic-step-table-item-select-btn">
              <Tooltip
                placement="bottomLeft"
                title={isDisabled ? title : ''}
                onVisibleChange={() => {
                  if (!isDisabled) return;

                  setTitle(getSelectionTooltip(record, model));
                }}
              >
                {/* need wrapper div otherwise tooltip does not interact with disabled checkbox */}

                <div>
                  <ScCheckbox
                    onChange={() =>
                      handleSelectRecord(record, !record[Model_CommonItemFields.selected])
                    }
                    checked={record[Model_CommonItemFields.selected]}
                    disabled={isDisabled}
                  />
                </div>
              </Tooltip>
              {get(record, MODEL_TABLE_ITEMS_FIELDS.optional, false) && (
                <ScBadge data-testid="dynamic-step-table-item-marked-as-optional" />
              )}
            </ScBadgeContainer>
          );
        },
      }),
      [columnKey.EXTENDED_IMAGE]: () => ({
        title: '',
        dataIndex: columnKey.EXTENDED_IMAGE,
        key: columnKey.EXTENDED_IMAGE,
        maxWidth: '14.5em',
        className: 'extended-image',
        render: (_value, record) => ({
          children: (
            <ExtendedImageTableItem
              src={getExtendedImage(model, Model_CommonItemFields.imageLink, record)}
              onClick={() => handleViewImages(record)}
            />
          ),
        }),
        sortOrder: sortState[columnKey.EXTENDED_IMAGE],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.CATEGORY]: () => ({
        title: <ScColumnTitle>{t('PRODUCT_CATEGORY')}</ScColumnTitle>,
        align: 'left',
        minWidth: '7em',
        maxWidth: '10em',
        dataIndex: MODEL_TABLE_ITEMS_FIELDS.categoryId,
        key: columnKey.CATEGORY,
        render: (value, row) => (
          <Tooltip placement="bottomLeft" title={() => getCategoriesHierarchy(row)}>
            {value}
          </Tooltip>
        ),
        sorter: (a, b) => sortStringValues(a, b, MODEL_TABLE_ITEMS_FIELDS.categoryId),
        sortOrder: sortState[columnKey.CATEGORY],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.DESCRIPTION]: () => ({
        title: <ScColumnTitle>{t('DESCRIPTION')}</ScColumnTitle>,
        dataIndex: Model_CommonItemFields.name,
        align: 'left' as const,
        minWidth: '14em',
        maxWidth: '30em',
        key: columnKey.DESCRIPTION,
        sorter: (a, b) => sortStringValues(a, b, Model_CommonItemFields.name),
        sortOrder: sortState[columnKey.DESCRIPTION],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.COMMENTS]: () => ({
        title: <ScColumnTitle>{t('TABLE_COMMENTS')}</ScColumnTitle>,
        dataIndex: 'comments',
        key: columnKey.COMMENTS,
        minWidth: '8em',
        render: (_value, row: Model_CommonItem) => (
          <CommentIcons
            onClick={activeTab =>
              handleCommentsModal({ option: row, defaultActiveKey: activeTab })
            }
            currentOption={row}
          />
        ),
        sortOrder: sortState[columnKey.COMMENTS],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.PAPERCLIP]: ({ icon }) => {
        const titleText = t('TABLE_PAPERCLIP');

        let width = 0;
        if (titleText) width += 6;
        if (icon) width += 3;

        return {
          title: (
            <ScColumnTitle>
              <ScIcon type={icon} title={titleText} />
              {titleText}
            </ScColumnTitle>
          ),
          dataIndex: 'paperclip',
          key: columnKey.PAPERCLIP,
          align: 'center' as const,
          minWidth: `${width}em`,
          render: (_value, row) => ({
            children:
              isManageDocumentsFeatureEnabled &&
              row[Model_CommonItemFields.hasDocuments] ? (
                <PaperClipOutlined
                  style={{ fontSize: 24 }}
                  onClick={() => handleDocumentsModal({ option: row })}
                  disabled={isConfigurationComplete}
                />
              ) : (
                '-'
              ),
          }),
          sortOrder: sortState[columnKey.PAPERCLIP],
          onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
        };
      },
      [columnKey.PHOTO]: ({ icon }) => {
        const titleText = t('TABLE_PHOTO');

        let width = 0;
        if (titleText) width += 5;
        if (icon) width += 3;

        return {
          title: (
            <ScColumnTitle>
              <ScIcon type={icon} title={titleText} />
              {titleText}
            </ScColumnTitle>
          ),
          dataIndex: 'img',
          key: columnKey.PHOTO,
          align: 'center' as const,
          minWidth: `${width}em`,
          className: 'photo',
          render: (_value, record) => ({
            children: record[Model_CommonItemFields.hasPhotos] ? (
              <ImageButton onClick={() => handleViewImages(record)} />
            ) : (
              '-'
            ),
          }),
          sortOrder: sortState[columnKey.PHOTO],
          onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
        };
      },
      [columnKey.ID]: () => ({
        title: <ScColumnTitle>{t('TABLE_ITEM_ID')}</ScColumnTitle>,
        dataIndex: Model_CommonItemFields.ID,
        key: columnKey.ID,
        minWidth: '12em',
        maxWidth: '14em',
        sorter: (a, b) => sortStringValues(a, b, Model_CommonItemFields.ID),
        sortOrder: sortState[columnKey.ID],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.AMOUNT]: () => ({
        title: <ScColumnTitle>{t('TABLE_AMOUNT')}</ScColumnTitle>,
        dataIndex: Model_CommonItemFields.defaultAmount,
        key: columnKey.AMOUNT,
        align: 'left',
        minWidth: '10em',
        render: (value, row) => {
          let disabled =
            !row[Model_CommonItemFields.askAmount] || isConfigurationComplete;

          if (
            isAllowStandardOptionAmountAlwaysChangeFeatureEnabled &&
            disabled &&
            isStandardOption(row)
          ) {
            disabled = false;
          }

          return {
            children: (
              <ScInputNumber
                size="small"
                min={0}
                value={value}
                defaultValue={1}
                onChange={event => handleChangeAmount(event.target.value, row)}
                disabled={disabled}
                data-testid={`table-amount-field-${row[Model_CommonItemFields.ID]}`}
              />
            ),
          };
        },
        sortOrder: sortState[columnKey.AMOUNT],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.GROSS_PRICE]: () => ({
        title: <ScColumnTitle>{t('TABLE_TOTAL_NET_PRICE')}</ScColumnTitle>,
        dataIndex: PRICE_FIELDS.GROSS_PRICE,
        key: columnKey.GROSS_PRICE,
        align: 'center',
        minWidth: '10em',
        render: (value, row) => {
          const isChanged = row[Model_CommonItemFields.YNchanged];
          const price =
            typeof value === 'number'
              ? formatPrice({
                  price: value,
                  currency: currencyCode,
                  locale: i18n.language,
                })
              : '-';

          return { children: isChanged ? <ScTableEdit>{price}</ScTableEdit> : price };
        },
        sorter: (a, b) => sortNumericValues(a, b, Model_CommonItemFields.grossPrice),
        sortOrder: sortState[columnKey.GROSS_PRICE],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      // check that correct key is used
      [columnKey.DISCOUNT]: () => ({
        title: <ScColumnTitle>{`${t('DISCOUNT')} %`}</ScColumnTitle>,
        dataIndex: Model_CommonItemFields.discountPercent,
        key: columnKey.DISCOUNT,
        minWidth: '10em',
        align: 'center' as const,
        render: value => {
          if (typeof value !== 'number' || value === 0) return '-';
          return (
            <ScTableDiscount>
              {formatNumber(value, i18n.language, { symbolsAfterDecimal: 2 })}%
            </ScTableDiscount>
          );
        },
        sorter: (a, b) => sortNumericValues(a, b, Model_CommonItemFields.discountPercent),
        sortOrder: sortState[columnKey.DISCOUNT],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.TOTAL_PRICE]: () => ({
        title: <ScColumnTitle>{t('TABLE_NET_PRICE')}</ScColumnTitle>,
        dataIndex: PRICE_FIELDS.TOTAL_PRICE,
        key: columnKey.TOTAL_PRICE,
        minWidth: '7em',
        align: 'center' as const,
        render: (value, row) => {
          const isChanged = row[Model_CommonItemFields.YNchanged];
          const price =
            typeof value === 'number'
              ? formatPrice({
                  price: value,
                  currency: currencyCode,
                  locale: i18n.language,
                })
              : '-';

          return { children: isChanged ? <ScTableEdit>{price}</ScTableEdit> : price };
        },
        sorter: (a, b) =>
          sortNumericValues(a, b, Model_CommonItemFields.totalSellingPrice),
        sortOrder: sortState[columnKey.TOTAL_PRICE],
        onHeaderCell: column => ({ onClick: () => toggleSort(column.key as string) }),
      }),
      [columnKey.ACTIONS]: () => ({
        title: <ScColumnTitle>{t('ACTION')}</ScColumnTitle>,
        key: columnKey.ACTIONS,
        fixed: 'right' as const,
        align: 'center' as const,
        width: '6em',
        render: (_value, row) => {
          const { menuLength, menuRenderNode } = menu(row);
          return (
            <ScDropdown
              trigger={['click']}
              overlay={() => menuRenderNode}
              placement="bottomLeft"
              disabled={menuLength === 0}
            >
              <DotsIcon
                style={{ fontSize: '2em' }}
                data-testid={`dynamic-step-actions-trigger-${
                  row[Model_CommonItemFields.ID]
                }`}
              />
            </ScDropdown>
          );
        },
      }),
    };
  }, [
    isSelectDisabled,
    model,
    title,
    MODEL_TABLE_ITEMS_FIELDS.optional,
    MODEL_TABLE_ITEMS_FIELDS.categoryId,
    getSelectionTooltip,
    handleSelectRecord,
    sortState,
    handleViewImages,
    toggleSort,
    t,
    getCategoriesHierarchy,
    handleCommentsModal,
    isManageDocumentsFeatureEnabled,
    isConfigurationComplete,
    handleDocumentsModal,
    isAllowStandardOptionAmountAlwaysChangeFeatureEnabled,
    handleChangeAmount,
    PRICE_FIELDS.GROSS_PRICE,
    PRICE_FIELDS.TOTAL_PRICE,
    currencyCode,
    i18n.language,
    menu,
  ]);

  const tableColumns: ColumnsType<Model_CommonItem> = useMemo(() => {
    let columns: ColumnsType<Model_CommonItem> = [];
    let hasSelection = false;

    if (columnsSettings) {
      columns = columnsSettings
        .filter(settings => keyToColumn[settings.name])
        .map(settings => ({ fn: keyToColumn[settings.name], settings }))
        .map(({ fn, settings }) => fn(settings));

      hasSelection = columnsSettings.some(settings => settings.name === 'Selection');
    } else {
      columns = Object.entries(keyToColumn).map(([name, fn]) => fn({ name }));
      hasSelection = true;
    }

    if (!canShowCategory) {
      columns = columns.filter(column => column.key !== columnKey.CATEGORY);
    }

    if (isTableViewExtended) {
      const hasExtendedImageColumn = columns.some(
        column => column.key === columnKey.EXTENDED_IMAGE,
      );

      if (!hasExtendedImageColumn) {
        columns.splice(
          hasSelection ? 1 : 0,
          0,
          keyToColumn[columnKey.EXTENDED_IMAGE]({ name: columnKey.EXTENDED_IMAGE }),
        );
      }

      columns = columns.filter(column => column.key !== columnKey.PHOTO);
    } else {
      columns = columns.filter(column => column.key !== columnKey.EXTENDED_IMAGE);
    }

    const hasActionsColumn = columns.some(column => column.key === columnKey.ACTIONS);
    if (!hasActionsColumn) {
      columns.push(keyToColumn[columnKey.ACTIONS]({ name: columnKey.ACTIONS }));
    }

    return columns;
  }, [columnsSettings, canShowCategory, keyToColumn, isTableViewExtended]);

  return tableColumns;
};
