import React, { FC, useCallback, useMemo } from 'react';
import AuthLayout from 'layout/Auth/AuthLayout';
import LoginForm from './LoginForm';
import { LoginFormValues } from './LoginFormMeta';
import { FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions, authSelectors } from 'store/auth';
import { useAuthApi } from 'context/auth/useAuthApi';
import { CLIENTS_URL, INDEX_URL, XDMS_SETTINGS_URL } from 'common/constants';
import { hostSettingsSelectors, sharedSelectors } from 'store';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'context/currency/CurrencyProvider';
import BackButton from './BackButton';
import { useQuery } from 'context/router/UrlQueryProvider';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { queryValues } = useQuery();

  const username = useSelector(authSelectors.getUsername);
  const host = useSelector(hostSettingsSelectors.getHost);
  const background = useSelector(hostSettingsSelectors.getBackground);
  const customerId = useSelector(hostSettingsSelectors.getCustomerId);
  const hostLogo = useSelector(hostSettingsSelectors.getSecondaryLogo);
  const initialLocation = useSelector(sharedSelectors.getInitialLocation);

  const { login } = useAuthApi();
  const { setActiveCurrency } = useCurrency();
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);

  const isBackButtonAllowed = useMemo(() => {
    return !host || !queryValues.hostLock;
  }, [host, queryValues.hostLock]);

  const goBack = useCallback(
    () => history.push({ pathname: XDMS_SETTINGS_URL, search: location.search }),
    [location.search, history],
  );
  const goForth = useCallback(
    () =>
      history.push(initialLocation ?? { pathname: CLIENTS_URL, search: location.search }),
    [location.search, history, initialLocation],
  );

  const onSubmit = useCallback(
    async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
      actions.setSubmitting(true);

      if (!host) {
        goBack();
        return;
      }

      const { status, messageHandled, response } = await login({
        host: host,
        username: values.userName,
        password: values.password,
      });

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Error]: t('AUTH__REQUEST_CODE_ERROR'),
        });
      }

      if (response) {
        setActiveCurrency(response.currency);
        dispatch(
          authActions.setAll({
            authToken: response.token,
            username: values.userName,
            baseUrl: response.tenantBaseUrl,
          }),
        );
      }

      actions.setSubmitting(false);

      if (status === Status.Success) goForth();
    },
    [host, login, goForth, goBack, t, setActiveCurrency, dispatch],
  );

  return (
    <AuthLayout
      isLoading={isWholePageLoading}
      logo={{
        alt: customerId ?? undefined,
        src: hostLogo,
        redirect: INDEX_URL,
      }}
      background={background}
      title={t('AUTH__SIGN_IN')}
      header={isBackButtonAllowed && <BackButton onClick={goBack} />}
    >
      <LoginForm
        onSubmit={onSubmit}
        initialValues={{ userName: username ?? '', password: '' }}
      />
    </AuthLayout>
  );
};

export default LoginPage;
