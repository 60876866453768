import styled from 'styled-components';
import { MdMenu } from 'react-icons/md';
import { mediaMax } from '../../../common/theme';

export const Menu = styled(MdMenu)`
  color: ${props => props.theme.textColor};
  font-size: 1.6em;
  vertical-align: bottom;
  cursor: pointer;
`;

export const ScCurrentContainerWrapper = styled.div`
  margin-bottom: 0.625em;
`;

export const ScMobileMenu = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.75em;

  ${mediaMax.md} {
    margin: 0 0.5em;
  }

  ${mediaMax.xs} {
    margin: 0 0.5em;
  }
`;

export const ScComplementActionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:first-child {
    background: black;
  }
`;

export const ScConfigurationActionLabel = styled.span`
  color: ${({ theme }) => theme.textColor};
  margin-left: 0.5em;
`;

export const ScConfigurationActionIconWrapper = styled.span`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
`;

export const ScToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
`;
