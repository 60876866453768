import React, { FC, useEffect } from 'react';
import { SoftOfferTable } from './SoftOfferTable';
import { SoftOfferForm } from './SoftOfferForm';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SOFT_OFFER_URL } from 'common/constants';
import { useSoftOfferApi } from 'context/softOffer/SoftOfferProvider';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';

export const SoftOffer: FC = () => {
  const { getSoftOffersList, loadSoftOfferOptions } = useSoftOfferApi();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  /** Its purpose mostly in fetching list of options */
  useEffect(() => {
    if (!configurationNumber) return;
    getSoftOffersList(configurationNumber).then(({ response }) => {
      if (!response?.length) loadSoftOfferOptions();
    });
  }, [configurationNumber, getSoftOffersList, loadSoftOfferOptions]);

  return (
    <Switch>
      <Route path={SOFT_OFFER_URL + '/list'} component={SoftOfferTable} />
      <Route path={SOFT_OFFER_URL + '/:id'} component={SoftOfferForm} />
      <Redirect to={SOFT_OFFER_URL + '/list'} />
    </Switch>
  );
};
