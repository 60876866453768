import { Model_CommonItemFields } from '@hypercharge/xdms-client/lib/types';

export const columnKey = {
  EXTENDED_IMAGE: 'ExtendedImage',
  CATEGORY: Model_CommonItemFields.categoryCode,
  DESCRIPTION: Model_CommonItemFields.name,
  COMMENTS: 'Comments',
  PAPERCLIP: 'Attachments',
  PHOTO: 'Photo',
  ID: Model_CommonItemFields.ID,
  AMOUNT: Model_CommonItemFields.defaultAmount,
  GROSS_PRICE: Model_CommonItemFields.grossPrice,
  DISCOUNT: Model_CommonItemFields.discountPercent,
  TOTAL_PRICE: Model_CommonItemFields.totalSellingPrice,
  ACTIONS: 'Operation',
};
