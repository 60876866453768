import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePriceList } from 'context/priceList/PriceListProvider';
import { useTradeIn } from 'context/tradeIn/TradeInProvider';
import { useCustomer } from 'context/customer/CustomerProvider';
import { useContact } from 'context/contact/ContactProvider';
import { useRelations } from 'context/relations/RelationsProvider';
import { useExternalStorage } from 'context/externalStorage/ExternalStorageProvider';
import {
  featuresFlagsSelectors,
  configurationSelectors,
  softOffersSelectors,
  documentsSelectors,
  modelSelectors,
  structureSelectors,
  sharedActions,
  financingSelectors,
} from 'store';

/**
 * Control over {@link isWholePageLoading} from all possible contexts which can load data
 */
export const useAuthenticatedLoadingStatusesUpdateEffect = () => {
  const dispatch = useDispatch();

  const dynamicStepsFeaturesStatus = useSelector(
    featuresFlagsSelectors.getDynamicStepsFeaturesStatus,
  );
  const globalFeaturesStatus = useSelector(
    featuresFlagsSelectors.getGlobalFeaturesStatus,
  );

  const configurationStatus = useSelector(configurationSelectors.getStatus);
  const softOffersListStatus = useSelector(softOffersSelectors.getListStatus);
  const softOfferDetailsStatus = useSelector(softOffersSelectors.getDetailsStatus);
  const { documentDetailsStatus, documentForAllEntitiesStatus, documentsListStatus } =
    useSelector(documentsSelectors.getAll);
  const modelStatus = useSelector(modelSelectors.getStatus);
  const structureStatus = useSelector(structureSelectors.getStructureStatus);
  const financingStatus = useSelector(financingSelectors.getDetailsStatus);

  const { status: priceListStatus } = usePriceList();
  const { status: tradeInStatus } = useTradeIn();
  const { status: customerStatus } = useCustomer();
  const { status: contactStatus } = useContact();
  const { status: relationsStatus } = useRelations();
  const { status: externalStorageStatus } = useExternalStorage();

  useEffect(() => {
    const statusesList = [
      configurationStatus,
      structureStatus,
      priceListStatus,
      tradeInStatus,
      customerStatus,
      contactStatus,
      relationsStatus,
      modelStatus,
      documentDetailsStatus,
      documentForAllEntitiesStatus,
      documentsListStatus,
      externalStorageStatus,
      softOffersListStatus,
      softOfferDetailsStatus,
      dynamicStepsFeaturesStatus,
      globalFeaturesStatus,
      financingStatus,
    ];
    dispatch(sharedActions.setAuthenticatedStatusesList(statusesList));
  }, [
    dispatch,
    configurationStatus,
    structureStatus,
    priceListStatus,
    tradeInStatus,
    customerStatus,
    contactStatus,
    relationsStatus,
    modelStatus,
    documentDetailsStatus,
    documentForAllEntitiesStatus,
    documentsListStatus,
    externalStorageStatus,
    softOffersListStatus,
    softOfferDetailsStatus,
    dynamicStepsFeaturesStatus,
    globalFeaturesStatus,
    financingStatus,
  ]);
};
