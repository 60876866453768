import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PriceList } from 'pages/PriceList/PriceList';
import NotFound from './layout/Default/NotFound';
import { Models } from 'pages/Models';
import Total from 'pages/total/Total';
import { Documents } from 'pages/documents';
import { RelationsList } from 'pages/Clients/RelationsList';
import { TradeIn } from 'pages/TradeIn';
import { ConfigurationList } from 'pages/ConfigurationList';
import Info from 'pages/Info/Info';
import { FINANCING_URL, INFO_URL, SOFT_OFFER_URL, TAXES_URL } from 'common/constants';
import { SoftOffer } from './pages/SoftOffer';
import {
  PAGES_SETTINGS,
  TableCategoriesProvider,
} from 'context/tableCategories/TableCategoriesProvider';
import { DynamicPage } from 'pages/DynamicPage';
import { useGlobalModalsStates } from './context/globalModalsStates/GlobalModalsStatesProvider';
import { TaxesPage } from 'pages/Taxes';
import ConfigurationImportModal from './components/configuration/ConfigurationImportModal';
import ConfigurationMarginModal from 'components/configuration/ConfigurationMarginModal';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationFields } from '@hypercharge/xdms-client/lib/configuration';
import { Helmet } from 'react-helmet';
import { ScFloatHolder } from 'AppAuthenticated.styles';
import StreamingMenuSelector from 'components/streaming/StreamingMenuSelector';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { FinancingPage } from 'pages/Financing';

const AppAuthenticated: FC = () => {
  const {
    configurationImportModalState,
    setConfigurationImportModalState,
    configurationMarginModalState,
    setConfigurationMarginModalState,
  } = useGlobalModalsStates();

  const configurationCommonVariables = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const configuration = useSelector(configurationSelectors.getConfiguration);

  const configurationNumber = configurationCommonVariables.configurationNumber;
  const customerLastName = configuration?.[ConfigurationFields.customerName];

  const { isFeatureEnabled } = useFeature();
  const isStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowStreaming,
  });

  return (
    <>
      <Helmet>
        <title>
          {configurationNumber && customerLastName
            ? `${configurationNumber}: ${customerLastName}`
            : 'HyperConfigurator'}
        </title>
      </Helmet>
      {configurationImportModalState && (
        <ConfigurationImportModal
          {...configurationImportModalState}
          onClose={() => {
            setConfigurationImportModalState(undefined);
          }}
        />
      )}
      {configurationMarginModalState && (
        <ConfigurationMarginModal
          {...configurationMarginModalState}
          onClose={() => {
            setConfigurationMarginModalState(undefined);
          }}
        />
      )}

      <ScFloatHolder id="float-holder" />

      {isStreamingFeatureEnabled && <StreamingMenuSelector />}

      <Switch>
        <Route exact path="/">
          <Redirect to={'/clients'} />
        </Route>
        <Route path="/price-list" component={PriceList} />
        <Route path="/documents" component={Documents} />
        <Route path="/clients" component={RelationsList} />
        <Route exact path="/total" component={Total} />
        <Route path="/configurations" component={ConfigurationList} />
        <Route path="/trade-in" component={TradeIn} />
        <Route path={INFO_URL} component={Info} />
        <Route path={SOFT_OFFER_URL} component={SoftOffer} />
        <Route path={FINANCING_URL} component={FinancingPage} />
        <Route exact path={'/models/:id?'} component={Models} />
        <Route
          exact
          path={'/steps/:stepId'}
          render={routeProps => {
            const { params } = routeProps.match;
            const stepId = params.stepId as keyof typeof PAGES_SETTINGS;

            return (
              <TableCategoriesProvider stepId={stepId}>
                <DynamicPage stepId={stepId} />
              </TableCategoriesProvider>
            );
          }}
        />
        <Route exact path={TAXES_URL} component={TaxesPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default AppAuthenticated;
