import cn from 'classnames';
import { TotalsTableColumnsGenericProp, totalsTableColumnsWidths } from '../styles';
import React, { DetailedHTMLProps, FC, ReactNode, TableHTMLAttributes } from 'react';
import { ScTable } from './TotalTableRow.styles';

interface Props
  extends DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {
  fields: TotalsTableColumnsGenericProp<ReactNode | string | undefined>;
  /**
   * Specific usage case - passing this component in collapse header
   */
  shiftFirstColumn?: boolean;
  borderTop?: 'thin' | 'thick';
  borderBottom?: 'thin' | 'thick';
  /**
   * Makes columns expand depending on content.
   */
  unfixed?: boolean;
  /**
   * Makes numbers columns gray
   */
  shadeNumbers?: boolean;
  textSize?: 'small' | 'medium' | 'large' | 'extra-large';
  fontWeight?: 'normal' | 'bold';
  customTotalsTableColumnsWidths?: TotalsTableColumnsGenericProp<number>;
}
export type TotalTableRowProps = Props;
export const TotalTableRow: FC<Props> = ({
  fields,
  shiftFirstColumn,
  borderTop,
  borderBottom,
  unfixed,
  textSize = 'medium',
  fontWeight = 'normal',
  shadeNumbers,
  customTotalsTableColumnsWidths = totalsTableColumnsWidths,
  ...props
}) => {
  // ref override fixes typescript error
  return (
    <ScTable
      {...props}
      className={cn(props.className, {
        unfixed: unfixed,
        'shade-numbers': shadeNumbers,
        'text-small': textSize === 'small',
        'text-medium': textSize === 'medium',
        'text-large': textSize === 'large',
        'text-extra-large': textSize === 'extra-large',
        'border-top-thick': borderTop === 'thick',
        'border-top-thin': borderTop === 'thin',
        'border-bottom-thick': borderBottom === 'thick',
        'border-bottom-thin': borderBottom === 'thin',
        'font-weight-bold': fontWeight === 'bold',
      })}
      ref={() => props.ref}
    >
      <colgroup>
        {customTotalsTableColumnsWidths.map((percent, idx) => (
          <col key={percent + '' + idx} width={`${percent}%`} />
        ))}
      </colgroup>
      <tbody>
        <tr>
          {fields.map((item, idx) => (
            <td
              key={item + '' + idx}
              className={cn({
                'shift-cell-to-left': idx === 0 && shiftFirstColumn,
              })}
            >
              {item}
            </td>
          ))}
        </tr>
      </tbody>
    </ScTable>
  );
};
