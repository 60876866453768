import {
  ModelRecordFields,
  ModelActionFields,
  ModelTotalsItemFields,
  ModelTradeInFields,
} from 'types/vendor';

export type TotalPageAdaptedFields = {
  listPrice: string;
  HOD: string;
  baseMachine: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  options: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  optionsSelected: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  optionsStandard: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  optionsItemsFields: {
    name: string;
    id: string;
    quantity: string;
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
    hasChanged: string;
  };
  subtotalExManufacturer: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  totalExManufacturer: string;
  accessories: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  accessoriesItemsFields: {
    name: string;
    id: string;
    quantity: string;
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
    hasChanged: string;
  };
  packages: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  packagesItemsFields: {
    name: string;
    id: string;
    quantity: string;
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
    hasChanged: string;
  };
  packagesLineItemsFields: {
    name: string;
    id: string;
    quantity: string;
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
    hasChanged: string;
  };
  totalExDealer: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  dealerDiscount: {
    amount: string;
    totalWithoutDealerDelta: string;
  };
  subtotal: {
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  tradeInSubtotal: string;
  tradeInItemsFields: {
    brandName: string;
    modelName: string;
    serialNumber: string;
    subtotal: string;
  };
  balance: string;
  additionalItemsFields: {
    name: string;
    id: string;
    quantity: string;
    brutto: string;
    discountAmount: string;
    discountPercent: string;
    netto: string;
  };
  currentTier: string;
  priceToNextTier: string;

  dealerAction: {
    isSelected: string;
    name: string;
    amount: string;
    /** Same as {@link amount} but reverted. Used to set 0 in another. */
    amountReverse: string;
    percent: string;
    sign: string;
    disabled: string;
  };
  actions: {
    selected: string;
    amount: string;
    percent: string;
    name: string;
    itemNumber: string;
    code: string;
    rule: string;
    sign: string;
    signEditable: string;
    disabled: string;
    sideCode: string;
  };
};

export const getTotalPageAdaptedFields = (
  includeVat: boolean,
): TotalPageAdaptedFields => {
  const {
    totalBruttoPrice,
    totalBruttoPrice_withVAT,
    totalCustomerDiscount,
    totalCustomerDiscount_withVAT,
    baseModel_TotalBruttoPrice,
    baseModel_TotalBruttoPrice_withVAT,
    baseModel_TotalDiscountAmount,
    baseModel_TotalDiscountAmount_withVAT,
    baseModel_TotalDiscountPercent,
    baseModel_TotalNettoPrice,
    baseModel_TotalNettoPrice_withVAT,
    options_TotalBruttoPrice,
    options_TotalBruttoPrice_withVAT,
    options_TotalDiscountAmount,
    options_TotalDiscountAmount_withVAT,
    options_TotalDiscountPercent,
    options_TotalNettoPrice,
    options_TotalNettoPrice_withVAT,
    optionsSelected_TotalBruttoPrice,
    optionsSelected_TotalBruttoPrice_withVAT,
    optionsSelected_TotalDiscountAmount,
    optionsSelected_TotalDiscountAmount_withVAT,
    optionsSelected_TotalDiscountPercent,
    optionsSelected_TotalNettoPrice,
    optionsSelected_TotalNettoPrice_withVAT,
    optionsStandard_TotalBruttoPrice,
    optionsStandard_TotalBruttoPrice_withVAT,
    optionsStandard_TotalDiscountAmount,
    optionsStandard_TotalDiscountAmount_withVAT,
    optionsStandard_TotalDiscountPercent,
    optionsStandard_TotalNettoPrice,
    optionsStandard_TotalNettoPrice_withVAT,
    packages_TotalBruttoPrice,
    packages_TotalBruttoPrice_withVAT,
    packages_TotalDiscountAmount,
    packages_TotalDiscountAmount_withVAT,
    packages_TotalDiscountPercent,
    packages_TotalNettoPrice,
    packages_TotalNettoPrice_withVAT,
    baseModelAndOptions_TotalBruttoPrice,
    baseModelAndOptions_TotalBruttoPrice_withVAT,
    baseModelAndOptions_TotalDiscountAmount,
    baseModelAndOptions_TotalDiscountAmount_withVAT,
    baseModelAndOptions_TotalDiscountPercent,
    baseModelAndOptions_TotalNettoPrice,
    baseModelAndOptions_TotalNettoPrice_withVAT,
    baseModelWithOptionsWithActions_TotalPrice,
    baseModelWithOptionsWithActions_TotalPrice_withVAT,
    totalNettoPriceWithoutActions,
    totalNettoPriceWithoutActions_withVAT,
    totalBruttoPriceWithoutActionsWithoutTov,
    totalBruttoPriceWithoutActionsWithoutTov_withVAT,
    totalBruttoDiscountAmountWithoutActionsWithoutTov,
    totalBruttoDiscountAmountWithoutActionsWithoutTov_withVAT,
    totalBruttoDiscountPercentWithoutActionsWithoutTov,
    accessories_TotalBruttoPrice,
    accessories_TotalBruttoPrice_withVAT,
    accessories_TotalDiscountAmount,
    accessories_TotalDiscountAmount_withVAT,
    accessories_TotalDiscountPercent,
    accessories_TotalNettoPrice,
    accessories_TotalNettoPrice_withVAT,
    baseModelWithOptionsWithActionsWithAccessories_TotalPrice,
    baseModelWithOptionsWithActionsWithAccessories_TotalPrice_withVAT,
    actions_DeltaAmount,
    actions_DeltaAmount_withVAT,
    baseModelWithOptionsWithActionsWithAccessories_TotalBruttoPrice,
    baseModelWithOptionsWithActionsWithAccessories_TotalBruttoPrice_withVAT,
    baseModelWithOptionsWithActionsWithAccessories_TotalDiscountAmount,
    baseModelWithOptionsWithActionsWithAccessories_TotalDiscountAmount_withVAT,
    baseModelWithOptionsWithActionsWithAccessories_TotalDiscountPercent,
    baseModelWithOptionsWithActionsWithAccessories_TotalNettoPrice,
    baseModelWithOptionsWithActionsWithAccessories_TotalNettoPrice_withVAT,
    tradeInDeltaAmount,
    tradeInDeltaAmount_withVAT,
    currentTier,
    priceToNextTier,
    priceToNextTier_withVAT,
    totalPrice_withVAT,
    totalPrice,
  } = ModelTotalsItemFields;

  return {
    listPrice: includeVat ? totalBruttoPrice_withVAT : totalBruttoPrice,
    HOD: includeVat ? totalCustomerDiscount_withVAT : totalCustomerDiscount,
    baseMachine: {
      brutto: includeVat
        ? baseModel_TotalBruttoPrice_withVAT
        : baseModel_TotalBruttoPrice,
      discountAmount: includeVat
        ? baseModel_TotalDiscountAmount_withVAT
        : baseModel_TotalDiscountAmount,
      discountPercent: baseModel_TotalDiscountPercent,
      netto: includeVat ? baseModel_TotalNettoPrice_withVAT : baseModel_TotalNettoPrice,
    },
    options: {
      brutto: includeVat ? options_TotalBruttoPrice_withVAT : options_TotalBruttoPrice,
      discountAmount: includeVat
        ? options_TotalDiscountAmount_withVAT
        : options_TotalDiscountAmount,
      discountPercent: options_TotalDiscountPercent,
      netto: includeVat ? options_TotalNettoPrice_withVAT : options_TotalNettoPrice,
    },
    optionsSelected: {
      brutto: includeVat
        ? optionsSelected_TotalBruttoPrice_withVAT
        : optionsSelected_TotalBruttoPrice,
      discountAmount: includeVat
        ? optionsSelected_TotalDiscountAmount_withVAT
        : optionsSelected_TotalDiscountAmount,
      discountPercent: optionsSelected_TotalDiscountPercent,
      netto: includeVat
        ? optionsSelected_TotalNettoPrice_withVAT
        : optionsSelected_TotalNettoPrice,
    },
    optionsStandard: {
      brutto: includeVat
        ? optionsStandard_TotalBruttoPrice_withVAT
        : optionsStandard_TotalBruttoPrice,
      discountAmount: includeVat
        ? optionsStandard_TotalDiscountAmount_withVAT
        : optionsStandard_TotalDiscountAmount,
      discountPercent: optionsStandard_TotalDiscountPercent,
      netto: includeVat
        ? optionsStandard_TotalNettoPrice_withVAT
        : optionsStandard_TotalNettoPrice,
    },
    optionsItemsFields: {
      name: ModelRecordFields.name,
      id: ModelRecordFields.ID,
      quantity: ModelRecordFields.quantity,
      brutto: includeVat ? ModelRecordFields.grossPriceVat : ModelRecordFields.grossPrice,
      discountAmount: includeVat
        ? ModelRecordFields.discountAmount_withVAT
        : ModelRecordFields.discountAmount,
      discountPercent: ModelRecordFields.discountPercent,
      netto: includeVat
        ? ModelRecordFields.nettoPriceWithVat
        : ModelRecordFields.totalSellingPrice,
      hasChanged: ModelRecordFields.YNchanged,
    },
    subtotalExManufacturer: {
      brutto: includeVat
        ? baseModelAndOptions_TotalBruttoPrice_withVAT
        : baseModelAndOptions_TotalBruttoPrice,
      discountAmount: includeVat
        ? baseModelAndOptions_TotalDiscountAmount_withVAT
        : baseModelAndOptions_TotalDiscountAmount,
      discountPercent: baseModelAndOptions_TotalDiscountPercent,
      netto: includeVat
        ? baseModelAndOptions_TotalNettoPrice_withVAT
        : baseModelAndOptions_TotalNettoPrice,
    },
    totalExManufacturer: includeVat
      ? baseModelWithOptionsWithActions_TotalPrice_withVAT
      : baseModelWithOptionsWithActions_TotalPrice,
    accessories: {
      brutto: includeVat
        ? accessories_TotalBruttoPrice_withVAT
        : accessories_TotalBruttoPrice,
      discountAmount: includeVat
        ? accessories_TotalDiscountAmount_withVAT
        : accessories_TotalDiscountAmount,
      discountPercent: accessories_TotalDiscountPercent,
      netto: includeVat
        ? accessories_TotalNettoPrice_withVAT
        : accessories_TotalNettoPrice,
    },
    accessoriesItemsFields: {
      name: ModelRecordFields.name,
      id: ModelRecordFields.ID,
      quantity: ModelRecordFields.quantity,
      brutto: includeVat ? ModelRecordFields.grossPriceVat : ModelRecordFields.grossPrice,
      discountAmount: includeVat
        ? ModelRecordFields.discountAmount_withVAT
        : ModelRecordFields.discountAmount,
      discountPercent: ModelRecordFields.discountPercent,
      netto: includeVat
        ? ModelRecordFields.nettoPriceWithVat
        : ModelRecordFields.totalSellingPrice,
      hasChanged: ModelRecordFields.YNchanged,
    },
    packages: {
      brutto: includeVat ? packages_TotalBruttoPrice_withVAT : packages_TotalBruttoPrice,
      discountAmount: includeVat
        ? packages_TotalDiscountAmount_withVAT
        : packages_TotalDiscountAmount,
      discountPercent: packages_TotalDiscountPercent,
      netto: includeVat ? packages_TotalNettoPrice_withVAT : packages_TotalNettoPrice,
    },
    packagesItemsFields: {
      name: ModelRecordFields.name,
      id: ModelRecordFields.ID,
      quantity: ModelRecordFields.quantity,
      brutto: includeVat ? ModelRecordFields.grossPriceVat : ModelRecordFields.grossPrice,
      discountAmount: includeVat
        ? ModelRecordFields.discountAmount_withVAT
        : ModelRecordFields.discountAmount,
      discountPercent: ModelRecordFields.discountPercent,
      netto: includeVat
        ? ModelRecordFields.nettoPriceWithVat
        : ModelRecordFields.totalSellingPrice,
      hasChanged: ModelRecordFields.YNchanged,
    },
    packagesLineItemsFields: {
      name: ModelRecordFields.name,
      id: ModelRecordFields.ID,
      quantity: ModelRecordFields.quantity,
      brutto: includeVat ? ModelRecordFields.grossPriceVat : ModelRecordFields.grossPrice,
      discountAmount: includeVat
        ? ModelRecordFields.discountAmount_withVAT
        : ModelRecordFields.discountAmount,
      discountPercent: ModelRecordFields.discountPercent,
      netto: includeVat
        ? ModelRecordFields.nettoPriceWithVat
        : ModelRecordFields.totalSellingPrice,
      hasChanged: ModelRecordFields.YNchanged,
    },
    totalExDealer: {
      brutto: includeVat
        ? totalBruttoPriceWithoutActionsWithoutTov_withVAT
        : totalBruttoPriceWithoutActionsWithoutTov,
      discountAmount: includeVat
        ? totalBruttoDiscountAmountWithoutActionsWithoutTov_withVAT
        : totalBruttoDiscountAmountWithoutActionsWithoutTov,
      discountPercent: totalBruttoDiscountPercentWithoutActionsWithoutTov,
      netto: includeVat
        ? totalNettoPriceWithoutActions_withVAT
        : totalNettoPriceWithoutActions,
    },
    subtotal: {
      brutto: includeVat
        ? baseModelWithOptionsWithActionsWithAccessories_TotalBruttoPrice_withVAT
        : baseModelWithOptionsWithActionsWithAccessories_TotalBruttoPrice,
      discountAmount: includeVat
        ? baseModelWithOptionsWithActionsWithAccessories_TotalDiscountAmount_withVAT
        : baseModelWithOptionsWithActionsWithAccessories_TotalDiscountAmount,
      discountPercent:
        baseModelWithOptionsWithActionsWithAccessories_TotalDiscountPercent,
      netto: includeVat
        ? baseModelWithOptionsWithActionsWithAccessories_TotalNettoPrice_withVAT
        : baseModelWithOptionsWithActionsWithAccessories_TotalNettoPrice,
    },
    tradeInSubtotal: includeVat ? tradeInDeltaAmount_withVAT : tradeInDeltaAmount,
    tradeInItemsFields: {
      brandName: ModelTradeInFields.brandName,
      modelName: ModelTradeInFields.modelName,
      serialNumber: ModelTradeInFields.machineId,
      subtotal: ModelTradeInFields.price,
    },
    balance: includeVat ? totalPrice_withVAT : totalPrice,
    additionalItemsFields: {
      name: ModelRecordFields.name,
      id: ModelRecordFields.ID,
      quantity: ModelRecordFields.quantity,
      brutto: includeVat ? ModelRecordFields.grossPriceVat : ModelRecordFields.grossPrice,
      discountAmount: includeVat
        ? ModelRecordFields.discountAmount_withVAT
        : ModelRecordFields.discountAmount,
      discountPercent: ModelRecordFields.discountPercent,
      netto: includeVat
        ? ModelRecordFields.nettoPriceWithVat
        : ModelRecordFields.totalSellingPrice,
    },
    currentTier: currentTier,
    priceToNextTier: includeVat ? priceToNextTier_withVAT : priceToNextTier,

    dealerAction: {
      isSelected: ModelActionFields.isSelected,
      amount: includeVat ? ModelActionFields.amount_withVat : ModelActionFields.amount,
      name: ModelActionFields.name,
      amountReverse: includeVat
        ? ModelActionFields.amount
        : ModelActionFields.amount_withVat,
      percent: ModelActionFields.percent,
      sign: ModelActionFields.sign,
      disabled: ModelActionFields.isDisabled,
    },
    dealerDiscount: {
      amount: includeVat ? actions_DeltaAmount_withVAT : actions_DeltaAmount,
      totalWithoutDealerDelta: includeVat
        ? baseModelWithOptionsWithActionsWithAccessories_TotalPrice_withVAT
        : baseModelWithOptionsWithActionsWithAccessories_TotalPrice,
    },

    actions: {
      selected: ModelActionFields.isSelected,
      amount: includeVat ? ModelActionFields.amount_withVat : ModelActionFields.amount,
      percent: ModelActionFields.percent,
      name: ModelActionFields.name,
      itemNumber: ModelActionFields.actionCode,
      code: ModelActionFields.actionCode,
      rule: ModelActionFields.ruleCode,
      sign: ModelActionFields.sign,
      sideCode: ModelActionFields.sideCode,
      signEditable: ModelActionFields.signEditable,
      disabled: ModelActionFields.isDisabled,
    },
  };
};
