import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import Table from 'components/table';
import styled from 'styled-components';

export const ScStockWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ScStockHeader = styled.div`
  padding: 1em;
  height: 3.125em;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const ScButton = styled(Button)`
  height: 100%;
`;

export const ScStockBody = styled.div`
  padding: 0 1em;
  padding-bottom: 1em;
  form {
    margin-bottom: 1rem;
  }
`;

export const ScTableWrapper = styled.div`
  position: relative;
`;

export const ScTable = styled(Table)`
  &&&& {
    th {
      .ant-table-column-sorters {
        padding: 0.5em;
      }

      * {
        font-size: inherit;
      }
    }

    .ant-table-tbody tr {
      font-size: 0.875em;
    }
  }
`;

export const ScStockFieldWrapper = styled.div`
  margin: 0 0.25em 0.5em 0.25em;
  flex: 1;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const ScStockMachineTriggerIcon = styled(SearchOutlined)`
  &&&& {
    font-size: 1em;
  }
`;
