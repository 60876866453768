import { Progress } from 'antd';
import classNames from 'classnames';
import { NoItems } from 'components/noItems/NoItems';
import { ScNoItemsIcon } from 'components/noItems/NoItems.styles';
import { useProgress } from 'hooks/progress';
import React, { FC, useMemo, PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { ScLoadNextStep } from './styles';

interface Props {
  message: string;
  beforeStart?(): void;
  afterFinish?(): void;
  isTransitionToNextStepAllowed: boolean;
}

export const NextStepTransition: FC<PropsWithChildren<Props>> = props => {
  const { mainColor } = useTheme();
  const { message, children, isTransitionToNextStepAllowed, beforeStart, afterFinish } =
    props;

  const {
    value: progressValue,
    pause: pauseProgress,
    resume: resumeProgress,
  } = useProgress({
    beforeStart,
    afterFinish,
    canProgress: isTransitionToNextStepAllowed,
  });

  const CircularProgress = useMemo(
    () => (
      <div onMouseOver={pauseProgress} onMouseOut={resumeProgress}>
        <Progress
          type="circle"
          format={() => <ScNoItemsIcon data-testid="no-item-pause-animation-icon" />}
          percent={progressValue}
          strokeColor={mainColor}
          strokeWidth={2}
          width={88}
        />
      </div>
    ),
    [progressValue, pauseProgress, resumeProgress, mainColor],
  );

  if (!isTransitionToNextStepAllowed) {
    return null;
  }

  return (
    <>
      <ScLoadNextStep
        className={classNames({
          active: isTransitionToNextStepAllowed,
        })}
        data-testid="next-step-animation-indicator"
      >
        <NoItems
          className={classNames('uppercase', {
            active: isTransitionToNextStepAllowed,
          })}
          message={message}
          component={CircularProgress}
        />
      </ScLoadNextStep>
      {children}
    </>
  );
};
