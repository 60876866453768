import Icon, { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import { TextArea } from 'components/form/TextArea';
import { mediaMax } from 'common/theme';
import styled from 'styled-components';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import Selector from 'components/form/Selector';

export const ScTradeInFormContainer = styled.div`
  margin: 0 auto 5em auto;
  padding: 0 3.75em;
`;

export const ScTradeInTile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${mediaMax.lg} {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScTradeInDocumentsContainer = styled.div`
  padding: 1.25em;
`;

export const ScTradeInForm = styled.div`
  padding: 1.375em;
  height: 100%;
`;

export const ScTradeInFieldsContainer = styled.div`
  margin-bottom: 2em;
`;

export const SubFooter = styled.div`
  width: 100%;
  display: flex;
  > button {
    flex: 1;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Footer = styled.div`
  max-width: 550px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  > button {
    margin-top: 1em;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ScTradeInHeader = styled.div`
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  padding: 1.6875em 0;
  max-height: 5em;
`;

export const ScButton = styled(Button)`
  &.ant-btn {
    padding: 0 1.75em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ScTextArea = styled(TextArea)`
  resize: none;
  padding: 1.563em;
`;

export const ScLinkIcon = styled(Icon).attrs({
  component: Link,
})`
  font-size: 1.375em;
`;

export const ScSelector = styled(Selector)``;

export const ScMarginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.0005em;

  div {
    margin-bottom: 0;
  }
`;

export const ScMarginLabel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: ${({ theme }) => theme.inputFontSize};
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`;

export const ScMarginInfoIcon = styled(QuestionCircleOutlined)`
  color: ${({ theme }) => theme.mainColor};
  margin-left: 0.5em;
  font-size: 1.6em;
`;

export const ScSearchMachineIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.mainColor};
  margin-left: 0.5em;
  font-size: 1em;
`;

export const ScTitle = styled.h3`
  width: 100%;
  text-align: center;
  line-height: 1.72375em;
  margin: 0;
  font-size: 1.25em;
  font-weight: 600;
`;
