import {
  ProspectDossierMainCustomerItem,
  ProspectDossierMainCustomerItemFields as MainFields,
  ProspectDossierAdditionalCustomersItem,
  ProspectDossierAdditionalCustomersItemFields as AdditionalFields,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerItem, CustomerFields } from 'types/vendor';

export const ProspectDossierFieldsPickers = {
  0: (
    customer: Partial<CustomerItem> | Partial<CustomerItem>,
  ): Partial<ProspectDossierMainCustomerItem> => ({
    [MainFields.customerId]: customer[CustomerFields.id],
    [MainFields.address]: customer[CustomerFields.address],
    [MainFields.address2]: customer[CustomerFields.address2],
    [MainFields.city]: customer[CustomerFields.city],
    [MainFields.commercialType]: customer[CustomerFields.commercialType],
    [MainFields.country]: customer[CustomerFields.country],
    [MainFields.email]: customer[CustomerFields.email],
    [MainFields.email2]: customer[CustomerFields.email2],
    [MainFields.lang]: customer[CustomerFields.lang],
    [MainFields.mobilePhone]: customer[CustomerFields.mobilePhone],
    [MainFields.mobilePhone2]: customer[CustomerFields.mobilePhone2],
    [MainFields.name]: customer[CustomerFields.name],
    [MainFields.name2]: customer[CustomerFields.name2],
    [MainFields.phone]: customer[CustomerFields.phone],
    [MainFields.phone2]: customer[CustomerFields.phone2],
    [MainFields.salutation]: customer[CustomerFields.salutation],
    [MainFields.title]: customer[CustomerFields.title],
    [MainFields.type]: customer[CustomerFields.type],
    [MainFields.vatCode]: customer[CustomerFields.vatNumber],
    [MainFields.vendor]: customer[CustomerFields.vendorNumber],
    [MainFields.zipcode]: customer[CustomerFields.zip],
  }),
  1: (
    customer: Partial<CustomerItem> | Partial<CustomerItem>,
  ): Partial<ProspectDossierAdditionalCustomersItem> => ({
    [AdditionalFields.customer2id]: customer[CustomerFields.id],
    [AdditionalFields.customer2address]: customer[CustomerFields.address],
    [AdditionalFields.customer2address2]: customer[CustomerFields.address2],
    [AdditionalFields.customer2city]: customer[CustomerFields.city],
    [AdditionalFields.customer2email]: customer[CustomerFields.email],
    [AdditionalFields.customer2mobilePhone]: customer[CustomerFields.mobilePhone],
    [AdditionalFields.customer2name]: customer[CustomerFields.name],
    [AdditionalFields.customer2name2]: customer[CustomerFields.name2],
    [AdditionalFields.customer2phone]: customer[CustomerFields.phone],
    [AdditionalFields.customer2salutation]: customer[CustomerFields.salutation],
    [AdditionalFields.customer2title]: customer[CustomerFields.title],
    [AdditionalFields.customer2vatCode]: customer[CustomerFields.vatNumber],
    [AdditionalFields.customer2zipcode]: customer[CustomerFields.zip],
  }),
  2: (
    customer: Partial<CustomerItem> | Partial<CustomerItem>,
  ): Partial<ProspectDossierAdditionalCustomersItem> => ({
    [AdditionalFields.customer3id]: customer[CustomerFields.id],
    [AdditionalFields.customer3address]: customer[CustomerFields.address],
    [AdditionalFields.customer3address2]: customer[CustomerFields.address2],
    [AdditionalFields.customer3city]: customer[CustomerFields.city],
    [AdditionalFields.customer3email]: customer[CustomerFields.email],
    [AdditionalFields.customer3mobilePhone]: customer[CustomerFields.mobilePhone],
    [AdditionalFields.customer3name]: customer[CustomerFields.name],
    [AdditionalFields.customer3name2]: customer[CustomerFields.name2],
    [AdditionalFields.customer3phone]: customer[CustomerFields.phone],
    [AdditionalFields.customer3salutation]: customer[CustomerFields.salutation],
    [AdditionalFields.customer3title]: customer[CustomerFields.title],
    [AdditionalFields.customer3vatCode]: customer[CustomerFields.vatNumber],
    [AdditionalFields.customer3zipcode]: customer[CustomerFields.zip],
  }),
};
