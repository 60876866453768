import React, {
  FC,
  useCallback,
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
  useRef,
  useEffect,
} from 'react';
import { LeftArrow, RightArrow } from './Arrows';
import {
  ScrollMenu,
  Props as ScrollMenuProps,
  VisibilityContext,
} from 'react-horizontal-scrolling-menu';
import { ScScrollWrapper } from './HorizontalScrollList.styles';
import { isNil } from '../../utils';

/** Amount of pixels to be moved before update. */
const STEP = 5;

export type ScrollMenuContextType = React.ContextType<typeof VisibilityContext>;

type Props = Omit<ScrollMenuProps, 'onMouseDown' | 'onMouseUp' | 'onMouseMove'> & {
  /** Field used to scroll to item if its hidden */
  activeItemIdx?: number;
};

/** Binding 'this' was creating brand-new function, so had to use Ref here. */
const HorizontalScrollList: FC<Props> = ({ activeItemIdx, ...props }) => {
  const api = useRef<ScrollMenuContextType>();
  const cachedDragPosition = useRef<number>();

  useEffect(() => {
    if (isNil(activeItemIdx) || !api) return;
    const item = api.current?.getItemByIndex(activeItemIdx);
    if (!item) return;
    if (!item.visible) api.current?.scrollToItem(item);
  }, [activeItemIdx]);

  const onMouseMove = useCallback((ev: MouseEvent) => {
    if (!cachedDragPosition.current) return;
    const diff = cachedDragPosition.current - ev.screenX;
    if (Math.abs(diff) > STEP && api.current?.scrollContainer.current) {
      api.current.scrollContainer.current.scrollLeft += diff;
      cachedDragPosition.current = ev.screenX;
    }
  }, []);

  const onMouseUp = useCallback(() => {
    document.body.removeEventListener('mousemove', onMouseMove);
    document.body.removeEventListener('mouseup', onMouseUp);
  }, [onMouseMove]);

  useEffect(() => onMouseUp, [onMouseUp]);

  const prepareOnMouseDown = useCallback(
    (localApi: ScrollMenuContextType): MouseEventHandler => {
      if (!api.current) api.current = localApi;

      return (ev: ReactMouseEvent) => {
        cachedDragPosition.current = ev.screenX;
        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp);
      };
    },
    [onMouseMove, onMouseUp],
  );

  return (
    <ScScrollWrapper>
      <ScrollMenu
        LeftArrow={<LeftArrow />}
        RightArrow={<RightArrow />}
        onMouseDown={prepareOnMouseDown}
        {...props}
      />
    </ScScrollWrapper>
  );
};

export default HorizontalScrollList;
