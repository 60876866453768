import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  featuresFlagsActions,
  featuresFlagsSelectors,
  hostSettingsSelectors,
  sharedActions,
} from 'store';
import { notification, NotificationType } from 'utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'utils/types';
import { useXdmsClient } from '../xdms/XdmsClient';
import { DynamicParameters_GetList_Output } from '@hypercharge/xdms-client/src/dynamicParameters/types';
import { RequestResponseDetails } from '../../types/common';
import {
  defaultGlobalFeaturesFlags,
  GlobalFeaturesFlags,
  GlobalFeaturesFlagsFields,
} from 'common/globalFeaturesFlags';
import {
  defaultDynamicPagesFeaturesFlags,
  DynamicStepFeaturesFlagsGrouped,
} from 'common/dynamicPagesFeaturesFlags';
import {
  assembleDynamicPagesFeaturesFlagsKey,
  assembleFeaturesFlagsKey,
} from '../../common/assemblers';
import { ERROR_REGEXP__SERVER_DOWN } from '../../common/constants';
import { useTranslation } from 'react-i18next';
import { DefaultParamsListItem } from '@hypercharge/xdms-client/lib/types';

export enum FeatureSource {
  // dynamic pages such as OPT / ACC / PCK / T&C etc
  DYNAMIC = 'dynamic',
  // global features
  GLOBAL = 'global',
}

type SourceToFeatureHandler = {
  [key in FeatureSource]: (feature: string) => boolean;
};

export type GetFeatureValueParams = {
  feature: string;
  source?: FeatureSource;
};

type ContextValue = {
  isFeatureEnabled(params: GetFeatureValueParams): boolean;
  getFeaturesSetByKey<T>(
    key: string | null | undefined,
  ): Promise<RequestResponseDetails<T | null | undefined>>;
  allowImportConfiguration: boolean;
};

const FeatureContext = React.createContext<ContextValue | undefined>(undefined);

interface Props {
  value?: ContextValue;
  preventAutoload?: boolean;
}

/** {@link preventAutoload} was added due to recursive renders caused by effect. */
const FeatureProvider: FC<PropsWithChildren<Props>> = ({ preventAutoload, ...props }) => {
  const { t } = useTranslation();
  const { xdmsClient } = useXdmsClient();

  const customerId = useSelector(hostSettingsSelectors.getCustomerId);
  const { globalFeatures, currentPageFeatures } = useSelector(
    featuresFlagsSelectors.getAll,
  );
  const dispatch = useDispatch();

  /** ### Get single rel-data table field by key */
  const getFeaturesSetByKey = useCallback<ContextValue['getFeaturesSetByKey']>(
    async function <T>(key) {
      let result: RequestResponseDetails<T>;
      try {
        const response: DynamicParameters_GetList_Output =
          await xdmsClient.dynamicParameters.getList({ key: key });

        result = {
          response: response.response as T,
          status: Status.Success,
        };
      } catch (e) {
        notification.requestError(e);
        result = {
          messageHandled: true,
          status: Status.Error,
          errors: [e as Error],
        };
      }

      return result;
    },
    [xdmsClient],
  );

  /** Initially get global features
   * Its wrong and inconsistent to call it here.
   * Used to call it in {@link AuthenticatedSessionProvider}, BUT, according to reinitialization
   * of {@link xdmsClient} relatively to one feature value, needs to call for features here.
   *
   * Also logs user out (with saving current configuration) if server responded with 'down' error.
   * */
  useEffect(() => {
    if (preventAutoload) return;
    dispatch(featuresFlagsActions.setGlobalFeaturesFlagsStatus(Status.Loading));

    const key = assembleFeaturesFlagsKey(customerId);
    getFeaturesSetByKey<GlobalFeaturesFlags>(key).then(({ response, status, errors }) => {
      if (status === Status.Success) {
        const result: GlobalFeaturesFlags =
          !response || !Object.keys(response).length
            ? defaultGlobalFeaturesFlags
            : response;

        dispatch(featuresFlagsActions.setGlobalFeaturesFlags(result));
        dispatch(featuresFlagsActions.setGlobalFeaturesFlagsStatus(Status.Success));
      }

      if (status === Status.Error) {
        dispatch(featuresFlagsActions.setGlobalFeaturesFlagsStatus(Status.Error));

        const hasServerDownError: boolean | undefined = errors
          ?.map(err => {
            if (err instanceof Error) return err.message;
            return err;
          })
          .some(string => ERROR_REGEXP__SERVER_DOWN.test(string));

        if (hasServerDownError) {
          notification.open({
            message: t('SERVER_DOWN_ERROR'),
            type: NotificationType.error,
          });

          dispatch(sharedActions.setIsServerDown(true));
        }
      }
    });
  }, [customerId, dispatch, getFeaturesSetByKey, preventAutoload, t]);

  /** Same description as above for globalFeatures retrieval */
  useEffect(() => {
    if (preventAutoload) return;
    dispatch(featuresFlagsActions.setDynamicStepsFeaturesStatus(Status.Loading));

    const key = assembleDynamicPagesFeaturesFlagsKey(customerId);
    getFeaturesSetByKey<DynamicStepFeaturesFlagsGrouped>(key).then(({ response }) => {
      const result: DynamicStepFeaturesFlagsGrouped =
        !response || !Object.keys(response).length
          ? defaultDynamicPagesFeaturesFlags
          : response;

      dispatch(featuresFlagsActions.setDynamicStepsFeatures(result));
      dispatch(featuresFlagsActions.setDynamicStepsFeaturesStatus(Status.Success));
    });
  }, [customerId, dispatch, getFeaturesSetByKey, preventAutoload]);

  const getDefaultValues = useCallback<
    () => Promise<RequestResponseDetails<DefaultParamsListItem | null>>
  >(async () => {
    let result: RequestResponseDetails<DefaultParamsListItem | null>;
    try {
      const response: DefaultParamsListItem | null =
        await xdmsClient.shared.getDefaultParametersList();

      result = {
        response: response,
        status: Status.Success,
      };
    } catch (e) {
      notification.requestError(e);
      result = {
        messageHandled: true,
        status: Status.Error,
        errors: [e as Error],
      };
    }

    return result;
  }, [xdmsClient]);

  useEffect(() => {
    if (preventAutoload) return;
    getDefaultValues().then(({ response }) => {
      dispatch(featuresFlagsActions.setDefaultValues(response));
    });
  }, [dispatch, getDefaultValues, preventAutoload]);

  const getDynamicPageFeatureValue = useCallback(
    (feature: string): boolean => {
      return currentPageFeatures ? Boolean(currentPageFeatures[feature]) : true;
    },
    [currentPageFeatures],
  );

  const getGlobalFeatureValue = useCallback(
    (feature: string): boolean => Boolean(globalFeatures?.[feature]),
    [globalFeatures],
  );

  const sourceToFeatureHandler: SourceToFeatureHandler = useMemo(() => {
    return {
      [FeatureSource.DYNAMIC]: getDynamicPageFeatureValue,
      [FeatureSource.GLOBAL]: getGlobalFeatureValue,
    };
  }, [getDynamicPageFeatureValue, getGlobalFeatureValue]);

  /**
   * Determines whether feature is enabled / disabled
   * @param params {GetFeatureValueParams}
   * @param params.feature {string} - feature name
   * @param params.source {FeatureSource} - source to feature upon
   * @returns {boolean} Whether feature is enabled / disabled
   */
  const isFeatureEnabled = useCallback<ContextValue['isFeatureEnabled']>(
    (params: GetFeatureValueParams): boolean => {
      const { feature, source = FeatureSource.GLOBAL } = params;
      const handler = sourceToFeatureHandler[source];
      if (!handler) {
        // default to as if feature is enabled if no handler found
        // in order to not break current behavior
        return true;
      }

      return handler(feature);
    },
    [sourceToFeatureHandler],
  );

  const isConfigurationImportFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationImport,
  });
  const isConfigurationImportCreateFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationImportCreate,
  });

  const allowImportConfiguration = useMemo(() => {
    return (
      isConfigurationImportFeatureEnabled && isConfigurationImportCreateFeatureEnabled
    );
  }, [isConfigurationImportFeatureEnabled, isConfigurationImportCreateFeatureEnabled]);

  const value = useMemo(
    () => ({
      isFeatureEnabled,
      currentPageFeatures,
      globalFeatures,
      getFeaturesSetByKey,
      allowImportConfiguration,
    }),
    [
      isFeatureEnabled,
      currentPageFeatures,
      globalFeatures,
      getFeaturesSetByKey,
      allowImportConfiguration,
    ],
  );

  return <FeatureContext.Provider value={value} {...props} />;
};

const useFeature = (): ContextValue => {
  const context = useContext(FeatureContext);

  if (context === undefined) {
    throw new Error(`${useFeature.name} must be used within an ${FeatureProvider.name}`);
  }

  return context;
};

export { FeatureProvider, useFeature };
