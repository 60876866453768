import { useCallback } from 'react';
import { ConfigurationTotalCalculations } from 'types/vendor';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { RequestResponseDetails } from 'types/common';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';

interface SharedOptionsApi {
  loadConfigurationTotalsCalculations(
    configurationNumber: number | string,
  ): Promise<RequestResponseDetails<ConfigurationTotalCalculations | null>>;
}

export const useConfigurationCalcApi = (): SharedOptionsApi => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const loadConfigurationTotalsCalculations = useCallback<
    SharedOptionsApi['loadConfigurationTotalsCalculations']
  >(
    async (configurationNumber: number | string) => {
      let result: RequestResponseDetails<ConfigurationTotalCalculations | null>;
      try {
        const response = await xdmsClient.configuration.getPriceCalculations(
          configurationNumber,
        );
        result = { status: Status.Error, messageHandled: false, response: response };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [xdmsClient.configuration],
  );

  return {
    loadConfigurationTotalsCalculations: loadConfigurationTotalsCalculations,
  };
};
