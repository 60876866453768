import { ReactComponent as ListMenuIcon } from 'assets/icons/list-menu.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ViewSwitcherButton from './button';
import { TableView } from './types';

type Props = {
  disabled: boolean;
  isTableViewExtended: boolean;
  changeTableView(tableViewExtendedStatus: boolean): void;
};

const ViewSwitcher: FC<Props> = ({ disabled, isTableViewExtended, changeTableView }) => {
  const { t } = useTranslation();

  const tableViewToggle = useCallback(
    (tableView: TableView) => {
      if (disabled) return;
      let tableViewExtendedStatus = isTableViewExtended;
      switch (tableView) {
        case TableView.extended:
          tableViewExtendedStatus = true;
          break;
        case TableView.list:
          tableViewExtendedStatus = false;
          break;
        default:
          break;
      }

      if (isTableViewExtended !== tableViewExtendedStatus) {
        changeTableView(tableViewExtendedStatus);
      }
    },
    [isTableViewExtended, disabled, changeTableView],
  );

  const tableViewSwitcherButtons = useMemo(
    () => [
      {
        Icon: MenuIcon,
        active: !isTableViewExtended,
        key: TableView.list,
        tooltip: t('TABLE_LIST_VIEW_TOOLTIP'),
      },
      {
        Icon: ListMenuIcon,
        active: isTableViewExtended,
        key: TableView.extended,
        tooltip: t('TABLE_EXTENDED_VIEW_TOOLTIP'),
      },
    ],
    [isTableViewExtended, t],
  );

  return (
    <>
      {tableViewSwitcherButtons.map(({ Icon, active, key, tooltip }) => (
        <ViewSwitcherButton
          key={key}
          id={key}
          tooltipText={tooltip}
          disabled={disabled}
          active={active}
          onClick={tableViewToggle}
        >
          <Icon />
        </ViewSwitcherButton>
      ))}
    </>
  );
};

export default ViewSwitcher;
