import styled from 'styled-components';

export const ScStreamingTerminalTitleWrapper = styled.span`
  display: flex;
  flex-direction: rown;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ScStreamingTerminalTitleStatus = styled.span<{ color: string }>`
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const ScStreamingTerminalTitle = styled.span`
  font-size: 0.875em;
  font-weight: 400;
`;
