import {
  DocumentItem,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
} from 'context/document/types';
import React, { FC, useState } from 'react';
import DocumentFormUpdate from '../Form/DocumentFormUpdate';
import { ScDocumentListDetail } from './DocumentsList.styles';
import DocumentHeader, { getDocumentHeaderIcon } from './Header/DocumentHeader';

interface Props {
  document: DocumentItem;

  relatedEntity: DocumentRelatedEntity;
  relatedEntityCode: DocumentRelatedEntityCode;

  isLoaderVisible: boolean;

  reload?(): void;

  onCheck(): void;
  isCheckSelected: boolean;
  isCheckVisible: boolean;
  isCheckDisabled: boolean;

  isCaretVisible: boolean;

  onDownload(): void;
  isDownloadVisible: boolean;

  onCopy(): void;
  isCopyVisible: boolean;

  onOpen(): void;
  isOpenVisible: boolean;

  onDelete(): void;
  isDeleteVisible: boolean;
}

const DocumentsListItem: FC<Props> = ({
  document,
  relatedEntity,
  relatedEntityCode,
  isLoaderVisible,
  reload,
  onCheck,
  isCheckSelected,
  isCheckVisible,
  isCheckDisabled,
  isCaretVisible,
  onDownload,
  isDownloadVisible,
  onCopy,
  isCopyVisible,
  onOpen,
  isOpenVisible,
  onDelete,
  isDeleteVisible,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <DocumentHeader
        name={document.name}
        createdAt={document.computed.createdAt}
        icon={getDocumentHeaderIcon(document)}
        isDraggable={document.computed.isDraggable}
        isLoaderVisible={isLoaderVisible}
        onCheck={onCheck}
        isCheckSelected={isCheckSelected}
        isCheckDisabled={isCheckDisabled || !document.computed.isSelectable}
        isCheckVisible={isCheckVisible}
        isCaretVisible={isCaretVisible}
        onCaret={() => setIsOpen(value => !value)}
        isCaretOpen={isOpen}
        onDownload={onDownload}
        isDownloadVisible={isDownloadVisible}
        onCopy={onCopy}
        isCopyVisible={isCopyVisible}
        onOpen={onOpen}
        isOpenVisible={isOpenVisible}
        onDelete={onDelete}
        isDeleteVisible={isDeleteVisible}
      />
      {isOpen && (
        <ScDocumentListDetail>
          <DocumentFormUpdate
            document={document}
            relatedEntity={relatedEntity}
            relatedEntityCode={relatedEntityCode}
            reload={() => {
              reload?.();
              setIsOpen(false);
            }}
          />
        </ScDocumentListDetail>
      )}
    </>
  );
};

export default DocumentsListItem;
