import * as Sentry from '@sentry/react';
import { Status } from 'utils/types';

export const copyToClipboard = async (content: string): Promise<Status> => {
  let status: Status;

  try {
    await navigator.clipboard.writeText(content);
    status = Status.Success;
  } catch (e: unknown) {
    Sentry.captureException(e);
    status = Status.Error;
  }

  return status;
};

export const copyToClipboardAsHtml = (content: string): Status => {
  let status: Status;

  const handleCopyEvent = evt => {
    evt.clipboardData.setData('text/html', content);
    evt.preventDefault();
  };

  try {
    document.addEventListener('copy', handleCopyEvent);
    document.execCommand('copy');
    document.removeEventListener('copy', handleCopyEvent);
    status = Status.Success;
  } catch (e: unknown) {
    Sentry.captureException(e);
    status = Status.Error;
  }

  return status;
};
