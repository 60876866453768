import React, {
  FC,
  PropsWithChildren,
  ReactComponentElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { get, groupBy, reverse } from 'utils';
import {
  ModelFields,
  Model_CommonItemFields,
  StructureLabelFields,
  Model_CommonCategoryFields,
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfDetailsFields,
  StructureLabel,
  StructureParamFields,
  StructureParamNameValues,
  StructureFields,
  Structure,
} from '@hypercharge/xdms-client/lib/types';
import { useTranslation } from 'react-i18next';
import {
  ACCESSORIES_PAGE_URL,
  CLIENTS_URL,
  CONFIGURATIONS_URL,
  DOCUMENTS_URL,
  FINANCING_URL,
  INFO_URL,
  LOGIN_URL,
  MODELS_URL,
  OPTIONS_PAGE_URL,
  PACKAGES_PAGE_URL,
  PAGES_WITH_REQUIRED_CATEGORIES,
  PRICE_LIST_URL,
  SOFT_OFFER_URL,
  STEPS_URL,
  TAXES_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
  TOTAL_URL,
  TRADE_IN_URL,
  XDMS_SETTINGS_URL,
} from 'common/constants';
import {
  InfoIcon,
  DocumentIcon,
  CustomerIcon,
  TotalIcon,
  TradeInIcon,
  TaxIcon,
  SoftOfferIcon,
} from './icons';
import { useRelations } from 'context/relations/RelationsProvider';
import { useHistory } from 'react-router-dom';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { PAGES_SETTINGS } from '../tableCategories/TableCategoriesProvider';
import useInconfiguredCategory from 'hooks/useInconfiguredCategory';
import { useSelector } from 'react-redux';
import {
  configurationSelectors,
  modelSelectors,
  sharedSelectors,
  structureSelectors,
} from 'store';
import { UrlTransform } from 'utils/urlTransform';
import { NotificationType, notification } from 'utils/notification';

// urls one can access even of there are inconfigured category(s)
const PAGES_GO_TO_AVOID_CHECK = [
  CLIENTS_URL,
  CONFIGURATIONS_URL,
  OPTIONS_PAGE_URL,
  LOGIN_URL,
  XDMS_SETTINGS_URL,
];

/** Pages to check, which user want to redirect to. */
const PAGES_NEXT_TO_CHECK = [TOTAL_URL];

type DynamicStep = {
  title: string;
  available: boolean;
  order: number;
};

export type Step = {
  title: string;
  link: string;
  icon: ReactComponentElement<'svg'> | null;
  getDisabledMessage(): string | null;
  hidden: boolean;
  position: number;
  onLoad?(): void;
};

type ContextValue = {
  steps: Step[];
  activeStepIndex: number;
  handleStepClick(position: number): void;
  handlePrevStep(): void;
  handleNextStep(): void;
};

const StepContext = React.createContext<ContextValue | undefined>(undefined);

const StepProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search, pathname } = history.location;

  const { areMandatoryRelationsFilled } = useRelations();
  const model = useSelector(modelSelectors.getModel);
  const { isFeatureEnabled } = useFeature();
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);

  const structure = useSelector(structureSelectors.getStructure);
  const { configurationNumber, modelVersionNumber, modelNumber, modelCatalogCode } =
    useSelector(configurationSelectors.getConfigurationCommonVariables);
  const configuration = useSelector(configurationSelectors.getConfiguration);
  const configurationDetails = useSelector(
    configurationSelectors.getConfigurationAllDetails,
  );
  const { isConfigurationComplete, isModelConfigured } = useSelector(
    modelSelectors.getAll,
  );

  const prospectDossierId = configuration?.[ConfDetailsFields.projectId];

  const { inconfiguredCategory } = useInconfiguredCategory();

  const isWithTaxesPageFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTaxesPage,
  });
  const isWithSoftOfferPageFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferPage,
  });
  const isWithFinancingPageFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowFinancingPage,
  });
  const isTermsAndConditionsStepFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTermsAndConditionsStep,
  });
  const isAllowInfoPageSalesCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSalesCode,
  });
  const isAllowInfoPageSellCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSellCode,
  });
  const isRequiredCategoriesFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RequiredCategories,
  });

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [dynamicSteps, setDynamicSteps] = useState<Record<string, DynamicStep>>({});

  const hasTaxesRecords = model?.[ModelFields.taxes]?.some(
    tax => !tax[Model_CommonItemFields.isDummy],
  );

  const stepsOrder = useMemo<string[]>(() => {
    const steps = get(structure, StructureFields.steps, []);

    const stepsSequenceStep = steps.find(step => {
      return step[StructureParamFields.name] === StructureParamNameValues.sequence;
    });
    return stepsSequenceStep?.[StructureParamFields.value]?.split(',') ?? [];
  }, [structure]);

  const hasInconfiguredStep = useCallback(
    (nextPosition: number, steps: Step[]): boolean => {
      if (!isRequiredCategoriesFeatureEnabled || !inconfiguredCategory) return false;

      const isPrevPageShouldBeChecked = PAGES_WITH_REQUIRED_CATEGORIES.some(path =>
        pathname.includes(path),
      );

      const nextStepBase = steps[nextPosition];

      if (!nextStepBase) return false;

      const newPathname = nextStepBase.link;

      // pages that are source of the check, no need to re-run check on them
      // as most probably we handle them separately per page (might have different rules per page)
      // for example, total page needs to be checked only once on page load
      const isNextPageShouldSkipCheck =
        PAGES_GO_TO_AVOID_CHECK.includes(newPathname) ||
        PAGES_WITH_REQUIRED_CATEGORIES.some(path => newPathname.includes(path));

      /** Overrides {@link isNextPageShouldSkipCheck} and {@link isPrevPageShouldBeChecked} */
      const isNextPageShouldBeChecked = PAGES_NEXT_TO_CHECK.includes(newPathname);

      if (
        (isPrevPageShouldBeChecked && !isNextPageShouldSkipCheck) ||
        isNextPageShouldBeChecked
      ) {
        const { category, stepId } = inconfiguredCategory;

        const categoryName = category[Model_CommonCategoryFields.name];
        const categoryId = category[Model_CommonCategoryFields.lvl1];

        if (!window.confirm(t('INCONFIGURED_CATEGORY_ALERT', { category: categoryName })))
          return false;

        const selectedCategoryQueryName = PAGES_SETTINGS[stepId].SELECTED_CATEGORY_NAME;

        const parsedSearch = UrlTransform.parseQuery(search) as Record<string, string>;
        parsedSearch[selectedCategoryQueryName] = categoryId;
        const newSearch = UrlTransform.stringifyQuery(parsedSearch);

        history.push({
          search: newSearch,
          pathname: `${STEPS_URL}/${stepId}`,
        });

        return true;
      }

      return false;
    },
    [
      inconfiguredCategory,
      isRequiredCategoriesFeatureEnabled,
      pathname,
      t,
      search,
      history,
    ],
  );

  const handleTaxesStepLoad = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTotalStepLoad = useCallback(
    (steps: Step[]) => {
      if (
        !isRequiredCategoriesFeatureEnabled ||
        // detect if everything is settled
        isWholePageLoading ||
        // we should allow customer
        // to access total page because
        // they may create trade-in without
        // model configuration and thus still
        // should be able to access total page
        !isModelConfigured
      ) {
        return;
      }

      const salesCode = configurationDetails?.configuration[ConfDetailsFields.salesCode];
      const sellCode = configurationDetails?.configuration[ConfDetailsFields.sellCode];

      const isMissingSalesCode = isAllowInfoPageSalesCode && !salesCode;
      const isMissingSellCode = isAllowInfoPageSellCode && !sellCode;

      if (isMissingSalesCode || isMissingSellCode) {
        history.push({ pathname: INFO_URL, search: search });
        return;
      }

      const totalPosition = steps.find(stepBase => stepBase.link === TOTAL_URL)
        ?.position as number;

      const shouldSkip = hasInconfiguredStep(totalPosition, steps);

      if (shouldSkip) return;

      window.scrollTo(0, 0);
    },
    [
      isRequiredCategoriesFeatureEnabled,
      isWholePageLoading,
      isModelConfigured,
      configurationDetails,
      isAllowInfoPageSalesCode,
      isAllowInfoPageSellCode,
      hasInconfiguredStep,
      history,
      search,
    ],
  );

  // keeps previous state of `steps`
  // used by `handleTotalStepLoad` method
  const prevStepsRef = useRef<Step[]>();
  const steps = useMemo<Step[]>(() => {
    const dynamicStepsByTitle = groupBy(dynamicSteps, dynamicStep => dynamicStep.title);

    const result: Step[] = [
      {
        title: 'CLIENT',
        getDisabledMessage: () => null,
        hidden: false,
        link: CLIENTS_URL,
        icon: <CustomerIcon />,
      },
      {
        title: 'PRICE_LIST',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (
            Boolean(configurationNumber) &&
            Boolean(prospectDossierId) &&
            !isConfigurationComplete
          ) {
            return t('STEP_CONFIGURATION_NOT_COMPLETED');
          }

          return null;
        },
        hidden: false,
        link: PRICE_LIST_URL,
        icon: null,
      },
      {
        title: 'MODELS',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          // @todo: add when the BE works
          // !get(offer, OfferItemFields.catalogCode);
          if (!(modelCatalogCode && modelVersionNumber)) {
            return t('STEP_MODEL_DATA_IS_MISSING');
          }

          if (isConfigurationComplete) {
            return t('STEP_CONFIGURATION_COMPLETED');
          }

          return null;
        },
        hidden: false,
        link: MODELS_URL,
        icon: null,
      },
      {
        title: dynamicSteps?.[PACKAGES_PAGE_URL]?.title || PACKAGES_PAGE_URL,
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!modelNumber) {
            return t('STEP_MODEL_NUMBER_IS_MISSING');
          }

          if (!isModelConfigured) {
            return t('STEP_MODEL_NOT_CONFIGURED');
          }

          return null;
        },
        hidden: !dynamicSteps?.[PACKAGES_PAGE_URL]?.available,
        link: `${STEPS_URL}/${PACKAGES_PAGE_URL}`,
        icon: null,
      },
      {
        title: dynamicSteps?.[OPTIONS_PAGE_URL]?.title || OPTIONS_PAGE_URL,
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!modelNumber) {
            return t('STEP_MODEL_NUMBER_IS_MISSING');
          }

          if (!isModelConfigured) {
            return t('STEP_MODEL_NOT_CONFIGURED');
          }

          return null;
        },
        hidden: !dynamicSteps?.[OPTIONS_PAGE_URL]?.available,
        link: `${STEPS_URL}/${OPTIONS_PAGE_URL}`,
        icon: null,
      },
      {
        title: dynamicSteps?.[ACCESSORIES_PAGE_URL]?.title || ACCESSORIES_PAGE_URL,
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!modelNumber) {
            return t('STEP_MODEL_NUMBER_IS_MISSING');
          }

          if (!isModelConfigured) {
            return t('STEP_MODEL_NOT_CONFIGURED');
          }

          return null;
        },
        hidden: !dynamicSteps?.[ACCESSORIES_PAGE_URL]?.available,
        link: `${STEPS_URL}/${ACCESSORIES_PAGE_URL}`,
        icon: null,
      },
      {
        title:
          dynamicSteps?.[TERMS_AND_CONDITIONS_PAGE_URL]?.title ||
          TERMS_AND_CONDITIONS_PAGE_URL,
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!modelNumber) {
            return t('STEP_MODEL_NUMBER_IS_MISSING');
          }

          if (!isModelConfigured) {
            return t('STEP_MODEL_NOT_CONFIGURED');
          }

          return null;
        },
        hidden:
          !isTermsAndConditionsStepFeatureEnabled ||
          !dynamicSteps?.[TERMS_AND_CONDITIONS_PAGE_URL]?.available,
        link: `${STEPS_URL}/${TERMS_AND_CONDITIONS_PAGE_URL}`,
        icon: null,
      },
      {
        title: 'TRADE_IN',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          return null;
        },
        hidden: false,
        link: TRADE_IN_URL,
        icon: <TradeInIcon />,
      },
      {
        title: 'INFO',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!modelNumber) {
            return t('STEP_MODEL_NUMBER_IS_MISSING');
          }

          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          return null;
        },
        hidden: false,
        link: INFO_URL,
        icon: <InfoIcon />,
      },
      {
        title: 'TAXES',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          return null;
        },
        hidden: !isModelConfigured || !isWithTaxesPageFeatureEnabled || !hasTaxesRecords,
        onLoad: () => handleTaxesStepLoad(),
        link: TAXES_URL,
        icon: <TaxIcon />,
      },
      {
        title: 'TOTAL',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          return null;
        },
        hidden: false,
        onLoad: () => prevStepsRef.current && handleTotalStepLoad(prevStepsRef.current),
        link: TOTAL_URL,
        icon: <TotalIcon />,
      },
      {
        title: 'SOFT_OFFER',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          return null;
        },
        hidden: !isWithSoftOfferPageFeatureEnabled,
        link: SOFT_OFFER_URL,
        icon: <SoftOfferIcon />,
      },
      {
        title: 'FINANCING',
        getDisabledMessage: () => {
          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          return null;
        },
        hidden: !isWithFinancingPageFeatureEnabled,
        link: FINANCING_URL,
        icon: <SoftOfferIcon />,
      },
      {
        title: 'DOCUMENTS',
        getDisabledMessage: () => {
          if (!configurationNumber) {
            return t('STEP_CONFIGURATION_NUMBER_IS_MISSING');
          }

          if (!areMandatoryRelationsFilled) {
            return t('STEP_MANDATORY_RELATIONS_ARE_NOT_FILLED');
          }

          // @todo: add when the BE works
          // !get(offer, OfferItemFields.catalogCode);
          if (!(modelCatalogCode && modelVersionNumber)) {
            return t('STEP_MODEL_DATA_IS_MISSING');
          }

          return null;
        },
        hidden: false,
        link: DOCUMENTS_URL,
        icon: <DocumentIcon />,
      },
    ]
      .sort((a, b) => {
        if (!dynamicStepsByTitle[a.title]?.[0] || !dynamicStepsByTitle[b.title]?.[0])
          return 0;

        return (
          dynamicStepsByTitle[a.title][0].order - dynamicStepsByTitle[b.title][0].order
        );
      })
      .map((step, index) => ({ ...step, position: index }));

    prevStepsRef.current = result;

    return result;
  }, [
    areMandatoryRelationsFilled,
    configurationNumber,
    handleTotalStepLoad,
    isModelConfigured,
    hasTaxesRecords,
    isConfigurationComplete,
    isTermsAndConditionsStepFeatureEnabled,
    isWithSoftOfferPageFeatureEnabled,
    isWithFinancingPageFeatureEnabled,
    isWithTaxesPageFeatureEnabled,
    modelCatalogCode,
    modelNumber,
    modelVersionNumber,
    prospectDossierId,
    handleTaxesStepLoad,
    dynamicSteps,
    t,
  ]);

  const handleStepClick = useCallback<ContextValue['handleStepClick']>(
    (position: number) => {
      const clickedStep = steps[position];

      const disabledMessage = clickedStep.getDisabledMessage();

      if (disabledMessage) {
        notification.open({
          message: disabledMessage,
          type: NotificationType.error,
        });
        return;
      }

      const shouldSkip = hasInconfiguredStep(position, steps);

      if (shouldSkip) return;

      history.push({
        pathname: steps[position].link,
        search: search,
      });
    },
    [hasInconfiguredStep, history, search, steps],
  );

  const handlePrevStep = useCallback(() => {
    const stepIndex = reverse(steps.slice(0, activeStepIndex)).find(
      step => !step.getDisabledMessage() && !step.hidden,
    )?.position;

    if (!stepIndex) return;

    const shouldSkip = hasInconfiguredStep(stepIndex, steps);

    if (shouldSkip) return;

    history.push({
      pathname: steps[stepIndex].link,
      search: search,
    });
  }, [activeStepIndex, hasInconfiguredStep, history, search, steps]);

  const handleNextStep = useCallback(() => {
    const stepIndex = steps
      .slice(activeStepIndex + 1)
      .find(step => !step.getDisabledMessage() && !step.hidden)?.position;

    if (!stepIndex) return;

    const shouldSkip = hasInconfiguredStep(stepIndex, steps);

    if (shouldSkip) return;

    history.push({ pathname: steps[stepIndex].link, search: search });
  }, [activeStepIndex, hasInconfiguredStep, history, search, steps]);

  useEffect(() => {
    if (!structure) {
      setDynamicSteps({});
      return;
    }

    const result = getDynamicSteps(structure, stepsOrder).reduce<
      Record<string, DynamicStep>
    >((result, dynamicStructure, index) => {
      const dynamicStepId = dynamicStructure[StructureLabelFields.code];
      const title = get(dynamicStructure, StructureLabelFields.description, '');
      result[dynamicStepId] = { available: true, title, order: index + 1 };
      return result;
    }, {});

    setDynamicSteps(result);
  }, [isModelConfigured, structure, stepsOrder]);

  useEffect(() => {
    const matchedStep = steps.find(step => pathname.includes(step.link));
    setActiveStepIndex(matchedStep?.position ?? 0);
  }, [steps, pathname]);

  // 'lastLoadedStepIndex' is a hack to 'avoid' onLoad being reinvoked when 'steps' update
  const [lastLoadedStepIndex, setLastLoadedStepIndex] = useState<number>();
  useEffect(() => {
    if (activeStepIndex !== lastLoadedStepIndex) {
      steps[activeStepIndex]?.onLoad?.();
      setLastLoadedStepIndex(activeStepIndex);
    }
  }, [activeStepIndex, steps, lastLoadedStepIndex]);

  const value = useMemo(
    () => ({
      steps,
      activeStepIndex,
      handleStepClick,
      handlePrevStep,
      handleNextStep,
    }),
    [steps, activeStepIndex, handleStepClick, handlePrevStep, handleNextStep],
  );

  return <StepContext.Provider value={value} {...props} />;
};

const useStep = (): ContextValue => {
  const context = useContext(StepContext);

  if (context === undefined) {
    throw new Error('useStep must be used within an StepProvider');
  }

  return context;
};

export { useStep, StepProvider };

/**
 * ## Get list of {@link StructureLabel} for dynamic steps only.
 * @description Picks only defined in "ttParam.SEQUENCE" and sorted in same sequence.
 */
const getDynamicSteps = (structure: Structure, stepsSequence: string[]) => {
  if (!structure) return [];

  const labels: StructureLabel[] = get(structure, StructureFields.labels, []);

  return labels
    .filter(item => {
      const code = get(item, StructureLabelFields.code, '');
      return stepsSequence.includes(code);
    })
    .sort(
      (a, b) =>
        stepsSequence.indexOf(a[StructureLabelFields.code]) -
        stepsSequence.indexOf(b[StructureLabelFields.code]),
    );
};
