import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { DocumentTypeListItem } from '@hypercharge/xdms-client/lib/types';

const NAME = 'documentTypes';

interface State {
  readonly documentTypeCategoryListStatus: Status;
  readonly documentTypeCategoryTypesListStatus: Status;
  readonly documentTypesListStatus: Status;
  readonly documentTypesList: DocumentTypeListItem[];
}

const initialState: State = {
  documentTypeCategoryListStatus: Status.Idle,
  documentTypeCategoryTypesListStatus: Status.Idle,
  documentTypesListStatus: Status.Idle,
  documentTypesList: [],
};

const actions = {
  setDocumentTypeCategoryListStatus: createAction<
    State['documentTypeCategoryListStatus']
  >(getActionName(NAME, 'SET_CATEGORIES_LIST_STATUS')),
  setDocumentTypeCategoryTypesListStatus: createAction<
    State['documentTypeCategoryTypesListStatus']
  >(getActionName(NAME, 'SET_CATEGORY_TYPES_LIST_STATUS')),
  setDocumentTypesListStatus: createAction<State['documentTypesListStatus']>(
    getActionName(NAME, 'SET_TYPES_LIST_STATUS'),
  ),
  setDocumentTypesList: createAction<State['documentTypesList']>(
    getActionName(NAME, 'SET_TYPES_LIST'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setDocumentTypeCategoryListStatus, (state, action) => ({
    ...state,
    documentTypeCategoryListStatus: action.payload,
  }));
  builder.addCase(actions.setDocumentTypeCategoryTypesListStatus, (state, action) => ({
    ...state,
    documentTypeCategoryTypesListStatus: action.payload,
  }));
  builder.addCase(actions.setDocumentTypesListStatus, (state, action) => ({
    ...state,
    documentTypesListStatus: action.payload,
  }));
  builder.addCase(actions.setDocumentTypesList, (state, action) => ({
    ...state,
    documentTypesList: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ documentTypes }: ApplicationState) => documentTypes,
    (state: State) => state,
  ),
  getTypeCategoryListStatus: createSelector(
    ({ documentTypes }: ApplicationState) => documentTypes,
    (state: State) => state.documentTypeCategoryListStatus,
  ),
  getTypeCategoryTypesListStatus: createSelector(
    ({ documentTypes }: ApplicationState) => documentTypes,
    (state: State) => state.documentTypeCategoryTypesListStatus,
  ),
  getTypesListStatus: createSelector(
    ({ documentTypes }: ApplicationState) => documentTypes,
    (state: State) => state.documentTypesListStatus,
  ),
  getTypesList: createSelector(
    ({ documentTypes }: ApplicationState) => documentTypes,
    (state: State) => state.documentTypesList,
  ),
};

export type { State as DocumentTypesState };
export {
  actions as documentTypesActions,
  reducer as documentTypesReducer,
  selectors as documentTypesSelectors,
};
