import {
  ConfigurationListItemFields,
  LanguageFields,
  StructureFields,
  StructureFilterFields,
  StructureFilter_SelectedFields,
  StructureItem,
  StructureItemFields,
  StructureLabel,
  StructureLabelFields,
  Message,
} from 'types/vendor';
import { ModelFields, PriceListFields } from 'types/vendor';
import { MODELS_FILTERING_KEYS, URL_QUERY_PARAMS } from 'common/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { sizes } from 'common/theme';
import Container from 'components/container/Container';
import Checkbox from 'components/form/Checkbox';
import BubblingPreventer from 'components/table/BubblingPreventer';
import { ExtendedImageTableItem } from 'components/table/ExtendedImageTableItem';
import { NextStepTransition } from 'components/table/NextStepTransition';
import { decodeModelNumber, encodeModelNumber } from 'context/model/utils/modelNumber';
import { useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { usePriceList } from 'context/priceList/PriceListProvider';
import { SetQueryParamArg, useQuery } from 'context/router/UrlQueryProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { useStructureApi } from 'context/structure/useStructureApi';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useLanguage } from 'context/language/LanguageProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useStep } from 'context/step/StepProvider';
import { useBackgroundImage } from 'hooks/useBackgroundImage';
import Layout from 'layout/Default/Layout';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import {
  authSelectors,
  configurationSelectors,
  hostSettingsSelectors,
  modelSelectors,
  sharedSelectors,
  structureSelectors,
} from 'store';
import { ImageItem, ScrollMenuOptions } from 'types/common';
import { get, orderBy } from 'utils';
import { getTtParams, mapToTableFormat, sortTableDataBySearchValue } from 'utils/format';
import { getCarouselFormattedImages, getExtendedImage } from 'utils/table-images';
import { ParsedQuery, UrlTransform } from 'utils/urlTransform';
import { ScTable } from './index.styles';
import { removeAllLabelsQueryParams } from './utils';
import TableImageGallery from 'components/ImageGalleryModal';
import SplitPane from 'components/table/splitPane';
import { ModelImage } from 'components/ModelImage';
import TableFilters from 'components/table/filters';
import { RowSelectionType } from 'antd/lib/table/interface';
import { notification } from 'utils/notification';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';

export const Models: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { getConfigurationDetailsById } = useConfiguration();
  const { getModel } = useModelApi();
  const { getStructure } = useStructureApi();
  const { selectedPriceListItem } = usePriceList();
  const { isFeatureEnabled } = useFeature();
  const { queryValues, setQueryParams, initialQueryValues } = useQuery();
  const { languages } = useLanguage();
  const { sendMessage } = useStreaming();
  const { handlePrevStep, handleNextStep } = useStep();
  const currentLanguageCode = useCurrentLanguageCode();

  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);
  const { isConfigurationComplete, isConfigurationCreatedFromStock } = useSelector(
    modelSelectors.getVariables,
  );
  const username = useSelector(authSelectors.getUsername);
  const { configurationNumber, modelNumber, modelCatalogCode } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const hostLogo = useSelector(hostSettingsSelectors.getPrimaryLogo);
  const structure = useSelector(structureSelectors.getStructure);
  const subStepsLabels = useSelector(structureSelectors.getModelBuildSteps);
  const translatedSubStepsLabels = useSelector(
    structureSelectors.getTranslatedModelBuildSteps,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [isExtendedViewEnabled, setIsExtendedViewEnabled] = useState<boolean>(false);
  const [hasFinishedConfiguration, setHasFinishedConfiguration] =
    useState<boolean>(false);

  /** Parsed model selections from query. From both {@link queryValues.modelNumber} and {@link queryValues.modelSelections} */
  const parsedModelSelectionFromQuery = useMemo<Record<string, string>>(() => {
    if (Object.entries(queryValues.modelSelections).length) {
      return queryValues.modelSelections;
    } else if (modelNumber) {
      return decodeModelNumber(modelNumber, subStepsLabels);
    }

    return {};
  }, [modelNumber, queryValues.modelSelections, subStepsLabels]);

  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  /** Object to contain selected rows from all subSteps */
  const [subStepsSelectedItems, setSubStepsSelectedItems] = useState<
    Record<string, string>
  >(parsedModelSelectionFromQuery);

  /** StructureItems for active subStep */
  const [activeSubStepItems, setActiveSubStepItems] = useState<StructureItem[]>([]);

  /** Main control over active subStep */
  const [activeSubStepIdx, setActiveSubStepIdx] = useState<number>(
    Object.keys(parsedModelSelectionFromQuery).length,
  );

  /** Keep queryValue 'modelPageExtendedView' always up to date with state */
  useEffect(() => {
    setQueryParams({
      key: URL_QUERY_PARAMS.modelPageExtendedView,
      value: String(isExtendedViewEnabled),
    });
  }, [isExtendedViewEnabled, setQueryParams]);

  useEffect(() => {
    const newQuery = subStepsLabels.map<SetQueryParamArg>(label => {
      const code = label[StructureLabelFields.code];

      return {
        key: code,
        value: subStepsSelectedItems[code],
      };
    });

    setQueryParams(...newQuery);
  }, [setQueryParams, subStepsLabels, subStepsSelectedItems]);

  /** Adaptation of {@link subStepsLabels} for table render */
  const scrollMenuOptions = useMemo<ScrollMenuOptions[]>(() => {
    return subStepsLabels.map((label, idx) => {
      return {
        name: get(label, StructureLabelFields.description),
        key: get(label, StructureLabelFields.code),
        active: idx <= activeSubStepIdx,
        disabled: idx > activeSubStepIdx,
        staticId: '',
      };
    });
  }, [activeSubStepIdx, subStepsLabels]);

  const activeSubStepLabel = useMemo<StructureLabel>(
    () => subStepsLabels[activeSubStepIdx],
    [subStepsLabels, activeSubStepIdx],
  );

  const activeSubStepValue = useMemo<string | undefined>(() => {
    return subStepsSelectedItems?.[activeSubStepLabel?.[StructureLabelFields.code]];
  }, [subStepsSelectedItems, activeSubStepLabel]);

  const isNextStepDisabled = useMemo(() => !activeSubStepValue, [activeSubStepValue]);

  const selectedTableItems = useMemo<string[]>(() => {
    return activeSubStepValue ? [activeSubStepValue] : [];
  }, [activeSubStepValue]);

  const allTableData = useMemo<StructureItem[]>(() => {
    return mapToTableFormat<StructureItem>(activeSubStepItems, StructureItemFields.code);
  }, [activeSubStepItems]);

  const tableData = useMemo<StructureItem[]>(() => {
    return sortTableDataBySearchValue({
      data: allTableData,
      searchValue,
      filteringKeys: MODELS_FILTERING_KEYS,
    });
  }, [allTableData, searchValue]);

  const isAllowExtendedViewFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowModelExtendedView,
  });
  const isExtendedViewByDefaultFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowModelExtendedViewByDefault,
  });
  const isAutoCompleteFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.AutoCompleteEnabled,
  });
  const isStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowStreaming,
  });

  useEffect(() => {
    if (!isAllowExtendedViewFeatureEnabled) {
      setIsExtendedViewEnabled(false);
      return;
    }
    if (initialQueryValues.modelPageExtendedView) {
      setIsExtendedViewEnabled(initialQueryValues.modelPageExtendedView === 'true');
      return;
    }

    setIsExtendedViewEnabled(isExtendedViewByDefaultFeatureEnabled);
  }, [
    isExtendedViewByDefaultFeatureEnabled,
    initialQueryValues.modelPageExtendedView,
    isAllowExtendedViewFeatureEnabled,
  ]);

  const [carouselImages, setCarouselImages] = useState<ImageItem[]>([]);

  const { getBackgroundImage, backgroundImage, canShowBackgroundImage, isFallback } =
    useBackgroundImage({
      isExtendedViewByDefault: {
        feature: GlobalFeaturesFlagsFields.allowModelExtendedViewByDefault,
      },
    });

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: '',
        dataIndex: 'ExtendedPhoto',
        key: 'ExtendedPhoto',
        width: '14.5em',
        className: 'extended-image',
        render: (_value, record) => ({
          children: (
            <ExtendedImageTableItem
              src={
                getExtendedImage(structure, StructureItemFields.imageLink, record) ??
                hostLogo
              }
              onClick={event => {
                event.stopPropagation();
                const carouselImages = getCarouselFormattedImages(structure, record);
                setCarouselImages(carouselImages);
              }}
            />
          ),
        }),
      },
      {
        title: t('CODE'),
        dataIndex: StructureItemFields.code,
        key: StructureItemFields.code,
      },
      {
        title: t('DESCRIPTION'),
        dataIndex: StructureItemFields.name,
        key: StructureItemFields.name,
      },
    ];

    // todo replace isTableViewExtended with a BE parameter when available
    if (!isExtendedViewEnabled) {
      return columns.filter(column => column.key !== 'ExtendedPhoto');
    }

    return columns;
  }, [isExtendedViewEnabled, t, structure, hostLogo]);

  const handleTableViewExtended = useCallback((tableViewExtendedStatus: boolean) => {
    setIsExtendedViewEnabled(tableViewExtendedStatus);
  }, []);

  const getModelPreviewImage = useCallback(
    (record: StructureItem): string => {
      const previewImage: string | undefined = getExtendedImage(
        structure,
        StructureItemFields.imageLink,
        record,
      );

      // zero-based position indexing
      const currentPositionIndex = record[StructureItemFields.structureNumberXdms] - 1;

      // assume only first case as an edge-case because
      // further there will be any image set: either price list
      // or from model itself - we stick with latest available image
      if (currentPositionIndex === 0 && !previewImage) {
        return selectedPriceListItem?.[PriceListFields.imageUrl] ?? '';
      }

      // should not emit local assets
      if (!previewImage?.includes('https://')) {
        return '';
      }

      return previewImage ?? '';
    },
    [selectedPriceListItem, structure],
  );

  const handleSelectOption = useCallback(
    async (record: StructureItem): Promise<void> => {
      if (isConfigurationComplete) return;

      const value = get(record, StructureItemFields.code);

      setSubStepsSelectedItems(prevState => ({
        ...prevState,
        [activeSubStepLabel[StructureLabelFields.code]]: value,
      }));

      if (!isStreamingFeatureEnabled || isConfigurationCreatedFromStock) return;

      const ttParam = getTtParams({
        [ConfigurationListItemFields.customerNumber]: username,
        [ConfigurationListItemFields.catalogCode]: modelCatalogCode,
        [LanguageFields.languageCode]: currentLanguageCode.system,
      });

      const labels = subStepsLabels.slice(0, activeSubStepIdx);
      const selected = labels.map(step => {
        const code = step[StructureLabelFields.code];
        return {
          [StructureFilter_SelectedFields.id]: code,
          [StructureFilter_SelectedFields.code]: subStepsSelectedItems[code],
        };
      });

      const { response: structure } = await getStructure({
        ...(selected.length ? { [StructureFilterFields.selected]: selected } : {}),
        ...ttParam,
      });

      const translatedRecord = structure?.[StructureFields.items]?.find(
        item => item[StructureItemFields.code] === record[StructureItemFields.code],
      );

      const translatedActiveSubStepLabel = translatedSubStepsLabels.find(
        item =>
          item[StructureLabelFields.code] ===
          activeSubStepLabel[StructureLabelFields.code],
      );

      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'model',
          data: {
            modelYear: selectedPriceListItem?.[PriceListFields.version],
            selections: {
              [record[StructureItemFields.structureNumberXdms] - 1]: {
                type: translatedActiveSubStepLabel?.[StructureLabelFields.description],
                value: translatedRecord?.[StructureItemFields.name],
              },
            },
            previewImage: getModelPreviewImage(record),
          },
        },
      });

      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'global',
          data: {
            carPreviewImageIndex: null,
            isAllRecordsPreview: false,
          },
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [
      isConfigurationComplete,
      isStreamingFeatureEnabled,
      isConfigurationCreatedFromStock,
      username,
      modelCatalogCode,
      currentLanguageCode.system,
      subStepsLabels,
      activeSubStepIdx,
      getStructure,
      translatedSubStepsLabels,
      sendMessage,
      selectedPriceListItem,
      getModelPreviewImage,
      activeSubStepLabel,
      subStepsSelectedItems,
    ],
  );

  const requestStructure = useCallback(
    async (idx: number): Promise<void> => {
      const currLanguage = languages.find(language => {
        const langCode = String(language[LanguageFields.shortLanguageCode]).toLowerCase();
        return langCode === i18n.language;
      });

      const ttParam = getTtParams({
        [ConfigurationListItemFields.customerNumber]: username,
        [ConfigurationListItemFields.catalogCode]: modelCatalogCode,
        [LanguageFields.languageCode]: currLanguage?.[LanguageFields.languageCode],
      });

      const labels = subStepsLabels.slice(0, idx);
      const selected = labels.map(step => {
        const code = step[StructureLabelFields.code];
        return {
          [StructureFilter_SelectedFields.id]: code,
          [StructureFilter_SelectedFields.code]: subStepsSelectedItems[code],
        };
      });

      const { response: structure } = await getStructure({
        ...(selected.length ? { [StructureFilterFields.selected]: selected } : {}),
        ...ttParam,
      });

      if (!structure?.[StructureFields.items]) return;

      const labelCode = subStepsLabels[idx]?.[StructureLabelFields.code];

      if (!labelCode) return;

      const items = structure[StructureFields.items];
      setActiveSubStepItems(items);

      if (items.length === 1) handleSelectOption(items[0]);
    },
    [
      languages,
      username,
      modelCatalogCode,
      subStepsLabels,
      getStructure,
      handleSelectOption,
      i18n.language,
      subStepsSelectedItems,
    ],
  );

  const onSubStepSelect = useCallback(
    (selectedSubStepCode: string): void => {
      const selectedSubStepLabelIdx = subStepsLabels.findIndex(label => {
        return label[StructureLabelFields.code] === selectedSubStepCode;
      });

      if (~selectedSubStepLabelIdx) {
        setActiveSubStepItems([]);
        setActiveSubStepIdx(selectedSubStepLabelIdx);
      }
    },
    [subStepsLabels],
  );

  const handlePrev = useCallback(() => {
    const prevSubStepLabel = subStepsLabels[activeSubStepIdx - 1];

    if (prevSubStepLabel) {
      onSubStepSelect(prevSubStepLabel[StructureLabelFields.code]);
      return;
    }

    handlePrevStep();
  }, [subStepsLabels, activeSubStepIdx, onSubStepSelect, handlePrevStep]);

  const handleNext = useCallback(async () => {
    const nextSubStep = subStepsLabels[activeSubStepIdx + 1];

    if (nextSubStep) {
      onSubStepSelect(nextSubStep[StructureLabelFields.code]);
    } else {
      const newModelNumber = encodeModelNumber(subStepsSelectedItems, subStepsLabels);

      const model = await getModel({ modelNumber: newModelNumber });
      const messages: Message[] | undefined = model?.[ModelFields.message];
      if (messages?.length) notification.open({ message: messages });

      await getConfigurationDetailsById({
        configurationNumber: String(configurationNumber),
      });

      setHasFinishedConfiguration(true);
    }
  }, [
    subStepsLabels,
    activeSubStepIdx,
    onSubStepSelect,
    subStepsSelectedItems,
    getModel,
    getConfigurationDetailsById,
    configurationNumber,
  ]);

  // added because 'handleNext' has dependency on 'subStepsSelectedItems'
  // when user clicks on row we first should set update 'subStepsSelectedItems', then invoke 'handlenext' with updated 'subStepsSelectedItems'
  const [rowClickTrigger, setRowClickTrigger] = useState<number>();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isAutoCompleteFeatureEnabled && activeSubStepValue && rowClickTrigger) {
        handleNext();
        setRowClickTrigger(undefined);
      }
    });
    return () => clearTimeout(timeout);
  }, [handleNext, isAutoCompleteFeatureEnabled, activeSubStepValue, rowClickTrigger]);

  const handleRowClick = useCallback(
    (record: StructureItem): void => {
      setSearchValue('');
      handleSelectOption(record);
      setRowClickTrigger(Number(new Date()));
    },
    [handleSelectOption],
  );

  const onRow = record => ({
    onClick: () => handleRowClick(record),
    style: {
      cursor: 'pointer',
    },
  });

  const rowSelection = {
    onSelect: handleRowClick,
    selectedRowKeys: selectedTableItems,
    type: 'radio' as RowSelectionType,
    renderCell: (checked, record, index, originNode) => {
      return (
        <BubblingPreventer>
          <Checkbox
            checked={originNode.props.checked}
            disabled={isConfigurationComplete}
            onChange={originNode.props.onChange}
            onClick={originNode.props.onClick}
            circle
          />
        </BubblingPreventer>
      );
    },
  };

  const onSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(target.value);
  }, []);

  /** Restore activeStepIdx */
  useEffect(() => {
    if (!subStepsLabels.length) return;

    const length = Object.keys(parsedModelSelectionFromQuery).length;
    const lastItemIdx = length ? length - 1 : 0; // dirty, but if length===0 - idx should be 0, length===1 - idx should be 0.

    onSubStepSelect(Object.keys(parsedModelSelectionFromQuery)[lastItemIdx]);
    // eslint-disable-next-line
  }, [subStepsLabels.length]);

  /**
   * Fetch structure on subStep change
   * @description
   * Timeout-debounce here fixes the issue when
   * 1. it makes first request with `activeSubStepIdx === 0`,
   * 2. then `activeSubStepIdx` is being set in initial hook above.
   * So it makes 2 requests, first of which is unnecessary.
   */
  useEffect(() => {
    if (subStepsLabels.length) {
      const timeout = setTimeout(() => requestStructure(activeSubStepIdx));
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [activeSubStepIdx, subStepsLabels.length]);

  /** Clear all values after just selected. It will also clear query according to hook far above. */
  useEffect(() => {
    setSubStepsSelectedItems(prevState => {
      const clonedState = { ...prevState };

      Object.keys(clonedState).forEach(key => {
        const number = parseInt(key.replace(/\D/g, ''));
        if (number > activeSubStepIdx) delete clonedState[key];
      });

      return clonedState;
    });
  }, [activeSubStepIdx]);

  /** Reset all query keys for labels */
  useEffect(() => {
    return history.listen((location, action) => {
      if (action === 'PUSH') {
        let query: ParsedQuery = UrlTransform.parseQuery(history.location.search);

        query = removeAllLabelsQueryParams(query);

        history.replace({
          search: UrlTransform.stringifyQuery(query),
        });
      }
    });
  }, [history]);

  useEffect(() => {
    getBackgroundImage();
  }, [getBackgroundImage]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'priceList',
        data: {
          priceListName: selectedPriceListItem?.[PriceListFields.catalog],
          priceListImage: selectedPriceListItem?.[PriceListFields.imageUrl],
          priceListDescription:
            selectedPriceListItem?.[PriceListFields.catalogDescription],
          modelYear: selectedPriceListItem?.[PriceListFields.version],
          companyName: 'Volvo',
        },
      },
    });
  }, [selectedPriceListItem, sendMessage]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'model',
      },
    });
  }, [sendMessage]);

  useEffect(() => {
    if (hasFinishedConfiguration) {
      handleNextStep();
    }
  }, [hasFinishedConfiguration, handleNextStep]);

  const isTransitionToNextStepAllowed = useMemo<boolean>(() => {
    return tableData.length === 1 && !isConfigurationComplete && !searchValue;
  }, [isConfigurationComplete, searchValue, tableData.length]);

  const data = orderBy(tableData, [StructureItemFields.name], ['asc']);
  const showModelImage = canShowBackgroundImage && isLgAndWider;

  const table = (
    <>
      <TableFilters
        scrollMenuOptions={scrollMenuOptions}
        handlePrevStep={handlePrev}
        onSelect={onSubStepSelect}
        // because we don't have situation when it should be disabled
        prevStepDisabled={false}
        nextStepDisabled={isNextStepDisabled}
        onSearch={onSearch}
        searchValue={searchValue}
        isSearchDisabled={allTableData.length === 1}
        isAllowExtendedView={isAllowExtendedViewFeatureEnabled}
        isTableViewExtended={isExtendedViewEnabled}
        setIsTableViewExtended={handleTableViewExtended}
        showModelImage={showModelImage}
        handleNextStep={isAutoCompleteFeatureEnabled ? undefined : handleNext}
      />
      <ScTable
        rowKey={record => get(record, StructureItemFields.code)}
        rowSelection={rowSelection}
        data={data}
        columns={tableColumns}
        onRow={onRow}
      >
        {!isWholePageLoading && (
          <NextStepTransition
            isTransitionToNextStepAllowed={isTransitionToNextStepAllowed}
            afterFinish={handleNext}
            message={t('TABLE_STUB_FOR_ONE_ITEM')}
          />
        )}
      </ScTable>
    </>
  );

  return (
    <Layout bg={false}>
      <Container>
        <TableImageGallery
          images={carouselImages}
          visible={carouselImages.length > 0}
          onCancel={() => setCarouselImages([])}
        />

        {showModelImage ? (
          <SplitPane isFallbackTableImage={isFallback}>
            {table}
            <ModelImage image={backgroundImage} isFallback={isFallback} />
          </SplitPane>
        ) : (
          table
        )}
      </Container>
    </Layout>
  );
};
