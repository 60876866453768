import { ModelRule, ModelRuleField } from '@hypercharge/xdms-client/lib/types';
import { get, isEmpty } from 'utils';
import { ModelRecordRules, ModelRulesGrouped } from '../types';

/**
 * Converts mode's rules to hash mirroring record ids
 *
 * @param model {Model} - model to map rules upon
 * @returns {ModelRulesGrouped | null}
 */
export const getRules = (modelRules: ModelRule[]): ModelRulesGrouped | null => {
  const rules = modelRules.reduce<ModelRulesGrouped>(
    (res: ModelRulesGrouped, rule: ModelRule) => {
      const recordKey = get(rule, ModelRuleField.option1, '');
      const relatedRecordKey = get(rule, ModelRuleField.option2, '');
      const ruleKey = rule[ModelRuleField.ruleCode];

      if (res[rule[ModelRuleField.ruleCode]]) {
        res[ruleKey][recordKey] = {
          ...res[rule[ModelRuleField.ruleCode]][recordKey],
          [relatedRecordKey]: true,
        };
        return res;
      }

      res[ruleKey] = {
        [recordKey]: {
          [relatedRecordKey]: true,
        },
      };

      return res;
    },
    {},
  );
  return isEmpty(rules) ? null : rules;
};

export const getRecordRules = (
  modelRules: ModelRule[],
  recordId: string,
): ModelRecordRules => {
  const rules = getRules(modelRules);
  if (!rules) return {};
  return Object.entries(rules).reduce<ModelRecordRules>((acc, [key, obj]) => {
    acc[key] = obj?.[recordId] ? Object.keys(obj[recordId]) : undefined;
    return acc;
  }, {});
};
