import React, { FC, ReactElement } from 'react';
import {
  ScHeaderActionsWrapper,
  ScHeaderCreatedAtDate,
  ScHeaderLeft,
  ScHeaderText,
  ScHeaderTitle,
  ScHeaderTitleText,
  ScHeaderWrapper,
} from './Header.styles';

interface HeaderProps {
  title: string;
  createdAt?: string;
  actions: ReactElement;
  preTitleActions: ReactElement;
  withCursorPointer?: boolean;
}

export const Header: FC<HeaderProps> = ({
  title,
  createdAt,
  actions,
  preTitleActions,
  withCursorPointer,
}) => {
  return (
    <ScHeaderWrapper withCursorPointer={withCursorPointer}>
      <ScHeaderLeft>
        <ScHeaderTitle>
          {preTitleActions}
          <ScHeaderText>
            <ScHeaderTitleText>{title}</ScHeaderTitleText>
            {createdAt && <ScHeaderCreatedAtDate>{createdAt}</ScHeaderCreatedAtDate>}
          </ScHeaderText>
        </ScHeaderTitle>
      </ScHeaderLeft>
      <ScHeaderActionsWrapper>{actions}</ScHeaderActionsWrapper>
    </ScHeaderWrapper>
  );
};
