import React, { FC } from 'react';
import styled from 'styled-components';

/**
 * Checks whether the color is white or adjacent color.
 *
 * white
 * #ffffff and all 64 case combinations thereof
 * #fff and all 8 case combinations thereof
 * rgb(255,255,255) and all combinations of arbitrary whitespace between values
 * rgba(255,255,255,1) and all combinations of arbitrary whitespace between values
 */
const IS_WHITE_REGEX =
  /^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i;

interface Props {
  description: string;
  code: string;
  color: string;
}

// @todo: refactor
const ScStatus = styled.div<{ color: string }>`
  border: 1px solid;
  padding: 1em;
  text-align: center;
  color: ${({ color, theme }) =>
    color
      ? color.match(IS_WHITE_REGEX)
        ? theme.colors.white
        : color
      : theme.colors.dustyGray};
  border: ${({ color, theme }) =>
    `1px solid ${
      color
        ? color.match(IS_WHITE_REGEX)
          ? theme.colors.white
          : color
        : theme.colors.dustyGray
    }`};
  background-color: ${({ color, theme }) =>
    color.match(IS_WHITE_REGEX) ? theme.colors.black : theme.colors.white};
`;

export const ConfigurationStatus: FC<Props> = props => {
  const { description, color: colorHex } = props;
  const color = colorHex ? '#' + colorHex : '';

  if (!description) {
    return null;
  }

  return <ScStatus color={color}>{description}</ScStatus>;
};
