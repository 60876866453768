import { MODELS_URL, URL_QUERY_PARAMS } from 'common/constants';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useRelations } from 'context/relations/RelationsProvider';
import { useStep } from 'context/step/StepProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import useOpenConfiguration from './useOpenConfiguration';
import { useQuery } from 'context/router/UrlQueryProvider';

type Result = {
  handleStockBuild(machineNumber: string): Promise<void>;
};

export default function useBuildStockMachine(): Result {
  const { t } = useTranslation();

  const { createFromMachine, updateConfigurationById } = useConfiguration();
  const { relations } = useRelations();
  const { handleOpenConfiguration } = useOpenConfiguration();

  const { handleStepClick, steps } = useStep();
  const { queryValues, removeQueryParams } = useQuery();

  const [redirectStepIndex, setRedirectStepIndex] = useState<number | undefined>();

  // makes use of properly updated handleStepClick which have circular deps
  // @todo: make handleStepClick better
  useEffect(() => {
    if (typeof redirectStepIndex !== 'number') return;
    setRedirectStepIndex(undefined);
    handleStepClick(redirectStepIndex);
  }, [handleStepClick, redirectStepIndex]);

  const handleStockBuild = useCallback(
    async (machineNumber: string) => {
      let projectId: number | undefined;
      if (queryValues.presetDossier) {
        projectId = queryValues.presetDossier as number;
        removeQueryParams(URL_QUERY_PARAMS.presetDossier);
      }

      const { response, messageHandled, status } = await createFromMachine({
        projectId: projectId,
        machineNumber: machineNumber,
      });

      const newConfigurationNumber = response?.configurationNumber;

      if (!messageHandled) {
        const mappedStatus = newConfigurationNumber ? status : Status.Error;

        notification.openByStatus(mappedStatus, {
          [Status.Error]: t('CONFIGURATION_CREATED_ERROR'),
          [Status.Success]: t('CONFIGURATION_CREATED_SUCCESS'),
        });
      }

      if (!newConfigurationNumber) {
        return;
      }

      await updateConfigurationById(newConfigurationNumber, { relations: relations });

      await handleOpenConfiguration(newConfigurationNumber, {
        skipRedirect: true,
      });

      const modelStepIndex = steps.findIndex(step => step.link === MODELS_URL);
      setRedirectStepIndex(modelStepIndex + 1);
    },
    [
      queryValues.presetDossier,
      createFromMachine,
      updateConfigurationById,
      relations,
      handleOpenConfiguration,
      steps,
      removeQueryParams,
      t,
    ],
  );

  return { handleStockBuild };
}
