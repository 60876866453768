import Autocomplete from 'components/form/Autocomplete';
import { useMachineApi } from 'context/machine/useMachineApi';
import React, { useMemo, useState } from 'react';
import { Filter, StockMachineColorFields, StockMachineFields } from 'types/vendor';
import useGetOptions from './useGetOptions';
import { FILTER_SIGNS } from 'common/constants';
import { Col, Row } from 'antd';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';

const SUB_ROW_SPACES: [number, number] = [16, 0];

type Props = Omit<React.ComponentProps<typeof Autocomplete>, 'options'>;

const ColorSelector: React.FC<Props> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const [labelValue, setLabelValue] = useState<string | null>(null);

  const { getColorOptions } = useMachineApi();

  const labelFilters = useMemo<Filter<StockMachineColorFields>>(() => {
    const result: Filter<StockMachineColorFields> = [];

    if (labelValue) {
      result.push({
        name: StockMachineColorFields.name,
        sign: FILTER_SIGNS.INCLUDES,
        value: labelValue,
      });
    }

    return result;
  }, [labelValue]);

  const valueFilters = useMemo<Filter<StockMachineColorFields>>(() => {
    const result: Filter<StockMachineColorFields> = [];

    if (props.value) {
      result.push({
        name: StockMachineColorFields.code,
        sign: FILTER_SIGNS.INCLUDES,
        value: props.value,
      });
    }

    return result;
  }, [props.value]);

  const { options: valueOptions, isLoading: valueIsLoading } = useGetOptions(
    getColorOptions,
    valueFilters,
  );
  const { options: labelOptions, isLoading: labelIsLoading } = useGetOptions(
    getColorOptions,
    labelFilters,
  );

  return (
    <Row gutter={SUB_ROW_SPACES}>
      <Col span={8}>
        <Autocomplete
          {...props}
          onChange={onChange}
          options={mapToSelectOptions(valueOptions, { label: 'value', value: 'value' })}
          loading={valueIsLoading}
          showSearch
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_COLOR_CODE')}
        />
      </Col>
      <Col span={16}>
        <Autocomplete
          {...omit(props, ['name', 'value'])}
          value={labelValue ?? undefined}
          onChange={(event, option) => {
            setLabelValue(event.target?.value ?? null);
            if (option) {
              event.target.name = StockMachineFields.colorCode;
              onChange?.(event, option);
            }
          }}
          onBlur={() => setLabelValue('')}
          options={labelOptions}
          loading={labelIsLoading}
          showSearch
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_COLOR_LABEL')}
        />
      </Col>
    </Row>
  );
};

export default ColorSelector;
