import { Structure, StructureFilter } from '@hypercharge/xdms-client/lib/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { structureActions } from 'store';
import { RequestResponseDetails } from 'types';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';

interface ContextValue {
  /**
   * This request makes 2 primary things:
   * 1. fetches list of build steps on Model step
   * 2. fetches list of dynamic steps
   *
   * That the reason of options.preserve flag being added - to use with usecase 2
   */
  getStructure(
    params: StructureFilter,
    options?: { preserve?: boolean },
  ): Promise<RequestResponseDetails<Structure | null>>;
  unsetStructure(): void;
}

const useStructureApi = (): ContextValue => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const getStructure = useCallback<ContextValue['getStructure']>(
    async (filter, options) => {
      let result: RequestResponseDetails<Structure | null> = { status: Status.Idle };
      try {
        dispatch(structureActions.setStructureStatus(Status.Loading));
        const { response, messages } = await xdmsClient.structure.getList(filter);

        result = {
          status: Status.Success,
          messageHandled: false,
          response: response,
        };
        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }
        if (options?.preserve) {
          dispatch(structureActions.setStructure(response ?? null));
        }
        dispatch(structureActions.setStructureStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(structureActions.setStructureStatus(Status.Error));
      }

      return result;
    },
    [xdmsClient, dispatch],
  );

  const unsetStructure = useCallback<ContextValue['unsetStructure']>(async () => {
    dispatch(structureActions.reset());
  }, [dispatch]);

  return { getStructure, unsetStructure };
};

export { useStructureApi };
