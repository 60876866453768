import {
  ModelRelation,
  ModelRemark,
  ModelRemarkFields,
  ModelRemarkRoles,
  ModelRemarkTypes,
} from '@hypercharge/xdms-client/lib/types';

export const TEXT_AREA_ROWS = 3;

/** Will be moved to lib */
export enum ContactRoles_RelationCodes {
  DELIVERY = 'DLV',
  FINANCIAL = 'FIN',
}

export type InfoPageFormValues = {
  customerNotes: string;
  customerReference: string;
  internalNotes: string;
  deliveryDate: string;
  contactRoles: ModelRelation[];
  conditions: string;
  conditionsDelivery: string;
  configurationName: string;
  salesCode: string;
  sellCode: string;
  saleGroupCode: string;
  configurationCustomerCommercialType: string;
};

export type InfoPageFormValues_Configuration = Pick<
  InfoPageFormValues,
  | 'conditions'
  | 'conditionsDelivery'
  | 'configurationName'
  | 'salesCode'
  | 'sellCode'
  | 'saleGroupCode'
  | 'configurationCustomerCommercialType'
>;
export type InfoPageFormValues_Model = Omit<
  InfoPageFormValues,
  | 'conditions'
  | 'conditionsDelivery'
  | 'configurationName'
  | 'salesCode'
  | 'sellCode'
  | 'saleGroupCode'
  | 'configurationCustomerCommercialType'
>;

export const infoPageInitialData: InfoPageFormValues = {
  customerNotes: '',
  customerReference: '',
  internalNotes: '',
  deliveryDate: '',
  contactRoles: [],
  conditions: '',
  conditionsDelivery: '',
  configurationName: '',
  salesCode: '',
  sellCode: '',
  saleGroupCode: '',
  configurationCustomerCommercialType: '',
};

// on remark create ModelRemarkFields.type should be ModelRemarkTypes.configuration
// on remark update ModelRemarkFields.type should be ModelRemarkTypes.info
export const remarkInitialData: ModelRemark = {
  [ModelRemarkFields.type]: ModelRemarkTypes.configuration,
  [ModelRemarkFields.relationNumber]: '',
  [ModelRemarkFields.languageCode]: '',
  [ModelRemarkFields.remarkNumber]: 1,
  [ModelRemarkFields.sysLink]: '',
  [ModelRemarkFields.rowId]: '',
  [ModelRemarkFields.commentTitle]: '',
  [ModelRemarkFields.commentDescription]: '',
  [ModelRemarkFields.role]: ModelRemarkRoles.external,
  INdummyDescr: '',
  YNdummyHyper: false,
};

export enum InfoPageFormFields {
  customerNotes = 'customerNotes',
  customerReference = 'customerReference',
  internalNotes = 'internalNotes',
  deliveryDate = 'deliveryDate',
  contactRoles = 'contactRoles',
  conditions = 'conditions',
  conditionsDelivery = 'conditionsDelivery',
  configurationName = 'configurationName',
  salesCode = 'salesCode',
  sellCode = 'sellCode',
  saleGroupCode = 'saleGroupCode',
  configurationCustomerCommercialType = 'configurationCustomerCommercialType',
}
