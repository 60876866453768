import React from 'react';
import { StockMachineFields } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { InputRangeGroup } from 'components/form/InputRangeGroup';
import { ScFormField } from './KMInputRange.styles';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

type Props = {
  allowClear?: boolean;
  label: string;
};

const KMInputRange: React.FC<Props> = ({ label, allowClear = false }) => {
  const { t } = useTranslation();

  return (
    <InputRangeGroup
      label={label}
      componentFrom={
        <ScFormField
          component={InputNumberLocalized}
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_KM_FROM')}
          name={StockMachineFields.kilometrageFrom}
          allowClear={allowClear}
        />
      }
      componentTo={
        <ScFormField
          component={InputNumberLocalized}
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_KM_TO')}
          name={StockMachineFields.kilometrageTo}
          allowClear={allowClear}
        />
      }
    />
  );
};

export default KMInputRange;
