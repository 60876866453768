import styled from 'styled-components';

export const ScTitlesList = styled.ul`
  display: flex;
  justify-content: flex-end;
  padding: 0.75em 0 0.75em 0;
  margin: 0;
`;

export const ScTitlesListItem = styled.li`
  margin: 0;
  padding: 0 2em 0 0;
  font-size: 0.875em;
  font-weight: 600;
`;
