import React, { FC, useCallback } from 'react';
import { ScButton, ScLabel } from './ConfigurationAction.styles';

interface ConfigurationActionProps {
  onClick?(): void;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const ConfigurationAction: FC<React.PropsWithChildren<ConfigurationActionProps>> =
  ({ onClick, children, icon = null, disabled = false, ...rest }) => {
    const handleClickNewConfiguration = useCallback(() => {
      onClick?.();
    }, [onClick]);

    return (
      <ScButton
        onClick={handleClickNewConfiguration}
        icon={icon}
        disabled={disabled}
        {...rest}
      >
        <ScLabel withIcon={Boolean(icon)}>{children}</ScLabel>
      </ScButton>
    );
  };
