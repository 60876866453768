import { Button } from './Button';
import { MdArrowDropDown } from 'react-icons/md';
import styled from 'styled-components';
import { mediaMax } from 'common/theme';

export const ScDropdownIcon = styled(MdArrowDropDown)`
  font-size: 1.5em;
`;

export const ScDropdownTriggerButton = styled(Button)`
  display: flex;
  padding: 0 1.25em;
  align-items: center;
  border: 1px solid transparent;

  ${mediaMax.md} {
    padding: 0 0.5em;
  }

  ${mediaMax.xs} {
    padding: 0 0.25em;
  }

  &.active,
  &:hover,
  &:focus {
    border: 1px solid transparent;
  }

  &.ant-dropdown-open.ant-dropdown-trigger {
    color: ${props => props.theme.colors.black};
  }

  > span {
    font-size: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  /* @todo: might be dead code*/
  > div {
    font-size: 1em;
    margin-right: 0.5em;

    ${mediaMax.md} {
      font-size: 0.875em;
    }

    ${mediaMax.sm} {
      margin-right: 0;
    }
  }

  & > .anticon-caret-down {
    margin: auto;
  }

  ${ScDropdownIcon} {
    color: ${({ theme }) => theme.mainColor};
  }
`;

export const ScMenuLabel = styled.span`
  font-size: 0.875em;
  font-weight: 600;
`;
