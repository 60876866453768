import { Col, Row } from 'antd';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScHeaderConfirmationContainer,
  ScHeaderConfirmationContainerButton,
} from './styles';

interface Props {
  handleConfirm(): void;
  handleReset?(): void;
  cancelText?: React.ReactNode;
  confirmText?: React.ReactNode;
}

export const ConfirmationContainer: FC<PropsWithChildren<Props>> = props => {
  const { handleConfirm, handleReset, children, cancelText, confirmText } = props;
  const { t } = useTranslation();

  return (
    <ScHeaderConfirmationContainer>
      {children}
      <Row align="middle" justify="space-between">
        <Col>
          <ScHeaderConfirmationContainerButton
            transparent
            onClick={handleReset}
            disabled={!handleReset}
          >
            {cancelText ?? t('RESET')}
          </ScHeaderConfirmationContainerButton>
        </Col>
        <Col>
          <ScHeaderConfirmationContainerButton transparent onClick={handleConfirm}>
            {confirmText ?? t('OK')}
          </ScHeaderConfirmationContainerButton>
        </Col>
      </Row>
    </ScHeaderConfirmationContainer>
  );
};
