import styled from 'styled-components';
import { Button } from 'components/button';

export const ScFooterContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ScButton = styled(Button)`
  .ant-btn-loading-icon {
    position: absolute;
    left: 1em;
  }
`;
