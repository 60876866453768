import React, { FC } from 'react';
import { CountryItem } from '@hypercharge/xdms-client/lib/types';
import { ContactItem, ContactFields, Language } from 'types/vendor';
import { UserForm, UserType } from 'components/customerForms/UserForm';
import {
  ScCloseOutlined,
  ScCollapse,
  ScCollapsePanel,
} from './AdditionalContactsList.styles';

interface Props {
  contacts: ContactItem[];
  isConfigurationComplete: boolean;
  deleteOne(record: ContactItem): void;
  countries: CountryItem[];
  languages: Language[];
}
export const AdditionalContactsList: FC<Props> = props => {
  const { contacts, isConfigurationComplete, countries, languages, deleteOne } = props;

  const handleDelete = (
    event: React.MouseEvent<HTMLElement>,
    contact: ContactItem,
  ): void => {
    event.stopPropagation();
    deleteOne(contact);
  };

  return (
    <div data-testid="customer-page-contact-search-block-additional-contact-collapse">
      <ScCollapse expandIconPosition="right" ghost>
        {contacts.map((contact, idx) => (
          <ScCollapsePanel
            extra={
              !isConfigurationComplete && (
                <ScCloseOutlined onClick={event => handleDelete(event, contact)} />
              )
            }
            header={contact[ContactFields.name]}
            key={ContactFields.ID + idx}
            data-testid="bla-bla-bla"
          >
            <UserForm
              initialFormValues={contact}
              readonly
              countries={countries}
              languages={languages}
              userType={UserType.Contact}
              showCommercialCustomerType={false}
              isForm={false}
            />
          </ScCollapsePanel>
        ))}
      </ScCollapse>
    </div>
  );
};
