import { Input } from 'components/form/Input';
import styled from 'styled-components';
import { mediaMin } from 'common/theme';

export const ScSearchInput = styled(Input)`
  margin-bottom: 0;
  .ant-input-suffix svg {
    font-size: 1.25em;
  }
  ${mediaMin.md} {
    width: 23.25rem;
  }
`;

export const ScFiltersWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.875em;
`;

export const ScFilters = styled.div`
  .ant-input-affix-wrapper {
    font-size: 1em;
  }
  display: flex;
  margin-right: 0.5em;
`;

export const ScOptionsFilter = styled.div`
  width: fit-content;
  max-width: 100%;
`;

export const ScFiltersContainer = styled.div<{ $showModelImage: boolean }>`
  margin-bottom: 1.25em;
  margin-right: ${({ $showModelImage }) => ($showModelImage ? '1em' : '0')};
`;

export const ScViewSwitcherContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  background-color: transparent;
  margin-left: 0.5em;
`;

export const ScStandardFeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  margin-left: 0.5em;
`;
