import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ScBody,
  ScButtonsContainer,
  ScRadio,
  ScRadioGroup,
  ScSourceInput,
  ScSourceText,
} from './ConfigurationImportModal.styles';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { Button } from '../button/Button';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Input } from '../form/Input';
import { SelectCustomerModal } from '../customer/SelectCustomerModal';
import { useCustomer } from 'context/customer/CustomerProvider';
import {
  ConfigurationListItem,
  ConfigurationListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, CustomerItem } from 'types/vendor';
import { SearchConfigurationModal } from './SearchConfigurationModal';
import { notification } from 'utils/notification';
import { useModelApi } from 'context/model/useModelApi';
import { Status } from 'utils/types';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { Modal } from 'components/modal';
import { ScModalTitle } from 'components/modalLike.styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';

enum Source {
  customer = 'customer',
  configuration = 'configuration',
}

export enum ConfigurationImportFormFields {
  configurationNumber = 'configurationNumber',
  customerNumber = 'customerNumber',
  token = 'token',
}

export type ConfigurationImportFormValues = {
  [ConfigurationImportFormFields.configurationNumber]?: number | string;
  [ConfigurationImportFormFields.customerNumber]?: number | string;
  [ConfigurationImportFormFields.token]: number | string;
};

interface Props {
  initialValues?: Partial<ConfigurationImportFormValues>;
  onClose(): void;
  onSubmit?(): void;
}

const ConfigurationImportModal: FC<Props> = ({ initialValues, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { getModel } = useModelApi();
  const { getCustomerById } = useCustomer();
  const {
    searchConfigurations,
    updateConfigurationByExternalToken,
    createConfigurationFromExternalToken,
    getConfigurationStatusCodes,
    getConfigurationDetailsById,
    assembleConfigurationUrlByConfigurationNumber,
  } = useConfiguration();
  const { isFeatureEnabled } = useFeature();

  const { configurationNumber: currentConfigurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const isConfigurationImportCreateFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationImportCreate,
  });
  const isConfigurationImportUpdateFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationImportUpdate,
  });

  const [token, setToken] = useState<string>();

  const [selectedConfiguration, setSelectedConfiguration] =
    useState<ConfigurationListItem>();
  const selectedConfigurationDisplayName = useMemo<string | undefined>(() => {
    if (!selectedConfiguration) return;

    const id = selectedConfiguration[ConfigurationListItemFields.id];
    const customerName = selectedConfiguration[ConfigurationListItemFields.customerName];
    const customerName2 =
      selectedConfiguration[ConfigurationListItemFields.customerName2];

    return `${id} ${customerName} ${customerName2}`;
  }, [selectedConfiguration]);

  const [isModalConfigurationSelectVisible, setIsModalConfigurationSelectVisible] =
    useState<boolean>(false);

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerItem>();
  const selectedCustomerDisplayName = useMemo<string | undefined>(() => {
    if (!selectedCustomer) return;

    const name = selectedCustomer[CustomerFields.name];
    const name2 = selectedCustomer[CustomerFields.name2];

    return `${name} ${name2}`;
  }, [selectedCustomer]);

  const [isModalCustomerSelectVisible, setIsModalCustomerSelectVisible] =
    useState<boolean>(false);

  const [source, setSource] = useState<Source>(Source.customer);

  useEffect(() => {
    getConfigurationStatusCodes();
  }, [getConfigurationStatusCodes]);

  /** Initially get configuration or customer record if initialValues provided */
  useEffect(() => {
    (async () => {
      const customerNumber =
        initialValues?.[ConfigurationImportFormFields.customerNumber];
      if (customerNumber) {
        setSource(Source.customer);
        await getCustomerById(String(customerNumber));
      }

      const configurationNumber =
        initialValues?.[ConfigurationImportFormFields.configurationNumber];
      if (configurationNumber) {
        setSource(Source.configuration);
        const list = await searchConfigurations([
          {
            name: ConfigurationListItemFields.id,
            value: String(configurationNumber),
          },
        ]);

        setSelectedConfiguration(list[0]);
      }
    })();
  }, [getCustomerById, initialValues, searchConfigurations]);

  const handleCreate = useCallback(
    async (customerNumber: number | string): Promise<string | undefined> => {
      if (!token) return;

      const { response, messageHandled } = await createConfigurationFromExternalToken(
        token,
        customerNumber,
      );

      if (!messageHandled) {
        const status: Status = response?.configurationNumber
          ? Status.Success
          : Status.Error;

        notification.openByStatus(status, {
          [Status.Success]: t('CONFIGURATION_IMPORT_SUCCESS'),
          [Status.Error]: t('CONFIGURATION_IMPORT_FAILURE'),
        });
      }

      if (response?.configurationNumber) {
        const url = await assembleConfigurationUrlByConfigurationNumber(
          response.configurationNumber,
        );
        onClose();
        return url;
      }
    },
    [
      assembleConfigurationUrlByConfigurationNumber,
      createConfigurationFromExternalToken,
      onClose,
      t,
      token,
    ],
  );

  const handleUpdate = useCallback(
    async (configurationNumber: number | string): Promise<string | undefined> => {
      if (!token) return;
      const { messageHandled, status } = await updateConfigurationByExternalToken(
        token,
        configurationNumber,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('CONFIGURATION_UPDATED'),
          [Status.Error]: t('CONFIGURATION_UPDATE_FAILED'),
        });
      }

      if (configurationNumber === currentConfigurationNumber) {
        await getConfigurationDetailsById({ configurationNumber: configurationNumber });
        await getModel();
      }
      onClose();
    },
    [
      currentConfigurationNumber,
      getConfigurationDetailsById,
      getModel,
      onClose,
      t,
      token,
      updateConfigurationByExternalToken,
    ],
  );

  const handleSubmit = useCallback(async () => {
    onSubmit?.();
    const customerNumber = selectedCustomer?.[CustomerFields.id];
    const configurationNumber = selectedConfiguration?.[ConfigurationListItemFields.id];

    if (source === Source.customer && customerNumber) {
      const url = await handleCreate(customerNumber);

      if (url) history.push(url);
    }

    if (source === Source.configuration && configurationNumber) {
      handleUpdate(configurationNumber);
    }
  }, [
    handleCreate,
    handleUpdate,
    history,
    onSubmit,
    selectedConfiguration,
    selectedCustomer,
    source,
  ]);

  const handleSelectCustomer = useCallback((customerItems: CustomerItem[]) => {
    setSelectedCustomer(customerItems[0]);
  }, []);

  const handleSelectConfiguration = useCallback(
    async (configurationItems: ConfigurationListItem[]) => {
      setSelectedConfiguration(configurationItems[0]);
    },
    [],
  );

  const isSubmitButtonDisabled = useMemo<boolean>(() => {
    if (!token) return true;
    if (source === Source.customer) return !selectedCustomer;
    if (source === Source.configuration) return !selectedConfiguration;
    return true;
  }, [selectedConfiguration, selectedCustomer, source, token]);

  const areAllFeaturesEnabled = useMemo(
    () =>
      [
        isConfigurationImportUpdateFeatureEnabled,
        isConfigurationImportCreateFeatureEnabled,
      ].every(Boolean),
    [
      isConfigurationImportCreateFeatureEnabled,
      isConfigurationImportUpdateFeatureEnabled,
    ],
  );

  return (
    <Modal variant="sm" center visible onCancel={onClose}>
      <ScModalTitle>{t('CONFIGURATION_IMPORT_MODAL_TITLE')}</ScModalTitle>
      {isConfigurationImportCreateFeatureEnabled && (
        <SelectCustomerModal
          initialValues={selectedCustomer ? [selectedCustomer] : []}
          onSelect={handleSelectCustomer}
          isOpen={isModalCustomerSelectVisible}
          setIsOpen={setIsModalCustomerSelectVisible}
          isAddEnabled
        />
      )}
      {isConfigurationImportUpdateFeatureEnabled && isModalConfigurationSelectVisible && (
        <SearchConfigurationModal
          initialValues={selectedConfiguration ? [selectedConfiguration] : []}
          onSubmit={handleSelectConfiguration}
          onClose={() => setIsModalConfigurationSelectVisible(false)}
        />
      )}

      <ScBody>
        <Input
          name={ConfigurationImportFormFields.token}
          placeholder={`${t('PUBLIC_TOKEN')}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToken(e.target.value);
          }}
          data-testid="configuration-import-modal__token-input"
        />

        <ScSourceText>{t('CONFIGURATION_IMPORT_SOURCE')}</ScSourceText>

        <ScRadioGroup onChange={e => setSource(e.target.value as Source)} value={source}>
          {areAllFeaturesEnabled && (
            <ScRadio value={Source.customer} className={'fullwidth'}>
              {t('CONFIGURATION_IMPORT_SOURCE__CUSTOMER')}
            </ScRadio>
          )}
          {isConfigurationImportCreateFeatureEnabled && (
            <ScSourceInput
              name={ConfigurationImportFormFields.customerNumber}
              value={selectedCustomerDisplayName}
              onClick={() => setIsModalCustomerSelectVisible(true)}
              disabled={source !== Source.customer}
              visible={source === Source.customer}
              shifted={areAllFeaturesEnabled}
              placeholder={t('CONFIGURATION_IMPORT_SOURCE__CUSTOMER')}
              data-testid="configuration-import-modal__customer-input"
            />
          )}
          {areAllFeaturesEnabled && (
            <ScRadio value={Source.configuration} className={'fullwidth'}>
              {t('CONFIGURATION_IMPORT_SOURCE__CONFIGURATION')}
            </ScRadio>
          )}
          {isConfigurationImportUpdateFeatureEnabled && (
            <ScSourceInput
              name={ConfigurationImportFormFields.configurationNumber}
              value={selectedConfigurationDisplayName}
              onClick={() => setIsModalConfigurationSelectVisible(true)}
              disabled={source !== Source.configuration}
              visible={source === Source.configuration}
              shifted={areAllFeaturesEnabled}
              placeholder={t('CONFIGURATION_IMPORT_SOURCE__CONFIGURATION')}
              data-testid="configuration-import-modal__configuration-input"
            />
          )}
        </ScRadioGroup>
      </ScBody>

      <ScButtonsContainer>
        <Row gutter={16}>
          <Col span="12">
            <Button
              onClick={onClose}
              fullwidth
              data-testid="configuration-import-modal__cancel-button"
            >
              {t('CANCEL')}
            </Button>
          </Col>
          <Col span="12">
            <Button
              disabled={isSubmitButtonDisabled}
              onClick={handleSubmit}
              fullwidth
              variant="primary"
              data-testid="configuration-import-modal__submit-button"
            >
              {t('SUBMIT')}
            </Button>
          </Col>
        </Row>
      </ScButtonsContainer>
    </Modal>
  );
};

export default ConfigurationImportModal;
