import Tabs from 'components/Tabs';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurationRelation } from '../../../context/relations/types';
import { RelationItemCustomer } from './customer/RelationItemCustomer';
import { RelationItemContact } from './contact/RelationItemContact';
import { useFeature } from '../../../context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { ContainerSize } from 'common/theme';
import { getTitle } from 'utils/get-title';

enum TabsKeys {
  customer = 'CUSTOMER',
  contact = 'CONTACT',
}

export interface RelationItemProps {
  relation: ConfigurationRelation;
  goForward(): void;
  goFarForward(): void;
  setContainerSize(size?: ContainerSize): void;
}
export const RelationItem: FC<RelationItemProps> = ({
  relation,
  goForward,
  goFarForward,
  setContainerSize,
}) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeature();

  const isContactsEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.ContactsEnabled,
  });
  const isContactMandatoryEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowContactMandatory,
  });

  const [activeTab, setActiveTab] = useState<TabsKeys>(TabsKeys.customer);

  useEffect(() => {
    let size: ContainerSize | undefined;

    if (activeTab === TabsKeys.customer) {
      size = relation.customer ? 'lg' : 'xs';
    }
    if (activeTab === TabsKeys.contact) {
      size = relation.primaryContact ? 'md' : 'xs';
    }

    setContainerSize(size);
  }, [activeTab, relation.customer, relation.primaryContact, setContainerSize]);

  if (!isContactsEnabled) {
    return (
      <RelationItemCustomer
        relation={relation}
        goForward={goForward}
        goFarForward={goFarForward}
      />
    );
  }

  return (
    <Tabs
      centered
      type="card"
      className="button-styled"
      data-testid="clientPage-tabs-container"
      activeKey={activeTab}
      onTabClick={setActiveTab as (k: string) => void}
      destroyInactiveTabPane
    >
      <Tabs.TabPane
        tab={getTitle(t('CUSTOMER_SEARCH'), { required: relation.mandatory })}
        key={TabsKeys.customer}
      >
        <RelationItemCustomer
          relation={relation}
          goForward={() => setActiveTab(TabsKeys.contact)}
          goFarForward={goForward}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        disabled={!relation.customer}
        tab={getTitle(t('CONTACT_SEARCH'), {
          required: isContactsEnabled && isContactMandatoryEnabled,
        })}
        key={TabsKeys.contact}
      >
        <RelationItemContact relation={relation} goForward={goForward} />
      </Tabs.TabPane>
    </Tabs>
  );
};
