import { ColumnType } from 'antd/lib/table';

/**
 * Assigns titles to "dataIndex" (usually "relaitonId")
 * That helps to provide constructed hash to render inside table columns
 */
export const getFilterTableTitlesBySearchColumns = <T = unknown>(
  searchColumns: ColumnType<T>[],
  initialValue: Record<string, string | never> = {},
): Record<string, string> => {
  return searchColumns.reduce<Record<string, string>>((acc, col) => {
    return {
      ...acc,
      [String(col.dataIndex)]: String(col.title),
    };
  }, initialValue);
};
