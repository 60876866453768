import { Col, Row, Select } from 'antd';
import { Button } from 'components/button';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { TextArea } from 'components/form/TextArea';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { uniq } from 'utils';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  ScDocumentModalDivider,
  ScDocumentModalHeader,
  ScDocumentModalSectionTitle,
  ScDocumentModalSectionWrapper,
  ScDocumentModalSelect,
  ScDocumentModalBody,
  ScDocumentModalSectionEntity,
  ScDocumentModalSectionEntityName,
} from './DocumentEmailModalBase.styles';
import { Modal } from 'components/modal';
import { isFieldRequired } from 'utils/is-field-required';
import {
  useDocumentsTree,
  DocumentsTreeData,
  DocumentTreeDataActionType,
} from 'hooks/useDocumentsTree';
import { DocumentsList } from './List/DocumentsList';

interface FormData {
  emailTo: string;
  emailCC: string[];
  subject: string;
  comment?: string;
}

export interface DocumentEmailModalBase_Data {
  emailTo: string;
  emailCC: string[];
  subject: string;
  body: string;
  documentsSchema: DocumentsTreeData;
}

interface Props {
  onClose?(): void;
  documentsSchema: DocumentsTreeData;
  onSubmit(params: DocumentEmailModalBase_Data): Promise<void>;
  title: string;
  initialFormData?: Partial<FormData>;
  isDocumentEntitiesBordered?: boolean;
  isDocumentActionsDisabled?: boolean;
}

export const DocumentEmailModalBase: FC<Props> = props => {
  const {
    onClose: onCancel,
    documentsSchema,
    onSubmit,
    title,
    initialFormData,
    isDocumentEntitiesBordered,
    isDocumentActionsDisabled,
  } = props;
  const { t } = useTranslation();
  const ref = useRef(null);

  const { documentsTreeState, documentsTreeDispatch } = useDocumentsTree();

  useEffect(() => {
    documentsTreeDispatch({
      type: DocumentTreeDataActionType.initFromTree,
      tree: documentsSchema,
    });
  }, [documentsTreeDispatch, documentsSchema]);

  const handleSubmit = useCallback(
    async (values: FormData, actions: FormikHelpers<FormData>) => {
      try {
        await onSubmit({
          emailTo: values.emailTo,
          emailCC: values.emailCC,
          subject: values.subject,
          body: values.comment || '',
          documentsSchema: documentsTreeState,
        });

        onCancel && onCancel();
      } finally {
        actions.setSubmitting(false);
      }
    },
    [documentsTreeState, onSubmit, onCancel],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape<FormData>({
      emailTo: Yup.string().email(t('INVALID_EMAIL')).required(t('FORM__REQUIRED')),
      emailCC: Yup.array(
        Yup.string().email(({ value }) => t('INVALID_EMAIL__EXACT', { v: value })),
      ),
      subject: Yup.string().required(t('FORM__REQUIRED')),
      comment: Yup.string().notRequired(),
    });
  }, [t]);

  const initialValues = useMemo<FormData>(
    () => ({
      emailTo: initialFormData?.emailTo ?? '',
      emailCC: initialFormData?.emailCC ?? [],
      subject: initialFormData?.subject ?? '',
      comment: '',
    }),
    [initialFormData],
  );

  return (
    <Modal variant="sm" data-testid="send-email-modal" visible onCancel={onCancel}>
      <ScDocumentModalHeader>{title}</ScDocumentModalHeader>

      <ScDocumentModalDivider />

      <ScDocumentModalBody>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formProps: FormikProps<FormData>) => (
            <form
              noValidate
              onSubmit={formProps.handleSubmit}
              data-testid="login-page-form"
            >
              <Row
                gutter={[16, 0]}
                align="middle"
                justify="center"
                data-testid="send-email-modal--fields-container"
              >
                <Col span={24}>
                  <FormField
                    data-testid="send-email-modal--emailto"
                    label={t('SEND_EMAIL_MODAL__EMAILTO_LABEL')}
                    required={isFieldRequired(validationSchema, 'emailTo')}
                    placeholder={t('SEND_EMAIL_MODAL__EMAILTO_PLACEHOLDER')}
                    id="emailTo"
                    component={Input}
                    disabled={formProps.isSubmitting}
                    name="emailTo"
                    type={'email'}
                    value={formProps.values.emailTo}
                    onChange={event =>
                      formProps.setFieldValue('emailTo', event.target.value)
                    }
                    onBlur={formProps.handleBlur}
                    withErrors={Boolean(
                      formProps.errors.emailTo && formProps.touched.emailTo,
                    )}
                  />
                </Col>
                <Col span={24} ref={ref}>
                  <FormField
                    data-testid="send-email-modal--emailcc"
                    label={t('SEND_EMAIL_MODAL__EMAILCC_LABEL')}
                    placeholder={t('SEND_EMAIL_MODAL__EMAILCC_PLACEHOLDER')}
                    id="emailCC"
                    component={ScDocumentModalSelect}
                    disabled={formProps.isSubmitting}
                    name="emailCC"
                    required={isFieldRequired(validationSchema, 'emailCC')}
                    mode="tags"
                    value={formProps.values.emailCC}
                    onChange={value => formProps.setFieldValue('emailCC', value)}
                    onBlur={formProps.handleBlur}
                    withErrors={Boolean(
                      formProps.errors.emailCC && formProps.touched.emailCC,
                    )}
                    getPopupContainer={() => ref.current}
                  >
                    {uniq([...formProps.values.emailCC]).map(item => (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </FormField>
                </Col>
                <Col span={24}>
                  <FormField
                    data-testid="send-email-modal--subject"
                    label={t('SEND_EMAIL_MODAL__SUBJECT_LABEL')}
                    required={isFieldRequired(validationSchema, 'subject')}
                    placeholder={t('SEND_EMAIL_MODAL__SUBJECT_PLACEHOLDER')}
                    id="subject"
                    component={Input}
                    disabled={formProps.isSubmitting}
                    name="subject"
                    type={'text'}
                    value={formProps.values.subject}
                    onChange={event =>
                      formProps.setFieldValue('subject', event.target.value)
                    }
                    onBlur={formProps.handleBlur}
                    withErrors={Boolean(
                      formProps.errors.subject && formProps.touched.subject,
                    )}
                  />
                </Col>
                <Col span={24}>
                  <FormField
                    data-testid="send-email-modal--comment"
                    label={t('SEND_EMAIL_MODAL__COMMENT_LABEL')}
                    placeholder={t('SEND_EMAIL_MODAL__COMMENT_PLACEHOLDER')}
                    id="comment"
                    component={TextArea}
                    disabled={formProps.isSubmitting}
                    name="comment"
                    required={isFieldRequired(validationSchema, 'comment')}
                    value={formProps.values.comment}
                    onChange={event =>
                      formProps.setFieldValue('comment', event.target.value)
                    }
                    rows={5}
                    onBlur={formProps.handleBlur}
                    withErrors={Boolean(
                      formProps.errors.comment && formProps.touched.comment,
                    )}
                  />
                </Col>
              </Row>

              {documentsTreeState.map(entity => (
                <ScDocumentModalSectionWrapper key={entity.relatedEntityCode}>
                  <ScDocumentModalSectionTitle>
                    {t(entity.tKey)}
                  </ScDocumentModalSectionTitle>
                  <ScDocumentModalSectionEntity $bordered={isDocumentEntitiesBordered}>
                    {entity.relatedEntities.map(entityItem => (
                      <Fragment key={entityItem.relatedEntity?.[entity.idField] ?? 'cfg'}>
                        <ScDocumentModalSectionEntityName>
                          {entityItem.relatedEntity?.[entity.nameField]}
                        </ScDocumentModalSectionEntityName>

                        <DocumentsList
                          readonly={isDocumentActionsDisabled}
                          documents={entityItem.documents}
                          relatedEntity={entityItem.relatedEntity}
                          relatedEntityCode={entity.relatedEntityCode}
                          onCheck={document => {
                            documentsTreeDispatch({
                              type: DocumentTreeDataActionType.selectDocument,
                              relatedEntityCode: entity.relatedEntityCode,
                              relatedEntity: entityItem.relatedEntity,
                              document: document,
                            });
                          }}
                          selectedIds={entityItem.selectedIds}
                        />
                      </Fragment>
                    ))}
                  </ScDocumentModalSectionEntity>
                </ScDocumentModalSectionWrapper>
              ))}

              <Button
                data-testid="send-email-modal--submit"
                loading={formProps.isSubmitting}
                noBorder
                variant="primary"
                fullwidth
                htmlType="submit"
              >
                {t('SEND_EMAIL_MODAL__SUBMIT')}
              </Button>
            </form>
          )}
        </Formik>
      </ScDocumentModalBody>
    </Modal>
  );
};
