import {
  DocumentEmailModalBase,
  DocumentEmailModalBase_Data,
} from 'components/document/DocumentEmailModalBase';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from '../../utils/notification';
import { Status } from '../../utils/types';
import { groupRelationsEmails } from '../documents/utils';
import { useRelations } from '../../context/relations/RelationsProvider';
import { GlobalFeaturesFlagsFields } from '../../common/globalFeaturesFlags';
import { useFeature } from '../../context/feature/FeatureProvider';
import { useTranslation } from 'react-i18next';
import { TradeIn, useTradeIn } from 'context/tradeIn/TradeInProvider';
import { DocumentsTreeData, documentTreeHelpers } from 'hooks/useDocumentsTree';
import { DocumentItem, DocumentRelatedEntityCode } from 'context/document/types';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentRelatedEntitySettingsSelectors } from 'context/document/settings';

export interface Props {
  onClose(): void;
  initialSubject: string;
  relatedEntity: TradeIn;
}
export type TradeInSendEmailModalProps = Props;
export const TradeInSendEmailModal: FC<Props> = ({
  onClose,
  initialSubject,
  relatedEntity,
}) => {
  const { t } = useTranslation();

  const { relations } = useRelations();
  const { isFeatureEnabled } = useFeature();
  const { sendEmailTradeIn } = useTradeIn();

  const { getDocumentsList } = useDocumentsApi();

  const [documents, setDocuments] = useState<DocumentItem[]>([]);

  const isContactsEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.ContactsEnabled,
  });

  const shouldSkipNoMail = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.SkipNoMail,
  });

  useEffect(() => {
    getDocumentsList(DocumentRelatedEntityCode.tradeInDedicated, relatedEntity).then(
      ({ response }) => {
        if (!response) return;
        setDocuments(response);
      },
    );
  }, [getDocumentsList, relatedEntity]);

  const onSubmit = useCallback(
    async (params: DocumentEmailModalBase_Data) => {
      const settings = DocumentRelatedEntitySettingsSelectors.getByEntityCode(
        DocumentRelatedEntityCode.tradeInDedicated,
      );
      const { status, messageHandled } = await sendEmailTradeIn({
        emailTo: params.emailTo,
        emailCC: params.emailCC,
        subject: params.subject,
        body: params.body,
        tradeInSerialNumber: relatedEntity[settings.idField],
        documentIds: documentTreeHelpers.getSelectedDocumentsIds(
          params.documentsSchema,
        ) as string[],
      });

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Error]: t('TRADE_IN_SEND_EMAIL_MODAL__ERROR'),
          [Status.Success]: t('TRADE_IN_SEND_EMAIL_MODAL__SUCCESS'),
        });
      }
    },
    [sendEmailTradeIn, t, relatedEntity],
  );

  const documentsSchema = useMemo<DocumentsTreeData>(() => {
    const settings = DocumentRelatedEntitySettingsSelectors.getByEntityCode(
      DocumentRelatedEntityCode.tradeInDedicated,
    );
    return [
      {
        relatedEntityCode: DocumentRelatedEntityCode.tradeInDedicated,
        tKey: settings.documentsPageTKey,
        nameField: settings.nameField,
        idField: settings.idField,
        relatedEntities: [
          {
            isOpen: true,
            relatedEntity: relatedEntity,
            selectedIds: documents
              .filter(document => document.computed.isSelectable)
              .map(({ id }) => id),
            documents: documents,
          },
        ],
      },
    ];
  }, [documents, relatedEntity]);

  const emails = useMemo(() => {
    return groupRelationsEmails(relations, isContactsEnabled, shouldSkipNoMail);
  }, [relations, isContactsEnabled, shouldSkipNoMail]);

  return (
    <DocumentEmailModalBase
      onSubmit={onSubmit}
      onClose={onClose}
      documentsSchema={documentsSchema}
      title={t('TRADE_IN_SEND_EMAIL_MODAL__TITLE')}
      initialFormData={{
        emailTo: emails.primary,
        emailCC: emails.cc,
        subject: initialSubject,
      }}
    />
  );
};
