import React, { FC, useMemo } from 'react';
import {
  Model,
  Model_CommonItem,
  ModelFields,
  OptionItemFields,
  TotalsItem,
} from '@hypercharge/xdms-client/lib/types';
import TotalTableCollapsibleListRow from '../../components/TotalTableCollapsibleListRow';
import { get } from 'utils';
import { sortSelectedByDefaultAsFirst, TotalPageAdaptedFields } from '../../utils';
import { Price } from 'components/Price';
import { TFunction } from 'i18next';
import { totalsInnerTableColumnsWidths, ChangedPrice } from '../../styles';
import { TotalTextEllipsis } from 'pages/total/components/TotalTextEllipsis';

interface Props {
  model: Model | null;
  FIELDS: TotalPageAdaptedFields;
  t: TFunction;
  totalData: TotalsItem | null;
  hideZeroDiscounts: boolean;
  hideIfEmpty: boolean;
}

const OptionsSelected: FC<Props> = ({
  model,
  FIELDS,
  t,
  totalData,
  hideZeroDiscounts,
  hideIfEmpty,
}) => {
  const items = useMemo<Model_CommonItem[]>(() => {
    const items = get(model, ModelFields.options, []);

    return [...items]
      .sort(sortSelectedByDefaultAsFirst)
      .filter(item => !item[OptionItemFields.package])
      .filter(
        item =>
          !item[OptionItemFields.selectedByDefault] && item[OptionItemFields.selected],
      );
  }, [model]);

  if (hideIfEmpty && !items.length) return null;

  const totalDiscountAmount = get(totalData, FIELDS.optionsSelected.discountAmount, 0);
  const totalDiscountPercent = get(totalData, FIELDS.optionsSelected.discountPercent, 0);

  const showTotalDiscountAmount = Boolean(!hideZeroDiscounts || totalDiscountAmount);
  const showTotalDiscountPercent = Boolean(!hideZeroDiscounts || totalDiscountPercent);

  return (
    <TotalTableCollapsibleListRow
      columnsCustomWidths={totalsInnerTableColumnsWidths}
      items={items}
      getItemFields={item => {
        const discountAmount = get(item, FIELDS.optionsItemsFields.discountAmount, 0);
        const discountPercent = get(item, FIELDS.optionsItemsFields.discountPercent, 0);

        const showDiscountAmount = Boolean(!hideZeroDiscounts || discountAmount);
        const showDiscountPercent = Boolean(!hideZeroDiscounts || discountPercent);

        const bruttoAmount = item[FIELDS.optionsItemsFields.brutto];
        const nettoAmount = item[FIELDS.optionsItemsFields.netto];

        return [
          undefined,
          <TotalTextEllipsis>{item[FIELDS.optionsItemsFields.name]}</TotalTextEllipsis>,
          <TotalTextEllipsis>{item[FIELDS.optionsItemsFields.id]}</TotalTextEllipsis>,
          item[FIELDS.optionsItemsFields.quantity],
          item[FIELDS.optionsItemsFields.hasChanged] ? (
            <ChangedPrice value={bruttoAmount} />
          ) : (
            <Price value={bruttoAmount} />
          ),
          <>{showDiscountAmount && <Price value={discountAmount} />}</>,
          <>
            {showDiscountPercent && (
              <Price value={discountPercent} percentage priceStyle="decimal" />
            )}
          </>,
          item[FIELDS.optionsItemsFields.hasChanged] ? (
            <ChangedPrice value={nettoAmount} />
          ) : (
            <Price value={nettoAmount} />
          ),
        ];
      }}
      testIdPrefix="selected-notStandard-options"
      headerFields={[
        t('TOTAL_SELECTED_OPTIONS_HEADER_SUBTOTAL'),
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.optionsSelected.brutto, 0)} />,
        <>{showTotalDiscountAmount && <Price value={totalDiscountAmount} />}</>,
        <>
          {showTotalDiscountPercent && (
            <Price value={totalDiscountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price value={get(totalData, FIELDS.optionsSelected.netto, 0)} />,
      ]}
    />
  );
};

export default OptionsSelected;
