import React, { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import { ModalProps as VendorModalProps } from 'antd';
import Icon from '@ant-design/icons';
import { VARIANT_TO_WIDTH_MAP } from 'common/theme';
import { ScModal, ScModalCloseIcon } from './Modal.styles';

type Props = Omit<VendorModalProps, 'width' | 'footer' | 'centered' | 'title'> & {
  variant: keyof typeof VARIANT_TO_WIDTH_MAP;
  center?: boolean;
  transparent?: boolean;
};

export const Modal: FC<PropsWithChildren<Props>> = ({
  variant,
  center = false,
  transparent = false,
  className,
  closeIcon,
  ...rest
}) => {
  return (
    <ScModal
      {...rest}
      footer={null}
      title={null}
      centered={center}
      width={`${VARIANT_TO_WIDTH_MAP[variant]}em`}
      className={cn(className, 'modal', {
        'modal-transparent': transparent,
      })}
      closeIcon={closeIcon ?? <Icon component={ScModalCloseIcon} />}
    />
  );
};
