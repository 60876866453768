import { Button } from 'components/button';
import { DocumentModal } from 'pages/DynamicPage/Modals/DocumentModal';
import { TradeIn, TradeInProviderValues } from 'context/tradeIn/TradeInProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { TFunction } from 'i18next';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ImageItem } from 'types/common';
import { DEFAULT_DOCUMENT_NAME_KEY, DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { RightComponent, ScTable } from './TradeInTable.styles';
import { useColumns } from './use-columns';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Layout from 'layout/Default/Layout';
import Container from 'components/container/Container';
import { DocumentRelatedEntityCode } from 'context/document/types';
import TableImageGallery from 'components/ImageGalleryModal';
import TableFilters from 'components/table/filters';
import { useModelApi } from 'context/model/useModelApi';

interface TradeInTableProps {
  tradeInContext: TradeInProviderValues;
  isConfigurationComplete: boolean;
  currencyCode: string;
  language: string;
  t: TFunction;
  getTradeInDocuments(machineNumber: string): Promise<any[] | undefined>;
  handleTradeInSendEmail(machineNumber: string): void;
  handlePrevStep(): void;
  handleNextStep(): void;
}

export const TradeInTable: FC<TradeInTableProps> = ({
  tradeInContext,
  isConfigurationComplete,
  currencyCode,
  language,
  t,
  getTradeInDocuments,
  handleTradeInSendEmail,
  handlePrevStep,
  handleNextStep,
}) => {
  const {
    tradeIns,
    setTradeInFormComponent,
    deleteTradeIn,
    editTradeIn,
    handleSearch,
    totalCount,
    loadTradeIns,
  } = tradeInContext;

  const { isFeatureEnabled } = useFeature();
  const { getModel } = useModelApi();

  const isAllowAddNewTradeIn = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddNew,
  });
  const isAllowAddFile = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddFile,
  });
  const isAllowAddLink = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddLink,
  });

  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [currentTradeIn, setCurrentTradeIn] = useState<TradeIn>();
  const [carouselImages, setCarouselImages] = useState<ImageItem[]>([]);

  useEffect(() => {
    loadTradeIns();
  }, [loadTradeIns]);

  const handleDeleteTradeIn = useCallback(
    async (machineNumber: string | undefined) => {
      const { status, messageHandled } = await deleteTradeIn(machineNumber);

      // get updated model to have updated prices in header
      await getModel();

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TRADE_IN_DELETE_SUCCESS'),
          [Status.Error]: t('TRADE_IN_DELETE_ERROR'),
        });
      }
    },
    [deleteTradeIn, t, getModel],
  );

  const handleDocumentModal = useCallback((show: boolean, tradeIn?: TradeIn) => {
    setShowDocumentModal(show);
    setCurrentTradeIn(tradeIn);
  }, []);

  const closeDocumentModal = useCallback(() => {
    setShowDocumentModal(false);
    loadTradeIns();
  }, [loadTradeIns]);

  const handleViewImages = useCallback(
    async (tradeIn?: TradeIn): Promise<void> => {
      if (!tradeIn) {
        setCarouselImages([]);
        return;
      }

      const carouselImages: ImageItem[] = [];

      if (tradeIn.hasPhotos) {
        const documents = await getTradeInDocuments(tradeIn.machineNumber);

        if (documents?.length) {
          documents
            .filter(document => document.isPhoto)
            .forEach(document => {
              carouselImages.push({
                id: String(document.id),
                image: document.fileUrl,
                alt: document.name[DEFAULT_DOCUMENT_NAME_KEY],
              });
            });
        }
      }

      setCarouselImages(carouselImages);
    },
    [getTradeInDocuments],
  );

  const columns = useColumns({
    isConfigurationComplete,
    handleDocumentModal,
    handleViewImages,
    editTradeIn,
    handleTradeInSendEmail,
    handleDeleteTradeIn,
    currencyCode,
    language,
    t,
  });

  const addNewButton = (
    <RightComponent data-testid="tradein-page-table-filter-buttons-container">
      <Button
        variant="highlighted"
        onClick={() => setTradeInFormComponent(true)}
        data-testid="trade-in-add"
        disabled={isConfigurationComplete}
      >
        {t('TRADE_IN_ADD_NEW')}
      </Button>
    </RightComponent>
  );

  return (
    <Layout bg={false}>
      <Container>
        <TableImageGallery
          images={carouselImages}
          visible={carouselImages.length > 0}
          onCancel={() => setCarouselImages([])}
        />

        <TableFilters
          scrollMenuOptions={[]}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          onSelect={() => {}}
          prevStepDisabled={false}
          nextStepDisabled={false}
          onSearch={e => handleSearch(e.target.value)}
          extraRight={isAllowAddNewTradeIn ? addNewButton : null}
        />
        <ScTable
          rowKey={({ modelName, machineNumber }) => `${modelName}-${machineNumber}`}
          data={tradeIns}
          totalCount={totalCount}
          columns={columns}
          scroll={{ x: 1700 }}
          pagination={tradeIns.length > DEFAULT_TABLE_PAGE_SIZE}
        >
          {showDocumentModal && (
            <DocumentModal
              onClose={closeDocumentModal}
              relatedEntity={currentTradeIn}
              relationEntityCode={DocumentRelatedEntityCode.tradeInDedicated}
              isAddFileVisible={isAllowAddFile}
              isAddLinkVisible={isAllowAddLink}
            />
          )}
        </ScTable>
      </Container>
    </Layout>
  );
};
