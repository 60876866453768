import styled from 'styled-components';
import { FileImageOutlined } from '@ant-design/icons/lib';

export const SubcategoryWrapper = styled.div`
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.black};

    > div:last-child {
      background: ${({ theme }) => theme.mainColor};
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const SubcategoryImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  background-color: ${({ theme }) => theme.colors.whisper};

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SubcategoryTitle = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.softPeach};
  width: 100%;
  text-align: center;
  padding: 1.5625em;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.0625em;
  color: ${({ theme }) => theme.colors.black};
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const ScFileImageOutlined = styled(FileImageOutlined)`
  font-size: 3em;
`;
