import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  SellCodesListItem,
  SaleGroupCodesListItem,
  SalesCodesListItem,
  CustomerCommercialTypesListItem,
} from '@hypercharge/xdms-client/src/types';
import { Status } from 'utils/types';

const NAME = 'sharedOptions';

interface State {
  salesCodesListStatus: Status;
  salesCodesList: SalesCodesListItem[];
  sellCodesListStatus: Status;
  sellCodesList: SellCodesListItem[];
  saleGroupCodesListStatus: Status;
  saleGroupCodesList: SaleGroupCodesListItem[];
  customerCommercialTypesListStatus: Status;
  customerCommercialTypesList: CustomerCommercialTypesListItem[];
}

const initialState: State = {
  salesCodesListStatus: Status.Idle,
  salesCodesList: [],
  sellCodesListStatus: Status.Idle,
  sellCodesList: [],
  saleGroupCodesListStatus: Status.Idle,
  saleGroupCodesList: [],
  customerCommercialTypesListStatus: Status.Idle,
  customerCommercialTypesList: [],
};

const actions = {
  setSalesCodesListStatus: createAction<State['salesCodesListStatus']>(
    getActionName(NAME, 'SET_SALES_CODES_LIST_STATUS'),
  ),
  setSalesCodesList: createAction<State['salesCodesList']>(
    getActionName(NAME, 'SET_SALES_CODES_LIST'),
  ),

  setSellCodesListStatus: createAction<State['sellCodesListStatus']>(
    getActionName(NAME, 'SET_SELL_CODES_LIST_STATUS'),
  ),
  setSellCodesList: createAction<State['sellCodesList']>(
    getActionName(NAME, 'SET_SELL_CODES_LIST'),
  ),

  setSaleGroupCodesListStatus: createAction<State['saleGroupCodesListStatus']>(
    getActionName(NAME, 'SET_SALE_GROUP_CODES_LIST_STATUS'),
  ),
  setSaleGroupCodesList: createAction<State['saleGroupCodesList']>(
    getActionName(NAME, 'SET_SALE_GROUP_CODES_LIST'),
  ),

  setCustomerCommercialTypesListStatus: createAction<
    State['customerCommercialTypesListStatus']
  >(getActionName(NAME, 'SET_CUSTOMER_COMMERCIAL_TYPES_LIST_STATUS')),
  setCustomerCommercialTypesList: createAction<State['customerCommercialTypesList']>(
    getActionName(NAME, 'SET_CUSTOMER_COMMERCIAL_TYPES_LIST'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setSalesCodesListStatus, (state, action) => ({
    ...state,
    salesCodesListStatus: action.payload,
  }));
  builder.addCase(actions.setSalesCodesList, (state, action) => ({
    ...state,
    salesCodesList: action.payload,
  }));

  builder.addCase(actions.setSellCodesListStatus, (state, action) => ({
    ...state,
    sellCodesListStatus: action.payload,
  }));
  builder.addCase(actions.setSellCodesList, (state, action) => ({
    ...state,
    sellCodesList: action.payload,
  }));

  builder.addCase(actions.setSaleGroupCodesListStatus, (state, action) => ({
    ...state,
    saleGroupCodesListStatus: action.payload,
  }));
  builder.addCase(actions.setSaleGroupCodesList, (state, action) => ({
    ...state,
    saleGroupCodesList: action.payload,
  }));

  builder.addCase(actions.setCustomerCommercialTypesListStatus, (state, action) => ({
    ...state,
    customerCommercialTypesListStatus: action.payload,
  }));
  builder.addCase(actions.setCustomerCommercialTypesList, (state, action) => ({
    ...state,
    customerCommercialTypesList: action.payload,
  }));
});

const selectors = {
  getSalesCodesData: createSelector(
    ({ sharedOptions }: ApplicationState) => sharedOptions,
    state => ({
      salesCodesListStatus: state.salesCodesListStatus,
      salesCodesList: state.salesCodesList,
    }),
  ),
  getSellCodesData: createSelector(
    ({ sharedOptions }: ApplicationState) => sharedOptions,
    state => ({
      sellCodesListStatus: state.sellCodesListStatus,
      sellCodesList: state.sellCodesList,
    }),
  ),
  getSaleGroupCodesData: createSelector(
    ({ sharedOptions }: ApplicationState) => sharedOptions,
    state => ({
      saleGroupCodesListStatus: state.saleGroupCodesListStatus,
      saleGroupCodesList: state.saleGroupCodesList,
    }),
  ),
  getCustomerCommercialTypesData: createSelector(
    ({ sharedOptions }: ApplicationState) => sharedOptions,
    state => ({
      customerCommercialTypesListStatus: state.customerCommercialTypesListStatus,
      customerCommercialTypesList: state.customerCommercialTypesList,
    }),
  ),
};

export type { State as SharedOptionsState };
export {
  actions as sharedOptionsActions,
  reducer as sharedOptionsReducer,
  selectors as sharedOptionsSelectors,
};
