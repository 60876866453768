import React, { Component, PropsWithChildren } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ScErrorContainer } from './ErrorBoundary.styles';
import * as Sentry from '@sentry/react';

type StateT = {
  hasError: boolean;
};

class ErrorBoundary extends Component<PropsWithChildren<WithTranslation>, StateT> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    Sentry.withScope(scope => {
      scope.setExtra('data', { stack: error.stack });
      Sentry.captureException(error);
    });
    this.setState({ hasError: true });
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <ScErrorContainer>
          <h1 data-testid="error-boundary-error-text">{t('GLOBAL_ERROR_TEXT')}</h1>
        </ScErrorContainer>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()<typeof ErrorBoundary>(ErrorBoundary);
