import { Configuration_GetDetailsById_Output } from '@hypercharge/xdms-client/lib/types';
import { ContactItem, CustomerItem } from 'types/vendor';

import { useContact } from 'context/contact/ContactProvider';
import { useCustomer } from 'context/customer/CustomerProvider';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Status } from 'utils/types';
import { ConfigurationRelation } from './types';
import { relationActions } from './actions';
import { useFeature } from '../feature/FeatureProvider';
import { TFunction } from 'i18next';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

type ContextValue = {
  status: Status;
  relations: ConfigurationRelation[];
  setRelationCustomer(id: number | string, customer: CustomerItem | null): void;
  setRelationPrimaryContact(id: number | string, contact: ContactItem | null): void;
  setRelationAdditionalContacts(id: number | string, contacts: ContactItem[]): void;
  addRelationAdditionalContact(id: number | string, contact: ContactItem): void;
  removeRelationAdditionalContact(id: number | string, additionalId: number): void;
  restoreRelations(
    configuration: Configuration_GetDetailsById_Output | null | undefined,
    globalFeatures: Record<string, boolean | unknown>,
    t: TFunction,
    customerId: string | null | undefined,
  ): Promise<void>;
  unsetRelations(): void;
  areMandatoryRelationsFilled: boolean;
  getArePrevRelationsFilled(r?: ConfigurationRelation): boolean;
  swapRelationsConfiguration(
    activeRelationId: number | string,
    swapRelationId: number | string,
  ): void;
  copyRelationData(
    activeRelationId: number | string,
    targetRelationId: number | string,
  ): void;
};

const RelationsContext = React.createContext<ContextValue | undefined>(undefined);

const RelationsProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { getCustomerById } = useCustomer();
  const { getContactById } = useContact();
  const { isFeatureEnabled } = useFeature();

  const isContactsFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.ContactsEnabled,
  });
  const isContactMandatoryEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowContactMandatory,
  });

  const [relations, setRelations] = useState<ContextValue['relations']>([]);

  const [status, setStatus] = useState(Status.Idle);

  /**
   * Set relation's Customer by relation id
   * @param {number | string} id
   * @param {CustomerItem | null} customer
   */
  const setRelationCustomer = useCallback<ContextValue['setRelationCustomer']>(
    (id, customer) => {
      setRelations(relationActions.setRelationCustomer(id, customer));
    },
    [],
  );

  /**
   * Set relation's Primary Contact by relation id
   * @param {number | string} id
   * @param {ContactItem | null} customer
   */
  const setRelationPrimaryContact = useCallback<
    ContextValue['setRelationPrimaryContact']
  >((id, contact) => {
    setRelations(relationActions.setRelationPrimaryContact(id, contact));
  }, []);

  /**
   * Set additional contacts on relation
   * @param {number | string} id
   * @param {ContactItem[]} customer
   */
  const setRelationAdditionalContacts = useCallback<
    ContextValue['setRelationAdditionalContacts']
  >((id, contacts) => {
    setRelations(relationActions.setRelationAdditionalContacts(id, contacts));
  }, []);

  /**
   * Add additional contact on relation
   * @param {number | string} id
   * @param {ContactItem | null} customer
   */
  const addRelationAdditionalContact = useCallback<
    ContextValue['addRelationAdditionalContact']
  >((id, contact) => {
    setRelations(relationActions.addRelationAdditionalContact(id, contact));
  }, []);

  /**
   * Remove additional contact on relation
   * @param {number | string} id
   * @param {ContactItem | null} customer
   */
  const removeRelationAdditionalContact = useCallback<
    ContextValue['removeRelationAdditionalContact']
  >((id, additionalId) => {
    setRelations(relationActions.removeRelationAdditionalContact(id, additionalId));
  }, []);

  const unsetRelations = useCallback<ContextValue['unsetRelations']>(() => {
    setRelations(relationActions.unsetRelations());
  }, []);

  const restoreRelations = useCallback<ContextValue['restoreRelations']>(
    async (configuration, globalFeatures, t, customerId) => {
      setStatus(Status.Loading);

      let updatedRelations: ConfigurationRelation[] =
        relationActions.setupRelationsFromGlobalFeatures(
          globalFeatures,
          customerId,
        )(relations);

      if (configuration) {
        updatedRelations = await relationActions.restoreRelationsByConfigurationDetails(
          configuration,
          updatedRelations,
          getCustomerById,
          getContactById,
        );
      }

      setRelations(updatedRelations);

      setStatus(Status.Idle);
    },
    [getContactById, getCustomerById, relations],
  );

  /**
   * Check if prev mand relations (relatively to passed) are filled.
   * If no relation passed - check all mand relations to be filled.
   */
  const getArePrevRelationsFilled = useCallback<
    ContextValue['getArePrevRelationsFilled']
  >(
    (relation?: ConfigurationRelation) => {
      const prevRelations = relation ? relations.slice(0, relation.idx) : relations;

      const mandatoryRelations = prevRelations.filter(({ mandatory }) => mandatory);

      return mandatoryRelations.every(rel => {
        if (!rel.mandatory) return true;

        let result = Boolean(rel.customer);

        if (isContactsFeatureEnabled && isContactMandatoryEnabled)
          result = Boolean(rel.primaryContact);

        return result;
      });
    },
    [relations, isContactsFeatureEnabled, isContactMandatoryEnabled],
  );

  const areMandatoryRelationsFilled = useMemo<
    ContextValue['areMandatoryRelationsFilled']
  >(() => {
    return getArePrevRelationsFilled();
  }, [getArePrevRelationsFilled]);

  /**
   * Swap relations configuration by relation ids
   * @param {number | string} activeRelationId
   * @param {number | string} swapRelationId
   */
  const swapRelationsConfiguration = useCallback<
    ContextValue['swapRelationsConfiguration']
  >((activeRelationId, swapRelationId) => {
    setRelations(
      relationActions.swapRelationsConfiguration(activeRelationId, swapRelationId),
    );
  }, []);

  const copyRelationData = useCallback<ContextValue['copyRelationData']>(
    (activeRelationId, targetRelationId) => {
      setRelations(relationActions.copyRelationData(activeRelationId, targetRelationId));
    },
    [],
  );

  const value = useMemo(
    () => ({
      status,
      relations,
      setRelationCustomer,
      setRelationPrimaryContact,
      setRelationAdditionalContacts,
      addRelationAdditionalContact,
      removeRelationAdditionalContact,
      restoreRelations,
      unsetRelations,
      areMandatoryRelationsFilled,
      getArePrevRelationsFilled,
      swapRelationsConfiguration,
      copyRelationData,
    }),
    [
      status,
      relations,
      setRelationCustomer,
      setRelationPrimaryContact,
      setRelationAdditionalContacts,
      addRelationAdditionalContact,
      removeRelationAdditionalContact,
      restoreRelations,
      unsetRelations,
      areMandatoryRelationsFilled,
      getArePrevRelationsFilled,
      swapRelationsConfiguration,
      copyRelationData,
    ],
  );
  return <RelationsContext.Provider value={value} {...props} />;
};

const useRelations = (): ContextValue => {
  const context = useContext(RelationsContext);
  if (context === undefined) {
    throw new Error(
      `${useRelations.name} must be used within an ${RelationsProvider.name}`,
    );
  }
  return context;
};

export { useRelations, RelationsProvider };
