import styled from 'styled-components/macro';
import { ConfigurationAction } from './ConfigurationAction';

export const ScCurrenContainerWrapper = styled.div`
  font-size: 0.875em;
  margin-top: 0.75em;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const ScLinksContainer = styled.div`
  width: 19.75em;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  display: flex;
  flex-direction: column;
  padding: 1.375em 1em;
  margin-top: 0.438em;
`;

export const ScConfigurationAction = styled(ConfigurationAction)`
  margin-top: 0.75em;
  margin-bottom: 0;
  border-color: rgba(152, 152, 152, 0.25);
`;

export const ScComplementActionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.75em;
`;

export const Separator = styled.div`
  height: 1.063em;
  border-right: 1px solid ${props => props.theme.colors.dustyGray};
  opacity: 0.25;
`;

export const ScCurrencyWrapper = styled.div<{ $isCentered: boolean }>`
  margin: ${({ $isCentered }) => ($isCentered ? '0 auto' : '0')};
  display: flex;
  align-items: center;
`;

export const ScStreamingToggleWrapper = styled.div`
  margin-right: auto;
`;
