import { useCallback } from 'react';
import {
  ProspectDossier_UpdateMainCustomer_Output,
  ProspectDossier_UpdateAdditionalCustomers_Output,
  ProspectDossier_GetById_Output,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerItem } from 'types/vendor';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { RequestResponseDetails } from 'types/common';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { ProspectDossierFieldsPickers } from './utils';
import { useDispatch } from 'react-redux';
import { prospectDossierActions } from 'store';

type UpdateProspectDossierCustomerByIdx_Output = Pick<
  ProspectDossier_UpdateMainCustomer_Output,
  'success'
> &
  Pick<ProspectDossier_UpdateAdditionalCustomers_Output, 'success'>;

interface ProspectDossierApi {
  updateProspectDossierCustomerByIdx(
    idx: number,
    prospectDossierId: number,
    customer: Partial<CustomerItem>,
  ): Promise<RequestResponseDetails<UpdateProspectDossierCustomerByIdx_Output>>;
  getProspectDossierById(
    id: number | string,
  ): Promise<RequestResponseDetails<ProspectDossier_GetById_Output>>;
}

export const useProspectDossierApi = (): ProspectDossierApi => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const dispatch = useDispatch();

  const updateProspectDossierCustomerByIdx = useCallback<
    ProspectDossierApi['updateProspectDossierCustomerByIdx']
  >(
    async (idx, prospectDossierId, customer) => {
      let result: RequestResponseDetails<UpdateProspectDossierCustomerByIdx_Output>;

      const fieldsToUpdate = ProspectDossierFieldsPickers[idx]?.(customer);
      try {
        if (!fieldsToUpdate) throw new Error('Unexpected relation');

        let response:
          | ProspectDossier_UpdateMainCustomer_Output
          | ProspectDossier_UpdateAdditionalCustomers_Output;

        if (idx === 0) {
          response = await xdmsClient.prospectDossier.updateMainCustomerById(
            prospectDossierId,
            fieldsToUpdate,
          );
        } else {
          response = await xdmsClient.prospectDossier.updateAdditinalCustomersById(
            prospectDossierId,
            fieldsToUpdate,
          );
        }

        result = { status: Status.Success, response: response };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient.prospectDossier],
  );

  const getProspectDossierById = useCallback<
    ProspectDossierApi['getProspectDossierById']
  >(
    async id => {
      let result: RequestResponseDetails<ProspectDossier_GetById_Output>;

      try {
        dispatch(prospectDossierActions.setStatus(Status.Loading));
        const response = await xdmsClient.prospectDossier.getById(id);

        dispatch(prospectDossierActions.setStatus(Status.Success));
        dispatch(prospectDossierActions.set(response));
        result = { status: Status.Success, response: response };
      } catch (e) {
        notification.requestError(e);
        dispatch(prospectDossierActions.setStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient.prospectDossier, dispatch],
  );

  return {
    updateProspectDossierCustomerByIdx: updateProspectDossierCustomerByIdx,
    getProspectDossierById: getProspectDossierById,
  };
};
