import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  #root {
    ${({ theme }) => {
      if (theme.letterSpacing) return `letter-spacing: ${theme.letterSpacing}em;`;
      return '';
    }}
  }

  /* Have class to dont affect existing tables and easily find it while refactor */
  table.styledTable {
    table-layout: fixed;
    width:100%;
    font-weight: 400;
    font-size: 0.875em;
    thead {
      tr {
        td, th {
          font-weight: 500;
          padding-top: 0.75em;
          padding-bottom: 0.75em;
        }
      }
    }

    tbody {
      tr {
        td{
          padding-top: 1.375em;
          padding-bottom: 1.375em;
          border-width: 1px 0 0 0;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.whisper};
        }
      }
    }
  }

  /* Stretches button inside NavLink, Link */
  a > button.ant-btn {
    width: 100%;
  }
`;
