import {
  Model,
  ModelFields,
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonItem_Type_Values,
} from '@hypercharge/xdms-client/lib/types';
import { ColumnsType } from 'antd/lib/table';
import { useCurrency } from 'context/currency/CurrencyProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExcludesFalse } from 'types/common';
import { formatPrice } from 'utils/format';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';
import { commonItemType__mapping__translationKey } from './RulesModal.meta';
import { useSelector } from 'react-redux';
import { sharedSelectors } from 'store';

export type ObligatoryStateItem = {
  key: string;
  item: Model_CommonItem;
  selected: boolean;
  relatedPackage?: Model_CommonItem;
};

export type ObligatoryState = ObligatoryStateItem[];

export const assembleObligatoryState = (
  model: Model,
  itemsIdsList: string[],
): ObligatoryState => {
  const itemsList = itemsIdsList
    .map(id => getModelCommonItemById(model, id))
    .filter(Boolean as unknown as ExcludesFalse);

  return itemsList.map(item => {
    const result: ObligatoryStateItem = {
      key: item[Model_CommonItemFields.ID],
      item: item,
      selected: true,
    };

    if (item[Model_CommonItemFields.type] === Model_CommonItem_Type_Values.packageLine) {
      const package_ = model[ModelFields.packages]?.find(
        record =>
          record[Model_CommonItemFields.ID] === item[Model_CommonItemFields.package],
      );

      if (package_) result.selected = package_[Model_CommonItemFields.selected];
    }

    return result;
  });
};

export const useObligatoryColumns = (): ColumnsType<ObligatoryStateItem> => {
  const { t, i18n } = useTranslation();
  const { currencyCode } = useCurrency();
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  return [
    {
      title: t('TABLE_ITEM_ID'),
      key: 'key',
      width: '9em',
      render: (value, record) => <>{record.item[Model_CommonItemFields.ID]}</>,
    },
    {
      title: t('DESCRIPTION'),
      width: '24em',
      dataIndex: ['item', Model_CommonItemFields.name],
      className: 'cell__description',
    },
    {
      title: t('ENTITY'),
      width: '8em',
      render: (value, record) => {
        return t(
          commonItemType__mapping__translationKey[
            record.item[Model_CommonItemFields.type]
          ],
          { value: record.relatedPackage?.[Model_CommonItemFields.ID] },
        );
      },
    },
    {
      title: t('MODAL_RULES__COLUMN_TITLE_GROSSPRICE'),
      render: (value, record) => {
        const field = shouldShowPricesWithVAT
          ? Model_CommonItemFields.grossPriceVat
          : Model_CommonItemFields.grossPrice;

        return formatPrice({
          price: record.item[field],
          currency: currencyCode,
          locale: i18n.language,
        });
      },
    },
  ];
};
