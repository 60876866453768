import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { mediaMax } from '../../common/theme';

// we suppress hover effect for touch devices
// because otherwise they keep the state when one focuses / touched them
// and never gives the control back
export const ScButton = styled(AntButton)`
  font-size: inherit;
  text-align: center;
  border-style: solid;
  border-radius: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0 2.5em;
  border-width: 1px;
  max-width: unset;

  ${mediaMax.xs} {
    padding: 1em 1.75em;
  }
  height: ${props => props.theme.buttonHeight};

  &.variant_primary {
    border-color: ${props => props.theme.mainColor};
    background-color: ${props => props.theme.mainColor};
    color: ${props => props.theme.mainColor_contrast};

    &:hover {
      border-color: ${props => props.theme.mainColor_hovered};
      background-color: ${props => props.theme.mainColor_hovered};
      color: ${props => props.theme.mainColor_contrast};
    }
    &:focus {
      border-color: ${props => props.theme.mainColor_contrast};
      background-color: ${props => props.theme.mainColor_pressed};
      color: ${props => props.theme.mainColor_contrast};
    }
  }

  &.variant_highlighted {
    border-color: ${props => props.theme.highlightColor};
    background-color: ${props => props.theme.highlightColor};
    color: ${props => props.theme.highlightColor_contrast};

    &:hover {
      border-color: ${props => props.theme.highlightColor_hovered};
      background-color: ${props => props.theme.highlightColor_hovered};
      color: ${props => props.theme.highlightColor_contrast};
    }
    &:focus {
      border-color: ${props => props.theme.highlightColor_contrast};
      background-color: ${props => props.theme.highlightColor_pressed};
      color: ${props => props.theme.highlightColor_contrast};
    }
  }

  &,
  &.variant_prev {
    border-color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};

    &:hover {
      border-color: ${props => props.theme.mainColor_hovered};
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.black};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: ${props => props.theme.mainColor_hovered};
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.black};
      }
    }
    &:focus {
      border-color: ${props => props.theme.mainColor_pressed};
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.mainColor};
    }
    &[disabled] {
      border-color: ${props => props.theme.disabledSurfaceColor};
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.disabledSurfaceColor};
    }
  }

  &.variant_add,
  &.variant_next {
    border-color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};

    &:hover {
      border-color: ${props => props.theme.colors.outerSpace};
      background-color: ${props => props.theme.colors.outerSpace};
      color: ${props => props.theme.colors.white};
      opacity: 0.9;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: ${props => props.theme.colors.outerSpace};
        background-color: ${props => props.theme.colors.outerSpace};
        color: ${props => props.theme.colors.white};
        opacity: 0.9;
      }
    }

    &:active,
    &:focus {
      border-color: ${props => props.theme.mainColor};
      background-color: ${props => props.theme.colors.dustyGray};
      color: ${props => props.theme.colors.white};
    }
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: ${props => props.theme.disabledSurfaceColor};
    border-color: ${props => props.theme.disabledSurfaceColor};
    color: ${props => props.theme.disabledSurfaceColor_contrast};
  }

  &.fullwidth {
    width: 100%;
  }

  &.noBorder,
  &.no-border {
    border: none;
  }

  &.transparent {
    background: transparent;
  }

  &.ant-btn-circle {
    padding: 0;
    border-radius: 50%;
    width: 4.375em;
    height: 4.375em;
    justify-content: center;

    &:hover,
    &.ant-dropdown-open {
      transform: rotate(45deg);
    }

    span {
      font-size: 1.875em;
    }
  }

  &.custom-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .anticon {
      font-size: 1.5em;
      height: 3em;
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid ${({ theme }) => theme.mainBorderColor};
      background-color: ${({ theme }) => theme.mainColor};

      + span {
        padding: 0 4em;
        margin-left: 0;

        ${mediaMax.sm} {
          padding: 0 1em;
        }
      }
    }
  }

  &.menu-item {
    border-color: ${({ theme }) => theme.colors.softPeach};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};

    &[disabled] {
      border-color: ${({ theme }) => theme.disabledSurfaceColor_contrast};
      background-color: ${props => props.theme.disabledSurfaceColor};
      color: ${props => props.theme.disabledSurfaceColor_contrast};
    }

    &.active {
      border-color: ${({ theme }) => theme.mainColor};
      background-color: ${props => props.theme.mainColor};
      color: ${props => props.theme.mainColor_contrast};

      &:hover {
        border-color: ${props => props.theme.mainColor_hovered};
        background-color: ${props => props.theme.mainColor_hovered};
        color: ${props => props.theme.mainColor_contrast};
      }
      &:focus {
        border-color: ${props => props.theme.mainColor_contrast};
        background-color: ${props => props.theme.mainColor_pressed};
        color: ${props => props.theme.mainColor_contrast};
      }
    }
  }
`;

export const ScButtonContent = styled.span`
  font-size: 0.75em;
  font-weight: 600;
`;
