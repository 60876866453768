import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Country, Message } from 'types/vendor';
import { Status } from 'utils/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { notification } from 'utils/notification';

type ContextValue = {
  countries: Country[];
  status: Status;
  getCountries(): Promise<Country[]>;
};

const CountryContext = React.createContext<ContextValue | undefined>(undefined);

const CountryProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();
  const [status, setStatus] = useState<ContextValue['status']>(Status.Idle);
  const [countries, setCountries] = useState<ContextValue['countries']>([]);

  const getCountries = useCallback<ContextValue['getCountries']>(async () => {
    let result: Country[] = [];

    try {
      setStatus(Status.Loading);

      const response: { messages: Message[]; records: Country[] } =
        await xdmsClient.countries.getList([]);

      notification.open({ message: response.messages });

      setCountries(response.records);
      setStatus(Status.Success);

      result = response.records;
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return result;
  }, [xdmsClient]);

  const value = useMemo(
    () => ({
      countries,
      status,
      getCountries,
    }),
    [countries, status, getCountries],
  );

  return <CountryContext.Provider value={value} {...props} />;
};

const useCountry = (): ContextValue => {
  const context: ContextValue | undefined = useContext(CountryContext);

  const getCountries = context?.getCountries;

  // Will trigger in used component at first render.
  // Instead manually asking in each component.
  useEffect(() => {
    getCountries?.();
  }, [getCountries]);

  if (context === undefined) {
    throw new Error('useCountry must be used within an CountryProvider');
  }

  return context;
};

export { CountryProvider, useCountry };
