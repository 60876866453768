import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CustomerSalutation,
  CustomerTitle,
  CustomerType,
  CustomerCommercialType,
  Message,
} from 'types/vendor';
import { CustomerFields, CustomerItem, Filter } from 'types/vendor';
import { Status } from 'utils/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { get } from 'utils';
import { notification } from 'utils/notification';
import { RequestResponseDetails } from '../../types/common';
import { API_CUSTOMER_DETAILS_URL } from 'common/constants';

type GetCustomerDetailsServiceResponseData = {
  'ns2:countryCode': string;
  'ns2:vatNumber': number;
  'ns2:requestDate': string;
  'ns2:valid': boolean;
  'ns2:name': string;
  'ns2:address': string;
};
type GetCustomerDetailsServiceResponseBody =
  | {
      'env:Fault': {
        faultcode: string;
        faultstring: string;
      };
    }
  | { 'ns2:checkVatResponse': GetCustomerDetailsServiceResponseData };

type GetCustomerDetailsServiceResponse = {
  'env:Envelope': {
    'env:Header': '';
    'env:Body': GetCustomerDetailsServiceResponseBody;
  };
};

type ContextValue = {
  status: Status;
  customers: CustomerItem[];
  customerTypes: CustomerType[];
  customerCommercialTypes: CustomerCommercialType[];
  customerSalutations: CustomerSalutation[];
  customerTitles: CustomerTitle[];
  getCustomers(filter?: Filter<CustomerFields>): Promise<CustomerItem[] | null>;
  getCustomerById(id: string, fields?: Array<string>): Promise<CustomerItem | null>;
  getCustomerTypes(): Promise<{ records: CustomerType[]; message: Message[] } | null>;
  loadCustomersList(filter?: Filter<CustomerFields>): Promise<CustomerItem[]>;
  getCustomerCommercialTypes(): Promise<CustomerCommercialType[] | null>;
  getCustomerSalutations(): Promise<CustomerSalutation[] | null>;
  getCustomerTitles(): Promise<CustomerTitle[] | null>;
  getCustomerByVat(vat: string): Promise<CustomerItem | null>;
  createCustomer(
    customer: CustomerItem,
  ): Promise<RequestResponseDetails<CustomerItem | null>>;
  updateCustomer(
    id: number,
    customer: CustomerItem,
  ): Promise<RequestResponseDetails<CustomerItem | null>>;
};

export function getCustomerInitialValues(): CustomerItem {
  return Object.assign(
    {},
    {
      [CustomerFields.name]: '',
      [CustomerFields.vatNumber]: '',
      [CustomerFields.name2]: '',
      [CustomerFields.email]: '',
      [CustomerFields.phone]: '',
      [CustomerFields.phone2]: '',
      [CustomerFields.mobilePhone]: '',
      [CustomerFields.address]: '',
      [CustomerFields.address2]: '',
      [CustomerFields.country]: '',
      [CustomerFields.zip]: '',
      [CustomerFields.city]: '',
      [CustomerFields.lang]: '',
      [CustomerFields.note]: '',
      [CustomerFields.title]: '',
    },
  );
}

const CustomerContext = React.createContext<ContextValue | undefined>(undefined);

const CustomerProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();
  const [status, setStatus] = useState(Status.Idle);
  const [customers, setCustomers] = useState<ContextValue['customers']>([]);
  const [customerTypes, setCustomerTypes] = useState<ContextValue['customerTypes']>([]);
  const [customerCommercialTypes, setCustomerCommercialTypes] = useState<
    ContextValue['customerCommercialTypes']
  >([]);
  const [customerSalutations, setCustomerSalutations] = useState<
    ContextValue['customerSalutations']
  >([]);
  const [customerTitles, setCustomerTitles] = useState<ContextValue['customerTitles']>(
    [],
  );

  const loadCustomersList = useCallback<ContextValue['loadCustomersList']>(
    async filter => {
      let list: CustomerItem[] = [];
      try {
        list = await xdmsClient.customers.getList(filter);
      } catch (e) {
        notification.requestError(e);
      }
      return list;
    },
    [xdmsClient],
  );

  const getCustomers = useCallback<ContextValue['getCustomers']>(
    async (filter): Promise<CustomerItem[] | CustomerItem[] | null> => {
      let customersList: CustomerItem[] = [];

      try {
        setStatus(Status.Loading);
        customersList = await xdmsClient.customers.getList(filter);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      setCustomers(customersList);

      return customersList;
    },
    [setCustomers, setStatus, xdmsClient],
  );

  const getCustomerById = useCallback<ContextValue['getCustomerById']>(
    async (id: string | number): Promise<CustomerItem | null> => {
      let customer: CustomerItem | null = null;

      try {
        setStatus(Status.Loading);
        customer = await xdmsClient.customers.getById(Number(id));
        setStatus(Status.Success);

        return customer;
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return customer;
    },
    [xdmsClient],
  );

  const getCustomerByVat = useCallback<ContextValue['getCustomerByVat']>(async vat => {
    const response = await fetch(API_CUSTOMER_DETAILS_URL + '?vat=' + vat, {
      method: 'get',
    });
    const body: GetCustomerDetailsServiceResponse = await response.json();
    const data = body['env:Envelope']['env:Body']['ns2:checkVatResponse'];

    if (!data || !data['ns2:valid']) return null;

    const addressLines = (data as GetCustomerDetailsServiceResponseBody)[
      'ns2:address'
    ]?.split('\n');

    const addressLineSpaceIdx = addressLines[1]?.indexOf(' ') || -1;

    const result = {
      [CustomerFields.address]: addressLines[0],
      [CustomerFields.zip]: ~addressLineSpaceIdx
        ? addressLines[1]?.substring(0, addressLineSpaceIdx)
        : undefined,
      [CustomerFields.city]: ~addressLineSpaceIdx
        ? addressLines[1]?.substring(addressLineSpaceIdx + 1)
        : undefined,
      [CustomerFields.name]: data['ns2:name'],
    };

    result[CustomerFields.vatNumber] = vat;

    return result;
  }, []);

  const createCustomer = useCallback<ContextValue['createCustomer']>(
    async (
      customer: CustomerItem,
    ): Promise<RequestResponseDetails<CustomerItem | null>> => {
      const result: RequestResponseDetails<CustomerItem | null> = {
        status: Status.Idle,
        messageHandled: false,
        response: null,
      };

      try {
        setStatus(Status.Loading);

        const { record, messages } = await xdmsClient.customers.create(customer);

        if (messages.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        setStatus(Status.Success);

        result.response = record;
        result.status = Status.Success;
      } catch (e) {
        notification.requestError(e, setStatus);

        result.status = Status.Error;
        result.messageHandled = true;
      }

      return result;
    },
    [xdmsClient],
  );

  const updateCustomer = useCallback<ContextValue['updateCustomer']>(
    async (id: number, customer: CustomerItem) => {
      const result: RequestResponseDetails<CustomerItem | null> = {
        status: Status.Idle,
        messageHandled: false,
        response: null,
      };

      try {
        setStatus(Status.Loading);

        const { record, messages = [] } = await xdmsClient.customers.update(id, customer);

        if (messages.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        setStatus(Status.Success);

        result.response = record;
        result.status = Status.Success;
      } catch (e) {
        notification.requestError(e, setStatus);

        result.status = Status.Error;
        result.messageHandled = true;
      }

      return result;
    },
    [xdmsClient],
  );

  const getCustomerTypes = useCallback<
    ContextValue['getCustomerTypes']
  >(async (): Promise<{ records: CustomerType[]; message: Message[] } | null> => {
    let response: { records: CustomerType[]; message: Message[] } | null = null;

    try {
      setStatus(Status.Loading);
      response = await xdmsClient.customers.getTypesList();
      const customerTypes = get(response, 'records', []);
      // use messages when needed
      // const messages = get(response, 'message', []);
      setCustomerTypes(customerTypes);
      setStatus(Status.Success);
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return response;
  }, [xdmsClient]);

  // getCommercialTypesList
  // @todo: while refactor - replace with {@link useSharedOptionsApi.getCustomerCommercialTypes}
  const getCustomerCommercialTypes = useCallback<
    ContextValue['getCustomerCommercialTypes']
  >(async () => {
    let response: CustomerCommercialType[] | null = null;
    try {
      setStatus(Status.Loading);

      response = await xdmsClient.customers.getCommercialTypesList();
      response && setCustomerCommercialTypes(response);

      setStatus(Status.Success);
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return response;
  }, [xdmsClient]);

  const getCustomerSalutations = useCallback<
    ContextValue['getCustomerSalutations']
  >(async () => {
    let response: CustomerSalutation[] | null = null;
    try {
      setStatus(Status.Loading);

      response = await xdmsClient.customers.getSalutationsList();
      response && setCustomerSalutations(response);

      setStatus(Status.Success);
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return response;
  }, [xdmsClient, setStatus]);

  const getCustomerTitles = useCallback<ContextValue['getCustomerTitles']>(async () => {
    let response: CustomerTitle[] | null = null;
    try {
      setStatus(Status.Loading);

      response = await xdmsClient.customers.getTitlesList();
      response && setCustomerTitles(response);

      setStatus(Status.Success);
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return response;
  }, [xdmsClient, setStatus]);

  useEffect(() => {
    getCustomerTypes();
  }, [getCustomerTypes]);

  const value = useMemo(
    () => ({
      status,
      customers,
      customerTypes,
      customerCommercialTypes,
      customerSalutations,
      customerTitles,
      getCustomers,
      getCustomerById,
      getCustomerByVat,
      getCustomerTypes,
      loadCustomersList,
      getCustomerCommercialTypes,
      getCustomerSalutations,
      getCustomerTitles,
      createCustomer,
      updateCustomer,
    }),
    [
      status,
      customers,
      customerTypes,
      customerCommercialTypes,
      customerSalutations,
      customerTitles,
      getCustomers,
      getCustomerById,
      getCustomerByVat,
      getCustomerTypes,
      loadCustomersList,
      getCustomerCommercialTypes,
      getCustomerSalutations,
      getCustomerTitles,
      createCustomer,
      updateCustomer,
    ],
  );
  return <CustomerContext.Provider value={value} {...props} />;
};

const useCustomer = (): ContextValue => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomer must be used within an CustomerProvider');
  }
  return context;
};

export { useCustomer, CustomerProvider, CustomerContext };
