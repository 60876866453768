import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  DocumentListItem,
  DocumentTypeCategoryListItem,
  DocumentTypeCategoryTypeListItem,
  DocumentTypeListItem,
} from '@hypercharge/xdms-client/lib/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { documentTypesActions } from 'store';
import { RequestResponseDetails } from 'types/common';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { DocumentRelatedEntityCode } from './types';
import { DocumentRelatedEntitySettingsSelectors } from './settings';

interface DocumentTypesApi {
  getDocumentTypeCategories(params: {
    relatedEntityCode: DocumentRelatedEntityCode;
    documentRecord?: DocumentListItem;
  }): Promise<RequestResponseDetails<DocumentTypeCategoryListItem[]>>;
  getDocumentTypeCategoryTypes(params: {
    relatedEntityCode: DocumentRelatedEntityCode;
    documentRecord?: DocumentListItem;
    categoryCode: string;
  }): Promise<RequestResponseDetails<DocumentTypeCategoryTypeListItem[]>>;
  getDocumentTypes(): Promise<RequestResponseDetails<DocumentTypeListItem[]>>;
}

export const useDocumentTypesApi = (): DocumentTypesApi => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const getDocumentTypeCategories = useCallback<
    DocumentTypesApi['getDocumentTypeCategories']
  >(
    async ({ relatedEntityCode, documentRecord: documentDetails }) => {
      let result: RequestResponseDetails<DocumentTypeCategoryListItem[]>;

      dispatch(documentTypesActions.setDocumentTypeCategoryListStatus(Status.Loading));

      try {
        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);
        const response = await xdmsClient.document.getTypeCategoriesList(
          settings.getTypeCategoriesPicklistCode(documentDetails),
        );
        result = { status: Status.Success, response: response };
        dispatch(documentTypesActions.setDocumentTypeCategoryListStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(documentTypesActions.setDocumentTypeCategoryListStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.document],
  );

  const getDocumentTypeCategoryTypes = useCallback<
    DocumentTypesApi['getDocumentTypeCategoryTypes']
  >(
    async ({ relatedEntityCode, documentRecord: documentDetails, categoryCode }) => {
      let result: RequestResponseDetails<DocumentTypeCategoryTypeListItem[]>;

      dispatch(
        documentTypesActions.setDocumentTypeCategoryTypesListStatus(Status.Loading),
      );
      try {
        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);
        const response = await xdmsClient.document.getTypeCategoryTypesList(
          settings.getTypeCategoriesPicklistCode(documentDetails),
          categoryCode,
        );
        result = { status: Status.Success, response: response };
        dispatch(
          documentTypesActions.setDocumentTypeCategoryTypesListStatus(Status.Success),
        );
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(
          documentTypesActions.setDocumentTypeCategoryTypesListStatus(Status.Error),
        );
      }

      return result;
    },
    [dispatch, xdmsClient.document],
  );

  const getDocumentTypes = useCallback<DocumentTypesApi['getDocumentTypes']>(async () => {
    let result: RequestResponseDetails<DocumentTypeListItem[]>;

    dispatch(documentTypesActions.setDocumentTypesListStatus(Status.Loading));
    try {
      const response = await xdmsClient.document.getTypesList();

      result = { status: Status.Success, response: response };
      dispatch(documentTypesActions.setDocumentTypesListStatus(Status.Success));
      dispatch(documentTypesActions.setDocumentTypesList(response));
    } catch (e) {
      notification.requestError(e);
      result = { status: Status.Error, messageHandled: true };
      dispatch(documentTypesActions.setDocumentTypesListStatus(Status.Error));
    }

    return result;
  }, [xdmsClient.document, dispatch]);

  return {
    getDocumentTypeCategories: getDocumentTypeCategories,
    getDocumentTypeCategoryTypes: getDocumentTypeCategoryTypes,
    getDocumentTypes: getDocumentTypes,
  };
};
