import styled from 'styled-components';

export const ScDocumentsHeader = styled.div`
  width: 100%;
  font-size: 1.25em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5em;
`;

export const ScPublishButtonWrapper = styled.div`
  margin-top: 0.625em;
`;
