import styled from 'styled-components';

export const ScLine = styled.div`
  padding: 0.25em 0;
`;

export const ScLineNested = styled.div`
  padding: 0.25em 0 0.25em 1.5em;
`;

export const ScLineContentGrouped = styled.div`
  padding: 0.25em 0 0.25em 1.5em;
  display: flex;
  align-items: center;

  .ant-checkbox-wrapper {
    padding-right: 1em;
  }
`;

export const ScFooter = styled.footer`
  padding: 1.25em;
`;

export const ScBody = styled.div`
  padding: 1.5em;
  .ant-radio-wrapper span {
    text-transform: capitalize;
  }

  .ant-radio-group {
    width: 100%;
  }
`;
