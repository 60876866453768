import { StructureLabel, StructureLabelFields } from '@hypercharge/xdms-client/lib/types';
import { get } from '../../utils';
import { STRUCTURE_LABEL } from '../../common/constants';

/**
 * Filter labels for SubSteps from list of all labels
 * @param {StructureLabel[]} labels
 * @return {StructureLabel[]}
 */
export const filterSubStepsLabels = (labels: StructureLabel[]): StructureLabel[] => {
  return labels.filter(label =>
    get(label, StructureLabelFields.code, '').includes(STRUCTURE_LABEL),
  );
};
