import * as Yup from 'yup';
import { FinancingCustomer, FinancingCustomerFields } from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<FinancingCustomer>>({
    [FinancingCustomerFields.name]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.name2]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.address]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.address2]: Yup.string(),
    [FinancingCustomerFields.zip]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.city]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.country]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.phone]: Yup.string(),
    [FinancingCustomerFields.phoneMobile]: Yup.string(),
    [FinancingCustomerFields.birthDate]: Yup.string().nullable(),
    [FinancingCustomerFields.language]: Yup.string(),
    [FinancingCustomerFields.email]: Yup.string()
      .email('FORM__EMAIL')
      .required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.typeOfUse]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCustomerFields.inPersonType]: Yup.string().when(
      FinancingCustomerFields.typeOfUse,
      {
        is: (typeOfUse: string) => typeOfUse !== 'PRIVATE',
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
  });
};
