import styled from 'styled-components';
import { Button } from 'components/button';

export const ScButton = styled(Button)`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.625em;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
`;

export const ScLabel = styled.span<{ withIcon: boolean }>`
  display: inline-block;
  margin-left: ${({ withIcon }) => (withIcon ? '0.5em' : '0')};
`;
