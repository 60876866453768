import {
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';
import { Model, ModelFields } from '@hypercharge/xdms-client/lib/types.export';

type GetSelectedTableItemsParams = {
  model: Model | null;
  tables?: string | string[];
  idFilter?: string;
};

// "all" means the ones we need to reference
// this might be not all of the possible so-called records
const ALL_TABLES: string[] = [
  ModelFields.accessories,
  ModelFields.options,
  ModelFields.termsAndConditions,
];

/**
 * Gets selected table items
 * @param params {GetSelectedTableItemsParams}
 * @param params.tables {string[]} - list of items to select upon
 * @param params.model {Model | null} - model containing records
 * @param params.idFilter {string} - ID for records to map upon
 * @returns {Object.<string, string>} list of selected table items ids
 */
export const getSelectedTableItems = (
  params: GetSelectedTableItemsParams,
): Record<string, string> => {
  const { idFilter = Model_CommonItemFields.ID, tables, model } = params;
  if (!model) return {};
  const tablesList: string[] = [];

  if (tables) {
    if (typeof tables === 'string') tablesList.push(tables);
    if (Array.isArray(tables)) tablesList.push(...tables);
  } else {
    tablesList.push(...ALL_TABLES);
  }

  return tablesList.reduce<Record<string, string>>((result, modelTableName: string) => {
    const tableItems: Model_CommonItem[] = get(model, modelTableName, []);
    const selectedTableItems = tableItems
      .filter(tableItem => tableItem[Model_CommonItemFields.selected])
      .reduce<Record<string, string>>((localResult, tableItem) => {
        const recordId = get(tableItem, idFilter);
        localResult[recordId] = recordId;
        return localResult;
      }, {});
    result = { ...result, ...selectedTableItems };
    return result;
  }, {});
};
