import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  Structure,
  StructureFields,
  StructureLabel,
  StructureLabelFields,
} from 'types/vendor';
import { STRUCTURE_LABEL } from 'common/constants';
import { get } from 'utils';

const NAME = 'structure';

interface State {
  readonly structureStatus: Status;
  readonly structure: Structure | null;
  /** Needed for streaming */
  readonly translatedModelBuildSteps: StructureLabel[];
}

const initialState: State = {
  structureStatus: Status.Idle,
  structure: null,
  translatedModelBuildSteps: [],
};

const actions = {
  setStructureStatus: createAction<State['structureStatus']>(
    getActionName(NAME, 'SET_STRUCTURE_STATUS'),
  ),
  setStructure: createAction<State['structure']>(getActionName(NAME, 'SET_STRUCTURE')),
  setTranslatedModelBuildSteps: createAction<State['translatedModelBuildSteps']>(
    getActionName(NAME, 'SET_MODEL_TRANSLATED_SUB_STEPS'),
  ),
  reset: createAction(getActionName(NAME, 'RESET')),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setStructureStatus, (state, action) => ({
    ...state,
    structureStatus: action.payload,
  }));
  builder.addCase(actions.setStructure, (state, action) => ({
    ...state,
    structure: action.payload,
  }));
  builder.addCase(actions.setTranslatedModelBuildSteps, (state, action) => ({
    ...state,
    translatedModelBuildSteps: action.payload,
  }));
  builder.addCase(actions.reset, () => initialState);
});

const selectors = {
  getAll: createSelector(
    ({ structure }: ApplicationState) => structure,
    (state: State) => state,
  ),
  getStructureStatus: createSelector(
    ({ structure }: ApplicationState) => structure,
    (state: State) => state.structureStatus,
  ),
  getStructure: createSelector(
    ({ structure }: ApplicationState) => structure,
    (state: State) => state.structure,
  ),
  getTranslatedModelBuildSteps: createSelector(
    ({ structure }: ApplicationState) => structure,
    (state: State) => state.translatedModelBuildSteps,
  ),
  getModelBuildSteps: createSelector(
    ({ structure }: ApplicationState) => structure,
    (state: State) => {
      if (!state.structure) return [];
      return state.structure?.[StructureFields.labels].filter(label =>
        get(label, StructureLabelFields.code, '').includes(STRUCTURE_LABEL),
      );
    },
  ),
};

export type { State as StructureState };
export {
  actions as structureActions,
  reducer as structureReducer,
  selectors as structureSelectors,
};
