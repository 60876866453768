import {
  Message,
  Model,
  Model_GetByParams_Input,
  ModelCreatableAttributesObject,
  ModelFields,
  ModelUpdatableAttributesObject,
} from '@hypercharge/xdms-client/lib/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import React, { useCallback } from 'react';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { RequestResponseDetails } from '../../types/common';
import { getModelResponseStatus } from './utils/getModelResponseStatus';
import { useDispatch, useSelector } from 'react-redux';
import { configurationSelectors } from 'store/configuration';
import { modelActions, sharedSelectors } from 'store';

const REQUIRED_FIELDS: (keyof Model_GetByParams_Input)[] = [
  'configurationNumber',
  'catalogCode',
  'modelNumber',
  'versionNumber',
];

type ContextValue = {
  getModel(model?: Partial<Model_GetByParams_Input>): Promise<Model | undefined>;
  updateModel(
    oldModel: Model,
    newModel: ModelUpdatableAttributesObject,
  ): Promise<RequestResponseDetails<Model | null>>;
  addModelProperties(
    oldModel: Model,
    newModel: ModelCreatableAttributesObject,
  ): Promise<RequestResponseDetails<Model | null>>;
  unsetModel(): void;
  modifyModelProperties(
    oldModel: Model,
    objectToUpdate: ModelUpdatableAttributesObject,
    objectToCreate: ModelCreatableAttributesObject,
  ): Promise<RequestResponseDetails<Model | null>>;
};

export const ModelContext = React.createContext<ContextValue | undefined>(undefined);

const useModelApi = (): ContextValue => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const { modelCatalogCode, modelNumber, modelVersionNumber, configurationNumber } =
    useSelector(configurationSelectors.getConfigurationCommonVariables);

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const dispatch = useDispatch();

  const getDefaultModelParams = useCallback(
    (
      params: Partial<Model_GetByParams_Input> = {},
    ): Model_GetByParams_Input | undefined => {
      const result: Model_GetByParams_Input = {
        configurationNumber: configurationNumber ?? '',
        catalogCode: modelCatalogCode ?? '',
        modelNumber: modelNumber ?? '',
        versionNumber: modelVersionNumber ?? '',
        withVAT: shouldShowPricesWithVAT,
        ...params,
      };

      if (!REQUIRED_FIELDS.every(field => result[field])) {
        return;
      }

      return result;
    },
    [
      configurationNumber,
      modelCatalogCode,
      modelNumber,
      modelVersionNumber,
      shouldShowPricesWithVAT,
    ],
  );

  const getModel = useCallback<ContextValue['getModel']>(
    async (params = {}) => {
      let result: Model | undefined;
      try {
        const modelParams = getDefaultModelParams(params);

        if (!modelParams) return;

        dispatch(modelActions.setStatus(Status.Loading));

        const model = await xdmsClient.model.getByParams(modelParams);

        dispatch(modelActions.setModel(model));
        dispatch(modelActions.setStatus(Status.Success));

        result = model;
      } catch (e) {
        notification.requestError(e);
        dispatch(modelActions.setStatus(Status.Error));
      }

      return result;
    },
    [xdmsClient, getDefaultModelParams, dispatch],
  );

  const updateModel = useCallback<ContextValue['updateModel']>(
    async (oldModel, updatableParams) => {
      let result: RequestResponseDetails<Model | null> = { status: Status.Idle };
      dispatch(modelActions.setStatus(Status.Loading));

      try {
        const updatedModel = await xdmsClient.model.updateModelProperties(
          oldModel,
          updatableParams,
        );
        result = {
          status: getModelResponseStatus(updatedModel),
          messageHandled: false,
          response: updatedModel,
        };
        dispatch(modelActions.setStatus(Status.Success));
        dispatch(modelActions.setModel(updatedModel));

        const messages: Message[] | undefined = updatedModel[ModelFields.message];
        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(modelActions.setStatus(Status.Error));
      }

      return result;
    },
    [xdmsClient, dispatch],
  );

  const addModelProperties = useCallback<ContextValue['addModelProperties']>(
    async (oldModel, newModel) => {
      let result: RequestResponseDetails<Model | null> = { status: Status.Idle };
      dispatch(modelActions.setStatus(Status.Loading));

      try {
        const updatedModel = await xdmsClient.model.addModelProperties(
          oldModel,
          newModel,
        );
        result = {
          status: getModelResponseStatus(updatedModel),
          messageHandled: false,
          response: updatedModel,
        };
        dispatch(modelActions.setStatus(Status.Success));
        dispatch(modelActions.setModel(updatedModel));

        const messages: Message[] | undefined = updatedModel[ModelFields.message];
        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(modelActions.setStatus(Status.Error));
      }

      return result;
    },
    [xdmsClient.model, dispatch],
  );

  const modifyModelProperties = useCallback<ContextValue['modifyModelProperties']>(
    async (oldModel, objectToUpdate, objectToCreate) => {
      let result: RequestResponseDetails<Model | null> = { status: Status.Idle };
      dispatch(modelActions.setStatus(Status.Loading));

      try {
        const updatedModel = await xdmsClient.model.modifyModelProperties(
          oldModel,
          objectToUpdate,
          objectToCreate,
        );
        result = {
          status: getModelResponseStatus(updatedModel),
          messageHandled: false,
          response: updatedModel,
        };

        dispatch(modelActions.setStatus(Status.Success));
        dispatch(modelActions.setModel(updatedModel));

        const messages: Message[] | undefined = updatedModel[ModelFields.message];
        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(modelActions.setStatus(Status.Error));
      }

      return result;
    },
    [xdmsClient.model, dispatch],
  );

  const unsetModel = useCallback(() => {
    dispatch(modelActions.reset());
  }, [dispatch]);

  return {
    getModel,
    updateModel,
    addModelProperties,
    unsetModel,
    modifyModelProperties,
  };
};

export { useModelApi };
