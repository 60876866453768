import styled from 'styled-components';
import { Button } from 'components/button';

export const ScFiltersListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
`;

export const ScClearFiltersButton = styled(Button)`
  height: 100%;
`;
