import React, { FC, useMemo, useState } from 'react';
import { LanguageFields } from '@hypercharge/xdms-client/lib/types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Modal, ModalHeader } from 'components/modal';
import { Button } from 'components/button/Button';
import { useLanguage } from 'context/language/LanguageProvider';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { ScModalContent, ScSelect, ScInputNumber } from './ConfigurationCopyModal.styles';
import { isNil } from 'utils';
import { Language } from 'types/vendor';

interface Props {
  onSubmit: (values: { language: string; quantity: number }) => void;
  defaultLanguageCode?: string;
  defaultQuantity?: number;
  onClose: () => void;
}

const ConfigurationCopyModal: FC<Props> = ({
  defaultLanguageCode,
  defaultQuantity,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const { languagesFiltered } = useLanguage();

  const [language, setLanguage] = useState<string | undefined>(defaultLanguageCode);
  const [quantity, setQuantity] = useState<number>(defaultQuantity ?? 1);

  const languageOptions = useMemo(() => {
    return mapToSelectOptions<Language>(languagesFiltered, {
      label: LanguageFields.language,
      value: LanguageFields.languageCode,
    });
  }, [languagesFiltered]);

  return (
    <Modal
      data-testid="configuration-copy-modal"
      variant="sm"
      visible={true}
      onCancel={onClose}
      center
    >
      <ModalHeader $textAlign="center">
        {t('CONFIGURATION_COPY_MODAL__TITLE')}
      </ModalHeader>

      <ScModalContent>
        <ScSelect
          options={languageOptions}
          value={language}
          onChange={event => setLanguage(event.target.value)}
          bordered
          fullwidth
          showSearch
          data-testid="configuration-copy-modal__select_language"
        />

        <ScInputNumber
          value={quantity}
          onChange={event => setQuantity(event.target.value)}
          min={1}
          data-testid="configuration-copy-modal__input_quantity"
        />

        <Row gutter={[15, 0]}>
          <Col md={12}>
            <Button
              data-testid="configuration-copy-modal__button_cancel"
              fullwidth
              onClick={onClose}
            >
              {t('FORM_CANCEL')}
            </Button>
          </Col>
          <Col md={12}>
            <Button
              variant="primary"
              fullwidth
              disabled={isNil(language)}
              onClick={() =>
                onSubmit({ language: language as string, quantity: quantity })
              }
              data-testid="configuration-copy-modal__button_submit"
            >
              {t('SAVE')}
            </Button>
          </Col>
        </Row>
      </ScModalContent>
    </Modal>
  );
};

export default ConfigurationCopyModal;
