import React, { FC } from 'react';
import useScript from 'hooks/useScript';
import { useEffect } from 'react';
import { ScProductsDrawerWrapper, ScTriggerIcon } from './ProductsDrawer.styles';
import { LOOPEND_INTEGRATION_SCRIPT } from 'common/constants';

const ProductsDrawer: FC = () => {
  useScript(LOOPEND_INTEGRATION_SCRIPT);

  useEffect(() => {
    const workspaceId = process.env.REACT_APP_LOOPEDIN_WORKSPACE_ID;

    if (!workspaceId) {
      throw new Error('Missing workspace ID for widget setup');
    }

    window.li_sidebar = {
      workspace_id: workspaceId,
    };
  }, []);

  return (
    <ScProductsDrawerWrapper>
      <ScTriggerIcon id="productstashSelector" />
    </ScProductsDrawerWrapper>
  );
};

export default ProductsDrawer;
