import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { typeDefs } from './type-defs';

const customerToPolicyApiSource = {
  volvo: 'volvo-be',
};

const POLICY_API_SOURCE_HEADER = 'x-policyapi-source';
// move it to env if it is constant
const API_CLIENT_NAME = 'apollographql-client-name';
const USER_AGENT = 'user-agent';

const authLink = setContext((_, prevContext) => {
  const { headers } = prevContext;
  let [customer] = window.location.hostname.split('.');

  if (process.env.NODE_ENV === 'development' || !customer) {
    customer = 'volvo';
  }

  return {
    headers: {
      ...headers,
      [POLICY_API_SOURCE_HEADER]: customerToPolicyApiSource[customer],
      [API_CLIENT_NAME]: 'studio',
      [USER_AGENT]: 'volvo-bot',
    },
  };
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
  typeDefs,
});
