import Icon from '@ant-design/icons';
import { Dropdown } from 'components/dropdown';
import styled, { css } from 'styled-components';
import { StreamingTerminalTitle } from '../StreamingTerminalTitle';

export const ScStreamingModalHeader = styled.div`
  padding: 1.375em 0;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScStreamingModalBody = styled.div`
  padding: 1.25em 1.5em 2.75em 1.5em;
`;

export const ScTerminalItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25em;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const commonTerminalListStyles = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScSelectedTerminalList = styled.ul`
  ${commonTerminalListStyles}
  height: 50px;
`;

export const ScAllTerminalsList = styled.ul`
  ${commonTerminalListStyles}
  height: 275px;
  overflow-y: scroll;
`;

export const ScBusyTerminalInfo = styled.span`
  display: inline-flex;
  flex-direction: column;
`;

export const ScBusyTerminalTitle = styled.span`
  font-size: 0.875em;
  font-weight: 400;
`;

export const ScBusyTerminalActionsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const ScBusyTerminalActionItem = styled.button<{ disabled: boolean }>`
  margin-left: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:first-of-type {
    margin-left: 0;
  }
`;

export const ScBusyTerminalActionIcon = styled(Icon)`
  height: 1.5em;

  && {
    cursor: inherit;
  }
`;

export const ScBusyTerminalActionTitle = styled.span`
  font-size: 0.625em;
  font-weight: 400;
  text-align: center;
`;

export const ScStreamingModalSearch = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 1.375em;
`;

export const ScStremaingSyncAction = styled.button`
  display: inline-block;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const ScStreamingSearchAutocompleteWrapper = styled.div`
  width: 19.25em;
  margin-left: 1.25em;
`;

export const ScStreamingGenerateCustomerIdAction = styled.button`
  display: inline-block;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 1.25em;
`;

export const ScTerminalSelectionWrapper = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ScStreamingCommonTerminalTitle = styled(StreamingTerminalTitle)`
  margin-left: 1.125em;
`;

export const ScTerminalTerminalChronology = styled.span`
  font-size: 0.875em;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.softPeach};
`;

export const ScConfirmationContainerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.625em;
`;

export const ScTerminalCheckboxWrapper = styled.span`
  display: inline-block;
  margin-right: 1.125em;
`;

export const ScDropdown = styled(Dropdown)`
  &.ant-dropdown-trigger {
    padding: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
