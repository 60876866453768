import * as Yup from 'yup';
import { FinancingCarInfo, FinancingCarInfoFields } from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<FinancingCarInfo>>({
    [FinancingCarInfoFields.brand]: Yup.string(),
    [FinancingCarInfoFields.modelName]: Yup.string(),
    [FinancingCarInfoFields.modelNumber]: Yup.string(),
    [FinancingCarInfoFields.fuelCode]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCarInfoFields.fuelCode2]: Yup.string(),
    [FinancingCarInfoFields.status]: Yup.string().required(t('FORM__REQUIRED')),
    [FinancingCarInfoFields.financialNumber]: Yup.string(),
    [FinancingCarInfoFields.firstRegistrationAt]: Yup.string().nullable(),
    [FinancingCarInfoFields.salePrice]: Yup.number(),
  });
};
