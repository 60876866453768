import Selector from 'components/form/Selector';
import React, { useMemo, useState } from 'react';
import { CustomerFields, Filter, FilterField } from 'types/vendor';
import useGetOptions from './useGetOptions';
import { FILTER_SIGNS } from 'common/constants';
import { useCustomer } from 'context/customer/CustomerProvider';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';

type Props = Omit<React.ComponentProps<typeof Selector>, 'options'>;

const CustomerSelector: React.FC<Props> = ({ onChange, ...props }) => {
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { loadCustomersList } = useCustomer();

  const filters = useMemo<Filter<CustomerFields>>(() => {
    const result: FilterField<CustomerFields>[] = [];

    if (searchValue) {
      if (isNaN(Number(searchValue))) {
        result.push({
          name: CustomerFields.name,
          sign: FILTER_SIGNS.INCLUDES,
          value: searchValue,
        });
      } else {
        result.push({
          name: CustomerFields.id,
          sign: FILTER_SIGNS.EQUAL,
          value: searchValue,
        });
      }
    }

    return result;
  }, [searchValue]);

  const { options: rawOptions, isLoading } = useGetOptions(loadCustomersList, filters);

  const options = mapToSelectOptions(rawOptions, {
    label: [CustomerFields.name, CustomerFields.id],
    value: CustomerFields.id,
    separator: ' - ',
  });

  return (
    <Selector
      {...props}
      onChange={(event, value) => {
        onChange?.(event, value);
        setSearchValue(null);
      }}
      options={options}
      loading={isLoading}
      showSearch
      onSearch={setSearchValue}
    />
  );
};

export default CustomerSelector;
