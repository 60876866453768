import React, { ComponentProps, FC } from 'react';
import { ScNavButtonsContainer } from './NavButtonsContainer.styles';

const NavButtonsContainer: FC<ComponentProps<typeof ScNavButtonsContainer>> = props => {
  const childrenQuantity = React.Children.toArray(props.children).length;

  return <ScNavButtonsContainer {...props} $childrenQuantity={childrenQuantity} />;
};

export default NavButtonsContainer;
