import styled from 'styled-components';
import { Divider } from 'antd';

export const ScDivider = styled(Divider)`
  margin-left: -1.625em;
  margin-right: -1.625em;
  margin-top: 2em;
  margin-bottom: 2em;

  width: calc(100% + 1.625em * 2);
  border-color: ${({ theme }) => theme.colors.lightSilver};
`;
