import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { DocumentTemplate } from 'types/vendor';

const NAME = 'documentTemplates';

interface State {
  readonly documentTemplatesStatus: Status;
  readonly documentTemplates: DocumentTemplate[];
}

const initialState: State = {
  documentTemplatesStatus: Status.Idle,
  documentTemplates: [],
};

const actions = {
  setDocumentTemplatesStatus: createAction<State['documentTemplatesStatus']>(
    getActionName(NAME, 'SET_CATEGORIES_LIST_STATUS'),
  ),
  setDocumentTemplates: createAction<State['documentTemplates']>(
    getActionName(NAME, 'SET_CATEGORY_TYPES_LIST_STATUS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setDocumentTemplatesStatus, (state, action) => ({
    ...state,
    documentTemplatesStatus: action.payload,
  }));
  builder.addCase(actions.setDocumentTemplates, (state, action) => ({
    ...state,
    documentTemplates: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ documentTemplates }: ApplicationState) => documentTemplates,
    (state: State) => state,
  ),
  getTemplatesListStatus: createSelector(
    ({ documentTemplates }: ApplicationState) => documentTemplates,
    (state: State) => state.documentTemplatesStatus,
  ),
  getTemplatesList: createSelector(
    ({ documentTemplates }: ApplicationState) => documentTemplates,
    (state: State) => state.documentTemplates,
  ),
};

export type { State as DocumentTemplatesState };
export {
  actions as documentTemplatesActions,
  reducer as documentTemplatesReducer,
  selectors as documentTemplatesSelectors,
};
