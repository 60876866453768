import React, { FC, useRef, PropsWithChildren } from 'react';
import { Tooltip } from 'antd';
import { ScTotalTextEllipsis } from './TotalTextEllipsis.styles';

const isShortened = (el: HTMLElement): boolean => {
  return el.offsetWidth < el.scrollWidth;
};

export const TotalTextEllipsis: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLElement | null>(null);
  const node = ref.current;
  const shouldBeTooltipped = node && isShortened(node);

  if (!shouldBeTooltipped)
    return <ScTotalTextEllipsis ref={ref}>{children}</ScTotalTextEllipsis>;

  return (
    <Tooltip placement="bottom" title={node?.textContent}>
      <ScTotalTextEllipsis ref={ref}>{children}</ScTotalTextEllipsis>
    </Tooltip>
  );
};
