import Selector from 'components/form/Selector';
import { useMachineApi } from 'context/machine/useMachineApi';
import React, { useMemo, useState } from 'react';
import { Filter, StockMachineFuelFields } from 'types/vendor';
import useGetOptions from './useGetOptions';
import { FILTER_SIGNS } from 'common/constants';

type Props = Omit<React.ComponentProps<typeof Selector>, 'options'>;

const FuelSelector: React.FC<Props> = ({ onChange, ...props }) => {
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { getFuelOptions } = useMachineApi();

  const filters = useMemo<Filter<StockMachineFuelFields>>(() => {
    const result: Filter<StockMachineFuelFields> = [];

    if (searchValue) {
      result.push({
        name: StockMachineFuelFields.name,
        sign: FILTER_SIGNS.INCLUDES,
        value: searchValue,
      });
    }

    return result;
  }, [searchValue]);

  const { options, isLoading } = useGetOptions(getFuelOptions, filters);

  return (
    <Selector
      {...props}
      onChange={(event, value) => {
        onChange?.(event, value);
        setSearchValue(null);
      }}
      options={options}
      loading={isLoading}
      showSearch
      onSearch={setSearchValue}
    />
  );
};

export default FuelSelector;
