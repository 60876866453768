import React, { FC, useMemo, useState } from 'react';
import { SearchContactModal } from './SearchContactModal';
import { CreateContactModal } from './CreateContactModal';
import { ContactFields, ContactItem, CustomerItem, Filter } from 'types/vendor';

interface Props {
  relatedCustomer?: CustomerItem | null;
  initialValues?: ContactItem[];
  onSelect(contact: ContactItem[]): void | Promise<void>;
  isOpen: boolean;
  setIsOpen(visible: boolean): void;
  defaultFilter?: Filter<ContactFields>;
  searchSelectMode?: 'single' | 'multiple';
  isAddEnabled: boolean;
}
export const SelectContactModal: FC<Props> = props => {
  const {
    relatedCustomer,
    initialValues,
    onSelect,
    defaultFilter = [],
    isOpen: isSearchVisible,
    setIsOpen: setIsSearchVisible,
    searchSelectMode,
    isAddEnabled,
  } = props;

  const [searchFilter, setSearchFilter] = useState<Filter<ContactFields>>([]);

  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);

  const filters = useMemo<Filter<ContactFields>>(() => {
    if (searchFilter.length) return searchFilter;
    if (defaultFilter.length) return defaultFilter;
    return [];
  }, [searchFilter, defaultFilter]);

  const onAddNew = useMemo(() => {
    return isAddEnabled && relatedCustomer ? () => setIsCreateVisible(true) : undefined;
  }, [isAddEnabled, relatedCustomer]);

  return (
    <>
      {isSearchVisible && (
        <SearchContactModal
          filters={filters}
          initialValues={initialValues}
          onClose={() => {
            setIsSearchVisible(false);
            setSearchFilter([]);
          }}
          onSubmit={onSelect}
          onAddNew={onAddNew}
          selectMode={searchSelectMode}
        />
      )}
      {isCreateVisible && relatedCustomer && (
        <CreateContactModal
          relatedCustomer={relatedCustomer}
          onClose={() => setIsCreateVisible(false)}
          onSubmit={record => {
            if (!record) return;

            setIsSearchVisible(false);
            setIsCreateVisible(false);
            onSelect([record]);
          }}
          onWarningClick={(name, value) => {
            setSearchFilter([{ name: name, value: value }]);
            setIsCreateVisible(false);
          }}
        />
      )}
    </>
  );
};
