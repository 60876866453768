import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authActions,
  authSelectors,
  hostSettingsActions,
  hostSettingsSelectors,
  sharedActions,
  sharedSelectors,
} from 'store';
import { Status } from 'utils/types';
import { loadFromLocalStorage } from 'utils/storage';
import {
  API_XDMS_LOGIN_URL,
  CLIENTS_URL,
  STORAGE_KEYS,
  URL_QUERY_PARAMS,
} from 'common/constants';
import { SetQueryParamArg, useQuery } from '../router/UrlQueryProvider';
import { useTranslation } from 'react-i18next';
import { getBaseRemoteUrlV2 } from '@hypercharge/xdms-client';
import { useHistory, useLocation } from 'react-router-dom';
import { UrlTransform } from 'utils/urlTransform';
import { useHostSettings } from 'context/hostSettings/useHostSettings';
import { useUnauthenticatedLoadingStatusesUpdatEffect } from 'providers/useUnauthenticatedLoadingStatusesUpdatEffect';

const SessionContext = React.createContext(undefined);

/** Context for session restore and some specific session actions */
const UnauthenticatedSessionProvider: FC<PropsWithChildren> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const hostSettings = useSelector(hostSettingsSelectors.getAll);
  const baseUrl = useSelector(authSelectors.getBaseUrl);
  const host = useSelector(hostSettingsSelectors.getHost);
  const isHostFromQuerySettled = useSelector(
    hostSettingsSelectors.getIsHostFromQuerySettled,
  );
  const initialLocation = useSelector(sharedSelectors.getInitialLocation);

  const { initialQueryValues, setQueryParams } = useQuery();
  const { getHostSettings } = useHostSettings();

  useUnauthenticatedLoadingStatusesUpdatEffect();

  const confirmHostChange = useCallback(
    (oldHost: string, newHost: string): boolean => {
      return window.confirm(
        t('HOST_CHANGE_WARNING', { oldHost: oldHost, newHost: newHost }),
      );
    },
    [t],
  );

  /** Refetch hostSettings */
  useEffect(() => {
    if (!baseUrl || !isHostFromQuerySettled) return;

    dispatch(hostSettingsActions.setStatus(Status.Loading));

    getHostSettings(baseUrl, hostSettings.customerId).then(
      ({ response: hostSettingsExternal }) => {
        if (hostSettingsExternal) {
          dispatch(hostSettingsActions.setFromExternalSettings(hostSettingsExternal));
        }
        dispatch(hostSettingsActions.setStatus(Status.Success));
      },
    );
  }, [
    baseUrl,
    hostSettings.customerId,
    getHostSettings,
    dispatch,
    isHostFromQuerySettled,
  ]);

  /** Update baseUrl depending on host */
  useEffect(() => {
    if (host) {
      dispatch(authActions.setBaseUrlStatus(Status.Loading));
      getBaseRemoteUrlV2({
        tenantUrlProviderUrl: API_XDMS_LOGIN_URL,
        host: host,
      })
        .then(url => {
          dispatch(authActions.setBaseUrl(url));
          dispatch(authActions.setBaseUrlStatus(Status.Success));
        })
        .catch(() => {
          dispatch(authActions.setBaseUrlStatus(Status.Error));
        });
    }
  }, [dispatch, host]);

  /** Handle change host from query */
  useEffect(() => {
    dispatch(hostSettingsActions.setIsHostFromQuerySettled(true));

    if (isHostFromQuerySettled || !initialQueryValues.host) return;

    const storedHost = loadFromLocalStorage<string>(STORAGE_KEYS.host);

    if (
      storedHost &&
      storedHost !== initialQueryValues.host &&
      !confirmHostChange(storedHost as string, initialQueryValues.host)
    ) {
      // clean configurationNumber and other fields from query as they are related to refused host
      history.push(CLIENTS_URL);
      return;
    }

    dispatch(hostSettingsActions.setHost(initialQueryValues.host));
  }, [
    confirmHostChange,
    dispatch,
    history,
    initialQueryValues.host,
    isHostFromQuerySettled,
  ]);

  useEffect(() => {
    const paramsToUpdate: SetQueryParamArg[] = [
      { key: URL_QUERY_PARAMS.host, value: host },
    ];

    if (initialQueryValues.hostLock) {
      paramsToUpdate.push({
        key: URL_QUERY_PARAMS.hostLock,
        value: String(initialQueryValues.hostLock),
      });
    }

    setQueryParams(...paramsToUpdate);
  }, [host, setQueryParams, initialQueryValues.hostLock, location.pathname]);

  /** Reset initialLocation as after host change it is invalid */
  useEffect(() => {
    if (!initialLocation) return;
    const initialLocationHost = UrlTransform.parseQuery(initialLocation.search)[
      URL_QUERY_PARAMS.host
    ];

    if (initialLocationHost && host && initialLocationHost !== host) {
      dispatch(sharedActions.setInitialLocation(undefined));
    }
  }, [dispatch, host, initialLocation]);

  return <SessionContext.Provider value={undefined} {...props} />;
};

export { UnauthenticatedSessionProvider };
