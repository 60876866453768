import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Loader = styled.div`
  margin: auto auto;
  white-space: nowrap;
  height: 2.5em;
  text-align: center;
  font-size: 0.625em;

  > div {
    background-color: ${props => props.theme.mainColor};
    height: 100%;
    width: 0.375em;
    margin: 0 3px 0 0;
    display: inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    animation-delay: -1.1s;
  }

  .rect3 {
    animation-delay: -1s;
  }

  .rect4 {
    animation-delay: -0.9s;
  }

  .rect5 {
    animation-delay: -0.8s;
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
    }
  }
`;

export default ({ className }: { className?: string }) => (
  <Loader className={classNames('spinner', className)}>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </Loader>
);
