import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { Status } from 'utils/types';
import {
  Model,
  ModelRule,
  ModelFields,
  ModelMachineConfigurationFields,
} from 'types/vendor';
import {
  getIsConfigurationComplete,
  getIsConfigurationCreatedFromStock,
  getIsModelConfigured,
  componentChangeToRule,
} from './utils';

const NAME = 'model';

interface State {
  readonly status: Status;
  readonly model: Model | null;
  readonly modelRules: ModelRule[];
  readonly isConfigurationComplete: boolean;
  readonly isConfigurationCreatedFromStock: boolean;
  readonly isModelConfigured: boolean;
}

const initialState: State = {
  status: Status.Idle,
  model: null,
  modelRules: [],
  /** Whether configuration is published */
  isConfigurationComplete: false,
  /** Whether model is created from configuration from stock */
  isConfigurationCreatedFromStock: false,
  /** Whether configuration's model is assembled on Model step */
  isModelConfigured: false,
};

const actions = {
  setStatus: createAction<State['status']>(getActionName(NAME, 'SET_STATUS')),
  setModel: createAction<Exclude<State['model'], null>>(getActionName(NAME, 'SET_MODEL')),
  reset: createAction(getActionName(NAME, 'RESET')),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setStatus, (state, action) => ({
    ...state,
    status: action.payload,
  }));

  builder.addCase(actions.setModel, (state, action) => {
    const model = action.payload;

    const rules = model[ModelFields.rules];
    const componentsChange = model[ModelFields.componentsChange];

    let localRules: ModelRule[] = [];

    if (componentsChange?.length) {
      localRules = componentsChange.map(componentChangeToRule);
    } else if (rules?.length) {
      localRules = rules;
    }

    return {
      ...state,
      model: model,
      modelRules: localRules,
      isConfigurationComplete: getIsConfigurationComplete(model),
      isConfigurationCreatedFromStock: getIsConfigurationCreatedFromStock(model),
      isModelConfigured: getIsModelConfigured(model),
    };
  });

  builder.addCase(actions.reset, () => initialState);
});

const selectors = {
  getAll: createSelector(
    ({ model }: ApplicationState) => model,
    state => state,
  ),
  getStatus: createSelector(
    ({ model }: ApplicationState) => model,
    state => state.status,
  ),
  getModel: createSelector(
    ({ model }: ApplicationState) => model,
    state => state.model,
  ),
  getRules: createSelector(
    ({ model }: ApplicationState) => model,
    state => state.modelRules,
  ),
  getToken: createSelector(
    ({ model }: ApplicationState) => model,
    state =>
      state.model?.[ModelFields.machineConfiguration]?.[0]?.[
        ModelMachineConfigurationFields.importToken
      ] ?? null,
  ),
  getVariables: createSelector(
    ({ model }: ApplicationState) => model,
    state => ({
      isConfigurationComplete: state.isConfigurationComplete,
      isConfigurationCreatedFromStock: state.isConfigurationCreatedFromStock,
      isModelConfigured: state.isModelConfigured,
    }),
  ),
};

export type { State as ModelState };
export { actions as modelActions, reducer as modelReducer, selectors as modelSelectors };
