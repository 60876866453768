import { Button as Origin } from './Button';
import { ButtonCollapse } from './ButtonCollapse';
import { ButtonDropdownTrigger } from './ButtonDropdownTrigger';
import { ButtonAdd } from './ButtonAdd';

export const Button = Object.assign(Origin, {
  Collapse: ButtonCollapse,
  DropdownTrigger: ButtonDropdownTrigger,
  ButtonAdd: ButtonAdd,
});
