import {
  AccessoryItemFields,
  ModelCommercialCommentFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonItem,
  Model_CommonItemFields,
  OptionItemFields,
  PackageItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Row } from 'antd';
import {
  ACCESSORIES_PAGE_URL,
  DEFAULT_SYSTEM_LANGUAGE_CODE,
  OPTIONS_PAGE_URL,
  PACKAGES_PAGE_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
} from 'common/constants';
import { Modal } from 'components/modal';
import Tabs from 'components/Tabs';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { get } from 'utils';
import { CommentsModalTabs } from 'utils/constants';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { ParsedQuery } from 'utils/urlTransform';
import { ScModalFooterButton } from 'components/modalLike.styles';
import {
  ScCommentsModalFooter,
  ScTabPaneContent,
  ScTabs,
  ScTextArea,
} from './CommentsModal.styles';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';

const { TabPane } = Tabs;

export type CommentsModalCallParams = Pick<
  CommentsModalProps,
  'defaultActiveKey' | 'option'
>;

export interface CommentsModalProps {
  onClose(): void;
  defaultActiveKey?: CommentsModalTabs;
  option: Model_CommonItem;
  stepId: keyof typeof STEP_SETTINGS;
}

const TEXT_AREAN_MIN_ROWS = 3;
const TEXT_AREAN__MAX_ROWS = 6;

// @todo: move to dynamic pages settings
const STEP_SETTINGS = {
  [ACCESSORIES_PAGE_URL]: {
    INTERNAL_COMMENT: AccessoryItemFields.optionName,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
  },
  [OPTIONS_PAGE_URL]: {
    INTERNAL_COMMENT: OptionItemFields.optionName,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
  },
  [TERMS_AND_CONDITIONS_PAGE_URL]: {
    INTERNAL_COMMENT: OptionItemFields.optionName,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.termsAndConditions,
  },
  [PACKAGES_PAGE_URL]: {
    INTERNAL_COMMENT: PackageItemFields.optionName,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
  },
};

const TAB_SETTINGS = {
  [CommentsModalTabs.commercial]: {
    MAIN_COMMENT: '',
    ADDITIONAL_COMMENT: '',
    EDITABLE: false,
  },
  [CommentsModalTabs.external]: {
    MAIN_COMMENT: Model_CommonItemFields.sellingInformation,
    ADDITIONAL_COMMENT: Model_CommonItemFields.externalCommentDescription,
    EDITABLE: true,
  },
  [CommentsModalTabs.internal]: {
    MAIN_COMMENT: Model_CommonItemFields.internalCommentTitle,
    ADDITIONAL_COMMENT: Model_CommonItemFields.internalCommentDescription,
    EDITABLE: false,
  },
};

export const CommentsModal: FC<CommentsModalProps> = ({
  onClose,
  defaultActiveKey,
  option,
  stepId,
}) => {
  const { system: languageSystemCode } = useCurrentLanguageCode();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();
  const { t } = useTranslation();

  const { isFeatureEnabled } = useFeature();

  const { isConfigurationComplete, status, model } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const isAddCommentFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowAddComment',
    source: FeatureSource.DYNAMIC,
  });

  const [activeTabKey, setActiveTabKey] = useState<CommentsModalTabs>(
    defaultActiveKey || CommentsModalTabs.internal,
  );
  const [comments, setComments] = useState({
    internal: '',
    internalAdditional: '',
    external: '',
    externalAdditional: '',
    commercial: '',
    commercialAdditional: '',
  });

  const { ADDITIONAL_COMMENT, MAIN_COMMENT, EDITABLE } = useMemo(
    () => TAB_SETTINGS[activeTabKey],
    [activeTabKey],
  );
  const { INTERNAL_COMMENT, MODEL_UPDATE_ATTRIBUTES } = STEP_SETTINGS[stepId];

  const mainCommentByStepId = useMemo((): string => {
    if (activeTabKey === CommentsModalTabs.internal) {
      return INTERNAL_COMMENT;
    }
    return MAIN_COMMENT;
  }, [activeTabKey, MAIN_COMMENT, INTERNAL_COMMENT]);

  const getActiveComments = useCallback(
    (editable = false) => {
      const tabs = [
        CommentsModalTabs.internal,
        CommentsModalTabs.external,
        CommentsModalTabs.commercial,
      ];
      const notEditableComments = [
        comments.internal,
        comments.external,
        comments.commercial,
        comments.commercialAdditional,
      ];
      const editableComments = [comments.internalAdditional, comments.externalAdditional];

      if (editable) {
        return editableComments[tabs.indexOf(activeTabKey)];
      }

      return notEditableComments[tabs.indexOf(activeTabKey)];
    },
    [comments, activeTabKey],
  );

  const addComment = useCallback(async (): Promise<void> => {
    if (!option) return;
    const { sellingInformation, externalCommentDescription, internalCommentDescription } =
      AccessoryItemFields;

    const requestFields: ParsedQuery = {
      [sellingInformation]: comments.external,
      [externalCommentDescription]: comments.externalAdditional,
      [internalCommentDescription]: comments.internalAdditional,
    };

    if (!model) return;

    const { messageHandled, status } = await updateModel(model, {
      [MODEL_UPDATE_ATTRIBUTES]: { ...option, ...requestFields },
    });

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }

    if (!messageHandled) {
      notification.openByStatus(status, {
        [Status.Success]: t('NOTIFICATION_SEND_COMMENT_SUCCESS'),
        [Status.Error]: t('GLOBAL_ERROR_TEXT'),
      });
    }

    onClose();
  }, [
    option,
    comments,
    model,
    updateModel,
    MODEL_UPDATE_ATTRIBUTES,
    sendMessage,
    modelToken,
    onClose,
    t,
  ]);

  const handleEditorChange = (content: string, key: string): void => {
    setComments({ ...comments, [key]: content });
  };

  const handleChangeActiveTab = useCallback((key: CommentsModalTabs): void => {
    setActiveTabKey(key);
  }, []);

  const submitBtnText = useMemo<string>(() => {
    return !!get(option, ADDITIONAL_COMMENT, false) ||
      !!get(option, mainCommentByStepId, false)
      ? t('EDIT_COMMENT')
      : t('SAVE');
  }, [option, ADDITIONAL_COMMENT, mainCommentByStepId, t]);

  const getIsSubmitButtonDisabled = useCallback(() => {
    if (activeTabKey === CommentsModalTabs.commercial) {
      return true;
    }
    const isFirstCommentChanged =
      get(option, mainCommentByStepId, '') === getActiveComments();
    const isSecondCommentChanged =
      get(option, ADDITIONAL_COMMENT, '') === getActiveComments(true);

    if (!ADDITIONAL_COMMENT) {
      return isFirstCommentChanged;
    }
    return isFirstCommentChanged && isSecondCommentChanged;
  }, [activeTabKey, option, ADDITIONAL_COMMENT, mainCommentByStepId, getActiveComments]);

  const getCommercialComments = useCallback(() => {
    let commercial = '';
    let commercialAdditional = '';
    const { sysLink, commentTitle, commentDescription, languageCode } =
      ModelCommercialCommentFields;

    if (get(option, sysLink)) {
      const remarkList = get(model, 'ttRemark', []);
      const currentOptionRemarkList = remarkList.filter(
        remark => get(remark, sysLink) === get(option, sysLink),
      );
      let list = currentOptionRemarkList.filter(
        remark => get(remark, languageCode) === languageSystemCode,
      );

      if (currentOptionRemarkList.length && !list.length) {
        list = currentOptionRemarkList.filter(
          remark => get(remark, languageCode) === DEFAULT_SYSTEM_LANGUAGE_CODE,
        );
      }

      const commercialTitle = list
        .map(comment => get(comment, commentTitle))
        .filter(comment => !!comment);
      const commercialDescription = list
        .map(comment => get(comment, commentDescription))
        .filter(comment => !!comment);

      commercial = commercialTitle.join('\n');
      commercialAdditional = commercialDescription.join('\n');
    }

    return { commercial, commercialAdditional };
  }, [model, option, languageSystemCode]);

  useEffect(() => {
    if (!option) return;
    const { internalCommentDescription, externalCommentDescription, sellingInformation } =
      AccessoryItemFields;
    const {
      [INTERNAL_COMMENT as AccessoryItemFields.optionName]: internal,
      [internalCommentDescription]: internalAdditional,
      [externalCommentDescription]: externalAdditional,
      [sellingInformation]: external,
    } = option;

    const { commercial, commercialAdditional } = getCommercialComments();

    setComments({
      internal,
      internalAdditional,
      external,
      externalAdditional,
      commercial,
      commercialAdditional,
    });
  }, [option, model, getCommercialComments, INTERNAL_COMMENT]);

  useEffect(() => {
    if (defaultActiveKey) {
      setActiveTabKey(defaultActiveKey);
    }
  }, [defaultActiveKey]);

  return (
    <Modal variant="sm" visible={true} onCancel={onClose} center>
      <ScTabs
        defaultActiveKey={defaultActiveKey}
        activeKey={activeTabKey}
        onChange={tab => handleChangeActiveTab(tab as CommentsModalTabs)}
      >
        <TabPane tab={t('COMMERCIAL_TAB_PANE')} key={CommentsModalTabs.commercial}>
          <ScTabPaneContent>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <ScTextArea
                  value={comments.commercial}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  disabled={true}
                  data-testid="comments-modal-commercial-input"
                />
              </Col>
              <Col span={24}>
                <ScTextArea
                  value={comments.commercialAdditional}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  disabled={true}
                  data-testid="comments-modal-commercial-additional-input"
                />
              </Col>
            </Row>
          </ScTabPaneContent>
        </TabPane>
        <TabPane tab={t('INTERNAL_TAB_PANE')} key={CommentsModalTabs.internal}>
          <ScTabPaneContent>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <ScTextArea
                  value={comments.internal}
                  onChange={e => handleEditorChange(e.target.value, 'internal')}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  disabled={
                    !isAddCommentFeatureEnabled || !EDITABLE || isConfigurationComplete
                  }
                  data-testid="comments-modal-internal-input"
                />
              </Col>
              <Col span={24}>
                <ScTextArea
                  value={comments.internalAdditional}
                  onChange={e => handleEditorChange(e.target.value, 'internalAdditional')}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  data-testid="comments-modal-internal-additional-input"
                  disabled={!isAddCommentFeatureEnabled || isConfigurationComplete}
                />
              </Col>
            </Row>
          </ScTabPaneContent>
        </TabPane>
        <TabPane tab={t('EXTERNAL_TAB_PANE')} key={CommentsModalTabs.external}>
          <ScTabPaneContent>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <ScTextArea
                  value={comments.external}
                  onChange={e => handleEditorChange(e.target.value, 'external')}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  disabled={
                    !isAddCommentFeatureEnabled || !EDITABLE || isConfigurationComplete
                  }
                  data-testid="comments-modal-external-input"
                />
              </Col>
              <Col span={24}>
                <ScTextArea
                  value={comments.externalAdditional}
                  onChange={e => handleEditorChange(e.target.value, 'externalAdditional')}
                  autoSize={{
                    minRows: TEXT_AREAN_MIN_ROWS,
                    maxRows: TEXT_AREAN__MAX_ROWS,
                  }}
                  data-testid="comments-modal-external-additional-input"
                  disabled={!isAddCommentFeatureEnabled || isConfigurationComplete}
                />
              </Col>
            </Row>
          </ScTabPaneContent>
        </TabPane>
      </ScTabs>
      <ScCommentsModalFooter>
        <ScModalFooterButton
          key={'add-comment'}
          onClick={addComment}
          variant="primary"
          fullwidth
          disabled={status === Status.Loading || getIsSubmitButtonDisabled()}
          data-testid="comments-modal-submit-btn"
        >
          {submitBtnText}
        </ScModalFooterButton>
      </ScCommentsModalFooter>
    </Modal>
  );
};
