import { saveAs } from 'file-saver';

type SaveByUrlOptions = {
  charset: string;
  mimeType: string;
};

export const saveByUrl = async (
  url: string,
  fileName: string,
  options: SaveByUrlOptions,
): Promise<void> => {
  const { mimeType, charset } = options;
  const fileResponse = await fetch(url, {
    method: 'GET',
  });
  const arrayBuffer = await fileResponse.arrayBuffer();
  const blob = new Blob([arrayBuffer], { type: mimeType + ';' + charset });
  saveAs(blob, fileName);
};
