import { useCallback, useState } from 'react';

type Args = {
  data: Record<string, unknown>[];
  pageSize: number;
  current?: number;
  total: number;
  skip?: boolean;
};

type Result = {
  pageSize: number;
  defaultCurrent: number;
  current: number;
  from: number;
  to: number;
  total: number;
  onChange(page: number): void;
  onResize(size: number): void;
  reset(): void;
  dataToShow: Record<string, unknown>[];
};

export default function usePagination({
  data,
  pageSize: initialPageSize,
  current: initialCurrent = 1,
  total,
  skip = false,
}: Args): Result {
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [current, setCurrent] = useState<number>(initialCurrent);
  const [from, setFrom] = useState<number>(initialCurrent);

  const to = Math.min(skip ? initialCurrent : current * pageSize, total);

  const onChange = useCallback(
    (page: number) => {
      if (skip) return;
      setCurrent(page);
      setFrom((page - 1) * pageSize + 1);
    },
    [pageSize, skip],
  );

  const onResize = useCallback((size: number) => {
    setPageSize(size);
  }, []);

  const reset = useCallback(() => {
    setCurrent(initialCurrent);
    setFrom(initialCurrent);
  }, [initialCurrent]);

  const dataToShow = skip
    ? data
    : data.slice((current - 1) * pageSize, pageSize * current);

  return {
    pageSize,
    defaultCurrent: initialCurrent,
    current,
    from,
    to,
    total,
    onChange,
    onResize,
    reset,
    dataToShow,
  };
}
