import {
  createAction,
  createReducer,
  createSelector,
  PrepareAction,
} from '@reduxjs/toolkit';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { STORAGE_KEYS } from 'common/constants';
import { Status } from 'utils/types';

const NAME = 'auth';

interface State {
  readonly authStatus: Status;
  readonly authToken: string | null | undefined;
  readonly isAuthenticated: boolean;
  readonly username: string | null | undefined;
  readonly baseUrl: string | null | undefined;
  readonly baseUrlStatus: Status;
}

const initialAuthToken = localStorage.getItem(STORAGE_KEYS.authToken);

const initialState: State = {
  authStatus: Status.Idle,
  authToken: initialAuthToken,
  isAuthenticated: Boolean(initialAuthToken),
  username: localStorage.getItem(STORAGE_KEYS.username),
  baseUrl: localStorage.getItem(STORAGE_KEYS.baseUrl),
  baseUrlStatus: Status.Idle,
};

const actions = {
  setAuthStatus: createAction<State['authStatus']>(
    getActionName(NAME, 'SET_AUTH_STATUS'),
  ),
  clear: createAction<
    PrepareAction<
      | {
          keepUsername?: boolean;
          keepConfigurationNumber?: boolean;
        }
      | undefined
    >
  >(getActionName(NAME, 'CLEAR'), permissions => {
    if (!permissions?.keepUsername) {
      localStorage.removeItem(STORAGE_KEYS.username);
    }
    if (!permissions?.keepConfigurationNumber) {
      localStorage.removeItem(STORAGE_KEYS.configurationNumber);
    }

    localStorage.removeItem(STORAGE_KEYS.authToken);

    return { payload: undefined };
  }),
  setAll: createAction<
    PrepareAction<{
      authToken: State['authToken'];
      username: State['username'];
      baseUrl: State['baseUrl'];
    }>
  >(getActionName(NAME, 'SET_ALL'), value => {
    localStorage.setItem(STORAGE_KEYS.authToken, value.authToken);
    localStorage.setItem(STORAGE_KEYS.baseUrl, value.baseUrl);
    localStorage.setItem(STORAGE_KEYS.username, value.username);
    return { payload: value };
  }),
  setBaseUrl: createAction<PrepareAction<State['baseUrl']>>(
    getActionName(NAME, 'SET_BASE_URL'),
    value => {
      localStorage.setItem(STORAGE_KEYS.baseUrl, value);
      return { payload: value };
    },
  ),
  setBaseUrlStatus: createAction<State['baseUrlStatus']>(
    getActionName(NAME, 'SET_BASE_URL_STATUS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setAuthStatus, (state, action) => ({
    ...state,
    authStatus: action.payload,
  }));
  builder.addCase(actions.clear, state => ({
    ...state,
    authToken: undefined,
    isAuthenticated: false,
    username: undefined,
  }));
  builder.addCase(actions.setAll, (state, action) => ({
    ...state,
    authToken: action.payload.authToken,
    isAuthenticated: Boolean(action.payload.authToken),
    baseUrl: action.payload.baseUrl,
    username: action.payload.username,
  }));
  builder.addCase(actions.setBaseUrl, (state, action) => ({
    ...state,
    baseUrl: action.payload,
  }));
  builder.addCase(actions.setBaseUrlStatus, (state, action) => ({
    ...state,
    baseUrlStatus: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state,
  ),
  getAuthStatus: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.authStatus,
  ),
  getAuthToken: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.authToken,
  ),
  getIsAuthenticated: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.isAuthenticated,
  ),
  getUsername: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.username,
  ),
  getBaseUrl: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.baseUrl,
  ),
  getBaseUrlStatus: createSelector(
    ({ auth }: ApplicationState) => auth,
    state => state.baseUrlStatus,
  ),
};

export type { State as AuthState };
export { actions as authActions, reducer as authReducer, selectors as authSelectors };
