import { UploadOutlined } from '@ant-design/icons';
import {
  DocumentTypeCategoryListItem,
  DocumentTypeCategoryListItemFields,
  DocumentTypeCategoryTypeListItem,
  DocumentTypeCategoryTypeListItemFields,
  DocumentListItem_Status_Values,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Progress, Row } from 'antd';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { Button } from 'components/button';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DISABLED_DOCUMENT_TYPES, EXCLUDED_DOCUMENT_TYPES } from 'utils/constants';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { Status } from 'utils/types';
import { isNil } from 'utils';
import {
  DocumentCheckbox,
  DocumentCheckboxMultipleNamesWrapper,
  DocumentLabel,
  DocumentTypeSelectArrowIcon,
  DocumentUpload,
  ProgressWrapper,
  SubmitButton,
} from './DocumentForm.styles';
import Select from '../../form/Selector';
import { useSelector } from 'react-redux';
import { documentTypesSelectors } from '../../../store';
import DocumentStatusField from './DocumentStatusField';
import { getSchema } from './schema';
import { getIntitials as getInitials } from './initials';
import { DocumentFormValues } from './types';
import { useDocumentTypesApi } from 'context/document/useDocumentTypesApi';
import { UploadFilesProgressGroup } from 'context/document/useFileUpload';
import { DocumentItemDetails, DocumentRelatedEntityCode } from 'context/document/types';

interface Props {
  isCreate?: boolean;
  isLink?: boolean;
  isFile?: boolean;
  isSaveHidden?: boolean;
  details?: DocumentItemDetails;
  disabled?: boolean;
  documentUploadFileProgress?: UploadFilesProgressGroup;
  initialValues?: DocumentFormValues;
  onSubmit(
    values: DocumentFormValues,
    options?: { hasSeparateNameForFile: boolean },
  ): void;
  relatedEntityCode: DocumentRelatedEntityCode;
}

const DocumentForm: FC<Props> = ({
  isCreate,
  isLink,
  isFile,
  isSaveHidden,
  details,
  disabled: formDisabled,
  documentUploadFileProgress,
  initialValues,
  onSubmit,
  relatedEntityCode,
}) => {
  const { t } = useTranslation();
  const { getDocumentTypeCategories, getDocumentTypeCategoryTypes } =
    useDocumentTypesApi();
  const [hasSeparateNameForFile, setHasSeparateNameForFile] = useState<boolean>(false);

  const [selectedCategoryCode, setSelectedCategoryCode] = useState<string | undefined>();
  const [typeCategoriesList, setTypeCategoriesList] = useState<
    DocumentTypeCategoryListItem[]
  >([]);
  const disabledTypeCategoriesCodesList = useMemo(() => {
    return [
      ...DISABLED_DOCUMENT_TYPES,
      ...typeCategoriesList
        // if type isEditable = false - its system type, and user shall not be allowed to select it himself
        .filter(item => !item[DocumentTypeCategoryListItemFields.isEditable])
        .map(item => item[DocumentTypeCategoryListItemFields.code]),
    ];
  }, [typeCategoriesList]);

  const [selectedCategoryTypeCode, setSelectedCategoryTypeCode] = useState<
    string | undefined
  >();
  const [typeCategoryTypesList, setTypeCategoryTypesList] = useState<
    DocumentTypeCategoryTypeListItem[]
  >([]);
  const disabledTypeCategoriesTypesCodesList = useMemo(() => {
    return [
      ...DISABLED_DOCUMENT_TYPES,
      ...typeCategoryTypesList
        // if type isEditable = false - its system type, and user shall not be allowed to select it himself
        .filter(item => !item[DocumentTypeCategoryTypeListItemFields.isEditable])
        .map(item => item[DocumentTypeCategoryTypeListItemFields.code]),
    ];
  }, [typeCategoryTypesList]);

  const isTypeSelectionDisabled = useMemo<boolean>(() => {
    if (isNil(selectedCategoryTypeCode)) return false;
    return disabledTypeCategoriesTypesCodesList.includes(selectedCategoryTypeCode);
  }, [selectedCategoryTypeCode, disabledTypeCategoriesTypesCodesList]);

  const { documentTypeCategoryListStatus, documentTypeCategoryTypesListStatus } =
    useSelector(documentTypesSelectors.getAll);

  const disabled = useMemo<boolean>(
    () => Boolean(formDisabled || isSaveHidden),
    [formDisabled, isSaveHidden],
  );

  const isCategoriesLoading = useMemo(
    () => documentTypeCategoryListStatus === Status.Loading,
    [documentTypeCategoryListStatus],
  );
  const isTypesLoading = useMemo(
    () => documentTypeCategoryTypesListStatus === Status.Loading,
    [documentTypeCategoryTypesListStatus],
  );

  useEffect(() => {
    getDocumentTypeCategories({ relatedEntityCode, documentRecord: details }).then(
      ({ response }) => {
        setTypeCategoriesList(response ?? []);
      },
    );
  }, [relatedEntityCode, getDocumentTypeCategories, details]);

  useEffect(() => {
    if (isNil(selectedCategoryCode)) return;
    getDocumentTypeCategoryTypes({
      relatedEntityCode,
      documentRecord: details,
      categoryCode: selectedCategoryCode,
    }).then(({ response }) => {
      const result = response?.filter(
        documentType =>
          !EXCLUDED_DOCUMENT_TYPES.includes(
            documentType[DocumentTypeCategoryTypeListItemFields.code],
          ),
      );
      setTypeCategoryTypesList(result ?? []);
    });
  }, [relatedEntityCode, selectedCategoryCode, getDocumentTypeCategoryTypes, details]);

  useEffect(() => {
    setSelectedCategoryCode(initialValues?.typeCategory);
    setSelectedCategoryTypeCode(initialValues?.type);
  }, [initialValues]);

  const handleSubmit = useCallback(
    (values: DocumentFormValues) => {
      onSubmit(values, { hasSeparateNameForFile: hasSeparateNameForFile });
    },
    [onSubmit, hasSeparateNameForFile],
  );

  const handleSetHasSeparateNameForFile = useCallback(
    (
      newHasSeparateNameForFile: boolean,
      values: DocumentFormValues,
      setFieldValue: FormikHelpers<DocumentFormValues>['setFieldValue'],
    ) => {
      if (newHasSeparateNameForFile) {
        values.files?.forEach((documentFile, idx) => {
          setFieldValue(`names.${idx}`, documentFile.name);
        });
      } else {
        setFieldValue(`names`, ['']);
      }
      setHasSeparateNameForFile(newHasSeparateNameForFile);
    },
    [],
  );

  const handleCategoryChange = useCallback(
    async (
      value: string,
      setFieldValue: FormikHelpers<DocumentFormValues>['setFieldValue'],
    ) => {
      setFieldValue('typeCategory', value);
      setFieldValue('type', undefined);
      setFieldValue('status', DocumentListItem_Status_Values.empty);

      setSelectedCategoryCode(value);
    },
    [],
  );

  const handleTypeChange = useCallback(
    (value: string, setFieldValue) => {
      setFieldValue('type', value);
      const documentType = typeCategoryTypesList?.find(
        documentType =>
          documentType[DocumentTypeCategoryTypeListItemFields.code] === value,
      );

      if (!documentType) return;

      setSelectedCategoryTypeCode(value);

      const status = documentType[DocumentTypeCategoryTypeListItemFields.status];

      setFieldValue('status', status);
    },
    [typeCategoryTypesList],
  );

  const formSchema = useMemo(
    () => getSchema(Boolean(isLink), Boolean(isFile), Boolean(isCreate), t),
    [isLink, isFile, isCreate, t],
  );

  return (
    <Formik
      initialValues={initialValues ?? getInitials()}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      enableReinitialize
    >
      {({ values, handleSubmit, setFieldValue, touched, errors, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          {isFile && isCreate && (
            <DocumentUpload
              fileList={Object.values(values.files ?? [])}
              listType="text"
              name="files"
              // a plug to prevent antd component to upload file instantly
              // https://stackoverflow.com/a/51519603
              customRequest={({ onSuccess }) => onSuccess?.({}, new XMLHttpRequest())}
              showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon: true,
                removeIcon: <DeleteIcon />,
              }}
              onChange={value => {
                const documentFiles = value.fileList.filter(
                  fileItem => fileItem.status !== 'removed',
                );
                setFieldValue('files', documentFiles);
              }}
              onRemove={value => {
                if (!values.files) return;

                const files = [...values.files];
                const fileIdx = files.findIndex(({ uid }) => uid === value.uid);
                if (~fileIdx) delete files[fileIdx];
                setFieldValue('files', files);
              }}
              multiple
              itemRender={(originNode, file) => {
                const fileProgress = documentUploadFileProgress?.[file.uid];
                if (fileProgress) {
                  const status = fileProgress.status;
                  if (status === Status.Error) {
                    file.status = 'error';
                  }
                  if (status === Status.Success) {
                    file.status = 'success';
                  }
                }
                return (
                  <div>
                    {originNode}
                    <ProgressWrapper isVisible={Boolean(fileProgress?.progress)}>
                      <Progress
                        percent={fileProgress?.progress ?? 0}
                        size="small"
                        showInfo={false}
                        strokeColor="#1890FF"
                        trailColor="#E9DBDB"
                        strokeWidth={2}
                      />
                    </ProgressWrapper>
                  </div>
                );
              }}
            >
              {!values.files?.length && (
                <Button icon={<UploadOutlined />} fullwidth>
                  {t('DOCUMENT_UPLOAD_TEXT')}
                </Button>
              )}
            </DocumentUpload>
          )}
          {values.files && values.files.length >= 2 && (
            <DocumentCheckboxMultipleNamesWrapper>
              <DocumentCheckbox
                checked={hasSeparateNameForFile}
                onChange={e => {
                  handleSetHasSeparateNameForFile(
                    e.target.checked,
                    values,
                    setFieldValue,
                  );
                }}
                disabled={disabled}
              >
                <DocumentLabel>{t('DOCUMENT_MULTIPLE_NAMES')}</DocumentLabel>
              </DocumentCheckbox>
            </DocumentCheckboxMultipleNamesWrapper>
          )}
          {isLink && (
            <FormField
              component={Input}
              label={t('DOCUMENT_LINK')}
              name="url"
              placeholder="https://"
              withErrors={Boolean(touched.url && errors.url)}
              onBlur={handleBlur}
              bordered
              disabled={disabled}
            />
          )}

          {isFile && hasSeparateNameForFile && isCreate ? (
            values.files?.map((documentFile, index) => (
              <FormField
                key={documentFile.fileName}
                component={Input}
                label={`${t('DOCUMENT_NAME')} ${index + 1}`}
                name={`names.${index}`}
                withErrors={Boolean(touched.names?.[index] && errors.names?.[index])}
                bordered
                disabled={disabled}
                onBlur={handleBlur}
              />
            ))
          ) : (
            <FormField
              component={Input}
              label={t('DOCUMENT_NAME')}
              name="names.0"
              withErrors={Boolean(touched.names?.[0] && errors.names?.[0])}
              bordered
              disabled={disabled}
              onBlur={handleBlur}
            />
          )}

          <Row gutter={[10, 0]}>
            <Col span={12}>
              <FormField
                component={Select}
                label={t('DOCUMENT_TYPE_CATEGORY')}
                name="typeCategory"
                withErrors={Boolean(touched.typeCategory && errors.typeCategory)}
                onBlur={handleBlur}
                options={mapToSelectOptions<DocumentTypeCategoryListItem>(
                  typeCategoriesList,
                  {
                    label: DocumentTypeCategoryListItemFields.name,
                    value: DocumentTypeCategoryListItemFields.code,
                    disabledOptions: disabledTypeCategoriesCodesList,
                  },
                )}
                onChange={event => {
                  handleCategoryChange(event.target.value, setFieldValue);
                }}
                loading={isCategoriesLoading}
                bordered
                disabled={disabled || isTypeSelectionDisabled}
                data-testid="select-documentTypeCategory"
              />
            </Col>
            <Col span={12}>
              <FormField
                component={Select}
                label={t('DOCUMENT_TYPE')}
                name="type"
                suffixIcon={<DocumentTypeSelectArrowIcon />}
                withErrors={Boolean(errors.type)}
                onBlur={handleBlur}
                options={mapToSelectOptions<DocumentTypeCategoryTypeListItem>(
                  typeCategoryTypesList,
                  {
                    label: DocumentTypeCategoryTypeListItemFields.name,
                    value: DocumentTypeCategoryTypeListItemFields.code,
                    disabledOptions: disabledTypeCategoriesTypesCodesList,
                  },
                )}
                onChange={event => {
                  handleTypeChange(event.target.value, setFieldValue);
                }}
                loading={isTypesLoading}
                bordered
                disabled={disabled || isTypeSelectionDisabled}
                data-testid="select-documentType"
              />
            </Col>
          </Row>

          <FormField
            component={DocumentStatusField}
            name="status"
            disabled={disabled}
            withErrors={false}
            errorMessages={[
              touched.status ? errors.status : undefined,
              touched.status ? errors.status : undefined,
            ]}
          />

          {!isSaveHidden && (
            <SubmitButton htmlType="submit" className="fullwidth" disabled={disabled}>
              {t(isCreate ? 'DOCUMENT_UPLOAD' : 'DOCUMENT_SAVE')}
            </SubmitButton>
          )}
        </form>
      )}
    </Formik>
  );
};

export default DocumentForm;
