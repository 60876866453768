import {
  DEFAULT_CUSTOMER_ID,
  REL_DATA_HOST_SETTINGS_DYNAMIC_PAGES_KEY_PREFIX,
  REL_DATA_HOST_SETTINGS_KEY_PREFIX,
  REL_DATA_THEME_KEY_PREFIX,
} from './constants';

/**
 * ### Assemble font file location
 * @param {string} base
 * @param {string} fontFamilyName
 * @param {string} weight
 * @param {string} style
 * @param {string} extension
 * @return {string}
 */
export const assembleFontFileLocation = (
  base: string,
  fontFamilyName: string,
  weight: string,
  style: string,
  extension: string,
): string => {
  let result = base;
  result = result.replace('{{fontFamilyName}}', fontFamilyName);
  result = result.replace('{{weight}}', weight);
  result = result.replace('{{style}}', style);
  result = result.replace('{{ext}}', extension);
  return result;
};

/**
 * ### Assemble import link
 * @param {string} base
 * @param {string} lng
 * @param {string} token
 * @return {string}
 */
export const assembleImportLocation = (
  base: string,
  lng: string,
  token: string,
): string => {
  let result = base;
  result = result.replace('{{lng}}', lng);
  result = result.replace('{{token}}', token);
  return result;
};

export const assembleFeaturesFlagsKey = (clientId: string | undefined | null): string =>
  `${REL_DATA_HOST_SETTINGS_KEY_PREFIX}${clientId ?? DEFAULT_CUSTOMER_ID}`;

export const assembleDynamicPagesFeaturesFlagsKey = (
  clientId: string | undefined | null,
): string =>
  `${REL_DATA_HOST_SETTINGS_DYNAMIC_PAGES_KEY_PREFIX}${clientId ?? DEFAULT_CUSTOMER_ID}`;

export const assembleHostSettingsKey = (clientId: string | undefined | null): string =>
  `${REL_DATA_THEME_KEY_PREFIX}${clientId ?? DEFAULT_CUSTOMER_ID}`;
