import {
  SoftOfferOptionsKeys,
  SoftOfferVehicle,
  SoftOfferVehicleFields,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { Status } from 'utils/types';
import {
  DATE_FORMAT,
  SOFT_OFFER_VEHICLE_TRAILER1TYPE_BLANK_VALUE,
} from 'common/constants';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { DatePicker } from 'components/form/DatePicker';
import Checkbox from 'components/form/Checkbox';
import { TextArea } from 'components/form/TextArea';
import { ScCheckboxFormFieldWrapper } from '../styles';
import { isFieldRequired } from 'utils/is-field-required';
import { ScList } from './styles';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

const tPrefix = 'SOFT_OFFER_VEHICLE_';
const testPrefix = 'soft-offer_vehicle_';

interface Props {
  isDisabled: boolean;
  initialValues?: SoftOfferVehicle;
  onSubmit(values: SoftOfferVehicle): void;
  setIsTabLocked?(value: boolean): void;
}

const VehicleForm: FC<Props> = ({
  onSubmit,
  initialValues,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const fuelCodeList = options?.[SoftOfferOptionsKeys.CarFuelCode]?.options ?? [];
  const typeList = options?.[SoftOfferOptionsKeys.CarType]?.options ?? [];
  const usageList = options?.[SoftOfferOptionsKeys.CarUsage]?.options ?? [];
  const kindOfBuyList = options?.[SoftOfferOptionsKeys.KindOfBuy]?.options ?? [];
  const alarmCodeList = options?.[SoftOfferOptionsKeys.AlarmCode]?.options ?? [];
  const maximumMileagePerYearCategoryList =
    options?.[SoftOfferOptionsKeys.MaximumMileagePerYearCategory]?.options ?? [];
  const trailer1typeList = options?.[SoftOfferOptionsKeys.Trailer1Type]?.options ?? [];

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    return initialValues;
  }, [initialValues]);

  const formProps = useFormik({
    initialValues: initialData!,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { values, dirty } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  const isTailerBlank = useMemo(() => {
    return (
      values?.[SoftOfferVehicleFields.trailer1Type] ===
      SOFT_OFFER_VEHICLE_TRAILER1TYPE_BLANK_VALUE
    );
  }, [values]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={8}>
            <FormField
              id={SoftOfferVehicleFields.type}
              name={SoftOfferVehicleFields.type}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(typeList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.type)}
              label={t(tPrefix + 'TYPE')}
              data-testid={testPrefix + 'type'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.brand}
              name={SoftOfferVehicleFields.brand}
              component={Input}
              required={isFieldRequired(schema, SoftOfferVehicleFields.brand)}
              label={t(tPrefix + 'BRAND')}
              data-testid={testPrefix + 'brand'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.chassis}
              name={SoftOfferVehicleFields.chassis}
              component={Input}
              required={isFieldRequired(schema, SoftOfferVehicleFields.chassis)}
              label={t(tPrefix + 'CHASSIS')}
              data-testid={testPrefix + 'chassis'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.fuelCode}
              name={SoftOfferVehicleFields.fuelCode}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(fuelCodeList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.fuelCode)}
              label={t(tPrefix + 'FUEL_CODE')}
              data-testid={testPrefix + 'fuelCode'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.power}
              name={SoftOfferVehicleFields.power}
              component={InputNumberLocalized}
              required={isFieldRequired(schema, SoftOfferVehicleFields.power)}
              label={t(tPrefix + 'POWER')}
              data-testid={testPrefix + 'power'}
              disabled={isDisabled}
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferVehicleFields.firstImmatriculationAt}
                  name={SoftOfferVehicleFields.firstImmatriculationAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferVehicleFields.firstImmatriculationAt,
                  )}
                  label={t(tPrefix + 'FIRST_REGISTRATION_AT')}
                  data-testid={testPrefix + 'firstImmatriculationAt'}
                  disabled={isDisabled}
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferVehicleFields.purchaseAt}
                  name={SoftOfferVehicleFields.purchaseAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(schema, SoftOfferVehicleFields.purchaseAt)}
                  label={t(tPrefix + 'PURCHASE_AT')}
                  data-testid={testPrefix + 'purchaseAt'}
                  disabled={isDisabled}
                />
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <FormField
              id={SoftOfferVehicleFields.kindOfBuy}
              name={SoftOfferVehicleFields.kindOfBuy}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(kindOfBuyList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.kindOfBuy)}
              label={t(tPrefix + 'KIND_OF_BUY')}
              data-testid={testPrefix + 'kindOfBuy'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.model}
              name={SoftOfferVehicleFields.model}
              component={Input}
              required={isFieldRequired(schema, SoftOfferVehicleFields.model)}
              label={t(tPrefix + 'MODEL')}
              data-testid={testPrefix + 'model'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.immatriculationPlate}
              name={SoftOfferVehicleFields.immatriculationPlate}
              component={Input}
              required={isFieldRequired(
                schema,
                SoftOfferVehicleFields.immatriculationPlate,
              )}
              label={t(tPrefix + 'REGISTRATION_PLATE')}
              data-testid={testPrefix + 'immatriculationPlate'}
              disabled={isDisabled}
            />
            <FormField
              id={SoftOfferVehicleFields.usage}
              name={SoftOfferVehicleFields.usage}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(usageList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.usage)}
              label={t(tPrefix + 'USAGE')}
              data-testid={testPrefix + 'usage'}
              disabled={isDisabled}
            />
            <FormField
              id={SoftOfferVehicleFields.remarque}
              name={SoftOfferVehicleFields.remarque}
              component={TextArea}
              required={isFieldRequired(schema, SoftOfferVehicleFields.remarque)}
              label={t(tPrefix + 'REMARQUE')}
              data-testid={testPrefix + 'remarque'}
              disabled={isDisabled}
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={8}>
                <FormField
                  id={SoftOfferVehicleFields.safetyFeaturesCount}
                  name={SoftOfferVehicleFields.safetyFeaturesCount}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferVehicleFields.safetyFeaturesCount,
                  )}
                  label={t(tPrefix + 'SAFETY_FEATURES_COUNT')}
                  data-testid={testPrefix + 'safetyFeaturesCount'}
                  disabled={isDisabled}
                  max={5}
                />
              </Col>
              <Col md={16}>
                <ScList>
                  <li>{t(tPrefix + 'SAFETY_FEATURES_COUNT__OPTION1')}</li>
                  <li>{t(tPrefix + 'SAFETY_FEATURES_COUNT__OPTION2')}</li>
                  <li>{t(tPrefix + 'SAFETY_FEATURES_COUNT__OPTION3')}</li>
                  <li>{t(tPrefix + 'SAFETY_FEATURES_COUNT__OPTION4')}</li>
                  <li>{t(tPrefix + 'SAFETY_FEATURES_COUNT__OPTION5')}</li>
                </ScList>
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <FormField
              id={SoftOfferVehicleFields.contractStartAt}
              name={SoftOfferVehicleFields.contractStartAt}
              component={DatePicker}
              format={DATE_FORMAT}
              required={isFieldRequired(schema, SoftOfferVehicleFields.contractStartAt)}
              label={t(tPrefix + 'CONTRACT_START_AT')}
              data-testid={testPrefix + 'contractStartAt'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.alarmCode}
              name={SoftOfferVehicleFields.alarmCode}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(alarmCodeList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.alarmCode)}
              label={t(tPrefix + 'ALARM_CODE')}
              data-testid={testPrefix + 'alarmCode'}
              disabled={isDisabled}
            />

            <FormField
              id={SoftOfferVehicleFields.maximumMileagePerYearCategory}
              name={SoftOfferVehicleFields.maximumMileagePerYearCategory}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(
                maximumMileagePerYearCategoryList,
                {
                  label: OlistItemOptionFields.label,
                  value: OlistItemOptionFields.value,
                },
              )}
              required={isFieldRequired(
                schema,
                SoftOfferVehicleFields.maximumMileagePerYearCategory,
              )}
              label={t(tPrefix + 'MAXIMUM_MILEAGE')}
              data-testid={testPrefix + 'maximumMileagePerYearCategory'}
              disabled={isDisabled}
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferVehicleFields.odometerActualReadingAt}
                  name={SoftOfferVehicleFields.odometerActualReadingAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferVehicleFields.odometerActualReadingAt,
                  )}
                  label={t(tPrefix + 'ODOMETER_READING_AT')}
                  data-testid={testPrefix + 'odometerActualReadingAt'}
                  disabled={isDisabled}
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferVehicleFields.odometerActualReadingQuantity}
                  name={SoftOfferVehicleFields.odometerActualReadingQuantity}
                  component={InputNumberLocalized}
                  required={isFieldRequired(
                    schema,
                    SoftOfferVehicleFields.odometerActualReadingQuantity,
                  )}
                  label={t(tPrefix + 'ODOMETER_READING_VALUE')}
                  data-testid={testPrefix + 'odometerActualReadingQuantity'}
                  disabled={isDisabled}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <ScCheckboxFormFieldWrapper>
                  <FormField
                    id={SoftOfferVehicleFields.isAllRoad}
                    name={SoftOfferVehicleFields.isAllRoad}
                    component={Checkbox}
                    checked={values?.[SoftOfferVehicleFields.isAllRoad]}
                    required={isFieldRequired(schema, SoftOfferVehicleFields.isAllRoad)}
                    label={t(tPrefix + 'IS_ALL_ROAD')}
                    data-testid={testPrefix + 'isAllRoad'}
                    disabled={isDisabled}
                  />
                </ScCheckboxFormFieldWrapper>
              </Col>
              <Col md={12}>
                <ScCheckboxFormFieldWrapper>
                  <FormField
                    id={SoftOfferVehicleFields.isConvertibleIndicator}
                    name={SoftOfferVehicleFields.isConvertibleIndicator}
                    component={Checkbox}
                    checked={values?.[SoftOfferVehicleFields.isConvertibleIndicator]}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.isConvertibleIndicator,
                    )}
                    label={t(tPrefix + 'IS_CONVERTIBLE_INDICATOR')}
                    data-testid={testPrefix + 'isConvertibleIndicator'}
                    disabled={isDisabled}
                  />
                </ScCheckboxFormFieldWrapper>
              </Col>
            </Row>

            <FormField
              id={SoftOfferVehicleFields.trailer1Type}
              name={SoftOfferVehicleFields.trailer1Type}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(trailer1typeList, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferVehicleFields.trailer1Type)}
              label={t(tPrefix + 'TRAILER1TYPE')}
              data-testid={testPrefix + 'trailer1Type'}
              disabled={isDisabled}
            />

            {!isTailerBlank && (
              <Row gutter={SUB_ROW_SPACES}>
                <Col md={12}>
                  <FormField
                    id={SoftOfferVehicleFields.trailer1Brand}
                    name={SoftOfferVehicleFields.trailer1Brand}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.trailer1Brand,
                    )}
                    label={t(tPrefix + 'TRAILER1BRAND')}
                    data-testid={testPrefix + 'trailer1Brand'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id={SoftOfferVehicleFields.trailer1Model}
                    name={SoftOfferVehicleFields.trailer1Model}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.trailer1Model,
                    )}
                    label={t(tPrefix + 'TRAILER1MODEL')}
                    data-testid={testPrefix + 'trailer1Model'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id={SoftOfferVehicleFields.trailer1Chassis}
                    name={SoftOfferVehicleFields.trailer1Chassis}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.trailer1Chassis,
                    )}
                    label={t(tPrefix + 'TRAILER1CHASSIS')}
                    data-testid={testPrefix + 'trailer1Chassis'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={12}>
                  <FormField
                    id={SoftOfferVehicleFields.trailer1Plate}
                    name={SoftOfferVehicleFields.trailer1Plate}
                    component={Input}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.trailer1Plate,
                    )}
                    label={t(tPrefix + 'TRAILER1PLATE')}
                    data-testid={testPrefix + 'trailer1Plate'}
                    disabled={isDisabled}
                  />
                </Col>
                <Col md={24}>
                  <FormField
                    id={SoftOfferVehicleFields.maximumAuthorisedWeightQuantity}
                    name={SoftOfferVehicleFields.maximumAuthorisedWeightQuantity}
                    component={InputNumberLocalized}
                    required={isFieldRequired(
                      schema,
                      SoftOfferVehicleFields.maximumAuthorisedWeightQuantity,
                    )}
                    label={t(tPrefix + 'MAXIMUM_AUTHORISED_WEIGHT_QUANTITY')}
                    data-testid={testPrefix + 'maximumAuthorisedWeightQuantity'}
                    disabled={isDisabled}
                    min={0}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default VehicleForm;
