import React, { FC, useCallback, useMemo } from 'react';
import { ConfigurationRelation } from 'context/relations/types';
import { useTranslation } from 'react-i18next';
import { BaseRelationTransactionModal } from './BaseRelationTransactionModal/BaseRelationTransactionModal';
import { useRelations } from 'context/relations/RelationsProvider';
import Icon from '@ant-design/icons/CopyOutlined';

interface Props {
  isOpen: boolean;
  activeRelationId?: string;
  relations: ConfigurationRelation[];
  onClose(targetRelationId?: string): void;
  onCopy?(activeRelationId: string, relationId: string): void;
}
export type CopyRelationModalProps = Props;
export const CopyRelationModal: FC<Props> = props => {
  const { isOpen, relations, onClose, activeRelationId, onCopy } = props;
  const { t } = useTranslation();
  const { copyRelationData } = useRelations();

  const handleChange = useCallback(
    (relationId: string) => {
      if (activeRelationId && relationId) {
        copyRelationData(activeRelationId, relationId);
        onCopy?.(activeRelationId, relationId);
      }
      onClose(relationId);
    },
    [onClose, copyRelationData, activeRelationId, onCopy],
  );

  const options = useMemo<ConfigurationRelation[]>(() => {
    return relations.filter(relation => relation.id !== activeRelationId);
  }, [relations, activeRelationId]);

  const active = useMemo<ConfigurationRelation | undefined>(() => {
    return relations.find(relation => relation.id === activeRelationId);
  }, [relations, activeRelationId]);

  if (!isOpen) return null;

  return (
    <BaseRelationTransactionModal
      title={t('COPY_RELATION')}
      options={options}
      onSubmit={handleChange}
      active={active}
      onClose={onClose}
      icon={<Icon />}
    />
  );
};
