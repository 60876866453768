import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  CarOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  EuroCircleOutlined,
  ExpandOutlined,
  ExportOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons';

const streamingIconStyles = css`
  &&&& {
    font-size: 1.75em;
    color: ${props => props.theme.highlightColor};
    cursor: pointer;
    margin-right: 0.75em;
  }
`;

export const ScPreviewIcon = styled(CarOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingPreviewAllNotSelectedRecords = styled(AppstoreAddOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingPreviewAllSelectedRecords = styled(AppstoreOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingOpenActiveTerminal = styled(ExportOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingStopStreaming = styled(CloudDownloadOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingModalCloseForTerminal = styled(FullscreenExitOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingStartStreaming = styled(CloudUploadOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingWithSummaryPrice = styled(EuroCircleOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingSettings = styled(ExpandOutlined)`
  ${streamingIconStyles}
`;

export const ScStreamingMenuItemWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const ScStreamingMenu = styled(Menu)<{ $width?: string | undefined }>`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  box-shadow: 0px 5px 68px rgba(0, 0, 0, 0.08);
  max-height: 25em;
  overflow-y: auto;
`;

export const ScStreamingMenuItem = styled(Menu.Item)`
  min-height: 3em;
  padding: 0.5em 1em;
  border: 1px solid transparent;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.mainColor};
    border: 1px solid ${({ theme }) => theme.mainBorderColor};
    color: ${({ theme }) => theme.mainColor_contrast};
  }
  /* @todo: refactor; just to have link inside of menu so it wont change color in a weird way */
  a {
    color: inherit;
    transition: color 0s;
  }

  &:hover {
    &&&&& {
      ${ScPreviewIcon},
      ${ScStreamingPreviewAllNotSelectedRecords},
      ${ScStreamingPreviewAllSelectedRecords},
      ${ScStreamingStopStreaming},
      ${ScStreamingSettings} {
        color: ${props => props.theme.highlightColor_contrast};
      }
    }
  }
`;
