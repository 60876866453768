import useIsMounted from 'hooks/useIsMounted';
import { useEffect, useState } from 'react';

export default function useGetOptions<T, F>(
  callback: (filters: F) => Promise<T[]>,
  filters: F,
) {
  const [options, setOptions] = useState<T[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    let localIsLoading = false;
    setIsLoading(value => {
      localIsLoading = value;
      return value;
    });
    if (localIsLoading) return;

    const handle = setTimeout(() => {
      setIsLoading(true);
      callback(filters)
        .then(data => {
          if (!isMounted()) return;
          setOptions(data);
        })
        .finally(() => {
          if (!isMounted()) return;
          setIsLoading(false);
        });
    }, 1000);

    return () => {
      clearTimeout(handle);
    };
  }, [callback, filters, isMounted]);

  return { options, isLoading };
}
