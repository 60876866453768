import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  MachineListItem,
  MachineListItemFields,
  Filter,
} from '@hypercharge/xdms-client/lib/types';
import { Status } from 'utils/types';
import { getFilterTableTitlesBySearchColumns } from 'components/customerForms/utils/get-filtered-table-titles-by-search-columns';
import { SearchModal } from 'components/SearchModal/SearchModal';
import { useColumns } from './use-columns';
import { useMachineApi } from 'context/machine/useMachineApi';

interface Props {
  onClose(): void;
  onSubmit(records: MachineListItem[]): void;
  initialValues?: MachineListItem[];
  /** Used just to update local `filters` state. */
  filters?: Filter<keyof MachineListItem>;
}
export type SearchMachineModalProps = Props;
export const SearchMachineModal: FC<Props> = ({
  onClose,
  onSubmit,
  initialValues,
  filters: externalFilters,
}) => {
  const { status, getMachinesList } = useMachineApi();

  const [filters, setFilters] = useState<Filter<keyof MachineListItem>>([]);
  const [tableItemsList, setTableItemsList] = useState<MachineListItem[]>([]);

  useEffect(() => {
    setFilters(externalFilters ?? []);
  }, [externalFilters]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const list = await getMachinesList(filters);
      setTableItemsList(list.response ?? []);
    }, 100);
    return () => clearTimeout(timeout);
  }, [getMachinesList, filters]);

  const isLoading = useMemo(() => {
    return [status].includes(Status.Loading);
  }, [status]);

  const handleReset = useCallback(
    async dataIndex => {
      const updatedFilters = filters.filter(filter => filter.name !== dataIndex);

      setFilters(updatedFilters);
    },
    [filters],
  );

  const columns = useColumns({
    handleReset,
    filters,
    setFilters,
  });

  const tableTitles = useMemo(() => {
    return getFilterTableTitlesBySearchColumns<MachineListItem>(columns);
  }, [columns]);

  // @todo: antd@5
  return (
    <SearchModal
      testNamePrefix="machine"
      onClose={onClose}
      initialValue={initialValues}
      tableTitles={tableTitles}
      tableColumns={columns as any}
      tableData={tableItemsList}
      tableDataIdField={MachineListItemFields.id}
      filters={filters}
      onFilterClearAll={() => setFilters([])}
      onFilterClearOne={handleReset}
      isLoading={isLoading}
      onSubmit={records => {
        onSubmit(records as MachineListItem[]);
        onClose();
      }}
    />
  );
};
