import Switcher from 'components/form/Switcher';
import { useStreaming } from 'context/streaming/StreamingProvider';
import React, { FC, useCallback, useState } from 'react';
import { ScMoreOverlayWrapper, ScOption } from './MoreOverlay.styles';
import { useTranslation } from 'react-i18next';
import { StreamingStatus, streamingSelectors } from 'store/streaming';
import { StreamingEventType } from 'context/streaming/types';
import { useSelector } from 'react-redux';

type Props = {
  type: 'static' | 'dynamic';
  withSummaryPrice: boolean;
  customerId: string;
  terminalStatus: StreamingStatus;
};

const MoreOverlay: FC<Props> = ({
  type,
  withSummaryPrice,
  customerId,
  terminalStatus,
}) => {
  const [isStaticType, setIsStaticType] = useState<boolean>(type === 'static');

  const { t } = useTranslation();
  const { sendMessage } = useStreaming();

  const currentScreen = useSelector(streamingSelectors.getCurrentScreen);

  const handleTypeChange = useCallback(() => {
    setIsStaticType(prevState => !prevState);
    sendMessage({
      type: StreamingEventType.UPDATE_TERMINAL_FLAGS,
      data: {
        customerId,
        flags: {
          type: isStaticType ? 'static' : 'dynamic',
        },
      },
    });
  }, [sendMessage, customerId, isStaticType]);

  const handleWithSummaryPriceChange = useCallback(() => {
    sendMessage({
      type: StreamingEventType.UPDATE_TERMINAL_FLAGS,
      data: {
        customerId,
        flags: {
          withSummaryPrice: !withSummaryPrice,
        },
      },
    });
  }, [customerId, sendMessage, withSummaryPrice]);

  return (
    <ScMoreOverlayWrapper>
      <ScOption>
        <Switcher
          alignment="row"
          value={!isStaticType}
          onClick={handleTypeChange}
          title={t('STREAMING_TERMINAL_TYPE')}
          disabled={terminalStatus === StreamingStatus.BUSY}
        />
      </ScOption>
      {currentScreen === 'summary' && terminalStatus === StreamingStatus.BUSY && (
        <ScOption>
          <Switcher
            alignment="row"
            value={withSummaryPrice}
            onClick={handleWithSummaryPriceChange}
            title={t('STREAMING_WITH_SUMMARY_PRICE')}
          />
        </ScOption>
      )}
    </ScMoreOverlayWrapper>
  );
};

export default MoreOverlay;
