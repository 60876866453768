import React, { FC, ReactNode, useMemo } from 'react';
import {
  Model,
  Model_CommonItemFields,
  ModelFields,
  ModelPackageLineFields,
  TotalsItem,
} from '@hypercharge/xdms-client/lib/types';
import TotalTableCollapsibleListRow from '../../components/TotalTableCollapsibleListRow';
import { TFunction } from 'i18next';
import { get } from '../../../../utils';
import {
  ChangedPrice,
  totalsInnerTableColumnsWidths,
  TotalsTableColumnsGenericProp,
} from '../../styles';
import {
  ScPackageId,
  ScPackageLineId,
  ScPackageLineName,
  ScPackageName,
} from './Package.styles';
import { sortSelectedByDefaultAsFirst, TotalPageAdaptedFields } from '../../utils';
import { Price } from '../../../../components/Price';
import { TotalTextEllipsis } from 'pages/total/components/TotalTextEllipsis';

interface Props {
  model: Model | null;
  t: TFunction;
  FIELDS: TotalPageAdaptedFields;
  totalData: TotalsItem | null;
  hideZeroDiscounts: boolean;
  hideIfEmpty: boolean;
}

const Packages: FC<Props> = ({
  model,
  t,
  totalData,
  FIELDS,
  hideZeroDiscounts,
  hideIfEmpty,
}) => {
  const items = useMemo<
    TotalsTableColumnsGenericProp<ReactNode | string | undefined>[]
  >(() => {
    const res: TotalsTableColumnsGenericProp<ReactNode | string | undefined>[] = [];

    let packages = get(model, ModelFields.packages, []);
    const packagesLines = get(model, ModelFields.packageLines, []);

    packages = [...packages]
      .sort(sortSelectedByDefaultAsFirst)
      .filter(item => item[Model_CommonItemFields.selected]);

    packages.forEach(pack => {
      const packDiscountAmount = get(pack, FIELDS.packagesItemsFields.discountAmount, 0);
      const packDiscountPercent = get(
        pack,
        FIELDS.packagesItemsFields.discountPercent,
        0,
      );

      const showPackDiscountAmount = Boolean(!hideZeroDiscounts || packDiscountAmount);
      const showPackDiscountPercent = Boolean(!hideZeroDiscounts || packDiscountPercent);

      const bruttoAmount = pack[FIELDS.packagesItemsFields.brutto];
      const nettoAmount = pack[FIELDS.packagesItemsFields.netto];

      res.push([
        undefined,
        <ScPackageName>
          <TotalTextEllipsis>{pack[FIELDS.packagesItemsFields.name]}</TotalTextEllipsis>
        </ScPackageName>,
        <ScPackageId>
          <TotalTextEllipsis>{pack[FIELDS.packagesItemsFields.id]}</TotalTextEllipsis>
        </ScPackageId>,
        pack[FIELDS.packagesItemsFields.quantity],
        pack[FIELDS.packagesItemsFields.hasChanged] ? (
          <ChangedPrice value={bruttoAmount} />
        ) : (
          <Price value={bruttoAmount} />
        ),
        <>{showPackDiscountAmount && <Price value={packDiscountAmount} />}</>,
        <>
          {showPackDiscountPercent && (
            <Price value={packDiscountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        pack[FIELDS.packagesItemsFields.hasChanged] ? (
          <ChangedPrice value={nettoAmount} />
        ) : (
          <Price value={nettoAmount} />
        ),
      ]);

      const lines = packagesLines.filter(line => {
        const isForThisPackage =
          line[ModelPackageLineFields.package] === pack[Model_CommonItemFields.package];
        const isSelected = line[ModelPackageLineFields.selected];

        return isForThisPackage && isSelected;
      });

      lines.forEach(line => {
        const lineDiscountAmount = get(
          line,
          FIELDS.packagesLineItemsFields.discountAmount,
          0,
        );
        const lineDiscountPercent = get(
          line,
          FIELDS.packagesLineItemsFields.discountPercent,
          0,
        );

        const showLineDiscountAmount = Boolean(!hideZeroDiscounts || lineDiscountAmount);
        const showLineDiscountPercent = Boolean(
          !hideZeroDiscounts || lineDiscountPercent,
        );

        const bruttoAmount = line[FIELDS.packagesLineItemsFields.brutto];
        const nettoAmount = line[FIELDS.packagesLineItemsFields.netto];

        res.push([
          undefined,
          <ScPackageLineName>
            {line[FIELDS.packagesLineItemsFields.name]}
          </ScPackageLineName>,
          <ScPackageLineId>{line[FIELDS.packagesLineItemsFields.id]}</ScPackageLineId>,
          line[FIELDS.packagesLineItemsFields.quantity],
          line[FIELDS.packagesLineItemsFields.hasChanged] ? (
            <ChangedPrice value={bruttoAmount} />
          ) : (
            <Price value={bruttoAmount} />
          ),
          <>{showLineDiscountAmount && <Price value={lineDiscountAmount} />}</>,
          <>
            {showLineDiscountPercent && (
              <Price value={lineDiscountPercent} percentage priceStyle="decimal" />
            )}
          </>,
          line[FIELDS.packagesLineItemsFields.hasChanged] ? (
            <ChangedPrice value={nettoAmount} />
          ) : (
            <Price value={nettoAmount} />
          ),
        ]);
      });
    });

    return res;
  }, [FIELDS, hideZeroDiscounts, model]);

  if (hideIfEmpty && !items.length) return null;

  const totalDiscountAmount = get(totalData, FIELDS.packages.discountAmount, 0);
  const totalDiscountPercent = get(totalData, FIELDS.packages.discountPercent, 0);

  return (
    <TotalTableCollapsibleListRow<
      TotalsTableColumnsGenericProp<ReactNode | string | undefined>
    >
      columnsCustomWidths={totalsInnerTableColumnsWidths}
      items={items}
      getItemFields={fields => fields}
      testIdPrefix="selected-packages"
      headerFields={[
        t('TOTAL_SELECTED_PACKAGES_SUBTOTAL'),
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.packages.brutto, 0)} />,
        <>
          {hideZeroDiscounts && !totalDiscountAmount ? null : (
            <Price value={totalDiscountAmount} />
          )}
        </>,
        <>
          {hideZeroDiscounts && !totalDiscountPercent ? null : (
            <Price value={totalDiscountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price value={get(totalData, FIELDS.packages.netto, 0)} />,
      ]}
    />
  );
};

export default Packages;
