import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { Price } from 'components/Price';
import { BannerSettings } from '../types';
import { useTotalPage } from '../../TotalPageProvider';
import { TotalTableRow } from '../../components/TotalTableRow';
import { sharedSelectors } from 'store';
import { useSelector } from 'react-redux';

type SpecificSettings = {
  /** Temporary field overriding, will be erased in next updates.
   * without vat, with vat */
  temp__nettoField?: [string, string];
};

type Props = BannerSettings<SpecificSettings>;

const TotalGenericTotalExDealer: FC<Props> = ({ temp__nettoField }) => {
  const { t } = useTranslation();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const { hideZeroDiscounts, totalData, FIELDS } = useTotalPage();

  const discountAmount = get(totalData, FIELDS.totalExDealer.discountAmount, 0);
  const discountPercent = get(totalData, FIELDS.totalExDealer.discountPercent, 0);

  const showDiscountAmount = Boolean(!hideZeroDiscounts || discountAmount);
  const showDiscountPercent = Boolean(!hideZeroDiscounts || discountPercent);

  const nettoField = useMemo<string>(() => {
    if (temp__nettoField) {
      const [withoutVat, withVat] = temp__nettoField;
      return shouldShowPricesWithVAT ? withVat : withoutVat;
    }
    return FIELDS.totalExDealer.netto;
  }, [temp__nettoField, FIELDS, shouldShowPricesWithVAT]);

  return (
    <TotalTableRow
      data-testid="total-page-total-ex-dealer"
      borderBottom="thin"
      textSize="large"
      shadeNumbers
      fields={[
        t('TOTAL_HEADER_TOTAL_EX_MANUFACTURER'),
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.totalExDealer.brutto, 0)} />,
        <>{showDiscountAmount && <Price value={discountAmount} />}</>,
        <>
          {showDiscountPercent && (
            <Price value={discountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price value={get(totalData, nettoField, 0)} />,
      ]}
    />
  );
};

export default TotalGenericTotalExDealer;
