import { QueryResult, useQuery } from '@apollo/client';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useCallback, useEffect, useMemo } from 'react';
import { CAR_BY_TOKEN } from 'graphql/car-by-token.gql';
import { useSelector } from 'react-redux';
import { modelSelectors } from '../store';
import useCurrentLanguageCode from './useCurrentLanguageCode';

interface Response {
  carByToken: {
    visualizations:
      | {
          views: {
            exterior: {
              studio: {
                threeQuartersFrontLeft: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  } | null;
                } | null;
                threeQuartersFrontRight: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  };
                } | null;
                threeQuartersRearLeft: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  };
                } | null;
                threeQuartersRearRight: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  };
                } | null;
                left: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  };
                } | null;
                right: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  };
                } | null;
              } | null;
              details: {
                wheel: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  } | null;
                } | null;
              };
            };
            interior: {
              studio: {
                driversView: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  } | null;
                } | null;
                side: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  } | null;
                } | null;
                rear: {
                  sizes: {
                    large: { url: string | null; transparentUrl: string | null } | null;
                  } | null;
                } | null;
              } | null;
            };
          };
        }[]
      | null;
  };
}

interface Vars {
  token: string;
  marketOrLocale: string;
}

interface Params {
  skip?: boolean;
}
interface Result {
  data: Response['carByToken'] | undefined;
  loading: boolean;
  refetch(): ReturnType<QueryResult['refetch']> | void;
  error: { message: string } | null;
  variables: Omit<Vars, 'token'> & { token: string | null };
}

const useCarInfo = (params: Params = {}): Result => {
  const { skip = false } = params;

  const { isFeatureEnabled } = useFeature();
  const currentLanguageCode = useCurrentLanguageCode();
  const isGOXFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.GOXEnabled,
  });

  const modelToken = useSelector(modelSelectors.getToken);

  const { data, refetch, error, networkStatus } = useQuery<Response, Vars>(CAR_BY_TOKEN, {
    variables: {
      marketOrLocale: currentLanguageCode.iso,
      // we skip if token is missing
      token: modelToken as string,
    },
    notifyOnNetworkStatusChange: true,
    skip: !isGOXFeatureEnabled || !modelToken || skip,
  });

  const refetchCarConfigurationBySpecification = useCallback<Result['refetch']>(() => {
    if (!modelToken) return;

    return refetch({
      token: modelToken,
      marketOrLocale: currentLanguageCode.iso,
    });
  }, [modelToken, currentLanguageCode, refetch]);

  useEffect(() => {
    if (data && !data.carByToken && modelToken) {
      refetch({
        token: modelToken,
        marketOrLocale: currentLanguageCode.iso,
      });
    }
  }, [data, refetch, currentLanguageCode, modelToken]);

  const variables = useMemo<Result['variables']>(() => {
    return {
      token: modelToken,
      marketOrLocale: currentLanguageCode.iso,
    };
  }, [modelToken, currentLanguageCode]);

  return {
    data: data?.carByToken,
    loading: networkStatus === 1 || networkStatus === 4,
    refetch: refetchCarConfigurationBySpecification,
    error: error ? { message: error.message } : null,
    variables,
  };
};

export default useCarInfo;
