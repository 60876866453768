export const checkWhetherRecordContainsString = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  record: T,
  string: string,
): boolean => {
  const stringifiedRecord: string = Object.values(record)
    .join('')
    .replace(',', '')
    .toLowerCase();
  return stringifiedRecord.includes(string.toLowerCase());
};
