import React from 'react';
import OriginalImageCard from 'layout/Default/header/recordsPreviewer/ImageCard';
import { NoImage, ScBadge, ScCaption, ScOptionsList, ScText } from './ImageCard.styles';
import { featuresFlagsSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { PreviewImage } from 'types';

type Props = {
  className?: string;
  image: PreviewImage;
  onClick?(image: PreviewImage): void;
  hideContent?: boolean;
};

const ImageCard: React.FC<Props> = ({
  className,
  image,
  onClick,
  hideContent = false,
}) => {
  const { t } = useTranslation();
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const noImage =
    globalFeatures?.[GlobalFeaturesFlagsFields.StreamingOptionFallbackImage];

  if (hideContent) {
    return (
      <OriginalImageCard
        className={className}
        errorIcon={
          <NoImage
            alt={t('STREAMING_RECORD_PREVIEW_DEFAULT_DESCRIPTION')}
            src={noImage}
          />
        }
        url={image.url}
        name={image.name}
        description={
          image.description ?? t('STREAMING_RECORD_PREVIEW_DEFAULT_DESCRIPTION')
        }
        onClick={() => onClick?.(image)}
      />
    );
  }

  return (
    <OriginalImageCard
      className={className}
      errorIcon={
        <NoImage alt={t('STREAMING_RECORD_PREVIEW_DEFAULT_DESCRIPTION')} src={noImage} />
      }
      url={image.url}
      name={image.name}
      description={image.description ?? t('STREAMING_RECORD_PREVIEW_DEFAULT_DESCRIPTION')}
      onClick={() => onClick?.(image)}
    >
      <ScBadge>{image.isDefault ? t('DEFAULT_LABEL') : image.type}</ScBadge>
      <ScCaption $truncated={!!image.options.length}>{image.name}</ScCaption>

      {image.description && <ScText>{image.description}</ScText>}
      {!!image.options?.length && (
        <>
          <ScText>{t('PACKAGE_CONTAIN_LABEL')}:</ScText>
          <ScOptionsList>
            {image.options.map(option => (
              <li key={option}>{option}</li>
            ))}
          </ScOptionsList>
        </>
      )}
    </OriginalImageCard>
  );
};

export default ImageCard;
