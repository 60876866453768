import {
  Model,
  ModelFields,
  ModelRule,
  ModelRuleValues,
  Model_CommonItem,
  Model_CommonItemFields,
  PackageItem,
} from '@hypercharge/xdms-client/lib/types';
import { ModelRulesGrouped } from '../types';
import { getRules } from './get-record-rules';
import { isEmpty } from 'utils';

const accumulatePackageLines = (
  result: string[][],
  packageLine: Model_CommonItem,
  rules: ModelRulesGrouped['incompatible'],
): string[][] => {
  const plId = packageLine[Model_CommonItemFields.ID];
  const group: string[] = [plId];
  if (rules?.[plId]) group.push(...Object.keys(rules[plId]));
  result.push(group);
  return result;
};

const accumulateIncRecords = (
  result: string[],
  group: string[],
  packageLinesIdToBool: Record<string, true>,
): string[] => {
  if (group.length === 1) return result;
  group.forEach(recordId => {
    if (packageLinesIdToBool[recordId]) result.push(recordId);
  });
  return result;
};

/**
 * Helper method to mirror inc. rules in case they are not properly normalized.
 * Example input: { '456': { '678': true } }
 * Example output: { '456': { '678': true }, '678': { '456': true } }
 *
 * @param incRules {ModelRulesGrouped} - incompatibility rules
 * @returns mirrored incompatibility rules
 */
const mirrorIncRules = (
  incRules: NonNullable<ModelRulesGrouped['incompatible']>,
): ModelRulesGrouped['incompatible'] => {
  return Object.keys(incRules ?? {}).reduce((res, sourceRecordId, _, list) => {
    res[sourceRecordId] = incRules[sourceRecordId];
    const targetRecordIds = Object.keys(incRules[sourceRecordId]);
    targetRecordIds.forEach(targetRecordId => {
      if (!res[targetRecordId]) list.push(targetRecordId);
      res[targetRecordId] = {
        ...res?.[targetRecordId],
        [sourceRecordId]: true,
      };
    });
    return res;
  }, {});
};

interface GetPackageIncompatibleRecordsParams {
  model: Model | null;
  modelRules: ModelRule[];
  pack: PackageItem | null;
  // todo: find a way to decouple utilities and avoid passing this parameter
  tableItems: Record<string, unknown>;
}
/**
 * Package specific incompatible record checker.
 * Package has separate incompatible handler because one
 * is different from other records in terms of structure due to
 * the fact other records (like OPT/ACC/etc) are comprised in packages
 *
 * @param params {GetPackageIncompatibleRecordsParams}
 * @param params.model {Model|null} - configuration model
 * @param params.modelRules {ModelRule[]} - list of rules from model
 * @param params.pack {PackageItem} - package record
 * @param params.tableItems {Object.<string, string>} - all selected table items
 * @returns {string[]}
 */
export const getPackageIncompatibleRecords = ({
  model,
  modelRules,
  pack,
  tableItems,
}: GetPackageIncompatibleRecordsParams): string[] => {
  if (!model || !pack) return [];
  if (!model?.[ModelFields.packageLines]?.length) return [];
  const packages =
    model[ModelFields.packages]?.filter(
      pl => pl[Model_CommonItemFields.package] === pack[Model_CommonItemFields.ID],
    ) ?? [];
  const packageLines =
    model[ModelFields.packageLines]?.filter(
      pl => pl[Model_CommonItemFields.package] === pack[Model_CommonItemFields.ID],
    ) ?? [];
  const records = [...packages, ...packageLines];
  if (!records.length) return [];
  const packageLinesIdToBool: Record<string, true> = records.reduce((res, pl) => {
    res[pl[Model_CommonItemFields.ID]] = true;
    return res;
  }, {});
  const rules = getRules(modelRules);
  const incompatibilityRules = rules?.[ModelRuleValues.incompatible];
  if (isEmpty(incompatibilityRules)) return [];
  const incRules = mirrorIncRules(incompatibilityRules);
  return (
    records
      // assuming package lines [ pl1OptRef: 23, pl2OptRef: 78 ] and
      // assuming options [ opt1: 23, opt2: 56 ] and
      // assuming rules for inc. { 23: { 56 }, 56: { 23 } }
      // then group inc. record ids in a group [23, 56] and push to list - [[23, 56]]
      // Note: pl2OptRef is not tracked because it has no inc. records
      // and appropriate inc. rules for any of related records
      .reduce<string[][]>((res, pl) => accumulatePackageLines(res, pl, incRules), [])
      // there should not be the case when package can be selected
      // if incompatible de-selected record is inside
      // but possible if selected record and de-selected ones outside
      .filter(group =>
        group.some(recordId => packageLinesIdToBool[recordId] && !tableItems[recordId]),
      )
      // if all incompatible records are de-selected, un-block package
      .filter(group => !group.every(recordId => !tableItems[recordId]))
      // flat the structure based on package lines
      .reduce((res, group) => accumulateIncRecords(res, group, packageLinesIdToBool), [])
  );
};
