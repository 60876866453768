import { StructureLabel, StructureLabelFields } from '@hypercharge/xdms-client/lib/types';

/**
 * Encode model selection into ready modelNumber string
 * @param {{[p: string]: string}} selection
 * @param {StructureLabel[]} labels
 * @return {string}
 */
export const encodeModelNumber = (
  selection: Record<string, string>,
  labels: StructureLabel[],
): string => {
  if (!selection) return '';
  const modelParamsValues = Object.entries(selection).reduce<string[]>(
    (acc, [key, value]) => {
      const label = labels.find(label => label[StructureLabelFields.code] === key);

      if (label) {
        const pos = label[StructureLabelFields.structurePosition];
        const len = label[StructureLabelFields.structureCodeLength];

        const splintedValue = value.split('');

        acc.splice(pos - 1, len, ...splintedValue);
      }

      return acc;
    },
    Array(100).fill(''),
  );

  // if we will have need to fill skipped symbols with something - should add it here in .join
  return modelParamsValues.join('');
};

/**
 * Decode modelNumber to selection object
 * @param {string} modelNumber
 * @param {StructureLabel[]} labels
 * @return {{[p: string]: string}}
 */
export const decodeModelNumber = (
  modelNumber: string,
  labels: StructureLabel[],
  options?: DecodeModelNumberOptions,
): Record<string, string> => {
  const { keyField = StructureLabelFields.code } = options ?? {};

  return labels.reduce<Record<string, string>>((acc, label) => {
    const value = modelNumber.substr(
      label[StructureLabelFields.structurePosition] - 1,
      label[StructureLabelFields.structureCodeLength],
    );

    if (value) {
      const key = label[keyField];

      acc[key] = value;
    }

    return acc;
  }, {});
};

type DecodeModelNumberOptions = {
  keyField?: StructureLabelFields;
};
