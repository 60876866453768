import { ModelRecordType, ModelRuleValues } from 'types/vendor';

export const RulesModalCoveredRules = {
  obligatoryOne: ModelRuleValues.obligatoryOne,
  obligatory: ModelRuleValues.obligatory,
  replaceable: ModelRuleValues.replaceable,
  related: ModelRuleValues.related,
};

export const commonItemType__mapping__translationKey = {
  [ModelRecordType.accessory]: 'MODAL_RULES__COLUMN_ENTITY_ACCESSORY',
  [ModelRecordType.option]: 'MODAL_RULES__COLUMN_ENTITY_OPTION',
  [ModelRecordType.packageLine]: 'MODAL_RULES__COLUMN_ENTITY_PACKAGELINE',
};
