import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const ScRotatingLoader = styled(LoadingOutlined)`
  && {
    font-size: 2em;
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.dustyGray : theme.mainColor};
  }
`;
