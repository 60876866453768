import { Tabs as AntTabs } from 'antd';
import { mediaMax } from 'common/theme';
import styled from 'styled-components';

const ScTabs = styled(AntTabs)`
  width: 100%;
  overflow: visible;

  &.huge-tabs {
    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-tabs-tab {
      border-right: unset;
    }
    .ant-tabs-nav::before {
      display: none;
    }
  }

  &.stretch-tabs {
    &.ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid ${({ theme }) => theme.colors.softPeach};
    }
    .ant-tabs-tab {
      margin-left: 0;
      width: 100%;
      border-right: 0;
      border-left: 1px solid ${({ theme }) => theme.colors.softPeach};
      white-space: pre-wrap;

      &:first-child {
        border-left: none;
      }
    }
  }

  .ant-tabs-nav-wrap {
    align-items: stretch;
    flex: 1;
    overflow: hidden;
  }

  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.mainColor};
  }

  .ant-tabs-tab {
    justify-content: center;
    margin-right: 0;
    padding: 1.75em 2.5em;
    border-right: 1px solid ${({ theme }) => theme.colors.softPeach};
    font-weight: 600;

    ${mediaMax.sm} {
      padding: 1em 2em;
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.textColor};
      }
    }

    .ant-tabs-tab-btn {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.dustyGray};
    }
  }

  &.button-styled {
    &.ant-tabs-top > .ant-tabs-nav::before,
    &.ant-tabs-top > div > .ant-tabs-nav::before {
      border-bottom: none;
    }

    .ant-tabs-nav-list {
      justify-content: center;

      .ant-tabs-tab {
        padding: unset;
        margin: 0 1em;
        border: unset;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        .ant-tabs-tab-btn {
          padding: 1em 2em;
          background-color: ${props => props.theme.colors.white};
          border-color: ${props => props.theme.colors.white};
          color: ${props => props.theme.textColor};
        }

        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          border-color: ${props => props.theme.mainColor};
          background-color: ${props => props.theme.mainColor};
          color: ${props => props.theme.mainColor_contrast};

          &:hover {
            border-color: ${props => props.theme.mainColor_hovered};
            background-color: ${props => props.theme.mainColor_hovered};
            color: ${props => props.theme.mainColor_contrast};
          }
          &:focus {
            border-color: ${props => props.theme.mainColor_contrast};
            background-color: ${props => props.theme.mainColor_pressed};
            color: ${props => props.theme.mainColor_contrast};
          }
        }
      }
    }
  }
`;

export default ScTabs;
