import {
  ModelUpdateAttributesObjectFields,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Row, Tooltip } from 'antd';
import { Button } from 'components/button/Button';
import { Modal, ModalHeader } from 'components/modal';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import { ModelRecordRules } from 'pages/DynamicPage/types';
import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { Status } from 'utils/types';
import { RulesModalCoveredRules } from './RulesModal.meta';
import {
  assembleObligatoryState,
  ObligatoryState,
  ObligatoryStateItem,
  useObligatoryColumns,
} from './RulesModal.meta.obligatory';
import {
  ObligatoryOneActionType,
  obligatoryOneReducer,
  ObligatoryOneStateItem,
  useObligatoryOneColumns,
} from './RulesModal.meta.obligatoryOne';
import {
  RelatedActionType,
  relatedReducer,
  RelatedStateItem,
  useRelatedColumns,
} from './RulesModal.meta.related';
import {
  assembleReplaceablesState,
  useReplaceablesColumns,
} from './RulesModal.meta.replaceables';
import {
  ScButtonsContainer,
  ScContainer,
  ScTable,
  ScSectionTitle,
} from './RulesModal.styles';

export type RulesModalCallParams = Pick<RulesModalProps, 'rules' | 'option'>;

export type RulesModalProps = {
  onClose(): void;
  option: Model_CommonItem;
  rules: ModelRecordRules;
};

export const RulesModal: FC<RulesModalProps> = ({ onClose, rules, option }) => {
  const { t } = useTranslation();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();

  const { model, status, modelRules } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const isLoading = useMemo(() => status === Status.Loading, [status]);

  /** + Obligatory one rule handling */
  const [obligatoryOneState, obligatoryOneDispatch] = useReducer(
    obligatoryOneReducer,
    [],
  );

  useEffect(() => {
    const modelItemsIdsList: string[] | undefined =
      rules[RulesModalCoveredRules.obligatoryOne];
    if (!model || !modelItemsIdsList?.length) return;

    obligatoryOneDispatch({
      type: ObligatoryOneActionType.init,
      model: model,
      modelItemsIdsList: modelItemsIdsList,
    });
  }, [model, rules]);

  const obligatoryOneColumns = useObligatoryOneColumns();
  /** - Obligatory one rule handling */

  /** + Obligatory rule handling */
  const [obligatoryItemsList, setObligatoryItemsList] = useState<ObligatoryState>([]);

  useEffect(() => {
    const modelItemsIdsList: string[] | undefined =
      rules[RulesModalCoveredRules.obligatory];
    if (!model || !modelItemsIdsList?.length) return;

    setObligatoryItemsList(assembleObligatoryState(model, modelItemsIdsList));
  }, [model, rules]);

  const obligatoryColumns = useObligatoryColumns();
  /** - Obligatory rule handling */

  /** + Replaceables rule handling */
  const [replaceablesItemsList, setReplaceablesItemsList] = useState<
    (Model_CommonItem & { key: string })[]
  >([]);

  useEffect(() => {
    const modelItemsIdsList: string[] | undefined =
      rules[RulesModalCoveredRules.replaceable];
    if (!model || !modelItemsIdsList?.length) return;

    setReplaceablesItemsList(assembleReplaceablesState(model, modelItemsIdsList));
  }, [model, rules]);

  const replaceablesColumns = useReplaceablesColumns();
  /** - Replaceables rule handling */

  /** + Related rule handling */
  const [relatedState, relatedDispatch] = useReducer(relatedReducer, []);

  useEffect(() => {
    const modelItemsIdsList: string[] | undefined = rules[RulesModalCoveredRules.related];
    if (!model || !modelItemsIdsList?.length) return;

    relatedDispatch({
      type: RelatedActionType.init,
      model: model,
      modelRules: modelRules,
      modelItemsIdsList: modelItemsIdsList,
    });
  }, [model, rules, modelRules]);

  const relatedColumns = useRelatedColumns();
  /** - Related rule handling */

  const isSubmitButtonDisabled = useMemo<boolean>(() => {
    const isObligatoryOneOk = obligatoryOneState.length
      ? obligatoryOneState.some(({ selected }) => selected)
      : true;

    const isObligatoryOk = obligatoryItemsList.length
      ? obligatoryItemsList.every(({ selected }) => selected)
      : true;

    return !(isObligatoryOneOk && isObligatoryOk);
  }, [obligatoryItemsList, obligatoryOneState]);

  const handleSubmit = useCallback(async () => {
    if (!model) return;

    let modelToUse = model;

    const obligatoryOneSelectedItem = obligatoryOneState.find(
      ({ selected, disabled }) => !disabled && selected,
    );
    if (obligatoryOneSelectedItem) {
      const { response } = await updateModel(modelToUse, {
        [ModelUpdateAttributesObjectFields.commonItem]: {
          ...obligatoryOneSelectedItem.item,
          [Model_CommonItemFields.selected]: true,
        },
      });
      if (response) modelToUse = response;
    }

    const relatedSelectedItems = relatedState?.filter(
      ({ selected, disabled }) => selected && !disabled,
    );
    if (relatedSelectedItems?.length) {
      for (const item of relatedSelectedItems) {
        const { response } = await updateModel(modelToUse, {
          [ModelUpdateAttributesObjectFields.commonItem]: {
            ...item.record,
            [Model_CommonItemFields.selected]: true,
          },
        });
        if (response) modelToUse = response;
      }
    }

    await updateModel(modelToUse, {
      [ModelUpdateAttributesObjectFields.commonItem]: {
        ...option,
        [Model_CommonItemFields.selected]: true,
      },
    });

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }

    onClose();
  }, [
    model,
    obligatoryOneState,
    relatedState,
    updateModel,
    option,
    sendMessage,
    modelToken,
    onClose,
  ]);

  return (
    <Modal variant="md" visible onCancel={onClose}>
      <ModalHeader>
        {t('MODAL_RULES__TITLE', {
          optionName: option[Model_CommonItemFields.name],
        })}
      </ModalHeader>
      <ScContainer>
        {Boolean(replaceablesItemsList.length) && (
          <>
            <ScSectionTitle>{t('MODAL_RULES__TITLE_REPLACEABLES')}</ScSectionTitle>
            <ScTable
              rowSelection={{
                selectedRowKeys: [],
                type: 'checkbox',
                getCheckboxProps: () => ({
                  disabled: true,
                }),
              }}
              columns={replaceablesColumns}
              data={replaceablesItemsList}
            />
          </>
        )}

        {Boolean(obligatoryItemsList.length) && (
          <>
            <ScSectionTitle>{t('MODAL_RULES__TITLE_OBLIGATORY')}</ScSectionTitle>
            <ScTable
              rowSelection={{
                selectedRowKeys: obligatoryItemsList
                  .filter(({ selected }) => selected)
                  .map(({ key }) => key),
                type: 'checkbox',
                getCheckboxProps: () => ({
                  disabled: true,
                }),
                renderCell: (value, record: ObligatoryStateItem, index, originNode) => {
                  if (!record.selected && record.relatedPackage) {
                    return (
                      <Tooltip
                        title={t('MODAL_RULES__WARNING_OBLIGATORY__PACKAGE_LINE', {
                          packageName:
                            record.relatedPackage?.[Model_CommonItemFields.name],
                          optionName: record.item[Model_CommonItemFields.name],
                        })}
                      >
                        {originNode}
                      </Tooltip>
                    );
                  }

                  return originNode;
                },
              }}
              columns={obligatoryColumns}
              data={obligatoryItemsList}
            />
          </>
        )}

        {Boolean(obligatoryOneState.length) && (
          <>
            <ScSectionTitle>{t('MODAL_RULES__TITLE_OBLIGATORY_ONE')}</ScSectionTitle>
            <ScTable
              rowSelection={{
                selectedRowKeys: obligatoryOneState
                  .filter(({ selected }) => selected)
                  .map(({ key }) => key),
                getCheckboxProps: record => ({
                  disabled: record.disabled,
                }),
                type: 'checkbox',
                onChange: selectedRowKeys => {
                  obligatoryOneDispatch({
                    type: ObligatoryOneActionType.select,
                    itemId: selectedRowKeys[selectedRowKeys.length - 1] as string,
                  });
                },
                renderCell: (
                  value,
                  record: ObligatoryOneStateItem,
                  index,
                  originNode,
                ) => {
                  if (record.disabled && !record.selected && record.relatedPackage) {
                    return (
                      <Tooltip
                        title={t('MODAL_RULES__WARNING_OBLIGATORY_ONE__PACKAGE_LINE', {
                          packageName:
                            record.relatedPackage?.[Model_CommonItemFields.name],
                          optionName: record.item[Model_CommonItemFields.name],
                        })}
                      >
                        {originNode}
                      </Tooltip>
                    );
                  }

                  return originNode;
                },
              }}
              columns={obligatoryOneColumns}
              data={obligatoryOneState}
            />
          </>
        )}

        {Boolean(relatedState.length) && (
          <>
            <ScSectionTitle>{t('MODAL_RULES__TITLE_RELATED')}</ScSectionTitle>
            <ScTable
              rowSelection={{
                selectedRowKeys: relatedState
                  .filter(({ selected }) => selected)
                  .map(({ key }) => key),
                getCheckboxProps: record => ({
                  disabled: record.disabled,
                }),
                type: 'checkbox',
                onSelect: selectedRecord => {
                  relatedDispatch({
                    type: RelatedActionType.select,
                    key: selectedRecord.key,
                  });
                },
                renderCell: (value, record: RelatedStateItem, index, originNode) => {
                  if (
                    record.disabled &&
                    (record.incompatibleRecords ||
                      record.isPackage ||
                      record.isPackageLine)
                  ) {
                    let message = '';

                    if (record.isPackageLine) {
                      message = t('MODAL_RULES__WARNING_RELATED_PACKAGE_LINE', {
                        packageName: record.parentPackage?.[Model_CommonItemFields.name],
                        optionName: record.record[Model_CommonItemFields.name],
                      });
                    }

                    if (record.isPackage) {
                      message = t('MODAL_RULES__WARNING_RELATED_PACKAGE');
                    }

                    if (record.incompatibleRecords) {
                      const names = record.incompatibleRecords
                        .map(record => record[Model_CommonItemFields.ID])
                        .join(', ');

                      message = t(`${t('TABLE_INCOMPATIBLE')} ${names}`);
                    }

                    return <Tooltip title={message}>{originNode}</Tooltip>;
                  }

                  return originNode;
                },
              }}
              columns={relatedColumns}
              data={relatedState}
            />
          </>
        )}

        <ScButtonsContainer>
          <Row gutter={[10, { xs: 0, sm: 10, md: 0 }]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8, offset: 8 }}>
              <Button onClick={onClose} fullwidth>
                {t('MODAL_CLOSE')}
              </Button>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                fullwidth
                variant={'primary'}
                disabled={isSubmitButtonDisabled}
              >
                {t('SAVE')}
              </Button>
            </Col>
          </Row>
        </ScButtonsContainer>
      </ScContainer>
    </Modal>
  );
};
