import { mediaMax } from 'common/theme';
import styled from 'styled-components';

export const ScTableInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mediaMax.sm} {
    padding: 0 1em;
  }

  > div:last-child {
    width: auto;
  }

  .ant-input-number {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.875em;

    &:hover {
      border-color: ${({ theme }) => theme.mainColor};
    }
  }
`;

export const ScTableInputPrefix = styled.div`
  margin-right: 0.375em;
  color: ${({ theme }) => theme.colors.black};
`;
