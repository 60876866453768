import * as Yup from 'yup';
import { SoftOfferVehicle, SoftOfferVehicleFields } from 'types/vendor';
import { TFunction } from 'i18next';
import { SOFT_OFFER_VEHICLE_TRAILER1TYPE_BLANK_VALUE } from 'common/constants';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<SoftOfferVehicle>>({
    [SoftOfferVehicleFields.purchaseAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    [SoftOfferVehicleFields.maximumMileagePerYearCategory]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferVehicleFields.odometerActualReadingAt]: Yup.string().nullable(),
    [SoftOfferVehicleFields.safetyFeaturesCount]: Yup.number(),
    [SoftOfferVehicleFields.odometerActualReadingQuantity]: Yup.number(),
    [SoftOfferVehicleFields.brand]: Yup.string(),
    [SoftOfferVehicleFields.model]: Yup.string(),
    [SoftOfferVehicleFields.chassis]: Yup.string(),
    [SoftOfferVehicleFields.immatriculationPlate]: Yup.string(),
    [SoftOfferVehicleFields.fuelCode]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferVehicleFields.type]: Yup.string(),
    [SoftOfferVehicleFields.power]: Yup.number().min(1).required(t('FORM__REQUIRED')),
    [SoftOfferVehicleFields.isAllRoad]: Yup.boolean(),
    [SoftOfferVehicleFields.isConvertibleIndicator]: Yup.boolean(),
    [SoftOfferVehicleFields.firstImmatriculationAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    [SoftOfferVehicleFields.contractStartAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    [SoftOfferVehicleFields.usage]: Yup.string(),
    [SoftOfferVehicleFields.enterpriseType]: Yup.string(),
    [SoftOfferVehicleFields.alarmCode]: Yup.string(),
    [SoftOfferVehicleFields.kindOfBuy]: Yup.string(),
    [SoftOfferVehicleFields.remarque]: Yup.string(),
    [SoftOfferVehicleFields.trailer1Type]: Yup.string(),
    [SoftOfferVehicleFields.maximumAuthorisedWeightQuantity]: Yup.number().when(
      SoftOfferVehicleFields.trailer1Type,
      {
        is: (value: string) => value !== SOFT_OFFER_VEHICLE_TRAILER1TYPE_BLANK_VALUE,
        then: Yup.number()
          .min(1, t('INVALID_NUMBER_MIN', { v: 1 }))
          .required(t('FORM__REQUIRED')),
      },
    ),
  });
};
