import { Button } from 'components/button';
import styled from 'styled-components';

export const ScUserButton = styled(Button.DropdownTrigger)`
  align-self: center;
  border: none;

  &[ant-click-animating-without-extra-node='true']::after {
    display: none;
  }

  outline: none;
  border: none;

  &:focus,
  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.black};
    outline: none;
    border: none;
  }
`;
