import {
  Configuration_GetDetailsById_Output_Configuration_Fields,
  ModelFields,
  TotalsItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { ConfDetailsFields, CustomerFields } from 'types/vendor';
import {
  DEFAULT_IMPORT_LINK_LANGUAGE_CODE,
  IMPORT_TOKEN_SEPARATOR,
} from 'common/constants';
import { sizes } from 'common/theme';
import React, { FC, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { get } from 'utils';
import MobileMenu from './MobileMenu';
import Order from './Order';
import User from './user/User';
import { useRelations } from 'context/relations/RelationsProvider';
import {
  Separator,
  HeaderContent,
  HeaderWrapper,
  LogoWrap,
  LogoImg,
  LogoContainer,
  ScHeaderActions,
  ScModelName,
  ScGoToImportSource,
  PageWidthWrapper,
  ScPublishConfigurationStatus,
  ScLockOutlined,
  ScHeaderIdsGroup,
  ScHeaderIdsRow,
  ScHeaderIdText,
  ScHeaderModelDetailsGroup,
  ScHeaderModelDetailsRow,
  ScVatSwitcherWrapper,
  ScHeaderModelDetailsNoWrap,
  ScHeaderModeDetailsCentered,
} from './Header.styles';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  configurationSelectors,
  featuresFlagsSelectors,
  modelSelectors,
  sharedActions,
  sharedSelectors,
} from 'store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { hostSettingsSelectors } from 'store/hostSettings';
import { assembleImportLocation } from '../../../common/assemblers';
import { Tooltip } from 'antd';
import { Status } from 'utils/types';
import { RotatingLoader } from 'components/RotatingLoader';
import Switcher from 'components/form/Switcher';
import { useStep } from 'context/step/StepProvider';
import ProductsDrawer from './ProductsDrawer';
import CarPreview from './CarPreview';

export const Header: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { relations } = useRelations();
  const { isFeatureEnabled } = useFeature();
  const { handleStepClick } = useStep();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const customerId = useSelector(hostSettingsSelectors.getCustomerId);
  const hostLogo = useSelector(hostSettingsSelectors.getPrimaryLogo);
  const {
    model,
    isConfigurationComplete,
    status: modelStatus,
  } = useSelector(modelSelectors.getAll);
  const importToken = useSelector(modelSelectors.getToken);
  const {
    configurationNumber,
    configurationDetails,
    configurationModelName,
    status: configurationStatus,
    modelNumber,
  } = useSelector(configurationSelectors.getAll);

  const { isModelConfigured } = useSelector(modelSelectors.getAll);

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const { availableLanguages } = useSelector(hostSettingsSelectors.getLanguageSettings);

  const isMdAndWider = useMediaQuery({ minWidth: sizes.sm });
  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  const isShowModelNumberHeaderFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowShowModelNumberHeader,
  });
  const isShowHeaderProspectDossierIDFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowShowHeaderProspectDossierID,
  });

  const isShowLoopendFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowLoopend,
  });

  const isAllowHeaderGalleryFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderGalleryEnabled,
  });

  const toggleShouldShowPricesWithVAT = useCallback(() => {
    dispatch(sharedActions.setShouldShowPricesWithVAT(!shouldShowPricesWithVAT));
  }, [dispatch, shouldShowPricesWithVAT]);

  // replacement of `client`. TODO Should ask BL if it is fine to use first relation here
  const firstCustomer = relations[0]?.customer;

  const userName = useMemo(() => {
    const secondName = get(firstCustomer, CustomerFields.name2, '');
    const firstName = get(firstCustomer, CustomerFields.name, '');

    return [firstName, secondName].filter(Boolean).join(' ');
  }, [firstCustomer]);

  const configurationNumberRender = useMemo(() => {
    return (
      configurationDetails?.configuration[ConfDetailsFields.ownNumber] ||
      configurationDetails?.configuration[ConfDetailsFields.id] ||
      ''
    );
  }, [configurationDetails]);

  const isConfigurationImportFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowConfigurationImport,
  });

  const importLinkLanguageCode = useMemo(() => {
    return (
      availableLanguages?.find(({ code }) => code === i18n.language)?.importCode ??
      DEFAULT_IMPORT_LINK_LANGUAGE_CODE
    );
  }, [availableLanguages, i18n.language]);

  const importUrl = useMemo<string | null>(() => {
    const endpoint =
      globalFeatures?.[GlobalFeaturesFlagsFields.ConfigurationImportSource];
    if (!endpoint || !importToken) return null;

    const [firstTokenPart] = importToken.split(IMPORT_TOKEN_SEPARATOR);

    return assembleImportLocation(endpoint, importLinkLanguageCode, firstTokenPart);
  }, [globalFeatures, importLinkLanguageCode, importToken]);

  const totalData = useMemo(() => get(model, `${ModelFields.totals}.0`), [model]);

  const total = useMemo<number | undefined>(() => {
    const field =
      globalFeatures?.[GlobalFeaturesFlagsFields.HeaderTotalField] ||
      TotalsItemFields.totalNettoPrice;
    return get(totalData, field);
  }, [globalFeatures, totalData]);

  const totalWithVAT = useMemo<number | undefined>(() => {
    const field =
      globalFeatures?.[GlobalFeaturesFlagsFields.HeaderTotalField_withVAT] ||
      TotalsItemFields.totalNettoPriceWithVAT;
    return get(totalData, field);
  }, [globalFeatures, totalData]);

  const prospectDossierId =
    configurationDetails?.configuration[
      Configuration_GetDetailsById_Output_Configuration_Fields.projectId
    ];

  return (
    <HeaderWrapper id="header" data-testid="header-container">
      <PageWidthWrapper data-testid="header-container-wrapper">
        <HeaderContent
          className="cp-c-row cp-c-align-spacebetween-center"
          data-testid="header-content-container"
        >
          <>
            <LogoContainer
              className="cp-show-flex cp-c-align-spacebetween-center"
              data-testid="header-logo-container"
            >
              <Link
                to={`/clients${get(window, 'location.search')}`}
                data-testid="header-logo-link"
                onClick={() => handleStepClick(0)}
              >
                <LogoWrap className="cp-c-none" data-testid="header-logo-image-wrapper">
                  <LogoImg
                    data-testid="header-logo"
                    src={hostLogo}
                    alt={customerId ?? 'Logo'}
                  />
                </LogoWrap>
              </Link>
              {isConfigurationComplete && (
                <ScPublishConfigurationStatus>
                  <Tooltip
                    placement="bottom"
                    title={t('HEADER_PUBLISHED_CONFIGURATION_LOCK')}
                  >
                    <ScLockOutlined />
                  </Tooltip>
                </ScPublishConfigurationStatus>
              )}
              {isConfigurationImportFeatureEnabled && importUrl && (
                <Tooltip placement="bottom" title={t('GO_TO_IMPORT_SOURCE')}>
                  <ScGoToImportSource target="_blank" href={importUrl} rel="noreferrer">
                    <GlobalOutlined />
                  </ScGoToImportSource>
                </Tooltip>
              )}

              {isAllowHeaderGalleryFeatureEnabled &&
                configurationDetails &&
                isModelConfigured && <CarPreview />}

              <ScHeaderIdsGroup>
                {configurationStatus === Status.Loading ? (
                  <RotatingLoader />
                ) : (
                  <>
                    {configurationNumberRender && (
                      <ScHeaderIdsRow>
                        <ScHeaderIdText>{t('HEADER_OFFER_ID')}:</ScHeaderIdText>
                        <ScHeaderIdText data-testid="header-offer-id">
                          {configurationNumberRender}
                        </ScHeaderIdText>
                      </ScHeaderIdsRow>
                    )}
                    {isShowHeaderProspectDossierIDFeatureEnabled &&
                      Boolean(prospectDossierId) && (
                        <ScHeaderIdsRow>
                          <ScHeaderIdText>
                            {t('HEADER_PROSPECT_DOSSIER_ID')}:
                          </ScHeaderIdText>
                          <ScHeaderIdText>{prospectDossierId}</ScHeaderIdText>
                        </ScHeaderIdsRow>
                      )}
                  </>
                )}
              </ScHeaderIdsGroup>
            </LogoContainer>
            {isMdAndWider &&
              (configurationStatus === Status.Loading ||
                userName ||
                modelNumber ||
                (isShowModelNumberHeaderFeatureEnabled && configurationModelName)) && (
                <ScModelName data-testid="header-customer-and-model-name">
                  <ScHeaderModelDetailsGroup>
                    {configurationStatus === Status.Loading ? (
                      <RotatingLoader />
                    ) : (
                      <>
                        <ScHeaderModelDetailsRow>
                          {userName && (
                            <ScHeaderModelDetailsNoWrap>
                              {userName}
                            </ScHeaderModelDetailsNoWrap>
                          )}
                          {modelNumber && (
                            <ScHeaderModelDetailsNoWrap>
                              {' - ' + modelNumber}
                            </ScHeaderModelDetailsNoWrap>
                          )}
                        </ScHeaderModelDetailsRow>
                        {isShowModelNumberHeaderFeatureEnabled && configurationModelName && (
                          <ScHeaderModelDetailsRow>
                            <ScHeaderModeDetailsCentered>
                              {configurationModelName}
                            </ScHeaderModeDetailsCentered>
                          </ScHeaderModelDetailsRow>
                        )}
                      </>
                    )}
                  </ScHeaderModelDetailsGroup>
                </ScModelName>
              )}
          </>
          {isLgAndWider && (
            <>
              <ScVatSwitcherWrapper>
                <Switcher
                  alignment="row"
                  value={shouldShowPricesWithVAT}
                  onClick={toggleShouldShowPricesWithVAT}
                  title={t(shouldShowPricesWithVAT ? 'VAT' : 'VAT_DISABLED')}
                />
              </ScVatSwitcherWrapper>
              <Separator />
            </>
          )}

          <ScHeaderActions data-testid="header-actions-container">
            {Boolean(modelStatus === Status.Loading || total || totalWithVAT) && (
              <>
                <Order
                  total={total}
                  totalWithVAT={totalWithVAT}
                  isLoading={modelStatus === Status.Loading}
                />
                <Separator />
              </>
            )}
            {isShowLoopendFeatureEnabled && (
              <>
                <ProductsDrawer />
                <Separator />
              </>
            )}
            {isLgAndWider ? <User /> : <MobileMenu />}
          </ScHeaderActions>
        </HeaderContent>
      </PageWidthWrapper>
    </HeaderWrapper>
  );
};
