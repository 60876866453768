import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LanguageFields, Language } from 'types/vendor';
import { Status } from 'utils/types';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { notification } from 'utils/notification';
import { RequestResponseDetails } from 'types/common';
import { useSelector } from 'react-redux';
import { hostSettingsSelectors } from '../../store';

type ContextValue = {
  languages: Language[];
  languagesFiltered: Language[];
  status: Status;
  getLanguages(): Promise<RequestResponseDetails<Language[]>>;
};

const LanguageContext = React.createContext<ContextValue | undefined>(undefined);

const LanguageProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const { availableLanguages } = useSelector(hostSettingsSelectors.getLanguageSettings);

  const [status, setStatus] = useState(Status.Idle);
  const [languages, setLanguages] = useState<ContextValue['languages']>([]);

  const languagesFiltered = useMemo<ContextValue['languagesFiltered']>(() => {
    const availableLanguagesCodesList = availableLanguages?.map(({ code }) => code) ?? [];

    return languages.filter(language => {
      const code = language[LanguageFields.shortLanguageCode].toLowerCase();
      return availableLanguagesCodesList.includes(code);
    });
  }, [languages, availableLanguages]);

  const getLanguages = useCallback<ContextValue['getLanguages']>(async (): Promise<
    RequestResponseDetails<Language[]>
  > => {
    let result: RequestResponseDetails<Language[]> = { status: Status.Idle };

    try {
      setStatus(Status.Loading);
      const { messages, records } = await xdmsClient.languages.getList([]);

      setLanguages(records);
      setStatus(Status.Success);

      result = { status: Status.Success, response: records };

      if (messages.length) {
        notification.open({ message: messages });
        result.messageHandled = true;
      }
    } catch (e) {
      notification.requestError(e, setStatus);

      result = {
        status: Status.Error,
        messageHandled: true,
      };
    }

    return result;
  }, [xdmsClient]);

  const value = useMemo(
    () => ({
      languages,
      status,
      getLanguages,
      languagesFiltered,
    }),
    [languages, status, getLanguages, languagesFiltered],
  );

  return <LanguageContext.Provider {...props} value={value} />;
};

const useLanguage = (): ContextValue => {
  const context: ContextValue | undefined = useContext(LanguageContext);

  const getLanguages = context?.getLanguages;

  // Will trigger in used component at first render.
  // Instead manually asking in each component.
  useEffect(() => {
    getLanguages?.();
  }, [getLanguages]);

  if (context === undefined) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
};

export { LanguageProvider, useLanguage };
