import React, { FC, ReactComponentElement } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as Total } from '../../assets/icons/total.svg';
import classNames from 'classnames';
import { ReactComponent as Customer } from '../../assets/icons/customer.svg';
import { ReactComponent as Document } from '../../assets/icons/document.svg';
import { ReactComponent as TradeIn } from '../../assets/icons/tradein.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as SoftOffer } from '../../assets/icons/soft-offer.svg';
import { ReactComponent as Tax } from '../../assets/icons/tax.svg';

interface StepNumberIcon {
  [key: string]: unknown;
  className?: string;
}

export const TotalIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={Total}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);

export const CustomerIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={Customer}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);

export const DocumentIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={Document}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);

export const TradeInIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={TradeIn}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);

export const InfoIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={Info}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);

export const SoftOfferIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={SoftOffer}
    {...props}
    className={classNames(className, {
      'step-number-icon': true,
      'step-number-icon--soft-offer': true,
    })}
  />
);

export const TaxIcon: FC<StepNumberIcon> = ({
  className,
  ...props
}): ReactComponentElement<'svg'> => (
  <Icon
    component={Tax}
    {...props}
    className={classNames(className, { 'step-number-icon': true })}
  />
);
