import Icon from '@ant-design/icons';
import {
  ModelCommercialCommentFields,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Row, Tooltip } from 'antd';
import { ReactComponent as CommercialSvgIcon } from 'assets/icons/comment-commercial-icon.svg';
import { ReactComponent as ExternalSvgIcon } from 'assets/icons/comment-external-icon.svg';
import { ReactComponent as InternalSvgIcon } from 'assets/icons/comment-internal-icon.svg';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { get } from 'utils';
import { CommentsModalTabs } from 'utils/constants';

export const ScCommentIcon = styled.div`
  font-size: 1.375em;
  cursor: pointer;

  svg path:nth-child(2) {
    fill: ${({ theme }) => theme.colors.white};
  }

  &.active svg path:nth-child(2) {
    fill: ${({ theme }) => theme.mainColor};
  }

  &.active svg path:nth-child(4) {
    fill: ${({ theme }) => theme.mainColor_contrast};
  }
`;

const ScCol = styled(Col)`
  max-width: 2em;
  padding-right: 5px;
`;

type CommentIconT = {
  onClick(tabName: CommentsModalTabs): void;
  currentOption: Model_CommonItem;
};

export default (props: CommentIconT) => {
  const { onClick, currentOption } = props;
  const { t } = useTranslation();

  /** checkout {@link CommentsModal -> STEP_SETTINGS} */
  const icons = [
    {
      title: t('COMMERCIAL_TAB_PANE'),
      component: CommercialSvgIcon,
      tabName: CommentsModalTabs.commercial,
      fieldName: ModelCommercialCommentFields.sysLink,
      testName: `dynamic-step-table-item-comment-commercial-btn-${get(
        currentOption,
        Model_CommonItemFields.ID,
        '',
      )}`,
    },
    {
      title: t('INTERNAL_TAB_PANE'),
      component: InternalSvgIcon,
      tabName: CommentsModalTabs.internal,
      fieldName: Model_CommonItemFields.optionName,
      additionalFieldName: Model_CommonItemFields.internalCommentDescription,
      testName: `dynamic-step-table-item-comment-internal-btn-${get(
        currentOption,
        Model_CommonItemFields.ID,
        '',
      )}`,
    },
    {
      title: t('EXTERNAL_TAB_PANE'),
      component: ExternalSvgIcon,
      tabName: CommentsModalTabs.external,
      fieldName: Model_CommonItemFields.sellingInformation,
      additionalFieldName: Model_CommonItemFields.externalCommentDescription,
      testName: `dynamic-step-table-item-comment-external-btn-${get(
        currentOption,
        Model_CommonItemFields.ID,
        '',
      )}`,
    },
  ];

  return (
    <Row data-testid="dynamic-step-table-item-comment-icons-container">
      {icons.map(
        (
          { title, tabName, component, fieldName, additionalFieldName = '', testName },
          i,
        ) => (
          <ScCol xs={24} xl={8} key={title}>
            <Tooltip placement="bottom" title={title} key={`${i}`}>
              <ScCommentIcon
                onClick={() => onClick(tabName)}
                className={classNames({
                  active:
                    get(currentOption, fieldName, false) ||
                    get(currentOption, additionalFieldName, false),
                })}
                data-testid={testName}
              >
                <Icon component={component} data-testid={`${testName}-icon`} />
              </ScCommentIcon>
            </Tooltip>
          </ScCol>
        ),
      )}
    </Row>
  );
};
