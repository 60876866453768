import styled from 'styled-components';
import { ReactComponent as NoImageIcon } from 'assets/icons/no-image.svg';

export const ScNoImageWrapper = styled.div<{
  $height: number | undefined;
  $ratio: string | undefined;
  $transparent: boolean | undefined;
}>`
  background-color: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.alternativeColor};
  height: ${({ $height }) => (typeof $height === 'number' ? $height + 'px' : 'auto')};
  box-shadow: ${({ theme }) => theme.shadows.noImage};
  aspect-ratio: ${({ $ratio }) => $ratio ?? 'auto'};
`;

export const SсNoImageContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ScNoImageIconWrapper = styled.div``;

export const ScNoImageText = styled.p`
  color: ${({ theme }) => theme.colors.softPeach};
  line-height: 1;
  margin: 0;
  position: absolute;
  bottom: -1em;
`;

export const ScNoImageIcon = styled(NoImageIcon)`
  vertical-align: middle;
  width: 100%;
  line-height: 0;
`;
