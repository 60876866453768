import { RotatingLoader } from 'components/RotatingLoader';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/format';
import { ScOrder, ScOrderText } from './Order.styles';

type OrderProps = {
  total: number | undefined;
  totalWithVAT: number | undefined;
  isLoading?: boolean;
};

export const Order: FC<OrderProps> = ({ total, totalWithVAT, isLoading = false }) => {
  const { t, i18n } = useTranslation();

  return (
    <ScOrder data-testid="header-order-container">
      {isLoading ? (
        <RotatingLoader />
      ) : (
        <div className="card-total" data-testid="header-order-content">
          {totalWithVAT && (
            <ScOrderText data-testid="header-order-total-net-price">
              {`${t('TOTAL_INCL')} : `}
              {formatPrice({
                locale: i18n.language,
                price: totalWithVAT,
                style: 'decimal',
              })}
            </ScOrderText>
          )}
          {total && (
            <ScOrderText data-testid="header-order-total-price">
              {`${t('TOTAL_EXCL')} : `}
              {formatPrice({
                locale: i18n.language,
                price: total,
                style: 'decimal',
              })}
            </ScOrderText>
          )}
        </div>
      )}
    </ScOrder>
  );
};
