import { InputNumber } from 'components/form/InputNumber';
import React, { FC, useCallback, ComponentProps } from 'react';
import {
  numberWithCurrencySymbolDynamicFormatter,
  numberWithCurrencySymbolParser,
} from 'utils/format/price';
import { useTranslation } from 'react-i18next';

// with type='number' special characters are getting ignored
interface Props
  extends Omit<ComponentProps<typeof InputNumber>, 'type' | 'formatter' | 'parser'> {
  symbolsAfterDecimal?: number;
  valueSuffix?: string;
  valuePrefix?: string;
}

export const InputNumberLocalized: FC<Props> = ({
  symbolsAfterDecimal,
  valueSuffix,
  valuePrefix,
  ...props
}) => {
  const { i18n } = useTranslation();

  const formatter = useCallback<
    NonNullable<ComponentProps<typeof InputNumber>['formatter']>
  >(
    (value, rawValue) => {
      return numberWithCurrencySymbolDynamicFormatter({
        value,
        locale: i18n.language,
        symbolsAfterDecimal,
        rawValue: rawValue,
        suffix: valueSuffix,
        prefix: valuePrefix,
      });
    },
    [i18n.language, symbolsAfterDecimal, valueSuffix, valuePrefix],
  );

  const parser = useCallback<NonNullable<ComponentProps<typeof InputNumber>['parser']>>(
    value => {
      return numberWithCurrencySymbolParser({
        value,
        locale: i18n.language,
        symbolsAfterDecimal,
        suffix: valueSuffix,
        prefix: valuePrefix,
      });
    },
    [i18n.language, symbolsAfterDecimal, valueSuffix, valuePrefix],
  );

  return <InputNumber {...props} formatter={formatter} parser={parser} />;
};
