import React, { FC, useCallback, useState, ReactNode, ComponentProps } from 'react';
import { ConfigurationRelation } from 'context/relations/types';
import { useTranslation } from 'react-i18next';
import { ListItem } from './ListItem';
import {
  ScButton,
  ScEmpty,
  ScFooter,
  ScIconWrap,
  ScSwapList as ScList,
  ScTitle,
} from './styles';
import { Modal } from 'components/modal';

interface Props
  extends Omit<ComponentProps<typeof Modal>, 'variant' | 'visible' | 'onCancel'> {
  active?: ConfigurationRelation;
  options: ConfigurationRelation[];
  onSubmit(relationId: string): void;
  onClose(): void;
  title: string;
  icon: ReactNode;
}

export const BaseRelationTransactionModal: FC<Props> = ({
  options,
  onClose,
  active,
  onSubmit,
  title,
  icon,
  ...props
}) => {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleSelect = (relationId: string) => {
    setSelectedId(relationId === selectedId ? null : relationId);
  };

  const handleCloseModal = useCallback(() => {
    setSelectedId(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (selectedId) onSubmit(selectedId);
    handleCloseModal();
  }, [handleCloseModal, onSubmit, selectedId]);

  return (
    <Modal {...props} variant="sm" visible={true} onCancel={handleCloseModal}>
      <ScTitle>{title}</ScTitle>
      <ScList>
        {active ? (
          <ListItem
            relation={active}
            selected={true}
            data-testid={`selection-${active.id}`}
          />
        ) : (
          <ScEmpty />
        )}

        <ScIconWrap>{icon}</ScIconWrap>

        {options.map(relation => (
          <ListItem
            data-testid={`option-${relation.id}`}
            key={relation.idx}
            relation={relation}
            selected={selectedId === relation.id}
            onSelect={() => handleSelect(relation.id)}
          />
        ))}

        {!options.length && <ScEmpty />}
      </ScList>
      <ScFooter>
        <ScButton onClick={handleCloseModal}>{t('FORM_CANCEL')}</ScButton>
        <ScButton variant="primary" onClick={handleSubmit} disabled={!selectedId}>
          {t('CONFIRM')}
        </ScButton>
      </ScFooter>
    </Modal>
  );
};
