import styled, { css } from 'styled-components';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const buttonCommonStyles = css`
  padding: 0.6em;
  font-size: 1.4em;
`;

export const ScButtonCharge = styled(ArrowUpOutlined)<{ $active: boolean }>`
  ${buttonCommonStyles};
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  color: ${({ theme }) => theme.chargeColor};
`;

export const ScButtonDiscount = styled(ArrowDownOutlined)<{ $active: boolean }>`
  ${buttonCommonStyles};
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  color: ${({ theme }) => theme.discountColor};
`;
