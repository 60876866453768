import {
  defaultImage,
  DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE,
  DEFAULT_BACKGROUND_IMAGE,
  DEFAULT_FAVICON,
} from './constants';
import { getHostThemeFromExternalSettings } from '../store/hostSettings/utils';

export type HostTheme = {
  textColor: string;
  highlightColor: string;
  highlightColor_contrast: string;
  highlightColor_hovered: string;
  highlightColor_pressed: string;
  mainColor: string;
  mainColor_contrast: string;
  mainColor_hovered: string;
  mainColor_pressed: string;
  fontFamilyName?: string;
  fontFamilyLocation?: string;
  /** In 'em's */
  letterSpacing?: string;
};

export type HostSettingsExternal = {
  favicon: string;
  highlightColor: string;
  highlightColor_contrast: string;
  primaryLogo: string;
  secondaryLogo: string;
  primaryColor: string;
  primaryColor_contrast: string;
  textColor: string;
  fontFamilyName?: string;
  fontFamilyLocation?: string;
  letterSpacing?: string;
  translationsLocation: string;
  availableLanguages?: { code: string; label: string; importCode: string }[];
  /** It's a bg for auth pages. */
  background: {
    background: string;
    backgroundSize?: string;
    backgroundRepeat?: string;
  };
  backgroundCommon?: {
    backgroundImage: string;
    backgroundSize?: string;
    backgroundRepeat?: string;
  };
  resizableBackgroundFallback?: string;
};

export const DEFAULT_HOST_SETTINGS_EXTERNAL: HostSettingsExternal = {
  favicon: DEFAULT_FAVICON,
  primaryLogo: '/images/default-logo.png',
  secondaryLogo: '/images/default-logo.png',
  highlightColor: '#D96738',
  highlightColor_contrast: '#ffffff',
  primaryColor: '#109bc5',
  primaryColor_contrast: '#ffffff',
  textColor: '#464646',
  translationsLocation:
    'https://xdms-1.s3.eu-west-1.amazonaws.com/translations/luyckx/{{lng}}-{{ns}}.json',
  availableLanguages: [
    { label: 'nl', code: 'nl', importCode: 'nl-be' },
    { label: 'en', code: 'en', importCode: 'intl' },
    { label: 'fr', code: 'fr', importCode: 'fr-be' },
  ],
  background: {
    background: `url(${DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
  },
  backgroundCommon: {
    backgroundImage: `url(${DEFAULT_BACKGROUND_IMAGE})`,
    backgroundRepeat: 'repeat',
  },
  resizableBackgroundFallback: defaultImage,
};

export const DEFAULT_HOST_THEME: HostTheme = getHostThemeFromExternalSettings(
  DEFAULT_HOST_SETTINGS_EXTERNAL,
);
