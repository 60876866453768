import { cloneDeep } from 'utils';
import { ConfigurationStatus, ConfigurationStatusUpdateReason } from 'types/vendor';
import { SALE_DATE_STATUS_ID, SALE_STATUS_ID } from 'common/constants';

export enum ValueType {
  reason,
  date,
  selection,
}

export enum StateActionType {
  init,
  setDate,
  setIsSelected,
  setIsReasonSelected,
  setReasonText,
}

export type StateAction =
  | {
      type: StateActionType.init;
      statusList: ConfigurationStatus[];
      saleReasonList: ConfigurationStatusUpdateReason[];
      initialValues?: {
        statusId?: string;
        reasonId?: string;
        reasonText?: string;
        date?: string;
      };
    }
  | {
      type: StateActionType.setDate;
      statusId: string;
      date: string;
    }
  | {
      type: StateActionType.setIsSelected;
      statusId: string;
      isSelected: boolean;
    }
  | {
      type: StateActionType.setIsReasonSelected;
      statusId: string;
      reasonId: string;
      isSelected: boolean;
    }
  | {
      type: StateActionType.setReasonText;
      statusId: string;
      reasonId: string;
      text: string;
    };

export type StateData = {
  valueType: ValueType;
  statusId: string;
  label: string;
  isSelected: boolean;
  data?: {
    date?: string;
    reasonList?: { reasonId: string; text: string; isSelected: boolean }[];
  };
}[];

export const reducer = (state: StateData, action: StateAction): StateData => {
  switch (action.type) {
    case StateActionType.init: {
      const { statusList, saleReasonList, initialValues } = action;

      return statusList.map(statusListItem => {
        let valueType: ValueType = ValueType.selection;
        let data: StateData[number]['data'] = undefined;

        if (statusListItem.id === SALE_STATUS_ID) {
          valueType = ValueType.reason;
          data = {
            reasonList: saleReasonList.map(({ id, text }) => ({
              reasonId: id,
              text: text,
              isSelected: initialValues?.reasonId === id,
            })),
          };
        }

        if (statusListItem.id === SALE_DATE_STATUS_ID) {
          valueType = ValueType.date;
          data = {
            date: initialValues?.date,
          };
        }

        return {
          statusId: statusListItem.id,
          label: statusListItem.text,
          valueType: valueType,
          isSelected: initialValues?.statusId === statusListItem.id,
          data: data,
        };
      });
    }
    case StateActionType.setIsSelected: {
      return state.map(item => {
        const clonedItem = cloneDeep(item);
        clonedItem.isSelected =
          item.statusId === action.statusId ? action.isSelected : false;

        if (clonedItem.data?.reasonList?.length) {
          clonedItem.data.reasonList = clonedItem.data.reasonList.map(reasonItem => ({
            ...reasonItem,
            isSelected:
              clonedItem.statusId === action.statusId ? reasonItem.isSelected : false,
          }));
        }

        return clonedItem;
      });
    }
    case StateActionType.setIsReasonSelected: {
      return state.map(item => {
        const clonedItem = cloneDeep(item);

        clonedItem.isSelected =
          item.statusId === action.statusId ? action.isSelected : false;

        if (clonedItem.data?.reasonList?.length) {
          clonedItem.data.reasonList = clonedItem.data.reasonList.map(reasonItem => ({
            ...reasonItem,
            isSelected:
              reasonItem.reasonId === action.reasonId ? action.isSelected : false,
          }));
        }

        return clonedItem;
      });
    }
    case StateActionType.setReasonText: {
      const clonedState = cloneDeep(state);

      const statusRecord = clonedState.find(
        ({ statusId }) => statusId === action.statusId,
      );

      const reasonRecord = statusRecord?.data?.reasonList?.find(
        ({ reasonId }) => reasonId === action.reasonId,
      );

      if (reasonRecord) reasonRecord.text = action.text;

      return clonedState;
    }
    case StateActionType.setDate: {
      const clonedState = cloneDeep(state);

      clonedState.forEach(statusItem => {
        statusItem.isSelected = statusItem.statusId === action.statusId;
        statusItem.data?.reasonList?.forEach(
          reasonItem => (reasonItem.isSelected = false),
        );
      });

      const statusRecord = clonedState.find(
        ({ statusId }) => statusId === action.statusId,
      );

      if (statusRecord?.data) statusRecord.data.date = action.date;

      return clonedState;
    }

    default:
      return state;
  }
};
