import { ScImageGalleryWrapper } from 'components/styles';
import styled from 'styled-components';
import { Price } from 'components/Price';

/**
 * Generic prop
 * @desc Used just to keep same quantity of elements over all entities
 */
export type TotalsTableColumnsGenericProp<T> = [T, T, T, T, T, T, T, T];

/**
 * Width of the columns in percents
 * @desc For now order is: Summary, Description, Code, Quantity, Price, Discount Amount, Discount Percent, Total
 */
export const totalsTableColumnsWidths: TotalsTableColumnsGenericProp<number> = [
  24, 10, 8, 5, 14, 14, 11, 14,
];

export const totalsInnerTableColumnsWidths: TotalsTableColumnsGenericProp<number> = [
  1, 33, 8, 5, 14, 14, 11, 14,
];

export const ScTotalTableContainer = styled.div`
  padding: 2em 1.2em;
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  font-size: inherit;

  .ant-input-number-input-wrap input.ant-input-number-input {
    font-size: 0.875em;
    height: 3.125em;
  }

  .ant-input-number {
    padding-top: 0;
    padding-bottom: 0;
    height: 3.125em;
  }
`;

export const ScTotalPageContainer = styled.div`
  font-size: 18px;
  margin: 0 auto;
  padding: 0 1em 6em 1em;
  width: 100%;
  max-width: 80em;
`;

export const ScTotalImageGalleryWrapper = styled(ScImageGalleryWrapper)`
  .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: calc(100% - 350px) !important;
  }

  .image-gallery-thumbnails {
    width: 350px !important;
    overflow-y: scroll;
  }

  .image-gallery-thumbnails::-webkit-scrollbar {
    display: none;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }

  .image-gallery-thumbnails-container button {
    flex: 1;
    width: 100%;
    height: 25%;
    overflow: hidden;
  }

  .image-gallery-thumbnails-container span,
  .image-gallery-thumbnails-container img {
    height: 100%;
  }

  .image-gallery-image {
    object-fit: cover !important;
  }
`;

export const ChangedPrice = styled(Price)`
  color: ${({ theme }) => theme.recordIsEditedHighlightColor};
`;
