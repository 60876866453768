// @todo: replace this form with proper one, or use make use oftypes/vendor
import {
  ContactFields,
  ContactItem,
  ContactListItem,
  CountryFields,
  CountryItem,
  CustomerFields,
  CustomerItem,
  CustomerListItem,
  CustomerTypeListItemFields,
  FilterField,
  LanguageFields,
  MessageCodeValues,
  MessageFields,
  CustomerCommercialTypesListItemFields,
  CustomerSalutationListItemFields,
  CustomerTitleListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Col, Row } from 'antd';
import { FILTER_SIGNS } from 'common/constants';
import Select from 'components/form/Selector';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { MobileInput, MobileInputCountry } from 'components/form/MobileInput';
import { RotatingLoader } from 'components/RotatingLoader';
import { useCountry } from 'context/country/CountryProvider';
import { useCustomer } from 'context/customer/CustomerProvider';
import { useLanguage } from 'context/language/LanguageProvider';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { get, uniqBy } from 'utils';
import React, {
  FC,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_COUNTRY_CODE } from 'utils/constants';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { Status } from 'utils/types';
import * as Yup from 'yup';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Loader } from '../Loader';
import {
  ScCheckOutlined,
  ScCustomerDetailsForm,
  ScLanguageCodeAndCountryContainer,
  ScSpin,
  ScWarningOutlined,
  TwoColumnContainer,
} from './UserForm.styles';
import { notification, NotificationType } from '../../utils/notification';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { isFieldRequired } from 'utils/is-field-required';
import { CustomerTypeFields, Language } from 'types/vendor';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';
import Autocomplete from 'components/form/Autocomplete';
import { OptionWithKey } from 'types';

export { CheckOutlined, WarningOutlined } from '@ant-design/icons';

export enum UserType {
  Customer = 'Customer',
  Contact = 'Contact',
}

interface SelectionProps {
  selectionValue?: string;
  selectionSuffix?: React.ReactNode;
  selectionHeaderValue?: string;
  selectionPlaceholder?: string;
  selectionReadOnly?: boolean;
  onSelectionClick?(): void;
  showCommercialCustomerType?: boolean;
  disableCommercialCustomerType?: boolean;
}

interface UserFormProps extends SelectionProps {
  footer?: ReactNode;
  countries: CountryItem[];
  languages: Language[];
  userType?: UserType;
  onSubmit?: (
    values: CustomerItem | ContactItem,
    actions: FormikHelpers<CustomerItem>,
  ) => Promise<void>;
  readonly: boolean;
  initialFormValues: CustomerItem | ContactItem | null;
  formSchema?: any;
  isForm?: boolean;
  onFormChange?(e: FormEvent<HTMLFormElement>, values: Record<string, unknown>): void;
  prefillOptionsList?: OptionWithKey[];
  onPrefillOptionSelect?(id: string | number): void;
  handleWarningIconClick?(name: ContactFields | CustomerFields, value: string): void;
  reloadEntities?(
    filters: FilterField<ContactFields | CustomerFields>[],
  ): Promise<ContactListItem[] | CustomerListItem[] | null>;
}

/** Combines client(XDMS term customer) and contacts form */

export type UserFormValues = CustomerItem | ContactItem;

export const UserForm: FC<UserFormProps> = props => {
  const {
    languages,
    countries,
    footer,
    userType = UserType.Customer,
    onSubmit,
    onSelectionClick,
    formSchema = Yup.object().shape({}),
    readonly,
    initialFormValues,
    isForm = true,
    selectionValue,
    selectionSuffix,
    selectionHeaderValue,
    selectionPlaceholder,
    selectionReadOnly,
    showCommercialCustomerType = true,
    disableCommercialCustomerType = false,
    onFormChange,
    prefillOptionsList,
    onPrefillOptionSelect,
    handleWarningIconClick,
    reloadEntities,
  } = props;
  console.log(prefillOptionsList);

  const formFieldsType = userType === UserType.Customer ? CustomerFields : ContactFields;
  const { t } = useTranslation();
  const { status: countriesStatus } = useCountry();
  const { status: langStatus } = useLanguage();
  const {
    status: customerStatus,
    customerTypes,
    customerCommercialTypes,
    customerSalutations,
    customerTitles,
    getCustomerCommercialTypes,
    getCustomerSalutations,
    getCustomerTitles,
  } = useCustomer();
  const { isFeatureEnabled } = useFeature();

  const isCustomerTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerTypeEnabled,
  });
  const isCustomerCreateTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerCreateTypeEnabled,
  });
  const isCustomerTitleEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerTitleEnabled,
  });
  const isCustomerSalutationEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerSalutationEnabled,
  });

  const [isLoadingSearchContacts, setIsLoadingSearchContacts] = useState<boolean>(false);
  const [hasDuplicateSearchContacts, setHasDuplicateSearchContacts] = useState<
    boolean | null
  >(null);
  const [isLoadingSearchCustomers, setIsLoadingSearchCustomers] =
    useState<boolean>(false);
  const [hasDuplicateSearchCustomers, setHasDuplicateSearchCustomers] = useState<
    boolean | null
  >(null);

  const customerCountryCode: string =
    initialFormValues?.[formFieldsType.country] || DEFAULT_COUNTRY_CODE;

  const [mobilePhoneCountryCode, setMobilePhoneCountryCode] = useState<string>(() => {
    const mobilePhone = initialFormValues?.[formFieldsType.mobilePhone];

    if (!mobilePhone) return customerCountryCode;

    return parsePhoneNumber(mobilePhone)?.country || customerCountryCode;
  });

  const [homePhoneCountryCode, setHomePhoneCountryCode] = useState<string>(() => {
    const phone = initialFormValues?.[formFieldsType.phone];

    if (!phone) return customerCountryCode;

    return parsePhoneNumber(phone)?.country || customerCountryCode;
  });

  const [prevEmailValue, setPrevEmailValue] = useState<string>('');
  const [prevVatValue, setPrevVatValue] = useState<string>('');

  const isNewUser = Boolean(initialFormValues && !initialFormValues[formFieldsType.ID]);

  const commercialTypeOptions = useMemo(() => {
    return uniqBy(
      mapToSelectOptions(customerCommercialTypes, {
        label: CustomerCommercialTypesListItemFields.name,
        value: CustomerCommercialTypesListItemFields.code,
      }),
      'value',
    );
  }, [customerCommercialTypes]);

  const typeOptions = useMemo(() => {
    const list = uniqBy(customerTypes, CustomerTypeFields.code);
    return mapToSelectOptions(list, {
      label: CustomerTypeFields.description,
      value: CustomerTypeFields.code,
    });
  }, [customerTypes]);

  const salutationOptions = useMemo(() => {
    return uniqBy(
      mapToSelectOptions(customerSalutations, {
        label: CustomerSalutationListItemFields.name,
        value: CustomerSalutationListItemFields.code,
      }),
      'value',
    );
  }, [customerSalutations]);

  const titleOptions = useMemo(() => {
    return uniqBy(
      mapToSelectOptions(customerTitles, {
        label: CustomerTitleListItemFields.name,
        value: CustomerTitleListItemFields.code,
      }),
      'value',
    );
  }, [customerTitles]);

  const languageOptions = useMemo(() => {
    return mapToSelectOptions<Language>(languages, {
      label: LanguageFields.language,
      value: LanguageFields.languageCode,
    });
  }, [languages]);

  useEffect(() => {
    if (!readonly) {
      (async () => {
        await getCustomerCommercialTypes();
        await getCustomerSalutations();
        await getCustomerTitles();
      })();
    }
  }, [getCustomerCommercialTypes, getCustomerSalutations, getCustomerTitles, readonly]);

  const handleLoadContactsList = useCallback(
    (value: string) => {
      setIsLoadingSearchContacts(true);
      const filters = [{ name: ContactFields.email, value, sign: FILTER_SIGNS.EQUAL }];

      reloadEntities &&
        reloadEntities(filters).then(contacts => {
          setIsLoadingSearchContacts(false);
          const hasDuplicate = Boolean(contacts && contacts.length);
          setHasDuplicateSearchContacts(hasDuplicate);
          notification.open({
            message: {
              [MessageFields.code]: hasDuplicate
                ? MessageCodeValues.warning
                : MessageCodeValues.success,
              [MessageFields.text]: t(
                `DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_CONTENT`,
                {
                  entity: 'contact',
                },
              ),
            },
            header: t(`DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_TITLE`, {
              entity: 'contact',
            }),
          });
          return contacts;
        });
    },
    [reloadEntities, t, setIsLoadingSearchContacts, setHasDuplicateSearchContacts],
  );

  const handleLoadCustomersList = useCallback(
    (value: string) => {
      setIsLoadingSearchCustomers(true);
      const filters = [
        {
          name: CustomerFields.vatCode,
          value,
          sign: FILTER_SIGNS.EQUAL,
        },
      ];

      reloadEntities?.(filters).then(customers => {
        setIsLoadingSearchCustomers(false);
        const hasDuplicate = Boolean(customers && customers.length);
        setHasDuplicateSearchCustomers(hasDuplicate);
        notification.open({
          header: t(`DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_TITLE`, {
            entity: 'customer',
          }),
          message: t(`DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_CONTENT`, {
            entity: 'customer',
          }),
          type: hasDuplicate ? NotificationType.warning : NotificationType.success,
        });
      });
    },
    [t, setIsLoadingSearchCustomers, setHasDuplicateSearchCustomers, reloadEntities],
  );

  const getSuffix = useCallback(
    (params: {
      isLoading: boolean;
      hasDuplicate: boolean | null;
      name: CustomerFields | ContactFields;
      value: string;
    }) => {
      const { isLoading, hasDuplicate, name, value } = params;

      if (isLoading) {
        return <RotatingLoader />;
      }

      if (typeof hasDuplicate === 'boolean') {
        return hasDuplicate ? (
          <ScWarningOutlined
            onClick={() => handleWarningIconClick && handleWarningIconClick(name, value)}
          />
        ) : (
          <ScCheckOutlined />
        );
      }
    },
    [handleWarningIconClick],
  );

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  const countryOptions = useMemo(() => {
    return mapToSelectOptions<CountryItem>(countries, {
      label: CountryFields.country,
      value: CountryFields.countryCode,
    });
  }, [countries]);

  const handleSubmit = useCallback(
    async (
      values: CustomerItem | ContactItem,
      actions: FormikHelpers<CustomerItem | ContactItem>,
    ) => {
      actions.setSubmitting(true);
      if (onSubmit) await onSubmit(values, actions);
      actions.setSubmitting(false);
    },
    [onSubmit],
  );

  const shouldShowSelection = Boolean(onSelectionClick);
  const shouldShowCustomerType =
    initialFormValues && userType === UserType.Customer && isCustomerTypeEnabled;

  const renderFormBody = formProps => (
    <>
      {(shouldShowSelection || shouldShowCustomerType || showCommercialCustomerType) && (
        <ScCustomerDetailsForm data-testid={`selection-container-${userType}`}>
          {shouldShowSelection && (
            <FormField
              disabled={selectionReadOnly}
              readOnly={selectionReadOnly}
              name=""
              data-testid={`selection-value-${userType}`}
              onClick={readonly || selectionReadOnly ? undefined : onSelectionClick}
              placeholder={selectionPlaceholder}
              label={selectionHeaderValue}
              component={Input}
              value={selectionValue}
              suffix={selectionSuffix}
            />
          )}
          {shouldShowCustomerType && (
            <FormField
              name={CustomerFields.type}
              component={Select}
              loading={customerStatus === Status.Loading}
              options={typeOptions}
              label={t('TYPE')}
              required={isFieldRequired(formSchema, get(formFieldsType, 'type', ''))}
              data-testid="customer-page-form-user-type-field"
              disabled={!isCustomerCreateTypeEnabled}
            />
          )}
          {showCommercialCustomerType && (
            <FormField
              disabled={readonly || disableCommercialCustomerType}
              name={formFieldsType.commercialType}
              required={isFieldRequired(formSchema, formFieldsType.commercialType)}
              id={formFieldsType.commercialType}
              component={Select}
              label={t('COMMERCIAL_TYPE')}
              placeholder={t('COMMERCIAL_TYPE')}
              data-testid="customer-page-form-commercial-type-field"
              options={commercialTypeOptions}
            />
          )}
        </ScCustomerDetailsForm>
      )}
      {initialFormValues && (
        <>
          {userType === UserType.Customer && (
            <>
              <ScCustomerDetailsForm data-testid="customer-page-form-vat-wrapper">
                {onPrefillOptionSelect ? (
                  <FormField
                    disabled={readonly}
                    name={get(formFieldsType, 'vatCode', '')}
                    required={isFieldRequired(
                      formSchema,
                      get(formFieldsType, 'vatCode', ''),
                    )}
                    id={get(formFieldsType, 'vatCode')}
                    component={Autocomplete}
                    label={t('VAT')}
                    data-testid="customer-page-form-mobile-vatCode-field"
                    onSelect={onPrefillOptionSelect}
                    options={prefillOptionsList ?? []}
                    isFilteringEnabled={false}
                  />
                ) : (
                  <FormField
                    disabled={readonly}
                    name={get(formFieldsType, 'vatCode', '')}
                    required={isFieldRequired(
                      formSchema,
                      get(formFieldsType, 'vatCode', ''),
                    )}
                    id={get(formFieldsType, 'vatCode')}
                    suffix={
                      formProps.values[CustomerFields.vatCode] &&
                      getSuffix({
                        isLoading: isLoadingSearchCustomers,
                        hasDuplicate: hasDuplicateSearchCustomers,
                        name: CustomerFields.vatCode,
                        value: formProps.values[CustomerFields.vatCode],
                      })
                    }
                    component={Input}
                    label={t('VAT')}
                    data-testid="customer-page-form-mobile-vatCode-field"
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.trim();
                      formProps.setFieldValue(CustomerFields.vatCode, value);
                      value && prevVatValue !== value && handleLoadCustomersList(value);
                      setPrevVatValue(value);
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.trim();
                      setPrevVatValue(formProps.values[CustomerFields.vatCode]);
                      formProps.setFieldValue(CustomerFields.vatCode, value);
                    }}
                  />
                )}
              </ScCustomerDetailsForm>
            </>
          )}
          {userType === UserType.Contact && (
            <ScCustomerDetailsForm data-testid="customer-page-form-email-wrapper">
              <FormField
                disabled={readonly}
                name={formFieldsType.email}
                id={formFieldsType.email}
                component={Input}
                suffix={
                  formProps.values[ContactFields.email] &&
                  getSuffix({
                    isLoading: isLoadingSearchContacts,
                    hasDuplicate: hasDuplicateSearchContacts,
                    name: ContactFields.email,
                    value: formProps.values[ContactFields.email],
                  })
                }
                label={t('CLIENTS_EMAIL')}
                required={isFieldRequired(formSchema, formFieldsType.email)}
                data-testid="customer-page-form-mobile-email-field"
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.trim();
                  formProps.setFieldValue(ContactFields.email, value);
                  value && prevEmailValue !== value && handleLoadContactsList(value);
                  setPrevEmailValue(value);
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.trim();
                  setPrevEmailValue(formProps.values[ContactFields.email]);
                  formProps.setFieldValue(ContactFields.email, value);
                }}
              />
            </ScCustomerDetailsForm>
          )}

          <ScCustomerDetailsForm data-testid="customer-page-form-name-wrapper">
            {userType === UserType.Customer &&
              (isCustomerTitleEnabled || isCustomerSalutationEnabled) && (
                <TwoColumnContainer data-testid="customer-page-form-title-wrapper">
                  {isCustomerTitleEnabled && (
                    <FormField
                      disabled={readonly}
                      name={formFieldsType.title}
                      required={isFieldRequired(formSchema, CustomerFields.title)}
                      id={formFieldsType.title}
                      component={Select}
                      label={t('CLIENTS_TITLE')}
                      placeholder={t('CLIENTS_TITLE')}
                      data-testid="customer-page-form-title-field"
                      options={titleOptions}
                    />
                  )}

                  {isCustomerSalutationEnabled && (
                    <FormField
                      disabled={readonly}
                      name={formFieldsType.salutation}
                      required={isFieldRequired(formSchema, formFieldsType.salutation)}
                      id={formFieldsType.salutation}
                      component={Select}
                      label={t('CLIENTS_SALUTATION')}
                      placeholder={t('CLIENTS_SALUTATION')}
                      data-testid="customer-page-form-salutation-field"
                      options={salutationOptions}
                    />
                  )}
                </TwoColumnContainer>
              )}

            <FormField
              disabled={readonly}
              id={formFieldsType.name}
              name={formFieldsType.name}
              component={Input}
              label={t(
                userType === UserType.Customer
                  ? 'CLIENTS_FIRST_NAME'
                  : 'CONTACT_SEARCH_FIRST_NAME',
              )}
              required={isFieldRequired(formSchema, formFieldsType.name)}
              data-testid="customer-page-form-name-field"
            />
            <FormField
              disabled={readonly}
              id={formFieldsType.name2}
              name={formFieldsType.name2}
              required={isFieldRequired(formSchema, formFieldsType.name2)}
              component={Input}
              label={t(
                userType === UserType.Customer
                  ? 'CLIENTS_LAST_NAME'
                  : 'CONTACT_SEARCH_LAST_NAME',
              )}
              data-testid="customer-page-form-name2-field"
            />
          </ScCustomerDetailsForm>

          {userType === UserType.Customer && (
            <ScCustomerDetailsForm data-testid="customer-page-form-location-wrapper">
              <FormField
                disabled={readonly}
                name={formFieldsType.adress}
                id={formFieldsType.adress}
                component={Input}
                label={t('CLIENTS_ADDRESS')}
                required={isFieldRequired(formSchema, formFieldsType.adress)}
                data-testid="customer-page-form-address-field"
              />
              <FormField
                disabled={readonly}
                name={formFieldsType.adress2}
                required={isFieldRequired(formSchema, formFieldsType.adress2)}
                id={formFieldsType.adress2}
                component={Input}
                label={`${t('CLIENTS_ADDRESS')} 2`}
                placeholder={''}
                data-testid="customer-page-form-second-address-field"
              />
              <InputCityZipField
                inputZipProps={{
                  id: formFieldsType.zipcode,
                  name: formFieldsType.zipcode,
                  required: isFieldRequired(formSchema, formFieldsType.zipcode),
                  disabled: readonly,
                  label: t('CLIENTS_ZIP'),
                  ['data-testid']: 'customer-page-form-zipcode-field',
                }}
                inputCityProps={{
                  id: formFieldsType.city,
                  name: formFieldsType.city,
                  required: isFieldRequired(formSchema, formFieldsType.city),
                  disabled: readonly,
                  label: t('CLIENTS_CITY'),
                  ['data-testid']: 'customer-page-form-city-field',
                }}
              />
            </ScCustomerDetailsForm>
          )}
          {userType === UserType.Customer && (
            <ScLanguageCodeAndCountryContainer data-testid="customer-page-form-language-country-fields-wrapper">
              <Row gutter={userType === UserType.Customer ? [32, 0] : 0}>
                <Col span={userType === UserType.Customer ? 12 : 24}>
                  <FormField
                    name={formFieldsType.lang}
                    component={Select}
                    loading={langStatus === Status.Loading}
                    options={languageOptions}
                    label={t('CLIENTS_LANGUAGE_CODE')}
                    required={isFieldRequired(formSchema, formFieldsType.lang)}
                    disabled={readonly}
                    data-testid="customer-page-form-user-language-field"
                  />
                </Col>
                <Col span={userType === UserType.Customer ? 12 : 0}>
                  <FormField
                    name={formFieldsType.country}
                    component={Select}
                    loading={countriesStatus === Status.Loading}
                    options={countryOptions}
                    label={t('CLIENTS_COUNTRY')}
                    required={isFieldRequired(formSchema, formFieldsType.country)}
                    onChange={event => {
                      const value = event.target.value;
                      formProps.setFieldValue(formFieldsType.country, value);
                      if (isNewUser) {
                        formProps.setFieldValue(formFieldsType.phone, '');
                        formProps.setFieldValue(formFieldsType.mobilePhone, '');
                        setMobilePhoneCountryCode(value);
                        setHomePhoneCountryCode(value);
                      }
                    }}
                    disabled={readonly}
                    data-testid="customer-page-form-country-field"
                    showSearch
                  />
                </Col>
              </Row>
            </ScLanguageCodeAndCountryContainer>
          )}
          <ScCustomerDetailsForm data-testid="customer-page-form-mobile-wrapper">
            <TwoColumnContainer>
              {readonly && !formProps.values[formFieldsType.mobilePhone] ? (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.mobilePhone}
                  required={isFieldRequired(formSchema, formFieldsType.mobilePhone)}
                  id={formFieldsType.mobilePhone}
                  component={Input}
                  label={t('CLIENTS_MOBILE_NUMBER')}
                  data-testid="customer-page-form-mobile-phone-field"
                  placeholder={''}
                />
              ) : (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.mobilePhone}
                  required={isFieldRequired(formSchema, formFieldsType.mobilePhone)}
                  id={formFieldsType.mobilePhone}
                  component={MobileInput}
                  label={t('CLIENTS_MOBILE_NUMBER')}
                  data-testid="customer-page-form-mobile-phone-field"
                  countries={countriesPhoneCodeList}
                  international={true}
                  defaultCountry={mobilePhoneCountryCode as MobileInputCountry}
                  addInternationalOption={false}
                />
              )}
              {readonly && !formProps.values[formFieldsType.mobilePhone2] ? (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.mobilePhone2}
                  required={isFieldRequired(formSchema, formFieldsType.mobilePhone2)}
                  id={formFieldsType.mobilePhone2}
                  component={Input}
                  label={t('CLIENTS_MOBILE2_NUMBER')}
                  data-testid="customer-page-form-mobile-phone2-field"
                  placeholder={''}
                />
              ) : (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.mobilePhone2}
                  required={isFieldRequired(formSchema, formFieldsType.mobilePhone2)}
                  id={formFieldsType.mobilePhone2}
                  component={MobileInput}
                  label={t('CLIENTS_MOBILE2_NUMBER')}
                  data-testid="customer-page-form-mobile-phone2-field"
                  countries={countriesPhoneCodeList}
                  international={true}
                  defaultCountry={mobilePhoneCountryCode as MobileInputCountry}
                  addInternationalOption={false}
                />
              )}
            </TwoColumnContainer>
            <TwoColumnContainer>
              {readonly && !formProps.values[formFieldsType.phone] ? (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.phone}
                  required={isFieldRequired(formSchema, formFieldsType.phone)}
                  id={formFieldsType.phone}
                  component={Input}
                  label={t('CLIENTS_PHONE_NUMBER')}
                  data-testid="customer-page-form-phone-field"
                  placeholder={''}
                />
              ) : (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.phone}
                  required={isFieldRequired(formSchema, formFieldsType.phone)}
                  id={formFieldsType.phone}
                  component={MobileInput}
                  label={t('CLIENTS_PHONE_NUMBER')}
                  data-testid="customer-page-form-phone-field"
                  countries={countriesPhoneCodeList}
                  international={true}
                  defaultCountry={homePhoneCountryCode as MobileInputCountry}
                  addInternationalOption={false}
                />
              )}
              {readonly && !formProps.values[formFieldsType.phone2] ? (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.phone2}
                  required={isFieldRequired(formSchema, formFieldsType.phone2)}
                  id={formFieldsType.phone2}
                  component={Input}
                  label={t('CLIENTS_PHONE2_NUMBER')}
                  data-testid="customer-page-form-phone2-field"
                  placeholder={''}
                />
              ) : (
                <FormField
                  disabled={readonly}
                  name={formFieldsType.phone2}
                  required={isFieldRequired(formSchema, formFieldsType.phone2)}
                  id={formFieldsType.phone2}
                  component={MobileInput}
                  label={t('CLIENTS_PHONE2_NUMBER')}
                  data-testid="customer-page-form-phone2-field"
                  countries={countriesPhoneCodeList}
                  international={true}
                  defaultCountry={homePhoneCountryCode as MobileInputCountry}
                  addInternationalOption={false}
                />
              )}
            </TwoColumnContainer>
            {userType === UserType.Customer ? (
              <FormField
                disabled={readonly}
                name={formFieldsType.email}
                id={formFieldsType.email}
                component={Input}
                label={t('CLIENTS_EMAIL')}
                required={isFieldRequired(formSchema, formFieldsType.email)}
                data-testid="customer-page-form-mobile-email-field"
              />
            ) : (
              <FormField
                name={formFieldsType.lang}
                required={isFieldRequired(formSchema, formFieldsType.lang)}
                component={Select}
                loading={langStatus === Status.Loading}
                options={languageOptions}
                label={t('CLIENTS_LANGUAGE_CODE')}
                disabled={readonly}
                data-testid="customer-page-form-user-language-field"
              />
            )}
          </ScCustomerDetailsForm>
          {footer}
        </>
      )}
    </>
  );

  return (
    <Formik
      initialValues={initialFormValues || {}}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={formSchema}
    >
      {(formProps: FormikProps<UserFormValues>) => {
        return (
          <ScSpin
            indicator={<Loader />}
            size="large"
            spinning={formProps.isSubmitting}
            data-testid="customer-page-form-loading-indicator"
          >
            {isForm ? (
              <form
                onChange={e => onFormChange?.(e, formProps.values)}
                onSubmit={e => {
                  e.stopPropagation();
                  formProps.handleSubmit(e);
                }}
                noValidate
                data-testid="customer-page-form"
              >
                {renderFormBody(formProps)}
              </form>
            ) : (
              <div data-testid="customer-page-readonly-form">
                {renderFormBody(formProps)}
              </div>
            )}
          </ScSpin>
        );
      }}
    </Formik>
  );
};
