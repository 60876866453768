import React, {
  useEffect,
  useMemo,
  ReactElement,
  useCallback,
  useState,
  FC,
  ChangeEvent,
} from 'react';
import { get } from 'utils';
import { useFeature } from 'context/feature/FeatureProvider';
import { Button } from 'components/button';
import { useColumns } from './use-columns';
import { RightComponent } from '../TradeIn/TradeInTable.styles';
import { useStep } from 'context/step/StepProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Layout from 'layout/Default/Layout';
import Container from 'components/container/Container';
import { FINANCING_URL } from 'common/constants';
import { ScTable } from './Table.styles';
import { useSelector } from 'react-redux';
import { configurationSelectors, financingSelectors, modelSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FinancingListItem, FinancingListItemFields } from 'types/vendor';
import { checkWhetherRecordContainsString } from 'utils/check-whether-record-contains-string';
import TableFilters from 'components/table/filters';
import { DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useFinancingApi } from 'context/financing/useFinancingApi';
import { Status } from 'utils/types';

export const FinancingTable: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { sendMessage } = useStreaming();
  const {
    getFinancingList,
    createFinancingRecord,
    deleteFinancingRecord,
    selectFinancingRecord,
  } = useFinancingApi();
  const { handlePrevStep, handleNextStep } = useStep();

  const modelToken = useSelector(modelSelectors.getToken);
  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const financingList = useSelector(financingSelectors.getList);
  const financingListStatus = useSelector(financingSelectors.getListStatus);

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    if (configurationNumber) getFinancingList(configurationNumber);
  }, [getFinancingList, configurationNumber]);

  const isAllowAddNew =
    isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowFinancingAddNew,
    }) || true;

  const [searchValue, setSearchValue] = useState<string>('');
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue],
  );

  const tableData = useMemo<FinancingListItem[]>(() => {
    return (
      financingList?.filter(record =>
        checkWhetherRecordContainsString(record, searchValue),
      ) ?? []
    );
  }, [searchValue, financingList]);

  const columns = useColumns({
    onEditAction: record => {
      history.push(`${FINANCING_URL}/${record[FinancingListItemFields.id]}`);
    },
    onSelectAction: async record => {
      if (!configurationNumber) return;
      await selectFinancingRecord(
        configurationNumber,
        record[FinancingListItemFields.id],
      );
      await getFinancingList(configurationNumber);
    },
    onDeleteAction: async record => {
      if (!configurationNumber) return;
      await deleteFinancingRecord(configurationNumber, record);
      await getFinancingList(configurationNumber);
    },
  });

  useEffect(() => {
    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }
  }, [modelToken, sendMessage]);

  const AddNewButton = useMemo<ReactElement | null>(() => {
    if (!isAllowAddNew) return null;
    return (
      <RightComponent data-testid="financing-step-table-filter-buttons-container">
        <Button
          onClick={async () => {
            if (!configurationNumber) return;
            const { response } = await createFinancingRecord(configurationNumber);
            if (!response) return;
            history.push({ pathname: './' + response });
          }}
          variant="highlighted"
          data-testid="financing-add"
        >
          {t('FINANCING_ADD_NEW')}
        </Button>
      </RightComponent>
    );
  }, [configurationNumber, createFinancingRecord, history, isAllowAddNew, t]);

  return (
    <Layout bg={false}>
      <Container>
        <TableFilters
          scrollMenuOptions={[]}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          onSelect={() => {}}
          prevStepDisabled={false}
          nextStepDisabled={false}
          searchValue={searchValue}
          onSearch={handleSearch}
          extraRight={AddNewButton}
        />
        <ScTable
          rowKey={record => get(record, FinancingListItemFields.id)}
          data={tableData}
          columns={columns}
          scroll={{ x: 1200 }}
          pagination={tableData.length > DEFAULT_TABLE_PAGE_SIZE}
          loading={financingListStatus === Status.Loading}
        />
      </Container>
    </Layout>
  );
};
