import { default as React, ReactNodeArray } from 'react';
import { sectionsDynamicTypes__mapping__component } from './componentsMapping';
import { BannerSettings } from './types';

type totalDynamicSectionsRendererType = (
  bannersSettings: BannerSettings[],
) => ReactNodeArray;

export const totalDynamicSectionsRenderer: totalDynamicSectionsRendererType =
  bannersSettings => {
    return bannersSettings
      .map(settings => ({
        settings,
        Component: sectionsDynamicTypes__mapping__component[settings.type],
      }))
      .filter(({ settings, Component }) => {
        if (!Component) console.warn(`Component not found for type: `, settings.type);
        return Boolean(Component);
      })
      .map(({ settings, Component }) => <Component {...settings} key={settings.key} />);
  };
