import styled, { css } from 'styled-components';
import { mediaMax } from '../../../common/theme';
import { Button } from '../../../components/button';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const ScTotalActionsWrapper = styled.div`
  text-align: center;
  margin-top: 3em;
`;

export const ScTotalAction = styled.div`
  margin-right: 0.625em;

  &:last-child,
  &:only-type {
    margin-right: 0;
  }

  ${mediaMax.md} {
    width: 100%;
    margin-top: 0.625em;

    &:last-child,
    &:only-type {
      margin-top: 0;
    }
  }
`;

const totalActionButtonStyles = css`
  max-width: 15.125em;

  ${mediaMax.md} {
    max-width: 100%;
  }
`;

export const ScTotalPublishButton = styled(Button)`
  ${totalActionButtonStyles}
  pointer-events: none;
`;

export const ScTotalPrevStepButton = styled(Button)`
  ${totalActionButtonStyles}
`;

export const ScTotalSkipButton = styled(Button)`
  ${totalActionButtonStyles}
`;

export const ScDisableExplanationListing = styled.div`
  display: flex;
`;

const commonRequiredItemStyles = css`
  height: 100%;
`;

export const ScCheckIcon = styled(CheckOutlined)`
  ${commonRequiredItemStyles}
`;

export const ScCloseIcon = styled(CloseOutlined)`
  ${commonRequiredItemStyles}
`;

export const ScListingItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const ScListingItemIconWrapper = styled.span`
  margin-right: 0.5em;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ScListingItemTextWrapper = styled.span`
  word-break: break-word;
`;
