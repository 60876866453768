import React, { FC } from 'react';
import { ModelActionSignValues } from 'types/vendor';
import { ScButtonCharge, ScButtonDiscount } from './ActionItemSignToggle.styles';
import { TFunction } from 'i18next';

interface Props {
  t: TFunction;
  value: ModelActionSignValues;
  onClick(): void;
  active: boolean;
}

const ActionItemSignToggle: FC<Props> = ({ value, onClick, t, active }) => {
  if (value === ModelActionSignValues.positive)
    return (
      <ScButtonCharge
        $active={active}
        title={t(active ? 'TOTAL_ACTION_SIGN_CHARGE_ACTIVE' : 'TOTAL_ACTION_SIGN_CHARGE')}
        onClick={active ? onClick : undefined}
      />
    );

  if (value === ModelActionSignValues.negative)
    return (
      <ScButtonDiscount
        $active={active}
        title={t(
          active ? 'TOTAL_ACTION_SIGN_DISCOUNT_ACTIVE' : 'TOTAL_ACTION_SIGN_DISCOUNT',
        )}
        onClick={active ? onClick : undefined}
      />
    );

  return null;
};

export default ActionItemSignToggle;
