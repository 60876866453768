import {
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields,
  PriceListFields,
  PriceListItem as TPriceListItem,
} from '@hypercharge/xdms-client/lib/types';
import { URL_QUERY_PARAMS } from 'common/constants';
import PriceListItem from 'components/priceList';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { configurationCreate_EncodeInputParams } from 'context/configuration/utils';
import { useModelApi } from 'context/model/useModelApi';
import { usePriceList } from 'context/priceList/PriceListProvider';
import { useRelations } from 'context/relations/RelationsProvider';
import { useQuery } from 'context/router/UrlQueryProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { useStructureApi } from 'context/structure/useStructureApi';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { F5_KEYBOARD_BUTTON_CODE, R_KEYBOARD_BUTTON_CODE } from 'utils/constants';
import { notification, NotificationType } from 'utils/notification';
import { Status } from 'utils/types';
import Layout from 'layout/Default/Layout';
import LanguageModal, { LanguageModalState } from './LanguageModal';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { useFeature } from 'context/feature/FeatureProvider';
import { useStep } from 'context/step/StepProvider';
import { CustomerFields } from 'types/vendor';
import StockMachineBuilder from './StockMachineBuilder';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import useBuildStockMachine from 'hooks/useBuildStockMachine';
import { Col, Row } from 'antd';
import Container from 'components/container/Container';

export const PriceList: FC = () => {
  const { t } = useTranslation();

  const { query, queryValues, removeQueryParams } = useQuery();

  const { getPriceList, priceList, selectedPriceListItem } = usePriceList();
  const {
    createConfiguration,
    isNewConfiguration,
    unsetConfigurationProvider,
    restoreConfigurationProvider,
  } = useConfiguration();
  const { unsetModel } = useModelApi();
  const { unsetStructure } = useStructureApi();
  const { relations } = useRelations();
  const { sendMessage } = useStreaming();
  const { isFeatureEnabled } = useFeature();
  const { handleStockBuild } = useBuildStockMachine();

  const { handleNextStep } = useStep();

  const configurationDetails = useSelector(
    configurationSelectors.getConfigurationAllDetails,
  );

  const [languageModalState, setLanguageModalState] = useState<LanguageModalState>();

  const isStockBasedModelFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowCreateFromStock,
  });
  const [hasCreatedNewOffer, setHasCreatedNewOffer] = useState<boolean>(false);

  const handleCreateOffer = useCallback(
    async (priceListItem: TPriceListItem, language?: string) => {
      const catalogCode = get(priceListItem, PriceListFields.catalog);

      const params = configurationCreate_EncodeInputParams({
        relations,
        [ConfigurationDetailsFields.catalogCode]: catalogCode,
        [ConfigurationDetailsFields.languageCode]: language,
      });

      if (queryValues.presetDossier) {
        params[ConfigurationDetailsFields.projectId] =
          queryValues.presetDossier as number;
        removeQueryParams(URL_QUERY_PARAMS.presetDossier);
      }

      const { status, messageHandled, response } = await createConfiguration(params);

      const configurationNumber = get(response, 'createdConfigurationNumber');

      if (!configurationNumber) {
        notification.open({
          message: t('CONFIGURATION_CREATED_ERROR'),
          type: NotificationType.error,
        });
        return;
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('CONFIGURATION_CREATED_SUCCESS'),
          [Status.Error]: t('CONFIGURATION_CREATED_ERROR'),
        });
      }

      unsetConfigurationProvider();
      unsetModel();
      unsetStructure();

      // used 'restore' here as it fits best for purpose of setting up new configuration
      await restoreConfigurationProvider(configurationNumber);

      setHasCreatedNewOffer(true);
      sendMessage({
        type: StreamingEventType.CLEAR_SLOTS,
      });
    },
    [
      relations,
      queryValues.presetDossier,
      createConfiguration,
      unsetConfigurationProvider,
      restoreConfigurationProvider,
      unsetModel,
      unsetStructure,
      t,
      sendMessage,
      removeQueryParams,
    ],
  );

  const onPriceListSelect = useCallback(
    priceList => {
      setLanguageModalState({
        isNewConfiguration: isNewConfiguration,
        defaultLanguageCode:
          relations[0]?.customer?.[CustomerFields.lang] ??
          configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
        onCancel: () => {
          setLanguageModalState(undefined);
        },
        onSubmit: language => handleCreateOffer(priceList, language),
      });

      sendMessage({
        type: StreamingEventType.CHANGE_SCREEN,
        data: {
          screen: 'priceList',
        },
      });

      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'priceList',
          data: {
            priceListName: priceList[PriceListFields.catalog],
            priceListImage: priceList[PriceListFields.imageUrl],
            priceListDescription: priceList[PriceListFields.catalogDescription],
            modelYear: priceList[PriceListFields.version],
            companyName: 'Volvo',
          },
        },
      });
    },
    [configurationDetails, handleCreateOffer, isNewConfiguration, sendMessage, relations],
  );

  useEffect(() => {
    const configurationNumber = get(query, URL_QUERY_PARAMS.configurationNumber);
    let reloadByKeyboard = false;
    const warningMessage = t('ATTENTION_TEXT_BEFORE_RELOAD');

    const keyboardReloadHandle = e => {
      if (
        (!configurationNumber && (e.which || e.keyCode) === F5_KEYBOARD_BUTTON_CODE) ||
        (e.ctrlKey && e.keyCode === R_KEYBOARD_BUTTON_CODE)
      ) {
        const isNeedToReload = window.confirm(warningMessage);

        if (isNeedToReload) {
          reloadByKeyboard = true;
          return true;
        } else {
          reloadByKeyboard = false;
          e.preventDefault();
        }
      }
    };

    const handleBrowserReloadBtn = e => {
      if (!reloadByKeyboard && !configurationNumber) {
        e.preventDefault();
        e.returnValue = warningMessage;
      }
    };

    window.addEventListener('keydown', keyboardReloadHandle);
    window.addEventListener('beforeunload', handleBrowserReloadBtn);

    return () => {
      window.removeEventListener('keydown', keyboardReloadHandle);
      window.removeEventListener('beforeunload', handleBrowserReloadBtn);
    };
  }, []);

  useEffect(() => {
    getPriceList();
  }, [getPriceList]);

  useEffect(() => {
    if (selectedPriceListItem) {
      sendMessage({
        type: StreamingEventType.CHANGE_SCREEN,
        data: {
          screen: 'priceList',
        },
      });
      return;
    }

    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'standby',
      },
    });
  }, [selectedPriceListItem, sendMessage]);

  useEffect(() => {
    if (hasCreatedNewOffer) {
      handleNextStep();
    }
  }, [hasCreatedNewOffer, handleNextStep]);

  return (
    <Layout>
      {isStockBasedModelFeatureEnabled && (
        <StockMachineBuilder onBuild={handleStockBuild} />
      )}

      {languageModalState && <LanguageModal {...languageModalState} />}

      <Container size="sm">
        <Row gutter={[15, 0]}>
          {priceList.map(item => (
            <Col key={item[PriceListFields.catalog]} sm={12} md={12} lg={8}>
              <PriceListItem onClick={() => onPriceListSelect(item)} record={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};
