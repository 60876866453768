import styled from 'styled-components';
import { AppstoreAddOutlined, ExpandOutlined } from '@ant-design/icons';
import { Modal, ModalCloseIcon } from 'components/modal';
import ImageCard from './shared/ImageCard';

export const ScModal = styled(Modal)`
  pointer-events: initial;
  top: 0;
`;

export const ScImageCard = styled(ImageCard)<{ $isPackage: boolean }>`
  cursor: ${({ $isPackage }) => ($isPackage ? 'pointer' : 'initial')};
`;

export const ScContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ScStreamingPreviewAllSelectedRecords = styled(ExpandOutlined)<{
  disabled: boolean;
}>`
  && {
    font-size: 1.4em;
    color: ${props =>
      props.disabled ? props.theme.colors.dustyGray : props.theme.mainColor};
    margin-right: 0.625em;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ScStreamingPreviewAllNotSelectedRecords = styled(AppstoreAddOutlined)<{
  disabled: boolean;
}>`
  && {
    font-size: 1.4em;
    color: ${props =>
      props.disabled ? props.theme.colors.dustyGray : props.theme.mainColor};
    margin-right: 0.625em;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ScModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScIconWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1em;
  text-align: right;
  z-index: 1;
`;

export const ScModalCloseIcon = styled(ModalCloseIcon)`
  fill: ${({ theme }) => theme.mainColor};
  cursor: pointer;
`;
