import MobileOverlayPage from 'components/MobileOverlayPage';
import React, { ComponentProps, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from './menus/Currency';
import { Language } from './menus/Language';
import {
  Menu,
  ScCurrentContainerWrapper,
  ScMobileMenu,
  ScComplementActionWrapper,
  ScConfigurationActionIconWrapper,
  ScConfigurationActionLabel,
  ScToggleWrapper,
} from './MobileMenu.styles';
import { ConfigurationAction } from './ConfigurationAction';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';
import { useFeature } from 'context/feature/FeatureProvider';
import { useSession } from 'context/session/AuthenticatedSessionProvider';
import { useAuthApi } from 'context/auth/useAuthApi';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { LogoutIcon } from './Logout.styles';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentContainer } from './menus/CurrentContainer';
import {
  hostSettingsSelectors,
  authSelectors,
  featuresFlagsSelectors,
  streamingSelectors,
  configurationSelectors,
  modelSelectors,
  sharedSelectors,
  sharedActions,
} from 'store';
import { useHistory } from 'react-router-dom';
import { notification } from 'utils/notification';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { ScCustomLink } from './menus/styles';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfigurationDetailsFields } from '@hypercharge/xdms-client/lib/configuration/types';
import ConfigurationNewVersionModal from 'components/configuration/ConfigurationNewVersionModal';
import ConfigurationCopyModal from 'components/configuration/ConfigurationCopyModal';
import { useModalState } from 'hooks/useModalState';
import { useStreaming } from 'context/streaming/StreamingProvider';
import Switcher from 'components/form/Switcher';
import { StreamingEventType } from 'context/streaming/types';
import * as storage from 'utils/storage';
import { STORAGE_KEYS } from 'common/constants';
import useOpenConfiguration from 'hooks/useOpenConfiguration';
import StreamingToggle from 'components/streaming/StreamingToggle';
import { useGlobalModalsStates } from 'context/globalModalsStates/GlobalModalsStatesProvider';

const MobileMenu: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const username = useSelector(authSelectors.getUsername);
  const hostname = useSelector(hostSettingsSelectors.getHost);

  const { logout } = useAuthApi();
  const { handleNewConfiguration: sessionHandleNewConfiguration } = useSession();
  const { setConfigurationImportModalState, setConfigurationMarginModalState } =
    useGlobalModalsStates();
  const { sendMessage } = useStreaming();
  const { configurationsListUrl, copyConfiguration, createNewVersion } =
    useConfiguration();
  const { allowImportConfiguration, isFeatureEnabled } = useFeature();
  const { handleOpenConfiguration } = useOpenConfiguration();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const { isConfigurationComplete, isConfigurationCreatedFromStock } = useSelector(
    modelSelectors.getVariables,
  );
  const { configurationNumber, configurationDetails } = useSelector(
    configurationSelectors.getAll,
  );
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const configurator = useSelector(streamingSelectors.getConfigurator);

  const isNewVersionButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderNewVersionButton,
  });
  const isCopyButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderCopyButton,
  });
  const isNewConfigurationButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderNewConfigurationButton,
  });
  const isHeaderMarginFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderMargin,
  });
  const isImportButtonFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderImportButton,
  });
  const isHeaderCurrencyFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHeaderCurrency,
  });
  const isStreamingFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowStreaming,
  });

  const isStreamingEnabled =
    !isConfigurationCreatedFromStock && isStreamingFeatureEnabled;

  const customLinkURL = globalFeatures?.[GlobalFeaturesFlagsFields.customLinkURL] ?? '';

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => setShowModal(true), []);

  const handleCloseModal = useCallback(() => setShowModal(false), []);

  const toggleShouldShowPricesWithVAT = useCallback(() => {
    dispatch(sharedActions.setShouldShowPricesWithVAT(!shouldShowPricesWithVAT));
  }, [dispatch, shouldShowPricesWithVAT]);

  const handleNewConfiguration = useCallback(() => {
    handleCloseModal();
    sessionHandleNewConfiguration();
  }, [handleCloseModal, sessionHandleNewConfiguration]);

  const handleConfigurationImport = useCallback(() => {
    setConfigurationImportModalState({ initialValues: {} });
  }, [setConfigurationImportModalState]);

  const handleConfigurationsList = useCallback(() => {
    handleCloseModal();
    history.push(configurationsListUrl);
  }, [handleCloseModal, history, configurationsListUrl]);

  const handleCustomLink = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const [configurationCopyModalState, handleConfigurationCopyModal] = useModalState<
    ComponentProps<typeof ConfigurationCopyModal>,
    Record<string, never>,
    ComponentProps<typeof ConfigurationCopyModal>
  >({});

  const handleMargin = useCallback(() => {
    setConfigurationMarginModalState({});
  }, [setConfigurationMarginModalState]);

  const handleCreateCopy = useCallback(
    async (
      configurationNumber: number,
      values: { language: string; quantity: number },
    ) => {
      const response = await copyConfiguration({
        configurationNumber: configurationNumber,
        languageCode: values.language,
        copiesQuantity: values.quantity,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationCopyModal(undefined);

      if (response?.firstConfigurationNumber) {
        await handleOpenConfiguration(response.firstConfigurationNumber);
      }
    },
    [copyConfiguration, handleOpenConfiguration, handleConfigurationCopyModal],
  );

  const handleClickCreateCopy = useCallback(() => {
    if (!configurationNumber) return;

    handleConfigurationCopyModal({
      defaultLanguageCode:
        configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
      onSubmit: values => handleCreateCopy(configurationNumber, values),
      onClose: () => handleConfigurationCopyModal(undefined),
    });
  }, [
    configurationNumber,
    handleConfigurationCopyModal,
    configurationDetails,
    handleCreateCopy,
  ]);

  const [configurationNewVersionModalState, handleConfigurationNewVersionModal] =
    useModalState<
      ComponentProps<typeof ConfigurationNewVersionModal>,
      Record<string, never>,
      ComponentProps<typeof ConfigurationNewVersionModal>
    >({});

  const handleCreateNewVersion = useCallback(
    async (configurationNumber: number, values: { language: string }) => {
      const response = await createNewVersion({
        configurationNumber: configurationNumber,
        languageCode: values.language,
      });

      if (response?.message?.length) {
        notification.open({ message: response.message });
      }

      handleConfigurationNewVersionModal(undefined);

      if (response?.firstConfigurationNumber) {
        await handleOpenConfiguration(response.firstConfigurationNumber);
      }
    },
    [createNewVersion, handleOpenConfiguration, handleConfigurationNewVersionModal],
  );

  const handleClickCreateNewVersion = useCallback(() => {
    if (!configurationNumber) return;

    handleConfigurationNewVersionModal({
      defaultLanguageCode:
        configurationDetails?.configuration[ConfigurationDetailsFields.languageCode],
      onSubmit: values => handleCreateNewVersion(configurationNumber, values),
      onClose: () => handleConfigurationNewVersionModal(undefined),
    });
  }, [
    configurationNumber,
    handleConfigurationNewVersionModal,
    configurationDetails,
    handleCreateNewVersion,
  ]);

  const handleLogout = useCallback(() => {
    logout({}, () => {
      const [terminal] = configurator?.terminals ?? [];

      if (terminal) {
        sendMessage({
          type: StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
          data: {
            customerId: terminal.customerId,
          },
        });
      }

      sendMessage({
        type: StreamingEventType.CLEAR_SLOTS,
      });

      storage.saveInLocalStorage(STORAGE_KEYS.connectionId, undefined);
    });
  }, [configurator, logout, sendMessage]);

  return (
    <>
      <ScMobileMenu data-testid="header-mobile-menu-button-container">
        <Menu onClick={handleOpenModal} data-testid="header-mobile-menu-button" />
      </ScMobileMenu>
      {showModal && (
        <MobileOverlayPage onClose={handleCloseModal}>
          <ScCurrentContainerWrapper>
            <CurrentContainer>{t('CURRENT_HOST', { host: hostname })}</CurrentContainer>
          </ScCurrentContainerWrapper>
          <ScCurrentContainerWrapper>
            <CurrentContainer>{t('CURRENT_USER', { user: username })}</CurrentContainer>
          </ScCurrentContainerWrapper>
          <div
            className="cp-c-column"
            data-testid="mobile-menu-modal-navigation-container"
          >
            <ConfigurationAction
              icon={
                <ScConfigurationActionIconWrapper>
                  <LinkIcon />
                </ScConfigurationActionIconWrapper>
              }
              onClick={handleConfigurationsList}
            >
              <ScConfigurationActionLabel>
                {t('CONFIGURATIONS')}
              </ScConfigurationActionLabel>
            </ConfigurationAction>

            {isCopyButtonFeatureEnabled && isConfigurationComplete && (
              <ConfigurationAction
                icon={
                  <ScConfigurationActionIconWrapper>
                    <LinkIcon />
                  </ScConfigurationActionIconWrapper>
                }
                disabled={!configurationNumber}
                onClick={handleClickCreateCopy}
              >
                <ScConfigurationActionLabel>
                  {t('COPY_ACTION')}
                </ScConfigurationActionLabel>
              </ConfigurationAction>
            )}

            {isNewVersionButtonFeatureEnabled && (
              <ConfigurationAction
                icon={
                  <ScConfigurationActionIconWrapper>
                    <LinkIcon />
                  </ScConfigurationActionIconWrapper>
                }
                disabled={!configurationNumber}
                onClick={handleClickCreateNewVersion}
              >
                <ScConfigurationActionLabel>
                  {t('NEW_VERSION_ACTION')}
                </ScConfigurationActionLabel>
              </ConfigurationAction>
            )}

            {isNewConfigurationButtonFeatureEnabled && (
              <ConfigurationAction
                onClick={handleNewConfiguration}
                icon={
                  <ScConfigurationActionIconWrapper>
                    <PlusIcon />
                  </ScConfigurationActionIconWrapper>
                }
              >
                <ScConfigurationActionLabel>
                  {t('NEW_CONFIGURATION')}
                </ScConfigurationActionLabel>
              </ConfigurationAction>
            )}

            {isHeaderMarginFeatureEnabled && (
              <ConfigurationAction
                onClick={handleMargin}
                icon={
                  <ScConfigurationActionIconWrapper>
                    <PercentIcon />
                  </ScConfigurationActionIconWrapper>
                }
              >
                <ScConfigurationActionLabel>
                  {t('CONFIGURATION_MARGIN')}
                </ScConfigurationActionLabel>
              </ConfigurationAction>
            )}

            {isImportButtonFeatureEnabled && allowImportConfiguration && (
              <ConfigurationAction
                onClick={handleConfigurationImport}
                icon={<ImportIcon />}
              >
                <ScConfigurationActionLabel>
                  {t('CONFIGURATION_IMPORT')}
                </ScConfigurationActionLabel>
              </ConfigurationAction>
            )}
            {customLinkURL && (
              <ScCustomLink href={customLinkURL} target="_blank">
                <ConfigurationAction onClick={handleCustomLink}>
                  <ScConfigurationActionLabel>
                    {t('CUSTOM_LINK')}
                  </ScConfigurationActionLabel>
                </ConfigurationAction>
              </ScCustomLink>
            )}

            <ConfigurationAction onClick={handleLogout} icon={<LogoutIcon />}>
              <ScConfigurationActionLabel>{t('LOG_OUT')}</ScConfigurationActionLabel>
            </ConfigurationAction>
          </div>
          <ScComplementActionWrapper>
            <ScToggleWrapper>
              {isStreamingEnabled && <StreamingToggle alignment="column" />}
              <Switcher
                alignment={isStreamingEnabled ? 'column' : 'row'}
                value={shouldShowPricesWithVAT}
                title={t(shouldShowPricesWithVAT ? 'VAT' : 'VAT_DISABLED')}
                onClick={toggleShouldShowPricesWithVAT}
              />
            </ScToggleWrapper>
            {isHeaderCurrencyFeatureEnabled && <Currency />}
            <Language />
          </ScComplementActionWrapper>
        </MobileOverlayPage>
      )}

      {configurationNewVersionModalState && (
        <ConfigurationNewVersionModal {...configurationNewVersionModalState} />
      )}
      {configurationCopyModalState && (
        <ConfigurationCopyModal {...configurationCopyModalState} />
      )}
    </>
  );
};

export default MobileMenu;
