import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ScStockBody,
  ScButton,
  ScStockFieldWrapper,
  ScStockHeader,
  ScStockMachineTriggerIcon,
  ScStockWrapper,
  ScTable,
  ScTableWrapper,
} from './StockMachineBuilder.styles';
import { Modal } from 'components/modal';
import { Form, FormikProvider, useFormik } from 'formik';
import FormField from 'components/form/formik/FormField';
import { StockMachine, StockMachineFields } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import useStockMachineFields from './use-stock-machine-fields';
import useStockMachineColumns from './use-stock-machine-columns';
import { useMachineApi } from 'context/machine/useMachineApi';
import { get, isEqual } from 'utils';
import { FILTER_SIGNS } from 'common/constants';
import { Col, Row } from 'antd';
import { Button } from 'components/button';
import { createPortal } from 'react-dom';
import useDebounce from 'hooks/useDebounce';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { featuresFlagsSelectors } from 'store';
import { useSelector } from 'react-redux';

type Props = {
  onBuild(machineNumber: string): Promise<void>;
};

const StockMachineBuilder: FC<Props> = ({ onBuild }) => {
  const { t } = useTranslation();
  const { getStockMachinesList } = useMachineApi();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const [isStockModalOpen, setIsStockModalOpen] = useState(false);
  const [stockMachines, setStockMachines] = useState<StockMachine[]>([]);
  const [isStockMachinesLoading, setIsStockMachinesLoading] = useState(false);
  const [selectedStockMachineNumber, setSelectedStockMachineNumber] = useState<
    string | null
  >(null);

  const defaultCustomerId = useMemo(() => {
    return globalFeatures?.[GlobalFeaturesFlagsFields.StockMachineDefaultCustomerId];
  }, [globalFeatures]);

  const defaultBrand = useMemo(() => {
    return globalFeatures?.[GlobalFeaturesFlagsFields.StockMachineDefaultBrand];
  }, [globalFeatures]);

  const handleSelectStockMachine = useCallback((currentMachineNumber: string) => {
    setSelectedStockMachineNumber(currentMachineNumber);
  }, []);

  const stockMachineFields = useStockMachineFields();
  const stockMachineColumns = useStockMachineColumns({
    handleSelect: handleSelectStockMachine,
    selectedStockMachineNumber,
  });

  const callback = useCallback(
    async (filters?: Parameters<typeof getStockMachinesList>[0]) => {
      try {
        setIsStockMachinesLoading(true);

        const response = await getStockMachinesList(filters);

        if (!response.response) {
          setStockMachines([]);
          return;
        }

        setStockMachines(response.response);
      } finally {
        setIsStockMachinesLoading(false);
        setSelectedStockMachineNumber(null);
      }
    },
    [getStockMachinesList],
  );

  const searchStockMachines = useDebounce<
    ReturnType<typeof callback>,
    Parameters<typeof callback>
  >(callback, 1000);

  const handleChange = useCallback(
    (event: React.ChangeEvent, formProps: ReturnType<typeof useFormik>) => {
      formProps.handleChange(event);
    },
    [],
  );

  const initialValues = useMemo(() => {
    const res = {} as ReturnType<typeof useFormik>['values'];
    if (stockMachineFields.some(item => item.name === StockMachineFields.isInStock)) {
      res[StockMachineFields.isInStock] = 'true';
    }

    if (
      stockMachineFields.some(item => item.name === StockMachineFields.customerNumber) &&
      defaultCustomerId
    ) {
      res[StockMachineFields.customerNumber] = defaultCustomerId;
    }

    if (
      stockMachineFields.some(item => item.name === StockMachineFields.brandName) &&
      defaultBrand
    ) {
      res[StockMachineFields.brandName] = defaultBrand;
    }

    return res;
  }, [stockMachineFields, defaultCustomerId, defaultBrand]);

  const formProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: initialValues,
    // need it as a plug here, as submit button is outside of the form
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!isStockModalOpen) formProps.resetForm();
  }, [isStockModalOpen, formProps.resetForm]);

  useEffect(() => {
    if (!isStockModalOpen) return;

    const filters = Object.entries(formProps.values).map(([name, value]) => {
      const field = stockMachineFields.find(
        field => field.name === name || field.subFields?.includes(name),
      );
      return {
        name: name as StockMachineFields,
        sign: field?.sign ?? FILTER_SIGNS.INCLUDES,
        value: value as string,
      };
    });

    searchStockMachines?.(filters);
  }, [formProps.values, isStockModalOpen, searchStockMachines, stockMachineFields]);

  const floatHolder = document.getElementById('float-holder');

  const stockMachineButton = floatHolder
    ? createPortal(
        <Button
          variant="highlighted"
          shape="circle"
          onClick={() => setIsStockModalOpen(true)}
        >
          <ScStockMachineTriggerIcon />
        </Button>,
        floatHolder,
      )
    : null;

  return (
    <>
      {stockMachineButton}

      <Modal
        variant="xl"
        center
        visible={isStockModalOpen}
        maskClosable
        onCancel={() => setIsStockModalOpen(false)}
        transparent
      >
        <ScStockWrapper>
          <ScStockHeader>
            <ScButton
              onClick={() => {
                formProps.setValues(initialValues);
              }}
              disabled={isEqual(initialValues, formProps.values)}
              variant="prev"
            >
              {t('CLEAR')}
            </ScButton>

            <ScButton
              onClick={() => {
                const stockMachine = stockMachines.find(
                  record =>
                    record[StockMachineFields.machineNumber] ===
                    selectedStockMachineNumber,
                );

                if (!stockMachine) return;

                onBuild(stockMachine[StockMachineFields.machineNumber]);
              }}
              disabled={!selectedStockMachineNumber}
              variant="highlighted"
            >
              {t('STOCK_BUILD')}
            </ScButton>
          </ScStockHeader>
          <ScStockBody>
            <FormikProvider value={formProps}>
              <Form>
                <Row gutter={[8, 0]}>
                  {stockMachineFields.map(({ withFormProps, ...field }) => {
                    return (
                      <Col span={6} key={field.name}>
                        <ScStockFieldWrapper>
                          {field.isCustom ? (
                            <field.component
                              allowClear={field.allowClear}
                              onChange={event => handleChange(event, formProps)}
                              label={field.label}
                            />
                          ) : (
                            <FormField
                              {...field}
                              {...(withFormProps ? { formProps: formProps } : {})}
                            />
                          )}
                        </ScStockFieldWrapper>
                      </Col>
                    );
                  })}
                </Row>
              </Form>
            </FormikProvider>
            <ScTableWrapper>
              <ScTable
                loading={isStockMachinesLoading}
                rowKey={record => get(record, StockMachineFields.machineNumber)}
                columns={stockMachineColumns}
                data={stockMachines}
                pageSize={9}
                scroll={{ x: 800 }}
                pagination
                showSizeChanger={false}
              />
            </ScTableWrapper>
          </ScStockBody>
        </ScStockWrapper>
      </Modal>
    </>
  );
};

export default StockMachineBuilder;
