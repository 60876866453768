import { useCallback, useEffect, useState } from 'react';
import { interval, NEVER, Subject } from 'rxjs';
import { scan, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';

interface ProgressSubject {
  isPaused: boolean;
  value: number;
}

interface ProgressParams {
  initialValue: number;
  upperBoundary: number;
  frequencyInMs: number;
  beforeStart(): void;
  afterFinish(): void;
  canProgress: boolean;
}

interface ProgressResult {
  value: number;
  pause(): void;
  resume(): void;
}

export const useProgress = (params: Partial<ProgressParams> = {}): ProgressResult => {
  const {
    initialValue = 0,
    frequencyInMs = 10,
    upperBoundary = 100,
    beforeStart,
    afterFinish,
    canProgress = true,
  } = params;

  const [value, setValue] = useState<number>(initialValue);
  const [subject] = useState<Subject<ProgressSubject>>(new Subject());

  const pause = useCallback(() => {
    subject.next({ isPaused: true, value });
  }, [subject, value]);

  const resume = useCallback(() => {
    subject.next({ isPaused: false, value });
  }, [subject, value]);

  useEffect(() => {
    if (beforeStart && value === 1 && canProgress) {
      beforeStart();
    }
  }, [beforeStart, value, canProgress]);

  /**
   * @description
   * Timeout-debounce here fixes the issue when deps change more frequently
   * than time changes - it fires `afterFinish` multiple times in row
   */
  useEffect(() => {
    if (afterFinish && value === upperBoundary && canProgress) {
      const timeout = setTimeout(afterFinish, frequencyInMs);
      return () => clearTimeout(timeout);
    }
  }, [afterFinish, value, upperBoundary, canProgress, frequencyInMs]);

  useEffect(() => {
    subject
      .pipe(
        startWith({ isPaused: !canProgress, value: initialValue }),
        scan((acc, val) => ({ ...acc, ...val })),
        tap(state => setValue(state.value)),
        switchMap(state =>
          state.isPaused
            ? NEVER
            : interval(frequencyInMs).pipe(
                takeWhile(() => state.value < upperBoundary),
                tap(() => {
                  state.value += 1;
                  setValue(state.value);
                }),
              ),
        ),
      )
      .subscribe();

    return () => {
      subject.complete();
    };
  }, [subject, initialValue, frequencyInMs, upperBoundary, canProgress]);

  return { value, pause, resume };
};
