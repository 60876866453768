import styled from 'styled-components';

// Common styles for image gallery vendor component
// it should be extended in order to inherit the colors
// and all needed styles to conform to customer theme
export const ScImageGalleryWrapper = styled.div`
  .image-gallery-icon {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }
  }

  .image-gallery-thumbnail {
    &:hover {
      border-color: ${({ theme }) => theme.mainColor};
    }

    &.active {
      border-color: ${({ theme }) => theme.mainColor};
    }
  }
`;

export const ScColumnTitle = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
`;
