import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { Price } from 'components/Price';
import { TotalTableRow } from '../../components/TotalTableRow';
import { useTotalPage } from '../../TotalPageProvider';
import { BannerSettings } from '../types';

type Props = BannerSettings;

const TotalGenericTotalExManufacturer: FC<Props> = () => {
  const { t } = useTranslation();

  const { FIELDS, totalData } = useTotalPage();

  return (
    <TotalTableRow
      data-testid="total-page-total-ex-manufacturer"
      borderBottom="thin"
      shadeNumbers
      fields={[
        t('TOTAL_HEADER_TOTAL_EX_MANUFACTURER'),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.totalExManufacturer, 0)} />,
      ]}
    />
  );
};

export default TotalGenericTotalExManufacturer;
