import {
  Configuration_GetDetailsById_Output_Configuration_Fields as ConfDetailsFields,
  Message,
  MessageCodeValues,
  MessageFields,
  ModelFields,
  ModelMachineConfigurationFields,
  ModelRemarkFields,
  ModelMachineConfiguration,
  ContactRoleListItem,
} from '@hypercharge/xdms-client/lib/types';
import * as Yup from 'yup';
import { CONFIGURATION_SOLD_STATUS_CODE } from 'common/constants';
import { useModelApi } from 'context/model/useModelApi';
import { FormikHelpers, useFormik, FormikProps } from 'formik';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { get, isEqual, omit, pick } from 'utils';
import { notesFieldsBuilder } from 'utils/format';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { useStep } from 'context/step/StepProvider';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import {
  InfoPageFormFields,
  InfoPageFormValues,
  InfoPageFormValues_Configuration,
  InfoPageFormValues_Model,
  infoPageInitialData,
} from './meta';
import { useSelector } from 'react-redux';
import { configurationSelectors, contactSelectors, modelSelectors } from 'store';
import { InfoPageUtils } from './utils';
import { useContact } from 'context/contact/ContactProvider';
import formFieldUtils from 'utils/formField';

type ContextValue = {
  t: TFunction;
  isConfigurationComplete: boolean;
  isConfigurationSold: boolean;
  formProps: FormikProps<InfoPageFormValues>;
  contactRoleList: ContactRoleListItem[];
  extendValidationSchema(extension: Yup.ObjectSchema): void;
};

const Context = createContext<ContextValue | undefined>(undefined);

const InfoFormProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { t } = useTranslation();

  const { modifyModelProperties } = useModelApi();
  const { handleNextStep } = useStep();
  const { updateConfiguration, getConfigurationDetailsById } = useConfiguration();
  const { getContactRoleList } = useContact();

  const { model, status, isConfigurationComplete } = useSelector(modelSelectors.getAll);
  const { configurationStatusCode, configurationNumber, configurationDetails } =
    useSelector(configurationSelectors.getAll);
  const { contactRoleList, contactRoleListStatus } = useSelector(contactSelectors.getAll);

  const isConfigurationSold = useMemo(() => {
    return configurationStatusCode === CONFIGURATION_SOLD_STATUS_CODE;
  }, [configurationStatusCode]);

  const [initialFormValues, setInitialFormValues] =
    useState<InfoPageFormValues>(infoPageInitialData);

  const [validationSchema, setValidationSchema] = useState<Yup.ObjectSchema>(
    Yup.object({}),
  );

  const extendValidationSchema = useCallback((extension: Yup.ObjectSchema) => {
    setValidationSchema(prevSchema => {
      // https://github.com/jquense/yup#schemaconcatschema-schema-schema
      return prevSchema.concat(extension);
    });
  }, []);

  const handleUpdateModel = useCallback(
    async (values: InfoPageFormValues_Model): Promise<Status | null> => {
      if (!model) return null;

      const { modelFieldsToUpdate, modelFieldsToCreate } =
        InfoPageUtils.prepareModelDataToUpdate(model, values);

      const updateResponse = await modifyModelProperties(
        model,
        modelFieldsToUpdate,
        modelFieldsToCreate,
      );

      if (!updateResponse.messageHandled) {
        notification.openByStatus(updateResponse.status, {
          [Status.Success]: t('INFO_REQUEST_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }

      return updateResponse.status;
    },
    [model, modifyModelProperties, t],
  );

  const handleUpdateConfiguration = useCallback(
    async (values: InfoPageFormValues_Configuration): Promise<void> => {
      await updateConfiguration({
        [ConfDetailsFields.conditionsDelivery]: values.conditionsDelivery,
        [ConfDetailsFields.name]: values.configurationName,
        [ConfDetailsFields.conditions]: values.conditions,
        [ConfDetailsFields.salesCode]: formFieldUtils.optionValueDecode(values.salesCode),
        [ConfDetailsFields.sellCode]: values.sellCode,
        [ConfDetailsFields.saleGroupCode]: values.saleGroupCode,
        [ConfDetailsFields.customerCommercialType]:
          values.configurationCustomerCommercialType,
      });

      if (configurationNumber)
        await getConfigurationDetailsById({ configurationNumber: configurationNumber });
    },
    [configurationNumber, getConfigurationDetailsById, updateConfiguration],
  );

  const handleSubmit = useCallback(
    async (values: InfoPageFormValues, actions: FormikHelpers<InfoPageFormValues>) => {
      try {
        const configurationUpdateFields = [
          InfoPageFormFields.conditions,
          InfoPageFormFields.conditionsDelivery,
          InfoPageFormFields.configurationName,
          InfoPageFormFields.salesCode,
          InfoPageFormFields.sellCode,
          InfoPageFormFields.saleGroupCode,
          InfoPageFormFields.configurationCustomerCommercialType,
        ];

        const mappedValues = {
          customerNotes: omit(
            values,
            configurationUpdateFields,
          ) as InfoPageFormValues_Model,
          configuration: pick(
            values,
            configurationUpdateFields,
          ) as InfoPageFormValues_Configuration,
        };

        const mappedValuesPrev = {
          customerNotes: omit(
            initialFormValues,
            configurationUpdateFields,
          ) as InfoPageFormValues_Model,
          configuration: pick(
            initialFormValues,
            configurationUpdateFields,
          ) as InfoPageFormValues_Configuration,
        };

        const shouldUpdateCustomerNotes = !isEqual(
          mappedValues.customerNotes,
          mappedValuesPrev.customerNotes,
        );
        const shouldUpdateConfiguration = !isEqual(
          mappedValues.configuration,
          mappedValuesPrev.configuration,
        );

        let nextStepTransitionAllowed = true;
        const resultingMessages: Message[] = [];

        actions.setSubmitting(true);

        if (shouldUpdateCustomerNotes) {
          const updateStatus = await handleUpdateModel(mappedValues.customerNotes);

          if (updateStatus === Status.Error) {
            nextStepTransitionAllowed = false;
            resultingMessages.push({
              [MessageFields.code]: MessageCodeValues.error,
              [MessageFields.text]: t('INFO_UPDATE_ERROR'),
            });
          }
        }

        if (shouldUpdateConfiguration) {
          await handleUpdateConfiguration(mappedValues.configuration);
        }

        if (resultingMessages.length) notification.open({ message: resultingMessages });

        actions.setSubmitting(false);

        if (nextStepTransitionAllowed) handleNextStep();
      } catch (e) {
        notification.requestError(e);
      }
    },
    [initialFormValues, handleNextStep, handleUpdateConfiguration, handleUpdateModel, t],
  );

  useEffect(() => {
    if (status === Status.Loading) return;

    const initialValues: Partial<InfoPageFormValues> = {};

    if (model) {
      const machineConfiguration: ModelMachineConfiguration | undefined = get(model, [
        ModelFields.machineConfiguration,
        0,
      ]);
      const { internalRemark, customerRemark } =
        InfoPageUtils.extractRemarksFromModel(model);
      const relatedContacts = InfoPageUtils.extractRelatedContactsFromModel(model);

      initialValues.customerNotes = notesFieldsBuilder(
        customerRemark?.[ModelRemarkFields.commentTitle] ?? '',
        customerRemark?.[ModelRemarkFields.commentDescription] ?? '',
      );
      initialValues.customerReference = get(
        machineConfiguration,
        ModelMachineConfigurationFields.customerReference,
        '',
      );
      initialValues.internalNotes = notesFieldsBuilder(
        internalRemark?.[ModelRemarkFields.commentTitle] ?? '',
        internalRemark?.[ModelRemarkFields.commentDescription] ?? '',
      );

      initialValues.deliveryDate =
        machineConfiguration?.[ModelMachineConfigurationFields.deliveryDate] ?? '';
      initialValues.contactRoles = relatedContacts;
    }

    if (configurationDetails) {
      const { configuration } = configurationDetails;

      initialValues.conditions = configuration[ConfDetailsFields.conditions] ?? '';
      initialValues.conditionsDelivery =
        configuration[ConfDetailsFields.conditionsDelivery] ?? '';
      initialValues.configurationName = configuration[ConfDetailsFields.name] ?? '';
      initialValues.salesCode = configuration[ConfDetailsFields.salesCode] ?? '';
      initialValues.sellCode = configuration[ConfDetailsFields.sellCode] ?? '';
      initialValues.saleGroupCode = configuration[ConfDetailsFields.saleGroupCode] ?? '';
      initialValues.configurationCustomerCommercialType =
        configuration[ConfDetailsFields.customerCommercialType] ?? '';
    }

    setInitialFormValues({ ...infoPageInitialData, ...initialValues });
  }, [model, status, configurationDetails]);

  useEffect(() => {
    if (contactRoleListStatus === Status.Idle) getContactRoleList();
  }, [contactRoleListStatus, getContactRoleList]);

  const formProps = useFormik<InfoPageFormValues>({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const value = useMemo(
    () => ({
      t,
      isConfigurationComplete,
      isConfigurationSold,
      formProps,
      contactRoleList,
      extendValidationSchema,
    }),
    [
      t,
      isConfigurationComplete,
      isConfigurationSold,
      formProps,
      contactRoleList,
      extendValidationSchema,
    ],
  );

  return <Context.Provider value={value} {...props} />;
};

const useInfoForm = (): ContextValue => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      `${useInfoForm.name} must be used within an ${InfoFormProvider.name}`,
    );
  }

  return context;
};

export { InfoFormProvider, useInfoForm };
