import {
  MachineListItemFields,
  TradeInListItemFields,
  MachineListItem,
} from '@hypercharge/xdms-client/lib/types';
import { TradeInBaseFields, TradeInFormFields } from 'context/tradeIn/TradeInProvider';
import { FormikProps } from 'formik';

export const fillFormFromMachine = (
  setFieldValue: FormikProps<TradeInBaseFields>['setFieldValue'],
  machine: MachineListItem,
): void => {
  setFieldValue(TradeInListItemFields.machineNumber, machine[MachineListItemFields.id]);
  setFieldValue(
    TradeInListItemFields.modelName,
    machine[MachineListItemFields.modelName],
  );
  setFieldValue(
    TradeInListItemFields.brandName,
    machine[MachineListItemFields.brandName],
  );

  setFieldValue(
    TradeInListItemFields.constructedAtYear,
    machine[MachineListItemFields.constructedAtYear] ?? undefined,
  );

  setFieldValue(
    TradeInListItemFields.workingHours,
    machine[MachineListItemFields.kilometrage],
  );
};

export const getNormalizedValues = (values: TradeInFormFields): TradeInFormFields => {
  return {
    ...values,
    purchaseValue: values?.purchaseValue ?? null,
    actualValue: values?.actualValue ?? null,
    workingHours: values?.workingHours ?? null,
  };
};
