import styled from 'styled-components';

export const ScPackageName = styled.span`
  font-weight: 600;
`;
export const ScPackageId = styled.span`
  font-weight: 600;
`;

export const ScPackageLineName = styled.span`
  padding-left: 1em;
  display: block;
  font-weight: 400;
`;

export const ScPackageLineId = styled.span`
  display: block;
  font-weight: 400;
`;
