import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { ReactComponent as AddCustomerSvgIcon } from '../../assets/icons/add-customer.svg';
import React from 'react';

export const ScAddCustomerTitleWrapper = styled.div`
  margin-bottom: 3.125em;
`;

export const ScAddCustomerTitle = styled.h2`
  font-size: 1.25em;
  max-width: 16em;
  text-align: center;
  margin-bottom: 0;
`;

export const ScAddCustomerContainer = styled.div`
  width: 32.375em;
  margin: 0 auto;
  margin-top: 10%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3.125em 1.938em 1.875em 1.938em;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScCustomerIcon = styled.div`
  background-color: ${({ theme }) => theme.mainColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.5em;
  height: 5.5em;
  margin-bottom: 3.125em;
`;

const AddCustomerIcon = props => <Icon component={AddCustomerSvgIcon} {...props} />;

export const ScAddCustomerIcon = styled(AddCustomerIcon)`
  font-size: 1.5em;

  path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.dustyGray : theme.mainColor_contrast};
  }
`;
