import React, { FC, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { DocumentTemplate } from 'types/vendor';
import { DocumentTemplateDropdown } from 'components/documentTemplate/DocumentTemplateDropdown';
import { Button } from 'components/button';

type Props = React.PropsWithChildren<{
  pdfTemplates: DocumentTemplate[];
  onSubmit(
    printTemplate: Parameters<
      React.ComponentProps<typeof DocumentTemplateDropdown>['onAction']
    >[0],
  ): void;
  onButtonClick?(): void;
  onMenuOpen(event: React.MouseEvent): Promise<boolean | undefined>;
  disabled: boolean;
  t: TFunction;
  isValid: boolean;
  'data-testid'?: string;
}>;
/** todo: make it a part of button-dropdown component */
export const TradeInSubmitAction: FC<Props> = ({
  pdfTemplates,
  onSubmit,
  onButtonClick,
  onMenuOpen,
  disabled,
  children,
  t,
  isValid,
  'data-testid': dataTestId,
}) => {
  const [visible, setVisible] = useState<boolean | undefined>();

  useEffect(() => {
    if (isValid) setVisible(false);
  }, [isValid]);

  return (
    <>
      {pdfTemplates.length <= 1 ? (
        <Button
          variant="highlighted"
          disabled={disabled}
          data-testid={dataTestId}
          htmlType="submit"
          onClick={onButtonClick}
        >
          {children}
        </Button>
      ) : (
        <DocumentTemplateDropdown
          visible={visible}
          templates={pdfTemplates}
          onAction={onSubmit}
          stubTitle={t('TOTAL_NOT_FOUND_TEMPLATES')}
          placement="topCenter"
          trigger={['click']}
          withConfirm={false}
          disabled={disabled}
        >
          <Button
            variant="highlighted"
            disabled={disabled}
            data-testid={dataTestId}
            htmlType="button"
            onClick={async e => {
              if (isValid) {
                setVisible(undefined);
                return;
              }
              const result = await onMenuOpen?.(e);
              setVisible(result);
            }}
          >
            {children}
          </Button>
        </DocumentTemplateDropdown>
      )}
    </>
  );
};
