import { gql } from '@apollo/client';

export const CarByTokenTypeDef = gql`
  extend type Query {
    carByToken(token: String!, marketOrLocale: Strinng!): Car!
  }

  # Output parameters

  type Car {
    visualizations(marketOrLocale: String!): [Visualization!]
  }

  type Visualization {
    views: Views!
  }

  type Views {
    exterior: ExteriorViewGroup!
    interior: InteriorViewGroup!
  }

  type ExteriorViewGroup {
    details: ExteriorStudioDetailsViewGroup
    studio: ExteriorStudioViewGroup
  }

  type InteriorViewGroup {
    studio: InteriorStudioViewGroup
  }

  type ExteriorStudioDetailsViewGroup {
    wheel: ImageGroup
  }

  type InteriorStudioViewGroup {
    driversView: ImageGroup
    rear: ImageGroup
  }

  type ImageGroup {
    sizes: ImageSizes
  }

  type ImageSizes {
    large: Image
  }

  type Large {
    url: String
  }
`;
