import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { SummaryField } from 'context/streaming/types';
import { ImageItem, PreviewImage } from 'types';
import { loadFromLocalStorage } from 'utils/storage';
import { STORAGE_KEYS } from 'common/constants';
import { isNil } from 'utils';

const NAME = 'streaming';

export enum SlotKey {
  Global = 'global',
  PriceList = 'priceList',
  Model = 'model',
  Summary = 'summary',
  Selection = 'selection',
}

export enum StreamingStatus {
  OFFLINE = 'offline',
  VACANT = 'vacant',
  BUSY = 'busy',
}

type BaseSlotEvent<N, D> = {
  name: N;
  data: Partial<D>;
};

export type GlobalSlotEvent = BaseSlotEvent<
  SlotKey.Global,
  {
    userName: string | null;
    modelNumber: string | null;
    modelName: string | null;
    isPublished: boolean;
    type: 'static' | 'dynamic';
  }
>;

type PriceListSlotEvent = BaseSlotEvent<
  SlotKey.PriceList,
  {
    priceListName: string;
    priceListImage: string;
    priceListDescription: string;
    modelYear: string;
    companyName: string;
  }
>;

type ModelSlotEvent = BaseSlotEvent<
  SlotKey.Model,
  {
    modelYear: string;
    type: string;
    position: number;
    value: string;
    previewImage: string;
  }
>;

type SummarySlotEvent = BaseSlotEvent<
  SlotKey.Summary,
  {
    documentSrc: string;
    fields: SummaryField[];
    imageSrc: string;
    configurationModelName: string;
  }
>;

type SelectionSlotEvent = BaseSlotEvent<
  SlotKey.Selection,
  {
    category: string | null;
    token: string;
    optionType: string | null;
  }
>;

type GlobalSlot = GlobalSlotEvent['data'];

type PriceListSlot = PriceListSlotEvent['data'];

type ModelSlot = {
  modelYear: ModelSlotEvent['data']['modelYear'];
  previewImage: string;
  selectedModel: string[][];
};

type SummarySlot = SummarySlotEvent['data'];

type SelectionSlot = SelectionSlotEvent['data'];

type Slot = GlobalSlot | PriceListSlot | ModelSlot | SummarySlot | SelectionSlot;

export type Configurator = {
  id: number;
  connectionId: string | null;
  status: StreamingStatus;
  terminals: Terminal[];
  isPublished: boolean;
  slots: {
    [key in SlotKey]: Slot;
  };
  createdAt: Date;
  updatedAt: Date;
  lastConnectionClosed: Date | null;
};

export type Terminal = {
  id: number;
  customerId: string;
  configurator: Configurator | null;
  configuratorId: number | null;
  host: string;
  status: StreamingStatus;
  createdAt: Date;
  screen: string;
  updatedAt: Date;
  // @todo: think whether we need it for other entities
  lastLoginAt: Date | null;
  connectionId: string | null;
  lastConnectionClosed: Date | null;
  flags: Partial<{
    type: 'static' | 'dynamic';
    withSummaryPrice: boolean;
  }>;
};

interface State {
  /* entity represeting currently logged in session to track terminals based on */
  readonly configurator: Configurator | null;
  readonly allTerminals: Terminal[];
  readonly isStreamBroken: boolean;
  readonly imageItems: ImageItem[];
  readonly previewImages: PreviewImage[];
  readonly isEnabled: boolean;
  readonly isStreamWaiting: boolean;
}

const isStreamingInitiallyEnabled = loadFromLocalStorage(
  STORAGE_KEYS.isStreamingInitiallyEnabled,
);

const initialState: State = {
  configurator: null,
  allTerminals: [],
  isStreamBroken: false,
  imageItems: [],
  previewImages: [],
  isEnabled: isNil(isStreamingInitiallyEnabled)
    ? false
    : !!Number(isStreamingInitiallyEnabled),
  isStreamWaiting: false,
};

const actions = {
  setConfigurator: createAction<State['configurator']>(
    getActionName(NAME, 'SET_CONFIGURATOR_ID'),
  ),
  setAllTerminals: createAction<State['allTerminals']>(
    getActionName(NAME, 'SET_ALL_TERMINALS'),
  ),
  setIsStreamBroken: createAction<State['isStreamBroken']>(
    getActionName(NAME, 'SET_IS_STREAM_BROKEN'),
  ),
  setIsStreamWaiting: createAction<State['isStreamWaiting']>(
    getActionName(NAME, 'SET_IS_STREAM_WAITING'),
  ),
  setImageItems: createAction<State['imageItems']>(
    getActionName(NAME, 'SET_IMAGE_ITEMS'),
  ),
  setPreviewImages: createAction<State['previewImages']>(
    getActionName(NAME, 'SET_PREVIEW_IMAGES'),
  ),
  setIsEnabled: createAction<State['isEnabled']>(getActionName(NAME, 'SET_IS_ENABLED')),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setAllTerminals, (state, action) => ({
    ...state,
    allTerminals: action.payload,
  }));
  builder.addCase(actions.setConfigurator, (state, action) => ({
    ...state,
    configurator: action.payload,
  }));
  builder.addCase(actions.setIsStreamBroken, (state, action) => ({
    ...state,
    isStreamBroken: action.payload ?? false,
  }));
  builder.addCase(actions.setIsStreamWaiting, (state, action) => ({
    ...state,
    isStreamWaiting: action.payload ?? false,
  }));
  builder.addCase(actions.setImageItems, (state, action) => ({
    ...state,
    imageItems: action.payload,
  }));
  builder.addCase(actions.setPreviewImages, (state, action) => ({
    ...state,
    previewImages: action.payload,
  }));
  builder.addCase(actions.setIsEnabled, (state, action) => ({
    ...state,
    isEnabled: action.payload,
  }));
});

const selectors = {
  getConfigurator: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.configurator,
  ),
  getAllTerminals: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.allTerminals,
  ),
  getGlobalSlot: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => (state.configurator?.slots.global ?? null) as GlobalSlot | null,
  ),
  getIsStreamBroken: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.isStreamBroken,
  ),
  getIsStreamWaiting: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.isStreamWaiting,
  ),
  getPreferredTerminal: createSelector(
    ({ streaming, auth }: ApplicationState) => ({
      ...streaming,
      username: auth.username,
    }),
    state =>
      // @todo: once query values are part of redux
      // add condition to encounter for STREAM query param in addition to username
      state.allTerminals.find(terminal => terminal.customerId === state.username),
  ),
  getActiveTerminal: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.configurator?.terminals?.[0] ?? null,
  ),
  getImageItems: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.imageItems,
  ),
  getPreviewImages: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.previewImages,
  ),
  getIsEnabled: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.isEnabled,
  ),
  getCurrentScreen: createSelector(
    ({ streaming }: ApplicationState) => streaming,
    state => state.configurator?.terminals?.[0]?.screen ?? null,
  ),
};

export type { State as StreamingState };
export {
  actions as streamingActions,
  reducer as streamingReducer,
  selectors as streamingSelectors,
};
