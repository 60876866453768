import React, { FC, HTMLProps } from 'react';
import { ConfigurationRelation } from 'context/relations/types';
import { ScLabel, ScRightCol, ScSwapListItem } from './styles';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CustomerFields } from 'types/vendor';
import Checkbox from 'components/form/Checkbox';

interface SwapListItemProps extends Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'> {
  relation: ConfigurationRelation;
  selected: boolean;
  onSelect?(): void;
}

export const ListItem: FC<SwapListItemProps> = ({
  relation,
  selected,
  onSelect,
  ...props
}) => {
  const { t } = useTranslation();

  const id = relation.customer?.[CustomerFields.id];

  return (
    <ScSwapListItem
      {...props}
      data-select={selected}
      onClick={e => {
        e.preventDefault();
        onSelect?.();
      }}
    >
      <Row>
        <Col span="20">
          <ScLabel>{`${t('CURRENT')} ${t(relation.titleTKey)}`}</ScLabel>
        </Col>
        {id && (
          <ScRightCol span="4">
            <ScLabel>{t('CUSTOMER_ID')}</ScLabel>
          </ScRightCol>
        )}
      </Row>
      <Row>
        <Col span="20">
          <Checkbox checked={selected} circle>
            {relation.customer?.[CustomerFields.name]}
          </Checkbox>
        </Col>
        {id && <ScRightCol span="4">{id}</ScRightCol>}
      </Row>
    </ScSwapListItem>
  );
};
