import styled from 'styled-components';

export const ScTableViewSwitcherButton = styled.div`
  width: 2.5em;
  height: 100%;
  max-height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.mainColor};

    > svg > path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.active.disabled {
    background-color: ${({ theme }) => theme.colors.dustyGray};
  }
`;
