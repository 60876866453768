import { TableColumn } from '.';
import { isNil } from 'utils';

const selectPropertyWithIndexFabric = (property: string) => {
  return (columns: TableColumn[], shift = 0): [number, string][] => {
    return columns
      .map((item, idx) => [idx + shift, item[property]] as [number, string])
      .filter(([_, value]) => !isNil(value) && value !== '');
  };
};

export const selectMaxWidths = selectPropertyWithIndexFabric('maxWidth');
export const selectMinWidths = selectPropertyWithIndexFabric('minWidth');
