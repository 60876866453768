import { Model } from '@hypercharge/xdms-client/lib/types';
import {
  DocumentItem,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
  DocumentsSwarm,
} from 'context/document/types';

export type DocumentsTreeData = DocumentTreeDataEntityGroup[];

type DocumentTreeDataEntityGroup = {
  relatedEntityCode: DocumentRelatedEntityCode;
  relatedEntities: DocumentTreeDataEntityItem[];
  // added for render simplicity, maybe better to get rid - pass full settings
  tKey: string;
  // added for render simplicity, maybe better to get rid - pass full settings
  nameField: string;
  // added for render simplicity, maybe better to get rid - pass full settings
  idField: string;
};

type DocumentTreeDataEntityItem = {
  isOpen: boolean;
  relatedEntity: DocumentRelatedEntity;
  selectedIds: (string | number)[];
  documents: DocumentItem[];
};

export enum DocumentTreeDataActionType {
  initFromTree,
  initFromSwarm,
  selectDocument,
  selectGroup,
  toggleOpenGroup,
}

export type DocumentTreeDataAction =
  | {
      type: DocumentTreeDataActionType.initFromTree;
      tree: DocumentsTreeData;
    }
  | {
      type: DocumentTreeDataActionType.initFromSwarm;
      model?: Model;
      documentsSwarm: DocumentsSwarm;
    }
  | {
      type: DocumentTreeDataActionType.toggleOpenGroup;
      relatedEntityCode: DocumentRelatedEntityCode;
      relatedEntity: DocumentRelatedEntity;
    }
  | {
      type: DocumentTreeDataActionType.selectGroup;
      relatedEntityCode: DocumentRelatedEntityCode;
      relatedEntity: DocumentRelatedEntity;
    }
  | {
      type: DocumentTreeDataActionType.selectDocument;
      relatedEntityCode: DocumentRelatedEntityCode;
      relatedEntity: DocumentRelatedEntity;
      document: DocumentItem;
    };
