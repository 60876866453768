import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { FC, PropsWithChildren, ComponentProps } from 'react';

// @todo: antd@5
export const ScBreadcrumb: FC<
  PropsWithChildren<ComponentProps<typeof Breadcrumb>>
> = styled(Breadcrumb)`
  height: 1.875em;
  font-size: 0.75em;
  padding: 0 1.25em 0.625em 1.25em;
`;

export const ScBreadcrumbItem: FC<
  PropsWithChildren<ComponentProps<typeof Breadcrumb.Item>>
> = styled(Breadcrumb.Item)``;
