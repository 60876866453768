import { Form } from 'antd';
import styled from 'styled-components';

export const ScFormItem = styled(Form.Item)`
  &.ant-form-item {
    flex-direction: column;

    .ant-form-item-label {
      > label {
        font-size: ${({ theme }) => theme.inputFontSize};
        color: ${({ theme }) => theme.textColor};
        height: auto;
        margin-bottom: 0.438em;
      }
    }
  }

  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100%;
  }
  &.ant-form-item .ant-form-item-explain-error {
    font-size: 0.625em;
    font-weight: 500;
    color: ${({ theme }) => theme.fieldErrorColor};
  }
`;
