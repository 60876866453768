import { Input } from 'antd';
import styled from 'styled-components';
import { ScModalFooter } from 'components/modalLike.styles';
import Tabs from 'components/Tabs';

export const ScTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      height: 3px;
    }

    .ant-tabs-tab {
      flex: 1;
      max-width: calc(100% / 3);
      padding: 1.75em 1em;
    }

    .ant-tabs-tab-btn {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-tabs-tabpane {
    .ant-input {
      padding: 0.75em 1em;
      font-size: 0.875em;
      line-height: 1.375em;
      font-weight: 400;
    }
  }
`;

export const ScTextArea = styled(Input.TextArea)`
  border-color: ${({ theme }) => theme.colors.softPeach};
  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.mainColor};
    box-shadow: none;
  }

  &[disabled],
  &[disabled]:hover {
    color: rgba(0, 0, 0, 0.7);
    background-color: ${({ theme }) => theme.alternativeColor};
    border-color: ${({ theme }) => theme.colors.softPeach};
  }
`;

export const ScTabPaneContent = styled.div`
  padding: 0 1.25em;
`;

export const ScCommentsModalFooter = styled(ScModalFooter)`
  padding-top: 0;
`;
