import React, { FC, HTMLProps } from 'react';
import { ContainerSize } from 'common/theme';
import { ScContainer } from './Container.styles';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  size?: ContainerSize;
  ref?: never;
  as?: never;
}
const Container: FC<Props> = ({ size, ...props }) => {
  return <ScContainer $size={size} {...props} />;
};

export default Container;
