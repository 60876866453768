import styled from 'styled-components';

export const ScFloatHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: fixed;
  bottom: 2em;
  left: 2em;
  z-index: 3;
`;
