import * as Sentry from '@sentry/react';
import { notification } from './notification';
import { NotificationType } from './notification';

const saveInStorage = (
  storage: Storage | undefined,
  storageKey: string,
  data: unknown | undefined,
): void => {
  try {
    if (storage) {
      if (data != null && data !== undefined) {
        storage.setItem(
          storageKey,
          typeof data === 'object' ? JSON.stringify(data) : String(data),
        );
      } else {
        storage.removeItem(storageKey);
      }
    }
  } catch (e) {
    Sentry.captureException(e);
    notification.open({
      message: 'STORAGE_ACCESS_ERROR',
      type: NotificationType.error,
    });
  }
};

const loadFromStorage = <T>(
  storage: Storage | undefined,
  localStorageKey: string,
): T | null | string => {
  try {
    if (storage) {
      const dataStr = storage.getItem(localStorageKey);
      if (!dataStr) return null;

      let result: T | null | string;
      try {
        result = JSON.parse(dataStr);
      } catch (e) {
        result = dataStr;
      }
      return result;
    } else {
      return null;
    }
  } catch (e) {
    Sentry.captureException(e);
    notification.open({
      message: 'STORAGE_ACCESS_ERROR',
      type: NotificationType.error,
    });
    return null;
  }
};

export const clearLocalStorage = <T>(): T | void => {
  try {
    window.localStorage.clear();
  } catch (e) {
    Sentry.captureException(e);
    notification.open({
      message: 'STORAGE_ACCESS_ERROR',
      type: NotificationType.error,
    });
  }
};

export const saveInLocalStorage = (storageKey: string, data: unknown | undefined): void =>
  saveInStorage(window.localStorage, storageKey, data);

export const loadFromLocalStorage = <T>(storageKey: string): T | null | string =>
  loadFromStorage<T>(window.localStorage, storageKey) ?? null;
