import { CheckboxProps } from 'antd/es/checkbox';
import React, { FC } from 'react';
import { ScCheckbox, ScCheckboxGroup } from './Checkbox.styles';

interface Props extends CheckboxProps {
  circle?: boolean;
}

const Checkbox: FC<Props> & { Group: typeof ScCheckboxGroup } = ({
  children,
  circle = false,
  value,
  checked,
  ...props
}) => {
  return (
    <ScCheckbox
      indeterminate={false}
      $circle={circle}
      checked={value || checked}
      {...props}
    >
      {children}
    </ScCheckbox>
  );
};

Checkbox.Group = ScCheckboxGroup;

export default Checkbox;
