import {
  PrepareAction,
  createAction,
  createReducer,
  createSelector,
} from '@reduxjs/toolkit';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { Location } from 'history';
import { Status } from 'utils/types';
import { STORAGE_KEYS } from 'common/constants';

const NAME = 'shared';

interface State {
  /** Tricky state.
   * XmdsClient is fully settled when additional 'environment constants'
   * retrieved in FeatureFlags */
  readonly isXdmsClientSettled: boolean;
  readonly isI18nSettled: boolean;
  readonly isServerDown: boolean;
  /** Entry URL. Used to redirect user after login. */
  readonly initialURL?: Location;
  readonly authenticatedStatusesList: Status[];
  readonly unauthenticatedStatusesList: Status[];
  readonly shouldShowPricesWithVAT: boolean;
}

const initialState: State = {
  isXdmsClientSettled: false,
  isI18nSettled: false,
  isServerDown: false,
  authenticatedStatusesList: [],
  unauthenticatedStatusesList: [],
  shouldShowPricesWithVAT: localStorage.getItem(STORAGE_KEYS.vatEnabled) === 'true',
};

const actions = {
  setIsXdmsClientSettled: createAction<State['isXdmsClientSettled']>(
    getActionName(NAME, 'SET_IS_XDMS_CLIENT_SETTLED'),
  ),
  setIsI18nSettled: createAction<State['isI18nSettled']>(
    getActionName(NAME, 'SET_IS_I18N_SETTLED'),
  ),
  setInitialLocation: createAction<State['initialURL']>(
    getActionName(NAME, 'SET_INITIAL_URL'),
  ),
  setIsServerDown: createAction<State['isServerDown']>(
    getActionName(NAME, 'SET_IS_SERVER_DOWN'),
  ),
  setAuthenticatedStatusesList: createAction<State['authenticatedStatusesList']>(
    getActionName(NAME, 'SET_AUTHENTICATED_STATUSES_LIST'),
  ),
  setUnauthenticatedStatusesList: createAction<State['unauthenticatedStatusesList']>(
    getActionName(NAME, 'SET_UNAUTHENTICATED_STATUSES_LIST'),
  ),
  setShouldShowPricesWithVAT: createAction<
    PrepareAction<State['shouldShowPricesWithVAT']>
  >(getActionName(NAME, 'SET_SHOULD_SHOW_PRICES_WITH_VAT'), value => {
    localStorage.setItem(STORAGE_KEYS.vatEnabled, value);
    return { payload: value };
  }),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setIsXdmsClientSettled, (state, action) => ({
    ...state,
    isXdmsClientSettled: action.payload,
  }));
  builder.addCase(actions.setIsI18nSettled, (state, action) => ({
    ...state,
    isI18nSettled: action.payload,
  }));
  builder.addCase(actions.setInitialLocation, (state, action) => ({
    ...state,
    initialURL: action.payload,
  }));
  builder.addCase(actions.setIsServerDown, (state, action) => ({
    ...state,
    isServerDown: action.payload,
  }));
  builder.addCase(actions.setAuthenticatedStatusesList, (state, action) => ({
    ...state,
    authenticatedStatusesList: action.payload,
  }));
  builder.addCase(actions.setUnauthenticatedStatusesList, (state, action) => ({
    ...state,
    unauthenticatedStatusesList: action.payload,
  }));
  builder.addCase(actions.setShouldShowPricesWithVAT, (state, action) => ({
    ...state,
    shouldShowPricesWithVAT: action.payload,
  }));
});

const selectors = {
  getIsXdmsClientSettled: createSelector(
    ({ shared }: ApplicationState) => shared,
    state => state.isXdmsClientSettled,
  ),
  getIsI18nSettled: createSelector(
    ({ shared }: ApplicationState) => shared,
    state => state.isI18nSettled,
  ),
  getInitialLocation: createSelector(
    ({ shared }: ApplicationState) => shared,
    state => state.initialURL,
  ),
  getIsServerDown: createSelector(
    ({ shared }: ApplicationState) => shared,
    state => state.isServerDown,
  ),
  getIsLoaderVisible: createSelector(
    ({ shared }: ApplicationState) => shared,
    state =>
      [...state.authenticatedStatusesList, ...state.unauthenticatedStatusesList].includes(
        Status.Loading,
      ),
  ),
  getShouldShowPricesWithVAT: createSelector(
    ({ shared }: ApplicationState) => shared,
    state => state.shouldShowPricesWithVAT,
  ),
};

export type { State as SharedState };
export {
  actions as sharedActions,
  reducer as sharedReducer,
  selectors as sharedSelectors,
};
