import {
  createAction,
  createReducer,
  createSelector,
  PrepareAction,
} from '@reduxjs/toolkit';
import {
  defaultImage,
  DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE,
  DEFAULT_FAVICON,
  STORAGE_KEYS,
} from 'common/constants';
import {
  DEFAULT_HOST_THEME,
  HostSettingsExternal,
  HostTheme,
} from 'common/hostsSettings';
import { shallowEqual } from 'react-redux';
import { Status } from 'utils/types';
import { isEqual } from '../../utils';
import { ApplicationState } from '../index';
import { addBaseReducerCases, BaseState, getActionName, getBaseActions } from '../utils';
import {
  detectCustomerId,
  detectHost,
  detectHostSettings,
  getHostThemeFromExternalSettings,
} from './utils';

const NAME = 'hostSettings';

interface State extends BaseState {
  /** Used for dev server. Regulates a theme and featuresFlags selection.
   * @example 'volvo' */
  readonly customerId: string;
  readonly host: undefined | string | null;
  /** Allows to prevent exhaustive effect call responsible for setting host from query.
   * That malicious behaviour is caused by rerender of {@link UnauthenticatedSessionProvider}
   * when hostSetting change. */
  readonly isHostFromQuerySettled: boolean;
  readonly favicon: string;
  readonly hostTheme: HostTheme;
  readonly primaryLogo: string;
  readonly secondaryLogo: string;
  readonly translationsLocation?: string;
  readonly availableLanguages?: { code: string; label: string; importCode: string }[];
  readonly background: HostSettingsExternal['background'];
  readonly backgroundCommon: HostSettingsExternal['backgroundCommon'];
  readonly resizableBackgroundFallback: HostSettingsExternal['resizableBackgroundFallback'];
}

const storedHostSettings = detectHostSettings();
const hostTheme = storedHostSettings
  ? getHostThemeFromExternalSettings(storedHostSettings)
  : DEFAULT_HOST_THEME;

const initialState: State = {
  /** Used for dev environment to select needed theme, globalFeaturesFlags,
   * dynamicPagesFeaturesFlags. Dev server may contain a list of such sets. */
  customerId: detectCustomerId(),
  host: detectHost(),
  isHostFromQuerySettled: false,
  status: Status.Idle,
  hostTheme: hostTheme,
  favicon: storedHostSettings?.favicon ?? DEFAULT_FAVICON,
  primaryLogo: storedHostSettings?.primaryLogo ?? defaultImage,
  secondaryLogo: storedHostSettings?.secondaryLogo ?? defaultImage,
  translationsLocation: storedHostSettings?.translationsLocation,
  availableLanguages: storedHostSettings?.availableLanguages,
  background: storedHostSettings?.background ?? {
    background: DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE,
  },
  backgroundCommon: storedHostSettings?.backgroundCommon,
  resizableBackgroundFallback:
    storedHostSettings?.resizableBackgroundFallback ?? defaultImage,
};

const actions = {
  ...getBaseActions(NAME),
  setFromExternalSettings: createAction<PrepareAction<HostSettingsExternal>>(
    getActionName(NAME, 'SET_HOST_SETTINGS'),
    (value: HostSettingsExternal) => {
      if (value)
        localStorage.setItem(STORAGE_KEYS.hostSettingsExternal, JSON.stringify(value));
      return { payload: value };
    },
  ),
  setCustomerId: createAction<PrepareAction<string>>(
    getActionName(NAME, 'SET_CUSTOMER_ID'),
    (value: string) => {
      if (value) localStorage.setItem(STORAGE_KEYS.customerId, value);

      return { payload: value };
    },
  ),
  setHost: createAction<PrepareAction<string>>(
    getActionName(NAME, 'SET_HOST'),
    (value: string) => {
      const storedHost = localStorage.getItem(STORAGE_KEYS.host);

      if (storedHost !== value) {
        localStorage.removeItem(STORAGE_KEYS.baseUrl);
        localStorage.removeItem(STORAGE_KEYS.customerId);
        localStorage.removeItem(STORAGE_KEYS.configurationNumber);
      }

      localStorage.setItem(STORAGE_KEYS.host, value);

      return { payload: value };
    },
  ),
  setIsHostFromQuerySettled: createAction<boolean>(
    getActionName(NAME, 'SET_IS_HOST_FROM_QUERY_SETTLED'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  addBaseReducerCases(builder, actions);

  builder.addCase(actions.setFromExternalSettings, (state, action) => {
    return {
      ...state,
      background: action.payload.background ?? {
        background: DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE,
      },
      favicon: action.payload.favicon ?? DEFAULT_FAVICON,
      backgroundCommon: action.payload.backgroundCommon,
      primaryLogo: action.payload.primaryLogo ?? defaultImage,
      secondaryLogo: action.payload.secondaryLogo ?? defaultImage,
      translationsLocation: action.payload.translationsLocation,
      availableLanguages: action.payload.availableLanguages ?? [],
      hostTheme: getHostThemeFromExternalSettings(action.payload),
      resizableBackgroundFallback:
        action.payload.resizableBackgroundFallback ?? defaultImage,
    };
  });

  builder.addCase(actions.setCustomerId, (state, action) => ({
    ...state,
    customerId: action.payload,
  }));

  builder.addCase(actions.setHost, (state, action) => ({
    ...state,
    host: action.payload,
  }));
  builder.addCase(actions.setIsHostFromQuerySettled, (state, action) => ({
    ...state,
    isHostFromQuerySettled: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    all => all,
  ),
  getPrimaryLogo: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.primaryLogo,
  ),
  getStatus: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.status,
  ),
  /** This state is sensitive to updates, so added additional memoize.
   * Used to make malicious updates of array without actual data update.
   * @see https://github.com/reduxjs/reselect/pull/513 */
  getLanguageSettings: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => ({
      translationsLocation: state.translationsLocation,
      availableLanguages: state.availableLanguages,
    }),
    {
      memoizeOptions: {
        equalityCheck: (a, b) => {
          return (
            isEqual(a.translationsLocation, b.translationsLocation) &&
            isEqual(a.availableLanguages, b.availableLanguages)
          );
        },
        resultEqualityCheck: shallowEqual,
      },
    },
  ),
  getSecondaryLogo: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.secondaryLogo,
  ),
  getBackground: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.background,
  ),
  getBackgroundCommon: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.backgroundCommon,
  ),
  getHostTheme: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.hostTheme,
  ),
  getCustomerId: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.customerId,
  ),
  getHost: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.host,
  ),
  getIsHostFromQuerySettled: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.isHostFromQuerySettled,
  ),
  getResizableBackgroundFallback: createSelector(
    ({ hostSettings }: ApplicationState) => hostSettings,
    state => state.resizableBackgroundFallback,
  ),
};

export type { State as HostSettingsState };
export {
  actions as hostSettingsActions,
  reducer as hostSettingsReducer,
  selectors as hostSettingsSelectors,
};
