import * as Sentry from '@sentry/react';
import { ConfigurationRelation } from './types';
import { cloneDeep } from 'utils';
import {
  Configuration_GetDetailsById_Output,
  Configuration_GetDetailsById_Output_Configuration_Fields,
  Configuration_GetDetailsById_Output_Relation_Fields,
  CONFIGURATION_PRIMARY_CONTACT_MARK,
} from '@hypercharge/xdms-client/lib/types';
import { ContactFields, ContactItem, CustomerItem } from 'types/vendor';

import { DEFAULT_CUSTOMER_ID } from 'common/constants';
import { isRelationId } from './utils';

/**
 * Clone relations list (to mutate it) and find relation by id
 * @param {ConfigurationRelation[]} relations
 * @param {number | string} id
 * @return {[ConfigurationRelation[], ConfigurationRelation]}
 */
const cloneAndFindRelationById = (
  relations: ConfigurationRelation[],
  id: number | string,
): [ConfigurationRelation[], ConfigurationRelation] | null => {
  const clonedRelations = cloneDeep(relations);
  const relation = clonedRelations.find(rel => rel.id === id);

  if (!relation) {
    console.warn('Unknown relation used. Check input params: ', relations, id);
    return null;
  }

  return [clonedRelations, relation];
};

const setRelationCustomer = (id: number | string, customer: CustomerItem | null) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const searchResult = cloneAndFindRelationById(relations, id);

    if (!searchResult) return relations;

    const [clonedRelations, relation] = searchResult;

    relation.customer = customer;

    return clonedRelations;
  };
};

const setRelationPrimaryContact = (id: number | string, contact: ContactItem | null) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const searchResult = cloneAndFindRelationById(relations, id);

    if (!searchResult) return relations;

    const [clonedRelations, relation] = searchResult;

    relation.primaryContact = contact;

    return clonedRelations;
  };
};

const setRelationAdditionalContacts = (id: number | string, contacts: ContactItem[]) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const searchResult = cloneAndFindRelationById(relations, id);

    if (!searchResult) return relations;

    const [clonedRelations, relation] = searchResult;

    relation.additionalContacts = contacts;

    return clonedRelations;
  };
};

const addRelationAdditionalContact = (id: number | string, contact: ContactItem) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const searchResult = cloneAndFindRelationById(relations, id);

    if (!searchResult) return relations;

    const [clonedRelations, relation] = searchResult;

    relation.additionalContacts.push(contact);

    return clonedRelations;
  };
};

const removeRelationAdditionalContact = (id: number | string, additionalId: number) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const searchResult = cloneAndFindRelationById(relations, id);

    if (!searchResult) return relations;

    const [clonedRelations, relation] = searchResult;

    const additionalContactIdx = relation.additionalContacts.findIndex(
      contact => contact[ContactFields.ID] === additionalId,
    );

    if (~additionalContactIdx) {
      relation.additionalContacts.splice(additionalContactIdx, 1);
    }

    return clonedRelations;
  };
};

const unsetRelations = () => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    return relations.map(relation => ({
      ...relation,
      customer: null,
      primaryContact: null,
      additionalContacts: [],
    }));
  };
};

const setupRelationsFromGlobalFeatures = (
  globalFeatures: Record<string, unknown>,
  customerId: string | null | undefined,
) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    /** get id's of enabled relations */
    const enabledRelations = Object.keys(globalFeatures)
      .filter(isRelationId)
      .filter(key => globalFeatures[key])
      .map(String);

    return enabledRelations.map((id, idx) => {
      const plainIdx = idx + 1;
      return {
        id: id,
        idx: idx,
        /** @todo #oneday. When BE will add translations labels - we'll rework it a bit. */
        titleTKey: `TEMP_${(
          customerId ?? DEFAULT_CUSTOMER_ID
        ).toUpperCase()}_RELATION_TITLE_${plainIdx}`,
        mandatory: Boolean(globalFeatures[`YNRelation${plainIdx}Mandatory`]),
        fields: {
          whereToSaveCustomerId: `customerNumber${plainIdx === 1 ? '' : plainIdx}`,
          whereToSavePrimaryContactId: plainIdx === 1 ? `contactNumber` : null,
          whereToSaveAdditionalContactsIds: `additionalContactsNumbers${
            plainIdx === 1 ? '' : plainIdx
          }`,
          customerIdFromConfigurationDetails: `NRcust${plainIdx === 1 ? '' : plainIdx}`,
        },
        customer: relations[idx]?.customer ?? null,
        primaryContact: relations[idx]?.primaryContact ?? null,
        additionalContacts: relations[idx]?.additionalContacts ?? [],
      };
    });
  };
};

const restoreRelationsByConfigurationDetails = async (
  configuration: Configuration_GetDetailsById_Output,
  relations: ConfigurationRelation[],
  getCustomerById: (id: string) => Promise<CustomerItem | null>,
  getContactById: (id: string) => Promise<ContactItem | null>,
): Promise<ConfigurationRelation[]> => {
  const RelationRecFields = Configuration_GetDetailsById_Output_Relation_Fields;
  const ConfigurationFields = Configuration_GetDetailsById_Output_Configuration_Fields;

  const clonedRelations: ConfigurationRelation[] = relations.map(relation => ({
    ...relation,
    customer: null,
    primaryContact: null,
    additionalContacts: [],
  }));

  for await (const relation of clonedRelations) {
    // customer
    {
      const customerId =
        configuration.configuration[relation.fields.customerIdFromConfigurationDetails];

      if (customerId) relation.customer = await getCustomerById(String(customerId));
    }

    // primary contact
    {
      let contactId: string | number | undefined;

      // this 'if' is an exception: for first relation should take contactId from configuration details (not from relations)
      if (
        relation.fields.customerIdFromConfigurationDetails ===
        ConfigurationFields.customerNumber
      ) {
        contactId = configuration.configuration[ConfigurationFields.contactNumber];
      } else {
        const relationRecord = configuration.relations.find(record => {
          const isPrimaryContact =
            record[RelationRecFields.priority] === CONFIGURATION_PRIMARY_CONTACT_MARK;
          const isRelatedToThisRelation =
            record[RelationRecFields.typeCode] ===
            relation.fields.customerIdFromConfigurationDetails;

          return isPrimaryContact && isRelatedToThisRelation;
        });

        if (relationRecord) {
          contactId = relationRecord[RelationRecFields.contactNumber];
        }
      }

      if (contactId) {
        relation.primaryContact = await getContactById(String(contactId));
      }
    }

    // additional contacts
    {
      const ids = configuration.relations
        .filter(record => {
          const isPrimaryContact =
            record[RelationRecFields.priority] === CONFIGURATION_PRIMARY_CONTACT_MARK;
          const isRelatedToThisRelation =
            record[RelationRecFields.typeCode] ===
            relation.fields.customerIdFromConfigurationDetails;

          return !isPrimaryContact && isRelatedToThisRelation;
        })
        .map(record => record[RelationRecFields.contactNumber]);

      for await (const id of ids) {
        const contact = await getContactById(String(id));

        if (contact) relation.additionalContacts.push(contact);
        else {
          Sentry.captureException(`Unknown contact requested: ${id}`);
        }
      }
    }
  }

  return clonedRelations;
};

const swapRelationsConfiguration = (
  activeRelationId: number | string,
  swapRelationId: number | string,
) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const activeRelationSearchResult = cloneAndFindRelationById(
      relations,
      activeRelationId,
    );
    const swapRelationSearchResult = cloneAndFindRelationById(relations, swapRelationId);

    if (!activeRelationSearchResult || !swapRelationSearchResult) return relations;

    const [, activeRelation] = activeRelationSearchResult;
    const [, swapRelation] = swapRelationSearchResult;

    return relations.map(relation => {
      switch (relation.id) {
        case activeRelation.id:
          return {
            ...relation,
            customer: swapRelation.customer,
            primaryContact: swapRelation.primaryContact,
            additionalContacts: swapRelation.additionalContacts,
          };
        case swapRelation.id:
          return {
            ...relation,
            customer: activeRelation.customer,
            primaryContact: activeRelation.primaryContact,
            additionalContacts: activeRelation.additionalContacts,
          };

        default:
          return relation;
      }
    });
  };
};

const copyRelationData = (
  activeRelationId: number | string,
  targetRelationId: number | string,
) => {
  return (relations: ConfigurationRelation[]): ConfigurationRelation[] => {
    const activeRelationSearchResult = cloneAndFindRelationById(
      relations,
      activeRelationId,
    );
    const targetRelationIdx = relations.findIndex(
      relation => relation.id === targetRelationId,
    );

    if (!activeRelationSearchResult || !~targetRelationIdx) return relations;

    const [clonedRelations, activeRelation] = activeRelationSearchResult;

    clonedRelations[targetRelationIdx].customer = activeRelation.customer;
    clonedRelations[targetRelationIdx].additionalContacts =
      activeRelation.additionalContacts;

    return clonedRelations;
  };
};

/**
 * Moved it into sync actions, because async `setState` caused errors.
 */
export const relationActions = {
  setRelationCustomer,
  setRelationPrimaryContact,
  setRelationAdditionalContacts,
  addRelationAdditionalContact,
  removeRelationAdditionalContact,
  restoreRelationsByConfigurationDetails,
  setupRelationsFromGlobalFeatures,
  unsetRelations,
  swapRelationsConfiguration,
  copyRelationData,
};
