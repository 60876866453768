import styled from 'styled-components';
import ImageCard from './shared/ImageCard';

export const ScHeaderImage = styled(ImageCard)`
  img {
    width: 100%;
  }
`;

export const ScBodyWrapper = styled.div`
  background: #ffffff99;
  padding: 1.5em;
`;

export const ScSection = styled.div`
  margin-bottom: 1em;
`;

export const ScSectionTitle = styled.div`
  color: #090909;
  font-family: 'VolvoNovum';
  font-size: 1.25em;
  font-style: normal;
  font-weight: 500;
  line-height: 136%;
  letter-spacing: -0.3px;
  margin-bottom: 1em;
`;

export const ScSectionSubtitle = styled.div`
  color: #464646;
  font-family: 'VolvoNovum';
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
`;

export const ScExclusiveOptionsLink = styled.a`
  color: #1b365d;
  font-family: 'VolvoNovum';
  font-size: 1.125em;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

export const ScContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
`;

export const ScOptionCard = styled.div`
  position: relative;
  background: white;
  padding: 0.625em;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.07);

  &.blured {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const ScImageWrapper = styled.div`
  width: 55%;
  border-radius: 3px;
  align-self: center;
`;

export const ScCardTextGroup = styled.div`
  flex: 1;
  margin-left: 1em;
  align-self: center;
`;

export const ScHeading = styled.div`
  color: #464646;
  text-overflow: ellipsis;
  font-family: 'VolvoNovum';
  font-size: 1.125em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em;
`;

export const ScTextFlow = styled.div`
  color: rgba(62, 50, 50, 0.75);
  text-overflow: ellipsis;
  font-family: 'VolvoNovum';
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.25px;
`;
