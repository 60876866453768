import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { CustomerFields } from 'types/vendor';
import { EMAIL_ALLOWED_VALUE } from 'common/constants';

export function getCustomerFormSchema(
  t: TFunction,
  flags: {
    isCustomerTypeEnabled: boolean;
    isCommercialTypeEnabled: boolean;
    isCommercialTypeRequired: boolean;
    isCustomerAddressFieldRequired: boolean;
    isCustomerPhoneFieldRequired: boolean;
    isCustomerPhone2FieldRequired: boolean;
    isCustomerMobilePhoneFieldRequired: boolean;
    isCustomerMobilePhone2FieldRequired: boolean;
  },
) {
  return Yup.object().shape(
    {
      ...(flags.isCustomerTypeEnabled
        ? { [CustomerFields.type]: Yup.string().required(t('FORM__REQUIRED')) }
        : {}),
      ...(flags.isCommercialTypeEnabled
        ? {
            [CustomerFields.commercialType]: flags.isCommercialTypeRequired
              ? Yup.string().required(t('FORM__REQUIRED'))
              : Yup.string(),
          }
        : {}),
      [CustomerFields.name]: Yup.string().required(t('FORM__REQUIRED')),
      [CustomerFields.name2]: Yup.string(),
      [CustomerFields.address]: flags.isCustomerAddressFieldRequired
        ? Yup.string().required(t('FORM__REQUIRED'))
        : Yup.string(),
      [CustomerFields.zip]: Yup.string().required(t('FORM__REQUIRED')),
      [CustomerFields.city]: Yup.string().required(t('FORM__REQUIRED')),
      [CustomerFields.mobilePhone]: flags.isCustomerMobilePhoneFieldRequired
        ? Yup.string().required(t('FORM__REQUIRED'))
        : Yup.string(),
      [CustomerFields.mobilePhone2]: flags.isCustomerMobilePhone2FieldRequired
        ? Yup.string().required(t('FORM__REQUIRED'))
        : Yup.string(),
      [CustomerFields.phone]: flags.isCustomerPhoneFieldRequired
        ? Yup.string().required(t('FORM__REQUIRED'))
        : Yup.string(),
      [CustomerFields.phone2]: flags.isCustomerPhone2FieldRequired
        ? Yup.string().required(t('FORM__REQUIRED'))
        : Yup.string(),
      [CustomerFields.country]: Yup.string().required(t('FORM__REQUIRED')),
      [CustomerFields.lang]: Yup.string(),
      [CustomerFields.email]: Yup.string()
        .required(t('FORM__REQUIRED'))
        .test('email', t('INVALID_EMAIL'), async (value: string) => {
          return (
            value?.toUpperCase() === EMAIL_ALLOWED_VALUE ||
            Yup.string().email().isValid(value)
          );
        }),
      [CustomerFields.email2]: Yup.string().test(
        'email2',
        t('INVALID_EMAIL'),
        async (value: string) => {
          return (
            value?.toUpperCase() === EMAIL_ALLOWED_VALUE ||
            Yup.string().email().isValid(value)
          );
        },
      ),
    },
    [[CustomerFields.mobilePhone, CustomerFields.phone]],
  );
}
