import React from 'react';
import { ScDatePicker } from './ConstructedYearInputRange.styles';
import { StockMachineFields } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { InputRangeGroup } from 'components/form/InputRangeGroup';
import FormField from 'components/form/formik/FormField';

type Props = {
  allowClear?: boolean;
  label: string;
};

const ConstructedYearInputRange: React.FC<Props> = ({ label, allowClear = false }) => {
  const { t } = useTranslation();

  return (
    <InputRangeGroup
      label={label}
      componentFrom={
        <FormField
          component={ScDatePicker}
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_CONSTRUCTED_FROM_YEAR')}
          name={StockMachineFields.constructedFromYear}
          allowClear={allowClear}
          picker="year"
          format={'YYYY'}
        />
      }
      componentTo={
        <FormField
          component={ScDatePicker}
          placeholder={t('STOCK_MACHINE_FIELD_PLACEHOLDER_CONSTRUCTED_TO_YEAR')}
          name={StockMachineFields.constructedToYear}
          allowClear={allowClear}
          picker="year"
          format={'YYYY'}
        />
      }
    />
  );
};

export default ConstructedYearInputRange;
