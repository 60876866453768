import {
  Model_CommonCategory,
  Model_CommonCategoryFields as CategoryFields,
  Model_CommonItem,
  Model_CommonItemFields as ItemFields,
} from '@hypercharge/xdms-client/lib/types';

export const getCategoryItems = (
  category: Model_CommonCategory,
  itemsList: Model_CommonItem[],
): Model_CommonItem[] => {
  return itemsList.filter(item => {
    return (
      item[ItemFields.categoryCode] === category[CategoryFields.lvl1] &&
      item[ItemFields.categoryCode2] === category[CategoryFields.lvl2]
    );
  });
};
