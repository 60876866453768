import styled from 'styled-components';
import { HostSettingsExternal } from 'common/hostsSettings';
import { NavLink } from 'react-router-dom';

export const ScAuthLayout = styled.div<{
  $background: HostSettingsExternal['background'];
}>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  ${({ $background }) => $background}
`;

export const ScFormWrap = styled.div`
  margin: 2em 0;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #d9e3ed;
  position: relative;
  padding: 1.5em 1.25em;
`;

export const ScTitle = styled.div`
  font-weight: 600;
  font-size: 1.25em;
  margin: 0 0 1em;
  text-align: center;
`;

export const ScHeaderWrap = styled.div`
  margin-bottom: 2.375em;
`;

export const ScLogoLink = styled(NavLink)`
  text-align: center;
  display: block;
  max-width: 100%;
  height: 6.875em;
  margin-bottom: 3.125em;
`;

export const ScImg = styled.img`
  height: 100%;
`;
