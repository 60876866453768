import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  ScCheckIcon,
  ScCloseIcon,
  ScListingItem,
  ScListingItemIconWrapper,
  ScListingItemTextWrapper,
  ScTotalAction,
  ScTotalActionsWrapper,
  ScTotalPrevStepButton,
  ScTotalPublishButton,
  ScTotalSkipButton,
} from './Buttons.styles';
import { DocumentTemplateDropdown } from 'components/documentTemplate/DocumentTemplateDropdown';
import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { DocumentTemplate } from 'types/vendor';
import NavButtonsContainer from 'components/button/NavButtonsContainer/NavButtonsContainer';
import { PrintTemplateListItemFields } from '@hypercharge/xdms-client/lib/types';
import { PRIMARY_DYNAMIC_FIELD } from 'common/constants';

interface Props {
  t: TFunction;
  handlePrevStep(): void;
  handleNextStep(): void;
  pdfTemplates: DocumentTemplate[];
  publishConfiguration(template?: DocumentTemplate): Promise<void>;
  printDocument(template?: DocumentTemplate): Promise<void>;
  isConfigurationComplete: boolean;
  modelPublishAllowed: boolean;
  requiredItems: [string, boolean][];
  isAllowTotalDocumentPrintFeatureEnabled: boolean;
}

const Buttons: FC<Props> = ({
  t,
  handlePrevStep,
  handleNextStep,
  pdfTemplates,
  publishConfiguration,
  printDocument,
  isConfigurationComplete,
  modelPublishAllowed,
  requiredItems,
  isAllowTotalDocumentPrintFeatureEnabled,
}) => {
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);

  const primaryPdfTemplates = useMemo(() => {
    return pdfTemplates.filter(
      pdfTemplate =>
        pdfTemplate[PrintTemplateListItemFields.dynamicField] === PRIMARY_DYNAMIC_FIELD,
    );
  }, [pdfTemplates]);

  const isRequiredItemMissing = requiredItems.some(([, isConfigured]) => !isConfigured);

  const tooltipTitle = useMemo(() => {
    if (isConfigurationComplete) return t('HEADER_PUBLISHED_CONFIGURATION_LOCK');

    return isRequiredItemMissing ? (
      <ul>
        {requiredItems.map(([displayName, isConditionMet]) => (
          <ScListingItem key={displayName}>
            <ScListingItemIconWrapper>
              {isConditionMet ? <ScCheckIcon /> : <ScCloseIcon />}
            </ScListingItemIconWrapper>
            <ScListingItemTextWrapper>{displayName}</ScListingItemTextWrapper>
          </ScListingItem>
        ))}
      </ul>
    ) : (
      ''
    );
  }, [isConfigurationComplete, isRequiredItemMissing, requiredItems, t]);

  const handlePrintDraft = useCallback(
    async (template: DocumentTemplate) => {
      setIsLoadingDraft(true);
      await printDocument(template);
      setIsLoadingDraft(false);
    },
    [printDocument],
  );

  const handlePublish = useCallback(
    async (template: DocumentTemplate) => {
      setIsLoadingPublish(true);
      await publishConfiguration(template);
      setIsLoadingPublish(false);
    },
    [publishConfiguration],
  );

  return (
    <ScTotalActionsWrapper>
      <NavButtonsContainer>
        <ScTotalAction>
          <ScTotalPrevStepButton
            fullwidth
            variant="prev"
            data-testid="total-page-prev-step-btn"
            onClick={handlePrevStep}
          >
            {t('PREV_STEP')}
          </ScTotalPrevStepButton>
        </ScTotalAction>
        {isAllowTotalDocumentPrintFeatureEnabled && (
          <ScTotalAction>
            <DocumentTemplateDropdown
              templates={primaryPdfTemplates}
              onAction={handlePrintDraft}
              stubTitle={t('TOTAL_NOT_FOUND_TEMPLATES')}
              placement="topCenter"
              trigger={['click']}
              disabled={isConfigurationComplete || !modelPublishAllowed || isLoadingDraft}
              confirmPlacement="right"
              confirmOffsetX={17}
            >
              <Tooltip placement="top" title={tooltipTitle}>
                {/* need wrapper div otherwise tooltip does not interact with disabled button */}
                <div>
                  <ScTotalPublishButton
                    fullwidth
                    variant="highlighted"
                    disabled={
                      isConfigurationComplete || isRequiredItemMissing || isLoadingDraft
                    }
                    loading={isLoadingDraft}
                  >
                    {t('TOTAL_DOCUMENT_PRINT')}
                  </ScTotalPublishButton>
                </div>
              </Tooltip>
            </DocumentTemplateDropdown>
          </ScTotalAction>
        )}
        <ScTotalAction>
          <DocumentTemplateDropdown
            templates={primaryPdfTemplates}
            onAction={handlePublish}
            stubTitle={t('TOTAL_NOT_FOUND_TEMPLATES')}
            placement="topCenter"
            trigger={['click']}
            disabled={isConfigurationComplete || !modelPublishAllowed}
            withConfirm={true}
            confirmPlacement="right"
            confirmOffsetX={17}
          >
            <Tooltip placement="top" title={tooltipTitle}>
              {/* need wrapper div otherwise tooltip does not interact with disabled button */}
              <div>
                <ScTotalPublishButton
                  fullwidth
                  variant="highlighted"
                  data-testid="total-page-publish-quote-btn"
                  disabled={
                    isConfigurationComplete || isRequiredItemMissing || isLoadingPublish
                  }
                  loading={isLoadingPublish}
                >
                  {t('TOTAL_PUBLISH_QUOTE')}
                </ScTotalPublishButton>
              </div>
            </Tooltip>
          </DocumentTemplateDropdown>
        </ScTotalAction>
        <ScTotalAction>
          <ScTotalSkipButton
            onClick={handleNextStep}
            fullwidth
            variant="next"
            data-testid="total-page-skip-btn"
          >
            {t('NEXT_STEP')}
          </ScTotalSkipButton>
        </ScTotalAction>
      </NavButtonsContainer>
    </ScTotalActionsWrapper>
  );
};

export default Buttons;
