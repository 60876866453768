import { Input } from 'components/form/Input';
import { get } from 'utils';
import React, { FC, useEffect, useRef } from 'react';
import { FilterDropdown, TableHeaderSearchContainer } from './TableHeaderSearchContainer';
import { ScInput } from './TableInputFilterByColumn.styles';
import { DataIndex } from './types';

interface Props extends FilterDropdown {
  dataIndex: DataIndex;
  handleSearch(selectedKeys, confirm, dataIndex: DataIndex): void;
  handleReset(dataIndex: DataIndex): void;
  inputProps?: React.ComponentProps<typeof Input>;
}

export const TableInputFilterByColumn: FC<Props> = props => {
  const {
    dataIndex,
    setSelectedKeys,
    selectedKeys,
    confirm,
    handleSearch,
    handleReset,
    inputProps,
    visible,
  } = props;

  const ref = useRef<any>(null);

  useEffect(() => {
    let timerId;
    if (ref && visible) {
      timerId = setTimeout(() => {
        if (ref && ref.current) ref.current.focus();
      }, 100);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [visible]);

  return (
    <TableHeaderSearchContainer
      dataIndex={dataIndex}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      handleSearch={handleSearch}
      handleReset={handleReset}
    >
      <ScInput
        autoFocus
        className="primary-border-color"
        data-testid="table-header-search-input"
        value={get(selectedKeys, 0) as string}
        onChange={e => {
          const value = get(e, 'target.value');
          setSelectedKeys(value ? [value] : []);
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        ref={ref}
        {...inputProps}
      />
    </TableHeaderSearchContainer>
  );
};
