import React, { FC, PropsWithChildren, Key } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ScHeaderSearchContainer,
  ScHeaderSearchContainerButton,
} from './TableHeaderSearchContainer.styles';
import { DataIndex } from 'components/table/types';

export interface FilterDropdown {
  setSelectedKeys(keys: Key[]): void;
  selectedKeys: Key[];
  confirm(params: { closeDropdown: boolean }): void;
  visible?: boolean;
  width?: string;
}

interface Props extends FilterDropdown {
  dataIndex: DataIndex;
  handleSearch(selectedKeys, confirm, dataIndex: DataIndex): void;
  handleReset(dataIndex: DataIndex): void;
  setSelectedFilters?(params: any): void;
  selectedFilters?: any;
}

export const TableHeaderSearchContainer: FC<PropsWithChildren<Props>> = props => {
  const { dataIndex, selectedKeys, confirm, handleSearch, handleReset, children, width } =
    props;
  const { t } = useTranslation();

  return (
    <ScHeaderSearchContainer $width={width}>
      {children}
      <Row align="middle" justify="space-between">
        <Col>
          <ScHeaderSearchContainerButton
            transparent
            onClick={() => {
              handleReset(dataIndex);
              confirm({ closeDropdown: true });
            }}
          >
            {t('RESET')}
          </ScHeaderSearchContainerButton>
        </Col>
        <Col>
          <ScHeaderSearchContainerButton
            transparent
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            {t('OK')}
          </ScHeaderSearchContainerButton>
        </Col>
      </Row>
    </ScHeaderSearchContainer>
  );
};
