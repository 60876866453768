import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, LinkOutlined } from '@ant-design/icons';
import { ScButtonWrapper } from './DocumentsList.styles';
import { Button } from 'components/button';

import DocumentFormCreate from '../Form/DocumentFormCreate';
import { DocumentRelatedEntity, DocumentRelatedEntityCode } from 'context/document/types';

interface Props {
  relatedEntity: DocumentRelatedEntity;
  relatedEntityCode: DocumentRelatedEntityCode;
  isAddLinkVisible?: boolean;
  isAddFileVisible?: boolean;
  disabled?: boolean;
  testidSuffix?: string;
  reload?(): void;
}

const DocumentListFooter: FC<Props> = ({
  relatedEntity,
  relatedEntityCode,
  isAddLinkVisible,
  isAddFileVisible,
  disabled,
  testidSuffix,
  reload,
}) => {
  const { t } = useTranslation();

  const [viewForm, setViewForm] = useState<'file' | 'link'>();

  return (
    <>
      <ScButtonWrapper>
        {isAddFileVisible && (
          <Button
            icon={<DownloadOutlined style={{ fontSize: '1em' }} />}
            variant="primary"
            disabled={disabled}
            onClick={() => {
              setViewForm(state => (state === 'file' ? undefined : 'file'));
            }}
            data-testid={`add-file-${testidSuffix ?? ''}`}
          >
            {t('TRADE_IN_ADD_FILE')}
          </Button>
        )}

        {isAddLinkVisible && (
          <Button
            icon={<LinkOutlined style={{ fontSize: '1em' }} />}
            variant="primary"
            disabled={disabled}
            onClick={() => {
              setViewForm(state => (state === 'link' ? undefined : 'link'));
            }}
            data-testid={`add-link-${testidSuffix ?? ''}`}
          >
            {t('TRADE_IN_ADD_LINK')}
          </Button>
        )}
      </ScButtonWrapper>

      {viewForm && (
        <DocumentFormCreate
          isFile={viewForm === 'file'}
          isLink={viewForm === 'link'}
          relatedEntity={relatedEntity}
          relatedEntityCode={relatedEntityCode}
          reload={() => {
            reload?.();
            setViewForm(undefined);
          }}
        />
      )}
    </>
  );
};

export default DocumentListFooter;
