import React, { FC, ChangeEvent, useCallback, InputHTMLAttributes } from 'react';
import { ScVendorMobileInput } from './MobileInput.styles';
import { Props as VendorProps } from 'react-phone-number-input';

export type { Country as MobileInputCountry } from 'react-phone-number-input';

type Props = Omit<VendorProps<InputHTMLAttributes<HTMLInputElement>>, 'onChange'> & {
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
};

export const MobileInput: FC<Props> = ({ defaultCountry, name, onChange, ...props }) => {
  const formatCountryCode = (code?: string) => {
    if (!code) return '';
    return code.toUpperCase();
  };

  const localOnChange = useCallback(
    (value: string) => {
      const event = {
        target: {
          name: name,
          value: value,
        },
      } as ChangeEvent<HTMLInputElement>;

      onChange?.(event);
    },
    [onChange, name],
  );

  return (
    <ScVendorMobileInput
      name={name}
      onChange={localOnChange}
      defaultCountry={formatCountryCode(defaultCountry)}
      {...props}
    />
  );
};
