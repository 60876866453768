import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { EMAIL_ALLOWED_VALUE } from 'common/constants';

import { ContactFields } from 'types/vendor';

export function getContactFormSchema(t: TFunction) {
  return Yup.object().shape(
    {
      [ContactFields.name]: Yup.string().required(t('FORM__REQUIRED')),
      [ContactFields.name2]: Yup.string(),
      [ContactFields.email]: Yup.string()
        .required(t('FORM__REQUIRED'))
        .test('email', t('INVALID_EMAIL'), async (value: string) => {
          return (
            value?.toUpperCase() === EMAIL_ALLOWED_VALUE ||
            Yup.string().email().isValid(value)
          );
        }),
      [ContactFields.lang]: Yup.string(),
      // work
      [ContactFields.mobilePhone]: Yup.string().required(
        t('FORM__PHONE_OR_MOBILE_REQUIRED'),
      ),
      [ContactFields.mobilePhone2]: Yup.string(),
      [ContactFields.phone]: Yup.string(),
      [ContactFields.phone2]: Yup.string(),
    },
    [[ContactFields.mobilePhone, ContactFields.phone]],
  );
}
