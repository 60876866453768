import { useState, useCallback } from 'react';

export const useModalState = <
  CallParams,
  RestParams,
  State extends { onClose: () => void },
>(
  restParams: RestParams,
  mappingFn?: (callParams: CallParams, restParams: RestParams) => State,
): [State | undefined, (callParams?: CallParams | boolean) => void] => {
  const [state, setState] = useState<State>();

  const handle = useCallback(
    callParams => {
      if (!callParams) {
        setState(undefined);
        return;
      }

      if (mappingFn) {
        const params = mappingFn(callParams, restParams);
        setState({
          ...params,
          onClose: () => {
            params.onClose?.();
            callParams.onClose?.();
            setState(undefined);
          },
        } as State);
        return;
      }

      setState({
        ...restParams,
        ...callParams,
        onClose: () => {
          callParams.onClose?.();
          setState(undefined);
        },
      } as State);
    },
    [mappingFn, restParams],
  );

  return [state, handle];
};
