import { Menu } from 'antd';
import styled from 'styled-components';

export const ScDocumentTemplateMenu = styled(Menu)<{ $width?: string | undefined }>`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  box-shadow: 0px 5px 68px rgba(0, 0, 0, 0.08);
  width: ${({ $width }) => $width ?? '13.125em'};
  max-height: 18em;
  overflow-y: auto;
`;

export const ScDocumentTemplateMenuItem = styled(Menu.Item)`
  min-height: 3em;
  padding: 0;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.mainColor};
    border: 1px solid ${({ theme }) => theme.mainBorderColor};
    color: ${({ theme }) => theme.mainColor_contrast};
  }
  /* @todo: refactor; just to have link inside of menu so it wont change color in a weird way */
  a {
    display: block;
    width: 100%;
    color: inherit;
    transition: color 0s;
    &:hover {
      color: inherit;
    }
  }
`;

export const ScDocumentTemplateMenuItemButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5em 1.25em;
  word-break: break-all;
  white-space: pre-wrap;
`;
