import React, { FC, useCallback } from 'react';
import { FilterField } from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, CustomerItem } from 'types/vendor';
import { VerticalScrolling } from 'components/VerticalScrolling/VerticalScrolling';
import { useTranslation } from 'react-i18next';
import { useCustomer } from '../../context/customer/CustomerProvider';
import { CreateCustomerForm } from './CreateCustomerForm';
import { Button } from '../button/Button';
import { ScAddNewEntityFormContainer } from './CreateCustomerModal.styles';
import { Modal } from 'components/modal';

type CustomerAddNewModalProps = {
  onClose(): void;
  onSubmit(rec: CustomerItem): void;
  onWarningClick(name: CustomerFields, value: string): void;
};
export const CreateCustomerModal: FC<CustomerAddNewModalProps> = ({
  onClose,
  onSubmit,
  onWarningClick,
}) => {
  const { t } = useTranslation();
  const { loadCustomersList } = useCustomer();

  const handleSubmit = useCallback(
    (record?: CustomerItem | null) => {
      if (record) {
        onSubmit(record);
        onClose();
      }
    },
    [onClose, onSubmit],
  );

  const reload = useCallback(
    (filters: FilterField<CustomerFields>[]) => {
      return loadCustomersList(filters);
    },
    [loadCustomersList],
  );

  return (
    <VerticalScrolling
      refEl="create-customer-modal-ref"
      refWrapper="create-customer-modal-wrapper-ref"
    >
      <Modal
        visible
        onCancel={onClose}
        data-testid="end-user-add-new-customer-modal"
        className="create-customer-modal-ref"
        wrapClassName="create-customer-modal-wrapper-ref"
        variant="md"
      >
        <ScAddNewEntityFormContainer>
          <CreateCustomerForm
            onSubmit={handleSubmit}
            onWarningClick={onWarningClick}
            reloadCustomers={reload}
            t={t}
          >
            <Button
              onClick={onClose}
              className="fullwidth"
              data-testid="customer-page-new-endUser-tab-cancel-modal"
            >
              {t('CANCEL')}
            </Button>
          </CreateCustomerForm>
        </ScAddNewEntityFormContainer>
      </Modal>
    </VerticalScrolling>
  );
};
