import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentRelatedEntity, DocumentRelatedEntityCode } from 'context/document/types';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentFormValues } from './types';
import DocumentForm from './DocumentForm';
import { useFileUpload } from 'context/document/useFileUpload';
import { ScFormWrap } from './DocumentForm.styles';

interface Props {
  relatedEntity: DocumentRelatedEntity;
  relatedEntityCode: DocumentRelatedEntityCode;
  reload?(): void;
  isFile: boolean;
  isLink: boolean;
}

const DocumentFormCreate: FC<Props> = ({
  relatedEntity,
  relatedEntityCode,
  reload,
  isFile,
  isLink,
}) => {
  const { t } = useTranslation();

  const { uploadDocumentFile, uploadDocumentLink } = useDocumentsApi();
  const { progressGroup, setupFunctions } = useFileUpload();

  const onSubmit = useCallback(
    async (values: DocumentFormValues, options: { hasSeparateNameForFile: boolean }) => {
      if (isFile) {
        if (!values.files?.length) return;

        for (const [idx, file] of values.files.entries()) {
          const name = options.hasSeparateNameForFile
            ? values.names?.[idx]
            : values.names?.[0];

          const { messageHandled, status } = await uploadDocumentFile(
            {
              file: file,
              status: values.status as any,
              type: values.type,
              typeCategory: values.typeCategory,
              name: name,
            },
            relatedEntityCode,
            relatedEntity,
            setupFunctions,
          );

          if (!messageHandled) {
            notification.openByStatus(status, {
              [Status.Success]: t('DOCUMENT_UPLOAD_FILE_SUCCESS'),
              [Status.Error]: t('DOCUMENT_UPLOAD_FILE_ERROR'),
            });
          }
        }
      }

      if (isLink) {
        const { messageHandled, status } = await uploadDocumentLink(
          {
            url: values.url,
            name: values.names?.[0],
            status: values.status as any,
            type: values.type,
            typeCategory: values.typeCategory,
          },
          relatedEntityCode,
          relatedEntity,
        );

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('DOCUMENT_UPLOAD_LINK_SUCCESS'),
            [Status.Error]: t('DOCUMENT_UPLOAD_LINK_ERROR'),
          });
        }
      }

      reload?.();
    },
    [
      isFile,
      isLink,
      t,
      reload,
      uploadDocumentFile,
      uploadDocumentLink,
      relatedEntity,
      relatedEntityCode,
      setupFunctions,
    ],
  );

  return (
    <ScFormWrap>
      <DocumentForm
        relatedEntityCode={relatedEntityCode}
        onSubmit={onSubmit}
        isCreate={true}
        isFile={isFile}
        isLink={isLink}
        documentUploadFileProgress={progressGroup}
      />
    </ScFormWrap>
  );
};

export default DocumentFormCreate;
