import styled from 'styled-components';
import { AutoComplete } from 'antd';
import { basicInputStyle, vendorSelectOptionStyle } from './styles';

const { Option } = AutoComplete;

export const ScSelect = styled(AutoComplete)`
  &.ant-select {
    border-radius: 0;
    font-size: 0.75em;
    color: ${props => props.theme.textColor};
    transition: all 700ms;
    border: none;

    > .ant-select-selector {
      ${basicInputStyle};
      /** Theres 'bordered' prop for this */
      border: unset;
      .ant-select-selection-placeholder {
        line-height: 20px;
      }

      .ant-select-selection-search {
        left: 0;
        /** These styles here only to sync 'padding's. Styles below just override rest. */
        ${basicInputStyle}
        border-radius: unset;
        border: unset;
        box-shadow: unset;

        .ant-select-selection-search-input {
          font-weight: 600;
          height: 1.875em;
        }
      }

      .ant-select-selection-item {
        line-height: 1.875em;
        pointer-events: none;
      }
    }

    &.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
      border-color: ${({ theme }) => theme.mainColor};
      box-shadow: unset;
    }

    &.size_small {
      > .ant-select-selector {
        height: unset;
        padding: 0.5em 1em;
        &::after {
          line-height: unset;
        }
        .ant-select-selection-search {
          height: unset;
          padding: 0.5em 1em;
          .ant-select-selection-search-input {
            height: unset;
          }
        }
      }
    }

    &.fullwidth {
      width: 100%;
    }

    &.bordered > .ant-select-selector {
      border: 1px solid ${props => props.theme.colors.softPeach};
    }

    > .ant-select-arrow {
      display: flex;
      align-items: center;
      width: 0.375em;
      margin-right: 0.5em;

      > span {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.375em 0.281em 0 0.281em;
        border-color: ${({ theme }) => theme.colors.black} transparent;
        > * {
          display: none;
        }
      }
    }

    &.ant-select-disabled > .ant-select-arrow > span {
      border-color: rgba(0, 0, 0, 0.25) transparent;
    }

    &.discount {
      border: 1px solid ${({ theme }) => theme.mainBorderColor};

      > .ant-select-selector {
        padding: 0 0.75em;
      }
    }

    &.modal-select {
      padding: 0 1em;

      &.ant-select-disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: ${({ theme }) => theme.modalDisabledColor};
        cursor: not-allowed;
        opacity: 1;
      }
    }
  }

  ${vendorSelectOptionStyle}
`;

export const ScOption = styled(Option)``;
