import { DocumentRelatedEntitySettingsSelectors } from 'context/document/settings';
import { DocumentSource } from 'context/document/types';
import { cloneDeep } from 'utils';
import {
  DocumentsTreeData,
  DocumentTreeDataAction,
  DocumentTreeDataActionType,
} from './types';

/** Now unifies all needed use cases
 * May be splitted, but not needed to
 */
export const reducer = (
  state: DocumentsTreeData,
  action: DocumentTreeDataAction,
): DocumentsTreeData => {
  switch (action.type) {
    case DocumentTreeDataActionType.initFromTree: {
      const { tree } = action;

      return tree;
    }
    /** todo: maybe simplify if possible */
    case DocumentTreeDataActionType.initFromSwarm: {
      const { documentsSwarm, model } = action;

      return Object.entries(documentsSwarm).map(([source, entitiesList]) => {
        const settings = DocumentRelatedEntitySettingsSelectors.getBySource(
          source as DocumentSource,
        );

        const previousEntity = state.find(
          ({ relatedEntityCode }) => relatedEntityCode === settings.entityCode,
        );

        return {
          tKey: settings.documentsPageTKey,
          nameField: settings.nameField,
          idField: settings.idField,
          relatedEntityCode: settings.entityCode,
          relatedEntities: entitiesList.map(({ code, documents }) => {
            const relatedEntityItem =
              code && settings.findModelItem && model
                ? settings.findModelItem(model, code)
                : undefined;

            const previousRelatedEntityItem = previousEntity?.relatedEntities.find(
              ({ relatedEntity }) =>
                relatedEntity?.[settings.idField] ===
                relatedEntityItem?.[settings.idField],
            );

            if (previousRelatedEntityItem) {
              const prevIds = previousRelatedEntityItem.documents.map(({ id }) => id);
              const prevSelectedIds = previousRelatedEntityItem.selectedIds;
              const selectedIds = documents
                .filter(document => {
                  return (
                    prevSelectedIds.includes(document.id) ||
                    (!prevIds.includes(document.id) && document.computed.isSelectable)
                  );
                })
                .map(({ id }) => id);

              return {
                ...previousRelatedEntityItem,
                selectedIds: selectedIds,
                documents: documents,
              };
            }

            return {
              isOpen: false,
              relatedEntity: relatedEntityItem,
              selectedIds: documents
                .filter(document => document.computed.isSelectable)
                .map(({ id }) => id),
              documents: documents,
            };
          }),
        };
      });
    }
    case DocumentTreeDataActionType.toggleOpenGroup: {
      const { relatedEntityCode, relatedEntity } = action;

      const newState = cloneDeep(state);

      const group = newState.find(group => group.relatedEntityCode === relatedEntityCode);
      if (!group) return newState;

      const settings =
        DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

      const entity = group.relatedEntities.find(entity => {
        return (
          entity.relatedEntity?.[settings.idField] === relatedEntity?.[settings.idField]
        );
      });
      if (!entity) return newState;

      entity.isOpen = !entity.isOpen;

      return newState;
    }

    case DocumentTreeDataActionType.selectGroup: {
      const { relatedEntityCode, relatedEntity } = action;

      const newState = cloneDeep(state);

      const group = newState.find(group => group.relatedEntityCode === relatedEntityCode);
      if (!group) return newState;

      const settings =
        DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

      const entity = group.relatedEntities.find(entity => {
        return (
          entity.relatedEntity?.[settings.idField] === relatedEntity?.[settings.idField]
        );
      });
      if (!entity) return newState;

      if (entity.selectedIds.length === 0) {
        entity.selectedIds = entity.documents
          .filter(document => document.computed.isSelectable)
          .map(({ id }) => id);
      } else {
        entity.selectedIds = [];
      }

      return newState;
    }

    case DocumentTreeDataActionType.selectDocument: {
      const { relatedEntityCode, relatedEntity, document } = action;

      const newState = cloneDeep(state);

      const group = newState.find(group => group.relatedEntityCode === relatedEntityCode);
      if (!group) return newState;

      const settings =
        DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

      const entity = group.relatedEntities.find(entity => {
        return (
          entity.relatedEntity?.[settings.idField] === relatedEntity?.[settings.idField]
        );
      });
      if (!entity) return newState;

      const idx = entity.selectedIds.findIndex(el => el === document.id);

      if (~idx) {
        entity.selectedIds.splice(idx, 1);
      } else {
        entity.selectedIds.push(document.id);
      }

      return newState;
    }

    default:
      return state;
  }
};
