import * as Sentry from '@sentry/react';
import {
  ModelFields,
  ModelItemFields,
  ModelTax,
  ModelTaxFields,
  ModelUpdateAttributesObjectFields,
} from '@hypercharge/xdms-client/lib/types';
import { Tooltip } from 'antd';
import { RowSelectionType, TableRowSelection } from 'antd/lib/table/interface';
import { MODELS_URL } from 'common/constants';
import Container from 'components/container/Container';
import { useModelApi } from 'context/model/useModelApi';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import useCarInfo from 'hooks/useCarInfo';
import Layout from 'layout/Default/Layout';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { get, orderBy } from 'utils';
import { sortTableDataBySearchValue } from 'utils/format';
import { getSelectedTableItems } from 'utils/get-selected-table-items';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { ScBadgeContainer, ScTable } from './index.styles';
import { useColumns } from './use-columns';
import TableFilters from 'components/table/filters';
import { DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { StreamingEventType } from 'context/streaming/types';

export const TaxesPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getModel, updateModel } = useModelApi();
  const { handlePrevStep, handleNextStep } = useStep();
  const { pathname } = useLocation();
  const tableColumns = useColumns();
  const { sendMessage } = useStreaming();
  const { variables: carInfoVariables } = useCarInfo({
    skip: true,
  });

  const {
    model,
    status: modelStatus,
    isConfigurationComplete,
  } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const [selectedTableItems, setSelectedTableItems] = useState<Record<string, string>>(
    {},
  );
  const [tableData, setTableData] = useState<ModelTax[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSelectOption = useCallback(
    async (record: ModelTax, selected: boolean) => {
      const selectedRecordId = get(record, ModelTaxFields.code);

      const selectedTableItems = getSelectedTableItems({
        model,
        tables: ModelFields.taxes,
        idFilter: ModelTaxFields.code,
      });

      if (selected) {
        setSelectedTableItems(() => ({
          ...selectedTableItems,
          [selectedRecordId]: selectedRecordId,
        }));
      } else {
        setSelectedTableItems(prevState => {
          const { [selectedRecordId]: _srk, ...rest } = prevState;
          const newState = Object.keys(rest);
          return newState.reduce<Record<string, string>>((res, recordId) => {
            res[recordId] = recordId;
            return res;
          }, selectedTableItems);
        });
      }

      if (!model) return;

      const { status, messageHandled } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.tax]: {
          ...record,
          [ModelTaxFields.isSelected]: selected,
        },
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: { token: modelToken },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_UPDATE_CONFIGURATION_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }
    },
    [model, updateModel, sendMessage, modelToken, t],
  );

  // these keys used in search to filter by
  const filteringKeys = useMemo(() => {
    const { info, amount } = ModelTaxFields;
    return [info, amount];
  }, []);

  const onSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>): void => {
      setSearchValue(target.value);
      setTableData(
        sortTableDataBySearchValue<ModelTax>({
          data: get(model, ModelFields.taxes, []) as ModelTax[],
          searchValue: target.value,
          filteringKeys,
        }),
      );
    },
    [filteringKeys, model],
  );

  const rowSelection: TableRowSelection<ModelTax> = {
    onSelect: handleSelectOption,
    selectedRowKeys: Object.keys(selectedTableItems),
    type: 'checkbox' as RowSelectionType,
    columnWidth: '3em',
    renderCell: (_checked, record, _index, originNode) => (
      <ScBadgeContainer data-testid="taxes-step-table-item-rodio-select-btn">
        <Tooltip placement="bottomLeft" title={record[ModelTaxFields.code]}>
          {originNode}
        </Tooltip>
      </ScBadgeContainer>
    ),
    getCheckboxProps: () => ({
      disabled: isConfigurationComplete,
    }),
  };

  useEffect(() => {
    getModel({ shouldRecalculateCo2Emission: true });
  }, [getModel]);

  /** @todo: move it into some step controlling stuff
   * to StepProvider after refactor merged
   */
  useEffect(() => {
    (async () => {
      if (!model || modelStatus === Status.Loading) {
        return;
      }

      try {
        const ttModel = get(model, ModelFields.model);
        const modelNumber = get(ttModel, `0.${ModelItemFields.modelNumber}`);

        if (!modelNumber) {
          history.push({
            pathname: MODELS_URL,
            search: history.location.search,
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    })();
  }, [getModel, history, model, modelStatus]);

  useEffect(() => {
    const preSelectedRows = getSelectedTableItems({
      model,
      tables: ModelFields.taxes,
      idFilter: ModelTaxFields.code,
    });
    setTableData(
      sortTableDataBySearchValue({
        data: get(model, ModelFields.taxes, []) as ModelTax[],
        searchValue,
      }),
    );
    setSelectedTableItems(() => ({
      ...preSelectedRows,
      ...getSelectedTableItems({
        model,
        tables: ModelFields.taxes,
        idFilter: ModelTaxFields.code,
      }),
    }));
  }, [model, pathname, searchValue]);

  useEffect(() => {
    const messages = get(model, ModelFields.message);

    if (messages) {
      notification.open({ message: messages });
    }
  }, [model]);

  useEffect(() => {
    if (!model) return;
    const hasTaxes = get(model, ModelFields.taxes, []).some(
      tax => !tax[ModelTaxFields.isDummy],
    );
    if (!hasTaxes) {
      handleNextStep();
    }
  }, [handleNextStep, model]);

  useEffect(() => {
    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            optionType: null,
            token: modelToken,
          },
        },
      });
    }
  }, [modelToken, carInfoVariables, sendMessage]);

  const data = orderBy(
    tableData,
    [ModelTaxFields.isSelected, ModelTaxFields.info],
    ['desc', 'asc'],
  );

  return (
    <Layout bg={false}>
      <Container>
        <TableFilters
          scrollMenuOptions={[]}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          onSelect={() => {}}
          prevStepDisabled={false}
          nextStepDisabled={false}
          onSearch={onSearch}
          searchValue={searchValue}
        />
        <ScTable
          rowKey={record => get(record, ModelTaxFields.code)}
          rowSelection={rowSelection}
          data={data}
          columns={tableColumns}
          scroll={{ x: 1200 }}
          pagination={data.length > DEFAULT_TABLE_PAGE_SIZE}
        />
      </Container>
    </Layout>
  );
};
