import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ContactItem, ContactFields, Filter, CustomerFields } from 'types/vendor';
import { checkChangeConfigData, customerContactFormatName } from 'utils/format';
import { Status } from 'utils/types';
import { ScTabContainerNested } from '../../RelationsList.styles';
import { ConfigurationRelation } from 'context/relations/types';
import { useTranslation } from 'react-i18next';
import { notification } from 'utils/notification';
import { useRelations } from 'context/relations/RelationsProvider';
import { useContact } from 'context/contact/ContactProvider';
import { Button } from 'components/button';
import { UserForm, UserType } from 'components/customerForms/UserForm';
import { useCountry } from 'context/country/CountryProvider';
import { useLanguage } from 'context/language/LanguageProvider';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { AdditionalContactsList } from './AdditionalContactsList';
import { getContactFormSchema } from 'components/contact/contact-form-schema';
import { SelectContactModal } from 'components/contact/SelectContactModal';
import { FILTER_SIGNS } from 'common/constants';
import { FormikHelpers } from 'formik';
import { checkFormValuesDiffersFromRecord } from 'utils/check-form-values-differs-from-record';
import { useFeature } from 'context/feature/FeatureProvider';
import { getGlobalFeatureFlag_RelationContactAddEnabled } from 'context/relations/utils';
import { useSelector } from 'react-redux';
import { configurationSelectors, modelSelectors } from 'store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

interface Props {
  relation: ConfigurationRelation;
  goForward(): void;
}
export const RelationItemContact: FC<Props> = props => {
  const { relation, goForward } = props;

  const { t } = useTranslation();
  const {
    setRelationPrimaryContact,
    setRelationAdditionalContacts,
    removeRelationAdditionalContact,
  } = useRelations();
  const { countries } = useCountry();
  const { languages } = useLanguage();
  const { loadContactsList, updateContact } = useContact();
  const { updateConfiguration, isNewConfiguration } = useConfiguration();
  const { isFeatureEnabled } = useFeature();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);
  const configurationDetails = useSelector(
    configurationSelectors.getConfigurationAllDetails,
  );

  const formSchema = useMemo(() => getContactFormSchema(t), [t]);

  const [isModalSearchPrimaryVisible, setIsModalSearchPrimaryVisible] =
    useState<boolean>(false);
  const [isModalSearchAdditionalVisible, setIsModalSearchAdditionalVisible] =
    useState<boolean>(false);

  const isAddNewItemFeatureEnabled = isFeatureEnabled({
    feature: getGlobalFeatureFlag_RelationContactAddEnabled(relation.idx + 1),
  });
  const isContactMandatoryEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowContactMandatory,
  });

  const defaultFilter = useMemo<Filter<ContactFields>>(() => {
    const customerId = relation.customer?.[CustomerFields.id];

    if (!customerId) return [];

    return [
      {
        name: ContactFields.relationId,
        value: customerId,
        sign: FILTER_SIGNS.EQUAL,
      },
    ];
  }, [relation]);

  const handleSelectPrimary = useCallback(
    async (records: ContactItem[]) => {
      const record = records[0];

      if (!record) return;

      setRelationPrimaryContact(relation.id, record);
    },
    [setRelationPrimaryContact, relation.id],
  );

  const handleSelectAdditional = useCallback(
    async (records: ContactItem[]) => {
      setRelationAdditionalContacts(relation.id, records);
    },
    [setRelationAdditionalContacts, relation.id],
  );

  const handleUpdateRecord = useCallback(
    async (
      values: ContactItem,
    ): Promise<{ status: Status; response: ContactItem | null }> => {
      const clientId: number | undefined = values[ContactFields.ID];
      if (!clientId) return { status: Status.Error, response: null };

      const { status, messageHandled, response } = await updateContact(clientId, values);

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('CLIENTS_CONTACT_UPDATED'),
          [Status.Error]: t('CLIENTS_CONTACT_UPDATE_ERROR'),
        });
      }

      return { status: status, response: response ?? null };
    },
    [updateContact, t],
  );

  const handleConfirm = useCallback(
    async (values: ContactItem, actions: FormikHelpers<ContactItem>) => {
      actions.setSubmitting(false); // to have loader on form to be hidden, as we have global loader

      if (checkFormValuesDiffersFromRecord(relation.primaryContact, values)) {
        const { status, response } = await handleUpdateRecord(values);

        if (status === Status.Success) {
          await handleSelectPrimary(response ? [response] : []);
        }
      }

      const isDataChanged = checkChangeConfigData(
        configurationDetails ?? null,
        relation.customer,
        relation.primaryContact,
        relation.additionalContacts,
        'client',
      );

      if (isDataChanged) {
        await updateConfiguration();
      }

      if (isNewConfiguration) goForward();
    },
    [
      configurationDetails,
      isNewConfiguration,
      goForward,
      handleSelectPrimary,
      handleUpdateRecord,
      relation,
      updateConfiguration,
    ],
  );

  const deselectAdditionalRecord = useCallback(
    (record: ContactItem) => {
      removeRelationAdditionalContact(relation.id, Number(record[ContactFields.ID]));
    },
    [relation.id, removeRelationAdditionalContact],
  );

  const deselectRecord = () => {
    setRelationPrimaryContact(relation.id, null);
  };

  const handleSelectionClick = useCallback(() => {
    setIsModalSearchPrimaryVisible(true);
  }, []);

  return (
    <>
      <SelectContactModal
        defaultFilter={defaultFilter}
        initialValues={relation.primaryContact ? [relation.primaryContact] : []}
        relatedCustomer={relation.customer}
        onSelect={handleSelectPrimary}
        isOpen={isModalSearchPrimaryVisible}
        setIsOpen={setIsModalSearchPrimaryVisible}
        isAddEnabled={isAddNewItemFeatureEnabled}
      />

      <SelectContactModal
        defaultFilter={defaultFilter}
        initialValues={relation.additionalContacts}
        relatedCustomer={relation.customer}
        onSelect={handleSelectAdditional}
        isOpen={isModalSearchAdditionalVisible}
        setIsOpen={setIsModalSearchAdditionalVisible}
        searchSelectMode={'multiple'}
        isAddEnabled={isAddNewItemFeatureEnabled}
      />

      <ScTabContainerNested data-testid="client-contact-wrapper">
        <UserForm
          formSchema={formSchema}
          readonly={false}
          languages={languages}
          countries={countries}
          initialFormValues={relation.primaryContact}
          userType={UserType.Contact}
          onSubmit={handleConfirm}
          onSelectionClick={handleSelectionClick}
          reloadEntities={loadContactsList as any}
          selectionSuffix={
            <Tooltip
              placement="top"
              title={t('CLIENT_DESELECT_ITEM')}
              data-testid="customer-page-client-block-search-tab-deselect-tooltip"
            >
              <CloseOutlined
                onClick={isConfigurationComplete ? undefined : deselectRecord}
                data-testid="customer-page-client-block-search-tab-deselect-icon-button"
              />
            </Tooltip>
          }
          selectionHeaderValue={relation.primaryContact ? t('CONTACT') : ''}
          selectionPlaceholder={t('SELECT_CONTACT')}
          selectionReadOnly={isConfigurationComplete}
          selectionValue={customerContactFormatName(
            relation.primaryContact?.[ContactFields.name],
            relation.primaryContact?.[ContactFields.name2],
          )}
          showCommercialCustomerType={false}
          footer={
            <>
              <AdditionalContactsList
                contacts={relation.additionalContacts}
                isConfigurationComplete={isConfigurationComplete}
                deleteOne={deselectAdditionalRecord}
                countries={countries}
                languages={languages}
              />

              <Row gutter={[20, 20]}>
                <Col lg={12} xl={12}>
                  <Button
                    onClick={() => setIsModalSearchAdditionalVisible(true)}
                    className="fullwidth"
                    disabled={isConfigurationComplete}
                    data-testid={`$-customer-page-select-additional-contacts-btn`}
                  >
                    {t('CLIENTS_ADDITIONAL_CONTACTS')}
                  </Button>
                </Col>
                <Col lg={12} xl={12}>
                  <Button
                    htmlType="submit"
                    fullwidth
                    variant="primary"
                    disabled={isConfigurationComplete}
                    data-testid={`relation_contactForm_confirmButton`}
                  >
                    {t('CONFIRM')}
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        {!isContactMandatoryEnabled && !relation.primaryContact && (
          <Button variant="primary" onClick={() => goForward()} className="fullwidth">
            {t('SKIP_AND_GO')}
          </Button>
        )}
      </ScTabContainerNested>
    </>
  );
};
