import styled from 'styled-components';
import { Row } from 'antd';

export const ScSubcategoryTable = styled.div`
  margin-bottom: 6em;

  &.with-pagination {
    margin-bottom: 0;
  }
`;

export const ScRow = styled(Row)`
  padding: 0 1em;

  > div {
    min-width: 20em;
  }
`;

export const ScPaginationWrapper = styled(Row)`
  width: 100%;
`;
