/**
 * Encodes option value properly
 * MUST be used on Select components alongside with {@link optionValueDecode}
 * Part of a difficult issue:
 * Yup transforms empty string value('') to undefined before passing to any test function
 * so replacing it with string containing string("''") here and do revert before saving
 * @param {string} value
 * @returns {string}
 */
export const optionValueEncode = (value: string): string => {
  return value === '' ? "''" : value;
};
