import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getExternalCredentials } from '@hypercharge/xdms-client/';
import * as AWS from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors, hostSettingsSelectors, authSelectors } from 'store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

interface UploadDocumentParams {
  id: string;
  file: File | Blob | undefined;
  key: string;
  contentType: string;
  onProgress?(id: string, progress: number): void;
  handleAbort?(id: string, abortCallback: () => void): void;
}

interface DeleteDocumentParams {
  key: string;
}

interface DeleteDocumentResult {
  isDeleted: boolean;
  error: string | null;
}

interface UploadFileResult {
  url: string;
}

interface GetOptimizedImageUrlParams {
  fileName: string | undefined;
  // add missing options if necessary
  // Reference: https://sharp.pixelplumbing.com/
  options: {
    resize?: {
      width?: number;
      height?: number;
    };
  };
}

export type StorageCredentials = {
  accessKey: string;
  secretKey: string;
  bucket: string;
};

type ContextValue = {
  status: Status;
  isLoading: boolean;
  storageCredentials: StorageCredentials | null;
  isExternalStorage(fileUrl: string): boolean;
  generateFileNameKey(id: string, fileName: string): string;
  uploadDocument(params: UploadDocumentParams): Promise<UploadFileResult>;
  deleteDocument(params: DeleteDocumentParams): Promise<DeleteDocumentResult>;
  getOptimizedImageUrl(params: GetOptimizedImageUrlParams): string | undefined;
};

const ExternalStorageContext = React.createContext<ContextValue | undefined>(undefined);

const ExternalStorageProvider: FC<PropsWithChildren<{ value?: ContextValue }>> =
  props => {
    const [status, setStatus] = useState<ContextValue['status']>(Status.Idle);
    const [storageCredentials, setStorageCredentials] =
      useState<ContextValue['storageCredentials']>(null);

    const authData = useSelector(authSelectors.getAll);

    const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
    const customerId = useSelector(hostSettingsSelectors.getCustomerId);

    const imageOptimizedEndpoint = useMemo<string | undefined>(() => {
      const endpoint = globalFeatures?.[
        GlobalFeaturesFlagsFields.ImageOptimizerEndpoint
      ] as string | undefined;

      if (!endpoint) {
        console.warn(`Image handler endpoint does not exist`);
        return;
      }

      return endpoint;
    }, [globalFeatures]);

    const isExternalStorage = useCallback<ContextValue['isExternalStorage']>(
      (fileUrl): boolean => {
        if (!storageCredentials?.bucket) {
          throw new Error('Bucket name is missing');
        }
        if (!fileUrl) return false;
        return fileUrl.includes(`https://${storageCredentials.bucket}.s3`);
      },
      [storageCredentials],
    );

    const generateFileNameKey = useCallback<ContextValue['generateFileNameKey']>(
      (id: string, fileName: string): string => {
        const ext = fileName.split('.').pop();
        const keyWithoutExt = fileName.replace(`.${ext}`, '');
        // BE requires lower-cased value
        return `${keyWithoutExt}_${id}.${ext}`.toLocaleLowerCase();
      },
      [],
    );

    const uploadDocument = useCallback<ContextValue['uploadDocument']>(
      async (params: UploadDocumentParams): Promise<UploadFileResult> => {
        if (!storageCredentials?.bucket) {
          throw new Error('Bucket name is missing');
        }
        const { id, file, key, onProgress, handleAbort, contentType } = params;
        const upload = new AWS.S3.ManagedUpload({
          params: {
            Body: file,
            Key: key,
            Bucket: storageCredentials.bucket,
            ContentType: contentType,
          },
        }).on('httpUploadProgress', ({ loaded, total }: ManagedUpload.Progress) => {
          const progress = +((loaded / total) * 100).toFixed(2);
          onProgress?.(id, progress);
        });

        handleAbort?.(id, upload.abort.bind(upload));

        const response = await upload.promise();
        return { url: response.Location };
      },
      [storageCredentials],
    );

    const deleteDocument = useCallback<ContextValue['deleteDocument']>(
      async (params: DeleteDocumentParams): Promise<DeleteDocumentResult> => {
        if (!storageCredentials?.bucket) {
          throw new Error('Bucket name is missing');
        }
        try {
          const { bucket } = storageCredentials;
          const { key } = params;
          const s3 = new AWS.S3();
          const normalizedKey = decodeURI(key);
          await s3.deleteObject({ Bucket: bucket, Key: normalizedKey }).promise();
          await s3.headObject({ Bucket: bucket, Key: normalizedKey }).promise();
          return {
            isDeleted: false,
            error: 'Object still exists.',
          };
        } catch (e) {
          return {
            isDeleted: true,
            error: null,
          };
        }
      },
      [storageCredentials],
    );

    const getOptimizedFileNameKey = useCallback(
      (fileName: string): string => {
        if (storageCredentials?.bucket && isExternalStorage(fileName)) {
          return fileName.replace(
            `https://${storageCredentials.bucket}.s3.eu-west-1.amazonaws.com/`,
            '',
          );
        }

        return fileName;
      },
      [isExternalStorage, storageCredentials],
    );

    const getOptimizedImageUrl = useCallback<ContextValue['getOptimizedImageUrl']>(
      (params: GetOptimizedImageUrlParams): string | undefined => {
        if (!storageCredentials?.bucket) {
          throw new Error('Bucket name is missing');
        }

        const { fileName, options } = params;

        if (!fileName || !imageOptimizedEndpoint || customerId !== 'volvo')
          return fileName;

        // Reference: https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html
        const imageRequest = JSON.stringify({
          bucket: storageCredentials.bucket,
          key: getOptimizedFileNameKey(fileName),
          edits: options,
        });

        const imageUrl = `${imageOptimizedEndpoint}/${btoa(imageRequest)}`;

        return imageUrl;
      },
      [storageCredentials, imageOptimizedEndpoint, customerId, getOptimizedFileNameKey],
    );

    const fetchStorageCredentials = useCallback(async (): Promise<void> => {
      try {
        if (authData.username && authData.authToken && authData.baseUrl) {
          setStatus(Status.Loading);
          const externalCredentials = await getExternalCredentials({
            appName: 'CONFIGURATOR',
            tenantBaseUrl: authData.baseUrl,
            username: authData.username,
            authData: {
              token: authData.authToken,
            },
          });
          if (externalCredentials) {
            setStorageCredentials({
              accessKey: externalCredentials.amazonToken,
              secretKey: externalCredentials.amazonSecret,
              bucket: externalCredentials.amazonBucketName,
            });
            setStatus(Status.Success);
          }
        }
      } catch (e) {
        notification.requestError(e, setStatus);
      }
    }, [setStatus, setStorageCredentials, authData]);

    useEffect(() => {
      fetchStorageCredentials();
    }, [fetchStorageCredentials]);

    const configureExternalStorage = useCallback(
      (storageCredentials: StorageCredentials): void => {
        AWS.config.update({
          accessKeyId: storageCredentials.accessKey,
          secretAccessKey: storageCredentials.secretKey,
          // ToDo: take from external API
          region: 'eu-west-1',
        });
      },
      [],
    );

    useEffect(() => {
      if (storageCredentials) {
        configureExternalStorage(storageCredentials);
      }
    }, [configureExternalStorage, storageCredentials]);

    const value = useMemo(() => {
      return {
        status,
        isLoading: status === Status.Loading,
        storageCredentials,
        isExternalStorage,
        generateFileNameKey,
        uploadDocument,
        deleteDocument,
        getOptimizedImageUrl,
      };
    }, [
      status,
      storageCredentials,
      deleteDocument,
      generateFileNameKey,
      isExternalStorage,
      uploadDocument,
      getOptimizedImageUrl,
    ]);

    return <ExternalStorageContext.Provider value={value} {...props} />;
  };

const useExternalStorage = (): ContextValue => {
  const context = useContext(ExternalStorageContext);

  if (context === undefined) {
    throw new Error(
      `${useExternalStorage.name} must be used within an ${ExternalStorageProvider.name}`,
    );
  }

  return context;
};

export { ExternalStorageProvider, useExternalStorage };
