import {
  Model,
  ModelFields,
  ModelRule,
  ModelRuleValues,
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonItem_Type_Values,
} from '@hypercharge/xdms-client/lib/types';
import { TFunction } from 'i18next';
import { ModelRulesGrouped } from '../types';
import { checkRecordIncompatibility } from './check-record-incompatibility';
import { getPackageIncompatibleRecords } from './get-package-incompatible-records';
import { getRules } from './get-record-rules';
import { intersection } from 'utils';

interface GetRecordIncompatibilityMessageParams {
  record: Model_CommonItem | null;
  tableItems: Record<string, string>;
  model: Model | null;
  modelRules: ModelRule[];
  t: TFunction;
}

interface GetFlatRecordIncompatibilityMessageParams
  extends GetRecordIncompatibilityMessageParams {
  rules: ModelRulesGrouped;
  record: Model_CommonItem;
}

interface GetPackageRecordIncompatibilityMessageParams
  extends GetRecordIncompatibilityMessageParams {
  rules: ModelRulesGrouped;
  record: Model_CommonItem;
}

const getFlatRecordIncompatibilityMessage = (
  params: GetFlatRecordIncompatibilityMessageParams,
): string[] => {
  const { rules, record } = params;
  const recordKey = record[Model_CommonItemFields.ID];
  const incompatibleRecords = rules?.[ModelRuleValues.incompatible]?.[recordKey];
  if (!incompatibleRecords) return [];
  return Object.keys(incompatibleRecords);
};

const getPackageRecordIncompatibilityMessage = (
  params: GetPackageRecordIncompatibilityMessageParams,
): string[] => {
  const { rules, model, record: pack, tableItems, modelRules } = params;
  const recordKeys = getPackageIncompatibleRecords({
    model,
    modelRules,
    pack,
    tableItems,
  });
  const incompatibilityRules = rules?.[ModelRuleValues.incompatible];
  const recordKey = pack[Model_CommonItemFields.ID];
  const incompatibilityRecords = Object.keys(
    rules?.[ModelRuleValues.incompatible]?.[recordKey] ?? {},
  );
  const packageLines = model?.[ModelFields.packageLines]?.filter(
    pl => pl[Model_CommonItemFields.package] === pack[Model_CommonItemFields.ID],
  );
  if (!packageLines) return [];
  return Array.from(
    recordKeys.reduce<Set<string>>((res, recordKey) => {
      if (incompatibilityRules[recordKey]) {
        const keys = Object.keys(incompatibilityRules[recordKey]);
        res = new Set([...res, ...keys]);
      }
      return res;
    }, new Set(incompatibilityRecords)),
  );
};

type RecordTypeTHandlerMapType = {
  [key in Model_CommonItem_Type_Values]: (
    params:
      | GetFlatRecordIncompatibilityMessageParams
      | GetPackageRecordIncompatibilityMessageParams,
  ) => string[];
};

// termsAndCondition and option have same value, so it is not being added
const recordTypeToHandlerMap: RecordTypeTHandlerMapType = {
  [Model_CommonItem_Type_Values.package]: getPackageRecordIncompatibilityMessage,
  [Model_CommonItem_Type_Values.option]: getFlatRecordIncompatibilityMessage,
  [Model_CommonItem_Type_Values.accessory]: getFlatRecordIncompatibilityMessage,
  [Model_CommonItem_Type_Values.packageLine]: getFlatRecordIncompatibilityMessage,
};

export const getRecordIncompatibilityMessage = (
  params: GetRecordIncompatibilityMessageParams,
): string | null => {
  const { record, tableItems, model, t, modelRules } = params;
  if (!model || !record) return null;
  const isRecordCompatible = !checkRecordIncompatibility({
    record,
    modelRules,
    tableItems,
    model,
  });
  const recordType = record[Model_CommonItemFields.type];
  const handler = recordTypeToHandlerMap[recordType];
  if (isRecordCompatible || !handler) return null;
  const rules = getRules(modelRules) as ModelRulesGrouped;
  // take the intersection of all incompatible records
  // and all selected ones
  const incompatibilityRecordKeys = intersection(
    handler({ ...params, rules, record }),
    Object.keys(tableItems),
  );
  if (!incompatibilityRecordKeys.length) return null;
  return `${t('TABLE_INCOMPATIBLE')} ${incompatibilityRecordKeys.join(', ')}`;
};
