import styled from 'styled-components';
import SplitPane, { SplitPaneProps } from 'react-split-pane';
import { mediaMax } from 'common/theme';
import { FC, PropsWithChildren } from 'react';

// @todo: antd@5
export const ScSplitPane = styled(
  SplitPane as unknown as FC<PropsWithChildren<SplitPaneProps>>,
)<{ $headerHeight: number | undefined; $isFallbackTableImage: boolean }>`
  width: 100%;
  display: flex;

  &.active {
    .Pane {
      &.vertical {
        &.Pane1 {
          display: none;
        }
      }
    }
  }

  .Pane {
    max-width: calc(100% + 15px);

    &.bg-image {
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;

      .eye-icon {
        bottom: 2.5em;
        right: 0;
        position: absolute;
      }

      > img {
        object-fit: cover;
      }
    }

    &.vertical {
      &.Pane1 {
        margin-right: -1.563em;
        display: block;
        z-index: 2;

        ${mediaMax.md} {
          margin-right: 0;
          min-width: 100%;
        }

        .Pane {
          display: flex;
          justify-content: space-between;
          height: 100%;
          flex-direction: column;
        }
      }
      &.Pane2 {
        position: sticky !important;
        z-index: 2;
        top: ${({ $headerHeight, $isFallbackTableImage }) => {
          let offset = $headerHeight ?? 0;
          if (offset && $isFallbackTableImage) offset += 16;
          return offset + 'px';
        }};
        height: ${({ $headerHeight, $isFallbackTableImage }) => {
          let offset = $headerHeight ?? 0;
          if (offset && $isFallbackTableImage) offset += 16;
          return `calc(100vh - ${offset + 'px'})`;
        }};
        width: calc(100% + 15px);
        margin-left: -1.563em;
        display: flex;
        justify-content: center;

        ${mediaMax.md} {
          margin-left: 0;
        }
      }
    }
  }

  .Resizer {
    z-index: 10;
    box-sizing: border-box;
    background-clip: padding-box;
  }

  .Resizer:hover {
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 3.75em;
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.vertical {
    ${mediaMax.md} {
      display: none;
    }

    width: 3.125em;
    min-width: 3.125em;
    cursor: col-resize;
    top: 0;
    position: sticky;
    height: calc(100vh - 1.563em);
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
  }

  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;
