import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { StockMachineFields } from 'types/vendor';
import ConstructedYearInputRange from './ConstructedYearInputRange';
import KMInputRange from './KMInputRange';
import Selector from 'components/form/Selector';
import { Option } from 'types';
import BrandSelector from './selectors/BrandSelector';
import InteriorSelector from './selectors/InteriorSelector';
import FuelSelector from './selectors/FuelSelector';
import ColorSelector from './selectors/ColorSelector';
import LocationSelector from './selectors/LocationSelector';
import ModelNumberInput from './ModelNumberInput';
import { FILTER_SIGNS } from 'common/constants';
import CustomerSelector from './selectors/CustomerSelector';
// import ModelNameSelector from './selectors/ModelNameSelector';

enum FieldKey {
  MODEL_NUMBER = StockMachineFields.modelNumber,
  MODEL_NAME = StockMachineFields.modelName,
  MACHINE_NUMBER = StockMachineFields.machineNumber,
  STOCK_LOCATION = StockMachineFields.stockLocation,
  SHORT_NAME = StockMachineFields.shortName,
  IS_SOLD = StockMachineFields.isSold,
  BRAND_NAME = StockMachineFields.brandName,
  IS_RESERVED = StockMachineFields.isReserved,
  COLOR_CODE = StockMachineFields.colorCode,
  INTERIOR_CODE = StockMachineFields.interiorCode,
  FUEL_CODE = StockMachineFields.fuelCode,
  IS_IN_STOCK = StockMachineFields.isInStock,
  CUSTOMER = StockMachineFields.customerNumber,
  CONSTRUCTED_YEAR_RANGE = 'CONSTRUCTED_YEAR_RANGE',
  KM_RANGE = 'KM_RANGE',
}

type Result = (React.ComponentProps<typeof FormField> & {
  name: string;
  sign?: typeof FILTER_SIGNS;
  isCustom?: boolean;
  subFields?: string[];
})[];

export default function useStockMachineFields(): Result {
  const { t } = useTranslation();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const fieldsSettings = globalFeatures?.StockMachineFields;

  const booleanFields = useMemo<Option[]>(
    () => [
      { label: t('YES'), value: 'true' },
      { label: t('NO'), value: 'false' },
      { label: t('STOCK_OPTION_ANY'), value: '' },
    ],
    [t],
  );

  const keyToField = useMemo<Record<FieldKey, Result[number]>>(() => {
    return {
      [FieldKey.CUSTOMER]: {
        name: FieldKey.CUSTOMER,
        component: CustomerSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_CUSTOMER'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_CUSTOMER'),
        allowClear: false,
        sign: FILTER_SIGNS.EQUAL,
      },
      [FieldKey.STOCK_LOCATION]: {
        name: FieldKey.STOCK_LOCATION,
        component: LocationSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_LOCATION'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_LOCATION'),
        allowClear: true,
      },
      [FieldKey.SHORT_NAME]: {
        name: FieldKey.SHORT_NAME,
        component: Input,
        type: 'text',
        label: t('STOCK_MACHINE_FIELD_LABEL_SHORT_NAME'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_SHORT_NAME'),
        allowClear: true,
      },
      [FieldKey.BRAND_NAME]: {
        name: FieldKey.BRAND_NAME,
        component: BrandSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_BRAND_NAME'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_BRAND_NAME'),
        allowClear: true,
      },
      [FieldKey.IS_SOLD]: {
        name: FieldKey.IS_SOLD,
        component: Selector,
        options: booleanFields,
        label: t('STOCK_MACHINE_FIELD_LABEL_IS_SOLD'),
        defaultValue: '',
        allowClear: false,
        sign: FILTER_SIGNS.EQUAL,
      },
      [FieldKey.IS_RESERVED]: {
        name: FieldKey.IS_RESERVED,
        component: Selector,
        options: booleanFields,
        label: t('STOCK_MACHINE_FIELD_LABEL_IS_RESERVED'),
        defaultValue: '',
        allowClear: false,
        sign: FILTER_SIGNS.EQUAL,
      },
      [FieldKey.CONSTRUCTED_YEAR_RANGE]: {
        name: FieldKey.CONSTRUCTED_YEAR_RANGE,
        label: t('STOCK_MACHINE_FIELD_LABEL_CONSTRUCTED_YEAR_RANGE'),
        component: ConstructedYearInputRange,
        isCustom: true,
        allowClear: true,
        subFields: [
          StockMachineFields.constructedFromYear,
          StockMachineFields.constructedToYear,
        ],
        sign: FILTER_SIGNS.EQUAL,
      },
      [FieldKey.COLOR_CODE]: {
        name: FieldKey.COLOR_CODE,
        component: ColorSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_COLOR_CODE'),
        allowClear: true,
      },
      [FieldKey.INTERIOR_CODE]: {
        name: FieldKey.INTERIOR_CODE,
        component: InteriorSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_INTERIOR_CODE'),
        allowClear: true,
      },
      [FieldKey.FUEL_CODE]: {
        name: FieldKey.FUEL_CODE,
        component: FuelSelector,
        label: t('STOCK_MACHINE_FIELD_LABEL_FUEL_CODE'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_FUEL_CODE'),
        allowClear: true,
      },
      [FieldKey.KM_RANGE]: {
        name: FieldKey.KM_RANGE,
        label: t('STOCK_MACHINE_FIELD_LABEL_KM_RANGE'),
        component: KMInputRange,
        allowClear: true,
        subFields: [StockMachineFields.kilometrageFrom, StockMachineFields.kilometrageTo],
        sign: FILTER_SIGNS.EQUAL,
      },
      [FieldKey.MODEL_NAME]: {
        name: FieldKey.MODEL_NAME,
        // component: ModelNameSelector,
        component: Input,
        type: 'text',
        label: t('STOCK_MACHINE_FIELD_LABEL_MODEL_NAME'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_MODEL_NAME'),
        allowClear: true,
        withFormProps: true,
      },
      [FieldKey.MACHINE_NUMBER]: {
        name: FieldKey.MACHINE_NUMBER,
        component: Input,
        type: 'text',
        label: t('STOCK_MACHINE_FIELD_LABEL_MACHINE_NUMBER'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_MACHINE_NUMBER'),
        allowClear: true,
      },
      [FieldKey.MODEL_NUMBER]: {
        name: FieldKey.MODEL_NUMBER,
        component: ModelNumberInput,
        type: 'text',
        label: t('STOCK_MACHINE_FIELD_LABEL_MODEL_NUMBER'),
        placeholder: t('STOCK_MACHINE_FIELD_PLACEHOLDER_MODEL_NUMBER'),
        allowClear: true,
        withFormProps: true,
      },
      [FieldKey.IS_IN_STOCK]: {
        name: FieldKey.IS_IN_STOCK,
        component: Selector,
        options: booleanFields,
        defaultValue: '',
        allowClear: false,
        label: t('STOCK_MACHINE_FIELD_LABEL_IS_IN_STOCK'),
        sign: FILTER_SIGNS.EQUAL,
      },
    };
  }, [booleanFields, t]);

  const fields = useMemo<Result>(() => {
    if (!fieldsSettings?.national?.length) return [];
    return fieldsSettings.national.reduce<Result>((acc, setting) => {
      const field = keyToField[setting.name];
      if (field) acc.push(field);
      return acc;
    }, []);
  }, [fieldsSettings, keyToField]);

  return fields;
}
