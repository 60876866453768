import React from 'react';
import { useFormik } from 'formik';
import { Input } from 'components/form/Input';
import { StockMachineFields } from 'types/vendor';

const ModelNumberInput: React.FC<Props> = ({ formProps, ...props }) => {
  return (
    <Input
      {...props}
      onChange={event => {
        props.onChange?.(event);

        formProps.setFieldValue(StockMachineFields.modelName, undefined);
      }}
    />
  );
};

type Props = React.ComponentProps<typeof Input> & {
  formProps: ReturnType<typeof useFormik>;
};

export default ModelNumberInput;
