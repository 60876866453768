import styled from 'styled-components';
import { ContainerSize, CONTAINER_SIZES } from 'common/theme';

export const ScContainer = styled.div<{ $size: ContainerSize | undefined }>`
  padding: 0.5em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${({ $size }) => {
    if (!$size) return '';

    const width = CONTAINER_SIZES[$size];
    return `
          @media screen and (min-width: calc(${width}px + 60px)) {
            max-width: ${width}px;
          }
        `;
  }}

  @media screen and (min-width: ${CONTAINER_SIZES.sm}px) {
    padding: 0 1em;
  }
`;
