import { useLayoutEffect, useCallback, useState } from 'react';

/**
 * Determines window width at each moment when the resize of the window is done
 * @returns [number] - Actual width of the window
 */
export const useWindowSize = (): [number] => {
  const [width, setWidth] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return [width];
};
