import { AccessoryCategoryItemFields } from '@hypercharge/xdms-client/lib/types';
import { CategoryModelItem } from 'context/tableCategories/TableCategoriesProvider';
import { get } from 'utils';
import React, { FC } from 'react';
import { TFunction } from 'i18next';
import {
  ScFileImageOutlined,
  SubcategoryImageContainer,
  SubcategoryTitle,
  SubcategoryWrapper,
} from './item.styles';

interface Props {
  id: string;
  data: CategoryModelItem;
  categoryImage?: string;
  selectSubcategory(category: CategoryModelItem): void;
  t: TFunction;
}

const Subcategory: FC<Props> = ({ data, selectSubcategory, id, categoryImage, t }) => {
  return (
    <SubcategoryWrapper
      onClick={() => selectSubcategory(data)}
      data-testid={`subcategory-item-container${id}`}
    >
      <SubcategoryImageContainer data-testid="subcategory-item-image-wrapper">
        {categoryImage ? (
          <img
            src={categoryImage}
            alt={get(data, AccessoryCategoryItemFields.name, 'subcategory')}
            data-testid={`subcategory-item-image${id}`}
          />
        ) : (
          <ScFileImageOutlined data-testid={`subcategory-item-default-image${id}`} />
        )}
      </SubcategoryImageContainer>
      <SubcategoryTitle data-testid={`subcategory-item-title${id}`}>
        {data.tKey ? t(data.tKey) : get(data, AccessoryCategoryItemFields.name, '')}
      </SubcategoryTitle>
    </SubcategoryWrapper>
  );
};

export default Subcategory;
