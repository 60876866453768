import StepNumber from 'components/steps/StepNumber';
import { mediaMax } from 'common/theme';
import styled from 'styled-components';

export const ScSteps = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.alternativeColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.whisper};
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  position: relative;
  z-index: 19;

  ${mediaMax.lg} {
    overflow-x: auto;
    min-height: 3.75em;
  }

  ${mediaMax.md} {
    justify-content: start;
    padding-left: 1.75em;
    overflow-x: auto;
  }
`;

export const ScStepNumber = styled(StepNumber)`
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin: 0 0.375em;
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
`;
