import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { DocumentFormValues } from './types';

export const getSchema = (
  isLink: boolean,
  isFile: boolean,
  isCreate: boolean,
  t: TFunction,
): Yup.ObjectSchema<DocumentFormValues> => {
  const object: any = {
    names: Yup.array().of(
      Yup.string().required(t('FORM__REQUIRED')).min(1, t('FORM__REQUIRED')),
    ),
    type: Yup.string().required(t('FORM__REQUIRED')),
    typeCategory: Yup.string().required(t('FORM__REQUIRED')),
    status: Yup.string().required(t('FORM__REQUIRED')),
  };

  if (isLink) object.url = Yup.string().url(t('FORM__URL')).required(t('FORM__REQUIRED'));
  if (isFile && isCreate)
    object.files = Yup.array()
      .of(Yup.mixed())
      .test({
        message: t('FORM__REQUIRED') as string,
        test: arr => arr.length !== 0,
      });

  return Yup.object().shape(object);
};
