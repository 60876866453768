import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styled from 'styled-components';

export const CloseWrapper = styled.button`
  display: block;
  padding: 1.5em 0 0 1.25em;
  margin: 0 0 1.5em auto;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const Close = styled(CloseIcon)`
  stroke: ${props => props.theme.colors.black};
`;
