import { Button } from 'components/button';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import {
  ScAddCustomerContainer,
  ScCustomerIcon,
  ScAddCustomerIcon,
  ScAddCustomerTitle,
  ScAddCustomerTitleWrapper,
} from './Greeting.styles';

interface GreetingProps {
  onClick(): void;
  t: TFunction;
}
export const Greeting: FC<GreetingProps> = ({ onClick, t }) => {
  return (
    <ScAddCustomerContainer data-testid="clients-page-add-customer-container">
      <ScCustomerIcon data-testid="clients-page-add-customer-icon-container">
        <ScAddCustomerIcon data-testid="clients-page-add-customer-icon" />
      </ScCustomerIcon>
      <ScAddCustomerTitleWrapper>
        <ScAddCustomerTitle data-testid="clients-page-add-customer-title">
          {t('CLIENTS_ADD_CUSTOMER_BEFORE_START')}
        </ScAddCustomerTitle>
      </ScAddCustomerTitleWrapper>
      <Button
        onClick={onClick}
        fullwidth
        variant="primary"
        data-testid="clients-page-add-customer-button"
      >
        {t('CLIENTS_ADD_CUSTOMER')}
      </Button>
    </ScAddCustomerContainer>
  );
};
