import { useCallback, useState, useRef } from 'react';
import { Status } from 'utils/types';

// todo: better naming
export interface FileUploadFunctions {
  onProgress(id: string, progress: number): void;
  setupAbort(id: string, abortCallback: () => void): void;
}

interface UploadFileProgress {
  progress: number;
  status: Status;
}

export type UploadFilesProgressGroup = Record<string, UploadFileProgress>;

interface Result {
  progressGroup: UploadFilesProgressGroup;
  abortFileUpload(id?: string): void;
  setupFunctions: FileUploadFunctions;
}

export const useFileUpload = (): Result => {
  const [progressGroup, setProgressGroup] = useState<UploadFilesProgressGroup>({});
  const abortDocumentFileUploadRef = useRef<Record<string, () => void>>({});

  const onProgress = useCallback<Result['setupFunctions']['onProgress']>(
    (id, progress) => {
      setProgressGroup(prevState => ({
        ...prevState,
        [id]: { progress, status: progress === 100 ? Status.Success : Status.Loading },
      }));
    },
    [setProgressGroup],
  );

  const setupAbort = useCallback<Result['setupFunctions']['setupAbort']>(
    (id, abortCallback) => {
      abortDocumentFileUploadRef.current[id] = abortCallback;
    },
    [abortDocumentFileUploadRef],
  );

  const abortDocumentFileUpload = useCallback<Result['abortFileUpload']>(
    id => {
      if (!id) {
        const abortHandlers = Object.values(abortDocumentFileUploadRef.current);
        abortHandlers.forEach(abortHandler => abortHandler());
        setProgressGroup({});
        return;
      }

      const abortHandler = abortDocumentFileUploadRef.current[id];
      if (abortHandler) {
        abortHandler();
        setProgressGroup(prevState => ({
          ...prevState,
          [id]: {
            progress: 0,
            status: Status.Error,
          },
        }));
      }
    },
    [abortDocumentFileUploadRef, setProgressGroup],
  );

  return {
    progressGroup: progressGroup,
    abortFileUpload: abortDocumentFileUpload,
    setupFunctions: {
      onProgress: onProgress,
      setupAbort: setupAbort,
    },
  };
};
