import {
  MessageCodeValues,
  MessageFields,
  Model,
  ModelFields,
} from '@hypercharge/xdms-client/lib/types';
import { Status } from 'utils/types';

/**
 * ## Detect status on model response
 * @param {Model} model
 * @return {Status}
 */
export const getModelResponseStatus = (model: Model): Status => {
  if (!model[ModelFields.model]) return Status.Error;

  const messages = model[ModelFields.message];

  if (messages?.some(m => m[MessageFields.code] === MessageCodeValues.error)) {
    return Status.Error;
  }

  return Status.Success;
};
