import { FilterOutlined } from '@ant-design/icons';
import {
  ConfigurationListItem,
  ConfigurationListItemFields,
  ConfigurationStatusListItem,
  ConfigurationStatusListItemFields,
  /** @todo: replace with {@link ConfigurationListItemFields} */
  OfferItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { DATE_FORMAT, DATE_FORMAT_BE, TABLE_ACTION_RULES } from 'common/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { ScRangePicker } from 'components/form/DatePicker';
import { ConfigurationStatus } from 'components/ConfigurationStatus';
import { ScDropdown, ScMenu } from 'components/table/styles';
import { TableHeaderSearchContainer } from 'components/table/TableHeaderSearchContainer';
import { useCurrency } from 'context/currency/CurrencyProvider';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/es/interface';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'utils';
import { formatPrice } from 'utils/format';
import { SelectedFiltersItemsList_Filters } from '../../components/filters/FiltersList';
import { bubblingPreventer } from '../../components/table/BubblingPreventer';
import getColumnSearchProps from 'components/table/helpers/getColumnSearchProps';
import { featuresFlagsSelectors } from 'store';
import { columnKeys, DateRange, DotsIcon } from './ConfigurationList';
import {
  ScLockOutlined,
  ScMenuItem,
  ScMenuItemContent,
} from './ConfigurationList.styles';
import { DataIndex } from 'components/table/types';
import { TableColumn } from 'components/table';
import { FeatureFlagColumnSettings } from 'types/common';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import Checkbox from 'components/form/Checkbox';
import { useGlobalModalsStates } from 'context/globalModalsStates/GlobalModalsStatesProvider';

type MenuItem = {
  item: React.ReactNode;
  order: number;
};

type Params = {
  tableTitles: Record<string, string>;
  filters: SelectedFiltersItemsList_Filters;
  handleSearch(selectedKeys, confirm, dataIndex: DataIndex): void;
  handleReset(dataIndex: DataIndex): void;
  configurationStatusCodes: ConfigurationStatusListItem[] | null;
  filterStatusCodeList: string[];
  onStatusSubmit(selectedKeys, confirm): void;
  onStatusChange(value: string[]): void;
  onCreationDateSubmit?(selectedKeys, confirm): void;
  onCreationDateChange?(momentDate: RangeValue<moment.Moment>, date: DateRange): void;
  getRangePickerValue?: RangeValue<Moment> | undefined;
  onEditConfiguration?(record: ConfigurationListItem): void;
  handleShowChangeStatusModal?(
    configurationNumber: string | number,
    statusId: string,
  ): void;
  handleShowCopyConfigurationModal?(configurationNumber: number): void;
  handleCreateNewVersion?(configurationNumber: number): Promise<void> | void;
  excludedColumns?: string[];
};

export const useColumns = (params: Params): TableColumn<ConfigurationListItem>[] => {
  const {
    tableTitles,
    filters,
    handleSearch,
    handleReset,
    configurationStatusCodes,
    filterStatusCodeList,
    onStatusSubmit,
    onStatusChange,
    onCreationDateSubmit = () => {
      console.warn(`'onCreationDateSubmit' called, but actual function not passed`);
    },
    onCreationDateChange,
    getRangePickerValue,
    onEditConfiguration,
    handleShowChangeStatusModal,
    handleShowCopyConfigurationModal,
    handleCreateNewVersion,
    excludedColumns,
  } = params;
  const { t, i18n } = useTranslation();
  const { currencyCode } = useCurrency();
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const { setConfigurationImportModalState } = useGlobalModalsStates();

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[
      GlobalFeaturesFlagsFields.ConfigurationListColumns
    ] as FeatureFlagColumnSettings[] | undefined;

    if (!columns) return [];
    return columns.filter(({ name }) => !excludedColumns?.includes(name));
  }, [globalFeatures, excludedColumns]);

  const getActionRules = useCallback((offer: ConfigurationListItem) => {
    const actionRulesString = get(offer, OfferItemFields.prohibitedActions, '');
    return actionRulesString.split(',');
  }, []);

  const getMenuItemEditOffer = useCallback(
    (offer: ConfigurationListItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => onEditConfiguration?.(offer)}
            data-testid="configurations-page-table-item-action-edit"
          >
            <ScMenuItemContent>{t('CONFIGURATION_LIST_EDIT_ACTION')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        order: 0,
      };
    },
    [t, onEditConfiguration],
  );

  const getMenuItemShowCopyConfiguration = useCallback(
    (offer: ConfigurationListItem) => {
      const id = get(offer, OfferItemFields.ID);
      return {
        item: (
          <ScMenuItem
            onClick={() => id && handleShowCopyConfigurationModal?.(+id)}
            data-testid="configurations-page-table-item-action-copy"
          >
            <ScMenuItemContent>{t('COPY_ACTION')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        order: 1,
      };
    },
    [t, handleShowCopyConfigurationModal],
  );

  const getMenuItemChangeStatus = useCallback(
    (offer: ConfigurationListItem) => {
      const actionRules = getActionRules(offer);
      const id = get(offer, OfferItemFields.ID);
      const status = get(offer, OfferItemFields.statusCode);
      return {
        item: (
          <ScMenuItem
            onClick={() => id && status && handleShowChangeStatusModal?.(id, status)}
            disabled={actionRules.includes(TABLE_ACTION_RULES.noStatusChange)}
            data-testid="configurations-page-table-item-action-change-status"
          >
            <ScMenuItemContent>{t('CHANGE_STATUS_ACTION')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        order: 2,
      };
    },
    [t, handleShowChangeStatusModal, getActionRules],
  );

  const getMenuItemNewVersion = useCallback(
    (offer: ConfigurationListItem) => {
      const actionRules = getActionRules(offer);
      const id = get(offer, OfferItemFields.ID);
      return {
        item: (
          <ScMenuItem
            onClick={() => id && handleCreateNewVersion?.(+id)}
            disabled={actionRules.includes(TABLE_ACTION_RULES.noNewVersion)}
            data-testid="configurations-page-table-item-action-new-version"
          >
            <ScMenuItemContent>{t('NEW_VERSION_ACTION')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        order: 3,
      };
    },
    [t, handleCreateNewVersion, getActionRules],
  );

  const getMenuItemImport = useCallback(
    (configuration: ConfigurationListItem) => {
      const id = get(configuration, OfferItemFields.ID);
      return {
        item: (
          <ScMenuItem
            onClick={() => {
              if (!id || !configuration) return;
              setConfigurationImportModalState({
                initialValues: { configurationNumber: id },
                onSubmit: () => onEditConfiguration?.(configuration),
              });
            }}
            data-testid="configurations-page-table-item-action-import"
          >
            <ScMenuItemContent>{t('IMPORT_ACTION')}</ScMenuItemContent>
          </ScMenuItem>
        ),
        order: 3,
      };
    },
    [t, setConfigurationImportModalState, onEditConfiguration],
  );

  const flagToMenuItem = useMemo<
    {
      featuresFlags: string[];
      fn: (offer: ConfigurationListItem) => MenuItem;
    }[]
  >(() => {
    return [
      {
        featuresFlags: [GlobalFeaturesFlagsFields.allowConfigurationListView],
        fn: getMenuItemEditOffer,
      },
      {
        featuresFlags: [GlobalFeaturesFlagsFields.allowConfigurationListCopy],
        fn: getMenuItemShowCopyConfiguration,
      },
      {
        featuresFlags: [GlobalFeaturesFlagsFields.allowConfigurationListChangeStatus],
        fn: getMenuItemChangeStatus,
      },
      {
        featuresFlags: [GlobalFeaturesFlagsFields.allowConfigurationListNewVersion],
        fn: getMenuItemNewVersion,
      },
      {
        featuresFlags: [
          GlobalFeaturesFlagsFields.allowConfigurationImport,
          GlobalFeaturesFlagsFields.allowConfigurationImportUpdate,
        ],
        fn: getMenuItemImport,
      },
    ];
  }, [
    getMenuItemEditOffer,
    getMenuItemShowCopyConfiguration,
    getMenuItemChangeStatus,
    getMenuItemNewVersion,
    getMenuItemImport,
  ]);

  const menu = useCallback(
    (offer: ConfigurationListItem) => {
      const menuItems: MenuItem[] = [];

      // if no globalFeatures provided - should hide 'em all, or display all actions.
      if (!globalFeatures) return <></>;

      flagToMenuItem.forEach(({ featuresFlags, fn }) => {
        const allFeaturesEnabled = featuresFlags.every(key => {
          const v = globalFeatures[key];
          return typeof v === 'boolean' && v;
        });

        if (allFeaturesEnabled) menuItems.push(fn(offer));
      });

      return (
        <ScMenu
          onClick={({ domEvent }) => bubblingPreventer(domEvent)}
          data-testid="configurations-page-table-item-actions-container"
        >
          {menuItems.map(menuItem => menuItem.item)}
        </ScMenu>
      );
    },
    [globalFeatures, flagToMenuItem],
  );

  // reason of minWidth defined in {@link Table component minWidth property}
  const keyToColumn: Record<string, TableColumn<ConfigurationListItem>> = useMemo(() => {
    const offerVersion = 'NRown';

    return {
      [columnKeys.PUBLISH_STATE]: {
        title: tableTitles[columnKeys.PUBLISH_STATE],
        key: columnKeys.PUBLISH_STATE,
        dataIndex: columnKeys.PUBLISH_STATE,
        width: '6em',
        maxWidth: '20em',
        render: value => {
          return value && <ScLockOutlined />;
        },
      },
      [columnKeys.WAREHOUSE_NUMBER]: {
        title: tableTitles[columnKeys.WAREHOUSE_NUMBER],
        key: columnKeys.WAREHOUSE_NUMBER,
        dataIndex: columnKeys.WAREHOUSE_NUMBER,
        width: '6em',
        maxWidth: '20em',
      },
      [columnKeys.ID]: {
        title: tableTitles[columnKeys.ID],
        key: offerVersion,
        dataIndex: offerVersion,
        minWidth: '9em',
        maxWidth: '20em',
        sorter: (a, b) => {
          return get(a, offerVersion, '').localeCompare(get(b, offerVersion, ''));
        },
        ...getColumnSearchProps({
          dataIndex: columnKeys.ID,
          filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.NAME]: {
        title: tableTitles[columnKeys.NAME],
        key: columnKeys.NAME,
        minWidth: '11em',
        maxWidth: '20em',
        dataIndex: columnKeys.NAME,
        sorter: (a, b) => {
          return get(a, columnKeys.NAME, '').localeCompare(get(b, columnKeys.NAME, ''));
        },
        ...getColumnSearchProps({
          dataIndex: columnKeys.NAME,
          filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.CUSTOMER_NAME]: {
        title: tableTitles[columnKeys.CUSTOMER_NAME],
        dataIndex: columnKeys.CUSTOMER_NAME,
        minWidth: '11em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.CUSTOMER_NAME, '').localeCompare(
            get(b, columnKeys.CUSTOMER_NAME, ''),
          ),
        ...getColumnSearchProps({
          dataIndex: columnKeys.CUSTOMER_NAME,
          filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.CUSTOMER_LAST_NAME]: {
        title: tableTitles[columnKeys.CUSTOMER_LAST_NAME],
        dataIndex: columnKeys.CUSTOMER_LAST_NAME,
        minWidth: '11em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.CUSTOMER_LAST_NAME, '').localeCompare(
            get(b, columnKeys.CUSTOMER_LAST_NAME, ''),
          ),
        ...getColumnSearchProps({
          dataIndex: columnKeys.CUSTOMER_LAST_NAME,
          filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.CUSTOMER_CONTACT]: {
        title: tableTitles[columnKeys.CUSTOMER_CONTACT],
        dataIndex: columnKeys.CUSTOMER_CONTACT,
        minWidth: '10em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.CUSTOMER_CONTACT, '').localeCompare(
            get(b, columnKeys.CUSTOMER_CONTACT, ''),
          ),
        render: (_, record) => ({
          children:
            record[ConfigurationListItemFields.phone] ||
            record[ConfigurationListItemFields.phone2] ||
            record[ConfigurationListItemFields.phoneMobile] ||
            record[ConfigurationListItemFields.phoneMobile2],
        }),
      },
      [columnKeys.END_USER]: {
        title: tableTitles[columnKeys.END_USER],
        dataIndex: columnKeys.END_USER,
        minWidth: '12em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.END_USER).localeCompare(get(b, columnKeys.END_USER, '')),
        ...getColumnSearchProps({
          dataIndex: columnKeys.END_USER,
          filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.MODEL]: {
        title: tableTitles[columnKeys.MODEL],
        dataIndex: columnKeys.MODEL,
        minWidth: '10em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.MODEL, '').localeCompare(get(b, columnKeys.MODEL, '')),
        ...getColumnSearchProps({
          dataIndex: columnKeys.MODEL,
          filters,
          render: value => value,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.ACTUAL_PRICE]: {
        title: tableTitles[columnKeys.ACTUAL_PRICE],
        dataIndex: columnKeys.ACTUAL_PRICE,
        minWidth: '10em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(b, columnKeys.ACTUAL_PRICE, 0) - get(a, columnKeys.ACTUAL_PRICE, 0),
        render: value => {
          return {
            children: formatPrice({
              locale: i18n.language,
              price: value,
              currency: currencyCode,
            }),
          };
        },
      },
      [columnKeys.STATUS]: {
        title: tableTitles[columnKeys.STATUS],
        dataIndex: columnKeys.STATUS,
        minWidth: '9em',
        maxWidth: '20em',
        sorter: (a, b) =>
          get(a, columnKeys.STATUS, '').localeCompare(get(b, columnKeys.STATUS, '')),
        ...getColumnSearchProps({
          filters,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
              <TableHeaderSearchContainer
                dataIndex={columnKeys.STATUS}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                handleSearch={onStatusSubmit}
                handleReset={handleReset}
                width="200px"
              >
                <Checkbox.Group
                  options={mapToSelectOptions(configurationStatusCodes ?? [], {
                    label: ConfigurationStatusListItemFields.name,
                    value: ConfigurationStatusListItemFields.code,
                  })}
                  onChange={values => onStatusChange(values.map(value => String(value)))}
                  value={filterStatusCodeList}
                />
              </TableHeaderSearchContainer>
            );
          },
          dataIndex: columnKeys.STATUS,
          render: (codeId, record) => {
            const configurationStatusCode = configurationStatusCodes?.find(
              statusCode =>
                codeId === get(statusCode, ConfigurationStatusListItemFields.code),
            );
            const configurationStatusCodeParams = {
              description: get(
                configurationStatusCode,
                ConfigurationStatusListItemFields.name,
                '',
              ),
              code: codeId,
              color: record[ConfigurationListItemFields.statusColor],
            };

            return {
              children: <ConfigurationStatus {...configurationStatusCodeParams} />,
            };
          },
          filterIcon: <FilterOutlined />,
          filteredValue: filterStatusCodeList,
          handleReset,
          handleSearch,
        }),
      },
      [columnKeys.CREATION_DATE]: {
        title: tableTitles[columnKeys.CREATION_DATE],
        key: columnKeys.CREATION_DATE,
        minWidth: '10em',
        maxWidth: '20em',
        dataIndex: columnKeys.CREATION_DATE,
        sorter: (a, b) =>
          new Date(get(b, columnKeys.CREATION_DATE, '')).getTime() -
          new Date(get(a, columnKeys.CREATION_DATE, '')).getTime(),
        ...getColumnSearchProps({
          dataIndex: t('CREATION_DATE'),
          filters,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
              <TableHeaderSearchContainer
                dataIndex={columnKeys.CREATION_DATE}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                handleSearch={onCreationDateSubmit}
                handleReset={handleReset}
                width="18.75em"
              >
                <ScRangePicker
                  onChange={onCreationDateChange}
                  format={DATE_FORMAT}
                  value={getRangePickerValue}
                />
              </TableHeaderSearchContainer>
            );
          },
          filterIcon: <FilterOutlined />,
          handleReset,
          handleSearch,
          render: value => {
            return value ? moment(value, DATE_FORMAT_BE).format(DATE_FORMAT) : '';
          },
        }),
        filteredValue: (() => {
          const { dateCreatedFrom, dateCreatedTo } = ConfigurationListItemFields;
          const createdDateFilter = filters.find(
            filter => filter.name === dateCreatedFrom || filter.name === dateCreatedTo,
          );
          return createdDateFilter
            ? [
                `${get(createdDateFilter, dateCreatedFrom)} - ${get(
                  createdDateFilter,
                  dateCreatedTo,
                )}`,
              ]
            : null;
        })(),
      },
      [columnKeys.CREATED_BY]: {
        title: tableTitles[columnKeys.CREATED_BY],
        key: columnKeys.CREATED_BY,
        minWidth: '12em',
        maxWidth: '20em',
        dataIndex: columnKeys.CREATED_BY,
        sorter: (a, b) =>
          get(a, columnKeys.CREATED_BY, '').localeCompare(
            get(b, columnKeys.CREATED_BY, ''),
          ),
      },
      [columnKeys.CHANGED_BY]: {
        title: tableTitles[columnKeys.CHANGED_BY],
        key: columnKeys.CHANGED_BY,
        minWidth: '10em',
        maxWidth: '20em',
        dataIndex: columnKeys.CHANGED_BY,
        sorter: (a, b) =>
          get(a, columnKeys.CHANGED_BY, '0').localeCompare(
            get(b, columnKeys.CHANGED_BY, '0'),
          ),
      },
      [columnKeys.TOKEN]: {
        title: tableTitles[columnKeys.TOKEN],
        minWidth: '10em',
        maxWidth: '20em',
        key: columnKeys.TOKEN,
        dataIndex: columnKeys.TOKEN,
      },
      [columnKeys.STOCK_NUMBER]: {
        title: tableTitles[columnKeys.STOCK_NUMBER],
        minWidth: '10em',
        maxWidth: '20em',
        key: columnKeys.STOCK_NUMBER,
        dataIndex: columnKeys.STOCK_NUMBER,
        align: 'center',
        // to dont display 0
        render: data => ({
          children: data || '',
        }),
      },
      [columnKeys.CALL_NAME]: {
        title: tableTitles[columnKeys.CALL_NAME],
        minWidth: '10em',
        maxWidth: '20em',
        key: columnKeys.CALL_NAME,
        dataIndex: columnKeys.CALL_NAME,
      },
      [columnKeys.ACTIONS]: {
        title: tableTitles[columnKeys.ACTIONS],
        key: columnKeys.ACTIONS,
        fixed: 'right' as const,
        width: '5.5em',
        render: data => (
          <>
            <ScDropdown
              trigger={['click']}
              overlay={() => menu(data)}
              placement="bottomLeft"
              data-testid="configurations-page-table-item-actions-btn"
            >
              <DotsIcon style={{ fontSize: '2em' }} />
            </ScDropdown>
          </>
        ),
      },
    };
  }, [
    tableTitles,
    filters,
    handleReset,
    handleSearch,
    filterStatusCodeList,
    t,
    i18n.language,
    currencyCode,
    onStatusSubmit,
    configurationStatusCodes,
    onStatusChange,
    onCreationDateSubmit,
    onCreationDateChange,
    getRangePickerValue,
    menu,
  ]);

  return useMemo<TableColumn<ConfigurationListItem>[]>(() => {
    let columns: TableColumn<ConfigurationListItem>[] = [];

    if (columnsSettings) {
      const columnKeysToRender: string[] = columnsSettings?.map(
        columnSettings => columnSettings.name,
      );
      columns = columnKeysToRender.map(key => keyToColumn[key]).filter(Boolean);
    } else {
      columns = Object.values(keyToColumn).filter(Boolean);
    }

    // exclude 'undefined's
    columns = columns.filter(Boolean);

    const hasActionsColumn = columns.some(column => column.key === columnKeys.ACTIONS);
    if (!hasActionsColumn && !excludedColumns?.includes(columnKeys.ACTIONS)) {
      columns.push(keyToColumn[columnKeys.ACTIONS]);
    }

    return columns;
  }, [columnsSettings, keyToColumn, excludedColumns]);
};
