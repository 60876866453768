import {
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';

/**
 * Sorts numeric values for Dynamic Page table
 *
 * @param a {Model_CommonItem} - prev item
 * @param b {Model_CommonItem} - next item
 * @param sortingField {Model_CommonItemFields} - field to sort by
 * @returns {number}
 */
export const sortNumericValues = (
  a: Model_CommonItem,
  b: Model_CommonItem,
  sortingField: Model_CommonItemFields,
): number => {
  const aOperand = get(a, sortingField) as number;
  const bOperand = get(b, sortingField) as number;
  return bOperand - aOperand;
};
