import * as Yup from 'yup';
import { TFunction } from 'i18next';

export interface LoginFormValues {
  userName: string;
  password: string;
}

export function getLoginFormSchema(t: TFunction): Yup.Schema<LoginFormValues> {
  return Yup.object().shape({
    userName: Yup.string().required(t('FORM__REQUIRED')),
    password: Yup.string().required(t('FORM__REQUIRED')),
  });
}
