import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import {
  MachineListItemFields,
  MachineListItem,
  Filter,
} from '@hypercharge/xdms-client/lib/types';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import getColumnSearchProps from 'components/table/helpers/getColumnSearchProps';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { get } from 'utils';
import { DataIndex } from 'components/table/types';
import { FeatureFlagColumnSettings } from 'types/common';

const columnKey = {
  ID: MachineListItemFields.id,
  NAME: MachineListItemFields.shortName,
  BRAND: MachineListItemFields.brandName,
  MODEL: MachineListItemFields.modelName,
  LICENCE: MachineListItemFields.licenceNumber,
  WORKING_HOURS: MachineListItemFields.kilometrage,
  CUSTOMER: MachineListItemFields.customerNumber,
  TYPE: MachineListItemFields.vehicleTypeCode,
  FIRST_REGISTRATION_DATE: MachineListItemFields.firstRegistrationAt,
};

type Params = {
  handleReset(dataIndex: DataIndex): Promise<void>;
  filters: Filter<keyof MachineListItem>;
  setFilters: Dispatch<SetStateAction<Filter<keyof MachineListItem>>>;
};

export const useColumns = (params: Params): ColumnsType<MachineListItem> => {
  const { handleReset, filters, setFilters } = params;

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[
      GlobalFeaturesFlagsFields.TradeInMachineSearchModalColumns
    ] as FeatureFlagColumnSettings[] | undefined;

    if (!columns) return [];
    return columns;
  }, [globalFeatures]);

  const { t } = useTranslation();

  const handleSearch = useCallback(
    async (selectedKeys, confirm, dataIndex) => {
      confirm({ closeDropdown: true });
      const preparedFilters = filters.filter(filter => get(filter, 'name') !== dataIndex);
      const filterValue = get(selectedKeys, 0, '');

      if (filterValue) {
        preparedFilters.push({
          name: dataIndex,
          value: filterValue,
        });
      }

      setFilters(preparedFilters);
    },
    [filters, setFilters],
  );

  const keyToColumn: Record<string, ColumnType<MachineListItem>> = useMemo(() => {
    return {
      [columnKey.ID]: {
        title: t('MACHINE_SERIAL'),
        dataIndex: MachineListItemFields.id,
        key: MachineListItemFields.id,
        ...getColumnSearchProps({
          dataIndex: MachineListItemFields.id,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.NAME]: {
        title: t('MACHINE_NAME'),
        dataIndex: MachineListItemFields.shortName,
        align: 'center',
        key: MachineListItemFields.shortName,
        ...getColumnSearchProps({
          dataIndex: MachineListItemFields.shortName,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.BRAND]: {
        title: t('MACHINE_BRAND_NAME'),
        dataIndex: MachineListItemFields.brandName,
        key: MachineListItemFields.brandName,
        ...getColumnSearchProps({
          dataIndex: MachineListItemFields.brandName,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.MODEL]: {
        title: t('MACHINE_MODEL_NAME'),
        dataIndex: MachineListItemFields.modelName,
        key: MachineListItemFields.modelName,
      },
      [columnKey.LICENCE]: {
        title: t('MACHINE_LICENCE'),
        dataIndex: MachineListItemFields.licenceNumber,
        key: MachineListItemFields.licenceNumber,
        ...getColumnSearchProps({
          dataIndex: MachineListItemFields.licenceNumber,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.WORKING_HOURS]: {
        title: t('MACHINE_WORKING_HOURS'),
        dataIndex: MachineListItemFields.kilometrage,
        align: 'center',
        key: MachineListItemFields.kilometrage,
      },
      [columnKey.CUSTOMER]: {
        title: t('MACHINE_CUSTOMER_ID'),
        dataIndex: MachineListItemFields.customerNumber,
        key: MachineListItemFields.customerNumber,
        align: 'center',
        ...getColumnSearchProps({
          dataIndex: MachineListItemFields.customerNumber,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.TYPE]: {
        title: t('MACHINE_TYPE'),
        dataIndex: MachineListItemFields.vehicleTypeCode,
        align: 'center',
        key: MachineListItemFields.vehicleTypeCode,
      },
      [columnKey.FIRST_REGISTRATION_DATE]: {
        title: t('MACHINE_FIRST_REGISTRATION_DATE'),
        key: MachineListItemFields.firstRegistrationAt,
        dataIndex: MachineListItemFields.firstRegistrationAt,
      },
    };
  }, [filters, handleReset, handleSearch, t]);

  return useMemo<ColumnsType<MachineListItem>>(() => {
    let columns: ColumnsType<MachineListItem> = [];

    if (columnsSettings) {
      const columnKeysToRender: string[] = columnsSettings?.map(
        columnSettings => columnSettings.name,
      );
      columns = columnKeysToRender.map(key => keyToColumn[key]).filter(Boolean);
    } else {
      columns = Object.values(keyToColumn).filter(Boolean);
    }

    // exclude 'undefined's
    columns = columns.filter(Boolean);

    return columns;
  }, [columnsSettings, keyToColumn]);
};
