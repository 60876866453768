import 'hyperplan/dist/hyperplan.min.css';
import React, { FC, useCallback } from 'react';
import { AppProviders, AuthenticatedAppProviders } from './providers';
import './assets/styles/index.css';
import AppAuthenticated from './AppAuthenticated';
import GlobalStyles from './assets/dynamicStyles';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import { authSelectors } from './store/auth';
import { Route, Switch, useHistory } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { useQuery } from './context/router/UrlQueryProvider';
import { hostSettingsSelectors, sharedActions } from './store';
import { LOGIN_URL, XDMS_SETTINGS_URL } from './common/constants';
import HostSettingsPage from './pagesAuth/HostSettings/HostSettingsPage';
import LoginPage from './pagesAuth/Login/LoginPage';
import { UrlTransform } from './utils/urlTransform';

if (!window.IntersectionObserver) {
  require('intersection-observer');
}

export const UNAUTHENTICATED_ROUTES = [XDMS_SETTINGS_URL, LOGIN_URL];

const App: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { queryValues, initialQuery } = useQuery();

  const host = useSelector(hostSettingsSelectors.getHost);
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

  /** It is a replacement for {@link Redirect} in order to save initial URL to redux.
   * @note `react-router state` doesn't fit here */
  const redirect = useCallback<() => null>(() => {
    if (!host || !isAuthenticated) {
      if (!matchPath(location.pathname, UNAUTHENTICATED_ROUTES)) {
        // using initialQuery instead current location query as current location query
        // may be changed in a moments between user logged in with wrong token and
        // being redirected to login
        dispatch(
          sharedActions.setInitialLocation({
            ...location,
            search: '?' + UrlTransform.stringifyQuery(initialQuery),
          }),
        );
      }

      const path = !host || !queryValues.hostLock ? XDMS_SETTINGS_URL : LOGIN_URL;
      history.push(path);
    }
    return null;
  }, [
    dispatch,
    history,
    host,
    isAuthenticated,
    location,
    queryValues.hostLock,
    initialQuery,
  ]);

  return (
    <Switch>
      {!queryValues.hostLock && (
        <Route path={XDMS_SETTINGS_URL} component={HostSettingsPage} />
      )}

      <Route path={LOGIN_URL} component={LoginPage} />

      {isAuthenticated && (
        <Route
          render={() => {
            return (
              <AuthenticatedAppProviders>
                <AppAuthenticated />
              </AuthenticatedAppProviders>
            );
          }}
        />
      )}
      <Route path="*" render={redirect} />
    </Switch>
  );
};

const AppWrapped: FC = () => {
  return (
    <Provider store={store}>
      <AppProviders>
        <GlobalStyles />
        <App />
      </AppProviders>
    </Provider>
  );
};

export default AppWrapped;
