import {
  ConfigurationDocListItem,
  DocumentListItem,
  DocumentListItem_LockType_Values,
} from '@hypercharge/xdms-client/lib/types';
import { DocumentItem } from './types';
import { DocumentRecordSourceValue } from 'types/vendor';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'common/constants';

type SortOptions = {
  isNewestSort?: boolean;
};

type Doc = DocumentListItem | ConfigurationDocListItem | DocumentItem;

enum SortBy {
  Priority = 'sortByPriority',
  PDF = 'sortByPDF',
  Newest = 'sortByNewest',
}

const sortMap: Record<SortBy, (a, b) => number> = {
  [SortBy.PDF]: (a: DocumentItem, b: DocumentItem) => {
    const aIsPDF = isDocPDF(a);
    const bIsPDF = isDocPDF(b);

    return Number(bIsPDF) - Number(aIsPDF);
  },
  [SortBy.Priority]: (a: DocumentItem, b: DocumentItem) => {
    const aIsPDF = isDocPDF(a);
    const bIsPDF = isDocPDF(b);

    const aCreatedAt = getDocDate(a);
    const bCreatedAt = getDocDate(b);

    const aIsDefault = isDocDefault(a);
    const bIsDefault = isDocDefault(b);

    const aSeq = getDocSeq(a);
    const bSeq = getDocSeq(b);

    if (aIsPDF && bIsPDF) return Number(aCreatedAt) - Number(bCreatedAt);
    if (aIsPDF || bIsPDF) return Number(bIsPDF) - Number(aIsPDF);

    if (aIsDefault && bIsDefault) return bSeq - aSeq;
    if (aIsDefault || bIsDefault) return Number(bIsDefault) - Number(aIsDefault);

    return aSeq - bSeq;
  },
  [SortBy.Newest]: (a: DocumentItem, b: DocumentItem) => {
    if (b.updatedAt && a.updatedAt) {
      return Number(getDocDate(b)) - Number(getDocDate(a));
    }

    return 0;
  },
};

export const getDocumentsSorted = (
  documentItems: DocumentItem[],
  options: SortOptions = {},
): DocumentItem[] => {
  const { isNewestSort = false } = options;
  const result = cloneDeep(documentItems);

  (isNewestSort ? [SortBy.Newest, SortBy.PDF] : [SortBy.Priority]).forEach(key =>
    result.sort(sortMap[key]),
  );

  return result;
};

export const isDocDefault = (item: Doc): boolean => {
  return item.lockType === DocumentListItem_LockType_Values.default;
};

/** If automatically generated PDF file */
export const isDocPDF = (item: Doc): boolean => {
  return item.source === DocumentRecordSourceValue.autogenerated;
};

/** Get document record {@link createdAt} */
const getDocDate = (item: Doc): Date | null => {
  // TODO: replace `updatedAt` with `createdAt`
  return item.updatedAt
    ? moment(item.updatedAt, `${DATE_FORMAT} ${TIME_FORMAT}`).toDate()
    : null;
};

/** Get document record {@link sequentialNumber} */
export const getDocSeq = (item: Doc): number => {
  return item.sequentialNumber;
};
