import { DocumentsTreeData } from './types';
import { cloneDeep } from 'utils';

export const documentTreeHelpers = {
  getSelectedDocumentsIds: (tree: DocumentsTreeData): (string | number)[] => {
    const res: (string | number)[] = [];

    tree.forEach(entity => {
      entity.relatedEntities.forEach(entityItem => {
        res.push(...entityItem.selectedIds);
      });
    });

    return res;
  },
  /** Its a pine treee :) */
  filterSelectedDocuments: (tree: DocumentsTreeData): DocumentsTreeData => {
    let newTree: DocumentsTreeData = cloneDeep(tree);

    newTree.forEach(entity => {
      entity.relatedEntities.forEach(entityItem => {
        entityItem.documents = entityItem.documents.filter(({ id }) =>
          entityItem.selectedIds.includes(id),
        );
      });
    });

    newTree.forEach(entity => {
      entity.relatedEntities = entity.relatedEntities.filter(
        entityItem => entityItem.documents.length,
      );
    });

    newTree = newTree.filter(entity => entity.relatedEntities.length);

    return newTree;
  },
};
