import { STORAGE_KEYS } from 'common/constants';
import Switcher from 'components/form/Switcher';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { streamingActions, streamingSelectors } from 'store';
import { saveInLocalStorage } from 'utils/storage';

const StreamingToggle: React.FC<Props> = ({ alignment }) => {
  const { t } = useTranslation();

  const { sendMessage } = useStreaming();

  const dispatch = useDispatch();

  const isEnabled = useSelector(streamingSelectors.getIsEnabled);
  const activeTerminal = useSelector(streamingSelectors.getActiveTerminal);
  const preferredTerminal = useSelector(streamingSelectors.getPreferredTerminal);

  const handleClick = useCallback(
    (checked: boolean) => {
      dispatch(streamingActions.setIsEnabled(checked));
      saveInLocalStorage(STORAGE_KEYS.isStreamingInitiallyEnabled, checked ? 1 : 0);

      if (!activeTerminal) return;

      const { customerId } = activeTerminal;

      sendMessage({
        type:
          activeTerminal.id === preferredTerminal?.id
            ? StreamingEventType.LOGOUT_TERMINAL
            : StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
        data: {
          customerId: customerId,
        },
      });
    },
    [dispatch, activeTerminal, preferredTerminal?.id, sendMessage],
  );

  return (
    <Switcher
      alignment={alignment}
      value={isEnabled}
      onClick={handleClick}
      title={t('STREAMING_TOGGLE')}
    />
  );
};

type Props = {
  alignment?: React.ComponentProps<typeof Switcher>['alignment'];
};

export default StreamingToggle;
