import {
  Model,
  ModelRecord,
  ModelRecordType,
  ModelRecordFields,
  ModelFields,
  ModelRuleValues,
  ModelRule,
} from 'types/vendor';
import { ModelRecordRules } from '../types';
import { RulesModalCoveredRules } from '../Modals/RulesModal/RulesModal.meta';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';
import { ExcludesFalse } from 'types/common';
import { getRecordRules } from './get-record-rules';

const isReplaceableRuleFits = (model: Model, records: ModelRecord[]): boolean => {
  return records.every(item => !item[ModelRecordFields.selected]);
};

const isRelatedRuleFits = (
  model: Model,
  records: ModelRecord[],
  modelRules: ModelRule[],
): boolean => {
  return records.every(item => {
    const recordRules = getRecordRules(modelRules, item[ModelRecordFields.ID]);
    const incompatibleRecordsIds = recordRules[ModelRuleValues.incompatible];
    const isAnyIncompatibleRecordSelected = incompatibleRecordsIds
      ?.map(id => getModelCommonItemById(model, id))
      ?.some(item => item?.[ModelRecordFields.selected]);

    return (
      isAnyIncompatibleRecordSelected ||
      item[ModelRecordFields.selected] ||
      item[ModelRecordFields.type] === ModelRecordType.package ||
      item[ModelRecordFields.type] === ModelRecordType.packageLine
    );
  });
};

const isObligatoryRuleFits = (model: Model, records: ModelRecord[]): boolean => {
  return records.every(item => {
    if (item[ModelRecordFields.type] === ModelRecordType.packageLine) {
      const package_ = model[ModelFields.packages]?.find(
        record => record[ModelRecordFields.ID] === item[ModelRecordFields.package],
      );

      if (package_) return package_[ModelRecordFields.selected];
    }

    return item[ModelRecordFields.selected];
  });
};

const isObligatoryOneRuleFits = (model: Model, records: ModelRecord[]): boolean => {
  return records.some(item => {
    if (item[ModelRecordFields.type] === ModelRecordType.packageLine) {
      const package_ = model[ModelFields.packages]?.find(
        record => record[ModelRecordFields.ID] === item[ModelRecordFields.package],
      );

      if (package_) return package_[ModelRecordFields.selected];
    }

    return item[ModelRecordFields.selected];
  });
};

const checksVocabulary = {
  [RulesModalCoveredRules.obligatoryOne]: isObligatoryOneRuleFits,
  [RulesModalCoveredRules.obligatory]: isObligatoryRuleFits,
  [RulesModalCoveredRules.replaceable]: isReplaceableRuleFits,
  [RulesModalCoveredRules.related]: isRelatedRuleFits,
};

export const isCommonItemDoesntFitAnyRule = (
  model: Model,
  modelRules: ModelRule[],
  rules: ModelRecordRules,
): boolean => {
  return (
    Object.values(RulesModalCoveredRules)
      .map(ruleKey => {
        const idsList = rules[ruleKey];

        if (!idsList?.length) return true;

        const itemsList = idsList
          .map(id => getModelCommonItemById(model, id))
          .filter(Boolean as unknown as ExcludesFalse);

        if (!itemsList.length) return true;

        return checksVocabulary[ruleKey]?.(model, itemsList, modelRules);
      })
      // if any fails - return true
      .some(result => !result)
  );
};
