import { Divider, Select } from 'antd';
import styled from 'styled-components';

export const ScDocumentModalSelect = styled(Select)`
  font-size: 0.875em;
  .ant-select-selector {
    padding: 0.8em 1em;
  }
  .ant-select-selection-placeholder {
    left: 1em;
  }
  .ant-select-selection-search {
    margin-left: 0;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  &.ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.mainColor};
    box-shadow: none;
  }
`;

export const ScDocumentModalDivider = styled(Divider)`
  margin-right: -1.5em;
  margin-left: -1.5em;
  width: calc(100% + 3em);
`;

export const ScDocumentModalHeader = styled.h2`
  padding-top: 1em;
  margin: 0;
  text-align: center;
  font-weight: 600;
`;

export const ScDocumentModalSectionTitle = styled.h3`
  font-size: 0.75em;
  font-weight: 600;
  margin-bottom: 1em;
`;

export const ScDocumentModalBody = styled.div`
  padding: 1.5em;
  padding-top: 0;
`;

export const ScDocumentModalSectionWrapper = styled.div`
  margin-bottom: 1.625em;
`;

export const ScDocumentModalSectionEntity = styled.div<{ $bordered?: boolean }>`
  margin-bottom: 0.5em;
  padding: ${({ $bordered }) => ($bordered ? '1em' : '0')};
  border: ${({ $bordered, theme }) =>
    $bordered ? `1px solid ${theme.colors.lightSilver}` : 'none'};
`;
export const ScDocumentModalSectionEntityName = styled.div`
  font-size: 0.75em;
  margin-left: 2em;
`;
