import styled from 'styled-components';
import { Col, Row } from 'antd';
import { ScMenuItem as MenuItem } from '../../components/table/styles';
import { LockOutlined } from '@ant-design/icons';
import Table from 'components/table';

export const ScColRight = styled(Col)`
  margin-left: auto;
  flex-shrink: 0;
`;

export const ScConfigurationFiltersRow = styled(Row)`
  flex-wrap: nowrap;
  height: 100%;
`;

export const ScMenuItem = styled(MenuItem)`
  padding-left: 2.5em;
  padding-right: 2.5em;

  &.ant-dropdown-menu-item-disabled:hover {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.softPeach};
  }
`;

export const ScMenuItemContent = styled.span`
  font-size: 0.75em;
  line-height: 1em;
`;

export const ScConfigurationFilters = styled.div`
  width: 100%;
  height: 3.125em;
  margin-bottom: 1em;
`;

export const ScLockOutlined = styled(LockOutlined)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.highlightColor};
`;

export const ScTable = styled(Table)`
  &&&& {
    th {
      font-size: 0.875em;

      * {
        font-size: inherit;
      }
    }
  }
`;
