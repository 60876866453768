import { Status } from 'utils/types';

export type Option = {
  label: string;
  value: string;
};

export type OptionWithKey = Option & {
  key: string;
  disabled: boolean;
};

export type ScrollMenuOptions = {
  key: string;
  name: string;
  tKey?: string;
  active: boolean;
  disabled: boolean;
  link?: string;
  required?: boolean;
  configured?: boolean;
  staticId: string;
};

export type ImageItem = {
  id: string;
  image: string;
  alt: string;
};

export interface RequestResponseDetails<R = unknown> {
  status: Status;
  messageHandled?: boolean;
  response?: R;
  errors?: (Error | string)[];
}

// special small type to use with .filter(Boolean)
export type ExcludesFalse = <T>(x: T | false | undefined | '' | 0) => x is T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeepPartial<T> = T extends Record<string, any>
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type FeatureFlagColumnSettings = {
  name: string;
  sort?: 'ascend' | 'descend';
  icon?: string;
};

export type FeatureFlagFieldSettings = {
  name: string;
};

export enum PreviewImageType {
  Package = 'package',
  Option = 'option',
  Accessory = 'accessory',
}

export type PreviewImage = {
  url: string;
  description: string | null;
  focused: boolean;
  options: string[];
  name: string;
  isDefault: boolean;
  type: PreviewImageType;
  tKey: string;
  code: string | null;
};
