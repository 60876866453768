import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  ProspectDossier_GetById_Output as ProspectDossier,
  ProspectDossier_GetById_OutputFields as ProspectDossierFields,
  ProspectDossierCarInfoFields,
} from '@hypercharge/xdms-client/lib/types';

const NAME = 'dossier';

interface State {
  readonly prospectDossierStatus: Status;
  readonly prospectDossier: ProspectDossier | null;
  readonly carBrandCurrent: string | null;
}

const initialState: State = {
  prospectDossierStatus: Status.Idle,
  prospectDossier: null,
  carBrandCurrent: null,
};

const actions = {
  setStatus: createAction<State['prospectDossierStatus']>(
    getActionName(NAME, 'SET_STATUS'),
  ),
  set: createAction<State['prospectDossier']>(getActionName(NAME, 'SET')),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setStatus, (state, action) => ({
    ...state,
    prospectDossierStatus: action.payload,
  }));
  builder.addCase(actions.set, (state, action) => {
    const [carInfo] = action.payload?.[ProspectDossierFields.carInfo] ?? [];

    return {
      ...state,
      prospectDossier: action.payload,
      carBrandCurrent: carInfo?.[ProspectDossierCarInfoFields.brandCurrent] ?? null,
    };
  });
});

const selectors = {
  getAll: createSelector(
    ({ prospectDossier }: ApplicationState) => prospectDossier,
    state => state,
  ),
  getStatus: createSelector(
    ({ prospectDossier }: ApplicationState) => prospectDossier,
    state => state.prospectDossierStatus,
  ),
  get: createSelector(
    ({ prospectDossier }: ApplicationState) => prospectDossier,
    state => state.prospectDossier,
  ),
  getCommonVariables: createSelector(
    ({ prospectDossier }: ApplicationState) => prospectDossier,
    state => ({
      carBrandCurrent: state.carBrandCurrent,
    }),
  ),
};

export type { State as ProspectDossierState };
export {
  actions as prospectDossierActions,
  reducer as prospectDossierReducer,
  selectors as prospectDossierSelectors,
};
