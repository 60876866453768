import React, { forwardRef } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import VendorImageGallery from 'react-image-gallery';
import { theme } from 'common/theme';
import { NoImage } from 'components/NoImage';
import 'react-image-gallery/styles/css/image-gallery.css';

type VendorProps = React.ComponentProps<typeof VendorImageGallery>;

type Props = VendorProps & {
  /* analog of aspect ratio for preview image */
  previewRatio?: string;
  /* whether to hide text for preview image */
  previewOnlyImage?: boolean;
  /* height in px of preview when in no-image state (i.e. when original image was broken) */
  previewNoImageHeight?: number;
  /* analog of aspect ratio for thumnail(s) image */
  thumbnailRatio?: string;
  /* whether to hide text for thumbnail(s) */
  thumbnailOnlyImage?: boolean;
  /* height in px of thumbnail(s) when in no-image state (i.e. when original image was broken) */
  thumbnailNoImageHeight?: number;
  /* whether thumbnail(s) in no-image state are transparent */
  thumbnailNoImageTransparent?: boolean;
};

const ImageGallery = forwardRef<VendorImageGallery, Props>(
  (
    {
      previewRatio,
      thumbnailRatio,
      previewOnlyImage = false,
      thumbnailOnlyImage = false,
      thumbnailNoImageHeight,
      previewNoImageHeight,
      thumbnailNoImageTransparent = false,
      onImageError,
      onThumbnailError,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <VendorImageGallery
        ref={ref}
        onImageError={event => {
          onImageError?.(event);

          if (!event.currentTarget.parentElement) return;

          const root = createRoot(event.currentTarget.parentElement);
          root.render(
            <ThemeProvider theme={theme}>
              <NoImage
                text={t('EXTERNAL_IMAGE_NOT_AVAILABLE')}
                height={previewNoImageHeight}
                ratio={previewRatio}
                onlyImage={previewOnlyImage}
              />
            </ThemeProvider>,
          );
        }}
        onThumbnailError={event => {
          onThumbnailError?.(event);

          if (!event.currentTarget.parentElement) return;

          const root = createRoot(event.currentTarget.parentElement);
          root.render(
            <ThemeProvider theme={theme}>
              <NoImage
                text={t('EXTERNAL_IMAGE_NOT_AVAILABLE')}
                height={thumbnailNoImageHeight}
                onlyImage={thumbnailOnlyImage}
                ratio={thumbnailRatio}
                transparent={thumbnailNoImageTransparent}
              />
            </ThemeProvider>,
          );
        }}
        {...rest}
      />
    );
  },
);

export default ImageGallery;
