import { STORAGE_KEYS } from 'common/constants';
import { UrlTransform } from './urlTransform';
import * as Sentry from '@sentry/react';
import { StreamingEventType } from 'context/streaming/types';

type InitArgs = {
  onmessage?: (message) => void;
  host?: string | undefined;
  token?: string | undefined | null;
  customerId?: string;
};

type Result = {
  init: (initArgs?: InitArgs) => Promise<WebSocket>;
  send: (message) => void;
  get: () => WebSocket | undefined;
  close: () => void;
  isReady: () => boolean;
};

const isReady = (connection: WebSocket | undefined) => connection?.readyState === 1;

const createConnection = (): Result => {
  let connection: WebSocket | undefined;

  return {
    init({ onmessage, host, token, customerId } = {}) {
      return new Promise((resolve, reject) => {
        // needed only in dev mode for hot-reload server
        // in order to avoid memory leak
        if (window._streaming_ws_connection && isReady(window._streaming_ws_connection)) {
          window._streaming_ws_connection.close();
        }

        if (isReady(connection)) return;

        if (connection && !isReady(connection)) {
          this.close();
        }

        const apiDomain = process.env.REACT_APP_STREAMING_SERVER_WS_ENDPOINT;

        if (!host || !apiDomain || !token || !customerId) {
          throw new Error('One or more required parameters missing.');
        }

        const connectionId = localStorage.getItem(STORAGE_KEYS.connectionId) ?? undefined;

        const url = UrlTransform.stringifyUrl({
          url: apiDomain,
          query: { host, token, connection_id: connectionId },
        });

        connection = new WebSocket(url);

        if (process.env.REACT_APP_IS_LOCAL === 'true') {
          window._streaming_ws_connection = connection;
        }

        if (connection && onmessage) {
          connection.onmessage = onmessage.bind(connection);
        }

        connection.onopen = () => {
          this.send({
            type: StreamingEventType.INITIALIZE,
            host,
            data: {
              host,
              customerId,
            },
          });

          resolve(connection as WebSocket);
        };

        connection.onerror = event => {
          Sentry.withScope(scope => {
            scope.setTag('ws', 'error');
            Sentry.captureException(event);
          });
        };
      });
    },
    send(message) {
      connection?.send(JSON.stringify(message));
    },
    get() {
      return connection;
    },
    close() {
      if (connection?.readyState === 0 || connection?.readyState === 1) {
        connection.close();
        connection = undefined;
      }
    },
    isReady() {
      return isReady(connection);
    },
  };
};

export default createConnection;
