import {
  Model,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/model';
import { getPackageLinesGroupedByName } from './getPackageLinesGroupedByName';

/**
 * Check whether package has package lines to select
 * @param model {Model}
 * @param packageRecord {Model_CommonItem}
 * @returns {boolean}
 */
export const isPackageHasSelection = (
  model: Model,
  packageRecord: Model_CommonItem,
): boolean => {
  const groupedPackageLines = getPackageLinesGroupedByName(model, packageRecord);

  if (!groupedPackageLines) return false;

  return Object.values(groupedPackageLines).some(arr => {
    return (
      arr.length > 1 && !arr.every(record => record[Model_CommonItemFields.selected])
    );
  });
};
