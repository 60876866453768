import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ScDisabledIcon, ScEnabledIcon } from './StandardFeatures.styles';
import { DEFAULT_SFV, URL_QUERY_PARAMS } from 'common/constants';
import { useQuery } from 'context/router/UrlQueryProvider';

interface StandardFeaturesProps {
  onChange?(isEnabled: boolean): void;
  t: TFunction;
  tooltipPlacement?: TooltipPlacement;
  tooltipTriggers?: string[];
}

export const StandardFeatures: FC<StandardFeaturesProps> = ({
  onChange,
  t,
  tooltipPlacement = 'bottomLeft',
  tooltipTriggers = ['hover'],
}) => {
  const { query, queryValues } = useQuery();

  const [isEnabled, setIsEnabled] = useState<boolean>(DEFAULT_SFV);

  useEffect(() => {
    // check for the presense of key in the URL query
    if (query[URL_QUERY_PARAMS.standardFeaturesValue]) {
      setIsEnabled(queryValues.standardFeaturesValue);
    }
  }, [queryValues.standardFeaturesValue, query]);

  const handleSwitch = useCallback(() => {
    setIsEnabled(prevState => {
      onChange?.(!prevState);
      return !prevState;
    });
  }, [onChange]);

  const tooltipTitle = useMemo<string>(() => {
    return t(
      isEnabled
        ? 'STANDARD_FEATURES_HIDE_TOOLTIP_TITLE'
        : 'STANDARD_FEATURES_SHOW_TOOLTIP_TITLE',
    );
  }, [t, isEnabled]);

  const Icon = isEnabled ? ScEnabledIcon : ScDisabledIcon;
  const testId = `standard-features-${isEnabled ? 'enable' : 'disable'}`;

  return (
    <Tooltip placement={tooltipPlacement} title={tooltipTitle} trigger={tooltipTriggers}>
      <Icon onClick={handleSwitch} data-testid={testId} />
    </Tooltip>
  );
};
