import { useReducer, Dispatch } from 'react';
import { reducer } from './reducer';
import { DocumentsTreeData, DocumentTreeDataAction } from './types';

export { DocumentTreeDataActionType } from './types';

interface Result {
  documentsTreeState: DocumentsTreeData;
  documentsTreeDispatch: Dispatch<DocumentTreeDataAction>;
}

export const useDocumentsTree = (): Result => {
  const [state, dispatch] = useReducer(reducer, []);

  return {
    documentsTreeState: state,
    documentsTreeDispatch: dispatch,
  };
};
