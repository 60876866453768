import { ContactFields, ContactItem, Filter, ContactRole } from 'types/vendor';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { RequestResponseDetails } from 'types/common';
import { useDispatch } from 'react-redux';
import { contactActions } from 'store';

type ContextValue = {
  status: Status;
  contacts: ContactItem[];
  getContactsList(filter?: Filter<ContactFields>): Promise<ContactItem[] | null>;
  saveNewContact(contact: ContactItem): Promise<ContactItem | null>;
  getContactById(id: number | string): Promise<ContactItem | null>;
  loadContactsList(filter?: Filter<ContactFields>): Promise<ContactItem[] | null>;
  updateContact(
    id: number | undefined,
    values?: ContactItem,
  ): Promise<RequestResponseDetails<ContactItem | null>>;
  getContactRoleList(): Promise<RequestResponseDetails<ContactRole[]>>;
};

export function Contact(): ContactItem {
  return Object.assign(
    {},
    {
      [ContactFields.relationType]: '',
      [ContactFields.relationId]: undefined,
      [ContactFields.name]: '',
      [ContactFields.name2]: '',
      [ContactFields.email]: '',
      [ContactFields.phone]: '',
      [ContactFields.phone2]: '',
      [ContactFields.mobilePhone]: '',
      [ContactFields.mobilePhone2]: '',
      [ContactFields.adress]: '',
      [ContactFields.adress2]: '',
      [ContactFields.country]: '',
      [ContactFields.zipcode]: '',
      [ContactFields.city]: '',
      [ContactFields.lang]: '',
      [ContactFields.note]: '',
      [ContactFields.title]: '',
    },
  );
}
const ContactContext = React.createContext<ContextValue | undefined>(undefined);

const ContactProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const dispatch = useDispatch();

  const [status, setStatus] = useState(Status.Idle);
  const [contacts, setContacts] = useState<ContextValue['contacts']>([]);

  const saveNewContact = useCallback<ContextValue['saveNewContact']>(
    async (contact: ContactItem): Promise<ContactItem | null> => {
      let newContact: ContactItem | null = null;
      try {
        setStatus(Status.Loading);
        const requestParams = {
          ...contact,
          // todo: find out what is K for and move to lib
          [ContactFields.relationType]: 'K',
        };
        for (const key in requestParams) {
          if (!requestParams[key]) {
            delete requestParams[key];
          }
        }

        const { messages, record } = await xdmsClient.contacts.create(requestParams);

        notification.open({ message: messages });

        newContact = record as ContactItem;

        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return newContact;
    },
    [xdmsClient],
  );

  const loadContactsList = useCallback<ContextValue['loadContactsList']>(
    async (filter): Promise<ContactItem[]> => {
      let list: ContactItem[] = [];

      try {
        list = await xdmsClient.contacts.getList(filter);
      } catch (e) {
        notification.requestError(e);
      }

      return list;
    },
    [xdmsClient],
  );

  const getContactsList = useCallback(
    async (filter): Promise<ContactItem[]> => {
      let list: ContactItem[] = [];

      try {
        setStatus(Status.Loading);
        list = await xdmsClient.contacts.getList(filter);
        setContacts(list);
        setStatus(Status.Success);

        return list;
      } catch (e) {
        notification.requestError(e, setStatus);
      }

      return list;
    },
    [setStatus, setContacts, xdmsClient],
  );

  const getContactById = useCallback<ContextValue['getContactById']>(
    async (id: string | number): Promise<ContactItem | null> => {
      let contact: ContactItem | null = null;
      try {
        setStatus(Status.Loading);
        contact = await xdmsClient.contacts.getByIdByFields(Number(id));
        setStatus(Status.Success);
        return contact;
      } catch (e) {
        notification.requestError(e, setStatus);
      }
      return contact;
    },
    [xdmsClient, setStatus],
  );

  const updateContact = useCallback<ContextValue['updateContact']>(
    async (id: number, contact: ContactItem) => {
      const result: RequestResponseDetails<ContactItem | null> = {
        status: Status.Idle,
        messageHandled: false,
        response: null,
      };

      try {
        setStatus(Status.Loading);

        const { record, messages } = await xdmsClient.contacts.update(id, contact);

        if (messages.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        setStatus(Status.Success);

        result.response = record;
        result.status = Status.Success;
      } catch (e) {
        notification.requestError(e, setStatus);

        result.status = Status.Error;
        result.messageHandled = true;
      }

      return result;
    },
    [xdmsClient, setStatus],
  );

  const getContactRoleList = useCallback<ContextValue['getContactRoleList']>(async () => {
    let result: RequestResponseDetails<ContactRole[]> = {
      status: Status.Idle,
    };

    dispatch(contactActions.setContactRolesListStatus(Status.Loading));

    try {
      const response = await xdmsClient.contacts.getRolesList();

      dispatch(contactActions.setContactRolesListStatus(Status.Success));
      dispatch(contactActions.setContactRolesList(response));

      result = { status: Status.Success, response: response };
    } catch (e) {
      notification.requestError(e);
      dispatch(contactActions.setContactRolesListStatus(Status.Error));
      result = { status: Status.Error, messageHandled: true };
    }

    return result;
  }, [xdmsClient, dispatch]);

  const value = useMemo(
    () => ({
      status,
      contacts,
      saveNewContact,
      getContactById,
      getContactsList,
      loadContactsList,
      updateContact,
      getContactRoleList,
    }),
    [
      status,
      contacts,
      saveNewContact,
      getContactById,
      getContactsList,
      loadContactsList,
      updateContact,
      getContactRoleList,
    ],
  );

  return <ContactContext.Provider value={value} {...props} />;
};

const useContact = (): ContextValue => {
  const context = useContext(ContactContext);
  if (context === undefined) {
    throw new Error('useContact must be used within an ContactProvider');
  }
  return context;
};

export { useContact, ContactProvider };
