import { gql } from '@apollo/client';

export const CAR_BY_TOKEN = gql`
  query CarByToken($token: String!, $marketOrLocale: String!) {
    carByToken(token: $token) {
      visualizations(marketOrLocale: $marketOrLocale) {
        views {
          exterior {
            studio {
              left {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              right {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              threeQuartersFrontLeft {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              threeQuartersFrontRight {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              threeQuartersRearRight {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              threeQuartersRearLeft {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
            }
            details {
              wheel {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
            }
          }
          interior {
            studio {
              side {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              driversView {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
              rear {
                sizes {
                  large {
                    url
                    transparentUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
