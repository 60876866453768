import React, { FC, useCallback, useEffect, useState } from 'react';
import { goToUrl } from 'utils/goToUrl';
import { notification, NotificationType } from 'utils/notification';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Status } from 'utils/types';
import {
  DocumentItem,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
} from 'context/document/types';
import { useTranslation } from 'react-i18next';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import DragList from '../../drag/DragList';
import DragListItem from '../../drag/DragListItem';
import DocumentsListItem from './DocumentsListItem';
import DocumentListFooter from './DocumentListFooter';

interface Props {
  documents: DocumentItem[];
  relatedEntity: DocumentRelatedEntity;
  relatedEntityCode: DocumentRelatedEntityCode;
  reloadDocuments?(): void;

  isLoaderVisible?: boolean;

  isCaretVisible?: boolean;

  onCheck?(record: DocumentItem): void;
  isCheckDisabled?: boolean;
  isCheckVisible?: boolean;

  isAddLinkVisible?: boolean;
  isAddFileVisible?: boolean;

  readonly?: boolean;

  selectedIds?: (string | number)[];

  isAddDisabled?: boolean;

  testidSuffix?: string;
}

export const DocumentsList: FC<Props> = ({
  documents,
  relatedEntity,
  relatedEntityCode,
  reloadDocuments,
  isLoaderVisible,
  isCaretVisible,
  onCheck,
  isCheckDisabled,
  isCheckVisible,
  isAddFileVisible,
  isAddLinkVisible,
  readonly,
  selectedIds,
  isAddDisabled,
  testidSuffix,
}) => {
  const { t } = useTranslation();

  const { deleteDocument, reorderDocuments } = useDocumentsApi();

  // this state exists only in sake of UI reason - to prevent just dragged items to jump back
  const [documentsLocal, setDocumentsLocal] = useState<DocumentItem[]>(documents);

  useEffect(() => setDocumentsLocal(documents ?? []), [documents]);

  const onDownload = useCallback(
    (document: DocumentItem) => {
      try {
        goToUrl(document.fileUrl);
        notification.open({
          message: t('DOCUMENT_DOWNLOAD_SUCCESS'),
          type: NotificationType.success,
        });
      } catch (e) {
        notification.open({
          message: t('DOCUMENT_DOWNLOAD_ERROR'),
          type: NotificationType.error,
        });
      }
    },
    [t],
  );

  const onDelete = useCallback(
    async (document: DocumentItem) => {
      const { messageHandled, status } = await deleteDocument(
        document,
        relatedEntityCode,
        relatedEntity,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('DOCUMENT_DELETE_SUCCESS'),
          [Status.Error]: t('DOCUMENT_DELETE_ERROR'),
        });
      }
      reloadDocuments?.();
    },
    [relatedEntityCode, relatedEntity, deleteDocument, t, reloadDocuments],
  );

  const onOpen = useCallback((document: DocumentItem) => {
    goToUrl(document.fileUrl);
  }, []);

  const onDragEnd = useCallback(
    async (startIdx: number, endIdx: number) => {
      const newDocuments = [...documentsLocal];

      // swap operation
      // https://stackoverflow.com/questions/872310/javascript-swap-array-elements
      [newDocuments[startIdx], newDocuments[endIdx]] = [
        newDocuments[endIdx],
        newDocuments[startIdx],
      ];

      setDocumentsLocal(newDocuments);

      const { messageHandled, status } = await reorderDocuments(
        newDocuments,
        relatedEntityCode,
        relatedEntity,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('DOCUMENT_REORDER_SUCCESS'),
          [Status.Error]: t('DOCUMENT_REORDER_ERROR'),
        });
      }

      reloadDocuments?.();
    },
    [
      documentsLocal,
      reorderDocuments,
      t,
      relatedEntity,
      relatedEntityCode,
      reloadDocuments,
    ],
  );

  const onCopy = useCallback(
    async (document: DocumentItem) => {
      const status = await copyToClipboard(document.fileUrl);

      notification.openByStatus(status, {
        [Status.Success]: t('COPY_TO_CLIPBOARD__SUCCESS'),
        [Status.Error]: t('COPY_TO_CLIPBOARD__ERROR'),
      });
    },
    [t],
  );

  return (
    <div>
      <DragList onChange={onDragEnd}>
        {documentsLocal.map((document, idx) => {
          return (
            <DragListItem
              key={document.id}
              idx={idx}
              id={document.id}
              disabledToDrag={!document.computed.isDraggable}
              disabledFromDrag={!document.computed.isDraggable}
            >
              <DocumentsListItem
                document={document}
                relatedEntity={relatedEntity}
                relatedEntityCode={relatedEntityCode}
                isLoaderVisible={Boolean(isLoaderVisible) && !readonly}
                onCheck={() => onCheck?.(document)}
                isCheckSelected={Boolean(selectedIds?.includes(document.id))}
                isCheckVisible={Boolean(isCheckVisible) && !readonly}
                isCheckDisabled={Boolean(isCheckDisabled)}
                isCaretVisible={Boolean(isCaretVisible) && !readonly}
                onDownload={() => onDownload(document)}
                isDownloadVisible={document.computed.isDownloadVisible && !readonly}
                onCopy={() => onCopy(document)}
                isCopyVisible={document.computed.isCopyVisible && !readonly}
                onOpen={() => onOpen(document)}
                isOpenVisible={document.computed.isOpenVisible && !readonly}
                onDelete={() => onDelete(document)}
                isDeleteVisible={document.computed.isDeleteVisible && !readonly}
                reload={reloadDocuments}
              />
            </DragListItem>
          );
        })}
      </DragList>

      {(isAddFileVisible || isAddLinkVisible) && (
        <DocumentListFooter
          relatedEntity={relatedEntity}
          relatedEntityCode={relatedEntityCode}
          isAddLinkVisible={isAddLinkVisible}
          isAddFileVisible={isAddFileVisible}
          disabled={isAddDisabled}
          reload={reloadDocuments}
          testidSuffix={testidSuffix}
        />
      )}
    </div>
  );
};
