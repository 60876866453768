import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModelActionFields, ModelActionSignValues } from 'types/vendor';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { get } from 'utils';
import { BannerSettings } from '../types';
import { useTotalPage } from '../../TotalPageProvider';
import { ScActionItem } from './DealerAction.styles';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';

type SpecificSettings = {
  isNameEnabled?: boolean;
  isSignEnabled?: boolean;
  isCheckEnabled?: boolean;
  isAmountEnabled?: boolean;
  isPercentEnabled?: boolean;
  // @todo: may make it more generic to control discount and additional cost separately
  maxDiscountAmountLimit?: number;
  fieldsToHide?: string[];
};

type Props = BannerSettings<SpecificSettings>;

const TotalGenericDealerAction: FC<Props> = ({
  isNameEnabled,
  isSignEnabled,
  isCheckEnabled,
  isAmountEnabled,
  isPercentEnabled,
  maxDiscountAmountLimit,
  fieldsToHide,
}) => {
  const { t } = useTranslation();

  const { currency } = useCurrency();

  const {
    totalData,
    dealerAction,
    FIELDS,
    updateActionPercent,
    updateActionAmount,
    toggleActionIsSelected,
    toggleActionSign,
    updateActionName,
  } = useTotalPage();

  const { isConfigurationComplete } = useSelector(modelSelectors.getAll);

  const maxDiscountAmountValue = useMemo<number | undefined>(() => {
    if (!maxDiscountAmountLimit) return;
    if (dealerAction?.[ModelActionFields.sign] === ModelActionSignValues.positive)
      return maxDiscountAmountLimit;

    const value = get(totalData, FIELDS.dealerDiscount.totalWithoutDealerDelta, 0);
    return value + maxDiscountAmountLimit;
  }, [
    dealerAction,
    totalData,
    FIELDS.dealerDiscount.totalWithoutDealerDelta,
    maxDiscountAmountLimit,
  ]);

  if (!dealerAction) return null;

  const isDisabled =
    dealerAction[FIELDS.dealerAction.disabled] || isConfigurationComplete;

  return (
    <ScActionItem
      t={t}
      description={t('TOTAL_DEALER_ACTION')}
      record={dealerAction}
      updateAmount={updateActionAmount}
      updatePercent={updateActionPercent}
      updateName={updateActionName}
      toggleCheck={toggleActionIsSelected}
      toggleSign={toggleActionSign}
      FIELDS={FIELDS}
      currency={currency}
      maxDiscountAmountValue={maxDiscountAmountValue}
      disabled={{
        name: isDisabled || !isNameEnabled,
        sign: isDisabled || !isSignEnabled,
        check: isDisabled || !isCheckEnabled,
        amount: isDisabled || !isAmountEnabled,
        percent: isDisabled || !isPercentEnabled,
      }}
      fieldsToHide={fieldsToHide}
    />
  );
};

export default TotalGenericDealerAction;
