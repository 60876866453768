import React, { FC } from 'react';
import { TFunction } from 'i18next';
import { TotalsItem } from '@hypercharge/xdms-client/lib/types';
import { TotalPageAdaptedFields } from '../utils';
import { Price } from '../../../components/Price';
import { get } from '../../../utils';
import { TotalTableRow } from '../components/TotalTableRow';

interface Props {
  t: TFunction;
  totalData: TotalsItem | null;
  FIELDS: TotalPageAdaptedFields;
  hideZeroDiscounts: boolean;
}

const Subtotal: FC<Props> = ({ t, totalData, FIELDS, hideZeroDiscounts }) => {
  const discountAmount = get(totalData, FIELDS.subtotal.discountAmount, 0);
  const discountPercent = get(totalData, FIELDS.subtotal.discountPercent, 0);

  const showDiscountAmount = !hideZeroDiscounts || discountAmount;
  const showDiscountPercent = !hideZeroDiscounts || discountPercent;

  return (
    <TotalTableRow
      data-testid="total-page-before-tradeIn-subtotal"
      borderBottom="thin"
      textSize="large"
      fontWeight="bold"
      shadeNumbers
      fields={[
        t('TOTAL_HEADER_TOTAL_EX_DEALER', {
          dealer: '',
        }),
        undefined,
        undefined,
        undefined,
        <Price value={get(totalData, FIELDS.subtotal.brutto, 0)} />,
        <>{showDiscountAmount && <Price value={-discountAmount} />}</>,
        <>
          {showDiscountPercent && (
            <Price value={-discountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price value={get(totalData, FIELDS.subtotal.netto, 0)} />,
      ]}
    />
  );
};

export default Subtotal;
