import { ConfigurationRelation } from '../relations/types';
import {
  Configuration_Create_Input,
  Configuration_Update_Input,
} from '@hypercharge/xdms-client/lib/types';
import { ContactFields, CustomerFields } from 'types/vendor';

export const configurationUpdate_EncodeInputParams = (
  params: Omit<Configuration_Update_Input, 'relations'> & {
    relations: ConfigurationRelation[];
  },
): Configuration_Update_Input => {
  return {
    ...params,
    relations: encodeRelations(params.relations),
  };
};

export const configurationCreate_EncodeInputParams = (
  params: Omit<Configuration_Create_Input, 'relations'> & {
    relations: ConfigurationRelation[];
  },
): Configuration_Create_Input => {
  return {
    ...params,
    relations: encodeRelations(params.relations),
  };
};

const encodeRelations = (
  relations: ConfigurationRelation[],
): Configuration_Update_Input['relations'] => {
  return relations
    .filter(({ customer }) => Boolean(customer))
    .map(relation => {
      const contactNumber = relation.primaryContact?.[ContactFields.ID];
      return {
        customerNumber: String(relation.customer?.[CustomerFields.id]),
        contactNumber: contactNumber ? String(contactNumber) : undefined,
        additionalContactsNumbers: relation.additionalContacts.map(c =>
          String(c[ContactFields.ID]),
        ),
      };
    });
};
