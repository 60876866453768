import styled, { css } from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

export const styles = css<{ $hidden: boolean }>`
  transition: opacity 0.3s;
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
  cursor: pointer;
`;

export const ScArrowLeftIcon = styled(ArrowLeftIcon)<{ $hidden: boolean }>`
  ${styles}
`;

export const ScArrowRightIcon = styled(ArrowRightIcon)<{ $hidden: boolean }>`
  ${styles}
`;
