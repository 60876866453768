import styled from 'styled-components';
import { ReactComponent as CustomerIcon } from 'assets/icons/customer.svg';

export const LogoutIcon = styled(CustomerIcon)`
  width: 1em;
  height: 1em;

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;
