import {
  Model,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/model';
import { getPackageLines } from './get-package-lines';
import { groupBy } from 'utils';

/**
 * Get object with package's line grouped by group name, or package line name
 * @param model {Model} Model
 * @param packageRecord {Model_CommonItem} Desired package record
 * @returns {Record<string, Model_CommonItem[]> | null}
 */
export const getPackageLinesGroupedByName = (
  model: Model,
  packageRecord: Model_CommonItem,
): Record<string, Model_CommonItem[]> | null => {
  const linesRecords = getPackageLines({ model: model, packageRecord: packageRecord });

  return groupBy(
    linesRecords,
    packageLine =>
      packageLine[Model_CommonItemFields.packageGroup] ||
      packageLine[Model_CommonItemFields.name],
  );
};
