import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import usePreviewImages from 'layout/Default/header/recordsPreviewer/usePreviewImages';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'store';
import { PreviewImage } from 'types';

type Params = {
  enabled?: boolean;
};

export default function useStreamingInstalledRecords({
  enabled = true,
}: Params = {}): Result {
  const isStreamBroken = useSelector(streamingSelectors.getIsStreamBroken);

  const { data } = usePreviewImages({
    isSelected: true,
  });

  const { sendMessage } = useStreaming();

  const displayData = useMemo<PreviewImage[]>(() => {
    if (!enabled || isStreamBroken) return [];

    return data;
  }, [data, isStreamBroken, enabled]);

  useEffect(() => {
    if (!enabled) return;

    sendMessage({
      type: StreamingEventType.EMIT_SLOT_CHANGE,
      data: {
        name: 'global',
        data: {
          isAllRecordsPreview: true,
          isAllSelectedRecordsPreview: true,
          isAllActiveRecordsPreview: true,
          carPreviewImageIndex: null,
        },
      },
    });
  }, [enabled, sendMessage]);

  return { displayData: displayData };
}

type Result = {
  displayData: PreviewImage[];
};
