import {
  Message,
  MessageCodeValues,
  MessageFields,
  TradeInListItem,
  TradeIn_SendEmail_Input,
  TradeInListItemFields,
  TradeInVatTypeListItem,
  TradeInVatTypeListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { TRADE_IN_URL, VAT } from 'common/constants';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { RequestResponseDetails } from 'types/common';
import { checkWhetherRecordContainsString } from 'utils/check-whether-record-contains-string';
import { cleanEntity } from 'utils/clean-entity';
import { Money } from 'utils/money';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { TFunction } from 'i18next';
import { DocumentTemplate } from 'types/vendor';

const TradeInContext = React.createContext<TradeInProviderValues | undefined>(undefined);

export type TradeIn = Pick<
  TradeInListItem,
  | TradeInListItemFields.stockNumber
  | TradeInListItemFields.actualValue
  | TradeInListItemFields.brandName
  | TradeInListItemFields.modelName
  | TradeInListItemFields.machineNumber
  | TradeInListItemFields.description
  | TradeInListItemFields.constructedAtYear
  | TradeInListItemFields.vat
  | TradeInListItemFields.workingHours
  | TradeInListItemFields.purchaseValue
  | TradeInListItemFields.attachments
  | TradeInListItemFields.hasDocuments
  | TradeInListItemFields.hasPhotos
  | TradeInListItemFields.documentsRelationType
  | TradeInListItemFields.documentsRelationCode
  | TradeInListItemFields.isVatEnabled
>;

export type SendEmailTradeInParams = Omit<
  TradeIn_SendEmail_Input,
  'configurationNumber' | 'documentIds'
> & {
  documentIds: string[] | number[];
};

export enum TradeInComponent {
  Table = 'tradeInTable',
  Form = 'tradeInForm',
}

/**
 * Fields present in UI form
 */
export interface TradeInBaseFields
  extends Omit<
    TradeIn,
    | TradeInListItemFields.stockNumber
    | TradeInListItemFields.hasDocuments
    | TradeInListItemFields.hasPhotos
    | TradeInListItemFields.workingHours
    | TradeInListItemFields.purchaseValue
    | TradeInListItemFields.actualValue
    | TradeInListItemFields.documentsRelationType
    | TradeInListItemFields.documentsRelationCode
  > {
  workingHours: number | null;
  purchaseValue: number;
  actualValue: number;
}

export type TradeInVatType = Pick<
  TradeInVatTypeListItem,
  TradeInVatTypeListItemFields.name | TradeInVatTypeListItemFields.id
>;

/**
 * Form fields + any additional fields required for such actions
 * As create / update / etc
 */
export interface TradeInFormFields extends TradeInBaseFields {
  printTemplate: DocumentTemplate;
}

type WithVariance<T> = T & { variance: number };

const FIELDS_LIST = [
  TradeInListItemFields.stockNumber,
  TradeInListItemFields.machineNumber,
  TradeInListItemFields.brandName,
  TradeInListItemFields.modelName,
  TradeInListItemFields.actualValue,
  TradeInListItemFields.purchaseValue,
  TradeInListItemFields.workingHours,
  TradeInListItemFields.description,
  TradeInListItemFields.constructedAtYear,
  TradeInListItemFields.attachments,
  TradeInListItemFields.hasDocuments,
  TradeInListItemFields.hasPhotos,
  TradeInListItemFields.documentsRelationType,
  TradeInListItemFields.documentsRelationCode,
  TradeInListItemFields.isVatEnabled,
  TradeInListItemFields.vat,
];

export interface TradeInProviderValues {
  isLoading: boolean;
  component: string;
  isCreate: boolean;
  searchValue: string;
  tradeIns: WithVariance<TradeIn>[];
  vatTypes: TradeInVatType[];
  totalCount: number;
  handleSearch(value: string): void;
  loadTradeIns(): Promise<void>;
  createTradeIn(fields: TradeInFormFields): Promise<RequestResponseDetails>;
  deleteTradeIn(machineNumber: string | undefined): Promise<RequestResponseDetails>;
  updateTradeIn(fields: TradeInFormFields): Promise<RequestResponseDetails>;
  editTradeIn(machineNumber: string): void;
  sendEmailTradeIn(params: SendEmailTradeInParams): Promise<RequestResponseDetails>;
  setTradeInFormComponent(isNewTradeIn?: boolean): void;
  setTradeInTableComponent(): void;
  calculateVariance(actualValue: number | null, purchaseValue: number | null): number;
  selectedTradeIn: TradeInBaseFields | undefined;
  status: Status;
  getInitialFields(t: TFunction): TradeInBaseFields;
  getTradeInByMachineNumber(machineNumber: string): WithVariance<TradeIn> | undefined;
  getVatTypesList(): Promise<void>;
}

const TradeInProvider: FC<PropsWithChildren<{ value?: TradeInProviderValues }>> =
  props => {
    const { xdmsClient } = useXdmsClient();
    const { pathname } = useLocation();

    const { configurationNumber } = useSelector(
      configurationSelectors.getConfigurationCommonVariables,
    );

    const [component, setComponent] = useState<string>(TradeInComponent.Table);
    const [tradeIns, setTradeIns] = useState<WithVariance<TradeIn>[]>([]);
    const [vatTypes, setVatTypes] = useState<TradeInVatType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [isCreate, setIsCreate] = useState<boolean>(true);
    const currentMachineNumber = useRef<string | undefined>('');
    const [selectedTradeIn, setSelectedTradeIn] = useState<
      TradeInBaseFields | undefined
    >();
    const currentOfferTradeInsUploaded = useRef<boolean>(false);
    const [status, setStatus] = useState<Status>(Status.Idle);

    /** Just a local adaptation for `configurationNumber`;
     * @description According to BE logic: `stockNumber` for `tradeIn` is just `configurationNumber` with '-' prepended
     */
    const stockNumber = useMemo<string>(() => {
      return configurationNumber ? `-${configurationNumber}` : '';
    }, [configurationNumber]);

    const calculateVariance = useCallback(
      (actualValue: number | null, purchaseValue: number | null): number => {
        return new Money(actualValue || 0).subtract(purchaseValue || 0).value;
      },
      [],
    );

    const getInitialFields = useCallback((t: TFunction): TradeInBaseFields => {
      return {
        actualValue: 0,
        purchaseValue: 0,
        brandName: '',
        modelName: '',
        machineNumber: '',
        workingHours: null,
        description: t('TRADE_IN_FORM_DEFAULT_DESCRIPTION'),
        constructedAtYear: 0,
        attachments: '',
        isVatEnabled: false,
        vat: VAT,
      };
    }, []);

    const withVariance = useCallback(
      (tradeIn: TradeIn): WithVariance<TradeIn> => {
        console.log(tradeIn);

        return {
          ...tradeIn,
          variance: calculateVariance(tradeIn.actualValue, tradeIn.purchaseValue),
        };
      },
      [calculateVariance],
    );

    const getTradeInByMachineNumber = useCallback(
      (machineNumber: string): WithVariance<TradeIn> | undefined => {
        const currentTradeIn = tradeIns.find(
          tradeIn => tradeIn.machineNumber === machineNumber,
        );
        return currentTradeIn;
      },
      [tradeIns],
    );

    const setTradeInTableComponent = useCallback(() => {
      setComponent(TradeInComponent.Table);
      currentMachineNumber.current = '';
    }, [setComponent]);

    const setTradeInFormComponent = useCallback(
      (isNewTradeIn = true) => {
        if (isNewTradeIn) {
          setIsCreate(true);
        } else {
          setIsCreate(false);
        }
        setComponent(TradeInComponent.Form);
      },
      [setComponent, setIsCreate],
    );

    const loadTradeIns = useCallback<TradeInProviderValues['loadTradeIns']>(async () => {
      if (stockNumber) {
        try {
          setStatus(Status.Loading);
          const response: TradeInListItem[] = await xdmsClient.tradeIn.getList([
            { name: TradeInListItemFields.stockNumber, value: stockNumber },
          ]);
          const cleanedTradeIns: TradeIn[] = response.map(tradeIn =>
            cleanEntity<TradeInListItem, TradeIn>(tradeIn, FIELDS_LIST),
          );
          setTradeIns(cleanedTradeIns.map(withVariance));
          currentOfferTradeInsUploaded.current = true;
          setStatus(Status.Success);
        } catch (e) {
          notification.requestError(e, setStatus);
        }
      }
    }, [stockNumber, xdmsClient, withVariance]);

    const createTradeIn = useCallback<TradeInProviderValues['createTradeIn']>(
      async (fields: TradeInFormFields): Promise<RequestResponseDetails> => {
        let result: RequestResponseDetails = { status: Status.Idle };

        try {
          setStatus(Status.Loading);
          const variance = calculateVariance(fields.actualValue, fields.purchaseValue);
          const messages = await xdmsClient.tradeIn.create(configurationNumber, {
            modelName: fields.modelName,
            actualValue: fields.actualValue,
            brandName: fields.brandName,
            attachments: fields.attachments,
            machineNumber: fields.machineNumber,
            description: fields.description,
            constructedAtYear: fields.constructedAtYear,
            printTemplate: fields.printTemplate,
            purchaseValue: fields.purchaseValue,
            workingHours: fields.workingHours,
            isVatEnabled: fields.isVatEnabled,
            variance,
            vat: +fields.vat,
          });
          if (messages.length) {
            notification.open({ message: messages });
            result = { status: Status.Success, messageHandled: true };
          } else {
            result = { status: Status.Success, messageHandled: false };
          }
          setStatus(Status.Success);
        } catch (e) {
          notification.requestError(e, setStatus);
          result = { status: Status.Error, messageHandled: true };
        }

        return result;
      },
      [calculateVariance, xdmsClient, configurationNumber],
    );

    const deleteTradeIn = useCallback<TradeInProviderValues['deleteTradeIn']>(
      async (machineNumber: string | undefined): Promise<RequestResponseDetails> => {
        let result: RequestResponseDetails = { status: Status.Idle };

        let messages: Message[] = [];
        try {
          setStatus(Status.Loading);

          messages = await xdmsClient.tradeIn.delete({
            stockNumber,
            machineNumber,
          });

          setStatus(Status.Success);

          if (messages.length) {
            notification.open({ message: messages });
            result = { status: Status.Success, messageHandled: true };
          } else {
            result = { status: Status.Success, messageHandled: false };
          }
        } catch (e) {
          notification.requestError(e, setStatus);
          result = { status: Status.Error, messageHandled: true };
        }

        if (messages.length) {
          const trades = tradeIns.filter(({ machineNumber: mN }) => mN !== machineNumber);
          setTradeIns(trades.map(withVariance));
          setStatus(Status.Idle);
        }

        return result;
      },
      [xdmsClient, stockNumber, tradeIns, withVariance],
    );

    const editTradeIn = useCallback(
      (machineNumber: string) => {
        const chosenTradeInOffer: TradeIn | undefined = tradeIns.find(
          ({ machineNumber: mN }) => mN === machineNumber,
        );

        if (chosenTradeInOffer) {
          setTradeInFormComponent(false);
          setSelectedTradeIn({
            actualValue: chosenTradeInOffer.actualValue,
            purchaseValue: chosenTradeInOffer.purchaseValue,
            attachments: chosenTradeInOffer.attachments,
            brandName: chosenTradeInOffer.brandName,
            description: chosenTradeInOffer.description,
            machineNumber: chosenTradeInOffer.machineNumber,
            modelName: chosenTradeInOffer.modelName,
            workingHours: chosenTradeInOffer.workingHours,
            constructedAtYear: chosenTradeInOffer.constructedAtYear,
            isVatEnabled: chosenTradeInOffer.isVatEnabled,
            vat: chosenTradeInOffer.vat,
          });

          currentMachineNumber.current = machineNumber;
        }
      },
      [setTradeInFormComponent, tradeIns, setSelectedTradeIn],
    );

    const sendEmailTradeIn = useCallback<TradeInProviderValues['sendEmailTradeIn']>(
      async (params: SendEmailTradeInParams): Promise<RequestResponseDetails> => {
        let result: RequestResponseDetails = { status: Status.Loading };

        try {
          setStatus(Status.Loading);
          const { message }: { message: Message[] } = await xdmsClient.tradeIn.sendEmail({
            ...params,
            configurationNumber: configurationNumber,
          });

          setStatus(Status.Success);

          if (message.length) {
            notification.open({ message: message });
            result = { status: Status.Success, messageHandled: true };
          } else {
            result = { status: Status.Success, messageHandled: false };
          }
        } catch (e) {
          notification.requestError(e, setStatus);

          result = { status: Status.Error, messageHandled: true };
        }

        return result;
      },
      [xdmsClient, configurationNumber],
    );

    const updateTradeIn = useCallback<TradeInProviderValues['updateTradeIn']>(
      async (fields: TradeInFormFields) => {
        let result: RequestResponseDetails = { status: Status.Loading };

        const variance = calculateVariance(fields.actualValue, fields.purchaseValue);

        let messages: Message[] | undefined;
        let hasError = false;
        try {
          setStatus(Status.Loading);
          messages = await xdmsClient.tradeIn.update(stockNumber, {
            workingHours: fields.workingHours,
            purchaseValue: fields.purchaseValue,
            actualValue: fields.actualValue,
            modelName: fields.modelName,
            brandName: fields.brandName,
            attachments: fields.attachments,
            isVatEnabled: fields.isVatEnabled,
            description: fields.description,
            constructedAtYear: fields.constructedAtYear,
            printTemplate: fields.printTemplate,
            variance,
            vat: +fields.vat,
            machineNumber: currentMachineNumber.current,
            newMachineNumber: fields.machineNumber,
          });

          if (messages?.length) {
            notification.open({ message: messages });
            hasError = messages.some(
              message => message[MessageFields.code] === MessageCodeValues.error,
            );
            const status = hasError ? Status.Error : Status.Success;
            result = { status, messageHandled: true };
          } else {
            result = { status: Status.Success, messageHandled: false };
          }

          if (!hasError) {
            const { printTemplate, ...selectedTradeInFields } = fields;
            setSelectedTradeIn(selectedTradeInFields);
          }
        } catch (e) {
          notification.requestError(e);

          result = { status: Status.Error, messageHandled: true };
        }

        if (messages && !hasError) {
          currentMachineNumber.current = fields.machineNumber;
          setStatus(Status.Idle);
        }

        return result;
      },
      [calculateVariance, xdmsClient, stockNumber],
    );

    const getVatTypesList = useCallback<
      TradeInProviderValues['getVatTypesList']
    >(async () => {
      try {
        setStatus(Status.Loading);
        const response: TradeInVatTypeListItem[] =
          await xdmsClient.tradeIn.getVatTypesList();

        const vatTypes: TradeInVatType[] = response.map(vat =>
          cleanEntity<TradeInVatTypeListItem, TradeInVatType>(vat, [
            TradeInVatTypeListItemFields.id,
            TradeInVatTypeListItemFields.name,
            TradeInVatTypeListItemFields.percent,
          ]),
        );

        setVatTypes(vatTypes);
        setStatus(Status.Success);
      } catch (e) {
        notification.requestError(e, setStatus);
      }
    }, [xdmsClient]);

    const filterTradeIns = (
      tradeIns: WithVariance<TradeIn>[],
      filterValue: string,
    ): WithVariance<TradeIn>[] => {
      return tradeIns.reduce<WithVariance<TradeIn>[]>((result, tradeIn, index) => {
        if (checkWhetherRecordContainsString(tradeIn, filterValue)) {
          return [...result, tradeIns[index]];
        }

        return result;
      }, []);
    };

    useEffect(() => {
      if (searchValue && pathname !== TRADE_IN_URL) {
        setSearchValue('');
      }
    }, [setSearchValue, pathname, searchValue]);

    useEffect(() => {
      if (configurationNumber && pathname === TRADE_IN_URL) loadTradeIns();
    }, [configurationNumber, pathname, loadTradeIns]);

    const data = useMemo(() => {
      return searchValue ? filterTradeIns(tradeIns, searchValue) : tradeIns;
    }, [searchValue, tradeIns]);

    useEffect(() => {
      setTotalCount(data.length);
    }, [data]);

    const handleSearch = useCallback<TradeInProviderValues['handleSearch']>(
      value => {
        setSearchValue(value);
      },
      [setSearchValue],
    );

    useEffect(() => {
      if (!configurationNumber && component !== TradeInComponent.Table) {
        setTradeInTableComponent();
      }
    }, [configurationNumber, component, setTradeInTableComponent]);

    const value = useMemo(
      () => ({
        isLoading: status === Status.Loading,
        component,
        isCreate,
        searchValue,
        tradeIns: data,
        totalCount,
        handleSearch,
        loadTradeIns,
        createTradeIn,
        deleteTradeIn,
        editTradeIn,
        sendEmailTradeIn,
        updateTradeIn,
        setTradeInFormComponent,
        setTradeInTableComponent,
        calculateVariance,
        selectedTradeIn,
        status,
        getInitialFields,
        getTradeInByMachineNumber,
        getVatTypesList,
        vatTypes,
      }),
      [
        status,
        component,
        isCreate,
        searchValue,
        data,
        totalCount,
        handleSearch,
        loadTradeIns,
        createTradeIn,
        deleteTradeIn,
        editTradeIn,
        sendEmailTradeIn,
        updateTradeIn,
        setTradeInFormComponent,
        setTradeInTableComponent,
        calculateVariance,
        selectedTradeIn,
        getInitialFields,
        getTradeInByMachineNumber,
        getVatTypesList,
        vatTypes,
      ],
    );

    return <TradeInContext.Provider value={value} {...props} />;
  };

const useTradeIn = (): TradeInProviderValues => {
  const context = useContext(TradeInContext);

  if (context === undefined) {
    throw new Error('useTradeIn must be used within an TradeInProvider');
  }

  return context;
};

export { TradeInProvider, useTradeIn };
