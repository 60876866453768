import styled from 'styled-components';

export const ScCurrentContainer = styled.div`
  color: ${({ theme }) => theme.colors.vampire};
  height: 3.125em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(40, 78, 128, 0.09);
  border-radius: 3px;
`;
