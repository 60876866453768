import { HostTheme, HostSettingsExternal } from 'common/hostsSettings';
import Color from 'color';
import { get } from 'utils';
import {
  DEFAULT_CUSTOMER_ID,
  STORAGE_KEYS,
  PROXY_DEFAULT_CUSTOMER_IDS_LIST,
} from 'common/constants';

export const getHostThemeFromExternalSettings = (
  theme: HostSettingsExternal,
): HostTheme => {
  return {
    textColor: Color(theme.textColor).hex(),

    highlightColor: Color(theme.highlightColor).hex(),
    highlightColor_contrast: Color(theme.highlightColor_contrast).hex(),
    highlightColor_hovered: Color(theme.highlightColor).fade(0.1).hsl().string(),
    highlightColor_pressed: Color(theme.highlightColor).fade(0.3).hsl().string(),

    mainColor: Color(theme.primaryColor).hex(),
    mainColor_contrast: Color(theme.primaryColor_contrast).hex(),
    mainColor_hovered: Color(theme.primaryColor).fade(0.1).hsl().string(),
    mainColor_pressed: Color(theme.primaryColor).fade(0.3).hsl().string(),

    fontFamilyName: theme.fontFamilyName,
    fontFamilyLocation: theme.fontFamilyLocation,
    letterSpacing: theme.letterSpacing,
  };
};

/** Get customerId from pathname (Like 'volvo' from 'https://volvo.hyperconfig.net/' ),
 *  or localStorage, or use default. */
export const detectCustomerId = (): string => {
  const subDomain = get(window.location.host.split('.').reverse(), 2, null);

  const savedCustomerId = localStorage.getItem(STORAGE_KEYS.customerId);

  const resultList: string[] = [subDomain, savedCustomerId, DEFAULT_CUSTOMER_ID].filter(
    (v): v is string => Boolean(v),
  );

  let [result] = resultList;

  if (PROXY_DEFAULT_CUSTOMER_IDS_LIST.includes(result)) result = DEFAULT_CUSTOMER_ID;

  return result;
};

export const detectHostSettings = (): HostSettingsExternal | undefined => {
  const stored = localStorage.getItem(STORAGE_KEYS.hostSettingsExternal);
  if (!stored) return;
  return JSON.parse(stored);
};

export const detectHost = (): string | null => {
  return localStorage.getItem(STORAGE_KEYS.host);
};
