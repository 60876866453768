import { default as React, useCallback, useMemo } from 'react';
import { FixedType } from 'antd/dist/antd'; // antd has not this export, take it from dist instead
import { ColumnsType } from 'antd/es/table';
import { TradeIn } from 'context/tradeIn/TradeInProvider';
import { PaperClipOutlined } from '@ant-design/icons';
import ImageButton from 'components/table/ImageButton';
import { DotsIcon, TableEmphasizedGreenText } from './TradeInTable.styles';
import { formatPrice, longTextFormat } from 'utils/format';
import { ScDropdown, ScMenu, ScMenuItem } from 'components/table/styles';
import { TradeInListItemFields } from '@hypercharge/xdms-client/lib/types';
import { TFunction } from 'i18next';
import { ColumnType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import {
  GlobalFeaturesFlagsFields,
  GlobalFeaturesFlags,
} from 'common/globalFeaturesFlags';
import { FeatureFlagColumnSettings } from 'types/common';
import { ScIcon } from './use-columns.styles';
import { ScColumnTitle } from 'components/styles';

type MenuItem = {
  item: React.ReactNode;
  order: number;
};

type KeyToColumn = Record<
  string,
  (
    settings: Omit<
      GlobalFeaturesFlags[GlobalFeaturesFlagsFields.TradeInColumns][number],
      'name'
    >,
  ) => ColumnType<TradeIn>
>;

export const columnKeys = {
  BRAND_NAME: TradeInListItemFields.brandName,
  MODEL_NAME: TradeInListItemFields.modelName,
  MACHINE_NUMBER: TradeInListItemFields.machineNumber,
  DOCUMENTS: TradeInListItemFields.hasDocuments,
  PHOTOS: TradeInListItemFields.hasPhotos,
  WORKING_HOURS: TradeInListItemFields.workingHours,
  PURCHASE_VALUE: TradeInListItemFields.purchaseValue,
  STANDING_VALUE: TradeInListItemFields.actualValue,
  DESCRIPTION: TradeInListItemFields.description,
  ATTACHMENTS: TradeInListItemFields.attachments,
  VARIANCE: 'variance',
  ACTIONS: 'actions',
};

interface Params {
  isConfigurationComplete: boolean;
  handleDocumentModal(show: boolean, tradeIn?: TradeIn): void;
  handleViewImages(tradeIn?: TradeIn): void;
  editTradeIn(machineNumber: string): void;
  handleTradeInSendEmail(machineNumber: string): void;
  handleDeleteTradeIn(machineNumber: string | undefined): Promise<void>;
  currencyCode: string;
  language: string;
  t: TFunction;
}

export const useColumns = (params: Params): ColumnsType<TradeIn> => {
  const {
    isConfigurationComplete,
    handleDocumentModal,
    handleViewImages,
    editTradeIn,
    handleTradeInSendEmail,
    handleDeleteTradeIn,
    t,
    language,
    currencyCode,
  } = params;

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[GlobalFeaturesFlagsFields.TradeInColumns] as
      | FeatureFlagColumnSettings[]
      | undefined;

    return columns ?? [];
  }, [globalFeatures]);

  const getFormatPrice = useCallback(
    (price: number): string =>
      formatPrice({
        price,
        locale: language,
        currency: currencyCode,
      }),
    [currencyCode, language],
  );

  const getMenuItemViewTradeIn = useCallback(
    (machineNumber): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => editTradeIn(machineNumber)}
            data-testid="tradein-page-table-item-edit"
          >
            {t('TRADE_IN_VIEW')}
          </ScMenuItem>
        ),
        order: 0,
      };
    },
    [t, editTradeIn],
  );

  const getMenuItemSendEmail = useCallback(
    (machineNumber, record: TradeIn): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleTradeInSendEmail(record.machineNumber)}
            data-testid="tradein-page-table-item-send-email"
          >
            {t('TRADE_IN_SEND_EMAIL')}
          </ScMenuItem>
        ),
        order: 1,
      };
    },
    [t, handleTradeInSendEmail],
  );

  const getMenuItemDelete = useCallback(
    (machineNumber): MenuItem => {
      return {
        item: (
          <ScMenuItem
            onClick={() => handleDeleteTradeIn(machineNumber)}
            data-testid="tradein-page-table-item-delete"
            disabled={isConfigurationComplete}
          >
            {t('TRADE_IN_DELETE')}
          </ScMenuItem>
        ),
        order: 2,
      };
    },
    [t, handleDeleteTradeIn, isConfigurationComplete],
  );

  const flagToMenuItem: Record<string, (machineNumber, record: TradeIn) => MenuItem> =
    useMemo(() => {
      return {
        [GlobalFeaturesFlagsFields.allowTradeInView]: getMenuItemViewTradeIn,
        [GlobalFeaturesFlagsFields.allowTradeInSendEmail]: getMenuItemSendEmail,
        [GlobalFeaturesFlagsFields.allowTradeInDelete]: getMenuItemDelete,
      };
    }, [getMenuItemViewTradeIn, getMenuItemSendEmail, getMenuItemDelete]);

  const menu = useCallback(
    (machineNumber, record: TradeIn) => {
      const menuItems: MenuItem[] = [];

      // if no globalFeatures provided - should hide 'em all, or display all actions.
      if (!globalFeatures) return <></>;

      Object.keys(flagToMenuItem).forEach(settingKey => {
        if (!globalFeatures[settingKey]) return;
        const menuItem = flagToMenuItem[settingKey](machineNumber, record);
        menuItems.push(menuItem);
      });

      return <ScMenu>{menuItems.map(menuItem => menuItem.item)}</ScMenu>;
    },
    [globalFeatures, flagToMenuItem],
  );

  const keyToColumn: KeyToColumn = useMemo(() => {
    return {
      [columnKeys.BRAND_NAME]: () => ({
        title: t('TRADE_IN_BRAND'),
        key: columnKeys.BRAND_NAME,
        width: '6em',
        dataIndex: columnKeys.BRAND_NAME,
      }),
      [columnKeys.MODEL_NAME]: () => ({
        title: t('TRADE_IN_MODEL'),
        key: columnKeys.MODEL_NAME,
        width: '10em',
        dataIndex: columnKeys.MODEL_NAME,
      }),
      [columnKeys.MACHINE_NUMBER]: () => ({
        title: t('TRADE_IN_SERIAL'),
        key: columnKeys.MACHINE_NUMBER,
        width: '10em',
        dataIndex: columnKeys.MACHINE_NUMBER,
      }),
      [columnKeys.DOCUMENTS]: ({ icon }) => {
        const titleText = t('TRADE_IN_DOCUMENTS');

        let width = 0;
        if (titleText) width += 6;
        if (icon) width += 3;

        return {
          title: (
            <ScColumnTitle>
              <ScIcon type={icon} title={titleText} />
              {titleText}
            </ScColumnTitle>
          ),
          minWidth: `${width}em`,
          key: columnKeys.DOCUMENTS,
          dataIndex: columnKeys.DOCUMENTS,
          align: 'center' as const,
          render: (hasDocuments: boolean, record) => ({
            children: hasDocuments ? (
              <PaperClipOutlined
                style={{ fontSize: 24 }}
                onClick={() => handleDocumentModal(true, record)}
                disabled={Boolean(isConfigurationComplete)}
              />
            ) : (
              '-'
            ),
          }),
        };
      },
      [columnKeys.PHOTOS]: ({ icon }) => {
        const titleText = t('TRADE_IN_PHOTO');

        let width = 0;
        if (titleText) width += 5;
        if (icon) width += 3;

        return {
          title: (
            <ScColumnTitle>
              <ScIcon type={icon} title={titleText} />
              {titleText}
            </ScColumnTitle>
          ),
          minWidth: `${width}em`,
          key: columnKeys.PHOTOS,
          dataIndex: columnKeys.PHOTOS,
          align: 'center' as const,
          render: (hasPhotos: boolean, record) => ({
            children: hasPhotos ? (
              <ImageButton onClick={() => handleViewImages(record)} />
            ) : (
              '-'
            ),
          }),
        };
      },
      [columnKeys.WORKING_HOURS]: () => ({
        title: t('TRADE_IN_WORKING_HOURS'),
        width: '10em',
        key: columnKeys.WORKING_HOURS,
        dataIndex: columnKeys.WORKING_HOURS,
      }),
      [columnKeys.PURCHASE_VALUE]: () => ({
        title: t('TRADE_IN_PURCHASE_VALUE'),
        key: columnKeys.PURCHASE_VALUE,
        width: '15em',
        dataIndex: columnKeys.PURCHASE_VALUE,
        render: record => getFormatPrice(record),
      }),
      [columnKeys.STANDING_VALUE]: () => ({
        title: t('TRADE_IN_STANDING_VALUE'),
        width: '10em',
        key: columnKeys.STANDING_VALUE,
        dataIndex: columnKeys.STANDING_VALUE,
        render: record => getFormatPrice(record),
      }),
      [columnKeys.DESCRIPTION]: () => ({
        title: t('TRADE_IN_DESCRIPTION'),
        key: columnKeys.DESCRIPTION,
        dataIndex: columnKeys.DESCRIPTION,
        render: record => longTextFormat(record),
      }),
      [columnKeys.ATTACHMENTS]: () => ({
        title: t('TRADE_IN_ATTACHMENTS'),
        key: columnKeys.ATTACHMENTS,
        dataIndex: columnKeys.ATTACHMENTS,
        render: record => longTextFormat(record),
      }),
      [columnKeys.VARIANCE]: () => ({
        title: t('TRADE_IN_VARIANCE'),
        key: columnKeys.VARIANCE,
        dataIndex: columnKeys.VARIANCE,
        render: record => (
          <TableEmphasizedGreenText>{getFormatPrice(record)}</TableEmphasizedGreenText>
        ),
      }),
      [columnKeys.ACTIONS]: () => ({
        title: t('TRADE_IN_ACTION'),
        key: columnKeys.ACTIONS,
        dataIndex: columnKeys.MACHINE_NUMBER,
        align: 'center' as const,
        width: '5.5em',
        render: (machineNumber, record: TradeIn) => (
          <ScDropdown
            trigger={['click']}
            overlay={() => menu(machineNumber, record)}
            placement="bottomLeft"
            data-testid="tradein-page-table-item-actions-menu-dropdown"
          >
            <DotsIcon
              style={{ fontSize: '2em' }}
              data-testid="tradein-page-table-item-actions-menu-dropdown-icon"
            />
          </ScDropdown>
        ),
        fixed: 'right' as FixedType,
      }),
    };
  }, [
    t,
    isConfigurationComplete,
    handleDocumentModal,
    handleViewImages,
    getFormatPrice,
    menu,
  ]);

  const tableColumns: ColumnsType<TradeIn> = useMemo(() => {
    let columns: ColumnsType<TradeIn> = [];

    if (columnsSettings) {
      columns = columnsSettings
        .filter(settings => keyToColumn[settings.name])
        .map(settings => ({ fn: keyToColumn[settings.name], settings }))
        .map(({ fn, settings }) => fn(settings));
    } else {
      columns = Object.values(keyToColumn).map(fn => fn({}));
    }

    const hasActionsColumn = columns.some(column => column.key === columnKeys.ACTIONS);
    if (!hasActionsColumn) {
      columns.push(keyToColumn[columnKeys.ACTIONS]({}));
    }

    return columns;
  }, [columnsSettings, keyToColumn]);

  return tableColumns;
};
