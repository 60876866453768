import styled from 'styled-components';

export const ScPaper = styled.div`
  margin-bottom: 2em;
  &:last-child {
    margin-bottom: 0;
  }
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;
export const ScPaperHeader = styled.div``;
export const ScPaperBody = styled.div`
  padding: 1.625em 1.625em 2em;
`;
export const ScPaperFooter = styled.div``;
