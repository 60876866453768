import * as Yup from 'yup';
import { SoftOfferUsualDriver, SoftOfferUsualDriverFields } from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<SoftOfferUsualDriver>>({
    [SoftOfferUsualDriverFields.driverLastName]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferUsualDriverFields.driverFirstname]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferUsualDriverFields.driverBirthdayAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverSex]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverIsHolder]: Yup.boolean().default(false),
    [SoftOfferUsualDriverFields.isOccasionalDriversEnabled]: Yup.boolean().default(false),
    [SoftOfferUsualDriverFields.driverStreet]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverNr]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverBox]: Yup.string(),
    [SoftOfferUsualDriverFields.driverZipCode]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferUsualDriverFields.driverLocality]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferUsualDriverFields.driverTel]: Yup.string(),
    [SoftOfferUsualDriverFields.driverFax]: Yup.string(),
    [SoftOfferUsualDriverFields.driverGsm]: Yup.string(),
    [SoftOfferUsualDriverFields.driverLanguage]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferUsualDriverFields.driverEmail]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverJob]: Yup.string().required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverAffinity]: Yup.string(),
    [SoftOfferUsualDriverFields.driverLicenceAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    [SoftOfferUsualDriverFields.driverLicenceCategoryCode]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
  });
};
