import { Model_CommonCategory } from '@hypercharge/xdms-client/lib/model';
import { Model_CommonItem } from '@hypercharge/xdms-client/lib/types';
import { isCategoryRequired } from './isCategoryRequired';
import { isCategoryConfigured } from './isCategoryConfigured';

export const getInconfiguredCategories = (
  categoriesList: Model_CommonCategory[],
  itemsList: Model_CommonItem[],
): Model_CommonCategory[] => {
  return categoriesList
    .filter(isCategoryRequired)
    .filter(category => !isCategoryConfigured(category, itemsList));
};
