import { Dropdown } from 'antd';
import styled from 'styled-components';
import { FC, PropsWithChildren, ComponentProps } from 'react';

// @todo: antd@5
export const ScDropdown: FC<PropsWithChildren<ComponentProps<typeof Dropdown>>> = styled(
  Dropdown,
)`
  &&&& {
    font-size: inherit;
  }
`;
