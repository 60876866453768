import {
  AccessoryCategoryItem,
  AccessoryCategoryItemFields,
  AccessoryItem,
  AccessoryItemFields,
  Model,
  ModelFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonCategoryFields,
  OptionCategoryItem,
  OptionItem,
  OptionItemCategoryFields,
  OptionItemFields,
  PackageCategoryItem,
  PackageCategoryItemFields,
  PackageItem,
  PackageItemFields,
  TermsAndConditionsItem,
} from '@hypercharge/xdms-client/lib/types';
import {
  ACCESSORIES_CATEGORY,
  ACCESSORIES_PAGE_URL,
  ACC_SUBCATEGORY,
  OPTIONS_CATEGORY,
  OPTIONS_PAGE_URL,
  OPT_SUBCATEGORY,
  PACKAGES_CATEGORY,
  PACKAGES_PAGE_URL,
  PKG_SUBCATEGORY,
  TERMS_AND_CONDITIONS_CATEGORY,
  TERMS_AND_CONDITIONS_PAGE_URL,
  TERMS_AND_CONDITIONS_SUBCATEGORY,
  VIRTUAL_SUBCATEGORY,
  IS_CATEGORY_REQUIRED_staticIdRegExp,
} from 'common/constants';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { useQuery } from 'context/router/UrlQueryProvider';
import { getFilteredTableData } from 'pages/DynamicPage/utils';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollMenuOptions } from 'types/common';
import { get, sortBy } from 'utils';
import { defaultStepId, NAME_TAB_ALL, NAME_TAB_ALL_KEY } from 'utils/constants';
import { getUniqueCategoryFilterKey } from 'utils/get-unique-category-filter-key';
import { Breadcrumbs } from 'utils/types';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { DocumentRelatedEntityCode } from '../document/types';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { StreamingOptionType } from 'context/streaming/types';
import { ModelCategory } from 'types/vendor';

export type OptionalModelItem =
  | AccessoryItem
  | OptionItem
  | PackageItem
  | TermsAndConditionsItem;
/** todo: same as {@link Model_CommonCategory} from xdms-client - replace */
export type CategoryModelItem =
  | (AccessoryCategoryItem & { tKey?: string })
  | (OptionCategoryItem & { tKey?: string })
  | (PackageCategoryItem & { tKey?: string });
export const subcategoryKeys = [
  OptionItemCategoryFields.lvl2,
  OptionItemCategoryFields.lvl3,
  OptionItemCategoryFields.lvl4,
];

export const PAGES_SETTINGS = {
  [ACCESSORIES_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: AccessoryItemFields,
    MODEL_TABLE_CATEGORY_FIELDS: AccessoryCategoryItemFields,
    SELECTED_CATEGORY_NAME: ACCESSORIES_CATEGORY,
    MODEL_TABLE: ModelFields.accessories,
    MODEL_TABLE_CATEGORIES: ModelFields.accessoriesCategories,
    SELECTED_SUBCATEGORIES: ACC_SUBCATEGORY,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
    DOCUMENT_ENTITY_RELATION_CODE: DocumentRelatedEntityCode.accessory,
    STREAMING_OPTION_TYPE: StreamingOptionType.accessory,
  },
  [OPTIONS_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: OptionItemFields,
    MODEL_TABLE_CATEGORY_FIELDS: OptionItemCategoryFields,
    SELECTED_CATEGORY_NAME: OPTIONS_CATEGORY,
    MODEL_TABLE: ModelFields.options,
    MODEL_TABLE_CATEGORIES: ModelFields.optionsCategories,
    SELECTED_SUBCATEGORIES: OPT_SUBCATEGORY,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
    DOCUMENT_ENTITY_RELATION_CODE: DocumentRelatedEntityCode.option,
    STREAMING_OPTION_TYPE: StreamingOptionType.option,
  },
  [PACKAGES_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: PackageItemFields,
    MODEL_TABLE_CATEGORY_FIELDS: PackageCategoryItemFields,
    SELECTED_CATEGORY_NAME: PACKAGES_CATEGORY,
    MODEL_TABLE: ModelFields.packages,
    MODEL_TABLE_CATEGORIES: '',
    SELECTED_SUBCATEGORIES: PKG_SUBCATEGORY,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.commonItem,
    DOCUMENT_ENTITY_RELATION_CODE: DocumentRelatedEntityCode.package,
    STREAMING_OPTION_TYPE: StreamingOptionType.package,
  },
  [TERMS_AND_CONDITIONS_PAGE_URL]: {
    MODEL_TABLE_ITEMS_FIELDS: OptionItemFields,
    MODEL_TABLE_CATEGORY_FIELDS: OptionItemCategoryFields,
    SELECTED_CATEGORY_NAME: TERMS_AND_CONDITIONS_CATEGORY,
    MODEL_TABLE: ModelFields.termsAndConditions,
    MODEL_TABLE_CATEGORIES: 'ttCatTermsAndConditions',
    SELECTED_SUBCATEGORIES: TERMS_AND_CONDITIONS_SUBCATEGORY,
    MODEL_UPDATE_ATTRIBUTES: ModelUpdateAttributesObjectFields.termsAndConditions,
    DOCUMENT_ENTITY_RELATION_CODE: DocumentRelatedEntityCode.tAc,
    STREAMING_OPTION_TYPE: null,
  },
};

type ContextValue = {
  scrollMenuOptions: ScrollMenuOptions[];
  allScrollMenuOptions: ScrollMenuOptions[];
  selectedScrollMenuOption: ScrollMenuOptions | null;
  subcategories: CategoryModelItem[];
  selectedSubcategory: CategoryModelItem | null;
  breadcrumbs: Breadcrumbs[];
  handleChangeRootCategory(category: string): void;
  handleSelectSubcategory(category: CategoryModelItem): void;
  setCreateVirtualCategory(isCreateVirtualCategory: boolean): void;
  getCategoriesHierarchy(item: OptionalModelItem): string;
  overrideStep(newStepId: keyof typeof PAGES_SETTINGS): void;
  getInconfirguredRequiredCategory(): [string, string] | null;
  stepId: keyof typeof PAGES_SETTINGS;
};

const TableCategoriesContext = React.createContext<ContextValue | undefined>(undefined);

interface Props {
  stepId?: keyof typeof PAGES_SETTINGS;
  value?: ContextValue;
}

const TableCategoriesProvider: FC<PropsWithChildren<Props>> = props => {
  const { query, setQueryParams, queryValues } = useQuery();
  const { pathname } = useLocation();
  const { isFeatureEnabled } = useFeature();

  const model = useSelector(modelSelectors.getModel);

  const [scrollMenuOptions, setScrollMenuOptions] = useState<ScrollMenuOptions[]>([]);
  // same as `scrollMenuOptions`, but not filtered according to strandard setting
  const [allScrollMenuOptions, setAllScrollMenuOptions] = useState<ScrollMenuOptions[]>(
    [],
  );
  const [selectedScrollMenuOption, setSelectedScrollMenuOption] =
    useState<ScrollMenuOptions | null>(null);
  const [subcategories, setSubcategoris] = useState<CategoryModelItem[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<CategoryModelItem | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs[]>([]);
  const [isCreateVirtualCategory, setCreateVirtualCategory] = useState<boolean>(false);

  const initialStepId = props.stepId ?? (defaultStepId as keyof typeof PAGES_SETTINGS);
  const [stepId, setStepId] = useState<keyof typeof PAGES_SETTINGS>(initialStepId);

  const isStandardFeaturesFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowStandardFeatures',
    source: FeatureSource.DYNAMIC,
  });
  const isRequiredCategoriesFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RequiredCategories,
  });

  const {
    MODEL_TABLE,
    MODEL_TABLE_CATEGORY_FIELDS,
    SELECTED_CATEGORY_NAME,
    MODEL_TABLE_CATEGORIES,
    SELECTED_SUBCATEGORIES,
    MODEL_TABLE_ITEMS_FIELDS,
  } = PAGES_SETTINGS[stepId];

  const overrideStep = useCallback((newStepId: keyof typeof PAGES_SETTINGS): void => {
    setStepId(newStepId);
  }, []);

  useEffect(() => {
    if (props.stepId) {
      setStepId(props.stepId);
    }
  }, [props.stepId]);

  const requiredCategoryToSelectedRecords = useMemo(() => {
    if (!isRequiredCategoriesFeatureEnabled) return {};
    const records = get(model, MODEL_TABLE, []) as Model_CommonItem[];
    const requiredCategories = scrollMenuOptions.filter(smo => smo.required);
    if (!requiredCategories) return {};
    const result: Record<string, { name: string; data: Model_CommonItem[] }> =
      requiredCategories.reduce((res, category) => {
        res[category.key] = {
          name: category.name,
          data: [],
        };
        return res;
      }, {});
    records.forEach(record => {
      const categoryKey = record[Model_CommonItemFields.categoryId];
      const isSelected =
        record[Model_CommonItemFields.selected] &&
        !record[Model_CommonItemFields.selectedByDefault];
      if (result[categoryKey] && isSelected) {
        result[categoryKey].data.push(record);
      }
    });
    return result;
  }, [isRequiredCategoriesFeatureEnabled, MODEL_TABLE, model, scrollMenuOptions]);

  const getInconfirguredRequiredCategory = useCallback((): [string, string] | null => {
    const iteratee: [string, { name: string; data: Model_CommonItem[] }][] =
      Object.entries(requiredCategoryToSelectedRecords);
    const category = iteratee.find(
      ([, { data: categoryRecords }]) => !categoryRecords.length,
    );
    if (!category) return null;
    const [categoryKey, { name: categoryName }] = category;
    return [categoryKey, categoryName];
  }, [requiredCategoryToSelectedRecords]);

  // returns an array of the selected subcategories based on the query
  const selectedSubcategories = useMemo((): string[] => {
    const selectedSubcategories = get(query, SELECTED_SUBCATEGORIES, '');
    let selectedSubcategoryIds: string[] = [];

    if (selectedSubcategories && typeof selectedSubcategories === 'string') {
      selectedSubcategoryIds = selectedSubcategories.split('|');
    }
    return selectedSubcategoryIds;
  }, [query, SELECTED_SUBCATEGORIES]);

  /**
   * Function for forming a string with selected subcategories in the required format
   * The required key is determined on the basis of the selected subcategories
   * If no value is found for a specific key in the selected subcategory, the function returns the previously selected subcategories
   */
  const buildSelectedSubcategoriesQuery = useCallback(
    (category: CategoryModelItem): string => {
      const activeSubcategoryKey = subcategoryKeys[selectedSubcategories.length];
      const selectedSubcategory = get(category, activeSubcategoryKey, '') as string;

      if (!selectedSubcategory) {
        return selectedSubcategories.join('|');
      }

      return selectedSubcategories.length
        ? `${[...selectedSubcategories, selectedSubcategory].join('|')}`
        : selectedSubcategory;
    },
    [selectedSubcategories],
  );

  /**
   * Function to change the main category
   * The function also clears the selected subcategories
   */
  const handleChangeRootCategory = useCallback(
    (category = ''): void => {
      const selectedOption =
        scrollMenuOptions.find(option => option.key === category) || null;

      setSelectedSubcategory(null);
      setSelectedScrollMenuOption(selectedOption);
      // TODO add a method for removing and adding query parameters in one method
      setQueryParams(
        {
          key: SELECTED_CATEGORY_NAME,
          value: get(selectedOption, 'key', NAME_TAB_ALL),
        },
        {
          key: SELECTED_SUBCATEGORIES,
          value: '',
        },
        {
          key: VIRTUAL_SUBCATEGORY,
          value: '',
        },
      );
    },
    [scrollMenuOptions, SELECTED_CATEGORY_NAME, SELECTED_SUBCATEGORIES, setQueryParams],
  );

  /**
   * Function to change the subcategory
   */
  const handleSelectSubcategory = useCallback(
    (category: CategoryModelItem): void => {
      const selectedSubcategory =
        get(model, MODEL_TABLE_CATEGORIES, []).find(option => option === category) ||
        null;

      setSelectedSubcategory(selectedSubcategory);
      setQueryParams(
        {
          key: SELECTED_SUBCATEGORIES,
          value: buildSelectedSubcategoriesQuery(category),
        },
        {
          key: VIRTUAL_SUBCATEGORY,
          value: category.tKey === NAME_TAB_ALL_KEY ? NAME_TAB_ALL : '',
        },
      );
    },
    [
      model,
      MODEL_TABLE_CATEGORIES,
      setQueryParams,
      SELECTED_SUBCATEGORIES,
      buildSelectedSubcategoriesQuery,
    ],
  );

  /**
   * Filter, sort and format all categories to get an array of main categories
   */
  const getScrolledMenuOptions = useCallback(
    (categories, type, selectedCategory): ScrollMenuOptions[] => {
      const result = categories
        .filter((category, index) => {
          const isDuplicate =
            categories.findIndex(
              cat =>
                get(cat, MODEL_TABLE_CATEGORY_FIELDS.lvl1) ===
                get(category, MODEL_TABLE_CATEGORY_FIELDS.lvl1),
            ) === index;
          return (
            !get(category, type.hidden) && get(category, type.lvl1) !== '' && isDuplicate
          );
        })
        .map(category => {
          const isCategoryActive =
            selectedCategory &&
            selectedCategory ===
              getUniqueCategoryFilterKey({
                category,
                idFlag: MODEL_TABLE_CATEGORY_FIELDS.lvl1,
              });

          const categoryKey = getUniqueCategoryFilterKey({
            category,
            idFlag: MODEL_TABLE_CATEGORY_FIELDS.lvl1,
          });

          return {
            key: categoryKey,
            name: get(category, type.name) || '',
            tKey: !get(category, type.name) && 'TABLE_CATEGORY_STUB',
            active: isCategoryActive,
            disabled: false,
            link: '',
            required: IS_CATEGORY_REQUIRED_staticIdRegExp.test(
              category[MODEL_TABLE_CATEGORY_FIELDS.staticId],
            ),
            configured: false,
            staticId: get(category, type.staticId, ''),
          };
        });

      return sortBy(result, ['name']);
    },
    [MODEL_TABLE_CATEGORY_FIELDS],
  );

  const categoryLvl1KeyToDefaultSelectedTableData = useMemo<
    Record<string, string[]>
  >(() => {
    if (!isStandardFeaturesFeatureEnabled) return {};
    const { tableData: records } = getFilteredTableData({
      items: get(model, MODEL_TABLE, []) as Model_CommonItem[],
      stepId,
      selectedCategory: null,
      selectedSubCategory: null,
    });
    return records.reduce((res, item) => {
      if (
        item[Model_CommonItemFields.selectedByDefault] ||
        item[Model_CommonItemFields.categoryCode2]
      ) {
        if (!res[item[Model_CommonItemFields.categoryCode]]) {
          res[item[Model_CommonItemFields.categoryCode]] = [];
        }
        return res;
      }
      if (!res[item[Model_CommonItemFields.categoryCode]]) {
        res[item[Model_CommonItemFields.categoryCode]] = [item];
        return res;
      }
      res[item[Model_CommonItemFields.categoryCode]].push(item);
      return res;
    }, {});
  }, [MODEL_TABLE, isStandardFeaturesFeatureEnabled, model, stepId]);

  /**
   * Function for saving main categories.
   * Also used to add the category "All" if the table is not empty
   */
  const setScrollingMenuOptions = useCallback(
    (model: Model): ScrollMenuOptions[] => {
      const tableData = get(model, MODEL_TABLE, []);
      const categories: ModelCategory[] = get(model, MODEL_TABLE_CATEGORIES, []);
      const requiredCategories = categories.filter(category =>
        category[MODEL_TABLE_CATEGORY_FIELDS.lvl1].includes('.'),
      );
      const optionalCategories = categories.filter(
        category => !category[MODEL_TABLE_CATEGORY_FIELDS.lvl1].includes('.'),
      );
      let menuOptions: ScrollMenuOptions[] = [
        ...getScrolledMenuOptions(
          requiredCategories,
          MODEL_TABLE_CATEGORY_FIELDS,
          get(query, SELECTED_CATEGORY_NAME),
        ),
        ...getScrolledMenuOptions(
          optionalCategories,
          MODEL_TABLE_CATEGORY_FIELDS,
          get(query, SELECTED_CATEGORY_NAME),
        ),
      ];

      if (isRequiredCategoriesFeatureEnabled) {
        menuOptions = menuOptions.map(menuOption => ({
          ...menuOption,
          configured: menuOption.required
            ? Boolean(requiredCategoryToSelectedRecords[menuOption.key]?.data.length)
            : true,
        }));
      }

      const allOptionsTab: ScrollMenuOptions = {
        key: NAME_TAB_ALL,
        active: get(query, SELECTED_CATEGORY_NAME, '') === NAME_TAB_ALL,
        disabled: false,
        name: '',
        tKey: 'ALL_TAB_IN_STEP',
        required: false,
        configured: true,
        staticId: '',
      };

      if (menuOptions.length || tableData.length) {
        menuOptions.unshift(allOptionsTab);
      }

      setAllScrollMenuOptions(menuOptions);

      if (isStandardFeaturesFeatureEnabled) {
        if (!queryValues.standardFeaturesValue) {
          const standardFeaturesMenuOptions = menuOptions.filter(menuOption => {
            return (
              categoryLvl1KeyToDefaultSelectedTableData[menuOption.key]?.length ||
              menuOption.key === NAME_TAB_ALL
            );
          });
          setScrollMenuOptions(standardFeaturesMenuOptions);
          return standardFeaturesMenuOptions;
        }
      }

      setScrollMenuOptions(menuOptions);
      return menuOptions;
    },
    [
      MODEL_TABLE,
      getScrolledMenuOptions,
      MODEL_TABLE_CATEGORIES,
      MODEL_TABLE_CATEGORY_FIELDS,
      query,
      SELECTED_CATEGORY_NAME,
      isRequiredCategoriesFeatureEnabled,
      isStandardFeaturesFeatureEnabled,
      requiredCategoryToSelectedRecords,
      queryValues.standardFeaturesValue,
      categoryLvl1KeyToDefaultSelectedTableData,
    ],
  );

  /**
   * The function of building a virtual subcategory to display items on the same level with subcategories
   */
  const buildVirtualSubcategory = useCallback((): CategoryModelItem => {
    const selectedCategory = get(query, SELECTED_CATEGORY_NAME, '');
    return {
      CDcat: selectedCategory,
      CDcat2: get(selectedSubcategories, 0, ''),
      CDcat3: get(selectedSubcategories, 1, ''),
      CDcat4: get(selectedSubcategories, 2, ''),
      NMcat: '',
      NRdisplaySequence: 1,
      YNdummyHyper: false,
      YNhidden: false,
      tKey: NAME_TAB_ALL_KEY,
    } as CategoryModelItem;
  }, [selectedSubcategories, query, SELECTED_CATEGORY_NAME]);

  /**
   * Function for filtering categories and defining an array of current subcategories
   */
  const filteredSubCategories = useCallback(() => {
    const modelCategories = get(model, MODEL_TABLE_CATEGORIES, []);
    const selectedCategory = get(query, SELECTED_CATEGORY_NAME, '');
    const isActiveVirtualCat = !!get(query, VIRTUAL_SUBCATEGORY, false);

    if (isActiveVirtualCat) {
      setSubcategoris([]);
      return;
    }

    let subCategories: CategoryModelItem[] = modelCategories.filter(category => {
      const isHidden = get(category, MODEL_TABLE_CATEGORY_FIELDS.hidden);
      const belongsSelectedCat =
        getUniqueCategoryFilterKey({
          category,
          idFlag: MODEL_TABLE_CATEGORY_FIELDS.lvl1,
        }) === selectedCategory;
      let belongsSelectedSubcategories = true;
      const hasSubcategories =
        !!subcategoryKeys[selectedSubcategories.length] &&
        get(category, subcategoryKeys[selectedSubcategories.length]) !== '' &&
        get(category, subcategoryKeys[selectedSubcategories.length + 1], '') === '';

      for (const selectedSubcat in selectedSubcategories) {
        belongsSelectedSubcategories =
          belongsSelectedSubcategories &&
          selectedSubcategories[selectedSubcat] ===
            get(category, subcategoryKeys[selectedSubcat]);
      }
      return (
        !isHidden &&
        belongsSelectedCat &&
        belongsSelectedSubcategories &&
        hasSubcategories
      );
    });

    subCategories = sortBy(subCategories, Model_CommonCategoryFields.name);

    if (isCreateVirtualCategory && subCategories.length) {
      subCategories.unshift(buildVirtualSubcategory());
    }
    setSubcategoris(subCategories);
  }, [
    model,
    MODEL_TABLE_CATEGORIES,
    query,
    SELECTED_CATEGORY_NAME,
    isCreateVirtualCategory,
    MODEL_TABLE_CATEGORY_FIELDS.hidden,
    MODEL_TABLE_CATEGORY_FIELDS.lvl1,
    selectedSubcategories,
    buildVirtualSubcategory,
  ]);

  /**
   * Function for creating an item of breadcrumbs
   */
  const buildBreadcrumbItem = useCallback(
    (
      selectedSubcategories: string,
      subcategory: string,
      title: string,
      disabled = false,
    ): Breadcrumbs => {
      const subcategoryQuery = {
        ...query,
        [SELECTED_SUBCATEGORIES]: selectedSubcategories,
        [VIRTUAL_SUBCATEGORY]: '',
      } as Record<string, string>;
      const stringQuery = new URLSearchParams(subcategoryQuery).toString();

      return {
        id: subcategory,
        title,
        disabled,
        path: `?${stringQuery}`,
      };
    },
    [query, SELECTED_SUBCATEGORIES],
  );

  /**
   * Breadcrumb building function.
   * Also, the function is used to create a virtual item for displaying items on the same level with other subcategories.
   */
  const buildBreadcrumb = useCallback((): void => {
    const isActiveVirtualCat = !!get(query, VIRTUAL_SUBCATEGORY, false);
    const modelCategories = get(model, MODEL_TABLE_CATEGORIES, []);

    const breadcrumbs = selectedSubcategories.map((subcategory, index) => {
      const selectedSubCatFormattedString: string = [...selectedSubcategories]
        .splice(0, index + 1)
        .join('|');
      const selectedSubcatigory = modelCategories.find(category => {
        const modelCatigoryName = get(category, MODEL_TABLE_CATEGORY_FIELDS.lvl1);
        const selectedCatigoryName = get(query, SELECTED_CATEGORY_NAME, '');
        const modelItemSubcategoriesFormattedString = [
          ...subcategoryKeys.map(key => get(category, key, false)),
        ]
          .filter(Boolean)
          .join('|');

        return (
          modelCatigoryName === selectedCatigoryName &&
          modelItemSubcategoriesFormattedString === selectedSubCatFormattedString
        );
      });
      const title = get(
        selectedSubcatigory,
        MODEL_TABLE_CATEGORY_FIELDS.name,
        subcategory,
      );

      return buildBreadcrumbItem(selectedSubCatFormattedString, subcategory, title);
    });

    const defaultBreadcrumbKey = get(selectedScrollMenuOption, 'key', '');
    const defaultBreadcrumbName = get(selectedScrollMenuOption, 'name', '');
    const defaultBreadcrumb = buildBreadcrumbItem(
      '',
      defaultBreadcrumbKey,
      defaultBreadcrumbName,
      false,
    );

    if (defaultBreadcrumbKey === NAME_TAB_ALL) {
      setBreadcrumbs([]);
    } else {
      const breadcrumb = [defaultBreadcrumb, ...breadcrumbs];
      if (isActiveVirtualCat) {
        breadcrumb.push({
          ...defaultBreadcrumb,
          title: NAME_TAB_ALL,
          disabled: true,
        });
      }
      setBreadcrumbs(breadcrumb);
    }
  }, [
    query,
    model,
    selectedSubcategories,
    MODEL_TABLE_CATEGORIES,
    SELECTED_CATEGORY_NAME,
    selectedScrollMenuOption,
    MODEL_TABLE_CATEGORY_FIELDS,
    buildBreadcrumbItem,
  ]);

  const getCategoriesHierarchy = useCallback(
    (item: OptionalModelItem): string => {
      const separator = ' > ';
      const itemCategoryId = get(item, MODEL_TABLE_ITEMS_FIELDS.categoryId, '');
      const itemSubcategories = get(item, 'CDcat2', '')
        ? get(item, 'CDcat2', '').split('|')
        : [];
      const modelCategories = get(model, MODEL_TABLE_CATEGORIES, []);
      const itemCategory = modelCategories.find(
        cat => get(cat, MODEL_TABLE_CATEGORY_FIELDS.lvl1) === itemCategoryId,
      );

      const hierarchy = itemSubcategories.map((subcategoryId, index) => {
        const selectedSubCatFormattedString: string = [...itemSubcategories]
          .splice(0, index + 1)
          .join('|');
        const subcategory = modelCategories.find(category => {
          const modelCategoryName = get(category, MODEL_TABLE_CATEGORY_FIELDS.lvl1);
          const modelItemSubcategoriesFormattedString = subcategoryKeys
            .map(key => get(category, key, false))
            .filter(Boolean)
            .join('|');
          return (
            modelCategoryName === itemCategoryId &&
            modelItemSubcategoriesFormattedString === selectedSubCatFormattedString
          );
        });
        return get(subcategory, MODEL_TABLE_CATEGORY_FIELDS.name, subcategoryId);
      });

      const itemCategoryName = get(
        itemCategory,
        MODEL_TABLE_CATEGORY_FIELDS.name,
        itemCategoryId,
      );

      return [itemCategoryName, ...hierarchy].join(separator);
    },
    [
      model,
      MODEL_TABLE_ITEMS_FIELDS,
      MODEL_TABLE_CATEGORIES,
      MODEL_TABLE_CATEGORY_FIELDS,
    ],
  );

  useEffect(() => {
    filteredSubCategories();
    buildBreadcrumb();
    // eslint-disable-next-line
  }, [
    selectedScrollMenuOption,
    query,
    selectedSubcategory,
    pathname,
    isCreateVirtualCategory,
  ]);

  useEffect(() => {
    if (!model) {
      return;
    }

    const rootCategories = setScrollingMenuOptions(model);

    if (!rootCategories.some(category => category.active)) {
      handleChangeRootCategory(NAME_TAB_ALL);
    } else {
      setSelectedScrollMenuOption(
        rootCategories.find(category => category.active) || null,
      );
    }
  }, [model, pathname, query, stepId]);

  const value = useMemo<ContextValue>(
    () => ({
      scrollMenuOptions,
      allScrollMenuOptions,
      selectedScrollMenuOption,
      subcategories,
      selectedSubcategory,
      breadcrumbs,
      handleChangeRootCategory,
      handleSelectSubcategory,
      setCreateVirtualCategory,
      getCategoriesHierarchy,
      overrideStep,
      getInconfirguredRequiredCategory,
      stepId,
    }),
    [
      scrollMenuOptions,
      allScrollMenuOptions,
      selectedScrollMenuOption,
      subcategories,
      selectedSubcategory,
      breadcrumbs,
      handleChangeRootCategory,
      handleSelectSubcategory,
      setCreateVirtualCategory,
      getCategoriesHierarchy,
      overrideStep,
      getInconfirguredRequiredCategory,
      stepId,
    ],
  );

  return <TableCategoriesContext.Provider value={value} {...props} />;
};

const useTableCategories = (): ContextValue => {
  const context = useContext(TableCategoriesContext);

  if (context === undefined) {
    throw new Error('useTableCategories must be used within an TableCategoriesProvider');
  }

  return context;
};

export { TableCategoriesProvider, useTableCategories };
