import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../common/theme';
import { Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';

export const HeaderWrapper = styled.div`
  height: 3.75em;
  font-weight: 600;
  color: ${props => props.theme.textColor};
  border-bottom: 1px solid ${props => props.theme.colors.softPeach};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 20;
`;

export const HeaderContent = styled.div`
  height: 3.75em;
  padding: 1em;

  ${mediaMax.md} {
    padding: 0;
  }

  ${mediaMin.md} {
    padding: 0;
    flex-direction: row;
  }
`;

export const LogoWrap = styled.div`
  margin-right: 2.188em;
  height: 100%;
  text-align: center;
`;

export const LogoImg = styled.img`
  max-height: 3.5em;
  padding: 0.3em 0;
`;

export const LogoContainer = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-left: 1em;
`;

export const Separator = styled.div`
  height: calc(3.75em - 1.25em);
  margin: auto 0;
  border-right: 1px solid ${props => props.theme.colors.dustyGray};
  opacity: 0.25;
`;

export const ScHeaderActions = styled(Row)`
  height: 100%;
  flex-wrap: nowrap;
`;

export const ScModelName = styled.div`
  padding: 0 1em;
  flex: 1;

  ${mediaMin.md} {
    width: auto;
  }
`;
export const ScGoToImportSource = styled.a`
  margin-right: 0.625em;
  font-size: 1.4em;
  color: ${props => props.theme.mainColor};
`;

export const PageWidthWrapper = styled.div`
  box-sizing: border-box;
`;

export const ScPublishConfigurationStatus = styled.div`
  margin-right: 0.625em;
`;

export const ScLockOutlined = styled(LockOutlined)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.highlightColor};
`;

export const ScHeaderIdsGroup = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 6.75em;
  margin-left: 1em;
`;

export const ScHeaderIdsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ScHeaderText = styled.span`
  color: ${({ theme }) => theme.colors.vampire};
  font-size: 0.875em;

  ${mediaMax.md} {
    font-size: 0.75em;
  }
`;

export const ScHeaderIdText = styled(ScHeaderText)`
  margin-right: 0.938em;

  &:last-child {
    margin-right: 0;
  }
`;

export const ScHeaderModelDetailsGroup = styled(ScHeaderIdsGroup)`
  align-items: center;
`;

export const ScHeaderModelDetailsRow = styled(ScHeaderIdsRow)`
  justify-content: center;
`;

export const ScHeaderModelDetailsNoWrap = styled(ScHeaderText)`
  white-space: nowrap;
`;

export const ScHeaderModeDetailsCentered = styled(ScHeaderText)`
  text-align: center;
`;

export const ScVatSwitcherWrapper = styled.div`
  margin-left: auto;
  margin-right: 1em;
`;
