import React, { FC } from 'react';
import { Step, useStep } from 'context/step/StepProvider';
import { ScSteps, ScStepNumber } from './Steps.styles';

export const Steps: FC = () => {
  const { steps, activeStepIndex, handleStepClick } = useStep();

  return (
    <ScSteps>
      {steps
        .map((step, index): [Step, boolean] => [step, activeStepIndex >= index])
        .filter(([step]) => !step.hidden)
        .map(([step, active], index) => (
          <ScStepNumber
            key={step.title}
            titleTKey={step.title}
            link={step.link}
            icon={step.icon}
            position={step.position}
            disabled={!!step.getDisabledMessage()}
            onClick={handleStepClick}
            active={active}
            number={index}
          />
        ))}
    </ScSteps>
  );
};
