import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { ReactComponent as Image } from 'assets/icons/image-icon.svg';

const ImageIcon = props => <Icon component={Image} {...props} />;

const ScImageIcon = styled(ImageIcon)`
  font-size: 1.75em;
`;

export default props => {
  return <ScImageIcon {...props} />;
};
