import styled from 'styled-components';
import Select from 'components/form/Selector';

export const ScModalContent = styled.div`
  padding: 1em;
`;

export const ScSelect = styled(Select)`
  margin-bottom: 2em;
`;

export const ScModalContentText = styled.p`
  font-weight: 400;
`;
