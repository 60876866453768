import {
  SoftOfferOccasionalDriversFields,
  SoftOfferOccasionalDrivers,
  SoftOfferOptionsKeys,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { DatePicker } from 'components/form/DatePicker';
import { Status } from 'utils/types';
import { DATE_FORMAT } from 'common/constants';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { isFieldRequired } from 'utils/is-field-required';
import { CountryFields } from '@hypercharge/xdms-client/lib/types';
import { MobileInput, MobileInputCountry } from 'components/form/MobileInput';
import { useCountry } from 'context/country/CountryProvider';
import { ScCollapse, ScCollapsePanel } from '../styles';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

interface Props {
  isDisabled: boolean;
  defaultCountryCode?: string;
  initialValues?: SoftOfferOccasionalDrivers;
  onSubmit(values: SoftOfferOccasionalDrivers): void;
  setIsTabLocked?(value: boolean): void;
}

const OccasionalDriversForm: FC<Props> = ({
  onSubmit,
  initialValues,
  defaultCountryCode,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const { countries } = useCountry();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  const driver1Jobs = options?.[SoftOfferOptionsKeys.Occa1Job]?.options ?? [];
  const driver1Languages = options?.[SoftOfferOptionsKeys.Occa1Language]?.options ?? [];
  const driver1LicenceCategoryCodes =
    options?.[SoftOfferOptionsKeys.Occa1LicenceCategoryCode]?.options ?? [];
  const driver1Sexes = options?.[SoftOfferOptionsKeys.Occa1Sex]?.options ?? [];

  const driver2Jobs = options?.[SoftOfferOptionsKeys.Occa2Job]?.options ?? [];
  const driver2Languages = options?.[SoftOfferOptionsKeys.Occa2Language]?.options ?? [];
  const driver2LicenceCategoryCodes =
    options?.[SoftOfferOptionsKeys.Occa2LicenceCategoryCode]?.options ?? [];
  const driver2Sexes = options?.[SoftOfferOptionsKeys.Occa2Sex]?.options ?? [];

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    return initialValues;
  }, [initialValues]);

  const formProps = useFormik({
    initialValues: initialData!,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { dirty } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={8}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1LastName}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1LastName,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_LAST_NAME')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Firstname}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Firstname,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_FIRST_NAME')}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1BirthdayAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1BirthdayAt,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_BIRTHDAY')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Sex}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(driver1Sexes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Sex,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_GENDER')}
                />
              </Col>
            </Row>

            <InputCityZipField
              inputZipProps={{
                id: SoftOfferOccasionalDriversFields.driver1ZipCode,
                name: SoftOfferOccasionalDriversFields.driver1ZipCode,
                required: isFieldRequired(
                  schema,
                  SoftOfferOccasionalDriversFields.driver1ZipCode,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_ZIPCODE'),
              }}
              inputCityProps={{
                id: SoftOfferOccasionalDriversFields.driver1Locality,
                name: SoftOfferOccasionalDriversFields.driver1Locality,
                required: isFieldRequired(
                  schema,
                  SoftOfferOccasionalDriversFields.driver1Locality,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_TOWN'),
              }}
            />

            <FormField
              name={SoftOfferOccasionalDriversFields.driver1Street}
              component={Input}
              required={isFieldRequired(
                schema,
                SoftOfferOccasionalDriversFields.driver1Street,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_STREET')}
            />
          </Col>
          <Col md={8}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Number}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Number,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_NR')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Box}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Box,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_BOX')}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Tel}
                  component={MobileInput}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Tel,
                  )}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Gsm}
                  component={MobileInput}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Gsm,
                  )}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE_GSM')}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Fax}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Fax,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_FAX')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1Language}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(driver1Languages, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1Language,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_LANGUAGE')}
                />
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <FormField
              name={SoftOfferOccasionalDriversFields.driver1Email}
              component={Input}
              required={isFieldRequired(
                schema,
                SoftOfferOccasionalDriversFields.driver1Email,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_EMAIL')}
            />
            <FormField
              name={SoftOfferOccasionalDriversFields.driver1Job}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(driver1Jobs, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(
                schema,
                SoftOfferOccasionalDriversFields.driver1Job,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_JOB')}
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1LicenceAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1LicenceAt,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver1LicenceCategoryCode}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(
                    driver1LicenceCategoryCodes,
                    {
                      label: OlistItemOptionFields.label,
                      value: OlistItemOptionFields.value,
                    },
                  )}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver1LicenceCategoryCode,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC_CODE')}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <ScCollapse>
          <ScCollapsePanel
            key="occasionalDriver2"
            header={t('SOFT_OFFER_OCCASIONAL_DRIVER2')}
          >
            <Row gutter={ROW_SPACES}>
              <Col md={8}>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2LastName}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2LastName,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_LAST_NAME')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Firstname}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Firstname,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_FIRST_NAME')}
                    />
                  </Col>
                </Row>

                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2BirthdayAt}
                      component={DatePicker}
                      format={DATE_FORMAT}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2BirthdayAt,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_BIRTHDAY')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Sex}
                      component={Select}
                      loading={isLoading}
                      options={mapToSelectOptions<OlistItemOption>(driver2Sexes, {
                        label: OlistItemOptionFields.label,
                        value: OlistItemOptionFields.value,
                      })}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Sex,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_GENDER')}
                    />
                  </Col>
                </Row>

                <InputCityZipField
                  inputZipProps={{
                    id: SoftOfferOccasionalDriversFields.driver2ZipCode,
                    name: SoftOfferOccasionalDriversFields.driver2ZipCode,
                    required: isFieldRequired(
                      schema,
                      SoftOfferOccasionalDriversFields.driver2ZipCode,
                    ),
                    disabled: isDisabled,
                    label: t('SOFT_OFFER_ZIPCODE'),
                  }}
                  inputCityProps={{
                    id: SoftOfferOccasionalDriversFields.driver2Locality,
                    name: SoftOfferOccasionalDriversFields.driver2Locality,
                    required: isFieldRequired(
                      schema,
                      SoftOfferOccasionalDriversFields.driver2Locality,
                    ),
                    disabled: isDisabled,
                    label: t('SOFT_OFFER_TOWN'),
                  }}
                />

                <FormField
                  name={SoftOfferOccasionalDriversFields.driver2Street}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver2Street,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_STREET')}
                />
              </Col>
              <Col md={8}>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Number}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Number,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_NR')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Box}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Box,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_BOX')}
                    />
                  </Col>
                </Row>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Tel}
                      component={MobileInput}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Tel,
                      )}
                      countries={countriesPhoneCodeList}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      international={true}
                      addInternationalOption={false}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_PHONE')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Gsm}
                      component={MobileInput}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Gsm,
                      )}
                      countries={countriesPhoneCodeList}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      international={true}
                      addInternationalOption={false}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_PHONE_GSM')}
                    />
                  </Col>
                </Row>

                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Fax}
                      component={Input}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Fax,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_FAX')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2Language}
                      component={Select}
                      loading={isLoading}
                      options={mapToSelectOptions<OlistItemOption>(driver2Languages, {
                        label: OlistItemOptionFields.label,
                        value: OlistItemOptionFields.value,
                      })}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2Language,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_LANGUAGE')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <FormField
                  name={SoftOfferOccasionalDriversFields.driver2Email}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver2Email,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_EMAIL')}
                />

                <FormField
                  name={SoftOfferOccasionalDriversFields.driver2Job}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(driver2Jobs, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferOccasionalDriversFields.driver2Job,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_JOB')}
                />
                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2LicenceAt}
                      component={DatePicker}
                      format={DATE_FORMAT}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2LicenceAt,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC')}
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={SoftOfferOccasionalDriversFields.driver2LicenceCategoryCode}
                      component={Select}
                      loading={isLoading}
                      options={mapToSelectOptions<OlistItemOption>(
                        driver2LicenceCategoryCodes,
                        {
                          label: OlistItemOptionFields.label,
                          value: OlistItemOptionFields.value,
                        },
                      )}
                      required={isFieldRequired(
                        schema,
                        SoftOfferOccasionalDriversFields.driver2LicenceCategoryCode,
                      )}
                      disabled={isDisabled}
                      label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC_CODE')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ScCollapsePanel>
        </ScCollapse>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default OccasionalDriversForm;
