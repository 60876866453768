import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { City } from 'types/vendor';

const NAME = 'city';

interface State {
  readonly citiesListStatus: Status;
  readonly citiesList: City[];
}

const initialState: State = {
  citiesListStatus: Status.Idle,
  citiesList: [],
};

const actions = {
  setCitiesListStatus: createAction<State['citiesListStatus']>(
    getActionName(NAME, 'SET_CITIES_LIST_STATUS'),
  ),
  setCitiesList: createAction<State['citiesList']>(
    getActionName(NAME, 'SET_CITIES_LIST'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setCitiesListStatus, (state, action) => ({
    ...state,
    citiesListStatus: action.payload,
  }));
  builder.addCase(actions.setCitiesList, (state, action) => ({
    ...state,
    citiesList: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ city }: ApplicationState) => city,
    (state: State) => state,
  ),
  getListStatus: createSelector(
    ({ city }: ApplicationState) => city,
    (state: State) => state.citiesListStatus,
  ),
  getList: createSelector(
    ({ city }: ApplicationState) => city,
    (state: State) => state.citiesList,
  ),
};

export type { State as CityState };
export { actions as cityActions, reducer as cityReducer, selectors as citySelectors };
