import React, { FC, useCallback, useState } from 'react';
import { ScBody } from './ConfigurationImportModal.styles';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/modal';
import { ScModalTitle } from 'components/modalLike.styles';
import ConfigurationMarginModalLogin from './ConfigurationMarginModal.login';
import ConfigurationMarginModalData from './ConfigurationMarginModal.data';

enum Stage {
  login,
  data,
}

interface Props {
  onClose(): void;
}

const ConfigurationMarginModal: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [stage, setStage] = useState<Stage>(Stage.login);

  const handleLoginSuccess = useCallback(() => {
    setStage(Stage.data);
  }, []);

  return (
    <Modal variant="sm" center visible onCancel={onClose}>
      <ScModalTitle>{t('CONFIGURATION_MARGIN_MODAL_TITLE')}</ScModalTitle>

      {stage === Stage.login && (
        <ScBody>
          <ConfigurationMarginModalLogin onSuccess={handleLoginSuccess} />
        </ScBody>
      )}

      {stage === Stage.data && <ConfigurationMarginModalData />}
    </Modal>
  );
};

export default ConfigurationMarginModal;
