import styled from 'styled-components';
import Selector from 'components/form/Selector';

export const ScSelector = styled(Selector)`
  text-transform: capitalize;
`;

export const ScCustomLink = styled.a`
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
`;
