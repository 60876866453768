import React, { FC, useEffect, useState } from 'react';
import { ModelAction, ModelTotalsItem } from 'types/vendor';
import { get } from 'utils';
import { TotalPageAdaptedFields } from '../utils';
import { TFunction } from 'i18next';
import { TotalTableInput } from '../components/TotalTableInput';
import { TotalTableRow } from '../components/TotalTableRow';
import { MarginChangeParams } from '../Total';

interface Props {
  totalData: ModelTotalsItem | null;
  FIELDS: TotalPageAdaptedFields;
  t: TFunction;
  isLoading: boolean;
  isConfigurationComplete: boolean;
  handleBalanceChange(params: MarginChangeParams): void;
  currency: string;
  dealerAction: ModelAction | undefined;
  maxValue: number | undefined;
}

const Balance: FC<Props> = ({
  totalData,
  FIELDS,
  t,
  dealerAction,
  handleBalanceChange,
  currency,
  isConfigurationComplete,
  isLoading,
  maxValue,
}) => {
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    setBalance(get(totalData, FIELDS.balance, 0));
  }, [totalData, FIELDS.balance]);

  return (
    <TotalTableRow
      data-testid="total-page-balance"
      borderBottom="thin"
      textSize="large"
      fields={[
        t('TOTAL_HEADER_BALANCE'),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        <TotalTableInput
          key={'1'}
          size="large"
          readOnly={isLoading}
          value={balance}
          step={1}
          testNamePrefix="discount-input-adjusted-balance"
          disabled={isConfigurationComplete || !dealerAction}
          onBlur={event => {
            handleBalanceChange({ value: event.target.value, type: 'balance' });
          }}
          onChange={event => setBalance(event.target.value)}
          max={maxValue}
          inputPrefix={currency}
          symbolsAfterDecimal={2}
        />,
      ]}
      customTotalsTableColumnsWidths={[20, 15, 9, 9, 9, 9, 9, 20]}
    />
  );
};

export default Balance;
