import { setupStore } from './init';
import { FeatureFlagsState } from './featureFlags';
import { HostSettingsState } from './hostSettings';
import { AuthState } from './auth';
import { SharedState } from './shared';
import { StreamingState } from './streaming';
import { ConfigurationState } from './configuration';
import { DocumentTypesState } from './documentTypes';
import { SoftOffersState } from './softOffers';
import { DocumentsState } from './documents';
import { SharedOptionsState } from './sharedOptions';
import { ModelState } from './model';
import { ContactState } from './contact';
import { ProspectDossierState } from './prospectDossier';
import { CityState } from './city';
import { DocumentTemplatesState } from './documentTemplates';
import { StructureState } from './structure';
import { FinancingState } from './financing';

export type ApplicationState = {
  featuresFlags: FeatureFlagsState;
  hostSettings: HostSettingsState;
  auth: AuthState;
  shared: SharedState;
  streaming: StreamingState;
  configuration: ConfigurationState;
  documentTypes: DocumentTypesState;
  softOffers: SoftOffersState;
  documents: DocumentsState;
  sharedOptions: SharedOptionsState;
  model: ModelState;
  contact: ContactState;
  prospectDossier: ProspectDossierState;
  city: CityState;
  documentTemplates: DocumentTemplatesState;
  structure: StructureState;
  financing: FinancingState;
};

export const store = setupStore();

const dispatch = store.dispatch;

export { dispatch };

export { featuresFlagsActions, featuresFlagsSelectors } from './featureFlags';
export type { FeatureFlagsState } from './featureFlags';

export { hostSettingsActions, hostSettingsSelectors } from './hostSettings';
export type { HostSettingsState } from './hostSettings';

export { authActions, authSelectors } from './auth';
export type { AuthState } from './auth';

export { sharedActions, sharedSelectors } from './shared';
export type { SharedState } from './shared';

export { streamingActions, streamingSelectors } from './streaming';
export type { StreamingState } from './streaming';

export { configurationActions, configurationSelectors } from './configuration';
export type { ConfigurationState } from './configuration';

export { documentTypesActions, documentTypesSelectors } from './documentTypes';
export type { DocumentTypesState } from './documentTypes';

export { softOffersActions, softOffersSelectors } from './softOffers';
export type { SoftOffersState } from './softOffers';

export { documentsActions, documentsSelectors } from './documents';
export type { DocumentsState } from './documents';

export { sharedOptionsActions, sharedOptionsSelectors } from './sharedOptions';
export type { SharedOptionsState } from './sharedOptions';

export { modelActions, modelSelectors } from './model';
export type { ModelState } from './model';

export { contactActions, contactSelectors } from './contact';
export type { ContactState } from './contact';

export { prospectDossierActions, prospectDossierSelectors } from './prospectDossier';
export type { ProspectDossierState } from './prospectDossier';

export { cityActions, citySelectors } from './city';
export type { CityState } from './city';

export {
  documentTemplatesActions,
  documentTemplatesSelectors,
} from './documentTemplates';
export type { DocumentTemplatesState } from './documentTemplates';

export { structureActions, structureSelectors } from './structure';
export type { StructureState } from './structure';

export { financingActions, financingSelectors } from './financing';
export type { FinancingState } from './financing';
