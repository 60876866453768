import styled from 'styled-components';
import { Radio } from 'antd';
import { Input } from '../form/Input';

export const ScSourceText = styled.p`
  text-align: center;
`;

export const ScRadio = styled(Radio)`
  display: block;
  padding: 0 0 1em;
  font-weight: normal;
  .ant-radio-checked .ant-radio-inner,
  .ant-radio .ant-radio-inner {
    border-color: ${({ theme }) => theme.mainBorderColor};
    width: 1.5em;
    height: 1.5em;

    &::after {
      content: '\\2713';
      width: 1.375em;
      height: 1.375em;
      background-color: ${({ theme }) => theme.mainColor};
      color: ${({ theme }) => theme.mainColor_contrast};
      border-radius: 1.875em;
      top: 0;
      left: 0;
      transition: linear;
      line-height: 1.5em;
      text-align: center;
    }
  }
`;

export const ScRadioGroup = styled(Radio.Group)`
  display: block;
`;

export const ScButtonsContainer = styled.div`
  padding: 2em;
`;

export const ScSourceInput = styled(Input)<{ visible: boolean; shifted: boolean }>`
  ${props => {
    if (props.shifted) return `margin-left: 2.7em; width: calc(100% - 2.7em);`;
  }}
  overflow: hidden;
  max-height: ${props => (props.visible ? '1000px' : '0')};
  opacity: ${props => (props.visible ? 1 : 0)};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;
`;

export const ScBody = styled.div`
  padding: 2.5em 2em 2em;
`;
