import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { ScStepNumber, ScStepNumberIcon, ScStepNumberTitle } from './StepNumber.styles';
import { useTranslation } from 'react-i18next';
import { NotificationType, notification } from 'utils/notification';

type Props = {
  icon: React.ReactNode;
  titleTKey: string;
  link: string;
  position: number;
  disabled: boolean;
  number: number;
  active: boolean;
  className?: string;
  onClick(position: number): void;
};

const StepNumber: FC<Props> = ({
  link,
  icon,
  titleTKey,
  position,
  disabled,
  number,
  active,
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (!link) return;

    if (position === 0) {
      onClick?.(position);
      return;
    }

    onClick?.(position);
  }, [link, position, onClick]);

  return (
    <ScStepNumber
      onClick={handleClick}
      className={classNames('cp-show-flex cp-c-align-start-center', className, {
        active: active,
        disabled: disabled,
      })}
      data-testid="step-number-container"
    >
      <ScStepNumberIcon data-testid="step-icon-circle-content">
        {icon ? icon : number}
      </ScStepNumberIcon>
      {titleTKey && (
        <ScStepNumberTitle data-testid="step-number-title">
          {t(titleTKey)}
        </ScStepNumberTitle>
      )}
    </ScStepNumber>
  );
};

export default StepNumber;
