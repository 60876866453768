import React, {
  FC,
  useState,
  ElementType,
  ChangeEvent,
  ReactNode,
  useEffect,
} from 'react';
import {
  ScCloseButton,
  ScEditButton,
  ScButtonContainer,
  ScContentContainer,
  ScWrapper,
  ScInputContainer,
} from './TotalEditableField.styles';
import { isEqual } from 'utils';

interface Props {
  component: ElementType;
  componentProps?: Record<string, unknown>;
  value: number | string;
  formatValueForStaticView?(value: number | string): ReactNode;
  onChange(value: number | string): void;
}

const TotalEditableField: FC<Props> = ({
  component: Component,
  componentProps,
  value: outerValue,
  formatValueForStaticView,
  onChange: outerOnChange,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<number | string>();

  useEffect(() => setValue(outerValue), [isEditing, outerValue]);

  return (
    <ScWrapper {...props}>
      <ScContentContainer>
        {isEditing && (
          <ScInputContainer $isVisible={isEditing}>
            <Component
              {...componentProps}
              value={value}
              onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                if (!isEqual(value, outerValue)) outerOnChange(event.target.value);
                setIsEditing(false);
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
            />
          </ScInputContainer>
        )}

        {formatValueForStaticView?.(outerValue) ?? String(outerValue)}
      </ScContentContainer>
      <ScButtonContainer $isEditing={isEditing}>
        {isEditing ? (
          <ScCloseButton onClick={() => setIsEditing(false)} />
        ) : (
          <ScEditButton title="Edit" onClick={() => setIsEditing(true)} />
        )}
      </ScButtonContainer>
    </ScWrapper>
  );
};

export default TotalEditableField;
