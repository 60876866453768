import styled from 'styled-components';

export const ScDocumentListDetail = styled.div`
  padding-top: 1.25em;
  padding-left: 1.875em;
`;

export const ScButtonWrapper = styled.div`
  margin-top: 0.625em;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
`;
