import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { ScTableViewSwitcherButton } from './button.styles';
import { TableView } from './types';

interface Props {
  id: TableView;
  tooltipText: string;
  active: boolean;
  disabled: boolean;
  onClick(tableView: TableView): void;
}

const Button: FC<PropsWithChildren<Props>> = ({
  id,
  tooltipText,
  disabled,
  active,
  onClick,
  children,
}) => (
  <Tooltip placement="top" title={tooltipText}>
    <ScTableViewSwitcherButton
      role="button"
      onClick={() => onClick(id)}
      className={classnames({
        active,
        disabled,
      })}
      data-testid={`configuration-table-${id}-view-btn`}
    >
      {children}
    </ScTableViewSwitcherButton>
  </Tooltip>
);

export default Button;
