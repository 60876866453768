import { Status } from '../utils/types';
import { createAction } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { PayloadActionCreator } from '@reduxjs/toolkit/src/createAction';

export const getActionName = (NAME: string, SUB: string): string => `${NAME}/${SUB}`;

export const getBaseActions = <State extends Record<string, unknown>>(
  name: string,
): { set: PayloadActionCreator<State>; setStatus: PayloadActionCreator<Status> } => ({
  set: createAction<State>(getActionName(name, 'SET')),
  setStatus: createAction<Status>(getActionName(name, 'SET_STATUS')),
});

export type BaseState = {
  status: Status;
};

export const addBaseReducerCases = (
  builder: ActionReducerMapBuilder<BaseState>,
  actions: ReturnType<typeof getBaseActions>,
): void => {
  builder.addCase(actions.set, (state, action) => {
    return { ...state, ...action.payload };
  });
  builder.addCase(actions.setStatus, (state, action) => {
    state.status = action.payload;
  });
};
