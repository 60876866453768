import Icon, {
  BorderOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  SyncOutlined,
  MoreOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { ReactComponent as DiceIcon } from 'assets/icons/dice.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { STREAMING_TERMINAL_SYNC_PERIOD_IN_SECONDS } from 'common/constants';
import { Dropdown } from 'components/dropdown';
import Select from 'components/form/Selector';
import Checkbox from 'components/form/Checkbox';
import { Input } from 'components/form/Input';
import { Modal } from 'components/modal';
import { ConfirmationContainer } from 'components/table/ConfirmationContainer';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authSelectors, streamingSelectors } from 'store';
import { StreamingStatus, Terminal } from 'store/streaming';
import {
  ScAllTerminalsList,
  ScBusyTerminalActionIcon,
  ScBusyTerminalActionItem,
  ScBusyTerminalActionsList,
  ScBusyTerminalActionTitle,
  ScBusyTerminalInfo,
  ScBusyTerminalTitle,
  ScConfirmationContainerWrapper,
  ScSelectedTerminalList,
  ScStreamingCommonTerminalTitle,
  ScStreamingGenerateCustomerIdAction,
  ScStreamingModalBody,
  ScStreamingModalHeader,
  ScStreamingModalSearch,
  ScStreamingSearchAutocompleteWrapper,
  ScStremaingSyncAction,
  ScTerminalCheckboxWrapper,
  ScTerminalItem,
  ScTerminalSelectionWrapper,
  ScTerminalTerminalChronology,
  ScDropdown,
} from './index.styles';
import MoreOverlay from './MoreOverlay';
import useStreamingOpenMyTerminal from '../useStreamingOpenMyTerminal';

interface Props {
  configuratorId: number;
}

type TerminalCustomRender = (terminal: Terminal) => React.ReactNode;

type TerminalHandler = (customerId: string) => void;

type TerminalDisable = (terminal: Terminal, doesOwn: boolean) => boolean;

const StreamingSettings: FC<Props> = ({ configuratorId }) => {
  const [terminalCustomerIdSearchValue, setTerminalCustomerIdSearchValue] =
    useState<string>('');
  const [newCustomerId, setNewCustomerId] = useState<string>('');
  const [isNewCustomerIdDropdownOpen, setIsNewCustomerIdDropdownOpen] =
    useState<boolean>(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);

  const { t } = useTranslation();
  const { isModalOpen, closeModal, sendMessage, getScreen } = useStreaming();
  const { handleOpenMyTerminal } = useStreamingOpenMyTerminal();

  const baseURL = useSelector(authSelectors.getBaseUrl);
  const username = useSelector(authSelectors.getUsername);
  const host = baseURL?.split('.')[0].replace('https://', '');
  const allTerminals = useSelector(streamingSelectors.getAllTerminals);
  const activeTerminal = useSelector(streamingSelectors.getActiveTerminal);

  const terminals = allTerminals.filter(terminal =>
    terminal.customerId.includes(terminalCustomerIdSearchValue),
  );

  const getRemainingTimeUntilStatusUpdate = useCallback((date: Date) => {
    return (
      STREAMING_TERMINAL_SYNC_PERIOD_IN_SECONDS -
      Number(
        moment
          .duration(moment().diff(moment(date)))
          .asSeconds()
          .toFixed(),
      )
    );
  }, []);

  const toggleNewCustomerIdDropdown = useCallback(() => {
    setIsNewCustomerIdDropdownOpen(prevState => !prevState);
  }, []);

  const handleNewCustomerIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setNewCustomerId(newValue);
    },
    [],
  );

  const handleNewCustomerIdSubmit = useCallback(() => {
    sendMessage({
      type: StreamingEventType.GENERATE_CUSTOMER_ID,
      data: {
        customerId: newCustomerId,
      },
    });
    setIsNewCustomerIdDropdownOpen(false);
  }, [sendMessage, newCustomerId]);

  const handleSyncTerminals = useCallback(() => {
    if (!host) return;

    sendMessage({
      type: StreamingEventType.LIST_ALL_TERMINALS,
      data: {
        host,
      },
    });
  }, [host, sendMessage]);

  const startSessionForTerminal: TerminalHandler = useCallback(
    customerId => {
      sendMessage({
        type: StreamingEventType.START_STREAMING_FOR_TERMINAL,
        data: {
          customerId,
          initialScreen: getScreen(),
        },
      });
    },
    [sendMessage, getScreen],
  );

  const stopSessionForTerminal: TerminalHandler = useCallback(
    customerId => {
      sendMessage({
        type: StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
        data: {
          customerId,
        },
      });
    },
    [sendMessage],
  );

  const openTerminal: TerminalHandler = useCallback(
    customerId => {
      handleOpenMyTerminal(customerId);
    },
    [handleOpenMyTerminal],
  );

  const logoutTerminal: TerminalHandler = useCallback(
    customerId => {
      sendMessage({
        type: StreamingEventType.LOGOUT_TERMINAL,
        data: {
          customerId,
        },
      });
    },
    [sendMessage],
  );

  const deleteTerminal: TerminalHandler = useCallback(
    customerId => {
      sendMessage({
        type: StreamingEventType.DELETE_TERMINAL,
        data: {
          customerId,
        },
      });
    },
    [sendMessage],
  );

  const terminalActions: {
    // @todo: remove any
    // 1. use one component type for each "icon"
    // 2. find the type to map (probably in ANTD)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    customRender?: TerminalCustomRender;
    title: string;
    handler: TerminalHandler;
    disable: TerminalDisable;
  }[] = [
    {
      icon: RightOutlined,
      title: t('STREAMING_SESSION_START'),
      handler: startSessionForTerminal,
      disable: (terminal, doesOwn) => {
        if (doesOwn) return false;
        return terminal.status !== StreamingStatus.VACANT;
      },
    },
    {
      icon: BorderOutlined,
      title: t('STREAMING_SESSION_STOP'),
      handler: stopSessionForTerminal,
      disable: (terminal, doesOwn) => {
        if (terminal.customerId === username) return true;
        if (doesOwn) return false;
        return terminal.status !== StreamingStatus.VACANT;
      },
    },
    {
      icon: ExportOutlined,
      title: t('STREAMING_OPEN_ACTIVE_TERMINAL'),
      handler: openTerminal,
      disable: (terminal, doesOwn) =>
        !doesOwn && terminal.status === StreamingStatus.BUSY,
    },
    {
      icon: KeyIcon,
      title: t('STREAMING_SESSION_LOGOUT'),
      handler: logoutTerminal,
      disable: (terminal, doesOwn) => {
        const { status } = terminal;
        if (
          (status === StreamingStatus.BUSY && !doesOwn) ||
          status === StreamingStatus.VACANT
        )
          return true;
        return status === StreamingStatus.OFFLINE;
      },
    },
    {
      icon: DeleteOutlined,
      title: t('STREAMING_SESSION_DELETE'),
      handler: deleteTerminal,
      disable: terminal => {
        return terminal.status === StreamingStatus.BUSY;
      },
    },
    {
      customRender: terminal => (
        <ScDropdown
          trigger={['click']}
          overlay={() => {
            return (
              <MoreOverlay
                type={terminal?.flags?.type ?? 'static'}
                withSummaryPrice={activeTerminal?.flags?.withSummaryPrice ?? true}
                terminalStatus={terminal.status}
                customerId={terminal.customerId}
              />
            );
          }}
          placement="bottomLeft"
        >
          <ScBusyTerminalActionIcon
            component={MoreOutlined as React.ForwardRefExoticComponent<unknown>}
          />
        </ScDropdown>
      ),
      title: t('STREAMING_SESSION_MORE'),
      handler: () => {},
      disable: (terminal, doesOwn) => {
        if (doesOwn) return true;
        return terminal.status !== StreamingStatus.OFFLINE;
      },
    },
  ];

  useEffect(() => {
    if (isModalOpen) {
      sendMessage({
        type: StreamingEventType.LIST_ALL_TERMINALS,
        data: {
          host,
        },
      });
    }
  }, [sendMessage, host, isModalOpen]);

  return (
    <Modal variant="lg" center visible={isModalOpen} onCancel={closeModal}>
      <ScStreamingModalHeader>{t('STREAMING_MODAL_TITLE')}</ScStreamingModalHeader>
      <ScStreamingModalBody>
        <ScSelectedTerminalList>
          {allTerminals
            .filter(terminal => terminal.customerId === selectedCustomerId)
            .map(terminal => {
              return (
                <ScTerminalItem key={terminal.connectionId}>
                  <ScBusyTerminalInfo>
                    <ScBusyTerminalTitle>
                      {t('STREAMING_TERMINAL_ITEM_TITLE')}
                    </ScBusyTerminalTitle>
                    <ScStreamingCommonTerminalTitle
                      terminalCustomerId={terminal.customerId}
                      terminalConfiguratorId={terminal.configuratorId}
                      configuratorId={configuratorId}
                      status={terminal.status}
                    />
                  </ScBusyTerminalInfo>
                  <ScBusyTerminalActionsList>
                    {terminalActions.map(
                      ({ customRender, icon, title, handler, disable }) => {
                        return (
                          <ScBusyTerminalActionItem
                            key={title}
                            disabled={disable(
                              terminal,
                              terminal.configuratorId === configuratorId,
                            )}
                            onClick={() => handler(terminal.customerId)}
                          >
                            {customRender ? (
                              customRender(terminal)
                            ) : (
                              <ScBusyTerminalActionIcon component={icon} />
                            )}
                            <ScBusyTerminalActionTitle>{title}</ScBusyTerminalActionTitle>
                          </ScBusyTerminalActionItem>
                        );
                      },
                    )}
                  </ScBusyTerminalActionsList>
                </ScTerminalItem>
              );
            })}
        </ScSelectedTerminalList>
        <ScStreamingModalSearch>
          <ScStremaingSyncAction>
            <Icon
              component={SyncOutlined as React.ForwardRefExoticComponent<unknown>}
              onClick={handleSyncTerminals}
              disabled={!host}
            />
          </ScStremaingSyncAction>
          <ScStreamingSearchAutocompleteWrapper>
            <Select
              placeholder={
                terminalCustomerIdSearchValue
                  ? undefined
                  : t('STREAMING_SEARCH_PLACEHOLDER')
              }
              // the plan is to make API call to search for
              // filled terminial name
              options={[]}
              fullwidth
              open={false}
              showSearch
              searchValue={terminalCustomerIdSearchValue}
              onSearch={value => setTerminalCustomerIdSearchValue(value)}
            />
          </ScStreamingSearchAutocompleteWrapper>
          <ScStreamingGenerateCustomerIdAction>
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              visible={isNewCustomerIdDropdownOpen}
              onVisibleChange={toggleNewCustomerIdDropdown}
              overlay={
                <ScConfirmationContainerWrapper>
                  <ConfirmationContainer handleConfirm={handleNewCustomerIdSubmit}>
                    <Input
                      placeholder="Enter a name or generate"
                      suffix={<DiceIcon />}
                      value={newCustomerId}
                      onChange={handleNewCustomerIdChange}
                      name="newCustomemrId"
                    />
                  </ConfirmationContainer>
                </ScConfirmationContainerWrapper>
              }
            >
              <Icon
                component={PlusOutlined as React.ForwardRefExoticComponent<unknown>}
              />
            </Dropdown>
          </ScStreamingGenerateCustomerIdAction>
        </ScStreamingModalSearch>
        <ScAllTerminalsList>
          {terminals.map(terminal => {
            return (
              <ScTerminalItem key={terminal.customerId}>
                <ScTerminalSelectionWrapper>
                  <ScTerminalCheckboxWrapper>
                    <Checkbox
                      circle
                      checked={terminal.customerId === selectedCustomerId}
                      onChange={() => {
                        setSelectedCustomerId(terminal.customerId);
                      }}
                    />
                  </ScTerminalCheckboxWrapper>
                  <ScStreamingCommonTerminalTitle
                    terminalCustomerId={terminal.customerId}
                    terminalConfiguratorId={terminal.configuratorId}
                    configuratorId={configuratorId}
                    status={terminal.status}
                  />
                </ScTerminalSelectionWrapper>
                {terminal.lastConnectionClosed && (
                  <ScTerminalTerminalChronology>
                    {t('STREAMING_APPROXIMATE_STATUS_UPDATE', {
                      seconds: getRemainingTimeUntilStatusUpdate(
                        terminal.lastConnectionClosed,
                      ),
                    })}
                  </ScTerminalTerminalChronology>
                )}
                <ScTerminalTerminalChronology>
                  {terminal.lastLoginAt
                    ? t('STREAMING_LAST_SEEN_AT', {
                        at: moment(terminal.lastLoginAt).format('MMM DD,YYYY'),
                      })
                    : t('STREAMING_LAST_SEEN_NEVER')}
                </ScTerminalTerminalChronology>
              </ScTerminalItem>
            );
          })}
        </ScAllTerminalsList>
      </ScStreamingModalBody>
    </Modal>
  );
};

export default StreamingSettings;
