import React, { useCallback } from 'react';
import ImageGalleryModal from 'components/ImageGalleryModal';
import useStreamingCarGallery from 'components/streaming/useStreamingCarGallery';
import { useState } from 'react';
import { Tooltip } from 'antd';
import { RotatingLoader } from 'components/RotatingLoader';
import { ScPreviewContainer, ScPreviewIcon } from './CarPreview.styles';
import useRequiredCategory from 'hooks/useRequiredCategory';

const CarPreview: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const requiredCategoryName = useRequiredCategory();

  const {
    carouselImages,
    handleSlide,
    handleClose,
    title,
    error,
    isLoading,
    handlePreview,
  } = useStreamingCarGallery();

  const handleCancel = useCallback(() => {
    handleClose();
    setIsOpen(false);
  }, [handleClose]);

  return (
    <>
      <ScPreviewContainer role="presentation">
        <Tooltip placement="bottom" title={title}>
          {isLoading ? (
            <RotatingLoader />
          ) : (
            <ScPreviewIcon
              disabled={Boolean(requiredCategoryName || !!error)}
              onClick={() => {
                setIsOpen(true);
                handlePreview();
              }}
            />
          )}
        </Tooltip>
      </ScPreviewContainer>

      {isOpen && (
        <ImageGalleryModal
          images={carouselImages}
          visible={isOpen}
          onCancel={handleCancel}
          onSlide={handleSlide}
        />
      )}
    </>
  );
};

export default CarPreview;
