import styled from 'styled-components';
import Tabs from 'components/Tabs';
import { FC, PropsWithChildren, ComponentProps } from 'react';
import { Collapse } from 'antd';
import Color from 'color';

export const ScTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 2em 0 1em;
    &:before {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        justify-content: space-evenly;

        .ant-tabs-tab {
          padding: 0.625em 0;
          margin: 0 1em;
          border-right: unset;
          .ant-tabs-tab-btn {
            font-size: 1em;
          }
        }
      }
    }
  }

  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  .ant-tabs-content-holder {
    margin: 1em 2em 2em;
  }
`;

export const ScCheckboxFormFieldWrapper = styled('div')`
  .ant-form-item {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1em;

    .ant-form-item-label > label {
      margin-bottom: 0;
    }
  }

  .ant-form-item-control {
    max-width: 2em;
  }
`;

export const ScCollapse: FC<PropsWithChildren<ComponentProps<typeof Collapse>>> = styled(
  Collapse,
)`
  border: unset;
  background-color: transparent;
  border-radius: unset;
  .ant-collapse-item {
    background-color: ${({ theme }) => theme.colors.white};
    border-width: 0 0 1px;
    border-style: solid;
    border-color: ${({ theme }) =>
      Color(theme.colors.dustyGray).fade(0.75).hsl().string()};
    .ant-collapse-header {
      background-color: unset;
      display: flex;
      align-items: center;
      cursor: unset;
      padding-top: 1.6em;
      padding-bottom: 1.6em;
      .ant-form-item {
        display: inline-block;
        margin-bottom: unset;
        margin-right: 1em;
      }
      .ant-collapse-arrow {
        top: 20px;
      }
    }

    .ant-collapse-content {
      border-top: unset;

      padding-left: calc(3.75em + 12px - 16px);
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const ScCollapsePanel: FC<
  PropsWithChildren<ComponentProps<typeof Collapse.Panel>>
> = styled(Collapse.Panel)``;
