import {
  Model,
  ModelFields,
  ModelRemark,
  ModelRemarkFields,
  ModelRemarkTypes,
  ModelRemarkRoles,
  ModelRelation,
  ModelRelationFields,
  ModelCreatableAttributesObject,
  ModelUpdatableAttributesObject,
  ModelUpdateAttributesObjectFields,
  ModelCreatableAttributesObjectFields,
  ModelMachineConfigurationFields,
  ModelMachineConfiguration,
} from '@hypercharge/xdms-client/lib/types';
import { DATE_FORMAT } from 'common/constants';
import moment from 'moment';
import { get, isEqual, set } from 'utils';
import { CONFIG_CONTACT_RELATION_TYPE, DEFAULT_REMARK_ROLE } from 'utils/constants';
import { splitRemark } from 'utils/format';
import { InfoPageFormValues_Model, remarkInitialData } from './meta';

const extractRemarksFromModel = (
  model: Model,
): {
  internalRemark: ModelRemark | undefined;
  customerRemark: ModelRemark | undefined;
} => {
  const filteredRemarks = get(model, ModelFields.remark, []).filter(
    remark => get(remark, ModelRemarkFields.type) === ModelRemarkTypes.info,
  );

  const internalRemark = filteredRemarks.find(
    remark => get(remark, ModelRemarkFields.role) === ModelRemarkRoles.internal,
  );
  const customerRemark = filteredRemarks.find(remark => {
    const roleValue = get(remark, ModelRemarkFields.role);
    return (
      roleValue !== ModelRemarkRoles.external && roleValue !== ModelRemarkRoles.internal
    );
  });

  return { internalRemark, customerRemark };
};

const extractRelatedContactsFromModel = (model: Model): ModelRelation[] => {
  return (
    model[ModelFields.relation]?.filter(relation => {
      return relation[ModelRelationFields.relationType] === CONFIG_CONTACT_RELATION_TYPE;
    }) ?? []
  );
};

const mutateInRemarkToModifierObject = (
  object: ModelUpdatableAttributesObject | ModelCreatableAttributesObject,
  field:
    | ModelUpdateAttributesObjectFields.remark
    | ModelCreatableAttributesObjectFields.remark,
  remark: ModelRemark,
): void => {
  const elseRemarks = object[field] as ModelRelation[] | undefined;

  set(object, [field, elseRemarks?.length ?? 0], remark);
};

const prepareModelDataToUpdate = (
  model: Model,
  values: InfoPageFormValues_Model,
): {
  modelFieldsToUpdate: ModelUpdatableAttributesObject;
  modelFieldsToCreate: ModelCreatableAttributesObject;
} => {
  const machineConfiguration: ModelMachineConfiguration | undefined = get(model, [
    ModelFields.machineConfiguration,
    0,
  ]);
  const configurationNumber = get(
    machineConfiguration,
    ModelMachineConfigurationFields.configurationNumber,
  );

  const modelFieldsToUpdate: ModelUpdatableAttributesObject = {};
  const modelFieldsToCreate: ModelCreatableAttributesObject = {};

  // + machine configuration
  if (machineConfiguration) {
    const oldDeliveryDateValue = moment(
      machineConfiguration[ModelMachineConfigurationFields.deliveryDate],
    );
    const deliveryDateValue = moment(values.deliveryDate, DATE_FORMAT);

    const oldDeliveryDate = oldDeliveryDateValue.isValid()
      ? oldDeliveryDateValue.toDate()
      : null;
    const deliveryDate = deliveryDateValue.isValid() ? deliveryDateValue.toDate() : null;

    const oldCustomerReference =
      machineConfiguration[ModelMachineConfigurationFields.customerReference];

    if (
      oldCustomerReference !== values.customerReference ||
      !isEqual(oldDeliveryDate, deliveryDate)
    ) {
      modelFieldsToUpdate[ModelUpdateAttributesObjectFields.machineConfiguration] = {
        ...machineConfiguration,
        [ModelMachineConfigurationFields.deliveryDate]: values.deliveryDate,
        [ModelMachineConfigurationFields.customerReference]: values.customerReference,
      };
    }
  }
  // - machine configuration

  // + remarks
  const { internalRemark, customerRemark } = extractRemarksFromModel(model);

  const { title: internalTitle, description: internalDescription } = splitRemark(
    values.internalNotes,
  );
  const { title: customerTitle, description: customerDescription } = splitRemark(
    values.customerNotes,
  );

  const remarksToMapOver: [
    ModelRemark | undefined,
    [string, string],
    ModelRemarkRoles,
  ][] = [
    [internalRemark, [internalTitle, internalDescription], ModelRemarkRoles.internal],
    [
      customerRemark,
      [customerTitle, customerDescription],
      DEFAULT_REMARK_ROLE as ModelRemarkRoles,
    ],
  ];

  remarksToMapOver.forEach(([oldRemark, [title, description], role]) => {
    if (
      title === oldRemark?.[ModelRemarkFields.commentTitle] &&
      description === oldRemark?.[ModelRemarkFields.commentDescription]
    )
      return;

    const remark: ModelRemark = {
      ...remarkInitialData,
      ...oldRemark,
      [ModelRemarkFields.relationNumber]:
        oldRemark?.[ModelRemarkFields.relationNumber] ?? String(configurationNumber),
      [ModelRemarkFields.role]: role,
      [ModelRemarkFields.commentTitle]: title,
      [ModelRemarkFields.commentDescription]: description,
    };

    if (oldRemark) {
      mutateInRemarkToModifierObject(
        modelFieldsToUpdate,
        ModelUpdateAttributesObjectFields.remark,
        remark,
      );
      return;
    }

    if (!oldRemark && (title || description)) {
      mutateInRemarkToModifierObject(
        modelFieldsToCreate,
        ModelCreatableAttributesObjectFields.remark,
        remark,
      );
    }
  });
  // - remarks

  // + related contacts
  const relatedContacts = extractRelatedContactsFromModel(model);
  if (relatedContacts.length && !isEqual(relatedContacts, values.contactRoles)) {
    const updatedRelatedContacts: ModelRelation[] = values.contactRoles.filter(
      contact => {
        const initialRelatedContact = relatedContacts.find(
          initContact =>
            initContact[ModelRelationFields.relationCode] ===
            contact[ModelRelationFields.relationCode],
        );
        return !isEqual(contact, initialRelatedContact);
      },
    );

    modelFieldsToUpdate[ModelUpdateAttributesObjectFields.relation] =
      updatedRelatedContacts;
  }
  // - related contacts

  return { modelFieldsToUpdate, modelFieldsToCreate };
};

export const InfoPageUtils = {
  extractRemarksFromModel,
  extractRelatedContactsFromModel,
  prepareModelDataToUpdate,
};
