import React, { FC, useEffect } from 'react';
import { Modal } from 'components/modal';
import Icon from '@ant-design/icons';
import { ImageItem } from 'types/common';
import BaseImageGallery from 'components/ImageGallery';
import { ScModalCloseIcon, ScModelImageGalleryWrapper } from './index.styles';
import {
  IMAGE_GALLERY_THUMBNAIL_HEIGHT,
  IMAGE_GALLERY_THUMBNAIL_WIDTH,
} from 'common/constants';
import { NO_IMAGE_PATH } from 'utils/constants';

type Props = {
  visible: boolean;
  onCancel(): void;
  onToggle?(isOpen: boolean): void;
  onSlide?(index: number): void;
  images: ImageItem[];
};

const ImageGalleryModal: FC<Props> = ({
  visible,
  onCancel,
  onSlide,
  onToggle,
  images,
}) => {
  useEffect(() => {
    onToggle?.(true);

    return () => {
      onToggle?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      variant="lg"
      center
      visible={visible}
      maskClosable
      onCancel={onCancel}
      transparent
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
      closeIcon={<Icon component={ScModalCloseIcon} />}
    >
      <ScModelImageGalleryWrapper $width={images.length === 1 ? '80%' : '100%'}>
        <BaseImageGallery
          items={images.map(item => ({
            original: item.image || NO_IMAGE_PATH,
            thumbnail: item.image,
            thumbnailHeight: IMAGE_GALLERY_THUMBNAIL_HEIGHT,
            thumbnailWidth: IMAGE_GALLERY_THUMBNAIL_WIDTH,
          }))}
          previewRatio="16 / 9"
          thumbnailOnlyImage
          onSlide={onSlide}
          thumbnailNoImageHeight={IMAGE_GALLERY_THUMBNAIL_HEIGHT}
          infinite
          showThumbnails
          showNav
          thumbnailNoImageTransparent
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </ScModelImageGalleryWrapper>
    </Modal>
  );
};

export default ImageGalleryModal;
