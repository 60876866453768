import { Button, Button as ScButton } from 'components/button';
import styled, { css } from 'styled-components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const ScDocumentsFooterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 6.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: auto;
`;

const commonButtonStyles = css`
  margin: 0 1em;
  &.ant-btn {
    padding-left: 5em;
    padding-right: 5em;
  }
`;

export const ScSendQuoteButton = styled(ScButton)`
  ${commonButtonStyles}
`;

export const ScPrevStepButton = styled(Button)`
  ${commonButtonStyles}
`;

export const ScDownloadPdfButton = styled(ScButton)`
  ${commonButtonStyles}

  display: flex;
  align-items: center;

  & svg {
    margin-right: 0.5em;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &[disabled] {
    svg {
      opacity: 0.25;
    }
  }
`;

const commonRequiredItemStyles = css`
  height: 100%;
`;

export const ScCheckIcon = styled(CheckOutlined)`
  ${commonRequiredItemStyles}
`;

export const ScCloseIcon = styled(CloseOutlined)`
  ${commonRequiredItemStyles}
`;

export const ScListingItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const ScListingItemIconWrapper = styled.span`
  margin-right: 0.5em;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ScListingItemTextWrapper = styled.span`
  word-break: break-word;
`;
