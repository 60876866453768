import { TFunction, i18n, createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_LANGUAGES_WHITELIST,
  IS_DEVELOPMENT_MODE,
} from './constants';

export const createI18nInstance = (
  translationsLocation?: string,
  availableLanguages?: { code: string; label: string }[],
): { i18nInstance: i18n; initPromise: Promise<TFunction> } => {
  const i18nInstance = createInstance();

  i18nInstance.use(LanguageDetector);
  i18nInstance.use(Backend);

  const initPromise = i18nInstance.init({
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
    },
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: false,
    lowerCaseLng: true,
    supportedLngs:
      availableLanguages?.map(({ code }) => code) ?? DEFAULT_LANGUAGES_WHITELIST,
    load: 'languageOnly',
    fallbackLng: availableLanguages?.[0]?.code ?? DEFAULT_LANGUAGE,
    debug: IS_DEVELOPMENT_MODE,
    backend: {
      loadPath: translationsLocation ?? '/locales/{{lng}}/{{ns}}.json',
    },
  });

  return {
    i18nInstance,
    initPromise,
  };
};
