import React from 'react';
import { ScLabel, ScSeparator, ScWrapper } from './InputRange.styles';

type Props = {
  componentFrom: React.ReactNode;
  componentTo: React.ReactNode;
  label: string;
};

export const InputRangeGroup: React.FC<Props> = ({
  componentFrom,
  componentTo,
  label,
}) => {
  return (
    <>
      <ScLabel>{label}</ScLabel>
      <ScWrapper>
        {componentFrom}
        <ScSeparator></ScSeparator>
        {componentTo}
      </ScWrapper>
    </>
  );
};
