import React, { FC } from 'react';
import TotalTableCollapsibleListRow from '../../components/TotalTableCollapsibleListRow';
import { useTranslation } from 'react-i18next';
import { BannerSettings } from '../types';
import { totalDynamicSectionsRenderer } from '../totalDynamicSectionsRenderer';

type SpecificSettings = {
  children: BannerSettings[];
  titleTKey: string;
};

type Props = BannerSettings<SpecificSettings>;

const Accordion: FC<Props> = ({ children, titleTKey }) => {
  const { t } = useTranslation();

  return (
    <TotalTableCollapsibleListRow
      testIdPrefix={'dynamic-accordion'}
      headerFields={[
        t(titleTKey),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ]}
    >
      {totalDynamicSectionsRenderer(children)}
    </TotalTableCollapsibleListRow>
  );
};

export default Accordion;
