import { DocumentListItem_Status_Values } from '@hypercharge/xdms-client/lib/types';
import { DocumentFormValues } from './types';

export const getIntitials = (
  initials?: Partial<DocumentFormValues>,
): DocumentFormValues => ({
  type: '',
  typeCategory: '',
  files: [],
  url: '',
  names: [''],
  status: DocumentListItem_Status_Values.empty,
  ...initials,
});
