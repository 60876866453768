import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const ScHeaderWrapper = styled.div<{ withCursorPointer?: boolean }>`
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  padding: 0.5em;
  cursor: ${({ withCursorPointer = false }) =>
    withCursorPointer ? 'pointer' : 'default'};
  background: ${({ theme }) => theme.colors.white};
  margin-left: 0.625em;
`;

export const ScHeaderLeft = styled.span`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const ScHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  line-height: 0.938em;
`;

export const ScHeaderTitleText = styled.span`
  font-size: 0.75em;
`;

export const ScHeaderText = styled.span`
  width: calc(100% - 2.5rem);
  display: inline-flex;
  flex-direction: column;
  word-break: break-all;
  margin-left: 1em;
`;

export const ScHeaderCreatedAtDate = styled.span`
  font-size: 0.625em;
  color: ${({ theme }) => theme.colors.dustyGray};
`;

export const ScHeaderActionsWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  position: relative;
`;

export const ScHeaderActionButton = styled(Button)`
  display: inline-flex;
  align-items: center;
`;

const commonCaretStyles = css`
  font-size: 0.8em;
`;

export const ScCaretDownOutlined = styled(CaretDownOutlined)`
  ${commonCaretStyles}
`;

export const ScCaretRightOutlined = styled(CaretRightOutlined)`
  ${commonCaretStyles}
`;
