import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerSettings } from '../types';
import { useTotalPage } from '../../TotalPageProvider';
import { GLOBAL_DISCOUNTS_LIST } from 'common/constants';
import GlobalDiscountItem from '../../sections/GLobalDiscounts/GlobalDiscountItem';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';

type Props = BannerSettings;

/** @todo: probably {@link GLOBAL_DISCOUNTS_LIST} may be moved to flags */
const TotalGenericGlobalDiscounts: FC<Props> = () => {
  const { t } = useTranslation();

  const { updateGlobalDiscount, totalData } = useTotalPage();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);

  return (
    <>
      {GLOBAL_DISCOUNTS_LIST.map(({ code, tKey, valueField }) => (
        <GlobalDiscountItem
          key={code}
          t={t}
          initialValue={totalData?.[valueField] ? Number(totalData[valueField]) : 0}
          onChange={value => {
            updateGlobalDiscount(code, value);
          }}
          tKey={tKey}
          disabled={{
            percent: isConfigurationComplete,
          }}
        />
      ))}
    </>
  );
};

export default TotalGenericGlobalDiscounts;
