import {
  CustomerCommercialTypesListItemFields,
  CustomerTypeListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, Filter } from 'types/vendor';
import { Tooltip } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation-mark.svg';
import { FILTER_SIGNS } from 'common/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import getColumnSearchProps from 'components/table/helpers/getColumnSearchProps';
import { DataIndex } from 'components/table/types';
import { useCustomer } from 'context/customer/CustomerProvider';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { FeatureFlagColumnSettings } from 'types/common';
import { get } from 'utils';
import { ScLongText, ScLongTextWrapper } from './styles';

const columnKey = {
  // @todo: add to customer types
  IS_BLOCKED: CustomerFields.isBlocked,
  LAST_NAME: CustomerFields.name2,
  FIRST_NAME: CustomerFields.name,
  ID: CustomerFields.id,
  ADDRESS: CustomerFields.address,
  ZIP_CODE: CustomerFields.zip,
  CITY: CustomerFields.city,
  COUNTRY: CustomerFields.country,
  CUSTOMER_TYPE: CustomerFields.type,
  COMMERCIAL_TYPE: CustomerFields.commercialType,
};

type Params = {
  handleReset(dataIndex: DataIndex): Promise<void>;
  filters: Filter<CustomerFields>;
  setFilters(newFilters: Filter<CustomerFields>): void;
};

export const useColumns = (params: Params): ColumnsType<CustomerFields> => {
  const { handleReset, filters, setFilters } = params;

  const { getCustomers, customerCommercialTypes, customerTypes } = useCustomer();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[GlobalFeaturesFlagsFields.SearchCustomerColumns] as
      | FeatureFlagColumnSettings[]
      | undefined;

    if (!columns) return [];
    return columns;
  }, [globalFeatures]);

  const { t } = useTranslation();

  const aggregateByField = useCallback(
    (data: { [key: string]: unknown }[], from: string, to: string) => {
      return data.reduce<Record<string, string>>((res, cur) => {
        const fromValue = String(cur[from]);
        const toValue = String(cur[to]);
        res[fromValue] = toValue;
        return res;
      }, {});
    },
    [],
  );

  const commercialTypeCodeToName = useMemo<Record<string, string>>(() => {
    return aggregateByField(
      customerCommercialTypes,
      CustomerCommercialTypesListItemFields.code,
      CustomerCommercialTypesListItemFields.name,
    );
  }, [aggregateByField, customerCommercialTypes]);

  const customerTypeCodeToName = useMemo<Record<string, string>>(() => {
    return aggregateByField(
      customerTypes,
      CustomerTypeListItemFields.code,
      CustomerTypeListItemFields.description,
    );
  }, [aggregateByField, customerTypes]);

  const handleSearch = useCallback(
    async (selectedKeys, confirm, dataIndex) => {
      confirm({ closeDropdown: true });
      const preparedFilters = filters.filter(filter => get(filter, 'name') !== dataIndex);
      const filterValue = get(selectedKeys, 0, '');

      if (filterValue) {
        const sign =
          dataIndex === CustomerFields.id ? FILTER_SIGNS.EQUAL : FILTER_SIGNS.INCLUDES;

        preparedFilters.push({
          name: dataIndex,
          value: filterValue,
          sign: sign,
        });
      }

      setFilters(preparedFilters);
      await getCustomers(preparedFilters);
    },
    [filters, getCustomers, setFilters],
  );

  const keyToColumn: Record<string, ColumnType<CustomerFields>> = useMemo(() => {
    return {
      [columnKey.IS_BLOCKED]: {
        title: '',
        dataIndex: CustomerFields.isBlocked,
        key: CustomerFields.isBlocked,
        align: 'center' as const,
        width: '1em',
        className: 'without-padding',
        render: value => {
          if (!value) return null;

          return (
            <Tooltip
              placement="top"
              title={t('CUSTOMER_BLOCKED_TOOLTIP')}
              data-testid="customer-page-endUser-tab-blocked-customer-tooltip"
            >
              <ExclamationIcon />
            </Tooltip>
          );
        },
      },
      [columnKey.LAST_NAME]: {
        title: t('CLIENTS_LAST_NAME'),
        maxWidth: '12em',
        dataIndex: CustomerFields.name2,
        key: CustomerFields.name2,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.name2,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.FIRST_NAME]: {
        title: t('CLIENTS_FIRST_NAME'),
        maxWidth: '10em',
        dataIndex: CustomerFields.name,
        key: CustomerFields.name,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.name,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.ID]: {
        title: t('CUSTOMER_ID'),
        maxWidth: '14em',
        dataIndex: CustomerFields.id,
        key: CustomerFields.id,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.id,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.ADDRESS]: {
        title: t('CLIENTS_ADDRESS'),
        maxWidth: '10em',
        dataIndex: CustomerFields.address,
        key: CustomerFields.address,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.address,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.ZIP_CODE]: {
        title: t('CLIENTS_ZIP'),
        maxWidth: '10em',
        dataIndex: CustomerFields.zip,
        key: CustomerFields.zip,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.zip,
          filters: filters,
          handleReset,
          handleSearch,
        }),
      },
      [columnKey.CITY]: {
        title: t('CLIENTS_CITY'),
        maxWidth: '8em',
        dataIndex: CustomerFields.city,
        key: CustomerFields.city,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.city,
          filters: filters,
          handleReset,
          handleSearch: handleSearch,
        }),
      },
      [columnKey.COUNTRY]: {
        title: t('CLIENTS_COUNTRY'),
        maxWidth: '10em',
        dataIndex: CustomerFields.country,
        key: CustomerFields.country,
        ...getColumnSearchProps({
          dataIndex: CustomerFields.country,
          filters: filters,
          handleReset,
          handleSearch: handleSearch,
        }),
      },
      [columnKey.CUSTOMER_TYPE]: {
        title: t('CUSTOMER_TYPE'),
        maxWidth: '10em',
        align: 'center',
        dataIndex: CustomerFields.type,
        key: CustomerFields.type,
        render: value => {
          return (
            <Tooltip placement="top" title={customerTypeCodeToName[value]}>
              <ScLongTextWrapper>
                <ScLongText>{value}</ScLongText>
              </ScLongTextWrapper>
            </Tooltip>
          );
        },
      },
      [columnKey.COMMERCIAL_TYPE]: {
        title: t('COMMERCIAL_TYPE'),
        maxWidth: '14em',
        align: 'center',
        dataIndex: CustomerFields.commercialType,
        key: CustomerFields.commercialType,
        render: value => {
          return (
            <Tooltip placement="top" title={commercialTypeCodeToName[value]}>
              <ScLongTextWrapper>
                <ScLongText>{value}</ScLongText>
              </ScLongTextWrapper>
            </Tooltip>
          );
        },
      },
    };
  }, [
    commercialTypeCodeToName,
    customerTypeCodeToName,
    filters,
    handleReset,
    handleSearch,
    t,
  ]);

  return useMemo<ColumnsType<CustomerFields>>(() => {
    let columns: ColumnsType<CustomerFields> = [];

    if (columnsSettings) {
      const columnKeysToRender: string[] = columnsSettings?.map(
        columnSettings => columnSettings.name,
      );
      columns = columnKeysToRender.map(key => keyToColumn[key]).filter(Boolean);
    } else {
      columns = Object.values(keyToColumn).filter(Boolean);
    }

    // exclude 'undefined's
    columns = columns.filter(Boolean);

    return columns;
  }, [columnsSettings, keyToColumn]);
};
