import styled from 'styled-components';

export const ScStub = styled.div`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  box-shadow: 0px 5px 68px rgba(0, 0, 0, 0.08);
  width: 13.125em;
  height: 3.125em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  line-height: 0.875em;
  color: ${({ theme }) => theme.colors.dustyGray};
  font-weight: 600;

  & > span {
    margin-right: 5px;
  }
`;
