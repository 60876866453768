import { useCallback, useEffect, useRef } from 'react';
import useIsMounted from './useIsMounted';
import { debounce } from 'utils';

export default function useDebounce<R, A extends unknown[]>(
  cb: (...args: A) => R,
  delay: number,
  options: Partial<{
    leading: boolean;
    trailing: boolean;
  }> = {},
): (...args: A) => R | void {
  const inputsRef = useRef<{ cb: (...args: A) => R; delay: number }>({ cb, delay });
  const isMounted = useIsMounted();

  useEffect(() => {
    inputsRef.current = { cb, delay };
  }, [cb, delay]);

  return useCallback(
    debounce(
      (...args: A) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted()) {
          return inputsRef.current.cb(...args);
        }
      },
      delay,
      {
        leading: false,
        trailing: true,
        ...options,
      },
    ),
    [delay, debounce, isMounted],
  );
}
