import React, { FC, useReducer, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ConfigurationStatus, ConfigurationStatusUpdateReason } from 'types/vendor';
import { Button } from 'components/button';
import { TextArea } from 'components/form/TextArea';
import { DATE_FORMAT } from 'common/constants';
import { DatePicker } from 'components/form/DatePicker';
import { Modal } from 'components/modal';
import { ScModalTitle } from 'components/modalLike.styles';
import {
  ScBody,
  ScLine,
  ScLineNested,
  ScLineContentGrouped,
  ScFooter,
} from './ChangeStatusModal.styles';
import Checkbox from 'components/form/Checkbox';
import { StateActionType, reducer, ValueType } from './ChangeStatusModal.reducer';

interface Props {
  initialValues?: {
    statusId?: string;
    reasonId?: string;
    reasonText?: string;
    date?: string;
  };

  configurationNumber: number | string;
  statusList: ConfigurationStatus[];
  reasonList: ConfigurationStatusUpdateReason[];
  onClose(): void;
  onSubmit(args: {
    configurationNumber: string | number;
    statusId: string;
    reasonId?: string;
    reasonText?: string;
    date?: string;
  }): void;
}

export const ChangeStatusModal: FC<Props> = ({
  initialValues,
  configurationNumber,
  statusList,
  reasonList,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({
      type: StateActionType.init,
      statusList: statusList,
      saleReasonList: reasonList,
      initialValues: initialValues,
    });
  }, [statusList, reasonList, onSubmit, initialValues]);

  const handleSubmit = useCallback(() => {
    const selectedStatus = state.find(({ isSelected }) => isSelected);
    if (!selectedStatus) return;
    const selectedReason = selectedStatus.data?.reasonList?.find(
      ({ isSelected }) => isSelected,
    );

    onSubmit({
      configurationNumber,
      statusId: selectedStatus.statusId,
      date: selectedStatus.data?.date,
      reasonId: selectedReason?.reasonId,
      reasonText: selectedReason?.text,
    });
  }, [state, onSubmit, configurationNumber]);

  const onStatusSelect = useCallback<(args: { statusId: string }) => void>(
    ({ statusId }) => {
      dispatch({
        type: StateActionType.setIsSelected,
        statusId: statusId,
        isSelected: true,
      });
    },
    [dispatch],
  );

  const onDateChange = useCallback<(args: { statusId: string; date: string }) => void>(
    ({ statusId, date }) => {
      dispatch({
        type: StateActionType.setDate,
        statusId: statusId,
        date: date,
      });
    },
    [dispatch],
  );

  const onReasonSelect = useCallback<
    (args: { statusId: string; reasonId: string }) => void
  >(
    ({ statusId, reasonId }) => {
      dispatch({
        type: StateActionType.setIsReasonSelected,
        statusId: statusId,
        reasonId: reasonId,
        isSelected: true,
      });
    },
    [dispatch],
  );

  const onReasonTextChange = useCallback<
    (args: { statusId: string; reasonId: string; text: string }) => void
  >(
    ({ statusId, reasonId, text }) => {
      dispatch({
        type: StateActionType.setReasonText,
        statusId: statusId,
        reasonId: reasonId,
        text: text,
      });
    },
    [dispatch],
  );

  return (
    <Modal variant="sm" visible onCancel={onClose}>
      <ScModalTitle>{t('SELECT_STATUS_YOU_WANT_APPLY')}</ScModalTitle>
      <ScBody>
        {state.map(stateItem => (
          <ScLine key={stateItem.label}>
            <Checkbox
              checked={stateItem.isSelected}
              onChange={() => onStatusSelect({ statusId: stateItem.statusId })}
              circle
            >
              {stateItem.label}
            </Checkbox>
            {stateItem.valueType === ValueType.date && (
              <ScLineNested>
                <DatePicker
                  defaultValue={
                    stateItem.data?.date ? moment(stateItem.data.date) : undefined
                  }
                  onChange={event => {
                    if (!event) return;
                    onDateChange({
                      statusId: stateItem.statusId,
                      date: event.target.value,
                    });
                  }}
                  format={DATE_FORMAT}
                  inputReadOnly
                />
              </ScLineNested>
            )}
            {stateItem.valueType === ValueType.reason &&
              stateItem.data?.reasonList?.map(reason => (
                <ScLineContentGrouped key={reason.reasonId}>
                  <Checkbox
                    checked={reason.isSelected}
                    onChange={() => {
                      onReasonSelect({
                        statusId: stateItem.statusId,
                        reasonId: reason.reasonId,
                      });
                    }}
                    circle
                  />
                  <TextArea
                    onClick={() => {
                      onReasonSelect({
                        statusId: stateItem.statusId,
                        reasonId: reason.reasonId,
                      });
                    }}
                    onChange={event => {
                      onReasonTextChange({
                        statusId: stateItem.statusId,
                        reasonId: reason.reasonId,
                        text: event.target.value,
                      });
                    }}
                    defaultValue={reason.text}
                    placeholder={t('SPECIFY_THE_REASON')}
                    label=""
                  />
                </ScLineContentGrouped>
              ))}
          </ScLine>
        ))}
      </ScBody>

      <ScFooter>
        <Button
          disabled={!state.some(({ isSelected }) => isSelected)}
          onClick={handleSubmit}
          fullwidth
          variant="primary"
        >
          {t('CONFIRM')}
        </Button>
      </ScFooter>
    </Modal>
  );
};
