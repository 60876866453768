import {
  DocumentListItem,
  DocumentUploadLinkCallParams,
  DocumentLocation,
  ModelRecord,
  ModelTradeIn,
  TradeIn as TradeInListItem,
  DocumentDetails,
  Model,
  SendEmailInput,
  DocumentRelation,
} from 'types/vendor';
import { DocumentRelationLeveled } from '@hypercharge/xdms-client/lib/_backendMethods/common/documentRelationLeveled';
import { UploadFile } from 'antd/lib/upload/interface';
import { TradeIn } from 'context/tradeIn/TradeInProvider';

export type DocumentsSwarm = Partial<
  Record<
    DocumentSource,
    {
      code: string | null;
      name: string | null;
      documents: DocumentItem[];
    }[]
  >
>;

export type DocumentRelatedEntity =
  | ModelRecord
  | TradeInListItem
  | ModelTradeIn
  | TradeIn
  | undefined;

export enum DocumentRelatedEntityCode {
  option = 'option',
  accessory = 'accessory',
  tAc = 'termsAndConditions',
  // todo: unify these records
  /** TradeIn from model */
  tradeIn = 'tradeIn',
  /** TradeIn from procedure */
  tradeInDedicated = 'tradeInDedicated',
  configuration = 'configuration',
  package = 'package',
}

export enum DocumentSource {
  OPTIONS = 'options',
  ACCESSORIES = 'accessories',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  TRADE_INS = 'tradeIns',
  CONFIGURATION = 'configuration',
  PACKAGES = 'packages',
}

export type DocumentRelatedEntitySettings = {
  entityCode: DocumentRelatedEntityCode;
  documentSource: DocumentSource;
  availabilityFeatureFlag: string;
  modalsTKey: string;
  documentsPageTKey: string;
  nameField: string;
  idField: string;
  defaultTypeCategoriesPicklistCode: string;

  getTypeCategoriesPicklistCode(documentRecord?: DocumentListItem): string;
  findModelItem: ((model: Model, id: string) => DocumentRelatedEntity) | null;
  assembleRelation_default(
    configurationNumber: number,
    entity?: DocumentRelatedEntity,
  ): DocumentRelation;
  assembleRelation_details(document: DocumentListItem | DocumentItem): DocumentRelation;
  assembleRelation_leveled(
    configurationNumber: number,
    entity?: DocumentRelatedEntity,
  ): DocumentRelationLeveled;
  assembleExternalStorageFilePath(
    configurationNumber: number,
    name: string,
    entity?: DocumentRelatedEntity,
  ): string;
};

/** Merges {@link DocumentListItem} and {@link ConfigurationDocListItem} */
export type DocumentItem = Omit<
  DocumentListItem,
  'location' | 'changedBy' | 'isEditable'
> & {
  /** Absent on {@link ConfigurationDocListItem} */
  changedBy?: string;
  /** Absent on {@link ConfigurationDocListItem} */
  isEditable?: boolean;

  computed: {
    /** Normally we would use this field as a mark if its a link, or file
     * But ist absent on {@link ConfigurationDocListItem}, so have to
     * acknowledge it by file url itself
     */
    location: DocumentLocation;
    isFile: boolean;
    isLink: boolean;
    isDeleteVisible: boolean;
    isDraggable: boolean;
    isSelectable: boolean;
    isEditable?: boolean;
    createdAt: string;
    isDownloadVisible: boolean;
    isCopyVisible: boolean;
    isOpenVisible: boolean;
  };
};

export type DocumentItemDetails = DocumentDetails & {
  computed: {
    isFile: boolean;
    isLink: boolean;
    isEditable: boolean;
  };
};

export type DocumentUploadLinkParams = Omit<
  DocumentUploadLinkCallParams,
  'relationCode' | 'relationType' | 'configurationNumber' | 'usernamePrefix' | 'relation'
>;

export type DocumentUploadFileParams = Omit<
  DocumentUploadLinkCallParams,
  | 'relationCode'
  | 'relationType'
  | 'configurationNumber'
  | 'usernamePrefix'
  | 'relation'
  | 'url'
> & {
  file: UploadFile;
};

export type DocumentUpdateParams = Partial<DocumentDetails>;

export type SendEmailQuoteParams = Pick<
  SendEmailInput,
  'emailFrom' | 'emailTo' | 'emailCC' | 'emailBCC' | 'subject' | 'body'
> & {
  // overrides native
  documentIds: (number | string)[];
};
