import { Input as AntInput } from 'antd';
import styled from 'styled-components';
import { basicInputStyle, inputStyles, readOnlyStyleInput } from './styles';

export const ScInput = styled(AntInput)`
  input {
    ${basicInputStyle}
  }

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.mainColor};
  }

  ${({ readOnly }) => readOnly && readOnlyStyleInput}

  ${({ style }) =>
    style
      ? `
    &.ant-input {
      ${style}
    }
  `
      : ''}

  &.ant-input-affix-wrapper {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
  }

  .ant-input-suffix {
    margin-right: 0.75em;
  }

  &.ant-input[readonly],
  &.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
  }

  &.dark-border {
    border-color: ${({ theme }) => theme.mainBorderColor};
  }

  &.primary-border-color {
    border-color: ${({ theme }) => theme.mainColor};
  }

  &.discount-input {
    border-top: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-left: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-bottom: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-right: none;
  }

  &.ant-input-sm.ant-input-sm {
    padding: 0.25rem;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ScInputContainer = styled.div`
  .form-input-disabled {
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  ${inputStyles}
`;

export const ScLabel = styled.label`
  ${({ style }) => `${style}` || ''}
  margin-bottom: 0.438em;
`;
