import { STORAGE_KEYS } from 'common/constants';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  configurationSelectors,
  featuresFlagsSelectors,
  hostSettingsSelectors,
  sharedSelectors,
} from 'store';
import { saveInLocalStorage } from 'utils/storage';
import useUnsetCurrentConfiguration from './useUnsetCurrentConfiguration';
import { useModelApi } from 'context/model/useModelApi';
import { ConfDetailsFields } from 'types/vendor';
import { NotificationType, notification } from 'utils/notification';
import { useTranslation } from 'react-i18next';
import { useRelations } from 'context/relations/RelationsProvider';

type Result = {
  handleOpenConfiguration(
    configurationNumber: number | string,
    options?: Partial<{ skipRedirect: boolean }>,
  ): ReturnType<ReturnType<typeof useConfiguration>['getConfigurationDetailsById']>;
};

export default function useOpenConfiguration(): Result {
  const history = useHistory();
  const { t } = useTranslation();
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const { sendMessage } = useStreaming();

  const { getConfigurationDetailsById, assembleConfigurationUrlByConfigurationNumber } =
    useConfiguration();
  const { steps, handleStepClick } = useStep();
  const { unsetCurrentConfiguration } = useUnsetCurrentConfiguration();
  const { getModel } = useModelApi();
  const { restoreRelations } = useRelations();

  const { configurationNumber } = useSelector(configurationSelectors.getAll);
  const customerId = useSelector(hostSettingsSelectors.getCustomerId);
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const handleOpenConfiguration = useCallback<Result['handleOpenConfiguration']>(
    async (newConfigurationNumber: number | string, options) => {
      const { skipRedirect = false } = options || {};

      let result: Awaited<
        ReturnType<ReturnType<typeof useConfiguration>['getConfigurationDetailsById']>
      > = null;

      if (configurationNumber !== newConfigurationNumber) {
        unsetCurrentConfiguration();

        result = await getConfigurationDetailsById({
          configurationNumber: newConfigurationNumber,
        });

        if (!result) {
          notification.open({
            type: NotificationType.error,
            message: t('CONFIGURATION_NOT_FOUND'),
          });
          return null;
        }

        await restoreRelations(result, globalFeatures ?? {}, t, customerId);

        saveInLocalStorage(STORAGE_KEYS.configurationNumber, newConfigurationNumber);

        const { configuration } = result;
        await getModel({
          modelNumber: configuration[ConfDetailsFields.modelNumber],
          configurationNumber: newConfigurationNumber,
          catalogCode: configuration[ConfDetailsFields.catalogCode],
          versionNumber: configuration[ConfDetailsFields.versionNumber],
          withVAT: shouldShowPricesWithVAT,
        });
      }

      sendMessage({
        type: StreamingEventType.CLEAR_SLOTS,
      });

      if (!skipRedirect) {
        const url = await assembleConfigurationUrlByConfigurationNumber(
          newConfigurationNumber,
        );

        history.push(url);

        const step = steps.find(step => url.includes(step.link));

        handleStepClick(step ? step.position : 0);
      }

      return result;
    },
    [
      configurationNumber,
      sendMessage,
      unsetCurrentConfiguration,
      getConfigurationDetailsById,
      restoreRelations,
      t,
      customerId,
      globalFeatures,
      getModel,
      shouldShowPricesWithVAT,
      assembleConfigurationUrlByConfigurationNumber,
      history,
      steps,
      handleStepClick,
    ],
  );

  return { handleOpenConfiguration };
}
