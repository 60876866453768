import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { theme } from 'common/theme';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import Table from 'components/table';

export const DotsIcon = props => <Icon component={Dots} {...props} />;

export const RightComponent = styled('div')`
  margin-left: auto;
  margin-right: 0.625em;
`;

export const TableEmphasizedGreenText = styled('span')`
  font-size: 0.875em;
  line-height: 1.063em;
  color: ${() => theme.discountColor};
`;

export const ScTable = styled(Table)`
  padding-bottom: 200px;
  padding-top: 1.875em;

  && {
    .ant-table-thead > tr > th.ant-table-cell,
    .ant-table tfoot > tr > th.ant-table-cell {
      padding: 0.75em 0.5em;
    }
  }

  .ant-table-cell {
    font-size: 0.875em;
  }

  .ant-table-cell span.anticon {
    margin-right: 0.5em;
  }

  /* these styles make icon not inline element, but needed for tablet responsiveness
  so :not(...) removes icon from styles selection */
  th.ant-table-cell span:not(.anticon) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
