import React, { FC, useCallback, useMemo } from 'react';
import { ConfigurationRelation } from 'context/relations/types';
import { useTranslation } from 'react-i18next';
import { BaseRelationTransactionModal } from './BaseRelationTransactionModal/BaseRelationTransactionModal';
import { useRelations } from 'context/relations/RelationsProvider';
import Icon from '@ant-design/icons';
import { ReactComponent as Swap } from 'assets/icons/swap.svg';

export const SwapIcon = props => <Icon component={Swap} {...props} />;

interface Props {
  isOpen: boolean;
  activeRelationId?: string;
  relations: ConfigurationRelation[];
  onClose(): void;
}
export type SwapRelationsModalProps = Props;
export const SwapRelationsModal: FC<Props> = props => {
  const { isOpen, relations, onClose, activeRelationId } = props;
  const { t } = useTranslation();
  const { swapRelationsConfiguration } = useRelations();

  const handleChange = useCallback(
    relationId => {
      if (activeRelationId && relationId) {
        swapRelationsConfiguration(activeRelationId, relationId);
      }
      onClose();
    },
    [onClose, swapRelationsConfiguration, activeRelationId],
  );

  const options = useMemo<ConfigurationRelation[]>(() => {
    return relations
      .filter(relation => relation.customer)
      .filter(relation => relation.id !== activeRelationId);
  }, [relations, activeRelationId]);

  const active = useMemo<ConfigurationRelation | undefined>(() => {
    return relations.find(relation => relation.id === activeRelationId);
  }, [relations, activeRelationId]);

  if (!isOpen) return null;

  return (
    <BaseRelationTransactionModal
      title={t('SWAP_RELATION')}
      options={options}
      onSubmit={handleChange}
      active={active}
      onClose={onClose}
      icon={<SwapIcon />}
    />
  );
};
