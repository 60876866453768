import { ParsedQuery } from 'utils/urlTransform';
import { STRUCTURE_LABEL } from '../../common/constants';

/**
 * Remove all model subStep's selected values from query
 * @param {ParsedQuery} query
 * @return {ParsedQuery}
 */
export const removeAllLabelsQueryParams = (query: ParsedQuery): ParsedQuery => {
  return Object.entries(query).reduce<ParsedQuery>((acc, [key, value]) => {
    if (!key.includes(STRUCTURE_LABEL)) acc[key] = value;

    return acc;
  }, {});
};
