import {
  Model_CommonCategory,
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { getCategoryItems } from './getCategoryItems';

/** @note In this case configured - means that at least one item is selected by user (not defaultSelected) */
export const isCategoryConfigured = (
  category: Model_CommonCategory,
  itemsList: Model_CommonItem[],
): boolean => {
  return getCategoryItems(category, itemsList).some(item => {
    if (item[Model_CommonItemFields.defaultOption]) return false;
    return item[Model_CommonItemFields.selected];
  });
};
