import React, { FC, ReactNode, PropsWithChildren } from 'react';
import { Col, Row } from 'antd';
import Container from 'components/container/Container';
import {
  ScAuthLayout,
  ScFormWrap,
  ScHeaderWrap,
  ScImg,
  ScLogoLink,
  ScTitle,
} from './AuthLayout.styles';
import { HostSettingsExternal } from 'common/hostsSettings';
import {
  DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE,
  DEFAULT_LOGO,
  INDEX_URL,
} from 'common/constants';
import Loading from 'components/Loader/Loading';
import { ScSpin } from '../Default/Layout.styles';
import { useMediaQuery } from 'react-responsive';
import { sizes } from 'common/theme';

interface Props {
  background?: HostSettingsExternal['background'];
  title?: string;
  header?: ReactNode;
  isLoading?: boolean;
  logo?: {
    src: string;
    redirect: string;
    alt?: string;
  };
}

const AuthLayout: FC<PropsWithChildren<Props>> = ({
  children,
  background,
  title,
  header,
  isLoading,
  logo = {
    src: DEFAULT_LOGO,
    redirect: INDEX_URL,
  },
}) => {
  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  const backgroundSettings = {
    background: `url(${DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE})`,
    ...background,
  };

  // for md and lower screen sizes we assume that we have large enough images
  // so that they cover automatically all horizontal space
  if (isLgAndWider) {
    backgroundSettings.backgroundSize = 'cover';
  }

  return (
    <ScAuthLayout $background={backgroundSettings}>
      <ScSpin
        indicator={<Loading />}
        size="large"
        spinning={isLoading}
        data-testid="loading-indicator"
      />

      <Container size="md">
        <Row>
          <Col md={{ offset: 16, span: 8 }}>
            <ScFormWrap>
              {header && <ScHeaderWrap>{header}</ScHeaderWrap>}
              {logo && (
                <ScLogoLink to={logo.redirect}>
                  <ScImg
                    src={logo.src}
                    alt={logo.alt}
                    data-testid="unauthenticated-pages-logo"
                  />
                </ScLogoLink>
              )}
              {title && <ScTitle>{title}</ScTitle>}
              {children}
            </ScFormWrap>
          </Col>
        </Row>
      </Container>
    </ScAuthLayout>
  );
};

export default AuthLayout;
