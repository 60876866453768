import {
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { get } from 'utils';

/**
 * Sorts string values for Dynamic Page table
 *
 * @param a {Model_CommonItem} - prev item
 * @param b {Model_CommonItem} - next item
 * @param sortingField {Model_CommonItemFields} - field to sort by
 * @returns {number}
 */
export const sortStringValues = (
  a: Model_CommonItem,
  b: Model_CommonItem,
  sortingField: Model_CommonItemFields,
): number => {
  const aOperand = get(a, sortingField) as string;
  const bOperand = get(b, sortingField) as string;
  return aOperand.localeCompare(bOperand);
};
