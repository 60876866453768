import { useCallback, useMemo } from 'react';
import { Status } from 'utils/types';
import { XdmsClient, useXdmsClient } from '../xdms/XdmsClient';
import { RequestResponseDetails } from '../../types/common';
import { notification } from '../../utils/notification';
import {
  FinancingDetailsFields,
  FinancingListItemFields,
  FinancingDetails,
  FinancingCalculation,
  FinancingListItem,
  FinancingProduct,
} from 'types/vendor';
import { useDispatch, useSelector } from 'react-redux';
import { financingActions, financingSelectors } from 'store';

const updateDetailsKeys__mapping__requestNames: Record<
  keyof Pick<
    FinancingDetails,
    FinancingDetailsFields.customer | FinancingDetailsFields.carInfo
  >,
  keyof Pick<XdmsClient['configurationCredit'], 'updateCustomer' | 'updateCarInfo'>
> = {
  [FinancingDetailsFields.customer]: 'updateCustomer',
  [FinancingDetailsFields.carInfo]: 'updateCarInfo',
};

export interface Values {
  createFinancingRecord(
    configurationNumber: number | string,
  ): Promise<RequestResponseDetails<number>>;
  updateFinancingDetails(
    configurationNumber: number | string,
    oldRecord: Partial<FinancingDetails>,
    newRecord: Partial<FinancingDetails>,
  ): Promise<RequestResponseDetails>;
  deleteFinancingRecord(
    configurationNumber: number | string,
    record: FinancingListItem,
  ): Promise<RequestResponseDetails>;
  selectFinancingRecord(
    configurationNumber: number | string,
    id: number | string,
  ): Promise<RequestResponseDetails>;
  getFinancingList(
    configurationNumber: number | string,
  ): Promise<RequestResponseDetails<FinancingListItem[]>>;
  getFinancingDetails(
    configurationNumber: number | string,
    id: string,
  ): Promise<RequestResponseDetails<FinancingDetails | null>>;
  getFinancingSimulation(
    configurationNumber: number | string,
    id: string,
    productKey: string,
    values: Record<string, number | string>,
  ): Promise<RequestResponseDetails<FinancingCalculation | null>>;
  resetFinancingSimulation(): void;
  saveFinancingSimulation(
    configurationNumber: number | string,
    id: string,
    calculation: FinancingCalculation,
    product: FinancingProduct,
    values: Record<string, number | string>,
  ): Promise<RequestResponseDetails>;
}

export const useFinancingApi = (): Values => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const dispatch = useDispatch();

  const financingRecordsList = useSelector(financingSelectors.getList);

  const createFinancingRecord = useCallback<Values['createFinancingRecord']>(
    async configurationNumber => {
      let result: RequestResponseDetails<number>;

      dispatch(financingActions.setFinancingDetailsStatus(Status.Loading));
      try {
        const { id, message } = await xdmsClient.configurationCredit.create(
          configurationNumber,
        );

        notification.open({ message: message });

        result = {
          status: Status.Success,
          messageHandled: false,
          response: id,
        };

        dispatch(financingActions.setFinancingDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.configurationCredit],
  );

  const deleteFinancingRecord = useCallback<Values['deleteFinancingRecord']>(
    async (configurationNumber, record) => {
      let result: RequestResponseDetails<number>;

      dispatch(financingActions.setFinancingDetailsStatus(Status.Loading));
      try {
        const { message, success } = await xdmsClient.configurationCredit.delete(
          configurationNumber,
          record,
        );

        notification.open({ message: message });

        result = {
          status: success ? Status.Success : Status.Error,
          messageHandled: false,
        };

        dispatch(financingActions.setFinancingDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.configurationCredit],
  );

  const selectFinancingRecord = useCallback<Values['selectFinancingRecord']>(
    async (configurationNumber, id) => {
      let result: RequestResponseDetails<number>;

      dispatch(financingActions.setFinancingDetailsStatus(Status.Loading));
      try {
        const { message, success } = await xdmsClient.configurationCredit.select(
          configurationNumber,
          id,
        );

        notification.open({ message: message });

        result = {
          status: success ? Status.Success : Status.Error,
          messageHandled: false,
        };

        dispatch(financingActions.setFinancingDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.configurationCredit],
  );

  const updateFinancingDetails = useCallback<Values['updateFinancingDetails']>(
    async (configurationNumber, oldDataSet, newDataSet) => {
      let result: RequestResponseDetails;

      dispatch(financingActions.setFinancingDetailsStatus(Status.Loading));
      try {
        for (const [key, newData] of Object.entries(newDataSet)) {
          const fnName = updateDetailsKeys__mapping__requestNames[key];
          const oldData = oldDataSet?.[key];
          if (!xdmsClient.configurationCredit[fnName]) continue;

          await xdmsClient.configurationCredit[fnName]?.(
            configurationNumber,
            oldData,
            newData,
          );
        }

        result = {
          status: Status.Success,
          messageHandled: false,
        };

        dispatch(financingActions.setFinancingDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.configurationCredit],
  );

  const getFinancingList = useCallback<Values['getFinancingList']>(
    async configurationNumber => {
      let result: RequestResponseDetails<FinancingListItem[]>;

      dispatch(financingActions.setFinancingListStatus(Status.Loading));
      try {
        const response = await xdmsClient.configurationCredit.getList(
          configurationNumber,
        );

        result = {
          response: response[FinancingDetailsFields.listItems] ?? [],
          status: Status.Success,
          messageHandled: false,
        };

        dispatch(
          financingActions.setFinancingList(response[FinancingDetailsFields.listItems]),
        );
        dispatch(
          financingActions.setFinancingOptions(response[FinancingDetailsFields.options]),
        );

        dispatch(financingActions.setFinancingListStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingListStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [dispatch, xdmsClient.configurationCredit],
  );

  const getFinancingDetails = useCallback<Values['getFinancingDetails']>(
    async (configurationNumber, financingRecordId) => {
      let result: RequestResponseDetails<FinancingDetails | null>;

      dispatch(financingActions.setFinancingDetailsStatus(Status.Loading));
      try {
        // + getting record to get rowid from it
        // searching in cached list, if didn't find - fetch list again
        let listItem: FinancingListItem | undefined = financingRecordsList?.find(
          record =>
            Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
        );
        if (!listItem) {
          const { response } = await getFinancingList(configurationNumber);

          listItem = response?.find(
            record =>
              Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
          );
        }
        if (!listItem) throw new Error('not found');
        // - getting record to get rowid from it

        const response = await xdmsClient.configurationCredit.getDetailsByRowId(
          configurationNumber,
          listItem.rowid,
        );

        result = {
          status: Status.Success,
          messageHandled: false,
          response: response,
        };

        dispatch(
          financingActions.setFinancingOptions(response[FinancingDetailsFields.options]),
        );

        dispatch(financingActions.setFinancingDetails(response));

        dispatch(financingActions.setFinancingDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, getFinancingList, financingRecordsList, xdmsClient.configurationCredit],
  );

  const getFinancingSimulation = useCallback<Values['getFinancingSimulation']>(
    async (configurationNumber, financingRecordId, productKey, values) => {
      let result: RequestResponseDetails<FinancingCalculation | null>;

      dispatch(financingActions.setFinancingSimulationStatus(Status.Loading));
      try {
        // + getting record to get rowid from it
        // searching in cached list, if didn't find - fetch list again
        let listItem: FinancingListItem | undefined = financingRecordsList?.find(
          record =>
            Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
        );
        if (!listItem) {
          const { response } = await getFinancingList(configurationNumber);
          listItem = response?.find(
            record =>
              Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
          );
        }
        if (!listItem) throw new Error('not found');
        // - getting record to get rowid from it

        const response = await xdmsClient.configurationCredit.getSimulatedCalculations(
          configurationNumber,
          listItem.rowid,
          productKey,
          values,
        );

        result = {
          status: Status.Success,
          messageHandled: false,
          response: response,
        };

        dispatch(financingActions.setFinancingSimulation(response));

        dispatch(financingActions.setFinancingSimulationStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingSimulationStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, financingRecordsList, getFinancingList, xdmsClient.configurationCredit],
  );

  const resetFinancingSimulation = useCallback<Values['resetFinancingSimulation']>(() => {
    dispatch(financingActions.setFinancingSimulation(null));
  }, [dispatch]);

  const saveFinancingSimulation = useCallback<Values['saveFinancingSimulation']>(
    async (configurationNumber, financingRecordId, calculation, product, values) => {
      let result: RequestResponseDetails;

      dispatch(financingActions.setFinancingSimulationStatus(Status.Loading));
      try {
        // + getting record to get rowid from it
        // searching in cached list, if didn't find - fetch list again
        let listItem: FinancingListItem | undefined = financingRecordsList?.find(
          record =>
            Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
        );
        if (!listItem) {
          const { response } = await getFinancingList(configurationNumber);

          listItem = response?.find(
            record =>
              Number(record[FinancingListItemFields.id]) === Number(financingRecordId),
          );
        }
        if (!listItem) throw new Error('not found');
        // - getting record to get rowid from it

        const response = await xdmsClient.configurationCredit.saveSimulatedCalculation({
          configurationNumber,
          id: financingRecordId,
          record: listItem,
          calculation: calculation,
          data: values,
          product: product,
        });

        result = {
          status: Status.Success,
          messageHandled: false,
          response: response,
        };

        dispatch(financingActions.setFinancingSimulationStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(financingActions.setFinancingSimulationStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, financingRecordsList, getFinancingList, xdmsClient.configurationCredit],
  );

  return useMemo(
    () => ({
      updateFinancingDetails,
      createFinancingRecord,
      deleteFinancingRecord,
      selectFinancingRecord,
      getFinancingList,
      getFinancingDetails,
      getFinancingSimulation,
      resetFinancingSimulation,
      saveFinancingSimulation,
    }),
    [
      updateFinancingDetails,
      createFinancingRecord,
      deleteFinancingRecord,
      selectFinancingRecord,
      getFinancingList,
      getFinancingDetails,
      getFinancingSimulation,
      resetFinancingSimulation,
      saveFinancingSimulation,
    ],
  );
};
