import React, { FC, useState } from 'react';
import { ScTotalTableRow } from './ColumnsTitles.styles';
import { TFunction } from 'i18next';
import { useLayoutEffect } from 'react';

interface Props {
  currency: string;
  t: TFunction;
  windowWidth: number;
}

const ColumnsTitles: FC<Props> = ({ currency, t, windowWidth }) => {
  const [yPosition, setYPosition] = useState<number>(0);

  useLayoutEffect(() => {
    const headerNode = document.getElementById('layout-top-section');
    const headerHeight = headerNode?.offsetHeight ?? 0;
    setYPosition(headerHeight);
  }, [windowWidth]);

  return (
    <ScTotalTableRow
      style={{
        top: yPosition + 'px',
      }}
      textSize="extra-large"
      borderTop="thick"
      borderBottom="thick"
      fields={[
        t('TOTAL_HEADER_SUMMARY'),
        t('TOTAL_HEADER_DESCRIPTION'),
        t('TOTAL_HEADER_CODE'),
        t('TOTAL_HEADER_QUANTITY'),
        t('TOTAL_HEADER_PRICE'),
        currency + ' ' + t('TOTAL_HEADER_DISCOUNT'),
        '% ' + t('TOTAL_HEADER_DISCOUNT'),
        t('TOTAL_HEADER_TOTAL'),
      ]}
    />
  );
};

export default ColumnsTitles;
