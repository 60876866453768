import {
  Model,
  Model_CommonItem,
  Model_CommonItem_Type_Values,
  Model_CommonItemFields,
  ModelRuleValues,
  ModelRule,
} from '@hypercharge/xdms-client/lib/types';
import { ColumnsType } from 'antd/lib/table';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useTranslation } from 'react-i18next';
import { ExcludesFalse } from 'types/common';
import { formatPrice } from 'utils/format';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';
import { commonItemType__mapping__translationKey } from './RulesModal.meta';
import { getRecordRules } from '../../utils/get-record-rules';
import { useSelector } from 'react-redux';
import { sharedSelectors } from 'store';

export type RelatedStateItem = {
  key: string;
  record: Model_CommonItem;
  selected: boolean;
  disabled: boolean;
  incompatibleRecords?: Model_CommonItem[];
  isPackage?: boolean;

  isPackageLine?: boolean;
  parentPackage?: Model_CommonItem;
};

export type RelatedState = RelatedStateItem[];

export enum RelatedActionType {
  init,
  select,
}

export type RelatedAction =
  | {
      type: RelatedActionType.init;
      model: Model;
      modelRules: ModelRule[];
      modelItemsIdsList: string[];
    }
  | {
      type: RelatedActionType.select;
      key: string;
    };

export const relatedReducer = (
  state: RelatedState,
  action: RelatedAction,
): RelatedState => {
  switch (action.type) {
    /**
     * For packages and packageLines selection should be disabled
     * Same as for already selected options
     */
    case RelatedActionType.init: {
      const itemsList = action.modelItemsIdsList
        .map(id => getModelCommonItemById(action.model, id))
        .filter(Boolean as unknown as ExcludesFalse);

      return itemsList.map(item => {
        const result: RelatedState[0] = {
          key: item[Model_CommonItemFields.ID],
          record: item,
          selected: item[Model_CommonItemFields.selected],
          disabled: item[Model_CommonItemFields.selected],
        };

        if (item[Model_CommonItemFields.type] === Model_CommonItem_Type_Values.package) {
          result.disabled = true;
          result.isPackage = true;
        }

        if (
          item[Model_CommonItemFields.type] === Model_CommonItem_Type_Values.packageLine
        ) {
          result.disabled = true;
          result.isPackageLine = true;
          result.parentPackage = getModelCommonItemById(
            action.model,
            item[Model_CommonItemFields.package],
          );
        }

        const recordRules = getRecordRules(
          action.modelRules,
          item[Model_CommonItemFields.ID],
        );

        const incompatibleRecordsIds = recordRules[ModelRuleValues.incompatible];

        if (incompatibleRecordsIds?.length) {
          const selectedIncompatibleRecord = incompatibleRecordsIds
            .map(id => getModelCommonItemById(action.model, id))
            .filter(Boolean as unknown as ExcludesFalse)
            .filter(item => item[Model_CommonItemFields.selected]);

          if (selectedIncompatibleRecord.length) {
            result.disabled = true;
            result.incompatibleRecords = selectedIncompatibleRecord;
          }
        }

        return result;
      });
    }
    case RelatedActionType.select: {
      return state.map(record => {
        if (record.key === action.key) {
          return {
            ...record,
            selected: !record.selected,
          };
        }

        return record;
      });
    }
    default:
      return state;
  }
};

export const useRelatedColumns = (): ColumnsType<RelatedStateItem> => {
  const { t, i18n } = useTranslation();
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const { currencyCode } = useCurrency();

  return [
    {
      title: t('TABLE_ITEM_ID'),
      width: '9em',
      dataIndex: ['record', Model_CommonItemFields.ID],
    },
    {
      title: t('DESCRIPTION'),
      width: '24em',
      dataIndex: ['record', Model_CommonItemFields.name],
      className: 'cell__description',
    },
    {
      title: t('ENTITY'),
      width: '8em',
      render: (value, record) => {
        return t(
          commonItemType__mapping__translationKey[
            record.record[Model_CommonItemFields.type]
          ],
        );
      },
    },
    {
      title: t('MODAL_RULES__COLUMN_TITLE_GROSSPRICE'),
      render: (value, item) => {
        const field = shouldShowPricesWithVAT
          ? Model_CommonItemFields.grossPriceVat
          : Model_CommonItemFields.grossPrice;

        return formatPrice({
          price: item.record[field],
          currency: currencyCode,
          locale: i18n.language,
        });
      },
    },
  ];
};
