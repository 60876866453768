import {
  DocumentLocation,
  DocumentTemplate,
  DocumentListItem,
  DocumentType,
} from 'types/vendor';
import { USERNAME_PREFIX } from 'common/constants';
import { useExternalStorage } from 'context/externalStorage/ExternalStorageProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors, configurationSelectors, documentsActions } from 'store';
import { RequestResponseDetails } from 'types/common';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { DocumentRelatedEntitySettingsSelectors } from './settings';
import {
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
  DocumentItem,
  DocumentsSwarm,
  DocumentUploadLinkParams,
  DocumentUploadFileParams,
  DocumentItemDetails,
  DocumentUpdateParams,
  SendEmailQuoteParams,
} from './types';
import { FileUploadFunctions } from './useFileUpload';
import { unifyDocumentRecord, unifyDocumentsSwarm } from './unifyRecords';
import { getDocumentsSorted } from './utils';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

interface DocumentsApi {
  getDocumentsList(
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
  ): Promise<RequestResponseDetails<DocumentItem[]>>;
  getDocumentDetails(
    document: DocumentItem | DocumentListItem,
    relatedEntityCode: DocumentRelatedEntityCode,
  ): Promise<RequestResponseDetails<DocumentItemDetails | null>>;
  getDocumentsForAllEntities(): Promise<RequestResponseDetails<DocumentsSwarm>>;
  deleteDocument(
    record: DocumentItem,
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
  ): Promise<RequestResponseDetails>;
  uploadDocumentLink(
    data: DocumentUploadLinkParams,
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
  ): Promise<RequestResponseDetails>;
  uploadDocumentFile(
    data: DocumentUploadFileParams,
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
    setupFunctions?: FileUploadFunctions,
  ): Promise<RequestResponseDetails>;
  updateDocument(
    rowid: string,
    data: DocumentUpdateParams,
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
  ): Promise<RequestResponseDetails>;
  reorderDocuments(
    documents: DocumentItem[],
    relatedEntityCode: DocumentRelatedEntityCode,
    entity?: DocumentRelatedEntity,
  ): Promise<RequestResponseDetails>;
  generateConfigurationDocument(
    printTemplate: DocumentTemplate,
    documentType: DocumentType,
  ): Promise<RequestResponseDetails<string | undefined>>;
  sendEmailQuote(
    params: SendEmailQuoteParams,
  ): Promise<RequestResponseDetails<string | undefined>>;
}

export const useDocumentsApi = (): DocumentsApi => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();
  const { isFeatureEnabled } = useFeature();

  const shouldSortByNewestDocuments = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.shouldSortByNewestDocuments,
  });

  const username = useSelector(authSelectors.getUsername);

  const {
    storageCredentials,
    generateFileNameKey,
    isExternalStorage,
    uploadDocument: uploadDocumentFileToExternalStorage,
    deleteDocument: deleteDocumentInExternalStorage,
  } = useExternalStorage();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  /** Related entities here - options, accessories, t&c, tradeIns... All but configuration documents. */
  const getDocumentsForAllEntities = useCallback<
    DocumentsApi['getDocumentsForAllEntities']
  >(async () => {
    if (!configurationNumber || !storageCredentials?.bucket)
      return { status: Status.Error };

    let result: RequestResponseDetails<DocumentsSwarm>;

    try {
      dispatch(documentsActions.setForAllEntitiesStatus(Status.Loading));

      const configurationSettings =
        DocumentRelatedEntitySettingsSelectors.getByEntityCode(
          DocumentRelatedEntityCode.configuration,
        );

      const filter = configurationSettings.assembleRelation_leveled(configurationNumber);

      const responseForConfiguration = await xdmsClient.document.getListLeveled(filter);

      const response = await xdmsClient.configuration.getDocumentsList({
        configurationNumber: String(configurationNumber),
      });

      result = {
        status: Status.Success,
        response: unifyDocumentsSwarm(
          responseForConfiguration,
          response,
          isExternalStorage,
          isFeatureEnabled,
        ),
      };

      dispatch(documentsActions.setForAllEntitiesStatus(Status.Success));
    } catch (e) {
      notification.requestError(e);
      dispatch(documentsActions.setForAllEntitiesStatus(Status.Error));
      result = { status: Status.Error, messageHandled: true };
    }

    return result;
  }, [
    configurationNumber,
    xdmsClient,
    dispatch,
    isExternalStorage,
    isFeatureEnabled,
    storageCredentials,
  ]);

  const getDocumentsList = useCallback<DocumentsApi['getDocumentsList']>(
    async (relatedEntityCode, entity) => {
      if (!configurationNumber || !storageCredentials?.bucket)
        return { status: Status.Error };

      let result: RequestResponseDetails<DocumentItem[]>;

      try {
        dispatch(documentsActions.setListStatus(Status.Loading));

        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const filter = settings.assembleRelation_leveled(configurationNumber, entity);

        const response = await xdmsClient.document.getListLeveled(filter);

        let documents = response.map(record =>
          unifyDocumentRecord.listItem(record, isExternalStorage),
        );

        documents = getDocumentsSorted(documents, {
          isNewestSort: shouldSortByNewestDocuments,
        });

        result = { status: Status.Success, response: documents };
        dispatch(documentsActions.setListStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setListStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [
      configurationNumber,
      storageCredentials,
      dispatch,
      xdmsClient.document,
      shouldSortByNewestDocuments,
      isExternalStorage,
    ],
  );

  const getDocumentDetails = useCallback<DocumentsApi['getDocumentDetails']>(
    async (document, relatedEntityCode) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails<DocumentItemDetails | null>;

      try {
        dispatch(documentsActions.setDetailsStatus(Status.Loading));

        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const filter = settings.assembleRelation_details(document);
        // Filter here is optional. Added to prevent downloading ALL the documents.
        const response = await xdmsClient.document.getDetailedByRowid(
          document.rowid,
          filter,
        );

        const record = response
          ? unifyDocumentRecord.details(response, username ?? '')
          : null;

        result = { status: Status.Success, response: record };
        dispatch(documentsActions.setDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [configurationNumber, xdmsClient, dispatch, username],
  );

  const deleteDocument = useCallback<DocumentsApi['deleteDocument']>(
    async (record, relatedEntityCode, entity) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails;

      try {
        dispatch(documentsActions.setDeletionStatus(Status.Loading));

        if (record.computed.location === DocumentLocation.amazon) {
          const settings =
            DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

          const key = settings.assembleExternalStorageFilePath(
            configurationNumber,
            record.fileName,
            entity,
          );

          if (!key) {
            result = { status: Status.Error };
            return result;
          }

          await deleteDocumentInExternalStorage({ key });
        }

        const messages = await xdmsClient.document.deleteLink({
          configurationNumber: String(configurationNumber),
          id: String(record.id),
        });

        result = { status: Status.Success };

        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        dispatch(documentsActions.setDeletionStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setDeletionStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [configurationNumber, xdmsClient, dispatch, deleteDocumentInExternalStorage],
  );

  const uploadDocumentLink = useCallback<DocumentsApi['uploadDocumentLink']>(
    async (data, relatedEntityCode, entity) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails;

      try {
        dispatch(documentsActions.setUploadStatus(Status.Loading));

        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const messages = await xdmsClient.document.uploadLink({
          ...data,
          typeCategoryPicklistCode: settings.getTypeCategoriesPicklistCode(),
          configurationNumber: String(configurationNumber),
          location: DocumentLocation.link,
          usernamePrefix: USERNAME_PREFIX,
          relation: settings.assembleRelation_default(configurationNumber, entity),
        });

        result = { status: Status.Success };

        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        dispatch(documentsActions.setUploadStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setUploadStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [configurationNumber, xdmsClient, dispatch],
  );

  const uploadDocumentFile = useCallback<DocumentsApi['uploadDocumentFile']>(
    async (data, relatedEntityCode, entity, uploadFunctions) => {
      if (!configurationNumber || !storageCredentials?.bucket)
        return { status: Status.Error };

      let result: RequestResponseDetails;

      try {
        dispatch(documentsActions.setUploadStatus(Status.Loading));

        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const externalFileName = generateFileNameKey(data.file.uid, data.file.name);
        const externalFilePath = settings.assembleExternalStorageFilePath(
          configurationNumber,
          externalFileName,
          entity,
        );

        const { url } = await uploadDocumentFileToExternalStorage({
          file: data.file.originFileObj,
          id: data.file.uid,
          key: externalFilePath,
          contentType: data.file.type,
          onProgress: uploadFunctions?.onProgress,
          handleAbort: uploadFunctions?.setupAbort,
        });

        const messages = await xdmsClient.document.uploadLink({
          ...data,
          typeCategoryPicklistCode: settings.getTypeCategoriesPicklistCode(),
          url: url,
          location: DocumentLocation.amazon,
          configurationNumber: String(configurationNumber),
          usernamePrefix: USERNAME_PREFIX,
          relation: settings.assembleRelation_default(configurationNumber, entity),
        });

        uploadFunctions?.onProgress?.(data.file.uid, 100);
        result = { status: Status.Success };

        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }

        dispatch(documentsActions.setUploadStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setUploadStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [
      configurationNumber,
      xdmsClient,
      dispatch,
      uploadDocumentFileToExternalStorage,
      generateFileNameKey,
      storageCredentials,
    ],
  );

  const updateDocument = useCallback<DocumentsApi['updateDocument']>(
    async (rowid, data, relatedEntityCode, entity) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails;

      try {
        dispatch(documentsActions.setUpdateStatus(Status.Loading));

        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const filter = settings.assembleRelation_default(configurationNumber, entity);

        const response = await xdmsClient.document.updateByRowid(
          rowid,
          { ...data, changedBy: `${USERNAME_PREFIX}${username}` },
          filter,
        );

        result = { status: response ? Status.Success : Status.Error };

        dispatch(documentsActions.setUpdateStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(documentsActions.setUpdateStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [configurationNumber, dispatch, xdmsClient.document, username],
  );

  const reorderDocuments = useCallback<DocumentsApi['reorderDocuments']>(
    async (documents, relatedEntityCode, entity) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails;

      try {
        const settings =
          DocumentRelatedEntitySettingsSelectors.getByEntityCode(relatedEntityCode);

        const filter = settings.assembleRelation_default(configurationNumber, entity);

        const messages = await xdmsClient.document.changeSequence({
          relation: filter,
          sequence: documents.map((document, idx) => ({
            id: document.id,
            sequentialNumber: idx,
          })),
        });

        result = { status: Status.Success };

        if (messages?.length) {
          notification.open({ message: messages });
          result.messageHandled = true;
        }
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [configurationNumber, xdmsClient.document],
  );

  const generateConfigurationDocument = useCallback<
    DocumentsApi['generateConfigurationDocument']
  >(
    async (printTemplate, documentType) => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails<string | undefined> = {
        status: Status.Loading,
      };

      try {
        const response = await xdmsClient.document.generateForConfiguration(
          configurationNumber,
          {
            printTemplate,
            type: documentType.id,
          },
        );

        if (response.messages) {
          notification.open({ message: response.messages });
        }

        result = {
          status: Status.Success,
          messageHandled: Boolean(response.messages?.length),
          response: response.fileUrl,
        };
      } catch (e) {
        notification.requestError(e);
        result.status = Status.Error;
        result.messageHandled = true;
      }

      return result;
    },
    [configurationNumber, xdmsClient.document],
  );

  const sendEmailQuote = useCallback<DocumentsApi['sendEmailQuote']>(
    async params => {
      if (!configurationNumber) return { status: Status.Error };

      let result: RequestResponseDetails<string | undefined> = {
        status: Status.Loading,
      };

      try {
        const response = await xdmsClient.configuration.sendEmail({
          ...params,
          documentIds: params.documentIds as string[],
          configurationNumber: configurationNumber,
        });

        if (response.message) {
          notification.open({ message: response.message });
        }

        result = {
          status: Status.Success,
          messageHandled: Boolean(response.message?.length),
        };
      } catch (e) {
        notification.requestError(e);
        result.status = Status.Error;
        result.messageHandled = true;
      }

      return result;
    },
    [configurationNumber, xdmsClient.configuration],
  );

  return {
    getDocumentsForAllEntities: getDocumentsForAllEntities,
    getDocumentsList: getDocumentsList,
    getDocumentDetails: getDocumentDetails,
    deleteDocument: deleteDocument,
    uploadDocumentLink: uploadDocumentLink,
    uploadDocumentFile: uploadDocumentFile,
    updateDocument: updateDocument,
    reorderDocuments: reorderDocuments,
    generateConfigurationDocument: generateConfigurationDocument,
    sendEmailQuote: sendEmailQuote,
  };
};
