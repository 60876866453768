import React, {
  ComponentProps,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import ConfigurationImportModal from 'components/configuration/ConfigurationImportModal';
import ConfigurationMarginModal from 'components/configuration/ConfigurationMarginModal';

interface ContextValue {
  configurationImportModalState:
    | Omit<ComponentProps<typeof ConfigurationImportModal>, 'onClose'>
    | undefined;
  setConfigurationImportModalState: Dispatch<
    SetStateAction<ContextValue['configurationImportModalState']>
  >;
  configurationMarginModalState:
    | Omit<ComponentProps<typeof ConfigurationMarginModal>, 'onClose'>
    | undefined;
  setConfigurationMarginModalState: Dispatch<
    SetStateAction<ContextValue['configurationMarginModalState']>
  >;
}

const Context = React.createContext<ContextValue | undefined>(undefined);

/**
 * State for global ui elements states
 */
const GlobalModalsStatesProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = ({
  children,
}) => {
  const [configurationImportModalState, setConfigurationImportModalState] =
    useState<ContextValue['configurationImportModalState']>();

  const [configurationMarginModalState, setConfigurationMarginModalState] =
    useState<ContextValue['configurationMarginModalState']>();

  const value = useMemo(
    () => ({
      configurationImportModalState,
      setConfigurationImportModalState,
      configurationMarginModalState,
      setConfigurationMarginModalState,
    }),
    [
      configurationImportModalState,
      setConfigurationImportModalState,
      configurationMarginModalState,
      setConfigurationMarginModalState,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useGlobalModalsStates = (): ContextValue => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      `${useGlobalModalsStates.name} must be used within an ${GlobalModalsStatesProvider.name}`,
    );
  }

  return context;
};

export { GlobalModalsStatesProvider, useGlobalModalsStates };
