import {
  DocumentEmailModalBase,
  DocumentEmailModalBase_Data,
} from 'components/document/DocumentEmailModalBase';
import { useRelations } from 'context/relations/RelationsProvider';
import React, { FC, useCallback, useMemo } from 'react';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { groupRelationsEmails } from './utils';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { useTranslation } from 'react-i18next';
import { DocumentsTreeData } from 'hooks/useDocumentsTree/types';
import { documentTreeHelpers } from 'hooks/useDocumentsTree/helpers';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';

interface Props {
  onCancel?(): void;
  documentsSchema: DocumentsTreeData;
}
export type DocumentQuoteModalProps = Props;
export const DocumentQuoteModal: FC<Props> = ({ onCancel, documentsSchema }) => {
  const { iso: languageISOCode } = useCurrentLanguageCode();
  const { t } = useTranslation();

  const { relations } = useRelations();
  const { isFeatureEnabled } = useFeature();

  const { sendEmailQuote } = useDocumentsApi();

  const { configurationModelName } = useSelector(configurationSelectors.getAll);

  const isContactsEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.ContactsEnabled,
  });

  const shouldSkipNoMail = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.SkipNoMail,
  });

  const handleSendEmailQuote = useCallback(
    async (params: DocumentEmailModalBase_Data) => {
      const { status, messageHandled } = await sendEmailQuote({
        emailTo: params.emailTo,
        emailCC: params.emailCC,
        subject: params.subject,
        body: params.body,
        documentIds: documentTreeHelpers.getSelectedDocumentsIds(params.documentsSchema),
      });
      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Error]: t('QUOTE_EMAIL_MODAL__SEND_ERROR'),
          [Status.Success]: t('QUOTE_EMAIL_MODAL__SEND_SUCCESS'),
        });
      }
    },
    [t, sendEmailQuote],
  );

  const emails = useMemo(() => {
    return groupRelationsEmails(relations, isContactsEnabled, shouldSkipNoMail);
  }, [relations, isContactsEnabled, shouldSkipNoMail]);

  const documentsSchemaWithSelectedDocuments = useMemo<DocumentsTreeData>(() => {
    return documentTreeHelpers.filterSelectedDocuments(documentsSchema);
  }, [documentsSchema]);

  return (
    <DocumentEmailModalBase
      isDocumentActionsDisabled
      isDocumentEntitiesBordered
      onClose={onCancel}
      initialFormData={{
        emailTo: emails.primary,
        emailCC: emails.cc,
        subject: t('DOCUMENT_SEND_EMAIL_SUBJECT', {
          configurationModelName,
          lng: languageISOCode,
        }),
      }}
      onSubmit={handleSendEmailQuote}
      documentsSchema={documentsSchemaWithSelectedDocuments}
      title={t('QUOTE_EMAIL_MODAL__TITLE')}
    />
  );
};
