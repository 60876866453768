import {
  Model,
  ModelFields,
  ModelPackageLineFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonItem,
  Model_CommonItemFields,
  PackageItem,
} from '@hypercharge/xdms-client/lib/types';
import { Row, Col, Tooltip } from 'antd';
import { MAX_TEXT_LENGTH_IN_MODAL_COLUMN } from 'common/constants';
import { Button } from 'components/button';
import Checkbox from 'components/form/Checkbox';
import { Modal } from 'components/modal';
import {
  PackageTitle,
  ScModalFooterDisabledButton,
  ScModalFooterItem,
  ScPackageContentWrapper,
  ScPackageEntryWrapper,
  ScPackageGroupCheckbox,
  ScPackageGroupTitle,
  ScPackageGroupWrapper,
  ScPackageLinesTableHeaderTitle,
  ScPackagesLinesWrapper,
  ScTotalItem,
  ScTotalItemValue,
  ScTotalSection,
} from 'pages/DynamicPage/Modals/PackageLinesModal.styles';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useModelApi } from 'context/model/useModelApi';
import {
  getPackageIncompatibleRecords,
  getPackageLines,
  getRecordIncompatibilityMessage,
} from 'pages/DynamicPage/utils';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { formatPrice, longTextFormat } from 'utils/format';
import { getSelectedTableItems } from 'utils/get-selected-table-items';
import { Status } from 'utils/types';
import { RequestResponseDetails } from '../../../types/common';
import { notification } from '../../../utils/notification';
import { ScModalFooter } from 'components/modalLike.styles';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { getPackageLinesGroupedByName } from '../utils/getPackageLinesGroupedByName';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';

// tables to look for items to apply rules based on
export const packageLineTablesToSearchForIncompatible = [
  ModelFields.accessories,
  ModelFields.options,
  ModelFields.termsAndConditions,
  ModelFields.packageLines,
  ModelFields.packages,
];

export type PackageLinesModalCallParams = Pick<
  PackageLinesModalProps,
  'option' | 'isSelectHidden'
>;

export type PackageLinesModalProps = {
  onClose(): void;
  option: PackageItem | null;
  isSelectHidden?: boolean;
};

export const PackageLinesModal: FC<PackageLinesModalProps> = ({
  onClose,
  option,
  isSelectHidden,
}) => {
  const { t, i18n } = useTranslation();
  const { updateModel } = useModelApi();
  const { currencyCode } = useCurrency();

  const { model, status, isConfigurationComplete, modelRules } = useSelector(
    modelSelectors.getAll,
  );

  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [packageTitle, setPackageTitle] = useState<string>('');
  const [packageTotal, setPackageTotal] = useState<number>(0);
  const [selectedPackageLines, setSelectedPackageLines] = useState<
    Record<string, string>
  >({});

  const packageLinesForCurrentRecord = useMemo<Model_CommonItem[]>(() => {
    return getPackageLines({ model, packageRecord: option });
  }, [option, model]);

  const packageLinesGroupedByGroupName = useMemo(() => {
    if (!model || !option) return null;
    return getPackageLinesGroupedByName(model, option);
  }, [model, option]);

  const incompatibleRecords = useMemo<string[]>(() => {
    return getPackageIncompatibleRecords({
      model,
      modelRules,
      pack: option,
      tableItems: getSelectedTableItems({
        model,
        tables: packageLineTablesToSearchForIncompatible,
      }),
    });
  }, [option, model, modelRules]);

  // on each modal open selected package lines should be pre-filled
  // so that client is allowed to see what has been selected previously
  // or should be selected by default
  useEffect(() => {
    if (isSelecting) return;
    if (!packageLinesGroupedByGroupName) return;
    const newSelectedPackageLines = Object.entries(packageLinesGroupedByGroupName).reduce<
      Record<string, string>
    >((res, [groupName, packageLines]) => {
      const selectedPackageLine = packageLines.find(
        item => item[Model_CommonItemFields.selected],
      );
      const selectedByDefaultPackageLine = packageLines.find(
        item => item[Model_CommonItemFields.selectedByDefault],
      );

      const resultingPackageLine = selectedPackageLine ?? selectedByDefaultPackageLine;

      if (resultingPackageLine) {
        res[groupName] = resultingPackageLine[ModelPackageLineFields.ID];
      }

      return res;
    }, {});
    setSelectedPackageLines(newSelectedPackageLines);
  }, [isSelecting, option, packageLinesGroupedByGroupName]);

  const handleSelect = useCallback(async () => {
    setIsSelecting(true);

    try {
      const operationsResponsesList: RequestResponseDetails<Model | null>[] = [];
      // package (with related package lines) selection
      // should start with package selection and it should not
      // proceed further unless package is successfully selected
      const selectPackageResponse = await updateModel(model as Model, {
        [ModelUpdateAttributesObjectFields.commonItem]: {
          ...(option as PackageItem),
          [Model_CommonItemFields.selected]: true,
        },
      });

      let updatedModel = selectPackageResponse.response as Model;
      operationsResponsesList.push(selectPackageResponse);

      // includes only selected package lines from group
      // one-level package lines (without sub-selections)
      // should not be inside as they are automatically selected
      // by package selection itself (above)
      const packageLinesToSelect = packageLinesForCurrentRecord.filter(packageLine => {
        const packageLineId = packageLine[ModelPackageLineFields.ID];
        const packageLineGroup = packageLine[ModelPackageLineFields.packageGroup];
        const isClientSelected = selectedPackageLines[packageLineGroup] === packageLineId;
        const isServerSelected = packageLine[ModelPackageLineFields.selected];
        return isClientSelected && !isServerSelected;
      });

      // @todo backend: we need to make request per each grouped package line
      // though it seems an overwhelm (and it is actually) still there is no
      // other option for now
      for (const packageLine of packageLinesToSelect) {
        const item = getModelCommonItemById(
          updatedModel,
          packageLine[ModelPackageLineFields.ID],
        );

        if (!item) continue;

        const packageLineSelectResponse = await updateModel(updatedModel, {
          [ModelUpdateAttributesObjectFields.commonItem]: {
            ...item,
            [Model_CommonItemFields.selected]: true,
          },
        });

        updatedModel = packageLineSelectResponse.response as Model;
        operationsResponsesList.push(packageLineSelectResponse);
      }

      if (!operationsResponsesList.every(({ messageHandled }) => messageHandled)) {
        const statusesList = operationsResponsesList.map(({ status }) => status);
        const status = statusesList.some(status => status === Status.Error)
          ? Status.Error
          : Status.Success;

        notification.openByStatus(status, {
          [Status.Success]: t('UPDATE_SUCCESSFUL'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }

      onClose();
    } finally {
      setIsSelecting(false);
    }
  }, [
    updateModel,
    model,
    option,
    packageLinesForCurrentRecord,
    onClose,
    selectedPackageLines,
    t,
  ]);

  useEffect(() => {
    const packageId: string | null = get(option, Model_CommonItemFields.package, null);

    if (model && packageId) {
      const packageItem = model[ModelFields.packages]?.find(
        item => item[Model_CommonItemFields.package] === packageId,
      );

      if (packageItem) {
        setPackageTitle(packageItem[Model_CommonItemFields.name]);
        setPackageTotal(packageItem[Model_CommonItemFields.totalSellingPrice]);
      }
    }
  }, [option, model]);

  const getFormatPrice = (price: number): string => {
    return formatPrice({
      price: price,
      locale: i18n.language,
      currency: currencyCode,
    });
  };

  const renderNameTooltip = (name: string): string | JSX.Element => {
    if (name.length > MAX_TEXT_LENGTH_IN_MODAL_COLUMN) {
      return (
        <Tooltip placement="topLeft" title={name}>
          {longTextFormat(name, MAX_TEXT_LENGTH_IN_MODAL_COLUMN)}
        </Tooltip>
      );
    }
    return name;
  };

  const getTooltipMessage = useCallback(
    packageLine => {
      const packageLineId = packageLine[ModelPackageLineFields.ID];
      const isIncompatiblePackageLine = incompatibleRecords.includes(packageLineId);
      if (!isIncompatiblePackageLine) return '';
      return getRecordIncompatibilityMessage({
        record: packageLine,
        modelRules,
        tableItems: getSelectedTableItems({
          model,
          tables: packageLineTablesToSearchForIncompatible,
        }),
        t,
        model,
      });
    },
    [incompatibleRecords, model, t, modelRules],
  );

  const isSelectionDone = useMemo(() => {
    if (!packageLinesGroupedByGroupName) return false;
    return Object.values(packageLinesGroupedByGroupName)
      .filter(packageLines => packageLines.length >= 2)
      .every(packageLines => {
        return packageLines.some(pl => {
          return Boolean(selectedPackageLines[pl[ModelPackageLineFields.packageGroup]]);
        });
      });
  }, [packageLinesGroupedByGroupName, selectedPackageLines]);

  const isSelectDisabled = useMemo<boolean>(() => {
    return Boolean(
      isSelectHidden ||
        status === Status.Loading ||
        isConfigurationComplete ||
        !isSelectionDone ||
        incompatibleRecords.length,
    );
  }, [
    incompatibleRecords.length,
    isConfigurationComplete,
    isSelectionDone,
    status,
    isSelectHidden,
  ]);

  const selectActionTooltip = useMemo<React.ReactNode>(() => {
    if (!incompatibleRecords.length) return '';

    const messageGroups: Record<string, string> = packageLinesForCurrentRecord.reduce(
      (res, packageLine) => {
        const packageLineCode = packageLine[ModelPackageLineFields.ID];
        const message = getRecordIncompatibilityMessage({
          record: packageLine,
          modelRules,
          tableItems: getSelectedTableItems({
            model,
            tables: packageLineTablesToSearchForIncompatible,
          }),
          t,
          model,
        });
        if (message) res[packageLineCode] = message;
        return res;
      },
      {},
    );

    return (
      <ul>
        {Object.entries(messageGroups).map(([packageLineCode, message]) => (
          <li
            key={packageLineCode}
          >{`${packageLineCode} ${message.toLocaleLowerCase()}`}</li>
        ))}
      </ul>
    );
  }, [incompatibleRecords.length, model, packageLinesForCurrentRecord, t, modelRules]);

  const renderPackageLines = () => {
    if (!packageLinesGroupedByGroupName) return;
    return Object.entries(packageLinesGroupedByGroupName).map(
      ([groupName, groupPackageLines]) => {
        const isChecked =
          groupPackageLines.length <= 1 || Boolean(selectedPackageLines[groupName]);

        return (
          <ScPackageGroupWrapper key={groupName}>
            <ScPackageEntryWrapper>
              <Row key={groupName} align="middle" justify="center">
                <Col span={16} data-testid="accessories-modal-item-name-label">
                  <Tooltip
                    placement="bottomLeft"
                    title={getTooltipMessage(groupPackageLines[0])}
                  >
                    {/* need wrapper div otherwise tooltip does not interact with disabled button */}
                    <div>
                      <ScPackageGroupCheckbox
                        disabled={
                          isChecked ||
                          incompatibleRecords.includes(
                            groupPackageLines[0][Model_CommonItemFields.ID],
                          )
                        }
                        checked={isChecked}
                      >
                        <ScPackageGroupTitle>
                          {renderNameTooltip(groupName)}
                        </ScPackageGroupTitle>
                      </ScPackageGroupCheckbox>
                    </div>
                  </Tooltip>
                </Col>
                <Col span={4}>
                  {groupPackageLines.length <= 1 &&
                    renderNameTooltip(groupPackageLines[0][Model_CommonItemFields.ID])}
                </Col>
                <Col span={4} data-testid="accessories-modal-item-name-label">
                  {groupPackageLines.length <= 1 &&
                    getFormatPrice(
                      groupPackageLines.reduce<number>(
                        (totalPriceForPackage, packageLine) => {
                          totalPriceForPackage +=
                            packageLine[Model_CommonItemFields.totalSellingPrice];
                          return totalPriceForPackage;
                        },
                        0,
                      ),
                    )}
                </Col>
              </Row>
            </ScPackageEntryWrapper>
            {groupPackageLines
              .filter(
                packageLine => packageLine[Model_CommonItemFields.name] !== groupName,
              )
              .map(packageLine => (
                <ScPackageEntryWrapper key={packageLine[Model_CommonItemFields.ID]}>
                  <Row
                    key={packageLine[Model_CommonItemFields.ID]}
                    align="middle"
                    justify="center"
                  >
                    <Col
                      span={15}
                      offset={1}
                      data-testid="accessories-modal-item-name-label"
                    >
                      <Tooltip
                        placement="bottomLeft"
                        title={getTooltipMessage(packageLine)}
                      >
                        {/* need wrapper div otherwise tooltip does not interact with disabled button */}
                        <div>
                          <Checkbox
                            checked={
                              selectedPackageLines[groupName] ===
                              packageLine[Model_CommonItemFields.ID]
                            }
                            disabled={incompatibleRecords.includes(
                              packageLine[Model_CommonItemFields.ID],
                            )}
                            onChange={() => {
                              setSelectedPackageLines(prevState => ({
                                ...prevState,
                                [groupName]: packageLine[Model_CommonItemFields.ID],
                              }));
                            }}
                            circle
                          >
                            <ScPackageGroupTitle>
                              {renderNameTooltip(
                                packageLine[Model_CommonItemFields.name],
                              )}
                            </ScPackageGroupTitle>
                          </Checkbox>
                        </div>
                      </Tooltip>
                    </Col>
                    <Col span={4}>
                      {renderNameTooltip(packageLine[Model_CommonItemFields.ID])}
                    </Col>
                    <Col span={4} data-testid="accessories-modal-item-name-label">
                      {getFormatPrice(
                        packageLine[Model_CommonItemFields.totalSellingPrice],
                      )}
                    </Col>
                  </Row>
                </ScPackageEntryWrapper>
              ))}
          </ScPackageGroupWrapper>
        );
      },
    );
  };

  return (
    <Modal variant="sm" visible onCancel={onClose}>
      <PackageTitle>{packageTitle}</PackageTitle>
      <ScPackageContentWrapper>
        <Row align="middle" justify="center">
          <Col span={16} data-testid="package-lines-modal-item-name-label">
            <ScPackageLinesTableHeaderTitle>
              {t('PACKAGE_LINES_MODAL_OPTION')}
            </ScPackageLinesTableHeaderTitle>
          </Col>
          <Col span={4}>
            <ScPackageLinesTableHeaderTitle>
              {t('PACKAGE_LINES_MODAL_CODE')}:
            </ScPackageLinesTableHeaderTitle>
          </Col>
          <Col span={4} data-testid="package-lines-modal-item-name-label">
            <ScPackageLinesTableHeaderTitle>
              {t('PACKAGE_LINES_MODAL_PRICE')}
            </ScPackageLinesTableHeaderTitle>
          </Col>
        </Row>

        <ScPackagesLinesWrapper>{renderPackageLines()}</ScPackagesLinesWrapper>

        <ScTotalSection>
          <ScTotalItem>
            {t('PACKAGE_LINES_MODAL_TOTAL_PACKAGE')}:{' '}
            <ScTotalItemValue>{getFormatPrice(packageTotal)}</ScTotalItemValue>
          </ScTotalItem>
        </ScTotalSection>

        <ScModalFooter>
          <ScModalFooterItem>
            <Button
              onClick={onClose}
              disabled={status === Status.Loading}
              fullwidth
              data-testid="package-lines-modal-cancel-btn"
            >
              {t('FORM_CANCEL')}
            </Button>
          </ScModalFooterItem>
          {!isSelectHidden && (
            <ScModalFooterItem>
              {selectActionTooltip ? (
                <Tooltip
                  title={selectActionTooltip}
                  overlayInnerStyle={{ width: 'max-content' }}
                >
                  {/* need wrapper div otherwise tooltip does not interact with disabled button */}
                  <div>
                    <ScModalFooterDisabledButton
                      variant="primary"
                      onClick={handleSelect}
                      disabled={isSelectDisabled}
                      fullwidth
                      data-testid="package-lines-modal-submit-btn"
                    >
                      {t('SELECT')}
                    </ScModalFooterDisabledButton>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  variant="primary"
                  onClick={handleSelect}
                  disabled={isSelectDisabled}
                  fullwidth
                  data-testid="package-lines-modal-submit-btn"
                >
                  {t('SELECT')}
                </Button>
              )}
            </ScModalFooterItem>
          )}
        </ScModalFooter>
      </ScPackageContentWrapper>
    </Modal>
  );
};
