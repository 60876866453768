import {
  PriceListFields,
  StructureFields,
  StructureFilter,
  StructureFilter_SelectedFields,
  StructureItem,
  StructureItemFields,
  TtSelected,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields } from 'types/vendor';
import { useMediaQuery } from 'react-responsive';
import { defaultImage, MODELS_URL, STRUCTURE_LABEL } from 'common/constants';
import {
  FeatureSource,
  GetFeatureValueParams,
  useFeature,
} from 'context/feature/FeatureProvider';
import { decodeModelNumber } from 'context/model/utils/modelNumber';
import { usePriceList } from 'context/priceList/PriceListProvider';
import { useQuery } from 'context/router/UrlQueryProvider';
import { useStructureApi } from 'context/structure/useStructureApi';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get, reduce } from 'utils';
import { getModelImage, getTtParams } from 'utils/format';
import { getExtendedImage } from 'utils/table-images';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { sizes } from 'common/theme';
import { useSelector } from 'react-redux';
import {
  hostSettingsSelectors,
  authSelectors,
  configurationSelectors,
  modelSelectors,
  structureSelectors,
} from 'store';

interface Params {
  /** ### Query for feature
   * Motivation: this hook is being called on DynamicPage and ModelPage */
  isExtendedViewByDefault: GetFeatureValueParams;
}
interface Result {
  getBackgroundImage(): Promise<void>;
  backgroundImage: string | undefined;
  canShowBackgroundImage: boolean;
  isFallback: boolean;
}

export const useBackgroundImage = (params: Params): Result => {
  const [image, setImage] = useState<string | undefined>();

  const { selectedPriceListItem } = usePriceList();
  const { queryValues } = useQuery();
  const { modelCatalogCode, modelNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const username = useSelector(authSelectors.getUsername);
  const resizableBackgroundFallback = useSelector(
    hostSettingsSelectors.getResizableBackgroundFallback,
  );
  const model = useSelector(modelSelectors.getModel);
  const subStepsLabels = useSelector(structureSelectors.getModelBuildSteps);

  const { getStructure } = useStructureApi();
  const { pathname } = useLocation();
  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });
  const { isFeatureEnabled } = useFeature();

  const isInheritBackgroundImageFirstSubStepFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInheritBackgroundImageFirstSubStep,
  });
  const isInheritBackgroundImageAllSubstepsFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInheritBackgroundImageAllSubsteps,
  });
  const isInheritBackgroundImageModelFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInheritBackgroundImageModel,
  });
  const isExtendedViewByDefaultFeatureEnabled = isFeatureEnabled(
    params.isExtendedViewByDefault,
  );
  const isShowBackgroundImageDynamicStepsFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowBackgroundImage',
    source: FeatureSource.DYNAMIC,
  });
  // although it seems to be global property,
  // actually it is used now only by Model step
  const isShowBackgroundImageModelStepFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowShowBackgroundImage,
  });

  const canShowBackgroundImage = useMemo<boolean>(() => {
    if (isExtendedViewByDefaultFeatureEnabled) {
      return true;
    }

    // Models step is handled separately in terms of feature flagging
    if (pathname === MODELS_URL) {
      return isShowBackgroundImageModelStepFeatureEnabled;
    }
    // the last check to pass through,
    //no more pages to support background image for now
    return isShowBackgroundImageDynamicStepsFeatureEnabled;
  }, [
    isExtendedViewByDefaultFeatureEnabled,
    isShowBackgroundImageDynamicStepsFeatureEnabled,
    isShowBackgroundImageModelStepFeatureEnabled,
    pathname,
  ]);

  const stringifiedSubStepsLabels = JSON.stringify(subStepsLabels); // caused recursive calls
  const getStructuresQuery = useCallback((): Record<string, string> => {
    const { modelSelections } = queryValues;

    if (modelSelections && Object.values(modelSelections).length) {
      return modelSelections;
    }

    if (modelNumber) {
      return decodeModelNumber(modelNumber, subStepsLabels);
    }

    return {};
    // eslint-disable-next-line ,react-hooks/exhaustive-deps
  }, [modelNumber, queryValues.modelSelections, stringifiedSubStepsLabels]);

  const getStructureFilters = useCallback((): StructureFilter[] => {
    if (!modelCatalogCode) return [];
    const structuresQuery = getStructuresQuery();
    const ttSelected = reduce<Record<string, string>, TtSelected[]>(
      structuresQuery,
      (acc, value, key) => {
        if (value && key) {
          acc.push({
            [StructureFilter_SelectedFields.id]: key,
            [StructureFilter_SelectedFields.code]: value,
          });
        }
        return acc;
      },
      [],
    );

    const { ttParam } = getTtParams({
      [CustomerFields.id]: username,
      [PriceListFields.catalog]: modelCatalogCode,
    });

    return ttSelected.reduce<StructureFilter[]>((res, _, index, source) => {
      // make sure previous selections are included for the next filter
      // for more information see related tests
      const selected = source.slice(0, index);
      const filter: StructureFilter = { ttParam };
      if (selected.length) {
        filter.ttSelected = selected;
      }
      res.push(filter);
      return res;
    }, []);
  }, [username, getStructuresQuery, modelCatalogCode]);

  const getStructureImages = useCallback(async (): Promise<
    [string | undefined, ...string[]] | []
  > => {
    const filters = getStructureFilters();
    const structuresPromises = filters.map(filter => getStructure(filter));
    const structures = await Promise.all(structuresPromises);
    const newQuery = getStructuresQuery();
    return structures.reduce<[string | undefined, ...string[]] | []>(
      (res, { response: structure }, index) => {
        // each incremented index corresponds exactly to existing structure key in query
        // thus with each iteration we are able to retrieve code value
        // for a particular Model step sub-step selection
        const selectedCode = newQuery[`${STRUCTURE_LABEL}${index + 1}`];
        const isFirstStructure = index === 0;
        const structureItems: StructureItem[] = get(structure, StructureFields.items, []);
        // based on retrieved code value from the query
        // we are able to map the code value to each structure item's code value
        // thus find the customer's selectino on a particular Model step sub-step selection
        const selectedStructureItem = structureItems.find(
          structureItem => structureItem[StructureItemFields.code] === selectedCode,
        );

        // find the corresponding image for found (if found)
        // Model step sub-step selection
        const image = getExtendedImage(
          structure ?? null,
          StructureItemFields.imageLink,
          selectedStructureItem,
        );

        // due to the fact we are dependent on feature for primary and secondary
        // Model step background image inheritance, we need to always
        // push the first iteration's image (even if it is "undefined")
        // to the list, so that we never mess up primary and secondary images
        // later when deciding which one to show if feature flags are switched on / off
        if (isFirstStructure) {
          (res as [string | undefined]).push(image);
          return res;
        }

        // for secondary Model step sub-step selections
        // push only existent images, otherwise we are risking to set
        // "undefined" as background image if last Model step sub-step selection
        // had not any image whereas the previous sub-step selectino had
        if (image) {
          (res as string[]).push(image);
        }

        return res;
      },
      [],
    );
  }, [getStructureFilters, getStructuresQuery, getStructure]);

  const fallback = useMemo<string>(() => {
    return resizableBackgroundFallback ?? defaultImage;
  }, [resizableBackgroundFallback]);

  const getBackgroundImage = useCallback(async () => {
    if (!canShowBackgroundImage || !isLgAndWider) return;

    if (isInheritBackgroundImageModelFeatureEnabled) {
      const modelImage = getModelImage(model);
      if (modelImage) return setImage(modelImage);
    }

    let primaryStructureImage: string | undefined;
    let secondaryStructureImages: string[] = [];
    // optimization: rely on the flag to make structure images requests once
    let hasFetchedStructureImages = false;

    if (isInheritBackgroundImageAllSubstepsFeatureEnabled) {
      // settings the retrieved values
      [primaryStructureImage, ...secondaryStructureImages] = await getStructureImages();
      // no need to re-fetch later
      hasFetchedStructureImages = true;
      if (secondaryStructureImages.length) {
        // set the last selected secondary structure image
        const image = secondaryStructureImages[secondaryStructureImages.length - 1];
        return setImage(image);
      }
    }

    if (isInheritBackgroundImageFirstSubStepFeatureEnabled) {
      if (!hasFetchedStructureImages) {
        // re-fetch only if by some reason structure images have not been retrieved yet
        [primaryStructureImage, ...secondaryStructureImages] = await getStructureImages();
      }
      if (primaryStructureImage) {
        return setImage(primaryStructureImage);
      }
    }

    const priceListImage = selectedPriceListItem?.[PriceListFields.imageUrl];
    if (priceListImage) return setImage(priceListImage);

    setImage(fallback);
  }, [
    canShowBackgroundImage,
    isLgAndWider,
    isInheritBackgroundImageModelFeatureEnabled,
    isInheritBackgroundImageAllSubstepsFeatureEnabled,
    isInheritBackgroundImageFirstSubStepFeatureEnabled,
    selectedPriceListItem,
    fallback,
    model,
    getStructureImages,
  ]);

  return {
    getBackgroundImage,
    backgroundImage: image,
    canShowBackgroundImage,
    isFallback: image === fallback,
  };
};
