import styled from 'styled-components';

export const ScDocumentItemContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
  margin: 0 1em 1em 1em;
`;

export const ScDocumentItemHeader = styled.div`
  padding: 1.375em 0.688em;
  font-size: 1.25em;
  font-weight: 600;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScDocumentItemInner = styled.div`
  padding: 1.25em;
`;
