import { nanoid } from 'nanoid';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  ComponentsRendererComponentsCollection as Components,
  ComponentsRendererSchema as Schema,
  ComponentsRendererSchemaItem as SchemaItem,
  ComponentsRendererSchemaLocal as SchemaLocal,
  ComponentsRendererSchemaItemLocal as SchemaItemLocal,
} from './types';
import { isEqual } from 'utils';

export const useComponentsRender = (
  components: Components,
  schema: Schema,
): ReactNode => {
  const [localizedSchema, setLocalizedSchema] = useState<SchemaLocal>([]);
  const [prevSchema, setPrevSchema] = useState<Schema>([]);

  useEffect(() => {
    if (isEqual(prevSchema, schema)) return;
    setPrevSchema(schema);
    setLocalizedSchema(localizeSchema(schema));
  }, [schema, prevSchema]);

  return useMemo<ReactNode>(
    () => render(components, localizedSchema),
    [localizedSchema, components],
  );
};

const localizeSchema = (schema: Schema): SchemaLocal => {
  if (Array.isArray(schema)) return schema.map(localizeSchemaItem);
  return localizeSchemaItem(schema);
};

const localizeSchemaItem = (schemaItem: SchemaItem): SchemaItemLocal => {
  return {
    ...schemaItem,
    inner: schemaItem.inner ? localizeSchema(schemaItem.inner) : undefined,
    key: nanoid(),
    forceDisabled: schemaItem.forceDisabled ?? false,
    forceEnabled: schemaItem.forceEnabled ?? false,
  };
};

const render = (components: Components, schema: SchemaLocal): ReactNode => {
  if (Array.isArray(schema)) {
    return <>{schema.map(schemaItem => renderItem(components, schemaItem))}</>;
  }
  return renderItem(components, schema);
};

const renderItem = (components: Components, schemaItem: SchemaItemLocal): ReactNode => {
  const Component = components[schemaItem.slug];

  if (!Component) return null;

  return (
    <Component key={schemaItem.key} schema={schemaItem}>
      {schemaItem.inner ? render(components, schemaItem.inner) : undefined}
    </Component>
  );
};
