import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import { ContactRoleListItem } from '@hypercharge/xdms-client/lib/types';

const NAME = 'contacts';

interface State {
  readonly contactRoleListStatus: Status;
  readonly contactRoleList: ContactRoleListItem[];
}

const initialState: State = {
  contactRoleListStatus: Status.Idle,
  contactRoleList: [],
};

const actions = {
  setContactRolesListStatus: createAction<State['contactRoleListStatus']>(
    getActionName(NAME, 'SET_ROLES_STATUS'),
  ),
  setContactRolesList: createAction<State['contactRoleList']>(
    getActionName(NAME, 'SET_ROLES'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setContactRolesListStatus, (state, action) => ({
    ...state,
    contactRoleListStatus: action.payload,
  }));
  builder.addCase(actions.setContactRolesList, (state, action) => ({
    ...state,
    contactRoleList: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ contact }: ApplicationState) => contact,
    state => state,
  ),
  getRoleListStatus: createSelector(
    ({ contact }: ApplicationState) => contact,
    state => state.contactRoleListStatus,
  ),
  getRoleList: createSelector(
    ({ contact }: ApplicationState) => contact,
    state => state.contactRoleList,
  ),
};

export type { State as ContactState };
export {
  actions as contactActions,
  reducer as contactReducer,
  selectors as contactSelectors,
};
