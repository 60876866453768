import styled from 'styled-components';
import { BulbOutlined } from '@ant-design/icons';

export const ScProductsDrawerWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1em;
`;

export const ScTriggerIcon = styled(BulbOutlined)`
  font-size: 1.5em;
  color: ${({ theme }) => theme.mainColor};
`;
