import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Status } from 'utils/types';
import { getActionName } from '../utils';
import { ApplicationState } from '../index';
import {
  FinancingListItem,
  FinancingDetails,
  FinancingDetailsFields,
  FinancingCalculation,
} from 'types/vendor';

const NAME = 'financing';

interface State {
  readonly financingListStatus: Status;
  readonly financingList: FinancingListItem[] | null | undefined;
  readonly financingDetailsStatus: Status;
  readonly financingDetails: FinancingDetails | null | undefined;
  readonly financingSimulationStatus: Status;
  readonly financingSimulation: FinancingCalculation | null | undefined;
  readonly financingOptions:
    | FinancingDetails[FinancingDetailsFields.options]
    | null
    | undefined;
}

const initialState: State = {
  financingListStatus: Status.Idle,
  financingList: undefined,
  financingDetailsStatus: Status.Idle,
  financingDetails: undefined,
  financingSimulationStatus: Status.Idle,
  financingSimulation: undefined,
  financingOptions: undefined,
};

const actions = {
  setFinancingListStatus: createAction<State['financingListStatus']>(
    getActionName(NAME, 'SET_LIST_STATUS'),
  ),
  setFinancingList: createAction<State['financingList']>(getActionName(NAME, 'SET_LIST')),
  setFinancingDetailsStatus: createAction<State['financingDetailsStatus']>(
    getActionName(NAME, 'SET_DETAILS_STATUS'),
  ),
  setFinancingDetails: createAction<State['financingDetails']>(
    getActionName(NAME, 'SET_DETAILS'),
  ),
  setFinancingSimulationStatus: createAction<State['financingSimulationStatus']>(
    getActionName(NAME, 'SET_SIMULATION_STATUS'),
  ),
  setFinancingSimulation: createAction<State['financingSimulation']>(
    getActionName(NAME, 'SET_SIMULATION'),
  ),
  setFinancingOptions: createAction<State['financingOptions']>(
    getActionName(NAME, 'SET_OPTIONS'),
  ),
};

const reducer = createReducer<State>(initialState, builder => {
  builder.addCase(actions.setFinancingListStatus, (state, action) => ({
    ...state,
    financingListStatus: action.payload,
  }));
  builder.addCase(actions.setFinancingList, (state, action) => ({
    ...state,
    financingList: action.payload,
  }));
  builder.addCase(actions.setFinancingDetailsStatus, (state, action) => ({
    ...state,
    financingDetailsStatus: action.payload,
  }));
  builder.addCase(actions.setFinancingDetails, (state, action) => ({
    ...state,
    financingDetails: action.payload,
  }));
  builder.addCase(actions.setFinancingSimulationStatus, (state, action) => ({
    ...state,
    financingSimulationStatus: action.payload,
  }));
  builder.addCase(actions.setFinancingSimulation, (state, action) => ({
    ...state,
    financingSimulation: action.payload,
  }));
  builder.addCase(actions.setFinancingOptions, (state, action) => ({
    ...state,
    financingOptions: action.payload,
  }));
});

const selectors = {
  getAll: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state,
  ),
  getListStatus: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingListStatus,
  ),
  getList: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingList,
  ),
  getDetailsStatus: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingDetailsStatus,
  ),
  getDetails: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingDetails,
  ),
  getSimulationStatus: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingSimulationStatus,
  ),
  getSimulation: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingSimulation,
  ),
  getOptions: createSelector(
    ({ financing }: ApplicationState) => financing,
    (state: State) => state.financingOptions,
  ),
};

export type { State as FinancingState };
export {
  actions as financingActions,
  reducer as financingReducer,
  selectors as financingSelectors,
};
