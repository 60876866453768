import { InputNumber } from 'components/form/InputNumber';
import React, { FC, ComponentProps } from 'react';
import { ScTableInputContainer, ScTableInputPrefix } from './TotalTableInput.styles';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

interface Props extends ComponentProps<typeof InputNumber> {
  testNamePrefix: string;
  inputPrefix?: string;
  symbolsAfterDecimal?: number;
}

export const TotalTableInput: FC<Props> = ({
  testNamePrefix,
  min = 0,
  step = 0.01,
  inputPrefix,
  symbolsAfterDecimal,
  ...props
}) => {
  return (
    <ScTableInputContainer>
      {inputPrefix && <ScTableInputPrefix>{inputPrefix}</ScTableInputPrefix>}
      <InputNumberLocalized
        size="small"
        {...props}
        min={min}
        step={step}
        symbolsAfterDecimal={symbolsAfterDecimal}
        data-testid={`total-page-${testNamePrefix}`}
      />
    </ScTableInputContainer>
  );
};
