import React, { forwardRef } from 'react';
import { ButtonProps as AntButtonProps } from 'antd';
import classnames from 'classnames';
import { ScButton, ScButtonContent } from './Button.styles';

type ButtonVariants = 'primary' | 'prev' | 'next' | 'add' | 'highlighted';

interface Props extends AntButtonProps {
  variant?: ButtonVariants;
  fullwidth?: boolean;
  noBorder?: boolean;
  transparent?: boolean;
}
export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { className, fullwidth, noBorder, variant, transparent, children, ...props },
  ref,
) {
  const assembledClassName = classnames(
    className,
    {
      variant_primary: variant === 'primary',
      variant_prev: variant === 'prev',
      variant_next: variant === 'next',
      variant_add: variant === 'add',
      variant_highlighted: variant === 'highlighted',
    },
    {
      fullwidth: fullwidth,
      noBorder: noBorder,
      transparent: transparent,
    },
  );

  return (
    <ScButton ref={ref} className={assembledClassName} {...props}>
      <ScButtonContent>{children}</ScButtonContent>
    </ScButton>
  );
});
