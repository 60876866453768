import * as Yup from 'yup';
import {
  SoftOfferOccasionalDrivers,
  SoftOfferOccasionalDriversFields,
} from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<SoftOfferOccasionalDrivers>>({
    [SoftOfferOccasionalDriversFields.driver1LastName]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Firstname]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Street]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Number]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Box]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver1ZipCode]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Locality]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver1Language]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Tel]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver1Fax]: Yup.string(),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver1Sex]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver1Gsm]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1BirthdayAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver1LicenceCategoryCode]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferOccasionalDriversFields.driver1Email]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    /** Date */
    [SoftOfferOccasionalDriversFields.driver1LicenceAt]: Yup.string()
      .nullable()
      .required(t('FORM__REQUIRED')),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver1Job]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),

    [SoftOfferOccasionalDriversFields.driver2LastName]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Firstname]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Street]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Number]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Box]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2ZipCode]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Locality]: Yup.string(),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver2Language]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Tel]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Fax]: Yup.string(),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver2Sex]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Gsm]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2BirthdayAt]: Yup.string().nullable(),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver2LicenceCategoryCode]: Yup.string(),
    [SoftOfferOccasionalDriversFields.driver2Email]: Yup.string(),
    /** Date */
    [SoftOfferOccasionalDriversFields.driver2LicenceAt]: Yup.string().nullable(),
    /** Picklist */
    [SoftOfferOccasionalDriversFields.driver2Job]: Yup.string(),
  });
};
