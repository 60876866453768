import React, { FC, useState } from 'react';
import { LanguageFields } from '@hypercharge/xdms-client/lib/types';
import { Col, Row } from 'antd';
import { Button } from 'components/button/Button';
import { Modal, ModalHeader } from 'components/modal';
import { useLanguage } from 'context/language/LanguageProvider';
import { Language } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { isNil } from 'utils';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { ScModalContent, ScModalContentText, ScSelect } from './LanguageModal.styles';

interface Props {
  defaultLanguageCode?: string;
  isNewConfiguration?: boolean;
  onSubmit: (lng: string) => void;
  onCancel: () => void;
}
export type LanguageModalState = Props;
const LanguageModal: FC<Props> = ({
  onSubmit,
  onCancel,
  defaultLanguageCode = '',
  isNewConfiguration,
}) => {
  const { t } = useTranslation();
  const { languagesFiltered } = useLanguage();

  const [language, setLanguage] = useState<string>(defaultLanguageCode);

  return (
    <Modal
      data-testid="pricelist-language-modal"
      variant="sm"
      visible={true}
      onCancel={onCancel}
      center
    >
      <ModalHeader $textAlign="center">
        {t('PRICE_LIST_LANGUAGE_MODAL__TITLE')}
      </ModalHeader>

      <ScModalContent>
        <ScModalContentText>{t('PRICE_LIST_LANGUAGE_MODAL__TEXT')}</ScModalContentText>

        <ScSelect
          options={mapToSelectOptions<Language>(languagesFiltered, {
            label: LanguageFields.language,
            value: LanguageFields.languageCode,
          })}
          value={language}
          onChange={event => setLanguage(event.target.value)}
          bordered
          fullwidth
          showSearch
          allowClear
          data-testid="pricelist-language-modal__select-language"
        />

        {!isNewConfiguration && (
          <ScModalContentText>{t('DATA_MAYBE_LOST')}</ScModalContentText>
        )}

        <Row gutter={[15, 0]}>
          <Col md={12}>
            <Button
              data-testid="pricelist-language-modal__button-cancel"
              fullwidth
              onClick={onCancel}
            >
              {t('FORM_CANCEL')}
            </Button>
          </Col>
          <Col md={12}>
            <Button
              variant="primary"
              fullwidth
              disabled={isNil(language)}
              onClick={() => onSubmit(language ?? '')}
              data-testid="pricelist-language-modal__button-submit"
            >
              {t('SAVE')}
            </Button>
          </Col>
        </Row>
      </ScModalContent>
    </Modal>
  );
};

export default LanguageModal;
