import React, { FC, useMemo, useState } from 'react';
import { LanguageFields } from '@hypercharge/xdms-client/lib/types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Modal, ModalHeader } from 'components/modal';
import { Button } from 'components/button/Button';
import { useLanguage } from 'context/language/LanguageProvider';
import { Language } from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { ScModalContent, ScSelect } from './ConfigurationNewVersionModal.styles';
import { isNil } from 'utils';

interface Props {
  onSubmit: (values: { language: string }) => void;
  defaultLanguageCode?: string;
  onClose: () => void;
}

const ConfigurationNewVersionModal: FC<Props> = ({
  defaultLanguageCode,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const { languagesFiltered } = useLanguage();

  const [language, setLanguage] = useState<string | undefined>(defaultLanguageCode);

  const languageOptions = useMemo(() => {
    return mapToSelectOptions<Language>(languagesFiltered, {
      label: LanguageFields.language,
      value: LanguageFields.languageCode,
    });
  }, [languagesFiltered]);

  return (
    <Modal
      data-testid="configuration-new-version-modal"
      variant="sm"
      visible={true}
      onCancel={onClose}
      center
    >
      <ModalHeader $textAlign="center">
        {t('CONFIGURATION_NEW_VERSION_MODAL__TITLE')}
      </ModalHeader>

      <ScModalContent>
        <ScSelect
          options={languageOptions}
          value={language}
          onChange={event => setLanguage(event.target.value)}
          bordered
          fullwidth
          showSearch
          data-testid="configuration-new-version-modal__select_language"
        />

        <Row gutter={[15, 0]}>
          <Col md={12}>
            <Button
              data-testid="configuration-new-version-modal__button_cancel"
              fullwidth
              onClick={onClose}
            >
              {t('FORM_CANCEL')}
            </Button>
          </Col>
          <Col md={12}>
            <Button
              variant="primary"
              fullwidth
              disabled={isNil(language)}
              onClick={() => onSubmit({ language: language as string })}
              data-testid="configuration-new-version-modal__button_submit"
            >
              {t('SAVE')}
            </Button>
          </Col>
        </Row>
      </ScModalContent>
    </Modal>
  );
};

export default ConfigurationNewVersionModal;
