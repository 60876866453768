import { DocumentListItem_Status_Values } from '@hypercharge/xdms-client/lib/types';
import {
  getDocumentStatusByValues,
  getStatusValuesByDocumentStatus,
} from 'context/document/documentStatus';
import React, { FC, useCallback, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentCheckbox,
  DocumentLabel,
  DocumentStatus,
  ScAddDocumentWrapper,
  StatusCheckboxWrapper,
  StatusErrorMessage,
} from './DocumentForm.styles';

type DocumentStatusFieldElement = Omit<HTMLInputElement, 'value'> & {
  value: DocumentListItem_Status_Values;
};

interface Props {
  value?: DocumentListItem_Status_Values;
  onChange?(event: ChangeEvent<DocumentStatusFieldElement>): void;
  disabled?: boolean;
  errorMessages?: [string | undefined, string | undefined];
  name?: string;
  onBlur?(e: FocusEvent): void;
}

const DocumentStatusField: FC<Props> = ({
  value,
  onChange,
  disabled,
  errorMessages,
  name,
  onBlur,
}) => {
  const { t } = useTranslation();

  const localBooleanValues = useMemo<[boolean, boolean]>(() => {
    return getStatusValuesByDocumentStatus(value ?? DocumentListItem_Status_Values.empty);
  }, [value]);

  const [isExternal, isInternal] = localBooleanValues;

  const handleChange = useCallback(
    (newValues: [boolean, boolean]) => {
      const event = {
        target: {
          name: name,
          value: getDocumentStatusByValues(newValues),
        },
      } as ChangeEvent<DocumentStatusFieldElement>;
      onChange?.(event);
      setTimeout(() => onBlur?.(event as unknown as FocusEvent));
    },
    [onChange, name, onBlur],
  );

  return (
    <>
      <DocumentStatus>{t('DOCUMENT_STATUS')}</DocumentStatus>
      <ScAddDocumentWrapper>
        <StatusCheckboxWrapper $errored={Boolean(errorMessages?.[0])}>
          <DocumentCheckbox
            checked={isExternal}
            disabled={disabled}
            onChange={e => {
              handleChange([e.target.checked, isInternal]);
            }}
          >
            <DocumentLabel>{t('DOCUMENT_EXTERNAL')}</DocumentLabel>
          </DocumentCheckbox>
          {errorMessages?.[0] && (
            <StatusErrorMessage>{errorMessages[0]}</StatusErrorMessage>
          )}
        </StatusCheckboxWrapper>
        <StatusCheckboxWrapper $errored={Boolean(errorMessages?.[1])}>
          <DocumentCheckbox
            checked={isInternal}
            disabled={disabled}
            onChange={e => {
              handleChange([isExternal, e.target.checked]);
            }}
          >
            <DocumentLabel>{t('DOCUMENT_INTERNAL')}</DocumentLabel>
          </DocumentCheckbox>
          {errorMessages?.[1] && (
            <StatusErrorMessage>{errorMessages[1]}</StatusErrorMessage>
          )}
        </StatusCheckboxWrapper>
      </ScAddDocumentWrapper>
    </>
  );
};

export default DocumentStatusField;
