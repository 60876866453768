import {
  Model,
  ModelFields,
  ModelPackageLine,
  ModelPackageLineFields,
  PackageItem,
  PackageItemFields,
} from '@hypercharge/xdms-client/lib/types.export';

interface GetPackageLinesParams {
  model: Model | null;
  packageRecord: PackageItem | null;
}
/**
 * Returns all package lines related to provided package record
 * @param params {GetSelectedTableItemsParams}
 * @param params.model {Model | null} - model containing records
 * @param params.packageRecord {PackageItem | null} - package record
 * @returns {ModelPackageLine[]} list of package lines
 */
export const getPackageLines = (params: GetPackageLinesParams): ModelPackageLine[] => {
  const { model, packageRecord } = params;
  if (!model || !packageRecord) return [];
  const packageLines = model[ModelFields.packageLines];
  if (!packageLines) return [];
  return packageLines.filter(packageLine => {
    const packageName = packageLine[ModelPackageLineFields.package];
    return packageName === packageRecord[PackageItemFields.package];
  });
};
