import { ApolloProvider } from '@apollo/client';
import { Loader } from 'components/Loader';
import { client } from 'graphql/client';
import { ConfigurationProvider } from '../context/configuration/ConfigurationProvider';
import { PriceListProvider } from '../context/priceList/PriceListProvider';
import { TradeInProvider } from '../context/tradeIn/TradeInProvider';
import { CustomerProvider } from '../context/customer/CustomerProvider';
import { ContactProvider } from '../context/contact/ContactProvider';
import { RelationsProvider } from '../context/relations/RelationsProvider';
import { Status } from '../utils/types';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { LanguageProvider } from '../context/language/LanguageProvider';
import { CountryProvider } from '../context/country/CountryProvider';
import { StepProvider } from '../context/step/StepProvider';
import { FeatureProvider } from '../context/feature/FeatureProvider';
import { ExternalStorageProvider } from '../context/externalStorage/ExternalStorageProvider';
import { AuthenticatedSessionProvider } from '../context/session/AuthenticatedSessionProvider';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { useSelector } from 'react-redux';
import { hostSettingsSelectors, sharedSelectors } from 'store';
import { AuthenticatedPrerenderSessionProvider } from '../context/session/AuthenticatedPrerenderSessionProvider';
import { StreamingProvider } from 'context/streaming/StreamingProvider';
import { GlobalModalsStatesProvider } from '../context/globalModalsStates/GlobalModalsStatesProvider';

const AuthenticatedAppProviders: FC<PropsWithChildren> = ({ children }) => {
  const { xdmsClient } = useXdmsClient();

  const isXdmsClientSettled = useSelector(sharedSelectors.getIsXdmsClientSettled);
  const isI18nSettled = useSelector(sharedSelectors.getIsI18nSettled);
  const hostSettingsStatus = useSelector(hostSettingsSelectors.getStatus);

  const isXdmsClientDependenciesSettling = useMemo<boolean>(() => {
    return !xdmsClient;
  }, [xdmsClient]);

  const isFeaturesFlagsDependenciesSettling = useMemo<boolean>(() => {
    return (
      !isXdmsClientSettled || hostSettingsStatus !== Status.Success || !isI18nSettled
    );
  }, [hostSettingsStatus, isXdmsClientSettled, isI18nSettled]);

  return (
    <AuthenticatedPrerenderSessionProvider>
      <Loader.Conditional isLoading={isXdmsClientDependenciesSettling} preventRender>
        <ApolloProvider client={client}>
          <FeatureProvider>
            <StreamingProvider>
              <Loader.Conditional
                isLoading={isFeaturesFlagsDependenciesSettling}
                preventRender
              >
                <LanguageProvider>
                  <CustomerProvider>
                    <ContactProvider>
                      <CountryProvider>
                        <RelationsProvider>
                          <ConfigurationProvider>
                            <PriceListProvider>
                              <ExternalStorageProvider>
                                <StepProvider>
                                  <TradeInProvider>
                                    <GlobalModalsStatesProvider>
                                      <AuthenticatedSessionProvider>
                                        {children}
                                      </AuthenticatedSessionProvider>
                                    </GlobalModalsStatesProvider>
                                  </TradeInProvider>
                                </StepProvider>
                              </ExternalStorageProvider>
                            </PriceListProvider>
                          </ConfigurationProvider>
                        </RelationsProvider>
                      </CountryProvider>
                    </ContactProvider>
                  </CustomerProvider>
                </LanguageProvider>
              </Loader.Conditional>
            </StreamingProvider>
          </FeatureProvider>
        </ApolloProvider>
      </Loader.Conditional>
    </AuthenticatedPrerenderSessionProvider>
  );
};

export default AuthenticatedAppProviders;
