import styled from 'styled-components';
import { Switch } from 'antd';
import { mediaMax, mediaMin } from 'common/theme';

export const ScTitle = styled.span`
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.black};

  ${mediaMax.md} {
    font-size: 0.75em;
  }
`;

// about $ sign - its transient props that are not being passed to render level
// and being consumed on styled-components level only
// https://github.com/styled-components/styled-components/pull/3052
export const ScSwitch = styled(Switch)<{ $hasLeftMargin?: boolean }>`
  &.ant-switch {
    box-shadow: none;
    background-color: ${props => props.theme.colors.softPeach};
    width: 3.5em;
    height: 1.75em;
    display: inline-block;
    margin-left: ${({ $hasLeftMargin }) => ($hasLeftMargin ? '0.5em' : '0')};

    .ant-switch-handle {
      left: 0;
      height: 1.5em;
      width: 1.5em;

      &::before {
        content: '';
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75em;
        border-radius: 2em;
        transform: translateX(2px);
      }
    }
  }

  &.ant-switch-checked {
    border: none;
    background-color: ${props => props.theme.mainColor};
    > .ant-switch-handle {
      left: 0;
      &::before {
        transform: translateX(calc(3.5em - 1em));
      }
    }
  }
`;

export const SwitcherWrapper = styled.div<{ direction: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')};
  color: rgba(0, 0, 0, 0.25);

  ${mediaMin.md} {
    flex-direction: row;
    align-items: center;

    > span {
      margin-right: 0.5em;
    }
  }
`;
