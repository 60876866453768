import React, { ReactNode } from 'react';
import { ReactComponent as Success } from 'assets/icons/toast/success.svg';
import { ReactComponent as Error } from 'assets/icons/toast/error.svg';
import { ReactComponent as Info } from 'assets/icons/toast/info.svg';
import { ReactComponent as Warning } from 'assets/icons/toast/warning.svg';
import { ReactComponent as Close } from 'assets/icons/toast/close.svg';
import { NotificationType } from './types';

export const NOTIFICATION_ICONS: { [key in NotificationType]: ReactNode } = {
  [NotificationType.success]: <Success />,
  [NotificationType.error]: <Error />,
  [NotificationType.info]: <Info />,
  [NotificationType.warning]: <Warning />,
};

export const CloseIcon = <Close />;
