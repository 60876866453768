import * as Yup from 'yup';

type ActualSchemaValues = { _exclusive?: { required?: boolean } };

/**
 * Checks whether field is required or not based on Yup schema
 * @param schema - Yup schema
 * @param fieldName - Name of the field to map to schema
 * @returns {boolean} Whether field is required or not
 */
export const isFieldRequired = (schema: Yup.ObjectSchema, fieldName: string): boolean => {
  try {
    const fieldSchema = Yup.reach(schema, fieldName);
    return Boolean((fieldSchema as unknown as ActualSchemaValues)?._exclusive?.required);
  } catch (e) {
    return false;
  }
};
