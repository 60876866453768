import {
  Model,
  ModelRule,
  ModelRuleValues,
  Model_CommonItem,
  Model_CommonItemFields,
  Model_CommonItem_Type_Values,
} from '@hypercharge/xdms-client/lib/types';
import { intersection } from 'utils';
import { ModelRulesGrouped } from '../types';
import { getPackageIncompatibleRecords } from './get-package-incompatible-records';
import { getRules } from './get-record-rules';

interface CheckRecordIncompatibilityParams {
  record: Model_CommonItem | null;
  tableItems: Record<string, string>;
  model: Model | null;
  modelRules: ModelRule[];
}

interface CheckFlatRecordIncompatibilityParams extends CheckRecordIncompatibilityParams {
  rules?: ModelRulesGrouped;
  record: Model_CommonItem;
}

interface CheckPackageRecordIncompatibilityParams
  extends CheckRecordIncompatibilityParams {
  record: Model_CommonItem;
}

const isFlatRecordIncompatible = (
  params: CheckFlatRecordIncompatibilityParams,
): boolean => {
  const { rules, record, tableItems } = params;
  const recordKey = record[Model_CommonItemFields.ID];
  if (!rules?.[ModelRuleValues.incompatible]?.[recordKey]) {
    return false;
  }
  const incompatibleItems = Object.keys(rules[ModelRuleValues.incompatible][recordKey]);
  if (!incompatibleItems?.length) return false;
  return Boolean(intersection(incompatibleItems, Object.keys(tableItems)).length);
};

const isPackageRecordIncompatible = (
  params: CheckPackageRecordIncompatibilityParams,
): boolean => {
  const { record: pack, tableItems, model, modelRules } = params;
  const incompatibleRecords = getPackageIncompatibleRecords({
    model,
    modelRules,
    pack,
    tableItems,
  });
  return Boolean(incompatibleRecords.length);
};

type RecordTypeToHandlerMapType = {
  [key in Model_CommonItem_Type_Values]: (
    params:
      | CheckFlatRecordIncompatibilityParams
      | CheckPackageRecordIncompatibilityParams,
  ) => boolean;
};
// termsAndCondition and option have same value, so it is not being added
const recordTypeToHandlerMap: RecordTypeToHandlerMapType = {
  [Model_CommonItem_Type_Values.package]: isPackageRecordIncompatible,
  [Model_CommonItem_Type_Values.option]: isFlatRecordIncompatible,
  [Model_CommonItem_Type_Values.accessory]: isFlatRecordIncompatible,
  [Model_CommonItem_Type_Values.packageLine]: isFlatRecordIncompatible,
};

export const checkRecordIncompatibility = (
  params: CheckRecordIncompatibilityParams,
): boolean => {
  const { record, model, modelRules } = params;
  if (!record || !model) return false;
  const rules = getRules(modelRules);
  if (!rules) return false;
  const recordType = record[Model_CommonItemFields.type];
  const handler = recordTypeToHandlerMap[recordType];
  const handlerParams = { ...params, rules, record };
  if (!handler) return isFlatRecordIncompatible(handlerParams);
  return handler(handlerParams);
};
