import React, { FC } from 'react';
import { ScAddOptionsButton } from './ButtonAdd.styles';
import { Button } from './Button';

type Props = React.ComponentProps<typeof Button>;

export const ButtonAdd: FC<Props> = props => {
  return (
    <ScAddOptionsButton
      className="cp-show-flex cp-c-align-center-center"
      variant="highlighted"
      shape="circle"
      {...props}
    >
      +
    </ScAddOptionsButton>
  );
};
