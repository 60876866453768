import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { ScNavLink, ScIconWrap } from './BackButton.styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick(): void;
}

const BackButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <ScNavLink onClick={onClick} data-testid="login-page-history-back-button">
      <ScIconWrap data-testid="login-page-history-back-icon">
        <Icon component={LeftArrow} />
      </ScIconWrap>
      {t('AUTH_XDMS_SETTING')}
    </ScNavLink>
  );
};

export default BackButton;
