import React, { FC, ComponentProps } from 'react';
import { getTitle } from 'utils/get-title';
import { ScLabel, ScTextArea, ScTextAreaContainer } from './TextArea.styles';

interface Props extends ComponentProps<typeof ScTextArea> {
  label: string;
}

export const TextArea: FC<Props> = props => {
  return (
    <ScTextAreaContainer>
      {props.label && (
        <ScLabel>{getTitle(props.label, { required: props.required })}</ScLabel>
      )}
      <ScTextArea value={props.value} {...props} />
    </ScTextAreaContainer>
  );
};
