import { useInvalidImage } from 'hooks/useInvalidImage';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const commonImageStyles = css`
  height: 100%;
  top: 0;
`;

const ScCommonImage = styled.img<{ $isFallback: boolean }>``;

const ScImage = styled(ScCommonImage)`
  ${commonImageStyles}
  object-fit: cover;
  width: ${({ $isFallback }) => ($isFallback ? 'auto' : '100%')};
`;

const ScImageInvalid = styled(ScCommonImage)`
  ${commonImageStyles}

  object-fit: contain;
`;

interface ModelImageProps {
  [key: string]: unknown;
  image?: string;
  isFallback?: boolean;
}

export const ModelImage: FC<ModelImageProps> = props => {
  const { image, isFallback = false, ...rest } = props;

  const { t } = useTranslation();
  const { isInvalidImage, handleImageError } = useInvalidImage({ imageSrc: image });

  const Image = isInvalidImage ? ScImageInvalid : ScImage;

  return (
    <Image
      {...rest}
      $isFallback={isFallback}
      src={image}
      onError={handleImageError}
      alt={t('MODELS')}
    />
  );
};
