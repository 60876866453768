import { AutoCompleteProps } from 'antd/lib/auto-complete';
import cn from 'classnames';
import React, { FC, ChangeEvent, useCallback } from 'react';
import { OptionWithKey, OptionWithKey as TOptionWithKey } from 'types/common';
import { ScOption, ScSelect } from './Autocomplete.styles';
import { Loader } from 'components/Loader';

interface Props extends Omit<AutoCompleteProps, 'onChange'> {
  options: TOptionWithKey[];
  fullwidth?: boolean;
  size?: 'small' | 'large';
  name?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>, option?: OptionWithKey): void;
  'data-testid'?: string;
  loading?: boolean;
  isFilteringEnabled?: boolean;
  isFilteringByValueEnabled?: boolean;
}
/** Most of code copied from {@link Selector} */
const Select: FC<Props> = ({
  options,
  size,
  className,
  bordered = true,
  fullwidth,
  onChange,
  loading,
  dropdownRender,
  isFilteringEnabled = true,
  isFilteringByValueEnabled,
  ...props
}) => {
  const localOnChange = useCallback(
    (
      value: number | string | string[] | number[],
      option: TOptionWithKey | undefined,
    ) => {
      if (!onChange) return;

      const event: ChangeEvent<HTMLInputElement> = {
        target: {
          value: value,
          name: props.name ?? '',
        },
      } as ChangeEvent<HTMLInputElement>;

      // onChange is being called on 3 events:
      // on input: option = empty object
      // on select from dropdown: option = selected option
      // on clear: option = undefined
      let resultOption: TOptionWithKey | undefined;
      if (option) {
        resultOption = Object.keys(option).length ? option : undefined;
      }

      onChange(event, resultOption);
    },
    [props.name, onChange],
  );

  return (
    <ScSelect
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={localOnChange as AutoCompleteProps['onChange']}
      className={cn(
        className,
        { bordered: bordered, fullwidth: fullwidth },
        {
          size_small: size === 'small',
        },
      )}
      data-testid="autocomplete-field-container"
      // to search by label only: https://stackoverflow.com/a/71530973
      filterOption={
        isFilteringEnabled
          ? (inputValue, option) => {
              if (isFilteringByValueEnabled) {
                return (
                  option?.children.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option?.value.toLowerCase().includes(inputValue.toLowerCase())
                );
              }
              return option?.children.toLowerCase().includes(inputValue.toLowerCase());
            }
          : undefined
      }
      {...props}
      dropdownRender={menu => (
        <>
          <Loader.Conditional isLoading={Boolean(loading)} />
          {dropdownRender?.(menu) ?? menu}
        </>
      )}
      /** pair for {@link vendorSelectOptionStyle} */
      getPopupContainer={trigger => trigger.parentElement}
    >
      {options.map(option => (
        <ScOption
          key={option.key}
          disabled={option.disabled}
          value={option.value}
          data-testid="select-field-option"
        >
          {option.label}
        </ScOption>
      ))}
    </ScSelect>
  );
};

export default Object.assign(Select, { Option: ScOption });
