import React, { FC, ReactNode, useMemo } from 'react';
import { Model, ModelFields, TotalsItem } from '@hypercharge/xdms-client/lib/types';
import TotalTableCollapsibleListRow from '../../components/TotalTableCollapsibleListRow';
import { TFunction } from 'i18next';
import { get } from '../../../../utils';
import {
  totalsInnerTableColumnsWidths,
  TotalsTableColumnsGenericProp,
} from '../../styles';
import { TotalPageAdaptedFields } from '../../utils';
import { Price } from '../../../../components/Price';
import { TotalTextEllipsis } from 'pages/total/components/TotalTextEllipsis';

interface Props {
  model: Model | null;
  t: TFunction;
  FIELDS: TotalPageAdaptedFields;
  totalData: TotalsItem | null;
  hideIfEmpty: boolean;
}

const TradeInsSeparate: FC<Props> = ({ model, t, totalData, FIELDS, hideIfEmpty }) => {
  const items = useMemo<
    TotalsTableColumnsGenericProp<ReactNode | string | undefined>[]
  >(() => {
    const res: TotalsTableColumnsGenericProp<ReactNode | string | undefined>[] = [];

    const dataList = get(model, ModelFields.tradeIns, []);

    dataList.forEach(item => {
      res.push([
        undefined,
        <TotalTextEllipsis>
          {item[FIELDS.tradeInItemsFields.brandName]}
          {' - '}
          {item[FIELDS.tradeInItemsFields.modelName]}
          {' - '}
          {item[FIELDS.tradeInItemsFields.serialNumber]}
        </TotalTextEllipsis>,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        <Price value={item[FIELDS.tradeInItemsFields.subtotal]} />,
      ]);
    });

    return res;
  }, [FIELDS, model]);

  if (hideIfEmpty && !items.length) return null;

  return (
    <TotalTableCollapsibleListRow<
      TotalsTableColumnsGenericProp<ReactNode | string | undefined>
    >
      columnsCustomWidths={totalsInnerTableColumnsWidths}
      items={items}
      getItemFields={fields => fields}
      testIdPrefix="selected-tradeins"
      headerFields={[
        t('TOTAL_HEADER_TRADEIN_SUBTOTAL'),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        <Price key="tradeInSubtotal" value={get(totalData, FIELDS.tradeInSubtotal, 0)} />,
      ]}
    />
  );
};

export default TradeInsSeparate;
